import React, { PureComponent } from "react";
import getC from "../TourneeFiches/Picto_correspondance";
import SvgEmptyMarker from "../SvgComponents/EmptyMarker";
import { withTranslation } from "react-i18next";

export interface Props {
  clickMarker?: Function;
  meterId?: string;
  meterSerial?: string;
  isCenterMarker?: boolean;
  lat: number;
  lng: number;
  icon: any;
  isSelected?: boolean;
  t: Function;
}

class MarkerGM extends PureComponent<Props> {
  render() {
    const {
      clickMarker,
      meterId,
      lat,
      lng,
      icon,
      isCenterMarker,
      isSelected,
      meterSerial,
      t,
    } = this.props;
    const iconProps = {
      height: isCenterMarker ? "40px" : "33px",
      width: isCenterMarker ? "40px" : "33px",
    };
    return (
      <div
        className="clickable markerGM"
        onClick={() => clickMarker && clickMarker(meterId, lat, lng)}
        key={meterId ? `meter-${meterId}` : `center_marker`}
        //@ts-ignore
        lat={lat}
        lng={lng}
      >
        {!isCenterMarker && (
          <span
            className="selected-marker"
            style={{ display: isSelected ? "inherit" : "" }}
          >
            <SvgEmptyMarker height="75px" width="75px" />
          </span>
        )}
        <span
          className={
            "marker" +
            (isSelected ? " markerSelected" : "") +
            (isCenterMarker ? " centerMarker" : "")
          }
        >
          {getC(icon, iconProps)}
        </span>
        {!isCenterMarker && (
          <div
            className="popupMarker absoluteVerticalCenter"
            style={{
              top: isSelected ? "calc(50% + (-40px))" : "",
              left: isSelected ? "calc(100% + 35px)" : "",
            }}
          >
            <h3>{t("all.meter.meter_serial")}</h3>
            <span className="meterSerial">{meterSerial}</span>
          </div>
        )}
      </div>
    );
  }
}

const tr = withTranslation()(MarkerGM);
export default tr;
