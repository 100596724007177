import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";
import { formatDashboardName } from "../_helpers";
import graphique1 from "../_svgs/graphique1.svg";
import graphique2 from "../_svgs/graphique2.svg";
import graphique3 from "../_svgs/graphique3.svg";
import { dashboard } from "../_entities/dashboard";
import { Bookmark } from "../_entities/bookmark";
import { User } from "../_entities/user";
import { WithTranslation, withTranslation } from "react-i18next";

type Props = {
  bookmarks: Array<Bookmark>;
  dashboards: Array<dashboard>;
  location: any;
  user: User;
  displayTitle?: boolean;
} & WithTranslation;

type State = {
  dashboards: Array<dashboard>;
};

/**
 * @class DisplayBookmark
 * @extends Component
 */
class DisplayBookmark extends React.Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      dashboards: [],
    };
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { bookmarks, dashboards } = this.props;
    const ids = bookmarks.map((it: Bookmark) => it.dashboardId);
    this.setState({
      dashboards: dashboards.filter(
        (it: dashboard) => it.id === ids.find((id: number) => id === it.id)
      ),
    });
  }

  /**
   * Affiche l'image de graphique en fonction de l'index
   * passé en paramètre
   *
   * @method displayGraph
   * @param {number} index Indice
   * @returns {JSX} Le graphique
   */
  displayGraph = (index: number) => {
    switch (index % 3) {
      case 0:
        return (
          <Fragment>
            {" "}
            <img src={graphique1} alt="graphique1" />{" "}
          </Fragment>
        );
      case 1:
        return (
          <Fragment>
            {" "}
            <img src={graphique2} alt="graphique2" />{" "}
          </Fragment>
        );
      case 2:
      default:
        return (
          <Fragment>
            {" "}
            <img src={graphique3} alt="graphique3" />{" "}
          </Fragment>
        );
    }
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { location, user, dashboards, displayTitle, t } = this.props;
    console.log("DisplayBookmark", this.props);
    return (
      <div>
        <h5>{displayTitle && location.name}</h5>
        {dashboards && (
          <Row>
            {dashboards.map((dashboard, index) => (
              <div key={dashboard.id} className="col-md-3 bookmark-container">
                <div className="bookmark-header">
                  <span className="bookmark-dashboard-title">
                    <Link
                      to={{
                        pathname: `/locations/${location.id}/dashboards/${dashboard.id}`,
                      }}
                    >
                      {t("display_bookmark.link_text.dashboard_nb_x", {
                        dashboardIndex: index + 1,
                      })}
                    </Link>
                  </span>
                  <span className="bookmark-dashboard-main-title">
                    <Link
                      to={{
                        pathname: `/locations/${location.id}/dashboards/${dashboard.id}`,
                      }}
                    >
                      {formatDashboardName(user, dashboard, t)}
                    </Link>
                  </span>
                </div>
                <div className="bookmark-body">
                  <Link
                    to={{
                      pathname: `/locations/${location.id}/dashboards/${dashboard.id}`,
                    }}
                  >
                    {this.displayGraph(index)}
                  </Link>
                </div>
              </div>
            ))}
          </Row>
        )}
      </div>
    );
  }
}

const tr = withTranslation()(DisplayBookmark);
export default tr;
