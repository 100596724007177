import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

function chronologie(a, b) {
  return new Date(a) - new Date(b);
}

const formatDate = (date, zoom) => {
  switch (zoom) {
    case "Hour":
      return moment(date).format("DD/MM/YYYY HH:mm");
    case "Day":
      return moment(date).format("DD/MM/YYYY");
    case "Week":
      return moment(date).format("DD/MM/YYYY");
    case "Month":
      return moment(date).format("MM/YYYY");
    case "Year":
      return moment(date).format("YYYY");
    default:
  }
};

const BarChart = (props) => {
  const { data, labels, zoom, t } = props;
  console.log(props);
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      t1: true,
      t2: false,
      datalabels: {
        display: false,
      },
    },
    legend: {
      display: false,
    },
    emptyOverlay: {
      message: t("all.text.no_data_available"),
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const datasets = [
    {
      label: "",
      data,
      backgroundColor: "#31c6b3",
      stack: "full",
    },
  ];

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Bar
        data={{ datasets, labels: labels.map((el) => formatDate(el, zoom)) }}
        options={options}
      />
    </div>
  );
};

function mapStateToProps(state) {
  const { vmeters } = state;
  return {
    vmeters,
  };
}

const mapping = connect(mapStateToProps)(BarChart);

const connectedChart = withRouter(mapping);
const tr = withTranslation()(connectedChart);
export default tr;
