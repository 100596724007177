import React, { Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import MesInformationsBlanc from "../SvgComponents/MesInformationsBlanc";
import Lock from "../SvgComponents/Lock";
import PoubelleBleue from "../SvgComponents/PoubelleBleu";
import Eye from "../SvgComponents/Eye";

import { Col, Row, UncontrolledTooltip } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import confirm from "../_components";
import { userActions } from "../_actions";
import { User } from "../_entities/user";
import { alert, users } from "../_interfaces/reducers";
import AddUser from "./AddUser";
import LoadingBand from "../Bands/Loading";
import ErrorBand from "../Bands/Error";

import GestionUtilisateurBlanc from "../SvgComponents/GestionUtilisateurBlanc";
import MoniteurBlanc from "../SvgComponents/MonitorBlanc";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { withTranslation } from "react-i18next";

interface Props {
  dispatch: Function;
  user: User;
  history: Array<string>;
  users: users;
  alert: alert;
  permissions: any;
  authentication: any;
  t: Function;
}

interface State {
  addOpen: boolean;
  addTourmaline: boolean;
  addOpenTourmaline: boolean;
}

/**
 * Liste des utilisateurs
 *
 * @class UsersComponent
 * @extends Component
 */
class UsersComponent extends React.Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      addOpen: false,
      addTourmaline: false,
      addOpenTourmaline: false,
    };

    this.delete = this.delete.bind(this);
    this.block = this.block.bind(this);
  }

  /**
   * @method componentDidUpdate
   * @param {any} prevProps Props précédentes
   * @param {any} prevState State précédent
   */
  componentDidUpdate(prevProps: any, prevState: any) {
    //Si l'utilisateur à été correctement mis à jour on force une déco
    if (!prevProps.users.savedUser && this.props.users.savedUser) {
      if (prevState.addOpenTourmaline) {
        this.addTourmaline();
      } else {
        this.add();
      }
      const { history } = this.props;
      history.push(`/users/${this.props.users.savedUser.id}`);
    }
  }

  /**
   * Gère l'ouverture/fermeture de la modal d'ajout
   * d'utilisateur
   *
   * @method add
   */
  add = () => {
    const { addOpen } = this.state;
    this.setState({
      addOpen: !addOpen,
    });
  };

  addTourmaline = () => {
    const { addOpenTourmaline } = this.state;
    this.setState({
      addOpenTourmaline: !addOpenTourmaline,
    });
  };

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { dispatch, user, history } = this.props;

    // on vérifie la sécurité
    if (user.role.name === "USER") {
      history.push("/forbidden");
    }
    dispatch(userActions.getAll());
  }

  /**
   * Gère les droits d'édition/suppression d'utilisateur
   *
   * @method couldEditOrDeleteUser
   * @param {User} userToEdit Utilisateur à éditer
   * @returns {boolean} Le test
   */
  couldEditOrDeleteUser = (userToEdit: User) => {
    const { user } = this.props;
    const roleList = ["DIOPTASE", "SUPERADMIN"];
    return userToEdit.role.name === "USER" ||
      (roleList.includes(user.role.name) &&
        (userToEdit.role.name === "ADMIN" || user.id === userToEdit.id)) ||
      user.role.name === "DIOPTASE"
      ? true
      : "";
  };

  /**
   * Gère le blocage/déblocage d'utilisateur
   *
   * @async
   * @method block
   * @param {Object} event Evènement
   * @param {User} user Utilisateur
   */
  async block(event: Object, user: User) {
    const { dispatch, users, t } = this.props;

    const { Fragment } = React;

    const result = await confirm({
      title: (
        <Fragment>
          <strong>
            {t("users_list.text.delete_user")} {user.userName} ?{" "}
          </strong>
        </Fragment>
      ),
      message: user.accountLocked ? t("user.text.unlock") : t("user.text.lock"),
      confirmText: user.accountLocked
        ? t("all.text.unlock")
        : t("all.text.lock"),
      confirmColor: "danger",
      cancelColor: "primary",
      cancelText: t("all.button.giveup"),
    });
    if (result) {
      dispatch(userActions.blockOrUnblock(user, users.items));
    }
  }

  /**
   * Gère la suppression d'un utilisateur
   *
   * @param {Object} event Evènement
   * @param {User} User Utilisateur
   * @method delete
   * @memberof DisplayDashBoardsComponent
   */
  async delete(event: Object, user: User) {
    const { t } = this.props;
    const { Fragment } = React;

    const result = await confirm({
      title: (
        <Fragment>
          <strong>
            {t("users_list.text.delete_user")} {user.userName} ?{" "}
          </strong>
        </Fragment>
      ),
      message: t("user.text.delete"),
      confirmText: t("all.button.delete"),
      confirmColor: "danger",
      cancelColor: "primary",
      cancelText: t("all.button.giveup"),
    });
    if (result) {
      const { users, dispatch } = this.props;
      const result = await confirm({
        title: (
          <Fragment>
            <strong>
              {t("users_list.text.delete_user")} {user.userName} ?{" "}
            </strong>
          </Fragment>
        ),
        message: t("user.text.sure"),
        confirmText: t("all.button.delete"),
        confirmColor: "danger",
        cancelColor: "primary",
        cancelText: t("all.button.giveup"),
      });
      if (result) {
        dispatch(userActions.toDelete(user, users.items));
      }
    }
  }

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { users, alert, history, t, user } = this.props;
    const { addOpen, addOpenTourmaline } = this.state;
    const roleTourmaline = "ADMIN";

    const { SearchBar } = Search;
    const rowEvents = (e: Object, row: any) => {
      history.push(`/users/${row.id}`);
    };
    const columns: any = [
      {
        dataField: "firstName",
        text: t("all.contact.first_name"),
        sort: true,
        style: {
          overflowWrap: "break-word",
        },
        headerStyle: () => ({ width: "15%" }),
      },
      {
        dataField: "lastName",
        text: t("all.contact.last_name"),
        sort: true,
        style: {
          overflowWrap: "break-word",
        },
        headerStyle: () => ({ width: "15%" }),
      },
      {
        dataField: "email",
        text: t("all.contact.email"),
        sort: true,
        style: {
          overflowWrap: "break-word",
        },
        headerStyle: () => ({ width: "30%" }),
      },
      {
        dataField: "role.name",
        text: t("all.user.role"),
        sort: true,
        style: {
          overflowWrap: "break-word",
        },
        headerStyle: () => ({ width: "15%" }),
      },
      {
        dataField: "user.accountLocked",
        text: t("all.text.state"),
        sort: true,
        style: {
          overflowWrap: "break-word",
        },
        headerStyle: () => ({ width: "15%" }),
        formatter: (cellContent: any, row: any) =>
          row.isAccountLocked ? t("all.text.locked") : t("all.text.active"),
      },
      {
        dataField: "action",
        isDummyField: true,
        align: "center",
        headerStyle: () => ({ width: "25%" }),
        text: "",
        formatter: (cellContent: any, row: any) =>
          this.couldEditOrDeleteUser(row) && (
            <Fragment>
              <Row>
                <Col md="4">
                  <Fragment>
                    <div
                      id={`user-show${row.id}`}
                      className="clickable round"
                      role="presentation"
                      onClick={(e) => rowEvents(e, row)}
                    >
                      <Eye height="1em" width="1em" />
                      <UncontrolledTooltip
                        placement="bottom"
                        target={`user-show${row.id}`}
                      >
                        {" "}
                        {t("users_list.text.view_informations")}
                      </UncontrolledTooltip>
                    </div>
                  </Fragment>
                </Col>
                <Col md="4">
                  <Fragment>
                    <div
                      id={`user-block${row.id}`}
                      className="clickable round"
                      role="presentation"
                      onClick={(e) => this.block(e, row)}
                    >
                      {!row.isAccountLocked && !row.ldap && (
                        <Fragment>
                          <Lock height="1em" width="1em" />

                          <UncontrolledTooltip
                            placement="bottom"
                            target={`user-block${row.id}`}
                          >
                            {" "}
                            {t("users_list.text.block_user")}
                          </UncontrolledTooltip>
                        </Fragment>
                      )}

                      {row.isAccountLocked && !row.ldap && (
                        <Fragment>
                          <Lock height="1em" width="1em" />

                          <UncontrolledTooltip
                            placement="bottom"
                            target={`user-block${row.id}`}
                          >
                            {" "}
                            {t("users_list.text.unblock_user")}
                          </UncontrolledTooltip>
                        </Fragment>
                      )}
                    </div>
                  </Fragment>
                </Col>
                <Col md="4">
                  <Fragment>
                    {!row.ldap && (
                      <div
                        id={`user-remove${row.id}`}
                        className="clickable round"
                        role="presentation"
                        onClick={(e) => this.delete(e, row)}
                      >
                        <PoubelleBleue height="1em" width="1em" />
                        <UncontrolledTooltip
                          placement="bottom"
                          target={`user-remove${row.id}`}
                        >
                          {" "}
                          {t("users_list.text.delete_user")}
                        </UncontrolledTooltip>
                      </div>
                    )}
                  </Fragment>
                </Col>
              </Row>
            </Fragment>
          ),
      },
    ];

    if (users.items && users.items.find((el: any) => el.ldap)) {
      columns.push({
        dataField: "ldap",
        text: "Ldap",
        sort: true,
        style: {
          overflowWrap: "break-word",
        },
        formatter: (cellContent: any, row: any) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {row.ldap ? (
              <CheckIcon className={"material-icon-check"} />
            ) : (
              <CloseIcon className={"material-icon-close"} />
            )}
          </div>
        ),
      });
    }

    return (
      <div className="col-md-12">
        {users.loading && (
          <LoadingBand message="Chargement de la liste des utilisateurs" />
        )}
        {users.error && <ErrorBand message={users.error} />}
        {users.items && (
          <div className="table-info-container">
            {" "}
            <div>
              <h2>
                <span>
                  <MesInformationsBlanc
                    height="1em"
                    width="1em"
                    fill="#31c6b3"
                  />
                </span>
                {t("users.text.user_management")} :
                <span className="addUser float-right" style={{ width: "8%" }}>
                  <div style={{ display: "flex", float: "right" }}>
                    {user &&
                      user.role &&
                      user.role.name &&
                      ["DIOPTASE", "SUPERADMIN", "ADMIN"].includes(
                        user.role.name
                      ) && (
                        <div
                          className="round clickable"
                          onClick={this.addTourmaline}
                          role="presentation"
                          id="addTourmalineUser"
                          style={{ marginRight: "10px" }}
                        >
                          {" "}
                          <MoniteurBlanc className="add" fill="#2c303b" />
                          <UncontrolledTooltip
                            placement="bottom"
                            target="addTourmalineUser"
                          >
                            {" "}
                            {t("users.text.add_tourmaline_user")}
                          </UncontrolledTooltip>
                          <AddUser
                            add={this.addTourmaline}
                            isOpen={addOpenTourmaline}
                            tourmaline={true}
                          />
                        </div>
                      )}
                    <div
                      className="round clickable"
                      onClick={this.add}
                      role="presentation"
                      id="adduser"
                    >
                      {" "}
                      <GestionUtilisateurBlanc className="add" fill="#2c303b" />
                      <UncontrolledTooltip placement="bottom" target="adduser">
                        {" "}
                        {t("users.text.add_user")}
                      </UncontrolledTooltip>
                      <AddUser
                        add={this.add}
                        isOpen={addOpen}
                        tourmaline={false}
                      />
                    </div>
                  </div>
                </span>
              </h2>
            </div>
            <ToolkitProvider
              keyField="id"
              data={users.items}
              columns={columns}
              search
            >
              {(props: any) => (
                <div className="searchbar crystalList-container-user">
                  <SearchBar
                    {...props.searchProps}
                    placeholder={t("users.text.search_user")}
                    style={{ width: "150%" }}
                  />
                  <br />
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    bordered={false}
                    hover
                    pagination={
                      users.items.length > 10 ? paginationFactory() : null
                    }
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { users, authentication, alert, permissions } = state;
  const { user } = authentication;
  return {
    permissions,
    user,
    users,
    alert,
  };
}

const mapping: any = connect(mapStateToProps)(UsersComponent);

const connectedUsers = withRouter(mapping);
const tr = withTranslation()(connectedUsers);
export default tr;
