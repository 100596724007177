import React, { Component } from "react";
import { Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import synchrocomActions from "../../../_actions/synchrocom.actions";
import synchroActions from "../../../_actions/synchro.actions";

const ValidateButtons = (props) => {
  const { task, online, isRound, gestionnaireId, waitingLabels, dispatch, t } =
    props;
  const { id } = task;
  const removeWaitingLabels = () => {
    dispatch(
      isRound
        ? synchrocomActions.roundCancel(id, gestionnaireId)
        : synchrocomActions.deviceCancel(id, gestionnaireId)
    );
  };

  const validWaitingLabels = () => {
    dispatch(
      isRound
        ? synchrocomActions.roundCom(
            id,
            gestionnaireId,
            waitingLabels.map((el) => el.device)
          )
        : synchrocomActions.deviceCom(
            id,
            gestionnaireId,
            waitingLabels.map((el) => el.tournee)
          )
    );
  };

  return (
    <div className="waiting-action-button">
      <Button
        className="button-valid-drag"
        color="primary"
        onClick={removeWaitingLabels}
        style={{
          borderRadius: "0",
          borderRight: "2px solid white",
          borderTop: "none",
          outline: "none",
        }}
      >
        {t("all.button.cancel")}
      </Button>{" "}
      <Button
        className="button-valid-drag"
        onClick={validWaitingLabels}
        style={{
          borderRadius: "0 0 10px 0",
          borderLeft: "2px solid white",
          borderTop: "none",
          outline: "none",
        }}
        disabled={!online && !isRound}
      >
        {t("all.button.apply")}
      </Button>{" "}
    </div>
  );
};

function mapStateToProps() {}

const mapping = connect(mapStateToProps)(ValidateButtons);
const connectedTask = withRouter(mapping);
const tr = withTranslation()(connectedTask);
export default tr;
