import { templateConstants } from "../_constants";
import templateService from "../_services/template.service";
import alertActions from "./alert.actions";
import { Template } from "../_entities/template";

/**
 * Récupère tous les templates de widgets
 *
 * @method getAll
 * @returns {Object} Les templates
 */
function getAll() {
  function request() {
    return { type: templateConstants.GETALL_REQUEST };
  }
  function success(templates: Object) {
    return { type: templateConstants.GETALL_SUCCESS, templates };
  }
  function failure(error: Object) {
    return { type: templateConstants.GETALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    templateService
      .getAll()
      .then((templates: Object) => dispatch(success(templates)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les datasources
 *
 * @method getAllDatasources
 * @returns {Object} Les datasources
 */
function getAllDatasources() {
  return templateService.getAllDatasources();
}

/**
 * Récupère le template dont l'id est passé en paramètre
 *
 * @method get
 * @param {number} id Id du template
 * @returns {Object} Le template
 */
function get(id: number) {
  function request() {
    return { type: templateConstants.GET_REQUEST };
  }
  function success(fetchedtemplate: Object) {
    return { type: templateConstants.GET_SUCCESS, fetchedtemplate };
  }
  function failure(error: Object) {
    return { type: templateConstants.GET_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    templateService
      .get(id)
      .then((fetchedtemplate: Object) => dispatch(success(fetchedtemplate)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 * Sauvegarde le template passé en paramètre
 *
 * @method save
 * @param {any} template Le template
 * @param {Array<any>} templates Tous les templates
 * @returns {Object} L'état de la sauvegarde
 */
function save(template: any, templates = <Template[]>[]) {
  function success(savedTemplate: Object) {
    return { type: templateConstants.SAVE_SUCCESS, templates, savedTemplate };
  }
  return (dispatch: Function) => {
    templateService
      .save(template)
      .then((savedTemplate: Object) => {
        dispatch(success(savedTemplate));
        dispatch(alertActions.success("Opération réussie"));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(alertActions.error(error));
      });
  };
}

/**
 * Met à jour le template passé en paramètre
 *
 * @method update
 * @param {any} template Le template
 * @returns {Object} L'état de la mise à jour
 */
function update(template: Template) {
  function success(updatedtemplate: Object) {
    return { type: templateConstants.UPDATE_SUCCESS, updatedtemplate };
  }
  return (dispatch: Function) => {
    templateService
      .update(template)
      .then((updatedTemplate: Object) => {
        dispatch(success(updatedTemplate));
        dispatch(alertActions.success("Opération réussie"));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(alertActions.error(error));
      });
  };
}

/**
 * Supprime le template passé en paramètre
 *
 * @method toDelete
 * @param {any} template Le template
 * @param {Array<any>} templates Tous les templates
 * @returns {Object} L'état de la suppression
 */
function toDelete(template: any, templates = <Template[]>[]) {
  function success(operation: Object) {
    return {
      type: templateConstants.DELETE_SUCCESS,
      operation,
      template,
      templates,
    };
  }

  function failure(error: Object) {
    return { type: templateConstants.DELETE_FAILURE, error };
  }

  return (dispatch: Function) => {
    templateService
      .toDelete(template.id)
      .then((operation: Object) => {
        dispatch(success(operation));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 * Vide le state des templates
 *
 * @method clear
 */
function clear() {
  return {
    type: templateConstants.CLEAR,
  };
}

const templateActions = {
  getAll,
  get,
  update,
  save,
  toDelete,
  getAllDatasources,
  clear,
};

export default templateActions;
