import { alarmConstants } from "../_constants";
import alarmService from "../_services/alarm.service";
import alertActions from "./alert.actions";
import { cloneDeep } from "lodash";
import { Alert } from "../_entities/alert";

/**
 * Récupère toutes les alarmes du site courant
 *
 * @methd getAll
 * @param {string} rndCode Code du site
 * @returns {Object} Les alarmes (retour ws)
 */
function getAll(rndCode: string) {
  function request() {
    return { type: alarmConstants.GETALL_REQUEST };
  }
  function success(alarms: Object) {
    return { type: alarmConstants.GETALL_SUCCESS, alarms };
  }
  function failure(error: Object) {
    return { type: alarmConstants.GETALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    alarmService
      .getAll(rndCode)
      .then((alarms: any) => {
        dispatch(success(alarms));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getAllType() {
  function request() {
    return { type: alarmConstants.GETALL_TYPE_REQUEST };
  }
  function success(types: Object) {
    return { type: alarmConstants.GETALL_TYPE_SUCCESS, types };
  }
  function failure(error: Object) {
    return { type: alarmConstants.GETALL_TYPE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    alarmService
      .getAllType()
      .then((types: any) => {
        dispatch(success(types));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Retourne tous les templates d'alarmes
 *
 * @method getAllTemplates
 * @returns {Object} Les templates
 */
function getAllTemplates() {
  return alarmService.getAllTemplates();
}

/**
 * Récupère l'alarme dont l'id est passé en
 * paramètre, sur le site courant
 *
 * @method get
 * @param {number} id Id de l'alarme
 * @param {string} rndCode Code du site
 * @returns {Object} L'alarme
 */
function get(id: number, rndId: number) {
  function request() {
    return { type: alarmConstants.GET_REQUEST };
  }
  function success(fetchedAlarm: any) {
    return { type: alarmConstants.GET_SUCCESS, fetchedAlarm };
  }
  function failure(error: Object) {
    return { type: alarmConstants.GET_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    alarmService
      .get(id, rndId)
      .then((fetchedAlarm: any) => dispatch(success(fetchedAlarm)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Enregistre l'alarme passée en paramètre
 *
 * @method save
 * @param {any} alarm L'alarme
 * @returns {Object} Etat de la sauvegarde
 */
function save(alarm: any) {
  function success(savedAlarm: Object) {
    return { type: alarmConstants.SAVE_SUCCESS, savedAlarm };
  }
  function failure(error: Object) {
    return { type: alarmConstants.SAVE_FAILURE, error };
  }
  return (dispatch: Function) => {
    alarmService
      .save(alarm)
      .then((savedAlarm: Object) => {
        dispatch(success(savedAlarm));
        dispatch(alertActions.success("Opération réussie"));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Met à jour l'alarme passée en paramètre
 *
 * @method update
 * @param {any} alarm L'alarme
 * @returns {Object} L'alarme mise à jour
 */
function update(alarm: any) {
  function success(updatedAlarm: Alert) {
    return { type: alarmConstants.UPDATE_SUCCESS, updatedAlarm };
  }
  function failure(error: Object) {
    return { type: alarmConstants.UPDATE_FAILURE, error };
  }
  return (dispatch: Function) => {
    alarmService
      .update(alarm)
      .then((updatedAlarm: Alert) => {
        dispatch(success(updatedAlarm));
        dispatch(alertActions.success("Opération réussie"));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Actualise l'état de l'alarme (active/inactive)
 *
 * @method setActive
 * @param {any} alarm L'alarme
 * @returns {Object} L'alarme mise à jour
 */
function setActive(alarm: any) {
  function success(updatedAlarm: Alert) {
    return { type: alarmConstants.UPDATE_SUCCESS, updatedAlarm };
  }
  function failure(error: Object) {
    return { type: alarmConstants.UPDATE_FAILURE, error };
  }
  return (dispatch: Function) => {
    alarmService
      .setActive(alarm)
      .then((updatedAlarm: Alert) => {
        dispatch(success(updatedAlarm));
        dispatch(alertActions.success("Opération réussie"));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Supprime l'alarme passée en paramètre
 *
 * @method toDelete
 * @param {any} alarm L'alarme
 */
function toDelete(alarm: any) {
  const alarmId = cloneDeep(alarm).id;
  function success(deletedAlarm: Object) {
    return { type: alarmConstants.DELETE_SUCCESS, alarmId };
  }

  function failure(error: Object) {
    return { type: alarmConstants.DELETE_FAILURE, error };
  }

  return (dispatch: Function) => {
    alarmService
      .toDelete(alarm)
      .then((deletedAlarm: any) => {
        dispatch(success(deletedAlarm));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Vide le state des alarmes
 *
 * @method clear
 */
function clear() {
  return {
    type: alarmConstants.CLEAR,
  };
}

const alarmActions = {
  getAll,
  get,
  update,
  setActive,
  save,
  toDelete,
  clear,
  getAllTemplates,
  getAllType,
};

export default alarmActions;
