import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { supportActions } from "../_actions";
import LoadingBand from "../Bands/Loading";

class PortailAboSupport extends Component<any, {}> {
  render() {
    const { dispatch, support } = this.props;
    return (
      <div style={{ marginLeft: "20px", marginRight: "20px" }}>
        <Button onClick={() => dispatch(supportActions.refreshAboPortal())}>
          Rafraichir le portail abonné
        </Button>
        {support.refresh && <LoadingBand />}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { support } = state;
  return {
    support,
  };
}

const wt: any = connect(mapStateToProps)(PortailAboSupport);
export default withTranslation()(withRouter(wt));
