import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  TagDto,
  TemplateCreationFormType,
  TemplateDto,
  TemplatesState,
  TourneeCreationFormType,
} from "../_interfaces/TourneeCreationTypes";
import TemplateLineInput, { LINE_INPUT_LIMIT } from "./TemplateLineInput";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup } from "reactstrap";
import { Location } from "history";
import SvgInfoVert from "../SvgComponents/InformationVert";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const TemplateCreationPart: React.FC<
  {
    tags: TagDto[];
    disabled: boolean;
    dispatchFormChange: Dispatch<{
      type: keyof Omit<TemplateCreationFormType, "id">;
      text: string;
    }>;
    templateForm: TemplateCreationFormType;
    focusedLine: number;
    setFocusedLine: Dispatch<SetStateAction<number>>;
    isLoaded: boolean;
    rndTemplate: TemplatesState;
    submit: () => void;
    location: Location<{
      from?: string;
      template?: TemplateDto;
      form: {
        tourneeForm: TourneeCreationFormType;
        listMeter: {
          [meterId: number]: string;
        };
      };
    }>;
  } & WithTranslation
> = ({
  tags,
  disabled,
  dispatchFormChange,
  templateForm,
  t,
  focusedLine,
  setFocusedLine,
  isLoaded,
  rndTemplate,
  submit,
  location,
}) => {
  const [otherTemplateNames, setOtherTemplateNames] = useState<string[]>([]);

  const validate = () => {
    if (isTemplateNameTaken()) {
      return false;
    }
    return true;
  };

  const isTemplateNameTaken = () => {
    return otherTemplateNames
      .map((name) => name.toUpperCase())
      .includes(templateForm.name.toUpperCase());
  };

  useEffect(() => {
    if (rndTemplate.templates) {
      let newOtherTemplateNames = rndTemplate.templates.map(
        (template) => template.name
      );
      if (location.state?.template) {
        newOtherTemplateNames = newOtherTemplateNames.filter(
          (templateName) => location.state.template!.name !== templateName
        );
      }
      setOtherTemplateNames(newOtherTemplateNames);
    }
  }, [rndTemplate]);

  return (
    <div className="template-creation-container col-6">
      <AvForm validate={validate} onValidSubmit={submit} id="myForm">
        <div className="template-name-container" style={{ maxWidth: "500px" }}>
          <AvField
            type="text"
            name="templateName"
            id="inputName"
            className={isTemplateNameTaken() ? "is-invalid" : ""}
            disabled={disabled}
            placeholder={t("tournee_creation.input_label.template_name")}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              dispatchFormChange({ type: "name", text: e.target.value })
            }
            value={templateForm.name}
            validate={{
              required: {
                value: true,
                errorMessage: t(
                  "tournee_creation.input_error.empty_template_name"
                ),
              },
              maxLength: {
                value: 30,
                errorMessage: t(
                  "tournee_creation.input_error.template_name_too_long"
                ),
              },
            }}
          />
          <div
            className="invalid-feedback"
            style={{ display: isTemplateNameTaken() ? "block" : "none" }}
          >
            {t("tournee_creation.input_error.template_name_already_exists")}
          </div>
        </div>
        <div className="template-type-container">
          <div className="template-line-label">{t("all.text.type")}</div>
          <RadioGroup
            value={templateForm.type}
            name="radio-buttons-group"
            onChange={(e) => {
              dispatchFormChange({ type: "type", text: e.target.value });
            }}
          >
            <FormControlLabel
              control={<Radio />}
              disabled={disabled}
              label={t("all.text.subscriber")}
              value={"subscriber"}
            />
            <FormControlLabel
              control={<Radio />}
              disabled={disabled}
              label={t("all.meter.meter")}
              value={"meter"}
            />
          </RadioGroup>
        </div>
        <h3 className="template-creation-title">
          {t("tournee_creation.container_label.template_creation")}
        </h3>
        <div className="template-lines-container">
          <div className="template-lines-container">
            <TemplateLineInput
              tags={tags}
              value={templateForm.line1}
              focusedLine={focusedLine}
              lineNumber={1}
              setFocusedLine={setFocusedLine}
              dispatchFormChange={dispatchFormChange}
              isLoaded={isLoaded}
              disabled={disabled}
            />
            <TemplateLineInput
              tags={tags}
              value={templateForm.line2}
              focusedLine={focusedLine}
              lineNumber={2}
              setFocusedLine={setFocusedLine}
              dispatchFormChange={dispatchFormChange}
              isLoaded={isLoaded}
              disabled={disabled}
            />
            <TemplateLineInput
              tags={tags}
              value={templateForm.line3}
              focusedLine={focusedLine}
              lineNumber={3}
              setFocusedLine={setFocusedLine}
              dispatchFormChange={dispatchFormChange}
              isLoaded={isLoaded}
              disabled={disabled}
            />
            <TemplateLineInput
              tags={tags}
              value={templateForm.line4}
              focusedLine={focusedLine}
              lineNumber={4}
              setFocusedLine={setFocusedLine}
              dispatchFormChange={dispatchFormChange}
              isLoaded={isLoaded}
              disabled={disabled}
            />
            {templateForm.type === "subscriber" && (
              <TemplateLineInput
                tags={tags}
                value={templateForm.line5}
                focusedLine={focusedLine}
                lineNumber={5}
                setFocusedLine={setFocusedLine}
                dispatchFormChange={dispatchFormChange}
                isLoaded={isLoaded}
                disabled={disabled}
              />
            )}
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
          >
            <div
              className="round-disabled"
              style={{ marginLeft: "55px", marginRight: "15px" }}
            >
              <SvgInfoVert height="1.2em" style={{}} />
            </div>
            <div style={{ maxWidth: "400px" }}>
              {t("tournee_creation.input_label.information_on_default")}
            </div>
          </div>
          <FormGroup className="field-group">
            <div style={{ marginLeft: "100px" }}>
              <AvField
                type="text"
                name="defaultInfo"
                id="inputDefaultInfo"
                className="template-default-info-input"
                disabled={disabled}
                placeholder={t("tournee_creation.placeholder.default_info")}
                onChange={(newValue: BaseSyntheticEvent) => {
                  dispatchFormChange({
                    type: "defaultInfo",
                    text: newValue.target.value,
                  });
                }}
                value={templateForm.defaultInfo}
                validate={{
                  maxLength: {
                    value: 30,
                    errorMessage: t(
                      "tournee_creation.input_error.default_info_too_long"
                    ),
                  },
                }}
              />
              {templateForm.defaultInfo.length !== 0 && (
                <div
                  style={{
                    textAlign: "end",
                    maxWidth: "400px",
                    marginLeft: "100px",
                  }}
                >
                  {t(
                    `tournee_creation.input_info.remaining_characters${
                      LINE_INPUT_LIMIT - templateForm.defaultInfo.length >= 2
                        ? "_plural"
                        : ""
                    }`,
                    {
                      nbRemainingChar:
                        LINE_INPUT_LIMIT - templateForm.defaultInfo.length,
                    }
                  )}
                </div>
              )}
            </div>
          </FormGroup>
        </div>
      </AvForm>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { rndTemplate } = state;
  return {
    rndTemplate,
  };
}

const connectedTemplateCreationPage =
  connect(mapStateToProps)(TemplateCreationPart);
const tr = withTranslation()(connectedTemplateCreationPage);

export default tr;
