import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import DownloadArrow from "../SvgComponents/DownloadArrow";
import { TFunction } from "react-i18next";
import { Button } from "reactstrap";
import { importActions } from "../_actions";
import { Divider } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import { History } from "history";
import RoundButton from "../_components/RoundButton";
import _ from "lodash";
import SvgEye from "../SvgComponents/Eye";
import { match } from "react-router";

const ImportFactuTab: React.FC<{
  dispatch: Function;
  history: History;
  imports: any;
  match: match<{}>;
  t: TFunction<"translation", undefined>;
  setLoadingMessage: Dispatch<SetStateAction<string>>;
  setSuccessMessage: Dispatch<SetStateAction<string>>;
}> = ({
  dispatch,
  history,
  imports,
  match,
  t,
  setLoadingMessage,
  setSuccessMessage,
}) => {
  const [successData, setSuccessData] = useState<
    {
      name: string;
      code: string;
      mtrCount: number;
    }[]
  >([]);

  const columns = [
    {
      dataField: "name",
      text: t("all.text.name"),
    },
    {
      dataField: "code",
      text: t("all.column_text.code"),
    },
    {
      dataField: "mtrCount",
      text: t("tournee_stat.container_title.nb_meter"),
    },
    {
      dataField: "actions",
      text: "",
      formatter: (
        cell: any,
        row: {
          name: string;
          code: string;
          mtrCount: number;
        }
      ) => {
        return (
          <div className="template-action-cell">
            <RoundButton
              id={`template-${row.code}-edit`}
              onClick={() =>
                history.push(
                  `/gestionnaires/${_.get(
                    match,
                    "params.GestionnaireId"
                  )}/synchronisation/tournees/${row.code}`
                )
              }
              icon={<SvgEye height="1em" width="1em" />}
            />
          </div>
        );
      },
    },
  ];

  const launchImport = () => {
    setLoadingMessage(
      t("import_silex.loading_band.import_facturation_loading")
    );
    dispatch(importActions.importFromFacturation());
  };

  useEffect(() => {
    if (imports.importFromFactu) {
      setSuccessData(
        imports.importFromFactu.map((round) => ({
          name: round.line0.libelleTournee,
          code: round.line0.codeTournee,
          mtrCount: round.listAbonneLines.length,
        }))
      );
      console.log(
        t(
          imports.importFromFactu.length > 1
            ? "import_silex.success_msg.x_loaded_silex_file_plural"
            : "import_silex.success_msg.x_loaded_silex_file",
          { count: imports.importFromFactu.length }
        )
      );
      setSuccessMessage(
        t(
          imports.importFromFactu.length > 1
            ? "import_silex.success_msg.x_loaded_silex_file_plural"
            : "import_silex.success_msg.x_loaded_silex_file",
          { count: imports.importFromFactu.length }
        )
      );
    } else {
      setSuccessData([]);
    }
  }, [imports]);

  return (
    <>
      <DownloadArrow
        width="40px"
        fill="#ACABAB"
        className="dl-icon import-side-icon"
      />
      <h3 className="import-side-title">Import depuis la facturation</h3>
      <Button
        style={{ marginBottom: "50px" }}
        onClick={launchImport}
        disabled={imports.loading || imports.loading}
      >
        Lancer l'import
      </Button>
      {successData.length !== 0 && (
        <>
          <Divider className="template-divider" />
          <div className="template-table-container">
            <BootstrapTable
              keyField="code"
              bootstrap4
              bordered={false}
              hover
              data={successData}
              columns={columns}
              headerClasses="template-table-header"
            />
          </div>
        </>
      )}
    </>
  );
};

export default ImportFactuTab;
