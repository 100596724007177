import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

/**
 * Rend la page de 404
 *
 * @method NotFound
 */
class NotFound extends Component<WithTranslation> {
  render() {
    const { t } = this.props;
    return (
      <div className="container">
        <div className="row">
          <h1>{t("not_found.main_title.not_found")}</h1>
        </div>
      </div>
    );
  }
}

const tr = withTranslation()(NotFound);
export default tr;
