import { format } from "date-fns";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "reactstrap";
import SvgCroixNoir from "../SvgComponents/croixNoir";
import { WithTranslation, withTranslation } from "react-i18next";

const FileDropZone: React.FC<
  {
    acceptedFilesFormat: string | string[] | undefined; //MIME types file extension
    file: File | undefined;
    height?: string | number;
    setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  } & WithTranslation
> = ({ acceptedFilesFormat, file, height, setFile, t }) => {
  const onDrop = useCallback((acceptedFile: File[]) => {
    if (acceptedFile.length == 1) {
      console.log("acceptedFile", acceptedFile[0]);
      setFile(acceptedFile[0]);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    draggedFiles,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    noClick: true,
    accept: acceptedFilesFormat,
  });

  const style = {
    marginBottom: "30px",
    cursor:
      isDragActive && draggedFiles.length != 1 ? "not-allowed" : "pointer",
    height: height || "100%",
  };

  return (
    <>
      <div {...getRootProps({ id: "dropzone", className: "dropzone", style })}>
        <input {...getInputProps()} />
        <svg width="100%" height="100%" className="dropzone-svg">
          <rect
            x="1"
            y="1"
            fill={
              isDragActive
                ? draggedFiles.length == 1
                  ? "#28A74840"
                  : "#DC354540"
                : "none"
            }
            rx="16"
            width="99%"
            height="99%"
          />
          <foreignObject
            width="100%"
            height="100%"
            style={{
              color: !isDragActive
                ? "inherit"
                : draggedFiles.length == 1
                ? "#28A748"
                : "#DC3545",
            }}
          >
            <div className="foreign-object">
              {!isDragActive ? (
                file ? (
                  <div className="filename">
                    {file.name}
                    <SvgCroixNoir
                      className="close-icon"
                      onClick={() => setFile(undefined)}
                    />
                  </div>
                ) : (
                  <>
                    <Button
                      id="access-button"
                      className="col-right footer-content"
                      onClick={open}
                    >
                      {t("all.text.select_file")}
                    </Button>
                    {t("tournee_creation.dropzone.or_drop_file")}
                  </>
                )
              ) : draggedFiles.length == 1 ? (
                t("tournee_creation.dropzone.drop_autorized")
              ) : (
                t("tournee_creation.dropzone.drop_unautorized")
              )}
            </div>
          </foreignObject>
        </svg>
        {acceptedFilesFormat != null && (
          <i
            className="format-helper"
            style={{
              marginLeft: "16px",
              color: rejectedFiles.length > 0 ? "#DC3545" : "inherit",
              animationName: rejectedFiles.length > 0 ? "tilt-shaking" : "none",
            }}
          >
            {rejectedFiles.length > 0
              ? t("tournee_creation.dropzone.incorrect_format", {
                  fileformats:
                    typeof acceptedFilesFormat === "string"
                      ? acceptedFilesFormat
                      : acceptedFilesFormat.join(", "),
                })
              : t("tournee_creation.dropzone.authorized_format", {
                  fileformats:
                    typeof acceptedFilesFormat === "string"
                      ? acceptedFilesFormat
                      : acceptedFilesFormat.join(", "),
                })}
          </i>
        )}
      </div>
    </>
  );
};

const tr = withTranslation()(FileDropZone);
export default tr;
