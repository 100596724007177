import React, { Component } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";

interface Props {
  handleRefWitnessMeters: Function;
  pdis?: Array<any>;
  alarmTemplates: any;
  t: Function;
  listMeters: any;
}
class RefWitnessSelector extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    const indexRef =
      props.alarmTemplates.content.dataSourceProperty.condition.findIndex(
        (el: any) => el.conditionTitle === "MeterSerialNumberRef"
      );
    const indexWit =
      props.alarmTemplates.content.dataSourceProperty.condition.findIndex(
        (el: any) => el.conditionTitle === "MeterSerialNumber_1"
      );
    this.state = {
      meters: {
        ref:
          props.alarmTemplates.content.dataSourceProperty.condition[indexRef]
            .conditionValue || "",
        witness:
          props.alarmTemplates.content.dataSourceProperty.condition[indexWit]
            .conditionValue || "",
      },
    };
  }

  handleChange = (value: any, type: any) => {
    const { handleRefWitnessMeters } = this.props;
    const { meters } = this.state;
    const newMeters = meters;
    if (type === "ref") {
      newMeters.ref = value;
    } else {
      newMeters.witness = value;
    }
    this.setState({
      meters: newMeters,
    });
    handleRefWitnessMeters(newMeters);
  };

  render() {
    const { listMeters, t } = this.props;
    const { meters } = this.state;
    const defaultProps = {
      options: listMeters,
    };
    return (
      <div className="ref-witness-selector wrapper">
        <h5>{t("all.meter.meter_serial_ref")}</h5>
        <Autocomplete
          {...defaultProps}
          id="debug"
          debug
          defaultValue={meters.ref}
          onChange={(e, value) => {
            this.handleChange(value, "ref");
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              label={t("all.meter.meter_ref")}
              margin="normal"
            />
          )}
        />
        <h5 className="wrapper">{t("all.meter.meter_serial_control")}</h5>
        <Autocomplete
          {...defaultProps}
          id="debug"
          debug
          defaultValue={meters.witness}
          onChange={(e, value) => this.handleChange(value, "wit")}
          renderInput={(params: any) => (
            <TextField
              {...params}
              label={t("all.meter.control_meter")}
              margin="normal"
            />
          )}
        />
      </div>
    );
  }
}

const tr = withTranslation()(RefWitnessSelector);
export default tr;
