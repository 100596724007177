import dashboardConstants from "../_constants/dashboard.constants";
import { action } from "../_interfaces/action";

export default function dashboards(state = {}, action: action) {
  switch (action.type) {
    case dashboardConstants.GET_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.CLEAR:
      return {};
    case dashboardConstants.GET_SUCCESS:
      return {
        dashboard: action.dashboard,
      };
    case dashboardConstants.GET_FAILURE:
      return {
        error: "Impossible de récupérer les informations du tableau de bord",
      };
    case dashboardConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.UPDATE_SUCCESS:
      return {
        dashboard: action.updateddashboard,
        items: Object.assign(
          action.dashboards,
          action.dashboards.map((el) =>
            el.id === action.updateddashboard.id ? action.updateddashboard : el
          )
        ),
      };
    case dashboardConstants.UPDATE_FAILURE:
      return {
        error: "Impossible de sauvegarder les informations du tableau de bord",
      };
    case dashboardConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.GETALL_SUCCESS:
      return {
        items: action.dashboards,
      };
    case dashboardConstants.GETALL_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des tableaux de bord",
      };
    case dashboardConstants.SAVE_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.SAVE_SUCCESS:
      return {
        items: action.dashboards.concat(action.savedDashboard),
      };
    case dashboardConstants.SAVE_FAILURE:
      return {
        error: "Impossible de sauvegarder les informations du tableau de bord",
      };
    case dashboardConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.DELETE_SUCCESS:
      return {
        items: action.dashboards.filter(
          (dashboard) => dashboard.id !== action.deletedDashBoard.id
        ),
      };
    case dashboardConstants.DELETE_FAILURE:
      return {
        error: "La suppression du dashboard a échouée",
      };
    default:
      return state;
  }
}
