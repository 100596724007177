import { notificationConstants } from "../_constants";
import notificationService from "../_services/notification.service";
import { Notification } from "../_entities/notification";

/**
 * Récupère toutes les notifications de l'utilisateur
 * courant
 *
 * @method getAll
 * @param userId Id utilisateur
 * @returns {Object} Notifications
 */
function getAll(userId: number) {
  function request() {
    return { type: notificationConstants.GETALL_REQUEST };
  }
  function success(notifications: any) {
    return { type: notificationConstants.GETALL_SUCCESS, notifications };
  }
  function failure(error: Object) {
    return { type: notificationConstants.GETALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    notificationService
      .getAll(userId)
      .then((notifications: any) => dispatch(success(notifications)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupèr les 3 dernières notifications de l'utilisateur
 * courant
 *
 * @param {number} userId Id utilisateur
 * @returns {Object} Dernières notifications
 */
function getLast(userId: number) {
  function request() {
    return { type: notificationConstants.GETLAST_REQUEST };
  }
  function success(notifications: Object) {
    return { type: notificationConstants.GETLAST_SUCCESS, notifications };
  }
  function failure(error: Object) {
    return { type: notificationConstants.GETLAST_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    notificationService
      .getLast(userId)
      .then((notifications: Object) => dispatch(success(notifications)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Retourne le compteur de notifications non lues
 * de l'utilisateur courant
 *
 * @method getCount
 * @param {number} userId Id utilisateur
 * @returns {Object} Compteur de notifs
 */
function getCount(userId: number) {
  function success(count: number) {
    return { type: notificationConstants.GETCOUNT_SUCCESS, count };
  }
  function failure(error: Object) {
    return { type: notificationConstants.GETCOUNT_FAILURE, error };
  }
  return (dispatch: Function) => {
    notificationService
      .getCount(userId)
      .then((count: number) => dispatch(success(count)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère la notification dont l'id est passé
 * en paramètre
 *
 * @method get
 * @param id Id notification
 * @returns {Object} La notification
 */
function get(id: number) {
  function request() {
    return { type: notificationConstants.GET_REQUEST };
  }
  function success(fetchedNotification: Object) {
    return { type: notificationConstants.GET_SUCCESS, fetchedNotification };
  }
  function failure(error: Object) {
    return { type: notificationConstants.GET_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    notificationService
      .get(id)
      .then((fetchedNotification: Object) => {
        dispatch(success(fetchedNotification));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Met à jour la notification passée en paramètre
 *
 * @method update
 * @param body Notification
 * @returns {Object} L'état de la mise à jour
 */
function update(body: any) {
  function success(fetchedNotification: Notification) {
    return { type: notificationConstants.UPDATE_SUCCESS, fetchedNotification };
  }
  function failure(error: Object) {
    return { type: notificationConstants.UPDATE_FAILURE, error };
  }
  return (dispatch: Function) => {
    notificationService
      .update(body)
      .then((fetchedNotification: Notification) => {
        dispatch(success(fetchedNotification));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Met à jour l'état de lecture de toutes les notification
 * (si besoin)
 *
 * @method readAll
 * @param {Array<any>} notifs Notifications
 * @returns {Object} Les notifications
 */
function readAll(notifs: any[]) {
  function success(notifications: Notification[]) {
    return { type: notificationConstants.READ_ALL_SUCCESS, notifications };
  }
  function failure(error: Object) {
    return { type: notificationConstants.READ_ALL_FAILURE, error };
  }

  return (dispatch: Function) => {
    let promises: any[] = [];
    notifs.forEach((it) => {
      promises.push(notificationService.update(it));
    });

    Promise.all(promises)
      .then((updatedNotifs) => {
        dispatch(success(updatedNotifs));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Supprime la notification dont l'id est passé
 * en paramètre
 *
 * @method toDelete
 * @param {number} id Id notification
 * @returns L'état de la suppression
 */
function toDelete(id: any) {
  function success() {
    return { type: notificationConstants.DELETE_SUCCESS, notifId: id };
  }
  function failure(error: Object) {
    return { type: notificationConstants.DELETE_FAILURE, error };
  }
  return (dispatch: Function) => {
    notificationService
      .toDelete(id)
      .then(() => {
        dispatch(success());
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Supprime les notifications dont les ids sont passés
 * en paramètre
 *
 * @method toDelete
 * @param {Array<number>} ids Ids notification
 * @returns L'état de la suppression
 */
function deleteMulti(ids: number[]) {
  function success() {
    return { type: notificationConstants.DELETE_MULTI_SUCCESS, notifIds: ids };
  }
  function failure(error: Object) {
    return { type: notificationConstants.DELETE_MULTI_FAILURE, error };
  }
  return (dispatch: Function) => {
    let promises: any[] = [];

    ids.forEach((id) => {
      promises.push(notificationService.toDelete(id));
    });

    Promise.all(promises)
      .then(() => {
        dispatch(success());
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Vide le state des notifications
 *
 * @method clear
 */
function clear() {
  return {
    type: notificationConstants.CLEAR,
  };
}

const notificationActions = {
  getAll,
  getLast,
  getCount,
  get,
  update,
  readAll,
  toDelete,
  deleteMulti,
  clear,
};

export default notificationActions;
