import _ from "lodash";
import pictureConstants from "../_constants/picture.constants";

export default function picture(state: any = { paths: {} }, action: any) {
  switch (action.type) {
    case pictureConstants.GET_ALL_PIC_PATH_REQUEST:
      return {
        ...state,
        loading: true,
        init: true,
      };
    case pictureConstants.GET_ALL_PIC_PATH_SUCCESS:
      const paths = _.merge(state.paths, action.pathList);
      return {
        ...state,
        loading: false,
        paths,
      };
    case pictureConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
