import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import sensorActions from "../_actions/sensor.actions";
import SvgFrequence from "../SvgComponents/Frequence";
import _ from "lodash";

const Frequence = (props: any) => {
  const { dispatch, t } = props;
  const [vOpen, setvOpen] = useState({
    openFrequencyValue: "1",
    openFrequencyUnit: "M",
  });
  const [vClose, setvClose] = useState({
    closeFrequencyValue: "1",
    closeFrequencyUnit: "M",
  });

  const handleSubmit = () => {
    dispatch(
      sensorActions.putUpLinkFrequency(1, {
        openFrequencyValue: vOpen.openFrequencyValue,
        openFrequencyUnit: vOpen.openFrequencyUnit,
        closeFrequencyValue: vClose.closeFrequencyValue,
        closeFrequencyUnit: vClose.closeFrequencyUnit,
      })
    );
  };
  return (
    <div
      className="col-md-12 downlink-container tele-releve-container"
      id="tab-container"
      style={{ width: "100%" }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="tele-releve-header flex-box">
          <SvgFrequence fill="#31c6b3" height="2em" />
          <h2 style={{ marginLeft: "20px" }}>{t("sensor.title.frequency")}</h2>
        </div>
        <div className="frequence-container">
          <span className="size-15 no-flex">
            {t("sensor.text.when_vanne_open")}
          </span>
          <input
            className="input-number"
            type="number"
            id="tentacles"
            name="tentacles"
            min="0"
            max="60"
            onChange={(e) =>
              setvOpen({
                openFrequencyValue: e.target.value,
                openFrequencyUnit: vOpen.openFrequencyUnit,
              })
            }
            defaultValue="1"
          />
          <Input
            type="select"
            name="select"
            id="exampleSelect"
            onChange={(e) =>
              setvOpen({
                openFrequencyValue: vOpen.openFrequencyValue,
                openFrequencyUnit: e.target.value,
              })
            }
          >
            <option value="M">{_.capitalize(t("sensor.text.minutes"))}</option>
            <option value="H">{_.capitalize(t("sensor.text.hours"))}</option>
          </Input>
        </div>
        <div className="frequence-container">
          <span className="size-15 no-flex">
            {t("sensor.text.when_vanne_close")}
          </span>
          <input
            className="input-number"
            type="number"
            id="tentacles"
            name="tentacles"
            min="0"
            max="60"
            onChange={(e) =>
              setvClose({
                closeFrequencyValue: e.target.value,
                closeFrequencyUnit: vClose.closeFrequencyUnit,
              })
            }
            defaultValue="1"
          />
          <Input
            type="select"
            name="select"
            id="exampleSelect"
            onChange={(e) =>
              setvClose({
                closeFrequencyValue: vClose.closeFrequencyValue,
                closeFrequencyUnit: e.target.value,
              })
            }
          >
            <option value="M">{_.capitalize(t("sensor.text.minutes"))}</option>
            <option value="H">{_.capitalize(t("sensor.text.hours"))}</option>
          </Input>
        </div>
        <div
          className="button-container"
          style={{
            marginRight: "15px",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Button color="primary" onClick={() => handleSubmit()}>
            {t("all.button.validate")}
          </Button>{" "}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { sensor } = state;
  return {
    sensor,
  };
}

const mapping: any = connect(mapStateToProps)(Frequence);
const connectedUser = withRouter(mapping);
export default withTranslation()(connectedUser);
