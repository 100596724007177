import React, { Component } from "react";
import { Button, CustomInput, FormGroup, Input, Label, Row } from "reactstrap";
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import ImportBleu from "../SvgComponents/ImportBleu";
import { importActions, localeActions } from "../_actions";
import LoadingBand from "../Bands/Loading";
import SuccessBand from "../Bands/Success";
import ErrorBand from "../Bands/Error";
import _ from "lodash";

type Props = {
  title: String;
  user: any;
  history: any;
  dispatch: Function;
  imports: any;
  locales: any;
  match: any;
} & WithTranslation;

export class ImportLoraCompenent extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      file: null,
      radioSerialColumnIndex: -1,
      devEUIColumnIndex: -1,
    };
    this.onChange = this.onChange.bind(this);
    this.invalidFile = this.invalidFile.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(localeActions.load());
  }

  onChange(e: any) {
    const { dispatch } = this.props;
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    this.setState({ file: formData });
    dispatch(importActions.clear());
  }

  invalidFile() {
    const { dispatch } = this.props;
    dispatch(importActions.clear());
    this.setState({ file: null });
  }

  submitFile = () => {
    const { dispatch } = this.props;
    const {
      file,
      radioSerialColumnIndex,
      devEUIColumnIndex,
      appEUIColumnIndex,
      loraSerialColumnIndex,
      appKeyColumnIndex,
    } = this.state;
    dispatch(
      importActions.importLoraFile(
        file,
        radioSerialColumnIndex,
        devEUIColumnIndex,
        loraSerialColumnIndex,
        appEUIColumnIndex,
        appKeyColumnIndex
      )
    );
  };

  chooseFileDone = () => {
    const { file } = this.state;
    const { imports } = this.props;
    return file !== null && file && imports && !imports.running;
  };

  changeDisplay = (key: string, context: string) => {
    const state: any = {};
    state[key + context] = this.state[key + context]
      ? !this.state[key + context]
      : true;
    this.setState({ ...state });
  };

  isDioptase = () => {
    const { user } = this.props;
    return user && _.get(user, "role.name") === "DIOPTASE";
  };

  render() {
    const { file } = this.state;
    const { imports, t } = this.props;
    const extension = ".csv,.txt";

    return (
      <div className="col-12">
        <div className="table-info-container">
          <h2>
            <span>
              <ImportBleu height="1em" width="1em" fill="#31c6b3" />
            </span>
            {t("loraimport.text.import_lora")}
          </h2>
          {!imports.loading && !imports.loraLinks ? (
            <FormGroup style={{ width: "25%" }}>
              <Label for="dataFile">Fichier LoRa {`(${extension})`}</Label>
              <CustomInput
                type="file"
                id="import-silex-button"
                onChange={this.onChange}
                accept={extension}
                name="customFile"
                label={file ? file.get("file").name : "Sélectionner un fichier"}
              />
            </FormGroup>
          ) : (
            <div style={{ display: "flex" }}>
              <Button color="danger" onClick={this.invalidFile}>
                {t("all.button.change_file")}
              </Button>
              <p
                style={{
                  padding: "auto",
                  fontWeight: "bold",
                  marginBottom: "0",
                  alignSelf: "center",
                  marginLeft: "5px",
                }}
              >
                {file && file.get("file").name}
              </p>
            </div>
          )}
          {this.isDioptase() && (
            <Row>
              <FormGroup style={{ marginLeft: "20px", marginRight: "50px" }}>
                <Label for="radioSerialIndex">
                  Index numéro de série radio
                </Label>
                <Input
                  type="number"
                  name="radioSerialIndex"
                  id="radioSerialIndex"
                  onChange={(e) =>
                    this.setState({
                      radioSerialColumnIndex: e.target.valueAsNumber,
                    })
                  }
                />
              </FormGroup>

              <FormGroup style={{ marginLeft: "20px", marginRight: "50px" }}>
                <Label for="devEUIIndex">Index devEUI</Label>
                <Input
                  type="number"
                  name="devEUIIndex"
                  id="devEUIIndex"
                  onChange={(e) =>
                    this.setState({ devEUIColumnIndex: e.target.valueAsNumber })
                  }
                />
              </FormGroup>

              <FormGroup style={{ marginLeft: "20px", marginRight: "50px" }}>
                <Label for="loraSerialColumnIndex">
                  Index Numéro de série LoRa
                </Label>
                <Input
                  type="number"
                  name="loraSerialColumnIndex"
                  id="loraSerialColumnIndex"
                  onChange={(e) =>
                    this.setState({
                      loraSerialColumnIndex: e.target.valueAsNumber,
                    })
                  }
                />
              </FormGroup>

              <FormGroup style={{ marginLeft: "20px", marginRight: "50px" }}>
                <Label for="appKeyColumnIndex">Index appKey</Label>
                <Input
                  type="number"
                  name="appKeyColumnIndex"
                  id="appKeyColumnIndex"
                  onChange={(e) =>
                    this.setState({ appKeyColumnIndex: e.target.valueAsNumber })
                  }
                />
              </FormGroup>

              <FormGroup style={{ marginLeft: "20px", marginRight: "50px" }}>
                <Label for="devEUIIndex">Index appEUI</Label>
                <Input
                  type="number"
                  name="appEUIColumnIndex"
                  id="appEUIColumnIndex"
                  onChange={(e) =>
                    this.setState({ appEUIColumnIndex: e.target.valueAsNumber })
                  }
                />
              </FormGroup>
            </Row>
          )}

          <div style={{ marginBottom: 20 }}> </div>

          {imports && imports.error && (
            <ErrorBand
              message={
                <>
                  <p>
                    {t("loraimport.text.import_file_failed", {
                      filename: file.get("file").name,
                    })}
                  </p>
                </>
              }
            />
          )}

          {this.chooseFileDone() && (
            <div>
              <h3>{t("all.text.submit_file")}</h3>
              <div style={{ display: "flex" }}>
                {file && imports && imports.loading ? (
                  <div style={{ marginTop: "10px" }}>
                    <LoadingBand
                      message={t("loraimport.text.import_lorafile_processing")}
                    />
                  </div>
                ) : (
                  <Row>
                    <Button
                      disabled={imports && imports && imports.loading}
                      color="primary"
                      style={{ marginTop: 10, marginLeft: 20 }}
                      onClick={this.submitFile}
                    >
                      {t("all.button.submit")}
                    </Button>
                  </Row>
                )}
              </div>
              <div style={{ marginBottom: 20 }}> </div>
              {imports && imports.loraLinks && (
                <SuccessBand
                  message={t("loraimport.text.lorafile_process_success")}
                />
              )}
            </div>
          )}
          {imports && imports.loraLinks && (
            <div>
              <p>
                {t("loraimport.text.nb_link_in_file")} :{" "}
                {imports.loraLinks.nbLinks}
              </p>
              <p>
                {t("loraimport.text.nb_new_link_in_file")} :{" "}
                {imports.loraLinks.listRadioInsert}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, imports } = state;
  const { user } = authentication;
  return {
    user,
    imports,
  };
}

const connectedStockImportation = connect(mapStateToProps)(ImportLoraCompenent);
export default withTranslation()(connectedStockImportation);
