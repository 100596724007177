import { mapConstants } from "../_constants";

export default function users(state: any = { count: 0 }, action: any) {
  switch (action.type) {
    case mapConstants.FILL_MARKERS:
      return {
        ...state,
        markers: action.listMarker,
        count: state.count + 1,
      };
    case mapConstants.FILL_GATEWAY:
      return {
        ...state,
        gateways: action.gateways,
      };
    case mapConstants.CLEAR_MARKERS:
      return {
        ...state,
        markers: null,
      };
    case mapConstants.SELECTED_MARKER:
      const index = state.markers.findIndex((item) => item.id === action.id);
      state.markers[index].icon = `${state.markers[index].icon}_selected`;
      return {
        ...state,
        updateM: !state.update || false,
      };
    default:
      return state;
  }
}
