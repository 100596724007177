import React, { Component, ComponentType, useRef } from "react";
import Select from "react-select";
import { Badge, Col, Input, Label, Row, UncontrolledTooltip } from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import { FaCheck, FaFilter, FaTimes } from "react-icons/fa";
import { Filter, Operator, Rule } from "../_interfaces/filter";
import PoubelleBleue from "../SvgComponents/PoubelleBleu";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import getC from "../TourneeFiches/Picto_correspondance";
import moment from "moment";
import { compose } from "redux";
import { InputType } from "reactstrap/es/Input";

interface Props {
  changeLineFilter: Function;
  removeLine: Function;
  listOperator: {
    operators: any;
  };
  listFilters: Array<Filter>;
  values: Rule;
  t: Function;
  match: any;
  filterSelected: any;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

class LineFilter extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      inputValue: "",
      value: [],
      secondValue: [],
      init: false,
    };
  }
  static nameField = {
    filter: 0,
    operator: 1,
    userInput: 2,
  };

  static getDerivedStateFromProps(props: Props, state: any) {
    let copyState = _.cloneDeep(state);
    if (props.values && copyState.init !== props.filterSelected) {
      copyState.init = props.filterSelected;
      copyState.value =
        props.values.filter &&
        props.values.filter.type === "text" &&
        !props.values.operator.noEntry &&
        !Array.isArray(props.values.userInput)
          ? ((props.values.userInput as string) || "")
              .split("|")
              .map((el) => ({ label: el, value: el }))
              .filter((el) => !_.isEmpty(el.value))
          : props.values.userInput;
    }
    return copyState;
  }

  /**
   * Remise à zero de la ligne quand le nom du filtre change
   *
   * @event onChange
   * @method filterChange
   * @memberof LineFilter
   * @param {Filter} filter Nouveau filtre de la ligne
   */
  filterChange = (filter: Filter) => {
    const { changeLineFilter, values, listOperator, t } = this.props;
    const resetValues = {
      filter: (filter && filter.label ? filter : {}) as Partial<Filter>,
      operator: (filter.type === "checkbox"
        ? listOperator.operators[filter.type][filter.value]
        : listOperator.operators[filter.type])[0],
      userInput: null,
      filterApplied: false,
    };
    resetValues.operator.label = t(
      `linefilter.operator.${
        _.get(filter, "value") === "state" ? "state." : ""
      }${resetValues.filter.type.toLowerCase()}.${resetValues.operator.value.toLowerCase()}`
    );
    const changedValues = Object.assign(cloneDeep(values), resetValues);
    changeLineFilter(values.num, changedValues);
    this.setState({ value: [] });
  };

  /**
   * Remise à zero de la valeur saisie quand l'operateur change
   *
   * @event onChange
   * @method operatorChange
   * @memberof LineFilter
   * @param {Operator} operator Operateur à appliquer sur la ligne
   */
  operatorChange = (operator: Operator) => {
    const { changeLineFilter, values } = this.props;
    const resetValues = {
      operator,
      filterApplied: false,
    };
    const changedValues = Object.assign(cloneDeep(values), resetValues);
    changeLineFilter(values.num, changedValues);
  };

  /**
   * Changement du statut de la ligne (-> plus appliqué)
   *
   * @event onChange
   * @method userInputChange
   * @memberof LineFilter
   * @param {string} userInput Valeur saisie par l'utilisateur
   */
  userInputChange = (userInput: any) => {
    const { changeLineFilter, values } = this.props;
    const newUserInput = !Array.isArray(userInput)
      ? (userInput && userInput.target && userInput.target.value) || userInput
      : userInput;
    const newSecondInputChange =
      values.operator.value == "between" &&
      values.secondInputChange &&
      values.secondInputChange.localeCompare(newUserInput) === 1
        ? values.secondInputChange
        : "";
    const resetValues = {
      userInput: newUserInput,
      filterApplied: false,
      secondInputChange: newSecondInputChange,
    };
    const changedValues = Object.assign(cloneDeep(values), resetValues);
    changeLineFilter(values.num, changedValues);
    this.setState({ value: userInput, secondValue: [] });
  };

  secondInputChange = (userInput: any) => {
    const { changeLineFilter, values } = this.props;
    const resetValues = {
      secondInputChange: !Array.isArray(userInput)
        ? (userInput && userInput.target && userInput.target.value) || userInput
        : userInput,
      filterApplied: false,
    };
    const changedValues = Object.assign(cloneDeep(values), resetValues);
    changeLineFilter(values.num, changedValues);
    this.setState({ secondValue: userInput });
  };

  definitionTimeChange(check: boolean) {
    const { changeLineFilter, values } = this.props;
    const changedValues = { ...values, defineLater: check };
    changeLineFilter(values.num, changedValues);
    this.setState({ defineLater: check });
  }

  /**
   * Récupère un badge correspondant au statut de la ligne (incorrect -> correct -> appliqué)
   *
   * @method getBadgeOfBeginningLine
   * @memberof LineFilter
   * @returns Représentation du badge du début de ligne
   */
  getBadgeOfBeginningLine = () => {
    const { values } = this.props;
    const { filter, operator, userInput, secondInputChange, defineLater } =
      values;
    const filterGood: boolean | undefined =
      filter &&
      operator &&
      ((userInput &&
        userInput.toString().length > 0 &&
        (operator.value != "between" ||
          (secondInputChange && secondInputChange.toString().length > 0))) ||
        operator.noEntry ||
        defineLater);

    return values.filterApplied ? (
      <Badge
        color="info"
        pill
        style={{
          display: "flex",
          alignItems: "center",
          height: "40px",
        }}
      >
        <FaFilter />
      </Badge>
    ) : (
      <Badge
        color={filterGood ? "success" : "danger"}
        pill
        style={{
          display: "flex",
          alignItems: "center",
          height: "40px",
        }}
      >
        {filterGood ? <FaCheck /> : <FaTimes />}
      </Badge>
    );
  };

  handleInputChange = (inputValue: string) => {
    this.setState({ inputValue });
  };

  handleKeyDown = (event: any) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
        const newValue = value || [];
        newValue.push(createOption(inputValue));
        this.userInputChange(newValue);
        this.setState({
          inputValue: "",
          value: newValue,
        });
        event.preventDefault();
      default:
    }
  };

  onBlur = () => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    const newValue = value || [];
    newValue.push(createOption(inputValue));
    this.userInputChange(newValue);
    this.setState({
      inputValue: "",
      value: newValue,
    });
  };

  getMinMaxDate = (input: string | number, operator: "+" | "-") => {
    const date = new Date(input);
    switch (operator) {
      case "+":
        date.setDate(date.getDate() + 1);
        break;
      case "-":
        date.setDate(date.getDate() - 1);
        break;
    }
    return moment(date).format("yyyy-MM-DD");
  };

  render() {
    const {
      values,
      removeLine,
      listOperator,
      listFilters,
      t,
      match: { url },
    } = this.props;
    const { userInput, secondInputChange, filter, operator, defineLater } =
      values;
    const { inputValue, value } = this.state;
    const typeInput = filter ? filter.type : "";
    const optionsFields: Array<Filter> = listFilters;
    const components = {
      DropdownIndicator: null,
    };
    const optionsOperators: Array<Operator> = (
      (typeInput === "checkbox"
        ? listOperator.operators[typeInput][filter.value]
        : listOperator.operators[typeInput]) || []
    )
      .filter((el) => {
        return (
          typeInput !== "date" ||
          filter.value === "read.date" ||
          el.value !== "between"
        );
      })
      .map((el) => ({
        ...el,
        label: t(
          `linefilter.operator.${
            _.get(filter, "value") === "state" ? "state." : ""
          }${typeInput.toLowerCase()}.${el.value.toLowerCase()}`
        ),
      }));
    let convertedValue: any = _.cloneDeep(userInput);
    if (_.get(filter, "value") === "ficheState" && convertedValue) {
      convertedValue.label = _.get(convertedValue, "value") && (
        <div>
          {getC(convertedValue.value, { height: "1.5em", width: "1.5em" })}
          {t(`releve_meter_status.text.${convertedValue.value}`)}
        </div>
      );
    }
    return (
      <Row
        style={{
          margin: 10,
          minHeight: url.includes("customexport") ? "60px" : "",
        }}
      >
        {this.getBadgeOfBeginningLine()}
        <Col xs="4" sm="4" md="4">
          <Select
            options={
              optionsFields.filter(({ value }) => !/mask/i.test(value)) as any
            }
            placeholder={t("all.filter.filter")}
            id={`filter${values.num}`}
            onChange={this.filterChange as any}
            value={filter}
            isSearchable
          />
        </Col>
        <Col xs="3" sm="3" md="3">
          {typeInput && (
            <Select
              options={optionsOperators}
              placeholder={t("all.text.operator")}
              id={`operator${values.num}`}
              onChange={this.operatorChange as any}
              value={operator}
            />
          )}
        </Col>
        {operator && !operator.noEntry ? (
          <Col xs="3" sm="3" md="3">
            {typeInput &&
              operator &&
              !operator.noEntry &&
              (typeInput.length > 0 &&
              [
                "select",
                "selectCont",
                "selectContValue",
                "selectAlarmTypeBasic",
              ].includes(typeInput) ? (
                <Select
                  options={filter.opts}
                  onChange={this.userInputChange}
                  id={`userInput${values.num}`}
                  value={convertedValue as any}
                  disabled={defineLater}
                />
              ) : operator.value == "between" ? (
                <div style={{ display: "flex", gap: 16 }}>
                  <Input
                    type="text"
                    name="filterSaisie"
                    id={`userInput${values.num}`}
                    placeholder={t("all.text.start_date")}
                    onChange={this.userInputChange}
                    value={userInput as string}
                    disabled={defineLater}
                    max={this.getMinMaxDate(Date.now(), "-")}
                    onFocus={(e) => {
                      if (
                        navigator.userActivation &&
                        navigator.userActivation.isActive
                      ) {
                        e.target.type = "date";
                        e.target.showPicker();
                      }
                    }}
                    onClick={(e: any) => {
                      if (!navigator.userActivation) {
                        e.target.type = "date";
                        e.target.showPicker();
                      }
                    }}
                    onBlur={(e) =>
                      !userInput ? (e.target.type = "text") : null
                    }
                  />
                  <Input
                    type="text"
                    name="filterSaisie"
                    id={`secondUserInput${values.num}`}
                    placeholder={t("all.text.end_date")}
                    onChange={this.secondInputChange}
                    value={secondInputChange}
                    disabled={defineLater}
                    min={this.getMinMaxDate(userInput as string, "+")}
                    max={moment(Date.now()).format("yyyy-MM-DD")}
                    onFocus={(e) => {
                      if (
                        navigator.userActivation &&
                        navigator.userActivation.isActive
                      ) {
                        e.target.type = "date";
                        e.target.showPicker();
                      }
                    }}
                    onClick={(e: any) => {
                      if (!navigator.userActivation) {
                        e.target.type = "date";
                        e.target.showPicker();
                      }
                    }}
                    onBlur={(e) =>
                      !secondInputChange ? (e.target.type = "text") : null
                    }
                  />
                </div>
              ) : typeInput === "text" ? (
                <CreatableSelect
                  components={components}
                  name="filterSaisie"
                  id={`userInput${values.num}`}
                  placeholder={t("linefilter.userinput.placeholder_input")}
                  onChange={this.userInputChange}
                  value={value}
                  isMulti
                  isClearable
                  menuIsOpen={false}
                  onInputChange={this.handleInputChange}
                  onKeyDown={this.handleKeyDown}
                  onBlur={this.onBlur}
                  inputValue={inputValue}
                  isDisabled={defineLater}
                />
              ) : (
                !(
                  typeInput === "select" ||
                  typeInput === "selectCont" ||
                  typeInput === "checkbox"
                ) && (
                  <Input
                    type={typeInput}
                    name="filterSaisie"
                    id={`userInput${values.num}`}
                    placeholder=""
                    onChange={this.userInputChange}
                    value={userInput as string}
                    disabled={defineLater}
                  />
                )
              ))}

            <Label
              style={{
                marginLeft: "20px",
                visibility: url.includes("customexport") ? "visible" : "hidden",
              }}
              check
            >
              <Input
                onChange={(e) => this.definitionTimeChange(e.target.checked)}
                type="checkbox"
                checked={defineLater}
              />{" "}
              {t("custom_export.text.define_at_launch")}
            </Label>
          </Col>
        ) : (
          <Col xs="3" sm="3" md="3" />
        )}
        <Col xs="1" sm="1" md="1">
          <div
            id={`removeLine${values.num}`}
            className="clickable round"
            role="presentation"
            onClick={() => removeLine(values.num)}
          >
            {" "}
            <PoubelleBleue height="1em" width="1em" />
            <UncontrolledTooltip
              placement="bottom"
              target={`removeLine${values.num}`}
            >
              {" "}
              {t("all.filter.delete_filter")}
            </UncontrolledTooltip>
          </div>
        </Col>
      </Row>
    );
  }
}

const connectedLineFilter = compose<ComponentType<any>>(withRouter)(LineFilter);
export default withTranslation()(connectedLineFilter);
