import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import { animateScroll } from "react-scroll";
import { cloneDeep, isEqual } from "lodash";
import EditDashBoard from "../DashBoards/EditDashBoard";
import {
  alertActions,
  bookmarkActions,
  colorActions,
  dashboardActions,
  localeActions,
  locationActions,
  meterActions,
  templateActions,
  vmeterActions,
  widgetActions,
} from "../_actions";

import AddBar from "./AddWidget";
import { BaseWidget, WidgetCreator } from "../Widget";
import confirm from "../_components/index";
import { formatDashboardName } from "../_helpers";
import AjoutBleu from "../SvgComponents/AjoutBleu";
import EditionBleu from "../SvgComponents/EditionBleu";
import EtoileFavoris from "../SvgComponents/FavorisVert";
import FavoriActif from "../SvgComponents/FavoriActif";

import { Template } from "../_entities/template";
import { dashboard } from "../_entities/dashboard";
import { Widget } from "../_entities/widget";
import { User } from "../_entities/user";
import {
  bookmarks,
  dashboards,
  locales,
  locations,
  templates,
} from "../_interfaces/reducers";
import ErrorBand from "../Bands/Error";
import SvgPleinEcranVert from "../SvgComponents/PleinEcranVert";
import SvgPetitEcranVert from "../SvgComponents/PetitEcranVert";
import userActions from "../_actions/user.actions";
import Loading from "../_animations/Loading";
import { withTranslation } from "react-i18next";
import _ from "lodash";

const ResponsiveGridLayout: any = WidthProvider(Responsive);

const nbColumns = 12;

const rangeWidth = { min: 4, max: nbColumns };
const rangeHeight = { min: 2, max: 3 };

const globalLimitWidgets = 100;

interface Props {
  dashboards: dashboards;
  templates: templates;
  bookmarks: bookmarks;
  locations: locations;
  user: User;
  locales: locales;
  dispatch: Function;
  match: any;
  history: any;
  users: any;
  t: Function;
}
interface State {
  dataCurrentWidget: Widget | null | undefined;
  openModal: boolean;
  limitWidgets: number;
  fullScreenWidget: number;
  editionOpen: boolean;
  dashboardToEdit: any;
  fullScreenDashboard: boolean;
}

/**
 * @class DashBoard
 * @extends {React.Component<Props, State>}
 */
class DashBoard extends React.Component<Props, State> {
  /**
   * Créé un widget vide non configuré
   *
   * @static
   * @param {Template} template
   * @param {number} idDashboard
   * @returns {Object} Widget vide
   * @memberof DashBoard
   */
  static createWidget(template: Template, idDashboard: number) {
    return {
      id: 0,
      name: "",
      type: "PUBLIC",
      content: template.content,
      configured: false,
      template: template.id,
      dashboard: idDashboard,
    };
  }

  /**
   * Place le widget aux positions retournées
   *
   * @static
   * @param {number} x
   * @param {number} y
   * @returns {Object} Les positions du widget
   * @memberof DashBoard
   */
  static createPositionWidget(x: number, y: number) {
    return {
      i: "",
      x,
      y,
      w: rangeWidth.min,
      h: rangeHeight.min,
      minW: rangeWidth.min,
      minH: rangeHeight.min,
      maxW: rangeWidth.max,
      maxH: rangeHeight.max,
      newWidget: 2,
    };
  }

  /**
   * Gère l'évènement de glisser/déposer d'un widget
   * sur le canvas
   *
   * @static
   * @param {Object} layout
   * @param {Object} oldItem
   * @param {Object} newItem
   * @param {Object} placeholder
   * @param {Object} e
   * @param {Object} element
   * @method onDrag
   * @memberof DashBoard
   */
  static onDrag(
    layout: Object,
    oldItem: Object,
    newItem: Object,
    placeholder: Object,
    e: Object,
    element: any
  ) {
    const grid: any = document.getElementsByClassName("react-grid-layout")[0];
    const translateXMaxValue = grid.offsetWidth - element.offsetWidth;
    const translateYMaxValue = grid.offsetHeight - element.offsetHeight;

    const welement = window.getComputedStyle(element);
    if (null != welement && welement) {
      const transform = welement.transform;
      if (null != transform && transform) {
        const translateValues = transform.split(",");
        let translateX = parseInt(
          translateValues[translateValues.length - 2],
          10
        );
        let translateY = parseInt(
          translateValues[translateValues.length - 1].slice(0, -1),
          10
        );

        if (translateX > translateXMaxValue) {
          translateX = translateXMaxValue;
        }
        if (translateX < 0) {
          translateX = 0;
        }
        if (translateY > translateYMaxValue) {
          translateY = translateYMaxValue;
        }
        if (translateY < 0) {
          translateY = 0;
        }

        const elem = element;
        elem.style.transform = `translate(${translateX}px, ${translateY}px)`;
      }
    }
  }

  /**
   * @param {Props} props
   * @constructor
   * @memberof DashBoard
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      openModal: false,
      dataCurrentWidget: null,
      limitWidgets: globalLimitWidgets,
      fullScreenWidget: -1,
      dashboardToEdit: null,
      editionOpen: false,
      fullScreenDashboard: false,
    };
    this.removeWidget = this.removeWidget.bind(this);
  }

  /**
   * Récupère tous les éléments nécessaire sur le composant
   * au montage de celui-ci
   *
   * @method componentDidMount
   * @memberof DashBoard
   */
  componentDidMount() {
    const { dispatch, match, locations, user } = this.props;
    dispatch(dashboardActions.get(match.params.dashboardId));
    dispatch(locationActions.get(match.params.locationId));
    dispatch(meterActions.getAllWithChildren(match.params.locationId));
    dispatch(vmeterActions.getAllWithChildren(match.params.locationId));
    dispatch(userActions.get(user.id));
    dispatch(localeActions.load());
    dispatch(colorActions.load());
    animateScroll.scrollToTop({ duration: 0 });
    const url = match.url;

    if (url.includes("/FullScreen")) {
      this.setState({ fullScreenDashboard: true });
    } else {
      dispatch(templateActions.getAll());
      dispatch(bookmarkActions.getAll(match.params.locationId));
    }
  }

  /**
   * Nettoie le state redux au démontage
   *
   * @method componentWillUnmount
   * @memberof DashBoard
   */
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(dashboardActions.clear());
    dispatch(locationActions.clear());
    dispatch(templateActions.clear());
    dispatch(bookmarkActions.clear());
    dispatch(widgetActions.clear());
  }

  /**
   * Gère les changement sur le layout du composant React Grid
   * (principalement les déplacements d'éléments)
   *
   * @param {Object} layout Layout
   * @method onLayoutChange
   * @memberof DashBoard
   */
  onLayoutChange = (layout: any) => {
    const { dispatch, dashboards, locations } = this.props;
    const { dataCurrentWidget } = this.state;
    if (!dashboards) {
      return;
    }
    if (!dashboards.dashboard) {
      return;
    }
    let content = cloneDeep(dashboards.dashboard.content);
    const parseContent = (data: Array<any>) => {
      let parsed: any = data.sort(
        (a, b) => parseInt(a.i, 10) - parseInt(b.i, 10)
      );
      parsed = JSON.stringify(parsed);
      return JSON.parse(parsed);
    };

    if (content && content.length > 0 && layout && layout.length > 0) {
      const wdgt: any = content.find((widget: any) => widget.newWidget === 2);
      if (wdgt !== undefined) {
        const pos = content.map((w: any) => w.newWidget).indexOf(2);
        delete wdgt.newWidget;
        content[pos] = wdgt;
      } else {
        content = layout;
      }

      const parsedContent = parseContent(content);
      const parsedDashContent = parseContent(dashboards.dashboard.content);

      if (
        dataCurrentWidget == null &&
        !isEqual(parsedDashContent, parsedContent) &&
        this.couldEditDashboardInformation() &&
        Object.keys(locations).length > 0
      ) {
        dashboards.dashboard.content = content;
        dispatch(dashboardActions.update(dashboards.dashboard));
      }
    }
  };

  /**
   * Gère l'état d'édition du dashboard
   *
   * @method isEditedDashboard
   * @memberof DashBoard
   */
  isEditedDashboard = () => {
    const { editionOpen } = this.state;
    this.setState({
      editionOpen: !editionOpen,
      dashboardToEdit: null,
    });
  };

  /**
   * Ouvre la modal avec le dashboard à éditer
   *
   * @param {Object} e Evènement
   * @param {Object} dashboardToEdit Le dashboard
   * @method editDashboard
   * @memberof DashBoard
   */
  editDashboard = (e: any, dashboardToEdit: dashboard) => {
    const { editionOpen } = this.state;
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
    this.setState({
      editionOpen: !editionOpen,
      dashboardToEdit,
    });
  };

  /**
   * Ajoute le dashboard à ses favoris
   *
   * @method bookmark
   * @memberof DashBoard
   */
  bookmark = () => {
    const { dispatch, dashboards, user, bookmarks, locations } = this.props;
    const bookmark = {
      userId: user.id,
      dashboardId: dashboards.dashboard.id,
      locationId: locations.fetchedLocation.id,
    };
    dispatch(bookmarkActions.save(bookmark, bookmarks.items));
  };

  /**
   * Supprime le dashboard de ses favoris
   *
   * @method unbookmark
   * @memberof DashBoard
   */
  unbookmark = () => {
    const { dispatch, dashboards, user, bookmarks, locations } = this.props;
    const bookmark = {
      userId: user.id,
      dashboardId: dashboards.dashboard.id,
      locationId: locations.fetchedLocation.id,
    };
    dispatch(bookmarkActions.deleteBookmark(bookmark, bookmarks.items));
  };

  /**
   * Teste si le dashboard est dans les favoris
   *
   * @returns {boolean} Le contrôle
   * @method isBookmarked
   * @memberof DashBoard
   */
  isBookmarked = () => {
    const { bookmarks, dashboards } = this.props;
    return (
      bookmarks.items.filter(
        (it: any) =>
          (it.dashboards.find((i) => i.id) &&
            it.dashboards.find((i) => i.id).id) === dashboards.dashboard.id
      ).length === 1
    );
  };

  /**
   * Récupère la position d'un widget à sa création,
   * en fonction de l'existant sur le canvas
   *
   * @method findPosition
   * @memberof DashBoard
   */
  findPosition = () => {
    const { dashboards } = this.props;
    dashboards.dashboard.content.sort((e1: any, e2: any) =>
      e1.y - e2.y !== 0 ? e1.y - e2.y : e1.x - e2.x
    );
    const orderList = dashboards.dashboard.content;
    const lastElement = orderList[orderList.length - 1];
    if (!lastElement) return { x: 0, y: 0 };

    const tableauRangement: Array<any> = [];
    // Création d'un tableau de 3 * (nbWidgets+2)
    // 3 pour le nombres de widgets possibles par ligne
    // +2 pour éviter les bugs (par exemple quand il y a un seul widget qui prend toute la ligne, si on ajoute pas +2, il y a une erreur car aucune place disponible)
    for (let i = 0; i < dashboards.dashboard.content.length + 2; i++) {
      for (let j = 0; j < 3; j++) {
        tableauRangement.push(true);
      }
    }
    // On va scanner chaque widgets du dashboards
    dashboards.dashboard.content.forEach((elem: any) => {
      // On cherche la ligne sur laquelle il est /2 (2 car la taille d'une ligne est de 2)
      const lineStart = Math.floor(elem.y / 2);
      // On cherche la colonne sur laquelle il est /4 (la taille minimum d'un widget est de 4)
      const colStart = Math.floor(elem.x / 4);
      // On cherche combien de ligne il occupe (1 si la taille est à 2 car une ligne est egal à 2 et 2 si la taille est egal à 3 (donc 3%2===1) car la taille maximale est 3)
      const nbLine = elem.h % 2 === 0 ? 1 : 2;
      // On cherche combien de colonnes il occupe : Pour ça, on prend l'index de la colonne la plus a droite / 4 - l'index de la colonne la plus a gauche du widget /4
      // Exemple: un widget qui à son coin gauche à l'index 7 et le coin droit à l'index 11 -> floor(7/4) == 1 et ceil(11/4) == 3 ==> Il occupe donc 2 colonnes
      const nbCols = Math.ceil((elem.x + elem.w) / 4) - Math.floor(elem.x / 4);

      // On change à false les emplacements où le nouveau widgets ne peut pas aller car un widget s'y trouve
      for (let l = 0; l < nbCols; l++) {
        tableauRangement[lineStart * 3 + colStart + l] = false;
      }
      for (let l = 0; l < nbLine; l++) {
        tableauRangement[lineStart * 3 + colStart + l * 3] = false;
      }
    });
    // On cherche le premier emplacement disponible (== true)
    const index = tableauRangement.findIndex((elem) => elem);

    // x : x % 3 pour avoir le numéro de la colonne (0,1,2) et * 4 car la taille minimale d'un widget est de 4
    // y : y / 3 pour avoir le numéro de la ligne et *2 car la taille minimale d'un widget est de 2
    return { x: (index % 3) * 4, y: Math.floor(index / 3) * 2 };
  };

  /**
   * Change le widget courant et ouvre sa modal
   *
   * @param {number} widgetId Identifiant du widget
   * @method changeCurrentWidget
   * @memberof DashBoard
   */
  changeCurrentWidget = (widgetId: number | null) => {
    const { dashboards, dispatch } = this.props;
    const { openModal } = this.state;
    this.setState({
      openModal: !openModal,
      dataCurrentWidget: cloneDeep(
        dashboards.dashboard.widgets.find(
          (widget: Widget) => widget.id === widgetId
        )
      ),
    });
    dispatch(alertActions.clear());
  };

  /**
   * Gère l'ouverture et fermeture d'un widget
   *
   * @method toggleModal
   * @memberof DashBoard
   */
  toggleModal = () => {
    const { dispatch, dashboards } = this.props;
    const { dataCurrentWidget } = this.state;
    if (
      dataCurrentWidget.id &&
      _.get(dataCurrentWidget, "content.dataSourceProperty.displayID")
    ) {
      dispatch(
        widgetActions.getWidgetData(
          dataCurrentWidget.id,
          dataCurrentWidget.content.dataSourceProperty.displayID
        )
      );
    }
    this.changeCurrentWidget(null);
  };

  /**
   * Gère la création d'un widget sur le dashboard
   *
   * @param {Template} template Template
   * @method addWidget
   * @memberof DashBoard
   */
  addWidget = (template: Template) => {
    const { dashboards, dispatch } = this.props;
    const widget = DashBoard.createWidget(template, dashboards.dashboard.id);
    const position = this.findPosition();
    const positionWidget = DashBoard.createPositionWidget(
      position.x,
      position.y
    );
    dispatch(widgetActions.save(widget, dashboards.dashboard, positionWidget));
    if (position.y > 2) animateScroll.scrollToBottom();
  };

  /**
   * Quitte le mode plein écran d'un widget
   *
   * @method quitFullScreen
   * @memberof DashBoard
   */
  quitFullScreen = () => {
    this.setState({ fullScreenWidget: -1 });
    animateScroll.scrollToTop({ duration: 0 });
  };

  /**
   * Affiche le mode plein écran d'un widget
   *
   * @param {number} fullScreenWidget L'id du widget
   * @method displayFullScreenWidget
   * @memberof DashBoard
   */
  displayFullScreenWidget = (fullScreenWidget: number) => {
    this.setState({ fullScreenWidget });
    animateScroll.scrollToTop({ duration: 0 });
  };

  /**
   * Gère le droit d'éditer les informations
   * du dashboard
   *
   * @returns {boolean} Le contrôle
   * @method couldEditDashboardInformation
   * @memberof DashBoard
   */
  couldEditDashboardInformation = () => {
    const { dashboards, user, locations, users } = this.props;
    const { dashboard } = dashboards;

    const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN"];
    if (roleList.includes(user.role.name)) {
      return true;
    }

    if (dashboard && (dashboard.personal || dashboard.private)) {
      return false;
    }

    if (locations.fetchedLocation && locations.fetchedLocation.tournee) {
      //CETTE LIGNE ALEXIS
      return true;
    }
    if (
      users &&
      users.fetchedUser &&
      locations.fetchedLocation &&
      users.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find(
            (permission: any) => permission.name === "edit.dashboard"
          ) && el.locationCode === locations.fetchedLocation.code
      )
    ) {
      return true;
    }
    return false;
  };

  /**
   * Gère le passage en mode présentation/normal
   *
   *
   *
   * @method removeWidget
   * @memberof DashBoard
   */

  toogleFullScreen = () => {
    const { fullScreenDashboard } = this.state;
    const { history, match, dispatch } = this.props;
    if (fullScreenDashboard) {
      history.goBack();
    } else {
      history.push(history.location.pathname + "/FullScreen");
    }

    const url = match.url;
    const regex1 = /\D+\/\d\/\d+\/FullScreen/g;

    if (url.match(regex1)) {
      this.setState({ fullScreenDashboard: true });
    } else {
      dispatch(templateActions.getAll());
      dispatch(bookmarkActions.getAll(match.params.locationId));
    }
  };

  /**
   * Gère la suppression d'un widget sur le dashboard
   *
   * @async
   * @param {number} widgetId Identifiant du widget
   * @method removeWidget
   * @memberof DashBoard
   */
  async removeWidget(widgetId: number) {
    const { Fragment } = React;
    const { dispatch, dashboards, t } = this.props;

    const result = await confirm({
      title: (
        <Fragment>
          <strong>
            {t("dashboard.button.delete_widget_from_dashboard")} ?{" "}
          </strong>
        </Fragment>
      ),
      message: "",
      confirmText: t("all.button.delete"),
      confirmColor: "danger",
      cancelColor: "primary",
      cancelText: t("all.button.cancel"),
    });
    if (result) {
      dispatch(widgetActions.toDelete(widgetId, dashboards.dashboard));
    }
  }

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof DashBoard
   */
  render() {
    const {
      dashboards,
      templates,
      bookmarks,
      locations,
      user,
      locales,
      match,
      t,
    } = this.props;
    const {
      dataCurrentWidget,
      openModal,
      limitWidgets,
      fullScreenWidget,
      editionOpen,
      dashboardToEdit,
      fullScreenDashboard,
    } = this.state;
    const couldEditDashboard = this.couldEditDashboardInformation();
    let dataWidgetFullScreen;
    if (dashboards.dashboard) {
      dataWidgetFullScreen = dashboards.dashboard.widgets.find(
        (widget: Widget) => widget.id === fullScreenWidget
      );
    }
    let headerClass = fullScreenDashboard ? "col-4" : "col-12";
    let sticky: any = !fullScreenDashboard
      ? { top: "90px", zIndex: 190, position: "sticky" }
      : {};
    return (
      <>
        {dashboards.loading && !_.get(locations, "fetchedLocation.id") && (
          <Loading message="Chargement des tableaux de bord" />
        )}
        <div className="col-md-12">
          {fullScreenWidget === -1 ? (
            <div>
              {dashboards.error && <ErrorBand message={dashboards.error} />}
              {templates.error && <ErrorBand message={templates.error} />}
              {dashboards.dashboard &&
                _.get(locations, "fetchedLocation.id") && (
                  <div>
                    <div style={sticky}>
                      <Row>
                        <div className={`widget-info ${headerClass}`}>
                          <div className="presentation-container">
                            <div
                              className="presentation-header"
                              style={{ borderRadius: "10px" }}
                            >
                              <Row>
                                <Col
                                  xs="6"
                                  md="8"
                                  lg="9"
                                  style={{ marginTop: "15px" }}
                                >
                                  <span
                                    className="presentation-main-title"
                                    style={{
                                      fontSize: "1.5em",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {formatDashboardName(
                                      user,
                                      dashboards.dashboard,
                                      t
                                    )}
                                  </span>
                                </Col>
                                <Col xs="6" md="4" lg="3">
                                  <Row>
                                    {!fullScreenDashboard && (
                                      <Col
                                        md="2"
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "15px",
                                        }}
                                      >
                                        {dashboards.dashboard &&
                                          this.couldEditDashboardInformation() && (
                                            <div
                                              id="editWidget"
                                              className="clickable round"
                                              role="presentation"
                                              onClick={(e) =>
                                                this.editDashboard(
                                                  e,
                                                  dashboards.dashboard
                                                )
                                              }
                                              style={{ margin: "auto" }}
                                            >
                                              <EditionBleu
                                                className="add"
                                                height="1.2em"
                                                width="1.2em"
                                              />
                                              <UncontrolledTooltip
                                                placement="bottom"
                                                target="editWidget"
                                              >
                                                {" "}
                                                {t(
                                                  "display_dashboards.button.rename_dashboard"
                                                )}
                                              </UncontrolledTooltip>
                                            </div>
                                          )}
                                      </Col>
                                    )}
                                    {!fullScreenDashboard && (
                                      <Col
                                        md="2"
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "15px",
                                        }}
                                      >
                                        {bookmarks.items &&
                                          this.isBookmarked() &&
                                          dashboards.dashboard.personal &&
                                          dashboards.dashboard.owner.id ===
                                            user.id && (
                                            <div
                                              id="addBookmark"
                                              className="clickable round"
                                              role="presentation"
                                              onClick={this.unbookmark}
                                              style={{ margin: "auto" }}
                                            >
                                              <FavoriActif
                                                className="add"
                                                fill="#2c303b"
                                                height="1.5em"
                                                width="1.5em"
                                              />
                                              <UncontrolledTooltip
                                                placement="bottom"
                                                target="addBookmark"
                                              >
                                                {" "}
                                                {t(
                                                  "dashboard.button.delete_from_favorite"
                                                )}
                                              </UncontrolledTooltip>
                                            </div>
                                          )}

                                        {bookmarks.items &&
                                          this.isBookmarked() &&
                                          !dashboards.dashboard.personal &&
                                          dashboards.dashboard.owner.id !==
                                            user.id && (
                                            <div
                                              id="addBookmark"
                                              className="clickable round"
                                              role="presentation"
                                              onClick={this.unbookmark}
                                              style={{ margin: "auto" }}
                                            >
                                              <FavoriActif
                                                className="add"
                                                fill="#2c303b"
                                                height="1.5em"
                                                width="1.5em"
                                              />
                                              <UncontrolledTooltip
                                                placement="bottom"
                                                target="addBookmark"
                                              >
                                                {" "}
                                                {t(
                                                  "dashboard.button.delete_from_favorite"
                                                )}
                                              </UncontrolledTooltip>
                                            </div>
                                          )}

                                        {bookmarks.items &&
                                          this.isBookmarked() &&
                                          !dashboards.dashboard.personal &&
                                          dashboards.dashboard.owner.id ===
                                            user.id && (
                                            <div
                                              id="addBookmark"
                                              className="clickable round"
                                              role="presentation"
                                              onClick={this.unbookmark}
                                              style={{ margin: "auto" }}
                                            >
                                              <FavoriActif
                                                className="add"
                                                fill="#2c303b"
                                                height="1.5em"
                                                width="1.5em"
                                              />
                                              <UncontrolledTooltip
                                                placement="bottom"
                                                target="addBookmark"
                                              >
                                                {" "}
                                                {t(
                                                  "dashboard.button.delete_from_favorite"
                                                )}
                                              </UncontrolledTooltip>
                                            </div>
                                          )}
                                        {bookmarks.items &&
                                          this.isBookmarked() &&
                                          dashboards.dashboard.personal &&
                                          dashboards.dashboard.owner.id !==
                                            user.id && (
                                            <div
                                              id="addBookmark"
                                              className="clickable round"
                                              role="presentation"
                                              onClick={this.unbookmark}
                                              style={{ margin: "auto" }}
                                            >
                                              <FavoriActif
                                                className="add"
                                                fill="#2c303b"
                                                height="1.5em"
                                                width="1.5em"
                                              />
                                              <UncontrolledTooltip
                                                placement="bottom"
                                                target="addBookmark"
                                              >
                                                {" "}
                                                {t(
                                                  "dashboard.button.delete_from_favorite"
                                                )}
                                              </UncontrolledTooltip>
                                            </div>
                                          )}
                                        {bookmarks.items &&
                                          bookmarks.items.length < 4 &&
                                          !this.isBookmarked() && (
                                            <div
                                              id="addBookmark"
                                              className="clickable round"
                                              role="presentation"
                                              onClick={this.bookmark}
                                              style={{ margin: "auto" }}
                                            >
                                              <EtoileFavoris
                                                className="add"
                                                fill="#2c303b"
                                                height="1.5em"
                                                width="1.5em"
                                              />
                                              <UncontrolledTooltip
                                                placement="bottom"
                                                target="addBookmark"
                                              >
                                                {" "}
                                                {t(
                                                  "all.button.add_to_favorites"
                                                )}
                                              </UncontrolledTooltip>
                                            </div>
                                          )}
                                      </Col>
                                    )}
                                    <Col
                                      md={fullScreenDashboard ? "12" : "2"}
                                      style={{
                                        marginTop: "15px",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <div
                                        id="toogleFullScreen"
                                        className="clickable round"
                                        role="presentation"
                                        onClick={this.toogleFullScreen}
                                        style={{ margin: "auto" }}
                                      >
                                        {!fullScreenDashboard ? (
                                          <SvgPleinEcranVert
                                            className="add"
                                            fill="#2c303b"
                                            height="1.5em"
                                            width="1.5em"
                                          />
                                        ) : (
                                          <SvgPetitEcranVert
                                            className="add"
                                            fill="#2c303b"
                                            height="1.5em"
                                            width="1.5em"
                                          />
                                        )}
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="toogleFullScreen"
                                        >
                                          {" "}
                                          {!fullScreenDashboard && (
                                            <span>
                                              {t(
                                                "dashboard.button.switch_display_mode"
                                              )}
                                            </span>
                                          )}
                                          {fullScreenDashboard && (
                                            <span>
                                              {t(
                                                "dashboard.button.exit_display_mode"
                                              )}
                                            </span>
                                          )}
                                        </UncontrolledTooltip>
                                      </div>
                                    </Col>
                                    {!fullScreenDashboard && (
                                      <Col
                                        xs="1"
                                        style={{ marginTop: "14.5px" }}
                                      >
                                        {templates.items &&
                                          templates.items.length > 0 &&
                                          this.couldEditDashboardInformation() && (
                                            <AddBar
                                              size="lg"
                                              buttonLabel={
                                                <span
                                                  className="clickable round"
                                                  id="addWidget"
                                                  role="presentation"
                                                  style={{
                                                    padding: "7px 5px 7px 5px",
                                                    margin: "auto",
                                                  }}
                                                >
                                                  <AjoutBleu
                                                    className="add"
                                                    fill="#2c303b"
                                                    height="1.5em"
                                                    width="1.5em"
                                                  />
                                                  <UncontrolledTooltip
                                                    placement="bottom"
                                                    target="addWidget"
                                                  >
                                                    {" "}
                                                    {t(
                                                      "add_widget.button.add_widget"
                                                    )}
                                                  </UncontrolledTooltip>
                                                </span>
                                              }
                                              listTemplates={templates.items}
                                              addWidget={this.addWidget as any}
                                              locales={locales}
                                              disabled={
                                                dashboards.dashboard.widgets
                                                  .length >= limitWidgets
                                              }
                                            />
                                          )}
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                    <ResponsiveGridLayout
                      onLayoutChange={
                        this.onLayoutChange
                      } /* layout={dashboards.dashboard.content} */
                      onDrag={DashBoard.onDrag}
                      cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                      isDraggable={this.couldEditDashboardInformation()}
                      isResizable={this.couldEditDashboardInformation()}
                    >
                      {dashboards.dashboard.widgets.map((widget: Widget) => {
                        const positionWidget =
                          dashboards.dashboard.content.find(
                            (element: any) =>
                              element.i ===
                              (widget.id != null ? widget.id.toString() : -1)
                          );
                        if (positionWidget === undefined) return <div />;
                        return (
                          <div
                            id={`${widget.name}l${positionWidget.x}l${positionWidget.y}l${positionWidget.w}l${positionWidget.h}`}
                            className="widget-info-container"
                            key={widget.id}
                            data-grid={positionWidget}
                          >
                            <BaseWidget
                              data={widget}
                              remove={this.removeWidget}
                              openEditor={this.changeCurrentWidget}
                              displayFullScreen={this.displayFullScreenWidget}
                              couldEditWidget={couldEditDashboard}
                              match={match}
                              modal={openModal}
                              containerId={`${widget.name}l${positionWidget.x}l${positionWidget.y}l${positionWidget.w}l${positionWidget.h}`}
                            />
                          </div>
                        );
                      })}
                    </ResponsiveGridLayout>
                    {dataCurrentWidget && dataCurrentWidget.id && (
                      <WidgetCreator
                        key={
                          JSON.stringify(dataCurrentWidget) +
                          dataCurrentWidget.id
                        }
                        widget={dataCurrentWidget}
                        modal={openModal}
                        locationId={locations.fetchedLocation.id}
                        configureDone={this.toggleModal}
                      />
                    )}
                  </div>
                )}
            </div>
          ) : (
            <BaseWidget
              data={dataWidgetFullScreen}
              quitFullScreen={this.quitFullScreen}
              match={match}
            />
          )}
          {dashboardToEdit && (
            <EditDashBoard
              dashboard={dashboardToEdit}
              isEdited={this.isEditedDashboard}
              edit={this.editDashboard}
              isOpen={editionOpen}
            />
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const {
    dashboards,
    alert,
    templates,
    widgets,
    authentication,
    bookmarks,
    locations,
    locales,
    users,
  } = state;
  const { user } = authentication;

  const { dashboard } = dashboards;
  return {
    dashboard,
    dashboards,
    alert,
    templates,
    widgets,
    user,
    bookmarks,
    locations,
    locales,
    users,
  };
}

const mapping: any = connect(mapStateToProps)(DashBoard);
const connectedDashboard = withRouter(mapping);
const tr = withTranslation()(connectedDashboard);
export default tr;
