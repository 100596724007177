import _ from "lodash";
import { authHeader, handleResponse } from "../_helpers";
import { ReportSetting } from "../_interfaces/RoundReportTypes";

async function getAllReport(roundCode: string) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/report/allReport/${roundCode}`,
    requestOptions
  );
  return handleResponse(response);
}

async function getReport(
  reportKey: string,
  rndCode: string,
  reportSetting: ReportSetting
) {
  _.get(reportSetting, "filters", []).forEach((e) => {
    delete e.filter.filter;
    if (_.isArray(e.userInput))
      e.userInput = _.join(
        e.userInput.map((el) => el.value),
        "|"
      );
    if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
  });
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(reportSetting),
  };
  const response = await fetch(
    `/api/report/${reportKey}/${rndCode}`,
    requestOptions
  );
  return response.json();
}

const roundReportService = {
  getAllReport,
  getReport,
};

export default roundReportService;
