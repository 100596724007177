import templateConstants from "../_constants/template.constants";
import { action } from "../_interfaces/action";

export default function templates(state = {}, action: action) {
  switch (action.type) {
    case templateConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case templateConstants.GETALL_SUCCESS:
      return {
        items: action.templates,
      };
    case templateConstants.GETALL_FAILURE:
      return {
        error: "Impossible d'obtenir la liste de widget",
      };
    case templateConstants.GET_REQUEST:
      return {
        loading: true,
      };
    case templateConstants.GET_SUCCESS:
      return {
        fetchedtemplate: action.fetchedtemplate,
      };
    case templateConstants.GET_FAILURE:
      return {
        error: "Impossible d'obtenir les informations du widget",
      };
    case templateConstants.SAVE_REQUEST:
      return {
        loading: true,
      };
    case templateConstants.SAVE_SUCCESS:
      return {
        items: action.templates.concat(action.savedTemplate),
      };
    case templateConstants.SAVE_FAILURE:
      return {
        error: "Impossible de sauvegarder le widget",
      };
    case templateConstants.DELETE_SUCCESS:
      return {
        items: action.templates.filter(
          (template) => template.id !== action.template.id
        ),
      };
    case templateConstants.DELETE_FAILURE:
      return {
        items: action.templates,
        error: "Impossible de supprimer le widget",
      };
    case templateConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
