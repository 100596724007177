import React, { Component } from "react";
import ReglagesVert from "../SvgComponents/ReglagesBleu";
import translate from "../_helpers/locale-helpers";
import SeuilComponent from "./SeuilComponent";
import PeriodeSelector from "./PeriodSelector";
import RangeConfigurator from "./RangeConfigurator";
import RefWitnessSelector from "./RefWitnessSelector";
import AlarmConstructeurOption from "./AlarmConstructeurOption";
import { withTranslation } from "react-i18next";

interface Props {
  locales: any;
  alarmTemplates: any;
  handleChangeSeuil: Function;
  handleChangePeriode: Function;
  handleLimiteConso: Function;
  handleRefWitnessMeters: Function;
  handleAlarm: Function;
  handleHourInterval: Function;
  pdis: Array<any>;
  typesAlarm: any;
  meters: any;
  t: Function;
  listMeters: any;
}

class AlarmOptions extends Component<Props> {
  renderByType() {
    const {
      alarmTemplates,
      handleChangeSeuil,
      handleChangePeriode,
      handleLimiteConso,
      handleRefWitnessMeters,
      handleHourInterval,
      handleAlarm,
      pdis,
      listMeters,
      typesAlarm,
      meters,
    } = this.props;
    let unit = "";
    if (alarmTemplates && alarmTemplates.content) {
      unit = alarmTemplates.content.dataSourceProperty.alertUnit;
    }
    switch (alarmTemplates.content.alertName) {
      case "RangeConsumption":
        return (
          <div className="options">
            <SeuilComponent
              unit={unit}
              handleChange={handleChangeSeuil}
              alarmTemplates={alarmTemplates}
            />
            <PeriodeSelector
              handleChangePeriode={handleChangePeriode}
              alarmTemplates={alarmTemplates}
            />
          </div>
        );
      case "GapConsumption":
        return (
          <div className="options">
            <SeuilComponent
              unit={unit}
              handleChange={handleChangeSeuil}
              alarmTemplates={alarmTemplates}
            />
            <PeriodeSelector
              handleChangePeriode={handleChangePeriode}
              alarmTemplates={alarmTemplates}
            />
          </div>
        );
      case "GapConsumptionComparison":
        return (
          <div className="options">
            <RangeConfigurator
              handleLimitConso={handleLimiteConso}
              alarmTemplates={alarmTemplates}
            />
            <RefWitnessSelector
              handleRefWitnessMeters={handleRefWitnessMeters}
              listMeters={listMeters}
              alarmTemplates={alarmTemplates}
            />
          </div>
        );
      case "Alarms":
        return (
          <div className={"options"}>
            <AlarmConstructeurOption
              meters={meters}
              typesAlarm={typesAlarm}
              alarmTemplates={alarmTemplates}
              handleChange={handleAlarm}
            />
          </div>
        );
      case "AverageRangeConsumption":
        return (
          <div className="options">
            <SeuilComponent
              unit={unit}
              handleChange={handleChangeSeuil}
              alarmTemplates={alarmTemplates}
            />
          </div>
        );
      case "RangeRealConsumption":
        return (
          <div className="options">
            <SeuilComponent
              unit={unit}
              handleChange={handleChangeSeuil}
              alarmTemplates={alarmTemplates}
            />
          </div>
        );
      case "NoReadForMeterAlert":
        return (
          <div className="options">
            <PeriodeSelector
              handleChangePeriode={handleChangePeriode}
              alarmTemplates={alarmTemplates}
            />
          </div>
        );
      case "ConsumptionHour":
        return (
          <div className="options">
            <PeriodeSelector
              isHour
              handleChangePeriode={handleChangePeriode}
              handleHourInterval={handleHourInterval}
              alarmTemplates={alarmTemplates}
            />
          </div>
        );
      default:
    }
  }

  render() {
    const { locales, alarmTemplates, t } = this.props;
    return (
      <div className="alarm-option-form filter-container">
        {alarmTemplates && alarmTemplates.content && (
          <>
            <div className="title flex-box">
              <ReglagesVert fill="#31c6b3" width="1.4em" />
              <h2>{t("all.text.option_plural")}</h2>
            </div>
            <div className="calcul-method">
              <h5>{t("alarm_options.text.calculation_method")}</h5>
              <span style={{ marginTop: "20px" }}>
                {translate(
                  "fr",
                  "alarmCalculation",
                  alarmTemplates.content.alertName,
                  locales && locales.locale
                ) || ".NoReadForMeterAlert.."}
              </span>
            </div>
            {this.renderByType()}
          </>
        )}
      </div>
    );
  }
}

const tr = withTranslation()(AlarmOptions);
export default tr;
