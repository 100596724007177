import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ChartRemoteReading from "./ChartRemoteReading";
import BarRemoteReading from "./BarRemoteReading";
import _ from "lodash";
import { UncontrolledTooltip } from "reactstrap";
import SvgChartBar from "../SvgComponents/ChartBar";
import SvgChartPie from "../SvgComponents/ChartPie";
import SvgMeterModel from "../SvgComponents/MeterModel";
import SvgMeterWithAlarm from "../SvgComponents/MeterWithAlarm";
import SvgPieNotAvailable from "../SvgComponents/PieNotAvailable";
import SvgChartBarHorizontalNotAvailable from "../SvgComponents/ChartBarHorizontalNotAvailable";
import { compose } from "redux";

interface ChartData {
  label: string;
  color: string;
  hoverColor?: string;
  data: number;
  handleClick?: Function;
}

interface ChartModel {
  title: string;
  data: ChartData[];
  handleClick?: Function;
  extra: any;
}

interface Props {
  barChart: ChartModel;
  pieChart: ChartModel;
  type: string;
  code: string;
}

class FlipChartRemoteReading extends Component<Props, any> {
  constructor(props: any) {
    super(props);

    this.handleFlip = this.handleFlip.bind(this);
    this.generateChart = this.generateChart.bind(this);

    this.state = {
      flipped: false,
    };
  }

  handleFlip() {
    const { flipped } = this.state;
    const { type } = this.props;
    const flipCardsInner = document.querySelectorAll<HTMLElement>("." + type)!;

    Array.prototype.forEach.call(flipCardsInner, function (item) {
      item.style.transform = flipped ? "rotateY(0deg)" : "rotateY(180deg)";
    });

    const flipCardFront = document.querySelectorAll<HTMLElement>(
      "." + type + "front"
    )!;
    Array.prototype.forEach.call(flipCardFront, function (item) {
      item.style.zIndex = flipped ? 0 : 1;
    });
    const flipCardBack = document.querySelectorAll<HTMLElement>(
      "." + type + "back"
    )!;
    Array.prototype.forEach.call(flipCardBack, function (item) {
      item.style.zIndex = flipped ? 1 : 0;
    });

    this.setState({
      flipped: !flipped,
    });
  }

  getIcon(type: string) {
    switch (type) {
      case "meterModel":
        return <SvgMeterModel height="2em" fill="#31c6b3" />;
      case "alarm":
        return <SvgMeterWithAlarm height="2em" fill="#31c6b3" />;
    }
  }

  generateChart(chartModel: ChartModel, front: boolean, key: string) {
    const { type, code } = this.props;
    const chartRemoteReadingProps = chartModel &&
      chartModel.data && {
        chartData: _.cloneDeep(chartModel.data),
        handleClick: chartModel && chartModel.handleClick,
        extra: chartModel.extra,
      };

    const idContainer = key;
    return (
      <div
        className="card"
        style={{
          height: "100%",
        }}
      >
        <div className="header-t">
          <div className="left-t">
            <span>{this.getIcon(type)}</span>
            <h2>{chartModel.title}</h2>
          </div>
          <div
            className="svg-container"
            id={"change" + idContainer + code}
            onClick={this.handleFlip}
            role="presentation"
          >
            {front ? (
              <SvgChartBar width="1.5em" height="1.5em" fill="#333" />
            ) : (
              <SvgChartPie width="1.5em" height="1.5em" fill="#333" />
            )}
            <UncontrolledTooltip target={"change" + idContainer + code}>
              {front ? "Voir le détail" : "Voir les pourcentages"}
            </UncontrolledTooltip>
          </div>
        </div>

        {chartModel && chartModel.data && chartModel.data.length > 0 ? (
          front ? (
            <div style={{ paddingTop: "15px", height: "calc(100% - 30px)" }}>
              <ChartRemoteReading {...chartRemoteReadingProps} />
            </div>
          ) : (
            <div
              style={{
                paddingTop: "15px",
                marginRight: "-20px",
                marginLeft: "-20px",
              }}
            >
              <BarRemoteReading {...chartRemoteReadingProps} />
            </div>
          )
        ) : (
          this.getNoMeterReadMsg(front)
        )}
      </div>
    );
  }

  getNoMeterReadMsg(front: boolean) {
    return (
      <div className="svgChartNotAvailable">
        {front ? (
          <SvgPieNotAvailable
            width="100%"
            fill="lightgrey"
            style={{ maxWidth: "-webkit-fill-available", height: "80%" }}
          />
        ) : (
          <SvgChartBarHorizontalNotAvailable
            width="100%"
            fill="lightgrey"
            style={{ maxWidth: "-webkit-fill-available", height: "80%" }}
          />
        )}
      </div>
    );
  }

  render() {
    const { barChart, pieChart, type, code } = this.props;
    const { flipped } = this.state;
    const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
    return (
      <div className="flip-card">
        {isChrome ? (
          <div className={"flip-card-inner " + type}>
            <div className={"flip-card-front " + type + "front"}>
              {this.generateChart(pieChart, true, "front")}
            </div>
            <div className={"flip-card-back " + type + "back"}>
              {this.generateChart(barChart, false, "back")}
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{ display: !flipped ? "none" : "initial" }}
              className={"moz-card"}
            >
              {this.generateChart(barChart, false, "back")}
            </div>
            <div
              style={{ display: flipped ? "none" : "initial" }}
              className={"moz-card"}
            >
              {this.generateChart(pieChart, true, "front")}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const connectedFlipChartRemoteReading = compose<ComponentType<any>>(
  withRouter,
  connect()
)(FlipChartRemoteReading);
export default connectedFlipChartRemoteReading;
