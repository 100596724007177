import React from "react";
import { Bar } from "react-chartjs-2";
import { withTranslation } from "react-i18next";
import moment from "moment";
import SvgChartBarVerticalNotAvailable from "../SvgComponents/ChartBarVerticalNotAvailable";

const AlarmChartPdf = (props) => {
  const { labels, data, t } = props;
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      t1: false,
      t2: false,
      datalabels: {
        display: false,
      },
    },
    emptyOverlay: {
      message:
        data.length === 0
          ? t("all.text.no_data_available")
          : "Consommation nulle",
    },
    legend: {
      display: true,
    },
    scales: {
      x: {
        stacked: true,
      },
      yAxes: [
        {
          display: false,
        },
      ],
    },
  };
  const xData = {
    labels: labels.map((el) => moment(el).format(t("all.date_format.date"))),
    datasets: data.map(({ label, data, color }) => ({
      label,
      data,
      backgroundColor: color,
      stack: "full",
    })),
  };

  return _.get(xData, "datasets", []).length > 0 ? (
    <Bar data={xData} options={options} />
  ) : (
    <SvgChartBarVerticalNotAvailable
      height="300px"
      fill="#E5E5E4"
      style={{ maxWidth: "-webkit-fill-available" }}
    />
  );
};

export default withTranslation()(AlarmChartPdf);
