import React from "react";
import history from "../_helpers/history";
import SvgFlecheAller from "../SvgComponents/FlecheAller";
import { UncontrolledTooltip } from "reactstrap";

const ArrowFicheButton: React.FC<{
  id: string;
  tooltipText: string;
  url: string;
}> = ({ id, tooltipText, url }) => (
  <div
    id={id}
    className="arrow-fiche-button"
    style={{ display: "inline-grid", float: "right" }}
    onClick={() => {
      history.push(url);
    }}
  >
    <SvgFlecheAller width="20px" height="20px" />
    <UncontrolledTooltip placement="bottom" target={id}>
      {tooltipText}
    </UncontrolledTooltip>
  </div>
);

export default ArrowFicheButton;
