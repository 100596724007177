import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import { importActions } from "../_actions";
import { Button, FormGroup, Input, Label } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ImportBleu from "../SvgComponents/ImportBleu";
import SuccessBand from "../Bands/Success";
import ErrorBand from "../Bands/Error";
import WarningBand from "../Bands/Warning";
import _ from "lodash";
import ImportSilexErrorTable from "./ImportSilexErrorTable";

const ImportSilex: React.FC<
  {
    imports: any;
    dispatch: Function;
    user: any;
    t: Function;
  } & RouteComponentProps<
    { GestionnaireId: string },
    StaticContext,
    { file: File; adminImport: boolean }
  >
> = ({ imports, dispatch, t, history, location, match, user }) => {
  const [lineType, setLineType] = useState<number>(0);
  const [selectedRound, setSelectedRound] = useState<any>({});
  const [file, setFile] = useState<File>();
  const [adminImport, setAdminImport] = useState<boolean>(false);

  useEffect(() => {
    const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN", "USER"];
    if (!roleList.includes(user.role.name)) {
      history.push("/forbidden");
    }
    if (
      location.state &&
      location.state.file &&
      Object.keys(imports).length > 0
    ) {
      setFile(location.state.file);
      setAdminImport(location.state.adminImport || false);
    } else {
      invalidateFile();
    }

    if (imports.errorSilex) {
      const newSelectedRound = imports.errorSilex.find((el: any) =>
        el.listResults.find((error: any) => error.differences.length > 0)
      );
      setLineType(
        _.get(newSelectedRound, "listResults").length > 0 &&
          _.get(
            newSelectedRound.listResults.find(
              (error: any) => error.differences.length > 0
            ),
            "lineType"
          )
      );
      setSelectedRound(newSelectedRound);
    }

    return () => {
      console.log("CLEARING");
      dispatch(importActions.clear());
    };
  }, []);

  useEffect(() => {
    if (!imports) {
      invalidateFile();
    } else if (_.get(imports, "silex[0].t0Result") === null) {
      setSelectedRound(_.get(imports, "silex[0]"));
    }
  }, [imports]);

  const invalidateFile = () => {
    history.replace(
      `/gestionnaires/${match.params.GestionnaireId}/synchronisation/silex`
    );
  };

  const getErrorMessage = (error: any) => {
    switch (error.errorCode) {
      case "400":
        if (error.errorMessage === "silex_format_error") {
          return t("import_silex.error_text.silex_error");
        }
        if (error.errorMessage === "Meters already in a round") {
          return t("import_silex.error_text.meter_in_round");
        }
      case "500":
        if (error.errorMessage === "charset_rejected") {
          const fileCharset: string = _.get(
            error,
            "errorDetail.fileCharset[0]",
            ""
          );
          const paramCharset: string = _.get(
            error,
            "errorDetail.paramCharset[0]",
            ""
          );

          return t("import_silex.text.wrong_encoding", {
            filename: file && file.name,
            fileCharset,
            paramCharset,
          });
        }
      default:
        return t("import_silex.text.load_file_failed", {
          filename: file && file.name,
        });
    }
  };

  const getAdminData = (selectedRound: any, lineType: any) => {
    const resultLine = _.get(selectedRound, `t${lineType}Result`);
    switch (lineType) {
      case 0:
        return [
          {
            identifiant: resultLine.codeTournee,
            label: resultLine.label,
            notes: resultLine?.modified
              ? "Modifier"
              : resultLine?.alreadyExist
              ? "Existe déjà"
              : "",
          },
        ];
      case 3:
        return resultLine.map((el: any) => ({
          identifiant: el.newSerial,
          oldSerial: el.theoricalOldSerial,
          changeDate: el.dateChangement.substr(0, 10),
        }));
      case 6:
      case 7:
        return resultLine.map((el: string, index: number) => ({
          identifiant: index,
          serial: el,
        }));
    }
  };

  const rowStyle = (row: any) => {
    const style: any = {};
    row?.alreadyExist
      ? (style.backgroundColor = "#FFD70088")
      : (style.backgroundColor = "#c8e6c9");
    return style;
  };

  const statutFormatter = (cell: any) => {
    return cell ? (
      <span>{t("import_silex.status_text.already_exist")}</span>
    ) : (
      <span>{t("import_silex.status_text.imported")}</span>
    );
  };

  const adminImportColumns = {
    t0Result: [
      {
        dataField: "identifiant",
        text: t("import_silex.columns.identifiant"),
      },
      {
        dataField: "label",
        text: t("import_silex.columns.field"),
      },
      {
        dataField: "notes",
        text: "Notes",
      },
    ],
    t3Result: [
      {
        dataField: "identifiant",
        text: t("import_silex.columns.identifiant"),
      },
      {
        dataField: "oldSerial",
        text: "Ancien numéro de série",
      },
      {
        dataField: "changeDate",
        text: "Date du changement",
        type: "date",
      },
    ],
    t6Result: [
      {
        dataField: "identifiant",
        text: t("import_silex.columns.identifiant"),
      },
      {
        dataField: "serial",
        text: "Numero de Série",
      },
    ],
    t7Result: [
      {
        dataField: "identifiant",
        text: t("import_silex.columns.identifiant"),
      },
      {
        dataField: "serial",
        text: "Numero de Série",
      },
    ],
  };

  const columns = [
    {
      dataField: "codeTournee",
      text: t("import_silex.column_header.round_code"),
    },
    {
      dataField: "label",
      text: t("import_silex.column_header.round_name"),
    },
    {
      dataField: "alreadyExist",
      text: t("import_silex.column_header.round_status"),
      type: "bool",
      formatter: statutFormatter,
    },
    {
      dataField: "startIndex",
      text: t("import_silex.column_header.first_row"),
      type: "number",
    },
    {
      dataField: "endIndex",
      text: t("import_silex.column_header.last_row"),
      type: "number",
    },
  ];

  return file ? (
    <div className="col-12">
      <div className="table-info-container">
        <h2>
          <span>
            <ImportBleu height="1em" width="1em" fill="#31c6b3" />
          </span>
          {t("import_silex.text.import_rounds")}
        </h2>
        <div style={{ display: "flex" }}>
          <Button color="danger" onClick={invalidateFile}>
            {t("all.button.change_file")}
          </Button>
          <p
            style={{
              padding: "auto",
              fontWeight: "bold",
              marginBottom: "0",
              alignSelf: "center",
              marginLeft: "5px",
            }}
          >
            {file.name}
          </p>
        </div>
        <div style={{ marginTop: 30 }}>
          {imports.silex ? (
            imports.silex.filter((rnd: any) => rnd?.t0Result.alreadyExist)
              .length === 0 ? (
              <SuccessBand
                message={t("import_silex.success_msg.x_loaded_silex_file", {
                  count: imports.silex.filter(
                    (rnd: any) => !rnd?.t0Result.alreadyExist
                  ).length,
                })}
              />
            ) : (
              <WarningBand
                message={
                  t("import_silex.error_band.load_x_file_failed", {
                    fileName: file.name,
                  }) +
                  ". " +
                  (imports.silex.length === 1
                    ? t("import_silex.error_band.already_exists_round", {
                        round:
                          imports.silex[0].t0Result.label +
                          `[${imports.silex[0].t0Result.codeTournee}]`,
                      })
                    : t("import_silex.error_band.already_exists_rounds", {
                        rounds: imports.silex
                          .map(
                            (rnd: any) =>
                              rnd.t0Result.label +
                              `[${rnd.t0Result.codeTournee}]`
                          )
                          .join(", "),
                      }))
                }
              />
            )
          ) : (
            imports.error && (
              <ErrorBand message={<p>{getErrorMessage(imports.error)}</p>} />
            )
          )}
        </div>
        {imports.silex &&
          (adminImport ? (
            <div className="tab-onglet-import-container">
              <div className="onglet-container">
                {_.uniq<string>(
                  imports.silex
                    .map((round: any) =>
                      _.entries(round)
                        .filter((rndMap: any) => rndMap[1] !== null)
                        .map((rndMap: any) => rndMap[0])
                    )
                    .flat()
                )
                  .map((type: string) => parseInt(type[1]))
                  .map((el: number) => (
                    <div
                      key={`t${el}Result`}
                      className={`onglet ${
                        lineType === el
                          ? "actif"
                          : !_.get(selectedRound, `t${el}Result`)
                          ? "inactif"
                          : ""
                      }`}
                      onClick={() => {
                        if (_.get(selectedRound, `t${el}Result`)) {
                          setLineType(el);
                        }
                      }}
                    >
                      <span>{`Type ${el}`}</span>
                    </div>
                  ))}
              </div>
              {_.get(selectedRound, `t${lineType}Result`) && (
                <BootstrapTable
                  keyField="identifiant"
                  bootstrap4
                  bordered
                  hover
                  data={getAdminData(selectedRound, lineType)}
                  columns={_.get(adminImportColumns, `t${lineType}Result`)}
                />
              )}
            </div>
          ) : (
            <>
              <h3>{t("import_silex.report_title.importation_report")}</h3>
              <BootstrapTable
                keyField="results"
                bootstrap4
                bordered={false}
                hover
                data={imports.silex.map((rnd) => rnd.t0Result)}
                columns={columns}
                rowStyle={rowStyle}
              />
            </>
          ))}
        <ImportSilexErrorTable
          imports={imports}
          lineType={lineType}
          setLineType={setLineType}
          selectedRound={selectedRound}
          setSelectedRound={setSelectedRound}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

function mapStateToProps(state: any) {
  const { authentication, imports } = state;
  const { user } = authentication;
  return {
    user,
    imports,
  };
}

const mapping: any = connect(mapStateToProps)(ImportSilex);
const connectedImportSilex = withRouter(mapping);
const tr = withTranslation()(connectedImportSilex);
export default tr;
