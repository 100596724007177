import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import SvgCleVerte from "../SvgComponents/CleVert";

interface Props {
  isOpen: boolean;
  toggle: Function;
  types: Array<any>;
  locales: any;
  handleChange: Function;
  submit: Function;
  t: Function;
}

class AddProfil extends Component<Props, any> {
  render() {
    const { isOpen, toggle, types, locales, handleChange, submit, t } =
      this.props;
    const options = types
      ? types.map((el) => ({
          value: el,
          label: t(`permission.text.${el.name}`),
        }))
      : [];
    return (
      <Modal isOpen={isOpen} toggle={() => toggle()} backdrop size="lg">
        <ModalHeader>
          <SvgCleVerte height="1em" width="1em" fill="currentcolor" /> &nbsp;{" "}
          {t("all.user.add_profile")}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="text">{t("all.column_text.name")}</Label>
            <Input
              type="text"
              name="text"
              placeholder={t("all.user.profile_name")}
              onChange={(e: any) => handleChange(e.target.value, false)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="text">{t("all.user.permission_plural")}</Label>
            <Select
              isMulti
              name="colors"
              options={options}
              className="basic-multi-select"
              s
              classNamePrefix="select"
              onChange={(e: any) => handleChange(e, true)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className={"profil-modal-footer"}>
          <Button color="danger" onClick={() => toggle()}>
            {t("all.button.cancel")}
          </Button>
          <Button color="success" onClick={() => submit()}>
            {t("all.button.register")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const tr = withTranslation()(AddProfil);
export default tr;
