import React, { Component, ComponentType } from "react";
import SvgAlarm from "../SvgComponents/AlarmeVert";
import { Alert, UncontrolledTooltip } from "reactstrap";
import { connect } from "react-redux";
import { Chart } from "../Widget";
import _ from "lodash";
import { formatAlarmPdi } from "../_helpers/alarms-types-helper";
import colorActions from "../_actions/color.actions";
import masksActions from "../_actions/mask.actions";
import maskActions from "../_actions/mask.actions";
import getC from "../MaskBar/AlarmPicto.js";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

interface Props {
  meters: any;
  locales: any;
  alarm: any;
  update: Function;
  colors: any;
  dispatch: Function;
  pdis: any;
  handleChart: Function;
  mask: any;
  t: Function;
}

class AlarmesPdi extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      type: null,
      labels: [],
      values: [],
      alarmType: [],
      listAlarmActive: [],
      desacAlarm: [],
      desacList: [],
    };
    this.getAdditionalInfo = this.getAdditionalInfo.bind(this);
    this.updateActive = this.updateActive.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(colorActions.load());
    dispatch(masksActions.getAvailableAlarms());
  }

  static getDerivedStateFromProps(props: Props, state: any) {
    const copyState = _.clone(state);
    copyState.values = [];
    copyState.labels = [];
    if (props.meters && props.meters.allMetersInfo) {
      props.meters.allMetersInfo.map((meter: any) => {
        copyState.values = _.concat(copyState.values, meter.alarms.data).sort(
          (a: any, b: any) =>
            new Date(a.date).valueOf() - new Date(b.date).valueOf()
        );
        _.get(props, "mask.masks", []).forEach((mask: any) => {
          copyState.values.forEach((value: any) => {
            if (value.types[mask.alarmName] === true) {
              copyState.desacList.push({
                date: value.date,
                alarm: mask.alarmName,
              });
              delete value.types[mask.alarmName];
            }
          });
        });
        if (props.mask && props.mask.alarmDesac) {
          copyState.desacList.forEach((desac) => {
            if (
              props.mask.alarmDesac.find(
                (alarm) => desac.alarm === alarm.alarmName
              )
            ) {
              const index = copyState.values.findIndex(
                (value) => value.date === desac.date
              );
              copyState.values[index].types[desac.alarm] = true;
            }
          });
        }
        copyState.labels = copyState.values
          .filter(
            (value: any) =>
              !props.mask.masks.find((mask: any) => value.types[mask.alarmName])
          )
          .map((el: any) => el.date);
      });
    }
    return copyState;
  }

  getAdditionalInfo = (type: string) => {
    return {
      type,
    };
  };

  updateActive(listAlarmActive: Array<any>) {
    this.setState({ listAlarmActive });
  }

  onClickChart = (alarm: any) => {
    const { dispatch } = this.props;
    const { desacAlarm } = this.state;

    if (
      desacAlarm.find((alarmName: string) => alarmName.includes(alarm.name))
    ) {
      dispatch(maskActions.activMask(alarm.name));
      this.setState({
        desacAlarm: desacAlarm.filter(
          (alarmName: string) => !alarmName.includes(alarm.name)
        ),
      });
    } else {
      dispatch(maskActions.desactivMask(alarm.name));
      desacAlarm.push(alarm.name);
      this.setState({
        desacAlarm,
      });
    }
  };

  render() {
    const { meters, locales, alarm, update, colors, handleChart, mask, t } =
      this.props;
    const { labels, values, desacAlarm, desacList } = this.state;
    let dataList: any = [];
    if (meters && meters.allMetersInfo) {
      let newValues = values;
      mask.masks.forEach((mask: any) => {
        const tempList = newValues.filter(
          (value: any) => value.types[mask.alarmName] !== true
        );
        newValues = tempList;
      });
      dataList = formatAlarmPdi(newValues, desacAlarm, locales, colors, t);
    }
    const hasAlarmsToDisplay = _.values(dataList.values).some((it) =>
      it.data.some((i) => i !== 0)
    );
    return (
      <div className="block-pdi-container own-container pdi-alarm">
        <div className="title flex-box">
          <SvgAlarm height="2em" fill="#31c6b3" />
          <h3>{t("all.alarm_meter.alarm_plural")}</h3>
        </div>
        <div className="alarm-item-selector flex-box">
          <h5 style={{ color: "black" }}>{t("all.mask.mask_plural")} :</h5>
          {mask.alarms &&
            mask.alarmDesac &&
            mask.masks &&
            mask.alarms.map((el) => (
              <div
                className="alarm-item"
                id={el.name}
                onClick={() => this.onClickChart(el)}
              >
                {getC(el.name, {
                  height: "2em",
                  width: "2em",
                  fill: mask.masks.find((mask: any) =>
                    mask.alarmName.includes(el.name)
                  )
                    ? "#31c6b3"
                    : mask.alarmDesac.find((mask: any) =>
                        mask.alarmName.includes(el.name)
                      )
                    ? "#666"
                    : "lightgrey",
                  opacity:
                    mask.masks.find((mask: any) =>
                      mask.alarmName.includes(el.name)
                    ) ||
                    mask.alarmDesac.find((mask: any) =>
                      mask.alarmName.includes(el.name)
                    )
                      ? 1
                      : 0.3,
                })}
                <UncontrolledTooltip placement="top" target={el.name}>
                  {" "}
                  {t(`alarm.type.${el.name.toLowerCase()}`)}
                  <div style={{ textAlign: "left", display: "bloc" }}>
                    {mask.masks.find((mask: any) =>
                      mask.alarmName.includes(el.name)
                    ) && (
                      <>
                        <div>
                          <span>
                            {t("all.text.start")}:{" "}
                            {moment
                              .utc(
                                mask.masks.find((mask: any) =>
                                  mask.alarmName.includes(el.name)
                                ).startDate
                              )
                              .format("DD/MM/YYYY")}
                          </span>
                        </div>
                        <div>
                          <span>
                            {t("all.text.end")}:{" "}
                            {moment
                              .utc(
                                mask.masks.find((mask: any) =>
                                  mask.alarmName.includes(el.name)
                                ).endDate
                              )
                              .format("DD/MM/YYYY")}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </UncontrolledTooltip>
              </div>
            ))}
        </div>
        <div className="flex-box infinite-box" style={{ overflowX: "auto" }}>
          {meters && meters.allMetersInfo && (
            <div
              className="chart-container"
              style={{ height: hasAlarmsToDisplay ? "600px" : "auto" }}
            >
              {hasAlarmsToDisplay ? (
                <Chart
                  labels={labels}
                  data={dataList.values}
                  type={"StackedHistogram"}
                  formatTime={
                    alarm.dataSourceProperty.displayProperty.displayZoom
                  }
                  additional={this.getAdditionalInfo(
                    alarm.dataSourceProperty.displayID
                  )}
                  click={(event: any, dataInfo: any) =>
                    handleChart(dataInfo, dataList)
                  }
                  t={t}
                />
              ) : (
                <Alert color="warning">{t("fiche_detail.text.no_alarm")}</Alert>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const { meters, alarms, locales, pdis, colors, mask } = state;

  return {
    meters,
    alarms,
    locales,
    pdis,
    colors,
    mask,
  };
}

const connectedAlarmesPdi = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(AlarmesPdi);
export default withTranslation()(withRouter(connectedAlarmesPdi));
