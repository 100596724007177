import React, { Fragment } from "react";
import { WidgetContent, Widget } from "../_entities/widget";
import BaseWidget from "./BaseWidget";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { withTranslation } from "react-i18next";

interface Props {
  content: WidgetContent;
  widget: Widget | undefined;
  dataPreview: any;
  t: Function;
}

interface State {
  modal: boolean;
}

/**
 * Affiche le composant de prévisualisation de widget
 *
 * @class WidgetPreview
 * @extends Component
 */
class WidgetPreview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  /**
   * Gère l'ouverture/fermeture de la modal de preview
   *
   * @method toggle
   */
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { content, widget, dataPreview, t } = this.props;
    return (
      <Fragment>
        <Button color="primary" type="button" onClick={this.toggle}>
          Prévisualiser
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
          <ModalHeader toggle={this.toggle}>Prévisualisation</ModalHeader>
          <ModalBody>
            <BaseWidget
              dataPreview={dataPreview}
              data={widget}
              match={null}
              isPreview={true}
            />
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

const tr = withTranslation()(WidgetPreview);
export default tr;
