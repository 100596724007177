import mapboxdraw from "@mapbox/mapbox-gl-draw";
import {
  CircleMode,
  DragCircleMode,
  DirectMode,
  SimpleSelectMode,
} from "mapbox-gl-circle";
//@ts-ignore
import { Map } from "!mapbox-gl";

const token =
  "pk.eyJ1IjoiYmptbjciLCJhIjoiY2t0c2Z3Y2x3MHlkcDJ1cW9tNTBqMGxjcSJ9.XuH4K6sOjxNJ_Af-t-29pQ";

const properties = {
  draw: {
    userProperties: true,
    displayControlsDefault: false,
    controls: {
      polygon: true,
      trash: true,
    },
    modes: {
      ...mapboxdraw.modes,
      draw_circle: CircleMode,
      drag_circle: DragCircleMode,
      direct_select: DirectMode,
      simple_select: SimpleSelectMode,
    },
  },

  geolocatedControl: {
    positionOptions: {
      enableHighAccuracy: true,
    },
    trackUserLocation: true,
    showUserHeading: true,
  },

  popup: {
    offset: {
      top: [3, 1],
    },
    closeButton: false,
    closeOnClick: false,
  },

  directions: {
    accessToken: token,
    unit: "metric",
    profile: "mapbox/driving",
    interactive: false,
    controls: {
      instructions: false,
      profileSwitcher: false,
      inputs: false,
    },
  },

  drawFeatures: ["draw.create", "click", "draw.update", "draw.selectionchange"],
  controls: ["top-right", "bottom-right"],
};

function mapDeclaration(
  container: any,
  center: Array<number>,
  zoom: number
): Map {
  return {
    accessToken: mapProperties.token,
    container: container,
    style: "mapbox://style/mapbox/streets-v11?optimize=true",
    center: center,
    zoom: zoom,
    antialias: true,
    maxZoom: 21,
  };
}

const mapProperties = {
  token,
  properties,
  mapDeclaration,
};

export default mapProperties;
