import React, { ComponentType } from "react";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { WithTranslation, withTranslation } from "react-i18next";
import { getActiveClassByPath } from "../_helpers";
import { User } from "../_entities/user";
import TourneesVertes from "../SvgComponents/TourneesVertes";
import gestionnaireAction from "../_actions/gestionnaire.actions";
import SvgDetailsPointCollecteBlanc from "../SvgComponents/DetailsSiteBlanc";
import SvgStatistiqueVert from "../SvgComponents/StatistiqueVert";
import SvgReport from "../SvgComponents/Report";
import { compose } from "redux";

type Props = {
  user: User;
  locations: any;
  gestionnaire: any;
  location: any;
  tournee: any;
  dispatch: any;
} & WithTranslation;

interface State {
  isOpen: boolean;
}

class SidebarTournees extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  componentDidMount() {
    const { dispatch, location } = this.props;
    const newId = location.pathname.split("/")[2];
    dispatch(gestionnaireAction.getGestionnaire(newId));
  }

  render() {
    const { isOpen } = this.state;
    const { gestionnaire, location, tournee, t } = this.props;
    const regex = /\/gestionnaires\/\d+\/synchronisation\/tournees\/.+/gm;
    return (
      <div>
        {gestionnaire &&
          gestionnaire.gestionnaire &&
          location &&
          location.pathname &&
          location.pathname.match(regex) && (
            <div className="sidebar-location sidebar-tournee">
              <Navbar className="sidebar-location-wrapper" dark expand="md">
                <div className="sidebar-synchro">
                  <NavbarToggler onClick={this.toggle} />
                  <Collapse isOpen={isOpen} navbar>
                    <Nav navbar>
                      <NavbarBrand
                        href={`/gestionnaires/${
                          gestionnaire.gestionnaire.id
                        }/synchronisation/tournees/${_.get(
                          tournee,
                          "info.code",
                          ""
                        )}`}
                        className="center"
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                        id="sidebar-tournee"
                      >
                        {_.get(tournee, "info.name", "")}
                      </NavbarBrand>
                      <NavItem>
                        <NavLink
                          className={getActiveClassByPath(
                            location.pathname,
                            "tourneeInfo"
                          )}
                          href={`/gestionnaires/${
                            gestionnaire.gestionnaire.id
                          }/synchronisation/tournees/${_.get(
                            tournee,
                            "info.code"
                          )}`}
                          id="sidebar-tournee"
                        >
                          <SvgDetailsPointCollecteBlanc
                            fill="currentcolor"
                            stroke="currentcolor"
                            height="1em"
                            width="1em"
                          />
                          <span style={{ paddingLeft: "5px" }}>
                            {t("sidebar_tournees.nav_link.round_detail")}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={getActiveClassByPath(
                            location.pathname,
                            "fiche"
                          )}
                          href={`/gestionnaires/${
                            gestionnaire.gestionnaire.id
                          }/synchronisation/tournees/${_.get(
                            tournee,
                            "info.code"
                          )}/fiche`}
                          id="sidebar-tournee"
                        >
                          <TourneesVertes
                            fill="currentcolor"
                            stroke="currentcolor"
                            height="1em"
                            width="1em"
                          />
                          <span style={{ paddingLeft: "5px" }}>
                            {t("sidebar_tournees.nav_link.record_plural")}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={getActiveClassByPath(
                            location.pathname,
                            "statistique"
                          )}
                          href={`/gestionnaires/${
                            gestionnaire.gestionnaire.id
                          }/synchronisation/tournees/${_.get(
                            tournee,
                            "info.code"
                          )}/statistique`}
                          id="sidebar-tournee"
                        >
                          <SvgStatistiqueVert
                            fill="currentcolor"
                            stroke="currentcolor"
                            height="1em"
                            width="1em"
                          />
                          <span style={{ paddingLeft: "5px" }}>
                            {t("sidebar_tournees.nav_link.stat_plural")}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={getActiveClassByPath(
                            location.pathname,
                            "report"
                          )}
                          href={`/gestionnaires/${
                            gestionnaire.gestionnaire.id
                          }/synchronisation/tournees/${_.get(
                            tournee,
                            "info.code"
                          )}/report`}
                          id="sidebar-tournee"
                        >
                          <SvgReport
                            fill="currentcolor"
                            height="1.5em"
                            width="1.5em"
                            style={{ marginLeft: "-3px" }}
                          />
                          <span style={{ paddingLeft: "2px" }}>
                            {t("bread_crumb.name.report_plural")}
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </div>
              </Navbar>
            </div>
          )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, match, locations, gestionnaire, tournee } = state;
  const { user } = authentication;
  return {
    user,
    match,
    locations,
    gestionnaire,
    tournee,
  };
}

const connectedSidebarTournees = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(SidebarTournees);
export default withTranslation()(connectedSidebarTournees);
