import { rndTemplateConstants } from "../_constants";
import { TagDto, TemplateDto } from "../_interfaces/TourneeCreationTypes";
import rndTemplateService from "../_services/rndTemplate.service";

function createTemplate(template: TemplateDto) {
  function request() {
    return { type: rndTemplateConstants.CREATE_TEMPLATE_REQUEST };
  }
  function success(template: TemplateDto) {
    return { type: rndTemplateConstants.CREATE_TEMPLATE_SUCCESS, template };
  }
  function failure(error: string | Object) {
    return { type: rndTemplateConstants.CREATE_TEMPLATE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    rndTemplateService
      .createTemplate(template)
      .then((template: TemplateDto) => {
        dispatch(success(template));
      })
      .catch((error: string | Object) => {
        dispatch(failure(error));
      });
  };
}

function getAllTemplates() {
  function request() {
    return { type: rndTemplateConstants.GET_ALL_TEMPLATE_REQUEST };
  }
  function success(listTemplates: Object) {
    return {
      type: rndTemplateConstants.GET_ALL_TEMPLATE_SUCCESS,
      listTemplates,
    };
  }
  function failure(error: string | Object) {
    return { type: rndTemplateConstants.GET_ALL_TEMPLATE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    rndTemplateService
      .getAllTemplates()
      .then((listTemplates: any) => dispatch(success(listTemplates)))
      .catch((error: string | Object) => {
        dispatch(failure(error));
      });
  };
}

function updateTemplate(template: TemplateDto) {
  function request() {
    return { type: rndTemplateConstants.UPDATE_TEMPLATE_REQUEST };
  }
  function success(template: TemplateDto) {
    return { type: rndTemplateConstants.UPDATE_TEMPLATE_SUCCESS, template };
  }
  function failure(error: string | Object) {
    return { type: rndTemplateConstants.UPDATE_TEMPLATE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    rndTemplateService
      .updateTemplate(template)
      .then((template: TemplateDto) => {
        dispatch(success(template));
        dispatch(getAllTags());
      })
      .catch((error: string | Object) => {
        dispatch(failure(error));
      });
  };
}

function deleteTemplate(templateId: number) {
  function request() {
    return { type: rndTemplateConstants.DELETE_TEMPLATE_REQUEST };
  }
  function success(template: TemplateDto) {
    return { type: rndTemplateConstants.DELETE_TEMPLATE_SUCCESS, template };
  }
  function failure(error: string | Object) {
    return { type: rndTemplateConstants.DELETE_TEMPLATE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    rndTemplateService
      .deleteTemplate(templateId)
      .then((template: TemplateDto) => {
        dispatch(success(template));
        dispatch(getAllTemplates());
      })
      .catch((error: string | Object) => {
        dispatch(failure(error));
      });
  };
}

function createTag(tag: TagDto) {
  function request() {
    return { type: rndTemplateConstants.CREATE_TAG_REQUEST };
  }
  function success(tag: TagDto) {
    return { type: rndTemplateConstants.CREATE_TAG_SUCCESS, tag };
  }
  function failure(error: string | Object) {
    return { type: rndTemplateConstants.CREATE_TAG_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    rndTemplateService
      .createTag(tag)
      .then((tag: TagDto) => {
        dispatch(success(tag));
        dispatch(getAllTags());
      })
      .catch((error: string | Object) => {
        dispatch(failure(error));
      });
  };
}

function getAllTags() {
  function request() {
    return { type: rndTemplateConstants.GET_ALL_TAG_REQUEST };
  }
  function success(tagList: TagDto[]) {
    return { type: rndTemplateConstants.GET_ALL_TAG_SUCCESS, tagList };
  }
  function failure(error: string | Object) {
    return { type: rndTemplateConstants.GET_ALL_TAG_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    rndTemplateService
      .getAllTag()
      .then((tagList: TagDto[]) => dispatch(success(tagList)))
      .catch((error: string | Object) => {
        dispatch(failure(error));
      });
  };
}

function updateTag(tag: TagDto) {
  function request() {
    return { type: rndTemplateConstants.UPDATE_TAG_REQUEST };
  }
  function success(tag: TagDto) {
    return { type: rndTemplateConstants.UPDATE_TAG_SUCCESS, tag };
  }
  function failure(error: string | Object) {
    return { type: rndTemplateConstants.UPDATE_TAG_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    rndTemplateService
      .updateTag(tag)
      .then((tag: TagDto) => {
        dispatch(success(tag));
        dispatch(getAllTags());
      })
      .catch((error: string | Object) => {
        dispatch(failure(error));
      });
  };
}

function deleteTag(tag: TagDto) {
  function request() {
    return { type: rndTemplateConstants.DELETE_TAG_REQUEST };
  }
  function success(tag: TagDto) {
    return { type: rndTemplateConstants.DELETE_TAG_SUCCESS, tag };
  }
  function failure(error: string | Object) {
    return { type: rndTemplateConstants.DELETE_TAG_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    rndTemplateService
      .deleteTag(tag.id!)
      .then((tag: TagDto) => {
        dispatch(success(tag));
        dispatch(getAllTags());
      })
      .catch((error: string | Object) => {
        dispatch(failure(error));
      });
  };
}

function clearSuccess() {
  return (dispatch: Function) => {
    dispatch({ type: rndTemplateConstants.CLEAR_SUCCESS });
  };
}

function clearError() {
  return (dispatch: Function) => {
    dispatch({ type: rndTemplateConstants.CLEAR_ERROR });
  };
}

const tplActions = {
  createTemplate,
  getAllTemplates,
  updateTemplate,
  deleteTemplate,
  createTag,
  getAllTags,
  updateTag,
  deleteTag,
  clearSuccess,
  clearError,
};

export default tplActions;
