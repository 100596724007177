import React, {
  Component,
  ComponentType,
  Dispatch,
  SetStateAction,
} from "react";
import { Button, Col, Row, UncontrolledTooltip } from "reactstrap";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import _, { cloneDeep as _cloneDeep } from "lodash";
import { connect } from "react-redux";
import Select from "react-select";
import LineFilter from "./LineFilter";
import { filterActions, pdiActions } from "../_actions";
import OperatorList from "../_shared/OperatorList";
import ModalSauvegardeConfig from "../SavingSettings/SavingSettingsModal";
import ListTools from "../List/ListTools";
import { Filter, FilterConfig, Operator, Rule } from "../_interfaces/filter";
import FiltreVert from "../SvgComponents/FiltreVert";
import PoubelleBleue from "../SvgComponents/PoubelleBleu";
import Ajout from "../SvgComponents/AjoutBleu";
import SauvegardeBleu from "../SvgComponents/SauvegardeBleu";
import DisquettePlus from "../SvgComponents/DisquettePlus";
import { withRouter } from "react-router";
import StaticList from "../TourneeFiches/StaticList";
import {
  getLocalStorage,
  setLocalStorage,
} from "../_helpers/localStorage-helper";
import getC from "../TourneeFiches/Picto_correspondance";
import moment from "moment";
import { withTranslation } from "react-i18next";
import SwitchButton from "../_components/SwitchButton";
import { compose } from "redux";

interface Props {
  sendListFiltered: any;
  listData: Array<any>;
  listOperator: {
    operators: any;
  };
  listFilters: Array<Filter>;
  defaultConfig?: Array<Rule> | null | undefined;
  idContext: any;
  idSite: number;
  dispatch: any;
  user: any;
  filters: any;
  save: boolean | null | undefined;
  filterSelected?: any | null;
  mask: any;
  t: Function;
  locations: any;
  setRuleList?: Dispatch<SetStateAction<Array<Rule>>>;
  tournee: any;
  match: any;
  location: any;
  alarms: any;
  defaultShow: any;
  isOpen: any;
  autosave: any;
  pdis: any;
  handleOpen: any;
}

interface State {
  ruleList: Array<Rule>;
  currentIndex: number;
  showFilters: boolean;
  savedFiltersList: Array<FilterConfig>;
  askSaveQuery: boolean;
  filterSelected: any; // {label: string, value: Array(rule), id: int}
  keyStorage: string;
  optionOr: boolean;
  listRulesDateApplied?: any;
}

const userInput = (el: any) => {
  return (
    <div>
      {getC(el.userInput.value, { height: "1.5em", width: "1.5em" })}
      {
        StaticList.etatFiche.find(
          (etat: any) => el.userInput.value === etat.picto
        ).label
      }
    </div>
  );
};

/**
 * Pour utiliser QueryBuilder :
 * Créer une méthode de callback dans le component source qui recevra en paramètre la liste de données filtrée (avec un state dédié pour l'affichage)
 * Passer via la propriété listData la liste de données du tableau
 * Passer une liste d'opérateurs sous la même forme que le fichier src/_shared/OperatorList.js
 * Passer une liste de champs sous la même forme que le fichier src/_shared/ListFields.json
 * (La forme est aussi présente en tant que PropTypes de QueryBuilder)
 *
 */
class QueryBuilder extends Component<Props, State> {
  static defaultProps = {
    save: true,
  };
  /**
   * Régénère les operations des filtres sauvegardés
   *
   * @static
   * @method regenerateOperations
   * @memberof QueryBuilder
   * @param {Array<Rule>} content Liste de rules à régénerer
   */
  static regenerateOperations = (
    content: Array<Rule>,
    extraParameter?: string
  ) => {
    if (extraParameter) {
      const extraParameterDecoded = decodeURI(extraParameter);
      content.forEach((rule) => (rule.userInput = extraParameterDecoded));
    }
    return content
      .filter((r) => QueryBuilder.isCorrectRule(r))
      .map((f: Rule, index: any) => {
        const otherF: Rule = f;
        const operatoList: any = OperatorList;
        otherF.operator.operation =
          f.filter.type === "checkbox"
            ? operatoList.operators[f.filter.type][f.filter.value].find(
                (o: Operator) => o.value === f.operator.value
              ).operation
            : operatoList.operators[f.filter.type].find(
                (o: Operator) => o.value === f.operator.value
              ).operation;
        if (
          f.filter.type === "selectCont"
          // || f.filter.type === 'selectContValue'
        ) {
          if (f.filter.value === "consoState" && extraParameter) {
            f.userInput = StaticList.consoState.find(
              (el) =>
                el.value === extraParameter ||
                extraParameter.includes(el.label.toLowerCase())
            );
          }
        }
        if (f.filter.value === "installDate" && extraParameter) {
          f.userInput = extraParameter;
        }
        if (f.filter.value === "radioManufacturer" && extraParameter) {
          f.userInput = extraParameter;
        }
        if (content.find((el: any) => el.num === f.num)) {
          f.num = index;
        }
        return otherF;
      });
  };

  /**
   * Vérifie si la règle est correct ou non
   *
   * @static
   * @method isCorrectRule
   * @memberof QueryBuilder
   * @param {Rule}
   * @returns {boolean}
   */
  static isCorrectRule = ({
    filter,
    operator,
    userInput,
    secondInputChange,
    defineLater,
  }: any): boolean => {
    return (
      filter &&
      operator &&
      ((userInput &&
        userInput.toString().length > 0 &&
        (operator.value != "between" ||
          (secondInputChange && secondInputChange.toString().length > 0))) ||
        operator.noEntry ||
        filter.type === "checkbox" ||
        defineLater)
    );
  };

  constructor(props: Props) {
    super(props);
    const {
      defaultConfig,
      locations,
      tournee,
      match: {
        params: { locationId },
      },
    } = props;
    const listParameters = this.props.location.search
      .replace("?", "")
      .split("=");
    const inAlarmAdd = [
      new RegExp("/alerts/add"),
      new RegExp("/alerts/editalert"),
      new RegExp("/virtuals/edit"),
      new RegExp("/mask/editmask"),
      new RegExp("/customexport/editexport"),
      /\/customexport\/add\?id=\d/gm,
    ].some((el) =>
      el.test(this.props.location.pathname + this.props.location.search)
    );
    const [typeUrl, valueType] = listParameters;
    let keyStorage = `filter-${props.idContext}-`;
    if (
      props.idContext === ListTools.typeOfList.Fiche &&
      this.props.location.pathname.includes("/gestionnaires")
    ) {
      keyStorage = keyStorage + tournee.info.code;
    } else {
      keyStorage = keyStorage + locationId;
    }
    const localData = getLocalStorage(keyStorage);
    let ruleList =
      typeUrl && !inAlarmAdd
        ? QueryBuilder.regenerateOperations(
            ListTools.filterURL[typeUrl] || [],
            valueType
          )
        : localData
        ? QueryBuilder.regenerateOperations(localData.ruleList || [])
        : [];
    this.state = {
      ruleList,
      currentIndex: ruleList.length,
      showFilters: ruleList.length > 0,
      savedFiltersList: [],
      askSaveQuery: false,
      filterSelected: {}, // {label: string, value: Array(rule), id: int}
      keyStorage,
      optionOr: localStorage.getItem(keyStorage + "_optionor") === "true",
    };
  }

  componentDidMount() {
    const { ruleList } = this.state;
    const { dispatch, user, idContext, mask, alarms, defaultShow } = this.props;
    window.addEventListener("keypress", this.applyWithKeyboard);
    let ruleListMount = _.cloneDeep(
      _.get(
        mask,
        "fetchedMask.filterClause",
        _.get(alarms, "fetchedAlarm.filterClause", ruleList)
      ) || ruleList
    );
    ruleListMount.forEach((filtre) => {
      filtre.operator =
        filtre.filter.type === "checkbox"
          ? OperatorList.operators[filtre.filter.type][
              filtre.filter.value
            ].find((o: Operator) => o.value === filtre.operator.value)
          : OperatorList.operators[filtre.filter.type].find(
              (o: Operator) => o.value === filtre.operator.value
            );
    });
    this.setState(
      {
        showFilters: ruleListMount.length > 0 || defaultShow,
        ruleList: ruleListMount,
      },
      this.applyFilters
    );
    dispatch(filterActions.getAll(user.id, idContext));
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (this.props.setRuleList != null) {
      if (!Object.is(this.state.ruleList, prevState.ruleList)) {
        this.props.setRuleList(this.state.ruleList);
      }
    } else {
      if (!_.isEqual(this.props.listData, prevProps.listData)) {
        this.applyFilters();
      }
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const {
      filters,
      defaultConfig,
      dispatch,
      idContext,
      idSite,
      isOpen,
      handleOpen,
    } = props;
    const {
      savedFiltersList: savedFiltersListState,
      filterSelected: baseSelected,
      ruleList,
      showFilters: showFiltersState,
    } = state;

    let showFilters = _.cloneDeep(showFiltersState);
    // Reception des colonnes sauvegardés en base
    if (handleOpen && isOpen !== showFiltersState) {
      showFilters = isOpen;
    }

    let filterSelected = baseSelected;
    const localSelected = ListTools.manageLocalFilters(
      idSite,
      {},
      "get",
      idContext
    );
    if (Object.keys(baseSelected).length === 0) {
      filterSelected = localSelected;
    }
    // Reception des configurations de filtres de la base
    if (filters.items && Object.keys(filters.items).length > 0) {
      // Si il y a une configuration par défaut alors on prend cette configuration et on l'ajoute à la liste des règles et on l'applique
      /*const ruleList = defaultConfig
        ? defaultConfig.map((filter) => {
            const copy = filter;
            copy.filterApplied = true;
            return copy;
          })
        : [];*/
      // On traite les configurations de filtres de la base
      // On garde seulement celle qui correspondent à l'identifiant du contexte de cette instance (passé en props)
      // On trie dans l'ordre décroissan (du plus récent au plus vieux)
      // Et on met en forme (en régénérant les opérations au préalable pour que les fonctions soit utilisables)
      const savedFiltersList = _cloneDeep(filters.items)
        .filter((item: any) => item.type === idContext)
        .sort((a: any, b: any) =>
          a.updatedAt && b.updatedAt
            ? ListTools.sortDates(a.updatedAt, b.updatedAt, "desc")
            : 0
        )
        .map((listFilters: any) => {
          const contentOfList = QueryBuilder.regenerateOperations(
            _cloneDeep(listFilters.content)
          );
          // Régénération des operations des filtres

          return {
            label: listFilters.name,
            value: contentOfList,
            id: listFilters.id,
            extraOptions: listFilters.extraOptions,
          };
        });
      // if (defaultConfig.length === 0) ruleList = ruleList.concat(savedFiltersList[savedFiltersList.length - 1].value);
      return {
        savedFiltersList,
        currentIndex: ruleList.length,
        filterSelected,
        showFilters,
      };
    }
    // S'il y a une nouvelle configuration de filtres inséré en base
    if (filters.filter && !filters.items) {
      // On régénère son opération
      const content = QueryBuilder.regenerateOperations(
        _cloneDeep(filters.filter.content)
      );
      const savedFiltersList = [
        { label: filters.filter.name, value: content, id: filters.filter.id },
      ].concat(savedFiltersListState.filter((f) => f.id !== filters.filter.id));
      return { savedFiltersList, filterSelected, showFilters };
    }
    // S'il y a une configuration qui a été supprimé de la base
    if (filters.deletedFilter && !filters.items) {
      const savedFiltersList = savedFiltersListState.filter(
        (f) => f.id !== filters.deletedFilter.id
      );
      return { savedFiltersList, filterSelected, showFilters };
    }
    return { filterSelected, showFilters };
  }

  /**
   * Ajout une ligne vide à ruleList
   *
   * @method addLineFilter
   * @memberof QueryBuilder
   */
  addLineFilter = () => {
    const { ruleList, currentIndex } = this.state;
    ruleList.push({
      filter: null as any,
      operator: null as any,
      userInput: "",
      num: currentIndex,
      filterApplied: false,
    });
    this.setState({ ruleList, currentIndex: currentIndex + 1 });
  };

  handleOptionOr = () => {
    const { keyStorage } = this.state;
    setLocalStorage(keyStorage + "_optionor", !this.state.optionOr);
    this.setState({ optionOr: !this.state.optionOr });
  };

  /**
   * Change la rule d'une ligne
   *
   * @callback
   * @method changeLineFilter
   * @memberof QueryBuilder
   * @param {string} indexLine Index de la ligne changée
   * @param {Rule} state Etat de la ligne
   */
  changeLineFilter = (indexLine: number, state: Rule) => {
    const { ruleList, filterSelected } = this.state;
    const { idSite, idContext, autosave } = this.props;
    ruleList[ruleList.findIndex((rule) => rule.num === indexLine)] = state;
    let localFilterSelected = filterSelected;
    localFilterSelected.value = ruleList;
    ListTools.manageLocalFilters(
      idSite,
      localFilterSelected,
      "post",
      idContext
    );
    const { filter, operator, userInput, defineLater } =
      ruleList[ruleList.findIndex((rule) => rule.num === indexLine)];
    const filterGood: boolean | undefined =
      filter &&
      operator &&
      ((userInput && userInput.toString().length > 0) ||
        operator.noEntry ||
        defineLater);
    this.setState({ ruleList }, () => {
      if (filterGood && autosave) {
        this.applyFilters();
      }
    });
  };

  applyWithKeyboard = (e: any) => {
    if (e.keyCode === 13) {
      this.applyFilters();
    }
  };

  /**
   * Applique les filtres à la liste de données
   *
   * @event
   * @method applyFilters
   * @memberof QueryBuilder
   */
  applyFilters = (noReload: boolean = true) => {
    const {
      sendListFiltered,
      listData,
      idSite,
      idContext,
      filterSelected,
      locations,
      dispatch,
    } = this.props;
    const {
      ruleList,
      filterSelected: stateFilterSelected,
      keyStorage,
      listRulesDateApplied,
      optionOr,
    } = this.state;
    let listDataFiltered: any[] = [];
    if (optionOr) {
      ruleList.forEach((r) => {
        const { filter, operator, userInput, secondInputChange }: any = r;
        const input =
          operator.value === "between"
            ? userInput + "," + secondInputChange
            : userInput;
        listDataFiltered = _.uniq(
          _.flatten([
            listDataFiltered,
            listData.filter((elem) => {
              if (filter.value === "consoState") {
                return elem.consoState && userInput.label
                  ? elem.consoState.includes(userInput.label.toLowerCase())
                  : false;
              } else {
                if (!filter) {
                  return true;
                } else {
                  let valueBase = _.get(elem, filter.value);
                  return QueryBuilder.isCorrectRule({ filter, operator, input }) // SI la ligne est complète
                    ? operator.operation(input, valueBase) // J'applique le filter
                    : true; // SInon je passe
                }
              }
            }),
          ])
        );
      });
    } else {
      listDataFiltered = _.cloneDeep(listData);
      ruleList.forEach((r) => {
        const { filter, operator, userInput, secondInputChange }: any = r;
        const input =
          operator && operator.value === "between"
            ? userInput + "," + secondInputChange
            : userInput;
        listDataFiltered = listDataFiltered.filter((elem) => {
          if (filter && filter.value === "consoState") {
            return elem.consoState && userInput.label
              ? elem.consoState.includes(userInput.label.toLowerCase())
              : false;
          } else {
            if (!filter) {
              return true;
            } else {
              let valueBase = _.get(elem, filter.value);
              return (
                QueryBuilder.isCorrectRule({ filter, operator, userInput }) &&
                operator.operation(input, valueBase)
              ); // J'applique le filter
            }
          }
        });
      });
    }
    ruleList.map((r) => {
      r.filterApplied = QueryBuilder.isCorrectRule(r);
      return r;
    });

    let localFilterSelected = stateFilterSelected;
    if (
      Object.keys(stateFilterSelected).length === 0 &&
      filterSelected !== null
    ) {
      localFilterSelected = filterSelected;
    }
    localFilterSelected.value = ruleList;
    if (idSite)
      ListTools.manageLocalFilters(
        idSite,
        localFilterSelected,
        "post",
        idContext
      );
    ruleList.filter((r) => {
      if (
        _.get(r, "filter.type") &&
        _.get(r, "filter.type") === "text" &&
        !Array.isArray(r.userInput) &&
        r.userInput
      )
        r.userInput = [{ label: r.userInput, value: r.userInput }];
    });
    if (ruleList.length > 0) {
      const copyRule = _.cloneDeep(ruleList)
        .filter((r) => r.filterApplied)
        .map((el: any) => {
          if (_.get(el, "filter.value") === "ficheState") {
            el.userInput.label = null;
            el.filter.opts = null;
          }
          return el;
        });
      setLocalStorage(keyStorage, { ruleList: copyRule });
    }
    const mappedRule = ruleList.map((el: any) => {
      if (_.get(el, "filter.value") === "ficheState") {
        el.filter.opts = StaticList.etatFiche.map((etat: any) => ({
          value: etat.picto,
          label: (
            <div>
              {getC(etat.picto, { height: "1.5em", width: "1.5em" })}
              {etat.label}
            </div>
          ),
        }));
        el.userInput.label = userInput(el);
        return el;
      } else {
        return el;
      }
    });

    const listRulesDate = ruleList.filter(
      (r) =>
        r.filterApplied &&
        r.filter.value === "read.date" &&
        "<=" == r.operator.value
    );
    if (
      listRulesDate.length > 0 &&
      noReload &&
      JSON.stringify(listRulesDate) !== listRulesDateApplied
    ) {
      const inputEndDate = listRulesDate[listRulesDate.length - 1];
      const endDate = inputEndDate
        ? moment
            .utc(inputEndDate.userInput, "YYYY-MM-DD")
            .endOf("day")
            .toISOString()
        : moment.utc().endOf("day").toISOString();
    } else if (listRulesDate.length === 0) {
      dispatch(pdiActions.clearRangeDate());
    }
    this.setState({
      ruleList: mappedRule,
      currentIndex: Math.max(-1, ...ruleList.map((r) => r.num)) + 1,
      listRulesDateApplied: JSON.stringify(listRulesDate),
    });
    sendListFiltered(
      listDataFiltered,
      ruleList.filter((el) => el.filterApplied)
    );
  };

  /**
   * Suppression d'une ligne
   *
   * @event
   * @method removeLine
   * @memberof QueryBuilder
   * @param {number} numero Numero de la ligne à supprimer
   */
  removeLine = (numero: number) => {
    const { ruleList, filterSelected, keyStorage } = this.state;
    const { sendListFiltered, listData, idSite, idContext, locations } =
      this.props;
    const purgedRuleList = ruleList.filter((rule) => rule.num !== numero);
    purgedRuleList.forEach((value, index) => (value.num = index));
    if (purgedRuleList.length === 0) {
      sendListFiltered(listData);
      localStorage.removeItem(keyStorage);
    } else {
      let localFilterSelected = filterSelected;
      localFilterSelected.value = purgedRuleList;
      setLocalStorage(keyStorage, purgedRuleList);
    }
    this.setState({ ruleList: purgedRuleList }, this.applyFilters);
  };

  /**
   * Sauvegarde la configuration
   *
   * @method saveQuery
   * @memberof QueryBuilder
   * @param {string} nameOfConfig Nom de la configuration pour la sauvegarde
   */
  saveQuery = (nameOfConfig: string) => {
    const { dispatch, user, idContext } = this.props;
    const { ruleList, optionOr } = this.state;
    dispatch(
      filterActions.save(
        nameOfConfig,
        user.id,
        idContext,
        ruleList.filter((r) => QueryBuilder.isCorrectRule(r)),
        optionOr
      )
    );
  };

  /**
   * Mise à jour d'une configuration
   *
   * @method updateQuery
   * @memberof QueryBuilder
   * @param {string} nameOfConfig Nom de la configuration pour la mise à jour
   * @param {number} idConfig Identifiant de la configuration dans la base
   */
  updateQuery = (nameOfConfig: string, idConfig: number) => {
    const { dispatch, user, idContext } = this.props;
    const { ruleList, optionOr } = this.state;
    dispatch(
      filterActions.update(
        ruleList.filter((r) => QueryBuilder.isCorrectRule(r)),
        idConfig,
        nameOfConfig,
        user.id,
        idContext,
        optionOr
      )
    );
  };

  /**
   * Suppression d'une configuration
   *
   * @event
   * @method deleteQuery
   * @memberof QueryBuilder
   */
  deleteQuery = () => {
    const { dispatch, user, idContext, idSite } = this.props;
    const { savedFiltersList, filterSelected } = this.state;
    dispatch(
      filterActions.deleteFilter(
        savedFiltersList.find((f) => f.id === filterSelected.id),
        filterSelected.id,
        user.id,
        idContext
      )
    );
    this.setState({ filterSelected: {} }, this.applyFilters);
    ListTools.manageLocalFilters(idSite, {}, "delete", idContext);
  };

  /**
   * Mise à jour d'une configuration
   *
   * @event
   * @method updateSelectedConfig
   * @memberof QueryBuilder
   */
  updateSelectedConfig = () => {
    const { filterSelected } = this.state;
    this.updateQuery(filterSelected.label, filterSelected.id);
  };

  /**
   * Purge de la liste des règles
   *
   * @event
   * @method resetFilters
   * @memberof QueryBuilder
   */
  resetFilters = () => {
    const { sendListFiltered, listData, idSite, idContext, locations } =
      this.props;
    const { keyStorage } = this.state;
    const localFilterSelected = { value: [] };
    ListTools.manageLocalFilters(
      idSite,
      localFilterSelected,
      "delete",
      idContext
    );
    localStorage.removeItem(keyStorage);
    localStorage.removeItem(keyStorage + "_optionor");
    this.setState(
      { ruleList: [], currentIndex: 0, filterSelected: {} },
      this.applyFilters
    );
    sendListFiltered(listData);
  };

  /**
   * Application du filtre selectionnée
   *
   * @event
   * @method filterChange
   * @memberof QueryBuilder
   * @param {FilterConfig} filter Configuration de filtres à appliquer
   */
  filterChange = (f: FilterConfig, context: any) => {
    const { idSite, idContext, t } = this.props;
    const { keyStorage } = this.state;
    const cloneFilterConfig = _.cloneDeep(f);
    const ruleList =
      cloneFilterConfig && cloneFilterConfig.value
        ? _cloneDeep(cloneFilterConfig.value)
        : [];
    ruleList.forEach((el) => {
      el.filter.label = t(`columns.${el.filter.value}`);
      el.operator.label = t(
        `linefilter.operator.${el.filter.type}.${el.operator.value}`.toLowerCase()
      );
    });
    if (context.action !== "clear") {
      ListTools.manageLocalFilters(
        idSite,
        cloneFilterConfig,
        "post",
        idContext
      );
      setLocalStorage(
        keyStorage + "_optionor",
        _.get(f, "extraOptions.separator") === "or"
      );
      this.setState(
        {
          ruleList,
          currentIndex: ruleList.length,
          filterSelected: cloneFilterConfig,
          optionOr: _.get(f, "extraOptions.separator") === "or",
        },
        this.applyFilters
      );
    } else {
      ListTools.manageLocalFilters(idSite, { value: [] }, "delete", idContext);
      this.setState(
        { ruleList, currentIndex: ruleList.length, filterSelected: {} },
        this.applyFilters
      );
    }
  };

  checkEmptyFilter = () => {
    const { ruleList } = this.state;

    if (ruleList.length === 0) {
      return true;
    }

    const emptyItem = ruleList.find((it: any) => {
      return QueryBuilder.isCorrectRule(it);
    });

    return emptyItem;
  };

  /**
   * Sauvegarde de la configuration des règles
   *
   * @method toggle
   * @memberof QueryBuilder
   */
  toggle = () => {
    const { askSaveQuery } = this.state;
    this.setState({ askSaveQuery: !askSaveQuery });
  };

  render() {
    const { ruleList, showFilters, savedFiltersList, askSaveQuery } =
      this.state;
    let { filterSelected } = this.state;
    const { listOperator, listFilters, save, pdis, t, handleOpen } = this.props;
    return (
      <div>
        {/* MODAL COMMENCEMENT */}
        <ModalSauvegardeConfig
          saveConfig={this.saveQuery}
          updateConfig={this.updateQuery}
          openModal={askSaveQuery}
          toggle={this.toggle}
          listOfExistingConfig={savedFiltersList}
        />
        {/* MODAL FIN */}
        <Row style={{ margin: "0 0 0 10px" }}>
          <Button
            color="secondary"
            size="lg"
            outline
            active
            style={{
              borderColor: "white",
              backgroundColor: "white",
              color: "black",
              display: "inline-flex",
              alignItems: "center",
              marginLeft: "-14px",
              boxShadow: "none",
            }}
            onClick={() => {
              handleOpen && handleOpen(!showFilters);
              this.setState({ showFilters: !showFilters });
            }}
          >
            <FiltreVert
              height="1.5em"
              width="1.5em"
              fill="#31c6b3"
              strokeWidth="1.5"
            />
            <h2 style={{ margin: "0 10px" }}>
              {t("all.filter.filter_plural")}
            </h2>
            {showFilters ? <FaCaretDown /> : <FaCaretRight />}
          </Button>
          {save && showFilters && (
            <Col xs="4" sm="4" md="4">
              <Select
                options={savedFiltersList}
                id="savedFilters"
                placeholder={t("all.filter.saved_filter_plural")}
                onChange={this.filterChange as any}
                noOptionsMessage={() => t("all.filter.no_conf_filter_saved")}
                value={filterSelected}
                isClearable={true}
                disabled={pdis.loadingFilters ? "disabled" : ""}
              />
            </Col>
          )}
          {save && showFilters && (
            <Col xs="1" md="1" sm="1">
              <div
                id="rmSavedFilter"
                className={
                  filterSelected.id
                    ? "clickable round trash-filter"
                    : "round-disabled trash-filter"
                }
                role="presentation"
                onClick={filterSelected.id ? this.deleteQuery : () => {}}
              >
                <PoubelleBleue
                  height="1em"
                  width="1em"
                  fill={filterSelected.id ? "currentcolor" : "white"}
                />
                {filterSelected.id && (
                  <UncontrolledTooltip
                    placement="bottom"
                    target="rmSavedFilter"
                  >
                    {" "}
                    {t("all.filter.delete_saved_filter")}
                  </UncontrolledTooltip>
                )}
              </div>
            </Col>
          )}
          {save && showFilters && ruleList.length > 0 && (
            <Col xs="1" md="1" sm="1">
              <div
                id="UpdateConfigFilter"
                className={
                  filterSelected.id > 0 && this.checkEmptyFilter()
                    ? "clickable round save-filter"
                    : "round-disabled  save-filter"
                }
                role="presentation"
                onClick={
                  !filterSelected.id || !this.checkEmptyFilter()
                    ? () => {}
                    : this.updateSelectedConfig
                }
              >
                <SauvegardeBleu
                  height="1em"
                  width="1em"
                  fill={
                    filterSelected.id > 0 && this.checkEmptyFilter()
                      ? "currentcolor"
                      : "white"
                  }
                />
                {filterSelected.id && (
                  <UncontrolledTooltip
                    placement="bottom"
                    target="UpdateConfigFilter"
                  >
                    {" "}
                    {t("all.text.update_selected_conf")}
                  </UncontrolledTooltip>
                )}
              </div>
            </Col>
          )}
          {save && showFilters && ruleList.length > 0 && (
            <Col xs="1" md="1" sm="1">
              <div
                id="NewConfigFilter"
                className={
                  !this.checkEmptyFilter()
                    ? "round-disabled save-filter "
                    : "clickable round  save-filter"
                }
                role="presentation"
                onClick={!this.checkEmptyFilter() ? () => {} : this.toggle}
              >
                <DisquettePlus
                  height="1.4em"
                  width="1.4em"
                  fill={this.checkEmptyFilter() ? "currentcolor" : "white"}
                />
                {this.checkEmptyFilter() && (
                  <UncontrolledTooltip
                    placement="bottom"
                    target="NewConfigFilter"
                  >
                    {" "}
                    {t("all.text.save_new_conf")}
                  </UncontrolledTooltip>
                )}
              </div>
            </Col>
          )}
        </Row>

        {/* Désactivé jusqu'à ce que la fonctionnalité soit de nouveau opérationnelle
         {showFilters && (
          <Row style={{ margin: '10px 0px 10px 5px' }}>
            {ruleList.length > 0 && (
              <div style={{ display: 'flex' }}>
                <h3 style={{ margin: '0 20px' }}>Tous les filtres</h3>
                <SwitchButton
                  checked={this.state.optionOr}
                  handleChange={this.handleOptionOr}
                  switchClass="synchroSwitch"
                  onColor="#409F95"
                  offColor="#999999"
                  offHandleColor="#CCCCCC"
                  onHandleColor="#31C6B3"
                />
                <h3 style={{ margin: '0 20px' }}>Au moins un filtre</h3>
              </div>
            )}
          </Row>
        )} */}

        {showFilters &&
          ruleList.map((rule) => (
            <LineFilter
              key={`key-${rule.num}-${rule.filter && rule.filter.value}-${
                rule.operator && rule.operator.value
              }`}
              changeLineFilter={this.changeLineFilter}
              removeLine={this.removeLine}
              values={rule}
              listOperator={listOperator}
              listFilters={listFilters}
              filterSelected={filterSelected.id}
            />
          ))}
        {showFilters && (
          <div>
            <Row style={{ margin: 10 }}>
              <span
                className={
                  !this.checkEmptyFilter()
                    ? "round-disabled"
                    : "clickable round"
                }
                id="addLineFilter"
                onClick={
                  !this.checkEmptyFilter() ? () => {} : this.addLineFilter
                }
                role="presentation"
              >
                <Ajout
                  className="clickable "
                  fill={this.checkEmptyFilter() ? "currentcolor" : "white"}
                  height="1em"
                  width="1em"
                />
                {this.checkEmptyFilter() && (
                  <UncontrolledTooltip
                    placement="bottom"
                    target="addLineFilter"
                  >
                    {" "}
                    {t("all.filter.add_filter")}
                  </UncontrolledTooltip>
                )}
              </span>
            </Row>
            <Row style={{ margin: 10 }}>
              <Col>
                <Row className="float-right">
                  {ruleList.length > 0 && (
                    <Button
                      className="filterbutton"
                      onClick={() => this.applyFilters()}
                      disabled={pdis.loadingFilters}
                    >
                      {t("all.button.apply")}
                    </Button>
                  )}
                  {ruleList.length > 0 && (
                    <Button
                      className="filterbutton"
                      onClick={this.resetFilters}
                      disabled={pdis.loadingFilters}
                    >
                      {t("all.button.reset")}
                    </Button>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { filters, authentication, mask, alarms, locations, pdis } = state;
  const { user } = authentication;
  return {
    alarms,
    filters,
    user,
    mask,
    locations,
    pdis,
  };
}

const connectedQueryBuilder = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(QueryBuilder);
const tr = withTranslation()(connectedQueryBuilder);
export default tr;
