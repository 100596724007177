import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";

/**
 * Enregistre l'élément HTML ciblé en PDF, en
 * le transformant au préalable en canvas
 *
 * @method printSheet
 * @param {string} element Element
 * @param {string} name Nom du fichier
 */
export function printSheet(element: string, name: string) {
  const printableElement = document.getElementById(element);
  if (null !== printableElement) {
    let pos: ClientRect = printableElement.getBoundingClientRect();
    let width: number = pos.width;
    let height: number = pos.height;
    let topLeftMargin: number = 15;
    let pdfWidth: number = width + topLeftMargin * 2;
    let pdfHeight: number = pdfWidth * 1.5 + topLeftMargin * 2;

    let totalPages = Math.ceil(height / pdfHeight) - 1;
    html2canvas(printableElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", [pdfWidth, pdfHeight]);
      pdf.addImage(
        imgData,
        "JPEG",
        topLeftMargin,
        topLeftMargin,
        width,
        height
      );

      for (let i = 1; i <= totalPages; i++) {
        pdf.addPage(pdfWidth, pdfHeight);
        pdf.addImage(
          imgData,
          "JPG",
          topLeftMargin,
          -(pdfHeight * i) + topLeftMargin * 4,
          width,
          height
        );
      }

      pdf.save(`${name}.pdf`);
    });
  }
}

/**
 * Enregistre les éléments HTML ciblé par la classe
 * passée en paramètre en les aggregeant dans un seul
 * fichier PDF
 *
 * @method printMultiSheet
 * @param {string} element Element
 * @param {string} name Nom du fichier
 */
export function printMultiSheet(element: string, name: string) {
  let promises = [];
  let sizes: any = [];

  const printableElements = document.getElementsByClassName(element);
  if (null !== printableElements) {
    for (let i = 0; i < printableElements.length; i++) {
      let pos: ClientRect = printableElements[i].getBoundingClientRect();
      let size = {
        width: pos.width,
        height: pos.height,
      };
      sizes.push(size);
      promises.push(htmlToImage.toPng(<HTMLElement>printableElements[i]));
    }
  }

  // Récupère tous les canvas et les ajoute au PDF
  Promise.all(promises).then((elements: any) => {
    // Traite indépendemment chaque élément dans l'ordre
    const pdf = new jsPDF();
    elements.forEach((it: any, index: any) => {
      const imgProps = pdf.getImageProperties(it);
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = imgProps.height * (imgWidth / imgProps.width);
      let heightLeft = imgHeight;
      let position = 0; // give some top padding to first page
      if (index > 0) {
        pdf.addPage();
      }
      pdf.addImage(it, "PNG ", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight; // top padding for other pages
        pdf.addPage();
        pdf.addImage(it, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
    });
    pdf.save(`${name}.pdf`);
  });
}
