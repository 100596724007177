import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import MeterSelectionComponent from "../_components/MeterSelectionComponent";
import ListTools from "../List/ListTools";
import {
  localeActions,
  locationActions,
  pdiActions,
  rndTemplateActions,
  tourneeActions,
} from "../_actions";
import {
  getFiltersRequest,
  getLightFilters,
} from "../QueryBuilder/FilterLSManager";
import _ from "lodash";
import Loading from "../_animations/Loading";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SvgAjoutBleu from "../SvgComponents/AjoutBleu";
import {
  TourneeCreationFormType,
  TemplateDto,
  TemplatesState,
  TourneeCreationDto,
} from "../_interfaces/TourneeCreationTypes";
import Select from "react-select";
import { RouteComponentProps, StaticContext } from "react-router";
import { Rule } from "../_interfaces/filter";
import OperatorList from "../_shared/OperatorList";
import ErrorBand from "../Bands/Error";
import SuccessBand from "../Bands/Success";
import StepPopover from "../_components/StepPopover";
import LoadingBand from "../Bands/Loading";

const TourneeCreationPage: React.FC<
  {
    dispatch: Function;
    locations: any;
    pdis: any;
    rndTemplate: TemplatesState;
    tournee: any;
  } & RouteComponentProps<
    { GestionnaireId: string },
    StaticContext,
    {
      from: string;
      form: {
        tourneeForm: TourneeCreationFormType;
        listMeter: { [meterId: number]: string };
      };
    }
  > &
    WithTranslation
> = ({
  dispatch,
  history,
  location,
  locations,
  match,
  pdis,
  t,
  rndTemplate,
  tournee,
}) => {
  const [listMeter, setListMeter] = useState<{ [meterId: number]: string }>({});
  const [allSelectButton, setAllSelectButton] = useState<number>(0);
  const [ruleList, setRuleList] = useState<Array<Rule>>([]);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [otherRoundName, setOtherRoundName] = useState<string[]>([]);
  const [otherRoundCode, setOtherRoundCode] = useState<string[]>([]);
  const [isTop, setIsTop] = useState<boolean>(true);
  const [isPageDisabled, setIsPageDisabled] = useState<boolean>(false);
  const [resetPage, setResetPage] = useState<boolean>(false);
  const [tourneeForm, setTourneeForm] = useState<TourneeCreationFormType>({
    name: "",
    code: "",
    message: "",
    templatePdi: null,
    templateMeter: null,
    autorizeMeterChange: false,
    defaultWheels: "",
  });
  const previousRuleListRef = useRef<Array<Rule>>();

  const keyStorage = `filter-${ListTools.typeOfList.Pdi}-0`;
  const displayingColumns: any[] = ListTools.getDefaultColumns(
    ListTools.typeOfList.VPdi
  ).map((champ: string) => {
    return {
      dataField: champ,
      text: t(`columns.${champ}`),
      sort: true,
      classes: champ.includes("address")
        ? ""
        : `crystalList-column ${
            champ === "clpInformations" || champ === "mtrInformations"
              ? "pre-space"
              : ""
          }`,
      filter: textFilter({
        placeholder: t(`columns.${champ}`),
        delay: 500,
      }),
    };
  });

  const getCreationFilters = () => {
    const selectedFilters =
      ruleList && ruleList.length != 0
        ? _.cloneDeep(ruleList)
        : getFiltersRequest(keyStorage) || [];

    selectedFilters.push({
      filter: {
        label: "Nom tournée relève",
        value: "labelTourneeReleve",
        type: "text",
      },
      filterApplied: true,
      num: 0,
      operator: OperatorList.operators.text.find((el) => el.value === "vide"),
      userInput: null,
    });

    return selectedFilters;
  };

  const selectListMeter = (event: Object, values: any) => {
    const { content } = locations.fetchedLocation;
    const selectedFilters = getCreationFilters();

    dispatch(
      pdiActions.getMapIdToSerial(
        selectedFilters,
        content && content.code,
        0,
        allSelectButton + 1
      )
    );
    setAllSelectButton(allSelectButton + 1);
  };

  const receiveData = (listDataFiltered, filtersApplied) => {
    if (!_.isEqual(ruleList, filtersApplied)) {
      setRuleList(filtersApplied);
    }
  };

  const getPdiList = () => {
    const fastFiltersRequest: { [key: string]: string } = {};
    const fastFilters = localStorage.getItem(`${keyStorage}-fast`);
    if (fastFilters && fastFilters.length > 0) {
      _.entries(fastFilters).forEach((it: Array<any>) => {
        fastFiltersRequest[it[0]] = it[1].filterVal;
      });
    }
    const selectedFilters = getCreationFilters();

    const page = parseInt(
      localStorage.getItem(`${keyStorage}-page`) || "1",
      10
    );
    dispatch(
      pdiActions.getRemotePdi(
        0,
        selectedFilters,
        parseInt(localStorage.getItem(`${keyStorage}-sizeperpage`) || "10", 10),
        page,
        {},
        fastFiltersRequest,
        null,
        false,
        0,
        !(localStorage.getItem(`${keyStorage}_optionor`) === "true"),
        null
      )
    );
  };

  const handleFormChange =
    (identifier: keyof TourneeCreationFormType) =>
    (e: BaseSyntheticEvent<InputEvent>) => {
      const newForm = { ...tourneeForm };
      switch (identifier) {
        case "name":
        case "code":
        case "message":
        case "defaultWheels":
          newForm[identifier] = e.target.value;
          break;
        case "autorizeMeterChange":
          newForm.autorizeMeterChange = e.target.checked;
          break;
      }
      setTourneeForm(newForm);
    };

  const handleValidationsForField = (
    identifier: keyof TourneeCreationFormType
  ) => {
    switch (identifier) {
      case "name":
        return {
          required: {
            value: true,
            errorMessage: t("tournee_creation.input_error.empty_round_name"),
          },
          maxLength: { value: 20 },
        };
      case "code":
        return {
          required: {
            value: true,
            errorMessage: t("tournee_creation.input_error.empty_round_code"),
          },
          pattern: { value: "^[a-zA-Z0-9_]+$" },
          maxLength: { value: 8 },
        };
      case "message":
        return {
          required: { value: false },
          maxLength: { value: 160 },
        };
      case "defaultWheels":
        return {
          required: { value: false },
          min: {
            value: 4,
            errorMessage: t(
              "tournee_creation.input_error.round_wheels_too_high"
            ),
          },
          max: {
            value: 9,
            errorMessage: t(
              "tournee_creation.input_error.round_wheels_too_low"
            ),
          },
        };
    }
  };

  const validateSubmit = () => {
    if (
      tourneeForm.templatePdi === null ||
      tourneeForm.templateMeter === null ||
      _.isEmpty(listMeter) ||
      _.size(listMeter) > 9999 ||
      nameAlreadyTaken() ||
      codeAlreadyTaken()
    ) {
      setIsSubmited(true);
      return false;
    }
    return true;
  };

  const handleScroll = () => {
    let topPosition = 0;
    tournee.error && (topPosition += 65);
    tournee.success && (topPosition += 65);
    tournee.loading && (topPosition += 65);
    setIsTop(window.scrollY <= topPosition);
  };

  const handleSelectChange =
    (identifier: "templatePdi" | "templateMeter") => (option: any) => {
      const newForm = { ...tourneeForm };
      newForm[identifier] = option;
      setTourneeForm(newForm);
    };

  const nameAlreadyTaken = () => {
    return (
      tourneeForm.name !== "" &&
      otherRoundName
        ?.map((name) => name.toUpperCase())
        .includes(tourneeForm.name.toUpperCase())
    );
  };

  const codeAlreadyTaken = () => {
    return otherRoundCode
      ?.map((code) => code.toUpperCase())
      .includes(tourneeForm.code.toUpperCase());
  };

  const handleSave = () => {
    if (validateSubmit()) {
      const tournee: TourneeCreationDto = {
        tourneeName: tourneeForm.name,
        codeTournee: tourneeForm.code,
        tourneeMessage: tourneeForm.message,
        autorizeMeterChangement: tourneeForm.autorizeMeterChange,
        collectpointInformationTemplateId: tourneeForm.templatePdi.value,
        meterInformationTemplateId: tourneeForm.templateMeter.value,
        listMtrId: Object.keys(listMeter).map((meterId: string) =>
          parseInt(meterId)
        ),
        defaultWheels:
          tourneeForm.defaultWheels !== ""
            ? Number(tourneeForm.defaultWheels)
            : 5,
      };
      dispatch(
        tourneeActions.createTournee(
          tournee,
          _.get(match, "params.GestionnaireId")
        )
      );
    }
  };

  const handleCancel = () => {
    history.push(
      `/gestionnaires/${_.get(
        match,
        "params.GestionnaireId"
      )}/synchronisation/silex`
    );
  };

  const handleNewTemplate = () => {
    history.push(
      `/gestionnaires/${_.get(
        match,
        "params.GestionnaireId"
      )}/synchronisation/silex/template`,
      {
        from: location.pathname,
        form: { tourneeForm, listMeter },
      }
    );
  };

  useEffect(() => {
    dispatch(localeActions.load());
    dispatch(locationActions.getInfos(0));
    dispatch(rndTemplateActions.getAllTemplates());
    dispatch(tourneeActions.getAllTourneeInfos());
    if (location.state && location.state.form) {
      setTourneeForm(location.state.form.tourneeForm);
      setListMeter(location.state.form.listMeter);
    }
    const InputElem = document.getElementById("inputCode");
    InputElem?.addEventListener("keydown", (e) => {
      const input = e.key;
      if (input != null && input.match(/[A-Za-z0-9_]/) === null) {
        e.preventDefault();
      }
    });
    return () => {
      dispatch(tourneeActions.clear());
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tournee.error, tournee.success]);

  useEffect(() => {
    if (!_.isEqual(ruleList, previousRuleListRef.current)) {
      getPdiList();
    }
    previousRuleListRef.current = _.cloneDeep(ruleList);
  }, [ruleList]);

  useEffect(() => {
    if (tournee.allTourneeInfos) {
      setOtherRoundName(
        tournee.allTourneeInfos.map((tourneeInfos: any) => tourneeInfos.name)
      );
      setOtherRoundCode(
        tournee.allTourneeInfos.map((tourneeInfos: any) => tourneeInfos.code)
      );
    }
    if (tournee.loading || tournee.success) {
      setIsPageDisabled(true);
    } else {
      setIsPageDisabled(false);
    }
  }, [tournee]);

  useEffect(() => {
    if (tournee.error) {
      const timeout = setTimeout(() => {
        dispatch(tourneeActions.clearError());
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [tournee.error]);

  useEffect(() => {
    if (tournee.success) {
      const timeout = setTimeout(() => {
        setResetPage(true);
        history.push(
          `/gestionnaires/${_.get(
            match,
            "params.GestionnaireId"
          )}/synchronisation/tournees/${tourneeForm.code}`
        );
      }, 2000);
      return () => {
        setResetPage(true);
        clearTimeout(timeout);
      };
    }
  }, [tournee.success]);

  useEffect(() => {
    if (_.get(pdis, "clickId") === allSelectButton) {
      const copyListMeter = _.cloneDeep(listMeter);
      _.entries(_.get(pdis, "mapIdToSerial")).forEach((it) => {
        if (!(it[0] in copyListMeter)) {
          copyListMeter[Number.parseInt(it[0])] = _.toString(it[1]);
        }
      });
      setListMeter(copyListMeter);
      setAllSelectButton(allSelectButton + 1);
    }
  });

  return pdis.items ? (
    <div className="col-12 tournee-creation-page">
      {tournee.error && (
        <ErrorBand
          message={t(
            `tournee_creation.error_band.${tournee.error.translationId}`,
            {
              errors: tournee.error.errorValue
                ?.map((el: string) => t(`tournee_creation.input_error.${el}`))
                .join(", "),
            }
          )}
        />
      )}
      {tournee.success && (
        <SuccessBand
          message={t("tournee_creation.success.roundCreation", {
            tourneeName: tourneeForm.name,
          })}
        />
      )}
      {tournee.loading && (
        <LoadingBand
          message={t("tournee_creation.loading_band.tournee_creation")}
        />
      )}
      <AvForm validate={validateSubmit} onValidSubmit={handleSave}>
        {/* Round name */}
        <div
          className="table-info-container col-12 tournee-input-container tournee-name-container"
          style={
            isTop
              ? {}
              : { position: "fixed", width: "calc(100% - 290px)", top: "100px" }
          }
        >
          <h2 className="tournee-name-label">{t("all.round.round_name")}</h2>
          <div>
            <AvField
              bsSize="lg"
              type="text"
              id="inputName"
              name="name"
              placeholder={t("all.round.round_name")}
              className={
                "tournee-input" + (nameAlreadyTaken() ? " is-invalid" : "")
              }
              onChange={handleFormChange("name")}
              value={tourneeForm.name}
              validate={handleValidationsForField("name")}
              disabled={isPageDisabled}
            />
            {isSubmited && (
              <div
                className="invalid-feedback"
                style={{ display: nameAlreadyTaken() ? "block" : "none" }}
              >
                {t("tournee_creation.input_error.round_name_already_exists")}
              </div>
            )}
          </div>
          <div className="buttons-container">
            <Button
              className={"import-silex-selection-button selected cancel"}
              onClick={handleCancel}
            >
              {t("all.button.cancel")}
            </Button>
            <Button
              id="tournee-creation-submit"
              className={"import-silex-selection-button selected"}
              disabled={isPageDisabled}
            >
              {t("all.button.register")}
            </Button>
          </div>
        </div>

        {/* Round informations */}
        <div
          className="table-info-container col-12 tournee-info-card"
          style={{ marginTop: isTop ? "10px" : "120px" }}
        >
          <div className="col-5">
            <h3 className="infos-card-title">
              {t("tournee_creation.container_label.tournee_informations")}
            </h3>
            <div className="tournee-input-container code-container">
              <div className="tournee-label">{t("all.text.code")}</div>
              <div>
                <AvField
                  type="text"
                  id="inputCode"
                  name="code"
                  className={
                    "tournee-input" + (codeAlreadyTaken() ? " is-invalid" : "")
                  }
                  placeholder={t(
                    "tournee_creation.input_label.code_placeholder"
                  )}
                  onChange={handleFormChange("code")}
                  autoComplete="NONE"
                  value={tourneeForm.code}
                  validate={handleValidationsForField("code")}
                  disabled={isPageDisabled}
                />
                {isSubmited && (
                  <div
                    className="invalid-feedback"
                    style={{ display: codeAlreadyTaken() ? "block" : "none" }}
                  >
                    {t(
                      "tournee_creation.input_error.round_code_already_exists"
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="tournee-input-container">
              <div className="tournee-label">{t("all.text.message")}</div>
              <AvField
                type="text"
                id="inputMessage"
                name="message"
                placeholder={t(
                  "tournee_creation.input_label.message_placeholder"
                )}
                className="tournee-input"
                onChange={handleFormChange("message")}
                value={tourneeForm.message}
                validate={handleValidationsForField("message")}
                disabled={isPageDisabled}
              />
            </div>
            <div className="tournee-input-container">
              <div className="tournee-label">
                {t("all.meter.wheels_number")}
              </div>
              <AvField
                type="number"
                id="inputDefaultWheels"
                name="defaultWheels"
                placeholder={t(
                  "tournee_creation.input_label.wheels_placeholder"
                )}
                className="tournee-input"
                onChange={handleFormChange("defaultWheels")}
                autoComplete="NONE"
                value={tourneeForm.defaultWheels}
                validate={handleValidationsForField("defaultWheels")}
                disabled={isPageDisabled}
              />
            </div>
          </div>
          <div className="col-5">
            <h3 className="infos-card-title">
              {t("tournee_creation.container_label.tournee_template")}
            </h3>
            <div className="infos-card-select-container">
              <FormGroup className="select-container-form-group">
                <Select
                  options={
                    rndTemplate.templates &&
                    rndTemplate.templates
                      .filter(
                        (template: TemplateDto) =>
                          template.type === "subscriber"
                      )
                      .map((template: TemplateDto) => ({
                        value: template.id,
                        label: template.name,
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label))
                  }
                  placeholder={t(
                    "tournee_creation.input_label.pdi_template_placeholder"
                  )}
                  onChange={handleSelectChange("templatePdi")}
                  value={tourneeForm.templatePdi}
                  styles={{
                    control: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      borderColor:
                        isSubmited && tourneeForm.templatePdi === null
                          ? "red"
                          : "#ced4da",
                    }),
                  }}
                  required
                  isDisabled={isPageDisabled}
                />
              </FormGroup>
              <div className="round-svg" onClick={handleNewTemplate}>
                <SvgAjoutBleu height="1em" />
              </div>
            </div>
            <div
              className="invalid-feedback"
              style={{
                display:
                  isSubmited && tourneeForm.templatePdi === null
                    ? "block"
                    : "none",
              }}
            >
              {t("tournee_creation.input_error.empty_template")}
            </div>
            <div
              className="infos-card-select-container"
              style={{ marginTop: "8px" }}
            >
              <FormGroup className="select-container-form-group">
                <Select
                  options={
                    rndTemplate.templates &&
                    rndTemplate.templates
                      .filter(
                        (template: TemplateDto) => template.type === "meter"
                      )
                      .map((template: TemplateDto) => ({
                        value: template.id,
                        label: template.name,
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label))
                  }
                  placeholder={t(
                    "tournee_creation.input_label.meter_template_placeholder"
                  )}
                  onChange={handleSelectChange("templateMeter")}
                  value={tourneeForm.templateMeter}
                  styles={{
                    control: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      borderColor:
                        isSubmited && tourneeForm.templateMeter === null
                          ? "red"
                          : "#ced4da",
                    }),
                  }}
                  required
                  isDisabled={isPageDisabled}
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      isSubmited && tourneeForm.templateMeter === null
                        ? "block"
                        : "none",
                  }}
                >
                  {t("tournee_creation.input_error.empty_template")}
                </div>
              </FormGroup>
              <div className="round-svg" onClick={handleNewTemplate}>
                <SvgAjoutBleu height="1em" />
              </div>
            </div>
            <Label className="infos-card-checkbox-container">
              <Input
                type="checkbox"
                onChange={handleFormChange("autorizeMeterChange")}
              />{" "}
              {t("tournee_creation.input_label.autorize_meter_change")}
            </Label>
          </div>
        </div>

        {/* Filters and meter list */}
        <MeterSelectionComponent
          displayingColumns={displayingColumns}
          displayNoMeterSelected={isSubmited}
          keyStorage={keyStorage}
          idContext={ListTools.typeOfList.Pdi}
          listMeter={listMeter}
          locationId={0}
          pdis={pdis}
          resetPage={resetPage}
          selectListMeter={selectListMeter}
          setListMeter={setListMeter}
          receiveData={receiveData}
          setRuleList={setRuleList}
          loading={pdis.loadingFilters || pdis.loading}
          disabled={isPageDisabled}
        />
      </AvForm>
    </div>
  ) : (
    <Loading />
  );
};

function mapStateToProps(state: any) {
  const {
    rndTemplate,
    gestionnaire: { gestionnaire },
    pdis,
    locations,
    tournee,
  } = state;
  return {
    gestionnaire,
    pdis,
    rndTemplate,
    locations,
    tournee,
  };
}

const connectedTourneeCreationPage =
  connect(mapStateToProps)(TourneeCreationPage);
const tr = withTranslation()(connectedTourneeCreationPage);

export default tr;
