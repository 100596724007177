import React, { Component } from "react";
import { connect } from "react-redux";
import LocationAdressChooser from "./LocationAdressChooser";
import { locales, locations } from "../_interfaces/reducers";
import { localeActions, meterActions } from "../_actions";
import PdiVert from "../SvgComponents/PdiVert";
import { WithTranslation, withTranslation } from "react-i18next";

type Props = {
  locales: locales;
  dispatch: any;
  locationId: number;
  match: any;
  locations: locations;
  location: any;
  history: any;
  pdis: any;
  meters: any;
  radios: any;
} & WithTranslation;

interface State {
  meter: any;
}

/**
 * @class CreatePdi
 * @extends {Component}
 */
class CreatePdi extends Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      meter: {},
    };
  }

  /**
   * Fait les vérifications des données du local
   * storage au montage du composant (suppression
   * si données d'un autre site, application sinon)
   *
   * @method componentDidMount
   */
  componentDidMount() {
    const { dispatch } = this.props;
    //TODO
    dispatch(localeActions.load());
    dispatch(meterActions.getUnlinkedMeters());
  }

  /**
   * Tronque les résultats s'ils dépassent 15 entrées
   * et retourne le check
   *
   * @method checkTotal
   * @param {any} data Résultats
   * @returns {boolean} Le dépassement ou non de 15 entrées
   */
  checkTotal = (data: any): boolean => {
    return true;
  };

  handleMeterChange = (event: any, values: any) => {
    const { meter } = this.state;
    meter[event.target.name] = event.target.value;
    this.setState({ meter });
  };

  /**
   * Construit le composant et mise en place
   * d'un système d'onglets pour les résultats
   * de la recherche
   *
   * @method render
   */
  render() {
    const { locales, history, t } = this.props;

    const locale = locales && locales.locale;

    return (
      <div className="col-12">
        {locale && (
          <div className="table-info-container">
            <h2>
              <span>
                <PdiVert height="1em" width="1em" fill="#31c6b3" />
              </span>
              {t("create_pdi.main_title.create_pdi")}
            </h2>
            <LocationAdressChooser history={history} />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, meters, locations, locales, radios, pdis } = state;
  const { user } = authentication;

  return {
    alert,
    user,
    meters,
    radios,
    pdis,
    locations,
    locales,
  };
}

const connectedCreatePdi = connect(mapStateToProps)(CreatePdi);
const tr = withTranslation()(connectedCreatePdi);
export default tr;
