import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { alert } from "../_interfaces/reducers";
import React from "react";

interface Props {
  isOpen: boolean;
  edit: Function;
  className: string | undefined;
  alert: alert;
  updated: boolean;
  password: string;
  passwordCopy: string;
  handleValidSubmit: Function;
  handleInvalidSubmit: Function;
  t: Function;
}

/**
 * Gère le changement de mot de passe avec auto-génération
 *
 * @method PasswordChangeRenderWithGenerate
 * @param {Props} props Props du composant
 */
export const PasswordChangeRenderWithGenerate: React.FunctionComponent<
  Props & {}
> = (props) => {
  const {
    isOpen,
    edit,
    className,
    handleInvalidSubmit,
    handleValidSubmit,
    alert,
    updated,
    password,
    passwordCopy,
  } = props;
  return (
    <Modal isOpen={isOpen} toggle={edit as any} className={className}>
      <ModalHeader toggle={edit as any}>Nouveau mot de passe</ModalHeader>
      <AvForm
        onValidSubmit={handleValidSubmit}
        onInvalidSubmit={handleInvalidSubmit}
      >
        <ModalBody>
          <p>
            Voici un mot de passe généré, en enregistrant, ce mot de passe sera
            appliqué pour l'utilisateur
          </p>
          <div>
            {alert.message && (
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            )}
          </div>
          {!updated && (
            <div>
              <AvField
                name="passwordShadow"
                value={password}
                label="Mot de passe généré"
                type="text"
                disabled={true}
              />
              <div style={{ display: "none" }}>
                <AvField
                  name="password"
                  value={password}
                  label="Mot de passe généré"
                  type="text"
                />
                <AvField
                  name="passwordCopy"
                  value={passwordCopy}
                  label="Mot de passe généré"
                  type="text"
                />
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {!updated && (
            <Button color="danger" onClick={edit as any}>
              Annuler
            </Button>
          )}
          {!updated && <Button color="primary">Enregistrer</Button>}
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};
