import locationConstants from "../_constants/location.constants";
import { action } from "../_interfaces/action";

export default function locations(state: any = {}, action: action) {
  switch (action.type) {
    case locationConstants.GETALL_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case locationConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.locations,
        loading: false,
      };
    case locationConstants.GETALL_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des sites",
      };
    case locationConstants.GETALLFROMSEED_REQUEST:
      return {
        loading: true,
      };
    case locationConstants.GETALLFROMSEED_SUCCESS:
      return {
        itemsWithSeed: action.locations,
      };
    case locationConstants.GETALLFROMSEED_FAILURE:
      return {
        error:
          "Impossible d'obtenir la liste des sites à partir de ce site racine",
      };
    case locationConstants.GET_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case locationConstants.GET_SUCCESS:
      return {
        fetchedLocation: action.fetchedLocation,
        ...state,
        loading: false,
      };
    case locationConstants.GET_FAILURE:
      return {
        error: "Impossible d'obtenir les informations du site",
      };
    case locationConstants.GETINFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case locationConstants.GETINFO_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchedLocation: action.fetchedLocation,
      };
    case locationConstants.GETINFO_FAILURE:
      return {
        error: "Impossible d'obtenir les informations distantes du site",
      };
    case locationConstants.SAVE_REQUEST:
      state.savedLocation = { loading: true };
      return {
        ...state,
      };
    case locationConstants.SAVE_SUCCESS:
      state.savedLocation = action.savedlocation;
      return {
        ...state,
      };
    case locationConstants.SAVE_FAILURE:
      state.savedLocation = { error: "Impossible de sauvegarder le site" };
      return {
        ...state,
      };
    case locationConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case locationConstants.UPDATE_SUCCESS:
      return {
        fetchedLocation: action.updatedLocation,
      };
    case locationConstants.UPDATE_FAILURE:
      return {
        error: "Impossible de mettre à jour le site",
      };
    case locationConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case locationConstants.DELETE_SUCCESS:
      return {};
    case locationConstants.DELETE_FAILURE:
      return {
        error: "Impossible de supprimer le site",
      };
    case locationConstants.GET_ALLMETER_REQUEST:
      //state.remoteReading.loading = { loadingMeter: true };
      return {
        ...state,
        remoteReading: {
          ...state.remoteReading,
          loading: {
            loadingMeter: true,
          },
        },
      };
    case locationConstants.GET_ALLMETER_SUCCESS:
      return {
        ...state,
        remoteReading: {
          ...state.remoteReading,
          nbMeter: action.nbMeter,
          loading: {
            ...state.remoteReading.loading,
            loadingMeter: false,
          },
        },
      };
    case locationConstants.GET_ALLMETER_FAILURE:
      return {
        error: "Impossible de récupérer le nombre total de compteur",
      };
    case locationConstants.GET_ALLREADMETER_REQUEST:
      state.remoteReading.loading = { loadingReadMeter: true };
      return {
        ...state,
      };
    case locationConstants.GET_ALLREADMETER_SUCCESS:
      return {
        ...state,
        remoteReading: {
          ...state.remoteReading,
          nbReadMeter: action.nbReadMeter,
          loading: {
            ...state.remoteReading.loading,
            loadingReadMeter: false,
          },
        },
      };
    case locationConstants.GET_ALLREADMETER_FAILURE:
      return {
        error: "Impossible de récupérer le nombre total de compteur relevé",
      };
    case locationConstants.GET_ALLREADMETERALARM_REQUEST:
      state.remoteReading.loading = { loadingAlarm: true };
      return {
        ...state,
      };
    case locationConstants.GET_ALLREADMETERALARM_SUCCESS:
      return {
        ...state,
        remoteReading: {
          ...state.remoteReading,
          nbReadMeterAlarm: action.nbReadMeterAlarm,
          loading: {
            ...state.remoteReading.loading,
            loadingAlarm: false,
          },
        },
      };
    case locationConstants.GET_ALLREADMETERALARM_FAILURE:
      return {
        error:
          "Impossible de récupérer le nombre total d'alarme compteur relevé",
      };
    case locationConstants.GET_ALLREADMETERALARMTYPE_REQUEST:
      state.remoteReading.loading = { loadingAlarmType: true };
      return {
        ...state,
      };
    case locationConstants.GET_ALLREADMETERALARMTYPE_SUCCESS:
      return {
        ...state,
        remoteReading: {
          ...state.remoteReading,
          alarmType: action.alarmType,
          loading: {
            ...state.remoteReading.loading,
            loadingAlarmType: false,
          },
        },
      };
    case locationConstants.GET_ALLREADMETERALARMTYPE_FAILURE:
      return {
        error: "Impossible de récupérer le type d'alarme des compteurs relevés",
      };
    case locationConstants.GET_ALLREADMETERMODEL_REQUEST:
      state.remoteReading.loading = { loadingModel: true };
      return {
        ...state,
      };
    case locationConstants.GET_ALLREADMETERMODEL_SUCCESS:
      return {
        ...state,
        remoteReading: {
          ...state.remoteReading,
          nbReadMeterModel: action.nbReadMeterModel,
          loading: {
            ...state.remoteReading.loading,
            loadingModel: false,
          },
        },
      };
    case locationConstants.GET_ALLREADMETERMODEL_FAILURE:
      return {
        error: "Impossible de récupérer le nombre de relevé par type de modèle",
      };
    case locationConstants.GET_ALLREADMETERREADMETHOD_REQUEST:
      state.remoteReading.loading = { loadingReadMethod: true };
      return {
        ...state,
      };
    case locationConstants.GET_ALLREADMETERREADMETHOD_SUCCESS:
      return {
        ...state,
        remoteReading: {
          ...state.remoteReading,
          readMethod: action.readMethod,
          loading: {
            ...state.remoteReading.loading,
            loadingReadMethod: false,
          },
        },
      };
    case locationConstants.GET_ALLREADMETERREADMETHOD_FAILURE:
      return {
        error: "Impossible de récupérer les méthodes de relèves",
      };
    case locationConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
