import React, { ComponentType } from "react";
import {
  Alert,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { WithTranslation, withTranslation } from "react-i18next";
import i18n from "i18next";
import SvgTeleReleve from "../SvgComponents/LogoTeleReleve";
import BurgerHeader from "../SvgComponents/BurgerHeader";
import StockHeader from "../SvgComponents/StockHeader";
import NotificationModal from "./NotificationModal";
import CrystalBreadCrumb from "./BreadCrumb";
import { getActiveClassByPath, getAuthUser } from "../_helpers";
import { userActions } from "../_actions";
import GestionUtilisateurBlanc from "../SvgComponents/GestionUtilisateurBlanc";
import HomeFavori from "../SvgComponents/HomeFavori";
import ListTools from "../List/ListTools";
import ReglagesBleu from "../SvgComponents/ReglagesBleu";
import SvgManageTournee from "../SvgComponents/ManageTournee";
import SvgFlagFr from "../SvgComponents/FrFlag";
import SvgFLagEn from "../SvgComponents/EnFlag";
import SvgFlagEs from "../SvgComponents/EsFlag";
import history from "../_helpers/history";
import { compose } from "redux";

type Props = {
  user: any;
  dispatch: Function;
  gestionnaire: any;
  users: any;
  match: any;
  history: any;
} & WithTranslation;

interface State {
  isOpen: boolean;
  tooltipOpen: boolean;
  goToBurger: boolean;
  teleHeight: number;
  homepageUrl: any;
  check: boolean;
}

const listRegexHomepaginable = {
  home: /^\/?$/,
  suiviSite: /^\/locations\/\d+\/?$/,
  gestionnaireSynchro: /^\/gestionnaires\/\d+\/synchronisation\/?\w*\/?$/,
};

/**
 * @class MainNavbar
 * @extends Component
 */
class MainNavbar extends React.Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      tooltipOpen: false,
      goToBurger: false,
      homepageUrl: localStorage.getItem("homepage_url") || "/",
      teleHeight: 0,
      check: getAuthUser() !== "USER",
    };
  }

  componentDidMount() {
    const {
      match: { params },
      dispatch,
    } = this.props;
    const { teleHeight, check } = this.state;
    const teleIconeHeight: any = document.getElementById("teleHeight");
    if (!check)
      _.entries(params).forEach(([key, value]: any) =>
        dispatch(userActions.checkPermission(key, value))
      );
    if (teleIconeHeight && teleHeight === 0) {
      this.setState({
        teleHeight: teleIconeHeight.getBoundingClientRect().height,
      });
    }
    window.addEventListener("resize", this.isPacked);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    const { users } = this.props;
    const { check } = this.state;
    if (
      !check &&
      _.some(
        _.entries(_.get(users, "checkPermissions", {})),
        ([key, value]) => !value
      )
    ) {
      history.push("/forbidden");
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    window.removeEventListener("resize", this.isPacked);
  }

  /**
   * Gère l'ouverture de la modal de support
   *
   * @method toggle
   */
  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  /**
   * Gère l'ouverture de la tooltip
   *
   * @method toggleTooltip
   */
  toggleTooltip = () => {
    const { tooltipOpen } = this.state;
    this.setState({
      tooltipOpen: !tooltipOpen,
    });
  };

  isPacked = () => {
    const { goToBurger } = this.state;
    const crystalBreadCrumbComponent =
      document.getElementById("crystalBreadCrumb");
    const navBarComponent = document.getElementById("navBarToBurger");
    if (crystalBreadCrumbComponent && navBarComponent) {
      const breadCrumbInfo = crystalBreadCrumbComponent.getBoundingClientRect();
      const navBarInfo = navBarComponent.getBoundingClientRect();
      if (breadCrumbInfo.right - 20 >= navBarInfo.left) {
        this.setState({
          goToBurger: true,
        });
      } else if (navBarInfo.width < 100) {
        if (breadCrumbInfo.right + 220 <= navBarInfo.left) {
          this.setState({
            goToBurger: false,
          });
        }
      } else {
        this.setState({
          goToBurger: false,
        });
      }
    }
  };

  isAdmin = () => {
    const { user } = this.props;
    return (
      user &&
      ["DIOPTASE", "SUPERADMIN", "ADMIN"].includes(_.get(user, "role.name"))
    );
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { isOpen, goToBurger, teleHeight } = this.state;
    const { user, gestionnaire, users, history, dispatch, t } = this.props;
    if (!user) return null;
    return (
      <div className="flex-box" style={{ height: "inherit" }}>
        {_.get(user, "demoMode") && (
          <Alert color="primary" className="demoBand">
            {`MODE DEMONSTRATION ${
              _.get(user, "endDateDemo")
                ? `VALABLE JUSQU'AU ${ListTools.formatDateDay(
                    _.get(user, "endDateDemo")
                  )}`
                : ""
            }`}
          </Alert>
        )}
        <CrystalBreadCrumb />
        <Navbar color="light" expand="md" id="head-navbar">
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav
              className="ml-auto"
              navbar
              id="navBarToBurger"
              style={{ alignItems: "center" }}
            >
              {!goToBurger && (
                <>
                  <NavItem
                    id="nav-homepage"
                    className="header-item clickable"
                    onClick={() =>
                      history.push({ pathname: this.state.homepageUrl })
                    }
                  >
                    <NavLink
                      className={getActiveClassByPath(
                        location.pathname,
                        `^${this.state.homepageUrl}$`
                      )}
                      href=""
                      onClick={(e) => e.preventDefault()}
                      id="nav-homepage"
                    >
                      <HomeFavori
                        height="1.5em"
                        width="1.5em"
                        fill="currentcolor"
                      />
                      <UncontrolledTooltip
                        placement="bottom"
                        target="nav-homepage"
                      >
                        {" "}
                        {t("all.homepage.homepage") as string}
                      </UncontrolledTooltip>
                    </NavLink>
                  </NavItem>
                  {_.get(user, "hasTelereleve") && this.isAdmin() && (
                    <NavItem
                      className="header-item"
                      style={{
                        height: `${teleHeight}px`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <NavLink
                        className={getActiveClassByPath(
                          location.pathname,
                          "telereleve"
                        )}
                        href="/telereleve"
                        id="nav-telereleve"
                      >
                        <SvgTeleReleve fill="currentcolor" height="2.5em" />
                        <UncontrolledTooltip
                          placement="bottom"
                          target="nav-telereleve"
                        >
                          {" "}
                          {t("telereleve.text.telereleve") as string}
                        </UncontrolledTooltip>
                      </NavLink>
                    </NavItem>
                  )}
                  <NavItem className="header-item" id="teleHeight">
                    <NavLink
                      className={getActiveClassByPath(
                        location.pathname,
                        "synchronisation"
                      )}
                      href="/gestionnaires"
                      id="nav-synchronisation"
                    >
                      <SvgManageTournee
                        fill="currentcolor"
                        height="1.5em"
                        width="1.5em"
                      />
                      <UncontrolledTooltip
                        placement="bottom"
                        target="nav-synchronisation"
                      >
                        {" "}
                        {t("all.round.round_mngmt") as string}
                      </UncontrolledTooltip>
                    </NavLink>
                  </NavItem>
                  <NavItem className="header-item">
                    <NavLink
                      className={getActiveClassByPath(
                        location.pathname,
                        "stock"
                      )}
                      href="/stock"
                      id="nav-stock"
                    >
                      <StockHeader
                        fill="currentcolor"
                        height="1.5em"
                        width="1.5em"
                      />
                      <UncontrolledTooltip
                        placement="bottom"
                        target="nav-stock"
                      >
                        {" "}
                        {t("all.text.stock") as string}
                      </UncontrolledTooltip>
                    </NavLink>
                  </NavItem>
                  <NavItem className="header-item">
                    <NotificationModal />
                  </NavItem>
                </>
              )}
              <UncontrolledDropdown nav inNavbar className="header-item-last">
                <DropdownToggle nav caret>
                  <BurgerHeader fill="#808080" height="1.5em" width="1.5em" />
                </DropdownToggle>
                <DropdownMenu right>
                  {goToBurger && (
                    <>
                      <DropdownItem
                        tag={Link}
                        to={
                          _.get(users, "fetchedUser.gestionnaires") &&
                          users.fetchedUser.gestionnaires.length === 1
                            ? `/gestionnaires/${users.fetchedUser.gestionnaires[0].gestionnaireId}/synchronisation`
                            : "/gestionnaires"
                        }
                      >
                        {" "}
                        {t("all.text.synchro")}
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem tag={Link} to="/stock">
                        {" "}
                        {t("all.text.stock")}
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem tag={Link} to="/notifications">
                        {" "}
                        {t("all.text.notif_plural")}
                      </DropdownItem>
                      <DropdownItem divider />
                    </>
                  )}
                  <DropdownItem tag={Link} to={`/users/${user.id}`}>
                    <GestionUtilisateurBlanc
                      height="1em"
                      width="1em"
                      stroke="currentcolor"
                      fill="currentcolor"
                      strokeWidth="0"
                      style={{ marginRight: "10px" }}
                    />{" "}
                    {t("all.user.my_info_plural")}
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    disabled={_.values(listRegexHomepaginable).every(
                      (regex) => !history.location.pathname.match(regex)
                    )}
                    onClick={() => {
                      dispatch(
                        userActions.changeHomepage(
                          user.id,
                          `${history.location.pathname}${history.location.search}`
                        )
                      );
                      this.setState({
                        homepageUrl: `${history.location.pathname}${history.location.search}`,
                      });
                    }}
                  >
                    <HomeFavori
                      fill={
                        _.values(listRegexHomepaginable).every(
                          (regex) => !history.location.pathname.match(regex)
                        )
                          ? "gray"
                          : "currentcolor"
                      }
                      height="1.2em"
                      width="1.2em"
                      style={{ marginRight: "10px" }}
                    />
                    {t("all.homepage.choose_homepage")}
                  </DropdownItem>
                  {_.get(user, "role.name") === "DIOPTASE" && (
                    <>
                      <DropdownItem divider />
                      <DropdownItem tag={Link} to="/support/importbilling">
                        <ReglagesBleu
                          height="1em"
                          width="1em"
                          stroke="currentcolor"
                          fill="currentcolor"
                          strokeWidth="0"
                          style={{ marginRight: "10px" }}
                        />{" "}
                        Support
                      </DropdownItem>
                    </>
                  )}
                  <DropdownItem divider />
                  <DropdownItem tag={Link} to="/signin/">
                    {t("all.user.logout")}
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={() => {
                      localStorage.setItem("language", "fr");
                      i18n.changeLanguage("fr");
                    }}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <SvgFlagFr width="1em" />
                    <span style={{ marginLeft: "5px" }}>Français</span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      localStorage.setItem("language", "en");
                      i18n.changeLanguage("en");
                    }}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <SvgFLagEn width="1em" />
                    <span style={{ marginLeft: "5px" }}>English</span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      localStorage.setItem("language", "es");
                      i18n.changeLanguage("es");
                    }}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <SvgFlagEs width="1em" />
                    <span style={{ marginLeft: "5px" }}>Español</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, gestionnaire, permissions, users } = state;
  const { user } = authentication;
  return {
    user,
    gestionnaire,
    permissions,
    users,
  };
}

const connectedMainNavbar = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(MainNavbar);
export default withTranslation()(connectedMainNavbar);
