import { authHeader, handleResponse } from "../_helpers";

async function getAll(siteId: number) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(`/api/meter/list/${siteId}/`, requestOptions);
  return handleResponse(response);
}

async function getAllWithChildren(siteId: number) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/meter/list/${siteId}/children`,
    requestOptions
  );
  return handleResponse(response);
}

async function getAllFromLocation(siteId: number) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/meter/list/${siteId}/children`,
    requestOptions
  );
  return handleResponse(response);
}

async function getUnlinkedMeters() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(`/api/meter/unlinked`, requestOptions);
  return handleResponse(response);
}

async function getManufacturers() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(`/api/meter/manufacturers`, requestOptions);
  return handleResponse(response);
}

async function getInfos(siteId: number, param: string) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/meter/${siteId}/infos${param}`,
    requestOptions
  );
  return handleResponse(response);
}

async function createMeterFromSerial(meter: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(meter),
  };
  let response = await fetch(`/api/meter/`, requestOptions);
  return handleResponse(response);
}

async function getReads(siteId: number, content: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(content),
  };
  let response = await fetch(`/api/meter/${siteId}/reads`, requestOptions);
  return handleResponse(response);
}

async function getConsumptions(siteId: number, content: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(content),
  };
  let response = await fetch(
    `/api/meter/${siteId}/consumptions`,
    requestOptions
  );
  return handleResponse(response);
}

async function getAlarms(siteId: number, content: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(content),
  };
  let response = await fetch(`/api/meter/${siteId}/alarms`, requestOptions);
  return handleResponse(response);
}

async function findInfosWithSerial(serial: string) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(`/api/meter/${serial}/model`, requestOptions);
  return handleResponse(response);
}

async function getAllMetersFromPdi(
  pdiId: number,
  rndCode: string,
  content: any
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(content),
  };
  let response = await fetch(
    `/api/pdi/${pdiId}/meters/infos/round/${rndCode}`,
    requestOptions
  );
  return handleResponse(response);
}

async function getInfosData(identifiant: string, rndId: number, content: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(content),
  };
  const response = await fetch(
    `/api/meter/infosdata/round/${rndId}${identifiant}`,
    requestOptions
  );
  return handleResponse(response);
}

const meterService = {
  getAll,
  getAllWithChildren,
  getManufacturers,
  findInfosWithSerial,
  createMeterFromSerial,
  getUnlinkedMeters,
  getInfos,
  getReads,
  getConsumptions,
  getAllMetersFromPdi,
  getAlarms,
  getInfosData,
  getAllFromLocation,
};

export default meterService;
