import React, { useState } from "react";
import SvgValidation from "../../../SvgComponents/ValidationVert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import synchrocomActions from "../../../_actions/synchrocom.actions";

const CheckBox = (props: any) => {
  const { id, col, dispatch } = props;
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    dispatch(synchrocomActions.setMultiDragList(id, col, !checked));
    setChecked(!checked);
  };

  const container = {
    height: "18px",
    width: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: checked ? "#31c6b3" : "#fff",
    border: "1px solid",
    borderColor: checked ? "#31c6b3" : "#bbb",
    borderRadius: "0.25rem",
    padding: "3px",
  };
  return (
    <div className="task-checkbox" onClick={handleClick} style={container}>
      {checked && <SvgValidation height="20px" fill="white" />}
    </div>
  );
};

function mapStateToProps() {}

const mapping = connect(mapStateToProps)(CheckBox);
const connectedComponent = withRouter(mapping);
const tr = withTranslation()(connectedComponent);
export default tr;
