import React from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";

const options = {
  plugins: {
    t1: false,
    datalabels: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
    yAxes: [
      {
        ticks: {
          display: false,
          beginAtZero: true,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

const HistoAlarmChart = (props) => {
  const { labels, datas } = props;
  const data = {
    labels: labels.map((el) => moment(el).format("DD/MM/YYYY")),
    datasets: datas.map((el) => ({
      label: el.label,
      data: el.data,
      backgroundColor: el.color,
      borderColor: el.color,
      borderWidth: 1,
    })),
  };
  return (
    <div
      className="chart-container histoAlarm"
      style={{ position: "relative", height: "200px" }}
    >
      <Bar data={data} options={options} />
    </div>
  );
};

export default HistoAlarmChart;
