import React from 'react';
export default { 
translation: { 
"all.button.submit":"Soumettre", 
"all.button.validate":"Valider", 
"all.button.edit":"Modifier", 
"all.button.integrate":"Intégrer", 
"all.button.close":"Fermer", 
"all.button.cancel":"Annuler", 
"all.button.delete":"Supprimer", 
"all.button.register":"Enregistrer", 
"all.button.change_file":"Changer de fichier", 
"all.button.search":"Rechercher", 
"all.button.reset":"Réinitialiser", 
"all.button.back":"Retour", 
"all.button.export":"Exporter", 
"all.button.select":"Sélectionner", 
"all.button.add_to_favorites":"Ajouter aux favoris", 
"all.button.next":"Continuer", 
"all.button.apply":"Appliquer", 
"all.button.save":"Sauvegarder", 
"all.button.giveup":"Abandonner", 
"all.button.recup":"Récupérer", 
"all.button.confirm":"Confirmer", 
"all.button.copy":"Copier", 
"all.button.paste":"Coller", 
"all.button.apply.all":"Tout appliquer", 
"all.button.cancel.all":"Tout annuler", 
"all.button.modification":"Modification", 
"all.button.send":"Envoyer", 
"all.text.block":"$t(all.button.delete)", 
"all.text.factu":"Facturation", 
"all.text.doc":"Documentation", 
"all.column_text.code":"$t(all.text.code)", 
"all.column_text.label":"Label", 
"all.column_text.name":"$t(all.text.name)", 
"all.column_text.state":"$t(all.text.state)", 
"all.column_text.row":"Ligne", 
"all.column_text.pdi_ref":"Référence PDI", 
"all.column_text.serial_meter":"$t(all.meter.meter_serial)", 
"all.column_text.meter":"$t(all.meter.meter)", 
"all.column_text.virtual_meter":"$t(all.text.virtual_meter)", 
"all.column_text.type":"$t(all.text.type)", 
"all.column_text.type_plural":"$t(all.text.type_plural)", 
"all.column_text.cons":"$t(all.read_meter.cons)", 
"all.column_text.cons_avg":"Consommation moyenne", 
"all.column_text.date":"$t(all.date.date)", 
"all.column_id":"Id", 
"all.brand.itron":"Itron", 
"all.address.city":"Ville", 
"all.address.country":"Pays", 
"all.address.complement":"Complément d'adresse", 
"all.address.street":"Voie", 
"all.address.street_number":"Numéro de la voie", 
"all.address.streetNumber":"$t(all.address.street_number)", 
"all.address.street_number_additionnal":"Additif", 
"all.address.streetNumberAdditionnal":"$t(all.address.street_number_additionnal)", 
"all.address.zipcode":"Code postal", 
"all.contact.civility":"Civilité", 
"all.contact.civility_male":"M", 
"all.contact.civility_female":"Mme", 
"all.contact.first_name":"Prénom", 
"all.contact.last_name":"$t(all.text.name)", 
"all.contact.phone":"Numéro de téléphone", 
"all.contact.email":"Email", 
"all.contact.mail":"$t(all.contact.email)", 
"all.contact.note":"$t(all.text.note)", 
"all.contact.no_contact":"Aucun contact", 
"all.devices":"Téléphones", 
"all.filter.filter_by_serial":"Filtrer par numéro de série", 
"all.filter.filter_by_ref":"Filtrer par référence", 
"all.filter.filter_by_name":"Filtrer par nom", 
"all.filter.saved_filter":"Filtre sauvegardé", 
"all.filter.saved_filter_plural":"Filtres sauvegardés", 
"all.filter.no_conf_filter_saved":"Pas de configuration de filtre sauvegardée", 
"all.filter.delete_saved_filter":"Supprimer le filtre sauvegardé", 
"all.filter.add_filter":"Ajouter un filtre", 
"all.filter.delete_filter":"Supprimer le filtre", 
"all.filter.filter":"Filtre", 
"all.filter.filter_plural":"Filtres", 
"all.filter.mask_filter":"Filtrer par masque", 
"all.filter.alarm_type_filter":"Filtrer par type d'alarme", 
"all.filter.round_filter":"Filtrer par tournée", 
"all.filter.round_filter_plural":"Filtrer par tournées", 
"all.filter.device_filter":"Filtrer par téléphone", 
"all.filter.device_filter_plural":"Filtrer par téléphones", 
"all.filter.with_hour":"Avec heures", 
"all.date.date":"Date", 
"all.date.date_plural":"Dates", 
"all.date.updated_on":"Mis à jour le ", 
"all.date.update_date":"Date de mise à jour", 
"all.date_format.date":"DD/MM/YYYY", 
"all.date_format.date_back":"dd/MM/yyyy", 
"all.date_format.date_with_time":"DD/MM/YYYY {{time}}", 
"all.date_format.date_and_time":"DD/MM/YYYY HH:mm", 
"all.date.today_date":"Date d'aujourd'hui", 
"all.date.end_date":"Date de fin", 
"all.user.user":"Utilisateur", 
"all.user.user_plural":"Utilisateurs", 
"all.user.profile":"Profil", 
"all.user.profile_plural":"Profils", 
"all.user.add_profile":"Ajouter un profil", 
"all.user.delete_profile":"Supprimer le profil", 
"all.user.profile_name":"Nom du profil", 
"all.user.role":"Rôle", 
"all.user.permission_plural":"Permissions", 
"all.user.my_info_plural":"Mes informations", 
"all.user.logout":"Se déconnecter", 
"all.user.user_required":"L'utilisateur est obligatoire", 
"all.user.pwd":"Mot de passe", 
"all.user.logIn":"Se connecter", 
"all.text.all":"Tout", 
"all.text.all_plural":"Tous", 
"all.text.no":"Non", 
"all.text.yes":"Oui", 
"all.text.unknown":"Inconnu", 
"all.text.no_data_available":"Pas de données disponibles", 
"all.text.indexed":"Indexé", 
"all.text.indexed_plural":"Indexés", 
"all.text.not_indexed":"Non indexé", 
"all.text.not_indexed_plural":"Non indexés", 
"all.text.modified":"Modifié", 
"all.text.modified_plural":"Modifiés", 
"all.text.not_modified":"Non modifié", 
"all.text.not_modified_plural":"Non modifiés", 
"all.text.gps":"Géolocalisé", 
"all.text.gps_plural":"Géolocalisés", 
"all.text.no_gps":"Non géolocalisé", 
"all.text.no_gps_plural":"Non géolocalisés", 
"all.text.multiple_select":"Multi-sélection", 
"all.text.edited_not_indexed_plural":"$t(tournee_stat.container_label.edited_not_indexed_plural)", 
"all.text.active":"Actif", 
"all.text.inactive":"Inactif", 
"all.text.latitude":"Latitude", 
"all.text.longitude":"Longitude", 
"all.text.not_possible_deletion":"Suppression impossible", 
"all.text.unity":"Unité", 
"all.text.configure":"Configurer", 
"all.text.filter_explanation":"Vous allez utiliser des filtres qui seront calculés dynamiquement", 
"all.text.required_field_character_condition":"Le champ est requis, ne doit pas contenir de caractères spéciaux autre que _ , ni d'accents, et faire moins de 100 caractères", 
"all.text.option":"Option", 
"all.text.option_plural":"Options", 
"all.text.select_file":"Sélectionner un fichier", 
"all.text.already_exist":"Existe déjà", 
"all.text.imported":"Importé", 
"all.text.msg":"Messages", 
"all.text.marking":"Marquages", 
"all.text.required":"Obligatoire", 
"all.text.required_field":"Champ obligatoire", 
"all.text.required_field_plural":"Champs obligatoires", 
"all.text.virtual_meter":"Compteur virtuel", 
"all.text.note":"Note", 
"all.text.manager":"Gestionnaire", 
"all.text.manager_plural":"Gestionnaires", 
"all.text.import_mfr":"Import constructeur", 
"all.text.silex_import":"Import Silex", 
"all.text.write_note":"Ecrire une note", 
"all.text.record":"Fiche", 
"all.text.record_plural":"Fiches", 
"all.text.update_date":"Dernière mise à jour", 
"all.text.marquage":"Marquage", 
"all.text.message":"Message", 
"all.text.particular_msg":"Message particulier", 
"all.text.import_billing":"Import patrimoine", 
"all.text.notif":"Notification", 
"all.text.notif_plural":"Notifications", 
"all.text.alert_plural":"$t(all.alert.alert_plural)", 
"all.text.detail_plural":"Détails", 
"all.text.stock":"Stock", 
"all.text.synchro":"Synchronisation", 
"all.text.start_new_releve":"Démarrer une nouvelle relève", 
"all.text.phone":"Portable", 
"all.text.phone_plural":"Portables", 
"all.text.name.export":"Export", 
"all.text.stat_plural":"Statistiques", 
"all.text.forbidden_access":"Non autorisé", 
"all.text.none_male":"Aucun", 
"all.text.none_female":"Aucune", 
"all.text.name":"Nom", 
"all.text.update_selected_conf":"Mettre à jour la configuration sélectionné", 
"all.text.save_new_conf":"Sauvegarder la nouvelle configuration", 
"all.text.operator":"Opérateur", 
"all.text.start_date":"Date de début", 
"all.text.end_date":"$t(all.date.end_date)", 
"all.text.new":"Nouveau", 
"all.text.name_lenght_max_100":"Le nom ne doit pas faire plus de 100 caractères", 
"all.text.link":"Associer", 
"all.text.unlink":"Dissocier", 
"all.text.model":"Modèle", 
"all.text.export_to":"Exporter en", 
"all.text.export_to_csv":"Exporter en CSV", 
"all.text.export_to_teleo":"Teleo", 
"all.text.type_x_list_loading":"Chargement de la liste des {{type}}", 
"all.text.not_specified":"Non renseigné", 
"all.text.zoom":"Zoom", 
"all.text.interval":"Intervalle", 
"all.text.export_data_from_x":"Exportation des données de {{name}}", 
"all.text.save_pdf":"Enregistrer le PDF", 
"all.text.preview_pdf":"Prévisualiser le PDF", 
"all.text.export_row_data":"Exporter les données brutes", 
"all.text.average":"Moyenne", 
"all.text.type":"Type", 
"all.text.type_plural":"Types", 
"all.text.information":"Information", 
"all.text.information_plural":"Informations", 
"all.text.state":"Etat", 
"all.text.start":"Début", 
"all.text.end":"Fin", 
"all.text.ok":"Ok", 
"all.text.more_info_plural":"Informations complémentaires", 
"all.text.last_update":"Dernière mise à jour", 
"all.text.unlock":"Débloquer", 
"all.text.locked":"Bloqué", 
"all.text.lock":"Bloquer", 
"all.text.link_all":"Tout associer", 
"all.text.dissociate_all":"Tout dissocier", 
"all.text.threshold":"Seuil", 
"all.text.edit_information":"Editer les informations", 
"all.text.edit_password":"Editer le mot de passe", 
"all.text.text_email":"Texte de votre mail", 
"all.text.mark_all_as_read":"Tout marquer comme lu", 
"all.text.title":"Titre", 
"all.text.read_notification":"Lire la notification", 
"all.text.resource_list":"Liste des ressources", 
"all.text.validate-_deletion_link":"Valider la suppression du lien", 
"all.text.widgets":"Widgets", 
"all.text.lora_import":"Importation LoRa", 
"all.text.code":"Code", 
"all.text.waiting_action":"Action en attente", 
"all.text.waiting_action_plural":"Actions en attentes", 
"all.text.go_back":"Retourner en arrière", 
"all.text.go_backward_step":"Retourner à l'étape précédente ?", 
"all.text.greater_than":"Superieur à", 
"all.text.less_than":"Inferieur à", 
"all.text.not_define":"Non défini", 
"all.text.summary":"Résumé", 
"all.text.calculation_method":"Méthode de calcul", 
"all.text.delete_x_ask":"Supprimer {{type}} ?", 
"all.text.irreversible_action_will_delete_x":"Cette action irreversible supprimera {{type}}.", 
"all.text.loading":"Chargement en cours", 
"all.text.selection":"Sélection", 
"all.text.more_informations_plural":"Infos supplémentaires", 
"all.text.select_all":"Tout sélectionner", 
"all.text.deselect_all":"Tout déselectionner", 
"all.text.submit_file":"Soumission du fichier", 
"all.text.install_date":"Date de pose", 
"all.text.reference":"Référence", 
"all.text.general_male":"Général", 
"all.text.previous":"Précédent", 
"all.text.close":"Fermé", 
"all.text.doclose":"Fermer", 
"all.text.open":"Ouvert", 
"all.text.doopen":"Ouvrir", 
"all.text.humidity":"Humidité", 
"all.text.temperature":"Température", 
"all.text.pressure":"Pression", 
"all.text.co2":"CO2", 
"all.text.downlink":"Downlink", 
"all.text.monday":"Lundi", 
"all.text.tuesday":"Mardi", 
"all.text.wednesday":"Mercredi", 
"all.text.thursday":"Jeudi", 
"all.text.friday":"Vendredi", 
"all.text.saturday":"Samedi", 
"all.text.sunday":"Dimanche", 
"all.text.version":"Version", 
"all.text.previous_releve":"Relève précédente", 
"all.text.value":"Valeur", 
"all.text.field":"Champ", 
"all.text.filter":"Filtrer", 
"all.text.result":"Résultat", 
"all.text.other":"Autre", 
"all.text.subscriber":"Abonné", 
"all.text.portable_informations":"Informations du portable", 
"all.text.portable_alias":"Alias", 
"all.text.irreversible_action_warning":"Attention, cette action est irréversible", 
"all.text.other_plural_fem":"autres..", 
"all.text.mounted":"Monté", 
"all.text.unmounted":"Démonté", 
"all.threshold.min_x_threshold":"seuil minimum :  {{value}}", 
"all.threshold.max_x_threshold":"seuil maximum :  {{value}}", 
"all.alert.alert":"Alerte", 
"all.alert.alert_plural":"Alertes", 
"all.alert.alert_name":"Nom de l'alerte", 
"all.alert.alert_type":"Type d'alerte", 
"all.alert.alert_conf":"Configurer l'alerte", 
"all.alert.delete_alert":"Supprimer l'alerte", 
"all.alert.edit_alert":"Modifier l'alerte", 
"all.alert.view_report_plural":"Voir les rapports", 
"all.alert.deactivate_alert":"Désactiver l'alerte", 
"all.alert.irreversible_action_deactivate_alert":"Cette action irréversible désactivera cette alerte du site", 
"all.homepage.homepage":"Page d'accueil", 
"all.homepage.choose_homepage":"Définir page d'accueil", 
"all.mask.mask_plural":"Masques", 
"all.mask.edit_mask":"Editer le masque", 
"all.mask.delete_mask":"Supprimer le masque", 
"all.mask.add_mask":"Ajouter un masque", 
"all.mask.mask_edition":"Edition de masque", 
"all.mask.mask_name":"Nom du masque", 
"all.device.last_login_date":"Date de la dernière connexion", 
"all.device.connected":"Connecté", 
"all.device.disconnected":"Déconnecté", 
"all.device.device_own_last_version":"Ce téléphone possède la dernière version de Saphir", 
"all.device.device_not_own_last_version":"Ce téléphone ne possède pas la dernière version de Saphir", 
"all.device.device_with_obsolete_version":"Ce téléphone possède une version obsolète de saphir et ne peut donc pas faire de com", 
"all.device.edit_device":"Editer le téléphone", 
"all.device.delete_device":"Supprimer le téléphone", 
"all.device.phone":"$t(all.text.phone)", 
"all.device.phone_plural":"$t(all.text.phone_plural)", 
"all.dashboard.dashboard_name":"Nom du tableau de bord", 
"all.dashboard.name_need_least_2_char":"Le nom du tableau de bord est obligatoire et doit avoir plus de 2 caractères", 
"all.dashboard.dashboard_favorite_plural":"Tableaux de bord favoris", 
"all.dashboard.dashboard_plural":"Tableaux de bord", 
"all.pdi.pdi":"PDI", 
"all.pdi.link_pdi_ref":"Référence du PDI associé", 
"all.pdi.pdi_ref":"Référence du PDI", 
"all.pdi.link_location_pdi":"PDI associés au site", 
"all.pdi.dissociate_location_pdi":"PDI dissociés du site", 
"all.pdi.go_to_pdi":"Aller à la fiche PDI", 
"all.pdi.state_pdi":"Etat du PDI", 
"all.pdi.add_location":"Ajouter une position GPS", 
"all.pdi.loading_location":"Chargement des coordonnées GPS", 
"all.location.location":"Site", 
"all.location.location_plural":"Sites", 
"all.location.address":"Adresse", 
"all.location.contact":"Contact", 
"all.location.note":"$t(all.text.note)", 
"all.location_button.access_location":"Accéder au site", 
"all.location.location_info":"Info du site", 
"all.location.pls_first_delete_child_location":"Veuillez d'abord supprimer les sites associés", 
"all.location.delete_location":"Supprimer le site", 
"all.round.location_follow_up":"Suivi du site", 
"all.round.round":"Tournée", 
"all.round.round_plural":"Tournées", 
"all.round.round_code":"Code tournée", 
"all.round.round_name":"Nom de la tournée", 
"all.round.go_to_record":"Aller à la fiche", 
"all.round.go_to_round":"Accéder à la tournée", 
"all.round.assigned_round_plural":"Tournées affectées", 
"all.round.round_info":"Info de la tournée", 
"all.round.round_follow_up":"Suivi de la tournée", 
"all.round.round_mngmt":"Gestion de tournées", 
"all.round.round_import":"Import tournée", 
"all.meter.serial":"Numéro de série", 
"all.meter.mfr":"Fabricant", 
"all.meter.meter_serial":"Numéro de série compteur", 
"all.meter.radio_serial":"Numéro de série radio", 
"all.meter.meter_serial_ref":"Numéro de série du compteur de référence", 
"all.meter.meter_serial_control":"Numéro de série du compteur témoin", 
"all.meter.available_meter":"Compteur disponible", 
"all.meter.available_meter_plural":"Compteurs disponibles", 
"all.meter.selection_explanation":"Vous allez sélectionner une liste finie de compteurs", 
"all.meter.confirm_x_selection_explanation":"Vous allez sélectionner {{count}} compteur du site", 
"all.meter.confirm_x_selection_explanation_plural":"Vous allez sélectionner les {{count}} compteurs du site", 
"all.meter.selected_meter":"Compteur sélectionné", 
"all.meter.selected_meter_plural":"Compteurs sélectionnés", 
"all.meter.real_meter_diameter":"Diamètre réel du compteur", 
"all.meter.meter_model":"Modèle du compteur", 
"all.meter.meter_manufacturer":"Constructeur du compteur", 
"all.meter.meter_index":"Index du compteur", 
"all.meter.index":"Index", 
"all.meter.meter_ref":"Compteur de référence", 
"all.meter.control_meter":"Compteur témoin", 
"all.meter.meter":"Compteur", 
"all.meter.meter_plural":"Compteurs", 
"all.meter.indexed_meter":"Compteur indexé", 
"all.meter.indexed_meter_plural":"Compteurs indexés", 
"all.meter.modified_meter":"Compteur modifié", 
"all.meter.modified_meter_plural":"Compteurs modifiés", 
"all.meter.all_meter_nb":"Nombre total de compteurs", 
"all.meter.virtual_meter_plural":"Compteurs virtuels", 
"all.meter.pulse_weight":"Poids d'impulsion", 
"all.meter.wheels_number":"Nombre de roues", 
"all.meter.add_virtual_meter":"Ajouter un compteur virtuel", 
"all.meter.go_to_meter":"Accéder à la fiche compteur", 
"all.meter.export_data_from_valid":"$t(all.text.export_data_from_x) - Relèves valides", 
"all.meter.edit_virtual_meter":"Editer le compteur virtuel", 
"all.meter.delete_virtual_meter":"Supprimer le compteur virtuel", 
"all.meter.virtual_meter_edition":"Edition de compteur virtuel", 
"all.meter.virtual_meter_name":"Nom du compteur virtuel", 
"all.meter.linked_meter_plural":"Compteurs associés", 
"all.meter.stack_index_plural":"Index cumulés", 
"all.meter.meter_to_x_plural":"Compteurs à {{mode}}", 
"all.meter.all_meter_dissociation_easy":"Les compteurs peuvent être dissociés du site sans vérification préalable", 
"all.meter.total_number_read_given_period":"Nombre total de compteurs géolocalisés captés sur la période donnée", 
"all.read_meter.read_last_date":"Date de dernière lecture", 
"all.read_meter.read_previous_date":"Date lecture précédente", 
"all.read_meter.total_conso_in_day":"Consommation sur une journée", 
"all.read_meter.total_conso_in_period":"Consommation sur {{totalDays}} jours", 
"all.read_meter.daily_avg_calc_info":"Sur une période de {{totalDays}} jours", 
"all.read_meter.daily_avg_cons_ratio":"Calculée à partir de {{totalReads}} lectures précédentes sur {{totalDays}} jours", 
"all.read_meter.daily_avg_cons":"Moyenne journalière", 
"all.read_meter.tendency":"Tendance", 
"all.read_meter.cons":"Consommation", 
"all.read_meter.cons_gap":"Ecart de consommation", 
"all.read_meter.last_read":"Dernière relève", 
"all.read_meter.cons_history":"Historique des consommations", 
"all.read_meter.no_current_read":"Aucune relève courante", 
"all.read_meter.read_history":"Historique des relèves", 
"all.read_meter.empty_cons":"Consommation nulle", 
"all.read_meter.high_cons":"Consommation forte", 
"all.read_meter.normal_cons":"Consommation normale", 
"all.read_meter.low_cons":"Consommation faible", 
"all.read_meter.cons_comparison":"Comparaison de consommations", 
"all.read_meter.max_index":"Index maximum", 
"all.read_meter.read_start_date":"Date de début de relève", 
"all.read_meter.read_method":"Méthode de relève", 
"all.read_meter.cons_m3":"Conso m<sup>3</sup>", 
"all.read_meter.cons_history_m3":"Historique des consommations (m<sup>3</sup>)", 
"all.read_meter.cons_history_m3_facturation":"Historique facturation des consommations  (m<sup>3</sup>)", 
"all.read_meter.cons_history_m3_radio":"Historique radio des consommations (m<sup>3</sup>)", 
"all.read_meter.type.M":"Manuel", 
"all.read_meter.type.A":"$t(all.radio.radio)", 
"all.read_meter.type.T":"Télérelève", 
"all.read_meter.consumptions":"Consommations", 
"all.read_meter.read_meters":"Compteurs relevés", 
"all.read_meter.not_read_meters":"Compteurs non relevés", 
"all.alarm_meter.alarm":"Alarme", 
"all.alarm_meter.alarm_plural":"Alarmes", 
"all.alarm_meter.alarm_type":"Types d'alarmes", 
"all.alarm_meter.alarms_list":"Liste alarmes", 
"all.alarm_meter.show_masked_alarm_plural":"Visualiser les alarmes masquées", 
"all.alarm_meter.alarm_history_plural":"Historique des alarmes", 
"all.radio.radio":"Radio", 
"all.radio.radio_plural":"Radio", 
"all.radio.all_radio_nb":"Nombre total de radio", 
"all.radio.current_radio":"Radio courante", 
"all.radio.go_to_radio":"Accéder à la fiche radio", 
"all.radio.no_linked_radio":"Aucune radio associée", 
"all.radio.radio_history":"Historique des radio", 
"all.radio.no_old_radio_linked":"Aucune ancienne radio associée", 
"all.radio.radio_manufacturer":"Constructeur de la radio", 
"all.radio.radio_model":"Modèle radio", 
"all.radio.mount_date":"Date de montage ", 
"edit_device.field_label.phone_name":"Nom du portable", 
"edit_device.field_error_msg.name_required_min_2_char":"Le nom du portable est obligatoire et doit avoir plus de 2 caractères", 
"edit_device.main_title.edit_phone":"Editer un téléphone", 
"edit_device.main_title.delete_phone":"Supression d'un portable", 
"edit_device.text.code_max_2_char":"Le code facturation du portable ne peut pas faire plus de 2 caractères", 
"edit_device.modal.text_confirm":"Êtes-vous sûr de vouloir supprimer ce portable ?", 
"edit_device.modal.text_warning_confirm":"Voulez-vous vraiment supprimer ce portable ?", 
"edit_device.modal.text_warning_confirm_round":"Attention! Ce portable contient actuellement une tournée", 
"edit_device.modal.text_warning_confirm_x_round":"Attention! Ce portable contient actuellement {{count}} tournées", 
"gestionnaires.main_title.managers":"$t(all.text.manager_plural)", 
"gestionnaires.tree_view_placeholder.search_manager":"Rechercher un gestionnaire", 
"import_constructeur.main_title.import_mfr":"$t(all.text.import_mfr)", 
"import_silex.main_title.import_silex":"$t(all.text.silex_import)", 
"import_silex.input_label.silex_file":"Fichier Silex", 
"import_silex.tab_label.file_import":"Import tournées", 
"import_silex.tab_label.facturation_import":"Import facturation", 
"import_silex.error_band.load_x_file_failed":"Chargement du fichier Silex {{fileName}} echoué", 
"import_silex.loading_band.silex_file_loading":"Import du fichier Silex en cours", 
"import_silex.loading_band.import_facturation_loading":"Import depuis la facturation en cours", 
"import_silex.error_band.import_error":"Une erreur est survenue lors de l'import", 
"import_silex.error_band.facturation_unreachable":"Une erreur est survenue lors de la connection au service de facturation", 
"import_silex.submission_title.submission_file":"$t(all.text.submit_file)", 
"import_silex.button.admin_submit":"Soumettre en admin (T2 & T4 prises en compte)", 
"import_silex.button.radio_file_submit":"Soumettre un fichier de radio", 
"import_silex.success_msg.x_loaded_silex_file":"Chargement du fichier Silex effectué, {{count}} tournée importée", 
"import_silex.success_msg.x_loaded_silex_file_plural":"Chargement du fichier Silex effectué, {{count}} tournées importées", 
"import_silex.report_title.importation_report":"Rapport d'importation", 
"import_silex.column_header.round_code":"$t(all.round.round_code)", 
"import_silex.column_header.round_name":"$t(all.round.round_name)", 
"import_silex.column_header.round_status":"Statut", 
"import_silex.column_header.first_row":"Ligne de début", 
"import_silex.column_header.last_row":"Ligne de fin", 
"import_silex.status_text.already_exist":"$t(all.text.already_exist)", 
"import_silex.status_text.imported":"$t(all.text.imported)", 
"import_silex.error_band.already_exists_round":"La tournée {{round}} existe déjà.", 
"import_silex.error_band.already_exists_rounds":"Les tournées {{rounds}} existent déjà.", 
"import_silex.loading_band.analysis_in_progress":"Analyse du fichier en cours", 
"import_silex.loading_band.import_in_progress":"Import de \"{{label}}/{{code}}\", tournée {{number}}/{{numberOfRound}}", 
"import_silex.loading_band.validating_import":"Validation des nouvelles données", 
"import_silex.facturation_import.title":"Import depuis la facturation", 
"import_silex.facturation_import.button_label":"Lancer l'import", 
"list_adder.main_title.add_x_list":"Ajouter une liste de {{type}}", 
"list_adder.modal_header.add_x_type":"Ajouter des {{type}}", 
"list_adder.modal_header.file_x_type":"Fichier {{type}}", 
"list_marquages.loading_band.loading_marking":"Import des marquages en cours", 
"list_messages.loading_band.loading_msg":"Import des messages en cours", 
"list_message_add_file":"Ajouter un fichier", 
"list_message_no_file":"Aucun fichier choisi", 
"list_message_or":"OU", 
"list_message_manually":"Ajouter manuellement", 
"list_message_add":"Ajouter un", 
"synchronisation.loading_band.loading_rounds_":"Import des tournées et téléphones en cours", 
"synchronisation.option.partial_sync":"Synchronisation partielle", 
"synchronisation.error_band.devices_not_connected":"Certains portables lié à la tournée ne sont pas connectés", 
"tournee_ressources.title_tab.rounds":"$t(all.round.round_plural)", 
"tournee_ressources.column_text.affected_phone":"Téléphone(s) affecté(s)", 
"tournee_ressources.column_text.round_code":"$t(all.column_text.code)", 
"tournee_ressources.column_exported_tooltip.unload_round":"Tournée déchargée", 
"tournee_ressources.column_exported_tooltip.load_round":"Tournée chargée", 
"tournee_ressources.column_exported_tooltip.waiting_load":"En attente d'affectation", 
"tournee_ressources.column_exported_tooltip.unload_all":"Tout désaffecter", 
"tournee_ressources.column_exported_tooltip.waiting_unload":"En attente de désaffectation", 
"tournee_ressources.column_exported_tooltip.exported_x_date":"Exporté le {{date}}", 
"tournee_ressources.column_exported_tooltip.never_exported":"Tournée jamais exportée", 
"tournee_ressources.column_exported_tooltip.exported_round":"Tournée exportée", 
"tournee_ressources.tooltip.delete_round":"Supprimer la tournée", 
"tournee_ressources.tooltip.export_round":"Exporter la tournée", 
"tournee_ressources.tooltip.access_round_detail":"Accéder aux détails de la tournée", 
"tournee_ressources.popup_title.ask_delete_x_round":"Supprimer la tournée {{roundName}} ?", 
"tournee_ressources.popup_title.some_round_cant_delete":"Certaines tournées sélectionnées ne peuvent pas être supprimées", 
"tournee_ressources.deletable_rounds_confirmation":"Voulez-vous tout de même supprimer les tournées suivantes ?", 
"tournee_ressources.no_round_can_be_deleted":"Aucune des tournées sélectionnées ne peut être supprimée", 
"tournee_ressources.popup_title.ask_delete_x_round_plural":"Supprimer les tournées sélectionnées ?", 
"tournee_ressources.popup_msg.delete_x_round_data":"Cette action irreversible supprimera toutes les données de la tournée {{roundName}}.", 
"tournee_ressources.popup_msg.delete_x_round_data_plural":"Cette action irreversible supprimera toutes les données des tournées suivantes : [ {{roundName}} ].", 
"tournee_ressources.popup_msg.delete_x_round_not_eligible":"La tournée suivante ne peut pas être supprimée car soit utilisée par un portable soit non exportée : {{roundNameNotEligible}}.", 
"tournee_ressources.popup_msg.delete_x_round_not_eligible_plural":"Les tournées suivantes ne peuvent pas être supprimées car soit utilisées par un portable soit non exportées : [ {{roundNameNotEligible}} ].", 
"tournee_ressources.column.avancement":"Avancement", 
"synchro.command.listround":"Lecture des données depuis le terminal", 
"synchro.command.listdevice":"Connexion au portable", 
"synchro.command.uploadround":"Téléchargement des données depuis le terminal", 
"synchro.command.downloadround":"Chargement des données sur le terminal", 
"synchro.command.downloadroundresponse":"Fin du chargement des données", 
"synchro.command.closedevice":"Finalisation de la communication", 
"synchro.command.closedeviceresponse":"Fin de communication", 
"synchro.command.listroundresponse":"Analyse des données lues", 
"synchro.command.uploadroundresponse":"Intégration des données reçues", 
"synchro.command.listdeviceresponse":"Connexion au portable établie", 
"synchro.command.removeround":"Suppression des données", 
"synchro.command.removeroundresponse":"Suppression des données terminées", 
"synchro.command.downloadmark":"Chargements des marquages", 
"synchro.command.downloadmessage":"Chargements des messages", 
"synchro.command.busydevice":"En attente ...", 
"synchro.command.error":"Erreur", 
"synchro.command.downloadamrkey":"Chargement des clés AMR", 
"tournee_ressources.delete_round":"Supprimer cette tournée", 
"tournee_ressources.cannot_delete_round":"Impossible de supprimer cette tournée", 
"tournee_ressources.round_not_selected":"Cette tournée n'a pas été sélectionnée", 
"tournee_ressources.round_pending_affectation":"tournée en attente d'affectation", 
"tournee_ressources.round_pending_deaffectation":"tournée en attente de désaffectation", 
"tournee_ressources.round_modif_without_export":"tournée modifiée sans avoir été exportée", 
"tournee_ressources.round_used_by_devices":"tournée utilisée par des portables", 
"tournee_ressources.round_never_exported":"cette tournée n'a jamais été exportée", 
"tournee_ressources.deleting_round":"Suppression d'une tournée", 
"tournee_ressources.deleting_round_plural":"Suppression de plusieurs tournées", 
"conflict_handling.change_type.meter_change":"Changement de compteur", 
"conflict_handling.title.singular":"Conflit", 
"conflict_handling.title.plural":"Conflits", 
"conflict_handling.title.neutral":"Conflit(s)", 
"conflict_handling.associated_devices":"Portables associés au conflit", 
"conflict_handling.author":"Responsable de la gestion de conflit", 
"conflict_handling.handling.main_instruction":"Sélectionnez les correctifs que vous souhaitez apporter à la tournée ", 
"conflict_handling.execution_time.delayed":"Appliquer lors de la prochaine synchronisation", 
"conflict_handling.execution_time.immediate":"Appliquer immédiatement", 
"conflict_handling.no_conflict_handling":"Pas de gestion de conflit", 
"conflict_handling.loading":"Chargement du conflit en cours", 
"conflict_handling.no_conflict_handling_on_round":"Aucune gestion de conflit sur cette tournée", 
"conflict_handling.conflict_open_button":"Cliquez ici pour en savoir plus", 
"conflict_handling.conflict_in_round":"Conflit(s) en cours sur la tournée", 
"conflict_handling.conflict_in_round_and_device":"Conflit(s) en cours entre cette tournée et le/les portable(s) suivant(s)", 
"conflict_handling.no_date":"Aucune date", 
"conflict_handling.conflict_in_device":"Un conflit de données a été généré par ce portable", 
"tournee_detail.info_container.round_code":"$t(all.round.round_code)", 
"tournee_detail.info_container.round_msg":"Message tournée", 
"tournee_detail.info_container.start_read_date":"$t(all.read_meter.read_start_date)", 
"tournee_detail.info_container.all_meter_nb":"$t(all.meter.all_meter_nb)", 
"tournee_detail.info_container.all_radio_nb":"$t(all.radio.all_radio_nb)", 
"tournee_detail.map_title.any_meter_gps":"Aucun compteur géolocalisé", 
"tournee_detail.field_placeholder.write_note":"$t(all.text.write_note)", 
"tournee_detail.tooltip.edit_note":"Editer le message", 
"tournee_detail.main_title.round_name":"$t(all.round.round_name)", 
"tournee_fiche.main_title.record":"$t(all.text.record)", 
"tournee_fiche.main_title.record_plural":"$t(all.text.record_plural)", 
"tournee_fiche.tooltip.invalid_cache":"Invalider le cache", 
"tournee_fiche.loading_band.load_round_record":"Chargement des fiches de la tournée", 
"tournee_stat.container_title.radio_type":"Type de module(s) AMR", 
"tournee_stat.container_title.radio_read_state":"Etat de la relève AMR", 
"tournee_stat.container_title.cons_state":"Etat des consommations", 
"tournee_stat.container_title.status_edited":"Statut des modifiés", 
"tournee_stat.container_state_read.not_read":"AMR Non relevé", 
"tournee_stat.container_state_read.not_radio":"Non AMR", 
"tournee_stat.container_state_read.read_with_alarm":"AMR Relevé avec Alarme", 
"tournee_stat.container_state_read.read_with_no_alarm":"AMR Relevé sans Alarme", 
"tournee_stat.container_label.edited_not_indexed":"Modifié non indexé", 
"tournee_stat.container_label.edited_not_indexed_plural":"Modifiés non indexés", 
"tournee_stat.container_label.not_edited_not_indexed":"Non modifié/indexé", 
"tournee_stat.container_label.not_edited_not_indexed_plural":"Non modifiés/indexés", 
"tournee_stat.container_title.nb_meter_year":"Nombre de compteurs par année de pose", 
"tournee_stat.container_title.nb_meter":"Nombre de compteur(s)", 
"tournee_creation.container_label.template":"Création de votre modèle d'information abonné / compteur", 
"tournee_creation.input_label.template_name":"Nom de votre modèle", 
"tournee_creation.container_label.create_tournee":"Créer une tournée", 
"tournee_creation.container_label.template_list":"Liste de vos modèles informations abonné / compteur", 
"tournee_creation.container_label.tournee_informations":"Informations tournée", 
"tournee_creation.container_label.tournee_template":"Modèles informations de la tournée", 
"tournee_creation.input_label.autorize_meter_change":"Autoriser le changement de compteur", 
"tournee_creation.dropzone.or_drop_file":"ou déposez le fichier ici", 
"tournee_creation.dropzone.drop_autorized":"Et on relâche !", 
"tournee_creation.dropzone.drop_unautorized":"Vous ne pouvez importer qu'un fichier à la fois", 
"tournee_creation.dropzone.incorrect_format":"Format de fichier incorrect. Formats autorisés : {{fileformats}}", 
"tournee_creation.dropzone.authorized_format":"Format {{fileformats}}", 
"tournee_creation.button.start":"Commencer", 
"tournee_creation.input_label.line_x":"Ligne {{lineNumber}}", 
"tournee_creation.input_label.information_on_default":"Personnalisez si vous le souhaitez le texte à afficher au cas où les informations générées sont vides :", 
"tournee_creation.placeholder.default_info":"Pas de données générées", 
"tournee_creation.container_label.template_creation":"Création de vos lignes", 
"tournee_creation.container_label.tag_creation":"Créer une étiquette", 
"tournee_creation.container_label.tag_modification":"Modifier l'étiquette {{tagName}}", 
"tournee_creation.container_label.list_tag":"Liste de vos étiquettes", 
"tournee_creation.container_label.list_tag_subtitle":"Utilisez vos étiquettes pour remplir vos lignes", 
"tournee_creation.input_label.modifiable":"Modifiable", 
"tournee_creation.input_label.unmodifiable":"Non-modifiable", 
"tournee_creation.input_label.code_placeholder":"Saisissez le code tournée", 
"tournee_creation.input_label.message_placeholder":"Saisissez votre message", 
"tournee_creation.input_label.wheels_placeholder":"Nombre de roues (Par defaut: 5)", 
"tournee_creation.input_label.pdi_template_placeholder":"Sélectionnez votre modèle information abonné", 
"tournee_creation.input_label.meter_template_placeholder":"Sélectionnez votre modèle information compteur", 
"tournee_creation.template_popover.title":"Suppression d'un modèle information abonné / compteur", 
"tournee_creation.template_popover.confirm_text":"Êtes-vous sûr de vouloir supprimer ce modèle ?", 
"tournee_creation.input_error.empty_round_name":"Veuillez renseigner un nom de tournée", 
"tournee_creation.input_error.round_name_already_exists":"Nom tournee déjà existant", 
"tournee_creation.input_error.empty_round_code":"Veuillez renseigner le code tournée", 
"tournee_creation.input_error.round_code_already_exists":"Code tournee déjà existant", 
"tournee_creation.input_error.empty_template":"Veuillez sélectionner un modèle", 
"tournee_creation.input_error.round_wheels_too_high":"Le nombre de roues doit être compris entre 4 et 9", 
"tournee_creation.input_error.round_wheels_too_low":"$t(tournee_creation.input_error.round_wheels_too_high)", 
"tournee_creation.input_error.template_name_already_exists":"Nom de modèle déjà existant", 
"tournee_creation.input_error.empty_template_name":"Veuillez entrer un nom de modèle", 
"tournee_creation.input_error.template_name_too_long":"Le nom du modèle est trop long. Max `: 30 caractères", 
"tournee_creation.input_error.line_too_long":"Le nombre de caractère théorique dépasse les 30 caractères maximum, l'excédent sera ignoré", 
"tournee_creation.input_error.default_info_too_long":"Le texte par défaut est trop long. Max : 30 caractères", 
"tournee_creation.input_error.empty_tag_name":"Veuillez renseigner le nom du tag", 
"tournee_creation.input_error.tag_name_already_exists":"Nom de tag déjà existant", 
"tournee_creation.input_error.tag_name_is_too_long":"Le nom de l'étiquette est trop long. Max : 16 caractères", 
"tournee_creation.input_error.empty_tag_info":"Veuillez selectionner l'information à afficher", 
"tournee_creation.input_error.empty_tag_size":"Veuillez selectionner la taille de l'étiquette", 
"tournee_creation.input_error.tag_size_too_high":"La taille de l'étiquette doit être compris entre 1 et 30", 
"tournee_creation.input_error.tag_size_too_low":"$t(tournee_creation.input_error.tag_size_too_high)", 
"tournee_creation.input_label.type_text":"$t(enquete.field_type.A)", 
"tournee_creation.input_label.type_number":"Nombre", 
"tournee_creation.input_label.type_date":"$t(all.date.date)", 
"tournee_creation.success.templateCreation":"Modèle {{successTarget}} créé", 
"tournee_creation.success.templateUpdate":"Modèle {{successTarget}} modifié", 
"tournee_creation.success.templateDeletion":"Modèle {{successTarget}} supprimé", 
"tournee_creation.success.tagCreation":"Étiquette {{successTarget}} créé", 
"tournee_creation.success.tagUpdate":"Étiquette {{successTarget}} modifié", 
"tournee_creation.success.tagDeletion":"Étiquette {{successTarget}} supprimé", 
"tournee_creation.success.roundCreation":"Tournée {{tourneeName}} créé", 
"tournee_creation.loading_band.template_creation":"Modèle en cours de création", 
"tournee_creation.loading_band.template_update":"Modèle en cours de modification", 
"tournee_creation.loading_band.template_deletion":"Modèle en cours de suppression", 
"tournee_creation.loading_band.tag_creation":"Étiquette en cours de création", 
"tournee_creation.loading_band.tag_update":"Étiquette en cours de modification", 
"tournee_creation.loading_band.tag_deletion":"Étiquette en cours de suppression", 
"tournee_creation.loading_band.tournee_creation":"Tournee en cours de création", 
"tournee_creation.error_band.tag_not_found":"Étiquette {{tag}} non trouvée", 
"tournee_creation.error_band.tag_still_linked":"Impossible de supprimer l'étiquette {{tag}}. L'étiquette toujours liée aux modèles suivants : {{templates}}", 
"tournee_creation.error_band.errors_in_tag":"Des erreurs sont présentes dans l'étiquette envoyée : {{errors}}", 
"tournee_creation.error_band.template_not_found":"Modèle {{template}} non trouvé", 
"tournee_creation.error_band.errors_in_template":"Des erreurs sont présentes dans le modèle envoyé : {{errors}}", 
"tournee_creation.error_band.tag_in_use":"Impossible de supprimer l'étiquette {{tag}}. Étiquette en cours d'utilisation.", 
"tournee_creation.error_band.errors_in_tournee":"Des erreurs sont présentes dans la tournée envoyée : {{errors}}", 
"tournee_creation.error_band.template_still_linked":"Impossible de supprimer le modèle {{template}}. Le modèle est toujours lié au tournées suivantes: {{rndList}}.", 
"tournee_creation.input_error.tournee_name_is_empty":"Nom de la tournée vide", 
"tournee_creation.input_error.tournee_name_already_exists":"Nom de tournée déjà existant", 
"tournee_creation.input_error.tournee_code_is_empty":"Code tournée vide", 
"tournee_creation.input_error.tournee_default_wheels_too_low":"Nombre de roues par défaut inférieur à 4", 
"tournee_creation.input_error.tournee_default_wheels_too_high":"Nombre de roues par défaut supérieur à 9", 
"tournee_creation.input_error.tournee_collect_point_template_is_missing":"Modèle abonné non renseigné ", 
"tournee_creation.input_error.tournee_meter_template_is_missing":"Modèle compteur non renseigné ", 
"tournee_creation.input_error.tournee_meter_list_is_empty":"Liste des compteurs vide", 
"tournee_creation.input_error.tournee_meter_list_size_over_limit":"Nombre de compteurs au dessus de 9999", 
"tournee_creation.input_info.remaining_characters_plural":"{{nbRemainingChar}} caractères restants", 
"tournee_creation.input_info.remaining_characters":"{{nbRemainingChar}} caractère restant", 
"tournee_creation.popover.tag_too_long":"L'étiquette ne peut pas être ajoutée. La taille de l'étiquette dépasse le nombre de caratères restants sur la ligne.", 
"create_meter.main_title.create_meter":"Créer un compteur", 
"create_meter.field_validate.serial_least_5_char":"Le numéro de série doit contenir au moins 5 caractères", 
"create_meter.field_validate.serial_max_20_char":"Le numéro de série doit contenir maximum 20 caractères", 
"create_meter.field_validate.serial_valid":"Renseigner un numéro de compteur valide", 
"create_meter.field_error_msg.need_serial":"Le numéro de série doit être renseigné", 
"create_meter.success_band.meter_created":"Le compteur a été créé", 
"create_pdi.main_title.create_pdi":"Créer un PDI", 
"location_address_chooser.loading_band.search_gps":"Recherche des coordonnées gps ...", 
"location_address_chooser.link_container_title.link_meter":"Associer un compteur", 
"location_address_chooser.success_band.pdi_created":"Le pdi a été créé", 
"location_address_chooser.warning_message.format_city_name":"Le nom de la ville ne doit pas contenir de chiffres ou de caractères spéciaux", 
"location_address_chooser.warning_message.format_streetnumber_name":"Le numéro de la voie doit contenir exclusivement des chiffres", 
"location_address_chooser.warning_message.format_zipcode_name":"Le code postal doit contenir 5 chiffres", 
"stock_importation.text.launch_by":"Lancé par", 
"stock_importation.main_title.import_billing":"$t(all.text.import_billing)", 
"stock_importation.input_file_label.import_file":"Fichier d'import (.csv, .tsv, .xls, .xlsx)", 
"stock_importation.step_1_loading_band.send_file":"Envoi du fichier en cours ...", 
"stock_importation.step_2_loading_band.check_file":"Vérification du fichier en cours ...", 
"stock_importation.step_3_success_band.integration_x_row_and_x_modified":"Intégration effectuée avec succès.{{nbRow}} lignes traitées dont {{count}} modifiée", 
"stock_importation.step_3_success_band.nb_meter_archived":"{{nbRow}} PDI archivés", 
"stock_importation.step_3_success_band.integration_x_row_and_x_modified_plural":"Intégration effectuée avec succès.{{nbRow}} lignes traitées dont {{count}} modifiées", 
"stock_importation.error.serial_too_long":"Le numéro de série {{element}} est d'une taille supérieur à 12 caractères", 
"stock_importation.error.wheels_wrong_value":"Le nombre de roues n'est pas entre 4 et 9. Nombre de roues : {{element}}", 
"stock_importation.error.date_too_old":"La date {{element}} est antérieur au 01/01/1970", 
"stock_importation.error.index_too_long":"La taille de l'lindex {{element}} est supérieur à 9", 
"stock_importation.error.depose_date_expected":"La date de dépose de l'ancien compteur est manquante", 
"stock_importation.error.serial_number_missing":"Le numéro de série de l'ancien compteur est manquant", 
"stock_importation.error.depose_index_expected":"L'index de dépose de l'ancien compteur est manquante", 
"stock_importation.importation_report.action_meter_change":"Changement de compteur", 
"stock_importation.importation_report.action_creation":"Création", 
"stock_importation.importation_report.action_unauthorized":"Changement rejeté", 
"stock_importation.importation_report.action_modification":"Modification", 
"stock_importation.importation_report.detail_address":"Adresse", 
"stock_importation.importation_report.detail_start_date":"Date de début", 
"stock_importation.importation_report.detail_meter_serial":"Numéro de série compteur", 
"stock_importation.importation_report.detail_notes":"Notes", 
"stock_importation.importation_report.detail_custom_info":"Informations personnalisées", 
"stock_importation.importation_report.detail_state":"État", 
"stock_importation.importation_report.detail_revised_wheels":"Nombre de roues", 
"stock_importation.importation_report.detail_meter_model":"Modèle du compteur", 
"stock_importation.importation_report.detail_empty":"Vide", 
"stock_importation.importation_report.download_button":"Télécharger le rapport", 
"stock_importation.importation_report.download_loading":"Envoie du rapport en cours", 
"stock_importation.importation_report.download_error":"Une erreur est survenue pendant l'envoie du fichier", 
"stock_importation.importation_report.detail_gps_longitude":"Coordonées GPS - Longitude", 
"stock_importation.importation_report.detail_gps_latitude":"Coordonées GPS - Latitude", 
"stock_importation.importation_report.detail_gps_altitude":"Coordonées GPS - Altitude", 
"stock_importation.importation_report.detail_gps_score":"Coordonées GPS - Score", 
"stock_importation.importation_report.detail_gps_accuracy":"Coordonées GPS - Précision", 
"stock_importation.error.not_match_config":"Le fichier ne correspond pas à la configuration", 
"import.loading_band.import_already_loading":"Un import est déjà en cours de traitement", 
"import.step_1_title.first_step_load_file":"Première étape : Chargement du fichier", 
"import.step_1_success_band.load_file":"Chargement du fichier effectué", 
"import.step_1_loading_band.file_analysis":"Analyse du fichier en cours ...", 
"import.step_2_title.second_step_check_error":"Deuxième étape : Contrôle des erreurs du fichier", 
"import.list.x_row":"Ligne {{row}},    PDI {{pdi}},    Numéro de série {{serial}}", 
"import.step.warning":"Avertissements :", 
"import.step_2_help.check_error_for_import":"Veuillez vérifier les erreurs ci-dessus afin de tester l'intégration", 
"import.step_2_button.test_integration":"Tester l'intégration", 
"import.step_2_success_band.test_integration":"Test d'intégration effectué", 
"import.step_3_title.third_step_check_integration":"Troisième étape : Contrôle des problèmes d'intégration", 
"import.step_error.error":"Erreurs", 
"import.step_3_help.check_error_for_finish_import":"Veuillez vérifier les erreurs ci-dessus afin de finaliser l'intégration", 
"import.step_3_loading_band.integration_file":"Intégration du fichier en cours ...", 
"import.step_4_title.integration_report":"Rapport d'intégration", 
"import.anomaly_text.emptyMeterSerial":"Numéro de série compteur vide", 
"import.anomaly_text.dateLineError":"Date invalide (inférieur à 1970)", 
"import.anomaly_text.emptyPDIReference":"Référence PDI vide", 
"import.anomaly_text.doublonMeter":"Numéro de série compteur en doublon", 
"import.anomaly_text.doublonPdi":"Référence PDI en doublon", 
"import.anomaly_text.unfoundModel":"Modèle compteur non trouvé", 
"import.anomaly_text.datePoseBeforeActual":"Date de pose du fichier antérieur à la date de pose en base", 
"import.anomaly_text.collectPointUsedByRound":"PDI avec changement de compteur mais en cours de relève", 
"import.anomaly_text.meterUsedByRound":"Compteur avec changement de PDI mais en cours de relève", 
"import.anomaly_text.doublons":"Doublons", 
"import.anomaly_text.doublonsMeter":"Doublons compteurs", 
"import.anomaly_text.doublonsPdi":"Doublons PDI", 
"import.anomaly_text.field_with_date_inf_1970":"Champ avec date invalide", 
"import.anomaly_text.datePoseNull":"Date de pose vide", 
"import_component.input_file_label.import_file":"Fichier d'import", 
"import_component.step_2_loading_band.testing_file":"Test du fichier en cours ...", 
"import_component.step_3_success_band.integration_x_row_success":"Intégration effectuée avec succès. {{count}} ligne traitée.", 
"import_component.step_3_success_band.integration_x_row_success_plural":"Intégration effectuée avec succès. {{count}} lignes traitées.", 
"alert_content.alarm_title.alarm_mfr":"Alarmes constructeurs", 
"alert_content.alarm_title.read_gap":"Ecart de lecture", 
"alert_content.alarm_title.real_cons":"Consommation réelle", 
"alert_content.alarm_title.cons_avg":"Moyenne de consommation", 
"alert_content.alarm_title.cons_comparison":"$t(all.read_meter.cons_comparison)", 
"alert_content.alarm_title.cons_gap":"$t(all.read_meter.cons_gap)", 
"alert_content.alarm_title.cons_alert":"Alertes de consommation", 
"alert_content.alarm_text.alarm_meter_since_integration":"Alarme du compteur (depuis la dernière intégration)", 
"alert_content.alarm_text.cons_of_period":"Consommation au court de la période prédéfini", 
"alert_content.alarm_text.read_gap_today":"Ecart de lecture par rapport à la date d'aujourd'hui", 
"alert_content.alarm_text.cons_limit_since_integration":"Limite de consommation min et/ou max (en m3) pour toutes les consommations depuis la dernière intégration", 
"alert_content.alarm_text.m3_cons_interval_of_cons_avg":"Intervalle de consommation min et/ou max (en m3) entre la consommation moyenne et la dernière consommation moyenne (depuis la dernière intégration)", 
"alert_content.alarm_text.cons_interval_of_two_meter":"Intervalle de consommation max (en pourcentage) entre la dernière consommation du compteur 1 et du compteur 2 (depuis la dernière intégration)", 
"alert_content.alarm_text.pct_cons_interval_of_cons_avg":"Intervalle de consommation min et/ou max (en pourcentage) entre la consommation moyenne et la consommation moyenne périodique (pour le dernier mois/semaine/jour)", 
"alert_content.alarm_result.result":"$t(all.text.result)", 
"alert_content.alarm_result.x_cons_gap":"Ecart de consommation : {{gapCons}}", 
"alert_content.meter_type.ref":"$t(all.text.reference)", 
"alert_content.meter_type.witness":"Témoin", 
"alert_content.table_title.alert_meter_list_and_info":"Voici la liste des compteurs en alerte, ainsi que les informations associées", 
"alert_content.table_title.alert_virtual_meter_list_and_info":"Voici la liste des compteurs virtuels en alerte, ainsi que les informations associées", 
"alert_content.warning.no_alert_found":"Aucune alerte n'a été relevée sur cette période", 
"alert_content.column_text.gap_last_date":"Ecart depuis la dernière date", 
"alert_content.column_text.to_date":"Jusqu'au", 
"alert_content.column_text.since_date":"Depuis le", 
"alert_content.column_text.ref_cons":"Consommation de référence", 
"alert_content.column_text.avg_day_cons":"Consommation moyenne par jour", 
"alert_content.column_text.cons_gap":"$t(all.read_meter.cons_gap)", 
"alert_content.column_text.read_date":"Date de relève", 
"alert_creator.name.rangeconsumption":"$t(all.read_meter.cons)", 
"alert_creator.name.gapconsumption":"$t(all.read_meter.cons_gap)", 
"alert_creator.name.gapconsumptioncomparison":"$t(all.read_meter.cons_comparison)", 
"alert_creator.name.alarms":"$t(alert_content.alarm_title.alarm_mfr)", 
"alert_creator.name.averagerangeconsumption":"$t(alert_content.alarm_title.cons_avg)", 
"alert_creator.name.rangerealconsumption":"$t(alert_content.alarm_title.real_cons)", 
"alert_creator.name.noreadformeteralert":"Retard de relève de compteur", 
"alert_creator.name.consumptionhour":"Consommation au cours d'un horaire", 
"alert_creator.extra_info.rangeconsumption":"Limite de consommation min et/ou max (en m3) pour la dernière consommation moyenne périodique (pour le dernier mois/semaine/jour)", 
"alert_creator.extra_info.gapconsumption":"$t(alert_content.alarm_text.pct_cons_interval_of_cons_avg)", 
"alert_creator.extra_info.gapconsumptioncomparison":"$t(alert_content.alarm_text.cons_interval_of_two_meter)", 
"alert_creator.extra_info.alarms":"$t(alert_content.alarm_text.alarm_meter_since_integration)", 
"alert_creator.extra_info.averagerangeconsumption":"$t(alert_content.alarm_text.m3_cons_interval_of_cons_avg)", 
"alert_creator.extra_info.rangerealconsumption":"$t(alert_content.alarm_text.cons_limit_since_integration)", 
"alert_creator.extra_info.noreadformeteralert":"Dernière lecture du compteur antérieur à la date d'aujourd'hui moins l'écart du zoom", 
"alert_creator.extra_info.consumptionhour":"Des consommations ont été détectées au cours de l'horaire prédéfini", 
"alert_creator.unit.number":"Nombre d'alarmes", 
"alert_creator.unit.m3":"m3", 
"alert_creator.unit.percent":"Pourcentage", 
"alert_creator.unit.time":"Temps", 
"best_date_component.label.start_date":"$t(all.text.start_date)", 
"best_date_component.label.end_date":"$t(all.text.end_date)", 
"best_date_component.label.zoom":"$t(all.text.zoom)", 
"best_date_component.zoom_option.hour":"Heure", 
"best_date_component.zoom_option.day":"Jour", 
"best_date_component.zoom_option.week":"Semaine", 
"best_date_component.zoom_option.month":"Mois", 
"best_date_component.zoom_option.year":"Année", 
"best_date_component.text.custom":"Personnalisé", 
"best_date_component.error":"La date de début doit être inférieure à la date de fin", 
"bread_crumb.name.crystal_cloud":"CrystalCloud", 
"bread_crumb.name.location":"$t(all.location.location)", 
"bread_crumb.name.location_plural":"$t(all.location.location_plural)", 
"bread_crumb.name.masks":"$t(all.mask.mask_plural)", 
"bread_crumb.name.dashboard":"Tableau de bord", 
"bread_crumb.name.dashboard_plural":"$t(all.dashboard.dashboard_plural)", 
"bread_crumb.name.user":"$t(all.user.user)", 
"bread_crumb.name.user_plural":"$t(all.user.user_plural)", 
"bread_crumb.name.widget":"Widget", 
"bread_crumb.name.widget_plural":"$t(all.text.widgets)", 
"bread_crumb.name.meter":"$t(all.meter.meter)", 
"bread_crumb.name.meter_plural":"$t(all.meter.meter_plural)", 
"bread_crumb.name.virtual_meter":"$t(all.text.virtual_meter)", 
"bread_crumb.name.virtual_meter_plural":"$t(all.meter.virtual_meter_plural)", 
"bread_crumb.name.radio":"$t(all.radio.radio)", 
"bread_crumb.name.radio_plural":"$t(all.radio.radio_plural)", 
"bread_crumb.name.pdi":"$t(all.pdi.pdi)", 
"bread_crumb.name.pdi_plural":"PDIs", 
"bread_crumb.name.new":"$t(all.text.new)", 
"bread_crumb.name.search":"$t(all.button.search)", 
"bread_crumb.name.notif":"$t(all.text.notif)", 
"bread_crumb.name.notif_plural":"$t(all.text.notif_plural)", 
"bread_crumb.name.alert":"$t(all.alert.alert)", 
"bread_crumb.name.alert_plural":"$t(all.alert.alert_plural)", 
"bread_crumb.name.detail":"Détail", 
"bread_crumb.name.detail_plural":"$t(all.text.detail_plural)", 
"bread_crumb.name.report":"Rapport", 
"bread_crumb.name.report_plural":"Rapports", 
"bread_crumb.name.stock":"$t(all.text.stock)", 
"bread_crumb.name.create_pdi":"Création PDI", 
"bread_crumb.name.create_meter":"Création compteur", 
"bread_crumb.name.billing_import":"$t(all.text.import_billing)", 
"bread_crumb.name.link_pdi":"Association de PDI", 
"bread_crumb.name.unlink_pdi":"Dissociation de PDI", 
"bread_crumb.name.synchro":"$t(all.text.synchro)", 
"bread_crumb.name.round":"$t(all.round.round)", 
"bread_crumb.name.round_plural":"$t(all.round.round_plural)", 
"bread_crumb.name.record":"$t(all.text.record)", 
"bread_crumb.name.record_plural":"$t(all.text.record_plural)", 
"bread_crumb.name.phone":"$t(all.text.phone)", 
"bread_crumb.name.phone_plural":"$t(all.text.phone_plural)", 
"bread_crumb.name.import_mfr":"$t(all.text.import_mfr)", 
"bread_crumb.name.silex_import":"$t(all.text.silex_import)", 
"bread_crumb.name.msg_list":"Listes des messages", 
"bread_crumb.name.marking_list":"Listes des marquages", 
"bread_crumb.name.manager":"$t(all.text.manager)", 
"bread_crumb.name.manager_plural":"$t(all.text.manager_plural)", 
"bread_crumb.name.export":"$t(all.text.name.export)", 
"bread_crumb.name.stat":"Statistique", 
"bread_crumb.name.stat_plural":"$t(all.text.stat_plural)", 
"bread_crumb.name.forbidden_access":"$t(all.text.forbidden_access)", 
"bread_crumb.name.profile":"$t(all.user.profile)", 
"bread_crumb.name.profile_plural":"$t(all.user.profile_plural)", 
"bread_crumb.name.edit_x_virtual_meter":"Edition de {{virtualMeterName}}", 
"bread_crumb.name.edit_x_alarm":"Edition de {{alarmName}}", 
"bread_crumb.name.edit_x_mask_alarm":"Edition de {{maskAlarmName}}", 
"bread_crumb.name.edit_x_custom_export":"Edition de {{customExport}}", 
"bread_crumb.name.personal_dashboard_of_x":"Tableau de bord personnel de {{userName}}", 
"bread_crumb.name.telereleve":"$t(all.read_meter.type.T)", 
"bread_crumb.name.provisionning":"Provisionning", 
"bread_crumb.name.support":"Support", 
"bread_crumb.name.sync":"$t(all.text.synchro)", 
"bread_crumb.name.remotereading":"$t(all.read_meter.type.T)", 
"bread_crumb.name.synchrotools":"Synchro mode Tools", 
"bread_crumb.name.importbilling":"$t(all.text.import_billing)", 
"bread_crumb.name.export_brut":"Export brut", 
"bread_crumb.name.new_tournee":"$t(sidebar_synchro.nav_link.new_round)", 
"bread_crumb.name.create_tournee":"Création de tournée", 
"bread_crumb.name.templates":"Templates", 
"confirm.msg.you_sure":"Etes-vous sûr ?", 
"confirm.title.warning":"Attention!", 
"confirm.confirm_text.ok":"$t(all.text.ok)", 
"error_boundary.main_title.cannot_show_page":"La page n'a pas pu s'afficher correctement.", 
"error_boundary.data":"Un conflit entre les données a été détecté", 
"forbidden.main_title.not_authorized":"$t(all.text.forbidden_access)", 
"help_modal.button_send.send_msg":"Envoyer un message", 
"help_modal.main_title.send_msg_to_support":"Envoyer un message au support", 
"help_modal.success_msg.msg_sent_thanks":"Mail envoyé, merci de votre message", 
"help_modal.field_label.msg_subject":"Sujet du message", 
"help_modal.field_required.min_size_10_char":"La taille minimale du champ est de 10 caractères", 
"help_modal.field_subject_error.max_size_10_char":"Le sujet du message doit faire au moins 10 caractères", 
"help_modal.field_msg_error.max_size_10_char":"Merci de remplir la zone de message", 
"not_found.main_title.not_found":"404 Page introuvable", 
"notification_modal.tooltip_notif.notif":"$t(all.text.notif)", 
"notification_modal.tooltip_notif.notif_plural":"$t(all.text.notif_plural)", 
"notification_modal.tooltip_notif.x_notif_not_read":"Notification : {{count}} non lue", 
"notification_modal.tooltip_notif.x_notif_not_read_plural":"Notifications : {{count}} non lues", 
"search.main_title_label.fast_search":"Recherche rapide", 
"search.main_title.general":"Générale", 
"search.input_search.serial_or_address":"Numéro de série ou adresse", 
"search.info_tooltip.search_in_pdi_meter_radio":"Rechercher dans PDI, compteurs et radio (3 caractères minimum)", 
"search.search_result.result_of_search":"Résultats de la recherche", 
"search.search_result.x_pdi":"PDI : {{pdiResult}}", 
"search.search_result.x_meter":"Compteur : {{meterResult}}", 
"search.search_result.x_meter_plural":"Compteurs : {{meterResult}}", 
"search.search_result.x_radio":"Radio : {{radioResult}}", 
"search.search_result.x_radio_plural":"Radio : {{radioResult}}", 
"search.info_title.general_info":"Information générale", 
"search.info_title.general_info_plural":"Informations générales", 
"search.record_access_button.go_to_record":"$t(all.round.go_to_record)", 
"sidebar.nav_link.home":"Accueil", 
"sidebar.nav_link.location":"$t(all.location.location)", 
"sidebar.nav_link.location_plural":"$t(all.location.location_plural)", 
"sidebar.nav_link.round":"$t(all.round.round)", 
"sidebar.nav_link.round_plural":"$t(all.round.round_plural)", 
"sidebar.nav_link.profile_manager":"Gestion du profil", 
"sidebar.nav_link.profile_manager_plural":"Gestion des profils", 
"sidebar.nav_link.user_manager":"Gestion de l'utilisateur", 
"sidebar.nav_link.user_manager_plural":"Gestion des utilisateurs", 
"sidebar.nav_link.my_info_plural":"$t(all.user.my_info_plural)", 
"sidebar.nav_link.info_plural":"$t(all.text.information_plural)", 
"sidebar.nav_link.import_billing":"$t(all.text.import_billing)", 
"sidebar.nav_link.global_search":"Recherche globale", 
"sidebar.nav_link.lora_import":"$t(all.text.lora_import)", 
"sidebar.nav_link.installer_manager":"Gestion des installateurs", 
"sidebar_location.nav_link.round_detail_plural":"Détails de la tournée", 
"sidebar_location.nav_link.location_detail_plural":"Détails du site", 
"sidebar_location.nav_link.record_plural":"$t(all.text.record_plural)", 
"sidebar_location.nav_link.stat_plural":"$t(all.text.stat_plural)", 
"sidebar_location.nav_link.dashboard_plural":"$t(all.dashboard.dashboard_plural)", 
"sidebar_location.nav_link.alert_plural":"$t(all.alert.alert_plural)", 
"sidebar_location.nav_link.mask_alarm_plural":"Masques d'alarmes", 
"sidebar_location.nav_link.meter_plural":"$t(all.meter.meter_plural)", 
"sidebar_location.nav_link.virtual_meter_plural":"$t(all.meter.virtual_meter_plural)", 
"sidebar_location.nav_link.radio":"$t(all.radio.radio)", 
"sidebar_location.nav_link.pdi":"$t(all.pdi.pdi)", 
"sidebar_location.nav_link.search":"$t(all.button.search)", 
"sidebar_location.nav_link.export":"$t(all.text.name.export)", 
"sidebar_stock.nav_title.stock":"$t(all.text.stock)", 
"sidebar_stock.nav_link.resources_list":"$t(all.text.resource_list)", 
"sidebar_stock.nav_link.create_meter":"Création compteur", 
"sidebar_stock.nav_link.create_pdi":"Création PDI", 
"sidebar_synchro.nav_title.synchro":"$t(all.text.synchro)", 
"sidebar_synchro.nav_link.round":"$t(all.round.round)", 
"sidebar_synchro.nav_link.round_plural":"$t(all.round.round_plural)", 
"sidebar_synchro.nav_link.phone":"$t(all.text.phone)", 
"sidebar_synchro.nav_link.phone_plural":"$t(all.text.phone_plural)", 
"sidebar_synchro.nav_link.import_mfr":"$t(all.text.import_mfr)", 
"sidebar_synchro.nav_link.msg_list":"Liste des messages", 
"sidebar_synchro.nav_link.marking_list":"Liste des marquages", 
"sidebar_synchro.nav_link.silex_import":"$t(all.text.silex_import)", 
"sidebar_synchro.nav_link.enquiry":"Enquête", 
"sidebar_synchro.nav_link.enquiry_plural":"Enquêtes", 
"sidebar_synchro.nav_link.new_round":"Nouvelle tournée", 
"sidebar_tournees.nav_link.round_detail":"Détails de la tournée", 
"sidebar_tournees.nav_link.record_plural":"$t(all.text.record_plural)", 
"sidebar_tournees.nav_link.stat_plural":"$t(all.text.stat_plural)", 
"single_gmap.loading_band.loading_map":"Chargement de la carte", 
"single_gmap.failure_text.not_able_show_map":"Impossible d'afficher la carte", 
"single_gmap.map_setting.language":"fr", 
"single_gmap.text.no_radio":"$t(all.text.none_female)", 
"tableau_gestion.menu_option.add":"Ajouter", 
"tableau_gestion.menu_option.unlink":"$t(all.text.unlink)", 
"tableau_gestion.menu_option.link":"$t(all.text.link)", 
"tableau_gestion.menu_option.alarm_plural":"$t(all.alarm_meter.alarm_plural)", 
"tableau_gestion.loading_band.exporting":"Export en cours...", 
"tableau_gestion.error_band.exporting":"Impossible d'exporter les données", 
"tournee_navbar.nav_tooltip.synchro":"$t(all.text.synchro)", 
"tournee_navbar.nav_tooltip.stock":"$t(all.text.stock)", 
"tournee_navbar.nav_tooltip.dashboard_favorite_plural":"$t(all.dashboard.dashboard_favorite_plural)", 
"tournee_navbar.nav_tooltip.my_info_plural":"Mes infos", 
"tournee_navbar.nav_tooltip.disconnect":"$t(all.user.logout)", 
"edit_dashboard.main_title.edit_dashboard":"Editer un tableau de bord", 
"edit_dashboard.name_field_label.dashboard_name":"$t(all.dashboard.dashboard_name)", 
"edit_dashboard.name_field_error_msg.name_need_least_2_char":"$t(all.dashboard.name_need_least_2_char)", 
"locations.loading_band.loading_location_info":"Chargement des informations du site", 
"locations.button.access_round":"$t(all.round.go_to_round)", 
"locations.error_msg.no_location_found":"Aucune correspondance", 
"locations.placeholder.search_location":"Rechercher un site", 
"locations.placeholder.search_rounds":"Rechercher une tournée", 
"locations.title.all_rounds":"Toutes", 
"locations.text.total_nb_meter":"$t(all.meter.all_meter_nb)", 
"locations.text.total_nb_sensor":"Nombre total de capteurs", 
"locations.text.total_nb_radio":"$t(all.radio.all_radio_nb)", 
"locations.text.no_sub_location":"Aucun sous-site", 
"dashboard_helper.dashboard_name.my_dashboard":"Mon tableau de bord", 
"dashboard_helper.dashboard_name.dashboard_of_x":"Tableau de bord de {{userName}}", 
"fiche_detail.label.detail_plural":"$t(all.text.detail_plural)", 
"fiche_detail.label.diameter":"Diamètre", 
"fiche_detail.label.install_year":"Année de pose", 
"fiche_detail.label.wheel_nb":"$t(all.meter.wheels_number)", 
"fiche_detail.label.revision":"Révision", 
"fiche_detail.label.mfr":"$t(all.meter.mfr)", 
"fiche_detail.label.model":"$t(all.text.model)", 
"fiche_detail.label.msg":"$t(all.text.message)", 
"fiche_detail.label.particular_msg":"$t(all.text.particular_msg)", 
"fiche_detail.title.new_meter":"Nouveau compteur", 
"fiche_detail.title.old_meter":"Ancien compteur", 
"fiche_detail.title.old_meter_plural":"Anciens compteurs", 
"fiche_detail.label.show_old_meter":"Voir ancien compteur", 
"fiche_detail.label.show_new_meter":"Voir nouveau compteur", 
"fiche_detail.title.meter":"$t(all.meter.meter)", 
"fiche_detail.title.revise_meter":"Compteur révisé", 
"fiche_detail.title.install_read":"Index de pose", 
"fiche_detail.title.uninstall_read":"$t(columns.previousMeter.deposeIndex)", 
"fiche_detail.title.cons_m3":"$t(all.read_meter.cons_m3)", 
"fiche_detail.title.avg":"$t(all.text.average)", 
"fiche_detail.label.radio":"$t(all.radio.radio)", 
"fiche_detail.label.new_radio":"Nouvelle radio", 
"fiche_detail.label.old_radio":"Ancienne radio", 
"fiche_detail.label.show_old_radio":"Voir ancienne radio", 
"fiche_detail.label.show_new_radio":"Voir nouvelle radio", 
"fiche_detail.label.internal_id":"Identifiant interne", 
"fiche_detail.label.install_date":"$t(all.text.install_date)", 
"fiche_detail.label.pulse_weight":"$t(all.meter.pulse_weight)", 
"fiche_detail.error_msg.no_radio":"Pas de radio", 
"fiche_detail.title.historical_alarm_plural":"$t(all.alarm_meter.alarm_history_plural)", 
"fiche_detail.title.previous_alarm_plural":"Alarmes précédentes", 
"fiche_detail.title.current_alarm_plural":"Alarmes courantes", 
"fiche_detail.title.record_number":"Fiche numéro", 
"fiche_detail.label.marking":"$t(all.text.marquage)", 
"fiche_detail.label.last_update":"$t(all.text.update_date)", 
"fiche_detail.title.no_gps":"Pas de coordonnées GPS", 
"fiche_detail.title.historical_cons":"Historique des consommations (en m<1>3</1>)", 
"fiche_detail.title.start_index":"Index de début", 
"fiche_detail.title.closest_reference_index":"Index de référence", 
"fiche_detail.title.previous_index":"Index précédent", 
"fiche_detail.title.end_index":"Index de fin", 
"fiche_detail.title.current_index":"Index courant", 
"fiche_detail.text.no_alarm":"Aucune alarme", 
"fiche_detail.text.saisie_index":"Saisie d'index (en m3)", 
"fiche_detail.title.round":"Fiche tournée", 
"fiche_detail.conso.range_tooltip":"La moyenne n'est plus comprise dans l'intervalle de consommation", 
"fiche_detail.conso.null_range_tooltip":"Il devrait n'y avoir aucune consommation sur ce compteur", 
"index_wrapped.label.read":"Relève", 
"index_wrapped.read_method.radio":"$t(all.read_meter.type.A)", 
"index_wrapped.read_method.manual":"$t(all.read_meter.type.M)", 
"index_wrapped.label.date":"$t(all.date.date)", 
"index_wrapped.no_data.index_not_present":"Index non renseigné", 
"index_wrapped.popover.title":"Index courrant inférieur au précédent", 
"index_wrapped.popover.confirm_text":"L'index courant est inférieur à l'index précédent, le compteur a t-il fait un tour ?", 
"index_wrapped.popover.confirm_deletion_text":"Souhaitez-vous supprimer cette lecture de manière définitive ?", 
"index_wrapped.updated_by":"Mis à jour par : ", 
"static_list.label.not_indexed_manual_read":"Non indexé (relève manuelle)", 
"static_list.label.not_indexed_radio_read":"Non indexé (relève radio)", 
"static_list.label.not_indexed_telereleve_read":"Non indexé (télérelève)", 
"static_list.label.edited_manual_read":"Modifié (relève manuelle)", 
"static_list.label.edited_radio_read":"Modifié (relève radio)", 
"static_list.label.edited_with_telereleve":"Modifié (télérelève)", 
"static_list.label.indexed_no_radio":"Indéxé (relève manuelle) sans radio", 
"static_list.label.indexed_with_radio":"Indéxé (relève manuelle) avec radio", 
"static_list.label.indexed_with_telereleve":"Indéxé (relève manuelle) avec télérelève", 
"static_list.label.indexed_radio_read":"Indéxé (relève radio)", 
"static_list.label.indexed_telereleve_read":"Indéxé (télérelève)", 
"static_list.label.indexed_radio_read_with_alarm":"Indéxé avec alarme (relève radio)", 
"static_list.label.indexed_telereleve_read_with_alarm":"Indéxé avec alarme (télérelève)", 
"static_list.label.normal":"Normale", 
"static_list.value.normal_cons":"Conso normale", 
"static_list.label.weak":"Faible", 
"static_list.value.weak_cons":"Conso faible", 
"static_list.label.nul":"Nulle", 
"static_list.value.nul_cons":"Conso nulle", 
"static_list.label.strong":"Forte", 
"static_list.value.strong_cons":"Conso forte", 
"column_list.label.pdi_ref":"$t(all.pdi.pdi_ref)", 
"column_list.label.meter":"$t(all.meter.meter)", 
"column_list.label.id_meter":"id compteur", 
"column_list.label.serial_meter":"$t(all.meter.meter_serial)", 
"column_list.label.revised_serial_meter":"Numero de série revisité compteur", 
"column_list.label.c":"c", 
"column_list.label.city":"$t(all.address.city)", 
"column_list.label.country":"$t(all.address.country)", 
"column_list.label.index_meter":"$t(all.meter.meter_index)", 
"column_list.label.serial_radio":"$t(all.meter.radio_serial)", 
"column_list.label.alarm":"$t(all.alarm_meter.alarm)", 
"column_list.label.altitude":"Altitude", 
"column_list.label.network_code":"Code réseau", 
"column_list.label.round_code":"$t(all.round.round_code)", 
"column_list.label.radio_mfr":"$t(all.radio.radio_manufacturer)", 
"column_list.label.meter_mfr":"$t(all.meter.meter_manufacturer)", 
"column_list.label.last_read_date":"$t(all.read_meter.read_last_date)", 
"column_list.label.sealing_color":"Couleur du plombage", 
"column_list.label.meter_diameter":"$t(all.meter.real_meter_diameter)", 
"column_list.label.radio_id":"Identifiant de la radio", 
"column_list.label.pdi_id":"Identifiant du PDI", 
"column_list.label.meter_id":"Identifiant du compteur", 
"bookmark_location.error_band.impossible_get_favorite":"Impossible d'obtenir la liste des favoris", 
"bookmark.loading_band.loading_favorite_dashboard":"Chargement des tableaux de bord favoris", 
"display_bookmark.link_text.dashboard_nb_x":"Tableau de bord {{dashboardIndex}}", 
"column_selector.no_options_msg.no_more_column_available":"Plus de colonnes disponibles", 
"column_selector.no_options_msg.no_corresponding_column":"Pas de colonne correspondante", 
"column_selector.text.column_plural":"Colonnes", 
"column_selector.text.delete_saved_conf":"Supprimer la configuration sauvegardée", 
"column_selector.text.no_saved_col_conf":"Pas de configuration de colonne sauvegardée", 
"home_page.title.hello":"Bonjour", 
"home_page.subtitle.your_dashboard":"Vos tableaux de bord favoris", 
"list_render.list_type.pdi":"$t(all.pdi.pdi)", 
"list_render.list_type.radio_plural":"$t(all.radio.radio_plural)", 
"list_render.list_type.meter_plural":"$t(all.meter.meter_plural)", 
"list_render.list_type.virtual_meter_plural":"$t(all.meter.virtual_meter_plural)", 
"list_render.delete_modal_title.delete_x_virtual_meter":"Supprimer le compteur virtuel {{rowName}} ?", 
"list_tools.label.cons_state":"Etat de la consommation', //Not used (for no", 
"list_tools.text.cons":"Conso", 
"notifications.tooltip.delete_selected":"Supprimer la sélection", 
"notifications.loading_band.loading_notification_plural":"Chargement des notifications", 
"notifications.popup_delete_title.delete_selected_notification_plural":"Supprimer les notifications sélectionnées", 
"notifications.popup_delete_title.delete_notification":"Supprimer la notification", 
"notifications.popup_delete_msg.sure_delete_selected_notification_plural":"Cette action irreversible supprimera les notifications sélectionnées", 
"notifications.popup_delete_msg.sure_delete_notification":"Cette action irreversible supprimera cette notification", 
"historique_compteur.title.install_date":"Date de montage", 
"historique_compteur.title.uninstall_date":"Date de démontage", 
"historique_compteur.text.dashboard_1":"Tableau de bord 1", 
"historique_compteur.text.serial_meter":"Numéro de série du compteur", 
"historique_compteur.text.actual_meter":"Compteur actuel", 
"historique_compteur.text.go_to_meter_detail":"Aller à la fiche compteur", 
"porte_ouverte.label.duration_door_opening":"Durée ouverture porte (en minute)", 
"porte_ouverte.label.door_opening":"Ouvertures porte", 
"location_creator.title.add_location":"Ajouter un site", 
"location_creator.title.edit_location":"Editer un site", 
"identity_chooser.text.location_name":"Nom du site", 
"identity_chooser.text.new_location_name":"Nom du nouveau site", 
"identity_chooser.text.link_to_location":"Associer à un site", 
"identity_chooser.text.select_location_to_link":"Sélectionner à quel site associer votre nouveau site", 
"identity_chooser.input_option.no_location":"$t(all.text.none_male)", 
"identity_chooser.text.location_x_belong_to_first_level":"Le site{{locationName}} sera un site de premier niveau.", 
"identity_chooser.text.location_x_belong_to_location_x":"Le site{{locationName}} sera un sous-site de {{belongTo}}.", 
"identity_chooser.error_msg.max_length_name":"Le nom ne doit pas faire plus de 25 caractères", 
"identity_chooser.error_msg.max_length_name_required":"Le champ est requis et doit faire moins de 25 caractères", 
"identity_chooser.warning_msg.no_location_to_link":"Aucun site disponible à associer", 
"identity_chooser.button.create_location":"Créer site", 
"identity_chooser.button.edit_location":"Modifier site", 
"widget.type_source.meter":"$t(all.meter.meter)", 
"widget.type_source.radio":"$t(all.radio.radio)", 
"widget.display_type.curve":"Courbe", 
"widget.display_type.histogram":"Histogramme", 
"widget.display_value.meterreadingvalue":"Index compteur", 
"widget.display_value.meterreadingconsumption":"Consommation compteur", 
"widget.display_value.radioreadingvalue":"Index radio", 
"widget.display_value.meteralarm":"Alarme compteur", 
"widget.display_value.metermultireadingvalue":"Index multiple compteurs", 
"widget.display_value.metermultireadingconsumption":"Consommation multiple compteurs", 
"widget.display_value.meteryield":"Consommation rendement", 
"widget.condition_title.meterserialnumber":"$t(all.meter.meter_serial)", 
"widget.condition_title.radioserialnumber":"$t(all.meter.radio_serial)", 
"widget.condition_title.meterserialnumberref":"$t(all.meter.meter_serial_ref)", 
"widget.condition_title.meterserialnumber_":"$t(all.meter.meter_serial_control)", 
"widget.condition_title.readingdateinterval":"Intervalle de relève", 
"widget.condition_title.alarmtype":"$t(all.alarm_meter.alarm_type)", 
"widget.condition_title.meters":"$t(all.meter.meter_plural)", 
"widget.condition_title.rangeconsumption":"Intervalle de consommation", 
"widget.condition_title.gapconsumption":"Limite de consommation", 
"widget.condition_title.meterslistautocomplete":"Compteurs concernés", 
"widget.condition_title.period":"Période", 
"widget.condition_title.meterserialnumber_1":"$t(all.meter.meter_serial_control)", 
"widget.graph_type.index_meter_histogram":"Index compteur histogramme", 
"widget.graph_type.index_meter_curve":"Index compteur courbe", 
"widget.graph_type.consumption_meter_histogram":"Consommation compteur histogramme", 
"widget.graph_type.consumption_meter_curve":"Consommation compteur courbe", 
"widget.graph_type.alarm_meter":"$t(widget.display_value.meteralarm)", 
"widget.graph_type.multiple_meter_consumption_histogram":"Consommation multiple compteur histogramme", 
"widget.graph_type.multiple_meter_consumption_curve":"Consommation multiple compteur courbe", 
"widget.graph_type.multiple_meter_index_histogram":"Index multiple compteurs histogramme", 
"widget.graph_type.multiple_meter_index_curve":"Index multiple compteurs courbe", 
"widget.graph_type.index_radio_histogram":"Index radio histogramme", 
"widget.graph_type.index_radio_curve":"Index radio courbe", 
"widget.graph_type.meter_yield_curve":"Rendement compteurs courbe", 
"widget_last.text.no_alarm_type":"$t(all.text.none_male)", 
"widget_last.title.summary":"$t(all.text.summary)", 
"widget_last.text.widget_name":"Nom du widget", 
"widget_last.text.widget_type":"Type du widget", 
"widget_last.text.widget_display":"Affichage du widget", 
"widget.text.not_configured":"Non configuré", 
"location_creator.button.check_on_map":"Vérifier sur la carte", 
"location_creator.title.location_place":"Localisation du site", 
"location_creator.text.address_entry":"Adresse saisie", 
"location_creator.button.edit_address":"Modifier l'adresse", 
"location_creator.text.corrected_address":"Adresse corrigée", 
"location_creator.text.choose_address":"Sélectionner l'adresse corrigée ou celle saisie", 
"location_creator.title.location_contact":"Contact pour le site", 
"location_creator.title.location_note":"Note pour le site", 
"location_creator.placeholder.write_note":"$t(all.text.write_note)", 
"location_creator.text.no_location_selected":"Aucun site sélectionné", 
"display_dashboards.title.favorite_dashboard":"$t(all.dashboard.dashboard_favorite_plural)", 
"display_dashboards.title.dashboard_plural":"$t(all.dashboard.dashboard_plural)", 
"display_dashboards.filter_placeholder.filter_by_dashboard":"Filtrer par tableau de bord", 
"display_dashboards.button.delete_dashboard":"Supprimer le tableau de bord", 
"display_dashboards.button.rename_dashboard":"Renommer le tableau de bord", 
"display_dashboard.confirm_modal_title.delete_x_dashboard":"Supprimer le tableau de bord {{dashboardName}} ?", 
"display_dashboards.delete_modal.title":"Supression d'un tableau de bord", 
"display_dashboards.delete_modal. warning_confirm_dashboard":"Ce tableau de bord est le tableau de bord personnel de {{ownerFirstName}} {{ownerLastName}}", 
"display_dashboards.delete_modal.warning_confirm":"Voulez-vous vraiment supprimer ce tableau de bord ?", 
"display_dashboards.delete_modal.confirm":"Êtes-vous sûr de vouloir supprimer ce tableau de bord ?", 
"add_dashboard.title.add_dashboard":"Ajouter un tableau de bord", 
"add_dashboard.text.dashboard_name":"$t(all.dashboard.dashboard_name)", 
"add_dashboard.error_msg.dashboard_name_error":"$t(all.dashboard.name_need_least_2_char)", 
"dashboard.button.switch_display_mode":"Passer en mode présentation", 
"dashboard.button.exit_display_mode":"Quitter le mode présentation", 
"dashboard.button.delete_widget_from_dashboard":"Supprimer le widget du tableau de bord", 
"dashboard.button.delete_from_favorite":"Supprimer des favoris", 
"add_widget.button.add_widget":"Ajouter un widget", 
"add_widget.text.reading_value":"Valeur relevé", 
"add_widget.text.source":"Source", 
"add_group.title":"Ajouter un groupe", 
"widget_creator.title.configure_widget":"Configurer le widget", 
"widget_option.error_msg.widget_name_error":"Le nom du widget est obligatoire et doit avoir plus de 2 caractères", 
"range_configurator.title.gap_consumption_percent":"Limite de consommation (pourcentage)", 
"alarm_creator.title.create_alert":"Créer une alerte", 
"alarm_creator.text.add_alert":"Ajouter une alerte", 
"alarms.filter_placeholder.alert_filter":"Filtrer par alerte", 
"alarm_configurator.title.alert_edition":"Edition d'alerte", 
"alarm_configurator.placeholder.alert_name":"$t(all.alert.alert_name)", 
"alarm_options.text.calculation_method":"$t(all.text.calculation_method)", 
"alarm_options.text.alert_name_required_with_min_length":"Le nom de l'alerte est obligatoire et doit avoir plus de 2 caractères", 
"period_selector.text.trigger_period":"Période de déclenchement", 
"period_selector.text.schedule_interval":"Interval d'horaires", 
"mask_alarm.button.access_card":"Accéder à la fiche", 
"mask_alarm.text.delete_mask_action":"Cette action irreversible supprimera le masque du site", 
"mask_alarm.text.nb_days_with_alarm":"Nombre de jours avec alarme", 
"mask_alarm.text.no_meter_associated":"Aucun compteur associé à ce masque", 
"mask_alarm.text.alarm_of_meters":"Alarmes des compteurs", 
"mask_alarm.text.no_alarm_masked":"Aucune alarme masquée", 
"source_sheet.text.internal_number":"Numéro interne", 
"source_sheet.text.current_meter":"Compteur courant", 
"source_sheet.text.no_linked_meter":"Aucun compteur associé", 
"source_sheet.text.meter_linked_history":"Historique des compteurs associés", 
"source_sheet.text.no_old_meter_linked":"Aucun ancien compteur associé", 
"printable_source_sheet.text.x_data_from_x_to_x_zoom_x":"Données de {{name}} - du {{start}} au {{end}} - Zoom : {{zoom}}", 
"virtual_meter.text.last_best_read":"Dernière meilleure lecture", 
"virtual_meter.text.invalid":"Invalide", 
"virtual_meter.text.read_meter_plural":"Compteurs relevés", 
"virtual_meter.text.only_valid_read_plural":"Relèves valides uniquement", 
"virtual_meter.text.load_index":"Chargement des index", 
"virtual_meter.text.load_consumption":"Chargement des consommations", 
"virtual_meter.text.load_last_best_read":"Chargement de la dernière meilleure lecture", 
"virtual_meter.text.load_alarms":"Chargement des alarmes", 
"alarm_selector.text.type_to_display_plural":"Types à afficher", 
"saving_settings_modal.text.saving_conf":"Enregistrement de la configuration", 
"saving_settings_modal.text.backup_name":"Nom de la sauvegarde", 
"source_sheet_pdi.text.alarm_mask":"Masque d'alarme", 
"source_sheet_pdi.text.display_interval":"Intervalle d'affichage", 
"source_sheet_pdi.text.meter_loading":"Chargement des compteurs", 
"source_sheet_pdi.text.terrain":"Terrain", 
"source_sheet_pdi.text.many_data_loading":"Beaucoup de données à récuperer. Veuillez patienter ...", 
"source_sheet_pdi.text.invalid_interval":"Intervalle invalide", 
"source_sheet_pdi.text.averages_unavailable":"Moyenne indisponible", 
"source_sheet_pdi.text.no_data_display":"Aucune donnée disponible", 
"source_sheet_pdi.text.display_type_read":"Affichage par type de lecture", 
"source_sheet_pdi.text-unique_read":"L'intervalle sélectionné ne contient qu'une seule lecture.", 
"export_pdi.text.export_preview":"Prévisualisation (aperçu des 5 premiers)", 
"export_pdi.text.refresh_preview":"Actualiser la prévisualisation", 
"export_pdi.text.cvs_export_progress":"Export CSV en cours ...", 
"export_pdi.text.cvs_export_done":"Export CSV effectué", 
"export_pdi.text.separator":"Séparateur", 
"export_pdi.text.comma":"virgule", 
"export_pdi.text.semicolon":"point-virgule", 
"export_pdi.text.vertical_bar":"barre verticale", 
"users.text.user_management":"Gestion utilisateurs", 
"users.text.search_user":"Rechercher un utilisateur", 
"users.text.add_user":"Ajouter un utilisateur", 
"users.text.add_tourmaline_user":"Ajouter un utilisateur Tourmaline", 
"add_user.button.add_new_user":"Ajouter un nouvel utilisateur", 
"add_user.field.ucid_code_error":"Le code UCID est obligatoire et doit avoir plus de 16 caractères", 
"add_user.field.email_unique":"Adresse email, unique", 
"add_user.field.email_error":"Adresse invalide", 
"add_user.field.name_error":"Le nom est obligatoire et doit avoir plus de 2 caractères", 
"add_user.field.field_only_alphabetical":"Le champ est uniquement alphabétique", 
"add_user.field.minimum_x_size":"La taille minimale du champ est de {{min}} caractères", 
"add_user.field.maximum_x_size":"La taille maximale du champ est de {{max}} caractères", 
"user.text.remove_profile":"Retirer le profil", 
"user.text.select_location":"Sélectionner un site", 
"user.text.select_profile":"Sélectionner un profil", 
"user.text.remove_permission_to_user":"Vous allez retirer la permission à cet utilisateur", 
"user.text.last_connection":"Dernière connexion", 
"user.text.generate_pwd":"Générer un mot de passe", 
"user.text.reset_pwd":"Réinitialiser le mot de passe", 
"user.text.admin_all_gestionnaires":"Vous êtes administrateur sur l'ensemble des gestionnaires", 
"user.text.admin_all_locations":"Vous êtes administrateur sur l'ensemble des sites", 
"user.text.user_is_admin":"Cet utilisateur dispose d'un profil administrateur", 
"user.text.select_lang":"Sélectionner une langue", 
"user.text.lang_choosen":"Langue choisie", 
"user.text.select_role":"Sélectionner un rôle", 
"user.text.please_fill_password":"Merci d'indiquer un mot de passe, de minimum 8 caractères, devant contenir au moins une majuscule et un chiffre", 
"user.text.new_password":"Nouveau mot de passe", 
"user.text.rewrite_new_password":"Retaper le mot de passe", 
"user.text.password_not_match":"Les mots de passes ne correspondent pas", 
"user.text.delete":"Attention la suppression est définitive, l'utilisateur et son tableau de bord personnel seront supprimés, les tableaux de bord créés par l'utilisateur vous seront réafectés. les données de connexions seront supprimées", 
"user.text.unlock":"Cette action débloquera l'utilisateur, il pourra à nouveau se connecter", 
"user.text.lock":"Cette action bloquera l'utilisateur, il ne pourra plus se connecter", 
"user.text.sure":"Etes-vous sur ?", 
"user.text.nb.phone":"Nombre de téléphones", 
"user.text.nb.deliveries":"Nombre de tournées", 
"user.text.authorization":"Autorisation", 
"user.text.language":"Langue", 
"user.text.french":"Francais", 
"user.text.english":"Anglais", 
"user.text.spanish":"Espagnol", 
"user.button.save.key":"Sauvegarder cette section", 
"location_modal.title_ask.delete_x_location":"Supprimer le site {{locationName}} ?", 
"location_modal.text.delete_location_effect":"Cette action irreversible supprimera toutes les données de ce site.", 
"columns.text.date_added":"Date d'ajout (défaut)", 
"columns.text.abc_order":"A -> Z", 
"columns.text.abc_disorder":"Z -> A", 
"columns.text.max_to_min":"+ -> -", 
"columns.text.min_to_max":"- -> +", 
"columns.text.code_abc_order":"$t(all.text.code) $t(columns.text.abc_order)", 
"columns.text.code_abc_disorder":"$t(all.text.code) $t(columns.text.abc_disorder)", 
"columns.text.wording_abc_order":"Libellé $t(columns.text.abc_order)", 
"columns.text.wording_abc_disorder":"Libellé $t(columns.text.abc_disorder)", 
"columns.text.actions_max_to_min":"Actions $t(columns.text.max_to_min)", 
"columns.text.actions_min_to_max":"Actions $t(columns.text.min_to_max)", 
"columns.text.loaded_max_to_min":"Chargés $t(columns.text.max_to_min)", 
"columns.text.loaded_min_to_max":"Chargés $t(columns.text.min_to_max)", 
"avancement_chart.text.indexed_x_on_x":"$t(all.text.indexed) : {{count}} / {{totIndexed}}", 
"avancement_chart.text.indexed_x_on_x_plural":"$t(all.text.indexed_plural) : {{count}} / {{totIndexed}}", 
"avancement_chart.text.edited_x_on_x":"$t(all.text.modified) : {{count}} / {{totEdited}}", 
"avancement_chart.text.edited_x_on_x_plural":"$t(all.text.modified_plural) : {{count}} / {{totEdited}}", 
"device_info.text.waiting_action":"$t(all.text.waiting_action)", 
"device_info.text.waiting_action_plural":"$t(all.text.waiting_action_plural)", 
"device_info.text.x_loaded_round":"{{count}} tournée chargée", 
"device_info.text.x_loaded_round_plural":"{{count}} tournées chargées", 
"device_info.text.first_com":"Première communication", 
"device_info.error_text.load":"Impossible de charger les données", 
"device_info.error_text.integrate":"Impossible d'intégrer les données", 
"device_info.error_text.contact":"Veuillez contacter le support", 
"device_info_error_load_round":"Impossible de charger la/les tournée(s) suivante(s):", 
"round_info.text.indexed":"Indexé(s)", 
"round_info.text.remaining":"Restant(s)", 
"round_info.text.edited":"Modifié(s)", 
"round_info.text.indexed_x_percent_plural":"$t(all.text.indexed_plural) ({{percent}}%)", 
"round_info.text.edited_x_percent_plural":"$t(all.text.modified_plural) ({{percent}}%)", 
"round_info.text.loaded_on_x_phone":"Chargée sur {{count}} portable", 
"round_info.text.loaded_on_x_phone_plural":"Chargée sur {{count}} portables", 
"task_duplication.text.code_x":"code : {{code}}", 
"alarm_updater.text.alert_edition":"Edition de l'alerte", 
"conso_interval.text.threshold_required":"Vous devez renseigner au moins un seuil", 
"conso_interval.text.threshold_x":"Seuil ({{unit}})", 
"last.text.recipient_user_plural":"Utilisateurs destinataires", 
"manage_alarm.text.x_linked_pdi_to_location":"{{count}} PDI asssocié au site.", 
"manage_alarm.text.x_linked_pdi_to_location_plural":"{{count}} PDIs asssociés au site.", 
"manage_alarm.text.x_dissociate_pdi_to_location":"{{count}} PDI dissocié du site.", 
"manage_alarm.text.x_dissociate_pdi_to_location_plural":"{{count}} PDIs dissociés du site.", 
"cluster_marker_gm.title.meter_detail_plural":"Détail des compteurs", 
"cluster_meter_list.text.close_meter_list":"Fermer la liste <br /> de compteur", 
"google_map_react_c.text.center_selected_meter":"Centrer sur le <br /> compteur sélectionné", 
"contact_chooser.text.lastname_only_letters":"Nom de famille ne peut contenir que des lettres", 
"contact_chooser.text.firstname_only_letters":"Le prénom ne doit contenir que des lettres", 
"contact_chooser.text.phone_number_needs_10_number":"Le numéro de téléphone doit contenir 10 chiffres", 
"contact_chooser.text.give_name_info":"Renseigner un nom", 
"contact_chooser.text.give_email_info":"Renseigner un email", 
"loraimport.text.nb_link_in_file":"Nombre de liens dans le fichier", 
"loraimport.text.nb_new_link_in_file":"Nombre de nouveaux liens dans le fichier", 
"loraimport.text.import_lorafile_processing":"Import du fichier LoRa en cours", 
"loraimport.text.import_file_failed":"Chargement du fichier LoRa {{filename}} échoué", 
"loraimport.text.import_lora":"Import LoRa", 
"loraimport.text.lorafile_process_success":"Chargement du fichier LoRa effectué", 
"import_billing.label.header_size":"Taille header", 
"import_billing.label.first_cell_row":"Ligne cellule d'identification", 
"import_billing.label.first_cell_col":"Colonne cellule d'identification", 
"import_billing.label.column_size":"Nombre de colonnes", 
"import_billing.label.end_of_first_cell":"Fin première cellule", 
"import_billing.label.first_line_number":"Index première ligne", 
"import_billing.label.id_cell_row":"N° de ligne", 
"import_billing.label.id_cell_col":"N° de colonne", 
"import_billing.label.id_cell_content":"Contenu", 
"import_billing.label.id_cell":"Cellule d'identification", 
"import_billing.label.sub":"Abonnement", 
"import_billing.label.pdi":"$t(all.pdi.pdi)", 
"import_billing.label.round":"$t(all.round.round)", 
"import_billing.label.cpt":"$t(all.meter.meter)", 
"import_billing.label.else":"$t(all.text.other)", 
"import_billing.label.pdi_ref":"$t(all.text.reference)", 
"import_billing.label.pdi_state":"$t(all.text.state)", 
"import_billing.label.pdi_address_number":"Numéro d'adresse", 
"import_billing.label.pdi_number_additionnal":"$t(all.address.street_number_additionnal)", 
"import_billing.label.pdi_street":"Rue", 
"import_billing.label.pdi_additionnal_street":"Complément rue", 
"import_billing.label.pdi_complement_address":"$t(all.address.complement)", 
"import_billing.label.pdi_appart":"Appartement", 
"import_billing.label.pdi_floor":"Etage", 
"import_billing.label.pdi_building":"Bâtiment", 
"import_billing.label.pdi_stair":"Escalier", 
"import_billing.label.pdi_zipcode":"$t(all.address.zipcode)", 
"import_billing.label.pdi_city":"$t(all.address.city)", 
"import_billing.label.pdi_note_plural":"Notes", 
"import_billing.label.round_code":"$t(all.round.round_code)", 
"import_billing.label.round_name":"$t(all.round.round_name)", 
"import_billing.label.cpt_number":"$t(all.meter.serial)", 
"import_billing.label.cpt_wheels":"Nb roues compteur", 
"import_billing.label.cpt_install_date":"$t(all.text.install_date)", 
"import_billing.label.cpt_oldnumber":"Ancien numéro de série compteur", 
"import_billing.label.date_depose":"Date de dépose", 
"import_billing.label.index_depose":"$t(columns.previousMeter.deposeIndex)", 
"import_billing.label.index_pose":"$t(fiche_detail.title.install_read)", 
"import_billing.label.cpt_color_filling":"Couleur plombage", 
"import_billing.label.branchement_code_reseau":"Code branchement réseau", 
"import_billing.label.date_start_subscription":"Date début abonnement", 
"import_billing.label.date_end_subscription":"Date fin abonnement", 
"import_billing.label.state_subscription":"Etat abonnement", 
"import_billing.label.gps":"GPS", 
"import_billing.label.gps_latitude":"$t(all.text.latitude)", 
"import_billing.label.gps_longitude":"$t(all.text.longitude)", 
"import_billing.label.gps_altitude":"$t(column_list.label.altitude)", 
"import_billing.label.gps_score":"Score", 
"import_billing.label.gps_source":"$t(add_widget.text.source)", 
"import_billing.placeholder.value_select":"Veuillez selectionner un nom de colonne", 
"import_billing.state.removed.match":"Archiver PDI avec un des etats", 
"import_billing.state.inactif.match":"Etat du PDI à inactif SI", 
"pdi.PDI_GPS_LONGITUDE":"$t(all.text.longitude)", 
"pdi.PDI_GPS_LATITUDE":"$t(all.text.latitude)", 
"pdi.PDI_GPS_SCORE":"$t(import_billing.label.gps_score)", 
"pdi.PDI_GPS_SOURCE":"$t(add_widget.text.source)", 
"pdi.PDI_GPS_ALTITUDE":"$t(column_list.label.altitude)", 
"pdi.PDI_GPS_ACCURACY":"Précision", 
"pdi.PDI_REF_ID":"Référence ID", 
"pdi.PDI_PARENT_REFERENCE":"Référence du PDI parent", 
"pdi.PDI_PARENT_RELATION":"Relation avec le PDI parent", 
"export_round_factu":"Export facturation", 
"export_round_teleo":"Export Téléo", 
"nbColumns":"$t(import_billing.label.column_size)", 
"general.label.round_code":"Code par défault export DAT", 
"general.label.round_default_wheels":"Roues par défaut export DAT", 
"general.label.round_libelle":"Libellé par défault export DAT", 
"general.label.default_language":"Langue par défaut", 
"general.label.end_date_demo":"Fin date démo", 
"general.label.in_demo_mode":"En mode démo", 
"general.label.saphir_version_current":"Version courante de Saphir", 
"general.label.skip_trigger_alarm":"Ignorer date de déclenchement alarme", 
"general.label.fetch_size_query":"Taille maximal du BATCH", 
"general.label.supported_language":"Langues supportées (séparé par virgule)", 
"general.label.timezone_id":"Timezone ID", 
"general.label.archive.inactif.pdi":"Archiver PDI inconnu fichier facturation", 
"general.label.apply.if_in_round":"Appliquer changement de compteur sur compteur en cours de relève", 
"general.label.telereleve.patrimoine.last_update":"$t(all.text.update_date) patrimoine télérelève", 
"general.ignore.t2t4.mrlId":"Ignorer les lignes T2 et T4", 
"general.seuil.limit":"Seuil limite", 
"general.only.current_read_table.pdi":"Utiliser uniquement les dernières lectures", 
"synchronisation.label.export_silex_encoding":"Encodage fichier Import/Export", 
"synchronisation.label.export_silex_encoding_detection_method":"Méthode de détection encodage", 
"synchronisation.label.export_silex_name":"Nom fichier RTR", 
"synchronisation.label.telereleve_sup_startdate":"Import télérelève > date début relève", 
"synchronisation.label.refresh_synchro_devices":"Intervalle rafraîchissement portable", 
"synchronisation.label.remove_missing_t3":"Enlever les T3 manquants", 
"synchronisation.label.silex_export_geoloc":"Export RTR avec géolocalisation (T7)", 
"synchronisation.label.silex_export_radio":"Export RTR avec radio (T2/T4)", 
"synchronisation.label.silex_export_enquete":"Export enquête T5/T6", 
"synchronisation.label.silex_import_allow_doublons":"Autoriser doublons Silex", 
"synchronisation.label.synchro_allow_creation_radiomodel":"Autoriser création Modèle radio", 
"synchronisation.label.stop_and_go":"STOP & GO", 
"synchronisation.label.import_all":"Test synchro", 
"synchronisation.label.import_t0":"Test T0", 
"synchronisation.label.import_t1":"Test T1", 
"synchronisation.label.import_t2":"Test T2", 
"synchronisation.label.import_t3":"Test T3", 
"synchronisation.label.import_t4":"Test T4", 
"synchronisation.label.import_t6":"Test T6", 
"synchronisation.label.import_t7":"Test T7", 
"synchronisation.label.saphir_version_current":"$t(general.label.saphir_version_current)", 
"synchronisation.ignore_invalid_histo_infuture":"Ignorer l'invalidation d'historique dans le future", 
"synchronisation.ignore_previous_index_value":"Ignorer la comparaison entre l'index précédent et la valeur maximum", 
"synchronisation.option.rotate.round":"Option rotation de tournée", 
"synchronisation.device":"$t(all.text.phone_plural)", 
"synchronisation.round":"$t(all.round.round_plural)", 
"remote_reading.label.default_format_lora_file":"Format fichier LoRa", 
"remote_reading.label.default_format_lora_file_column_number":"Nombre de colonne fichier LoRa", 
"remote_reading.label.has_remote_reading":"Option télérelève", 
"remote_reading.label.app_eui_column_index":"Index AppEUI", 
"remote_reading.label.app_key_column_index":"Index Appkey", 
"remote_reading.label.dev_eui_column_index":"Index DevEUI", 
"remote_reading.label.lora_serial_column_index":"Numéro de série LoRa", 
"remote_reading.label.radio_serial_column_index":"$t(all.meter.radio_serial)", 
"remote_reading.nbColumns":"$t(import_billing.label.column_size)", 
"report.label.logo_path":"Chemin du logo", 
"report.label.specific_template_directory":"Dossier contenant les templates personnalisés", 
"report.label.custom_template_names":"Liste des templates personnalisés", 
"support.title.report":"$t(bread_crumb.name.report)", 
"meter.unit.W":"m<sup>3</sup>", 
"meter.unit.E":"kWh", 
"meter.unit.":"$t(meter.unit.W)", 
"meter.unit.C":"$t(meter.unit.W)", 
"meter.unit.H":"$t(meter.unit.W)", 
"meter.unit.G":"$t(meter.unit.W)", 
"meter.unit.T":"$t(meter.unit.E)", 
"meter.unit.O":"$t(meter.unit.W)", 
"meter.type.W":"Eau", 
"meter.type.E":"Electrique", 
"meter.type.":"$t(meter.type.W)", 
"meter.type.C":"Eau froide", 
"meter.type.H":"Eau chaude", 
"meter.type.G":"Gaz", 
"meter.type.T":"Thermique", 
"meter.type.O":"$t(all.text.other)", 
"last_reading.text.tooltip_real_previous_index":"L'index courant et l'index précédent datant de la même journée. Un index pertinent le plus récent a été choisi automatiquement. Vous pouvez cliquer ci-dessous pour revenir à l'index de référence.", 
"last_reading.text.tooltip_reference_index":"L'index courant et l'index précédent datant de la même journée. Un index pertinent le plus récent a été choisi automatiquement. Vous pouvez cliquer ci-dessous pour afficher l'index précédent.", 
"last_reading.text.no_last_read":"Aucune lecture courante", 
"last_reading.text.no_other_read":"Aucune autre lecture", 
"last_reading.text.no_previous_read":"Aucune lecture précédente", 
"last_reading.text.index_meter":"$t(widget.display_value.meterreadingvalue)", 
"tournee_band.text.list_devices":"Liste des portables", 
"releve_meter_status.text.red_manual":"$t(static_list.label.not_indexed_manual_read)", 
"releve_meter_status.text.red_radio":"$t(static_list.label.not_indexed_radio_read)", 
"releve_meter_status.text.yellow_manual":"$t(static_list.label.edited_manual_read)", 
"releve_meter_status.text.yellow_radio":"$t(static_list.label.edited_radio_read)", 
"releve_meter_status.text.green_manual":"$t(static_list.label.indexed_no_radio)", 
"releve_meter_status.text.green_radio":"$t(static_list.label.indexed_with_radio)", 
"releve_meter_status.text.blue_radio":"$t(static_list.label.indexed_radio_read)", 
"releve_meter_status.text.blue_telereleve":"$t(static_list.label.indexed_telereleve_read)", 
"releve_meter_status.text.purple_radio":"$t(static_list.label.indexed_radio_read_with_alarm)", 
"releve_meter_status.text.purple_telereleve":"$t(static_list.label.indexed_telereleve_read_with_alarm)", 
"sensor.text.sensor":"Capteur", 
"sensor.text.sensor_plural":"Capteurs", 
"sensor.plannification":"Planification ouverture", 
"sensor.title.manual_management":"Gestion manuelle", 
"sensor.title.synchro":"Synchronisation horloge", 
"sensor.title.frequency":"Fréquence trame", 
"sensor.error.wrong_plage":"Plage horaire incorrect", 
"sensor.error.previous_date_sup":"Le départ de cette plage horaire ne peut être inférieure à la plage précédente", 
"sensor.error.fix_previous_plage":"Veuillez corriger la plage horaire précédente", 
"sensor.text.minutes":"minutes", 
"sensor.text.hour":"heures", 
"sensor.error.synchro":"Erreur synchronisation", 
"sensor.title.vanne_status":"Etat de la vanne", 
"sensor.title.open_planned":"Planification d'ouverture", 
"sensor.text.remove_interval":"Supprimer un intervalle", 
"sensor.text.add_interval":"Ajouter un intervalle", 
"sensor.text.when_vanne_open":"Lorsque la vanne est ouverte", 
"sensor.text.when_vanne_close":"Lorsque la vanne est fermée", 
"sensor.type.STR-EV":"Electrovanne STREGA", 
"sensor.type.ADE-COM":"Adeunis Comfort2", 
"sensor.type.ADE-CO2":"Adeunis Comfort CO2", 
"sensor.type.ADE-DRYC":"Adeunis Dry Contact", 
"sensor.type.ADE-DTP":"Adeunis DeltaP", 
"sensor.type.ADE-TEMP":"Adeunis Temp", 
"sensor.text.sensor_type":"Type capteur", 
"sensor.text.serial":"Numéro de série du capteur", 
"sensor.dashboard":"Tableau de bord - Capteurs", 
"sensor.data.temperature":"$t(all.text.temperature)", 
"sensor.data.temperature2":"Température 2", 
"sensor.data.humidity":"$t(all.text.humidity)", 
"sensor.data.co2":"$t(all.text.co2)", 
"sensor.data.pressure":"$t(all.text.pressure)", 
"sensor.data.valveOpen":"Vanne Ouverte", 
"support.title.importbilling":"$t(all.text.import_billing)", 
"support.title.remotereading":"$t(all.read_meter.type.T)", 
"support.title.sync":"$t(all.text.synchro)", 
"support.title.general":"$t(all.text.general_male)", 
"support.title.column":"$t(column_selector.text.column_plural)", 
"support.title.script":"Script", 
"support.title.import":"Import", 
"support.option.duplicateAllowed":"Doublons autorisés", 
"support.subtitle.autochange":"Configuration des changements automatiques", 
"support.subtitle.billing_import":"Configuration de l'import", 
"support.autochange.truncate":"Tronquer le numéro de série", 
"support.autochange.truncate_at":"Tronquer à ", 
"support.autochange.left":"gauche", 
"support.autochange.right":"droite", 
"support.autochange.replace_date":"Correction des dates trop anciennes", 
"support.title.export":"Export", 
"replace_silex_lines":"Remplacement ligne silex", 
"saphir_version_current":"Version de saphir", 
"stop_and_go":"Redémarage automatique", 
"refresh_synchro_devices":"Actualiser les appareils synchronisés", 
"silex.export.alarm.past":"Exporter les alarmes passées", 
"silex.export.change_readmethod_T_to_A":"Modification RMT T -> A", 
"export_round_csv_custom":"Export CSV tournée personnalisé", 
"export_round_csv_custom_format_dir":"Export CSV tournée personnalisé format DIR", 
"import_round_csv_enabled":"Import CSV activé", 
"import_round_csv_custom":"Import CSV tournée personnalisé", 
"import_round_facturation_enabled":"Activation import facturation ", 
"export_round_csv_encoding":"Export encodage CSV tournée", 
"support.option.comma":"Virgule", 
"support.option.semicolon":"Point-virgule", 
"support.option.tabulation":"Tabulation", 
"script.export.format":"Formats d'export", 
"synchronisation.label.script.export.encoding_source":"Encodage source de l'export", 
"synchronisation.label.script.export.encoding_output":"Encodage de sortie de l'export", 
"synchronisation.label.script.export.delimiter":"Délimiteur de l'export", 
"synchronisation.label.script.import.format":"Format de l'import", 
"synchronisation.label.script.import.encoding_source":"Encodage source de l'import", 
"synchronisation.label.script.import.encoding_output":"Encodage de sortie de l'import", 
"synchronisation.label.script.import.delimiter":"Délimiteur de l'import", 
"synchrotools.option.download_round":"Charger une tournée", 
"synchrotools.option.remove_round":"Supprimer une tournée", 
"synchrotools.option.upload_round":"Décharger une tournée", 
"synchrotools.option.rounds":"$t(all.round.round_plural)", 
"synchrotools.button.connect":"Connexion", 
"synchrotools.button.disconnect":"Déconnexion", 
"synchrotools.option.command":"Commandes", 
"synchrotools.button.launchCommand":"Exécuter la commande", 
"remotereading.text.read_with_no_alarm":"Relevé sans alarme", 
"remotereading.text.read_with_alarm":"Relevé avec alarme", 
"remotereading.text.read_sort_by_model":"Relevés par modèles", 
"remotereading.text.meter_alarm":"Compteurs en alarmes", 
"remotereading.text.without_alarm":"Sans alarme", 
"remotereading.text.meter_alarm_by_model":"Compteurs en alarmes par modèles", 
"remotereading.text.alarm_type":"Type d'alarme", 
"remotereading.text.synthese":"Synthèse", 
"remotereading.text.in_alarm":"en alarmes", 
"remotereading.text.read":"Relevé", 
"remotereading.text.not_read":"Non relevé", 
"remotereading.text.no_meter_in_interval ":"Aucun compteur avec cette option n'existe dans l'intervalle choisie", 
"remotereading.text.link_meter_now":"Associer des compteurs maintenant ?", 
"remotereading.text.no_meter_link_to":"Aucun compteur associé à", 
"remotereading.text.select_default":"Sélection par défaut", 
"remotereading.text.map_view":"Vue carte", 
"remotereading.text.list_view":"Vue liste", 
"remotereading.text.for_current_round":"Pour la relève courante", 
"remotereading.text.for_selected_period":"Pour la période sélectionnée", 
"remotereading.text.by_read_mode":"Par mode", 
"remotereading.text.by_manufacturer":"Par marque", 
"remotereading.text.by_status":"Par statut", 
"remotereading.text.multi_choices_allow":"choix multiple possible", 
"remotereading.text.read_method":"Mode de lecture", 
"remotereading.text.select_at_least":"Veuillez sélectionner au moins", 
"remotereading.text.one_manufacturer":"une marque", 
"remotereading.text.one_status":"un statut", 
"remotereading.text.one_read_method":"une méthode de relève", 
"remotereading.text.manufacturer":"Marque", 
"remotereading.text.alarm_detail":"Détail des alarmes", 
"remotereading.text.match_selected_meter":"correspondant à la selection sur un total de {{nbMeters}}", 
"remotereading.text.meter_geolocated":"dont {{meterGeolocated}} avec géolocalisation", 
"remotereading.text.no_meter_to_display":"Aucun compteur à afficher", 
"enquete.text.add_enq_page":"Ajout page d'enquête", 
"enquete.text.link_enq_round":"Associer aux tournées", 
"enquete.text.name_enquete_page":"Nom de la page d'enquête", 
"enquete.text.field_list":"Liste des champs", 
"enquete.text.field_type":"Type du champ", 
"enquete.field_type.A":"Texte", 
"enquete.field_type.N":"Numérique", 
"enquete.field_type.C":"Choix", 
"enquete.text.delete_field":"Supprimer le champ", 
"enquete.text.delete_field_impossible":"Impossible de supprimer le champ", 
"enquete.text.add_field":"Ajouter champ d'enquête", 
"enquete.text.field":"$t(all.text.field)", 
"enquete.text.field_libelle":"Libellé du champ", 
"enquete.text.field_size":"Taille du champ", 
"enquete.text.field_default_value":"Valeur par défaut du champ", 
"enquete.text.no_field_exist":"Aucun choix défini", 
"enquete.text.no_field_selected":"Aucun choix sélectionné pour ce champ", 
"enquete.text.choices_list":"Liste des choix", 
"enquete.text.associated_page":"Page d'enquêtes associées", 
"enquetes.text.edit_enquete":"Editer la page d'enquête", 
"enquete.text.edit_impossible_in_used":"Edition de l'enquête impossible : utilisé par une tournée", 
"enquete.text.enquete_deleted_links_warning":"Vous allez dissocier des enquêtes. Êtes-vous sûr de vouloir continuer ? Cette action est irréversible.", 
"enquete.text.deletion_enquetes":"Suppression d'enquêtes", 
"enquete.text.placeholder_create_options":"Choix 1, Choix 2, Choix 3 ...", 
"enquete.text.remove_impossible_in_used":"Suppression de l'enquête impossible : utilisé par une tournée", 
"enquetes.text.remove_enquete":"Supprimer la page d'enquête", 
"enquetes.popover.title":"Suppression d'une page d'enquête", 
"enquete.popover.confirm_text":"Êtes-vous sûr de vouloir supprimer cette enquête ?", 
"enquete.error.choice_max_length":"Il ne peut pas y avoir plus de 8 valeurs sur ce champ", 
"enquete.text.round_loaded_devices_no_update_allow":"La tournée est chargée sur des portables. Aucune modification possible.", 
"enquete.text.select_enquete":"Sélectionner des pages d'enquêtes ...", 
"enquete.text.each_option_max_20_char":"Chaque choix ne peut faire que 20 caractères maximum", 
"enquete.text.libelle_max_16_char":"Le libellé doit faire maximum 16 caractères", 
"enquete.text.size_max_30":"La taille doit être inférieur à 30 et ne doit pas contenir de décimales", 
"enquete.text.default_value_max_size":"La valeur par défaut doit avoir moins de caractères que la taille renseignée, ici", 
"lostpass.text.password_identifiant_lost":"Mot de passe/identifiant oublié(s) ?", 
"lostpass.text.get_identifants":"Récupération des identifiants", 
"lostpass.text.enter_email":"Indiquer votre email afin de recevoir les informations de connexions", 
"lostpass.text.your_email":"Votre email", 
"lostpass.text.enter_valid_email":"Votre email doit être valide", 
"lostpass.text.check_email":"Surveillez vos emails. les informations ont été envoyées. Si vous n'avez pas reçu d'email sous 24h , merci de contacter votre administrateur.", 
"alarm.type.fraud":"Fraude", 
"alarm.type.leak":"Fuite", 
"alarm.type.backflow":"Retour d'eau", 
"alarm.type.meterblock":"Compteur bloqué", 
"alarm.type.clock":"Horloge", 
"alarm.type.moduledefect":"Dysfonctionnement du module", 
"alarm.type.moduleremoved":"Module démonté", 
"alarm.type.configuration":"Configuration incohérente", 
"alarm.type.reversedmeter":"Compteur inversé", 
"alarm.type.overflow":"Sur-débit", 
"alarm.type.underflow":"Sous-débit", 
"alarm.type.brokenpipe":"Conduite cassée", 
"alarm.type.battery":"Batterie", 
"alarm.type.pocfraud":"Fraude (passée ou courante)", 
"alarm.type.pocleak":"Fuite (passée ou courante)", 
"alarm.type.pocbackflow":"Retour d'eau (passé ou courant)", 
"alarm.type.pocmeterblock":"Compteur bloqué (passé ou courant)", 
"alarm.type.pocoverflow":"$t(alarm.type.overflow.poc)", 
"alarm.type.endofbattery":"Fin de batterie", 
"alarm.type.clockfault":"$t(alarm.type.clock)", 
"alarm.type.itronbatteryalarm":"Batterie (ITRON)", 
"alarm.type.itrondetectionalarm":"Détection", 
"alarm.type.itronmagnetictamperalarm":"Fraude magnétique", 
"alarm.type.itronmemorizedremovalalarm":"Module démonté (passé)", 
"alarm.type.itronmemoryalarm":"Mémoire", 
"alarm.type.itronrealtimeremovalalarm":"Module démonté (en court)", 
"alarm.type.itronmemorizedtamperalarm":"Module défaillant (passé)", 
"alarm.type.itronrealtimetamperalarm":"Module défaillant (en court)", 
"alarm.type.itronnotwatermediumtype":"Type fluide pas eau", 
"alarm.type.itronchecksumcodealarm":"Checksum", 
"alarm.type.itroncorruptdataalarm":"Données corrompues", 
"alarm.type.itroncurrentyearyearlyindexabovethresholdalarm":"Index annuel supérieur au seuil", 
"alarm.type.itroncurrentyearyearlyindexbelowthresholdalarm":"Index annuel inférieur au seuil", 
"alarm.type.itrondateandtimereconfigurationalarm":"Reconfiguration date/heure", 
"alarm.type.itronmeterblockedalarm":"$t(alarm.type.meterblock)", 
"alarm.type.itronmonthlyleakagealarm":"Fuite mensuelle", 
"alarm.type.itronpreviousyearyearlyindexabovethresholdalarm":"Index annuel précédent supérieur au seuil", 
"alarm.type.itronpreviousyearyearlyindexbelowthresholdalarm":"Index annuel précédent inférieur au seuil", 
"alarm.type.itronrfwakeupalarm":"Réveil radio", 
"alarm.type.itronreconfigurationalarm":"Reconfiguration", 
"alarm.type.itronreversedmeteralarm":"$t(alarm.type.reversedmeter)", 
"alarm.type.itronyearlybackflowalarm":"Retour d'eau annuel", 
"alarm.type.itronyearlyleakagealarm":"Fuite annuelle", 
"alarm.type.itronyearlypeakalarm":"Pic consommation annuelle", 
"alarm.type.itroneverbluenabled":"EverBlue activé", 
"alarm.type.itroncablecutalarm":"Câble coupé", 
"alarm.type.itron.everbluenabled":"Mode Ever blue activé", 
"alarm.type.pastleak":"Fuite passée", 
"alarm.type.currentleak":"Fuite en cours", 
"alarm.type.pastmecafraud":"Fraude mécanique passée", 
"alarm.type.currentmecafraud":"Fraude mécanique en cours", 
"alarm.type.pastmagnetfraud":"Fraude magnétique passée", 
"alarm.type.currentmagnetfraud":"Fraude magnétique en cours", 
"alarm.type.pastmeterblock":"Blocage passé", 
"alarm.type.currentmeterblock":"Blocage en cours", 
"alarm.type.metrologyerror":"Erreur métrologique", 
"alarm.type.mediumabsent":"Médium absent", 
"alarm.type.specificerror":"Erreur spécifique", 
"alarm.type.modulesetting":"Réglage du module", 
"alarm.type.temperature":"$t(all.text.temperature)", 
"alarm.type.fraud.current":"Fraude (courante)", 
"alarm.type.fraud.poc":"Fraude (passée ou courante)", 
"alarm.type.fraud.past":"Fraude (passée)", 
"alarm.type.leak.past":"Fuite (passée)", 
"alarm.type.leak.poc":"Fuite (passée ou courante)", 
"alarm.type.leak.current":"Fuite (courante)", 
"alarm.type.leak.current.high":"Fuite importante (courante)", 
"alarm.type.backflow.past":"Retour d’eau passé", 
"alarm.type.backflow.current":"Retour d’eau en cours", 
"alarm.type.backflow.poc":"Retour d'eau (passé ou courant)", 
"alarm.type.pastbackflow":"$t(alarm.type.backflow.past)", 
"alarm.type.currentbackflow":"$t(alarm.type.backflow.current)", 
"alarm.type.underflow.past":"Sous-débit (passé)", 
"alarm.type.pocunderflow":"$t(alarm.type.underflow.poc)", 
"alarm.type.underflow.current":"Sous-débit (courant)", 
"alarm.type.underflow.poc":"Sous-débit (passé ou courant)", 
"alarm.type.overflow.past":"Sur-débit (passé)", 
"alarm.type.overflow.current":"Sur-débit (courant)", 
"alarm.type.overflow.poc":"Sur-débit (passé ou courant)", 
"alarm.type.meterblock.past":"Compteur bloqué (passé)", 
"alarm.type.meterblock.current":"Compteur bloqué (courant)", 
"alarm.type.meterblock.poc":"$t(alarm.type.pocmeterblock)", 
"alarm.type.battery.past":"Batterie (passé)", 
"alarm.type.battery.current":"Batterie (courant)", 
"alarm.type.battery.poc":"Batterie (passé ou courant)", 
"alarm.type.clock.past":"Horloge (passé)", 
"alarm.type.clock.current":"Horloge (courant)", 
"alarm.type.clock.poc":"Horloge (passé ou courant)", 
"alarm.type.moduledefect.past":"Dysfonctionnement du module (passé)", 
"alarm.type.moduledefect.current":"Dysfonctionnement du module (courant)", 
"alarm.type.moduledefect.poc":"Dysfonctionnement du module (passé ou courant)", 
"alarm.type.modulesetting.past":"Réglage du module (passé)", 
"alarm.type.modulesetting.current":"Réglage du module (courant)", 
"alarm.type.modulesetting.poc":"Réglage du module (passé ou courant)", 
"alarm.type.reversedmeter.past":"Compteur inversé (passé)", 
"alarm.type.reversedmeter.current":"Compteur inversé (courant)", 
"alarm.type.reversedmeter.poc":"Compteur inversé (passé ou courant)", 
"alarm.type.mediumabsent.past":"Médium absent (passé)", 
"alarm.type.mediumabsent.current":"Médium absent (courant)", 
"alarm.type.mediumabsent.poc":"Médium absent (passé ou courant)", 
"alarm.type.brokenpipe.past":"Conduite cassée (passé)", 
"alarm.type.brokenpipe.current":"Conduite cassée (courant)", 
"alarm.type.brokenpipe.poc":"Conduite cassée (passé ou courant)", 
"alarm.type.temperature.past":"Température (passé)", 
"alarm.type.temperature.current":"Température (courant)", 
"alarm.type.temperature.poc":"Température (passé ou courant)", 
"alarm.type.specificerror.past":"Erreur spécifique (passé)", 
"alarm.type.specificerror.current":"Erreur spécifique (courant)", 
"alarm.type.specificerror.poc":"Erreur spécifique (passé ou courant)", 
"alarm.type.fraud.mecanic.past":"Fraude mécanique (passé)", 
"alarm.type.fraud.mecanic.current":"Fraude mécanique (courant)", 
"alarm.type.fraud.mecanic.poc":"Fraude mécanique (passé ou courant)", 
"alarm.type.fraud.magnetic.past":"Fraude magnétique (passé)", 
"alarm.type.fraud.magnetic.current":"Fraude magnétique (courante)", 
"alarm.type.fraud.magnetic.poc":"Fraude magnétique (passé ou courant)", 
"alarm.type.temperature.low.past":"Température basse (passé)", 
"alarm.type.temperature.low.current":"Température basse (courant)", 
"alarm.type.temperature.low.poc":"Température basse (passé ou courant)", 
"alarm.type.temperature.hight.past":"Température élevée (passé)", 
"alarm.type.temperature.hight.current":"Température élevée (courant)", 
"alarm.type.temperature.hight.poc":"Température élevée (passé ou courant)", 
"alarm.type.moduleremoved.past":"$t(alarm.type.itronmemorizedremovalalarm)", 
"alarm.type.moduleremoved.current":"Module démonté (courant)", 
"alarm.type.moduleremoved.poc":"Module démonté (passé ou courant)", 
"alarm.type.itron.battery":"$t(alarm.type.itronbatteryalarm)", 
"alarm.type.itron.detection":"Détection (ITRON)", 
"alarm.type.itron.magnetic.tamper":"Fraude magnétique (ITRON)", 
"alarm.type.itron.memorizedremoval":"Module ITRON démonté (passé)", 
"alarm.type.itron.memory":"Mémoire (ITRON)", 
"alarm.type.itron.realtimeremoval":"Module ITRON démonté (courant)", 
"alarm.type.itron.memorizedtamper":"Module ITRON défaillant (passé)", 
"alarm.type.itron.realtimetamper":"Module ITRON défaillant (courant)", 
"alarm.type.itron.notwatermedium":"Le fluide n'est pas de l'eau (ITRON)", 
"alarm.type.itron.checksumcode":"Trame invalide (ITRON)", 
"alarm.type.itron.corruptdata":"Données corrompues (ITRON)", 
"alarm.type.itron.currentyearyearlyindexabovethreshold":"Index annuel supérieur au seuil (ITRON)", 
"alarm.type.itron.currentyearyearlyindexbelowthreshold":"Index annuel inférieur au seuil (ITRON)", 
"alarm.type.itron.dateandtimereconfiguration":"Reconfiguration date/heure (ITRON)", 
"alarm.type.itron.meterblocked":"Compteur bloqué (ITRON)", 
"alarm.type.itron.monthlyleakage":"Fuite mensuelle (ITRON)", 
"alarm.type.itron.previousyearyearlyindexabovethreshold":"Index annuel précédent supérieur au seuil (ITRON)", 
"alarm.type.itron.previousyearyearlyindexbelowthreshold":"Index annuel précédent inférieur au seuil (ITRON)", 
"alarm.type.itron.rfwakeup":"Réveil radio (ITRON)", 
"alarm.type.itron.reconfiguration":"Reconfiguration (ITRON)", 
"alarm.type.itron.reversedmeter":"Compteur inversé (ITRON)", 
"alarm.type.itron.yearlybackflow":"Retour d'eau annuel (ITRON)", 
"alarm.type.itron.yearlyleakage":"Fuite annuelle (ITRON)", 
"alarm.type.itron.yearlypeak":"Pic consommation annuelle (ITRON)", 
"alarm.type.itron.cablecut":"Cable coupé (ITRON)", 
"alarm.type.birdz.reversedmountmeter":"Compteur inversé (BIRDZ)", 
"alarm.type.birdz.oversizedsmalldiameter":"Petit diamètre trop grand (BIRDZ)", 
"alarm.type.birdz.oversizedbigdiameter":"Grand diamètre trop grand (BIRDZ)", 
"alarm.type.birdz.undersizedsmalldiameter":"Petit diamètre trop petit (BIRDZ)", 
"alarm.type.birdz.undersizedbigdiameter":"Grand diamètre trop petit (BIRDZ)", 
"alarm.type.birdz.irregularblock":"Blocage irrégulier (BIRDZ)", 
"alarm.type.birdz.lowtemperaturefreeze":"Gel à basse température (BIRDZ)", 
"alarm.type.birdz.reversedmeter":"$t(alarm.type.birdz.reversedmountmeter)", 
"alarm.type.birdz.magneticfraud":"Fraude magnétique (BIRDZ)", 
"alarm.type.birdz.unmountedmodule":"Module démonté (BIRDZ)", 
"alarm.type.birdz.backflowl1":"Retou d'eau L1 (BIRDZ)", 
"alarm.type.birdz.backflowl2":"Retour d'eau L2 (BIRDZ)", 
"alarm.type.birdz.hightemperaturesmalldiameter":"Petit diamètre température élevé (BIRDZ)", 
"alarm.type.birdz.hightemperaturebigdiameter":"Grand diamiètre température élevé (BIRDZ)", 
"alarm.type.birdz.stoppersistence":"Arrêter la persistance (BIRDZ)", 
"alarm.type.birdz.irregularleak":"Fuite irrégulière (BIRDZ)", 
"alarm.type.integra.meterblocked":"Compteur bloqué (INTEGRA)", 
"alarm.type.integra.minwatertemp":"Température de l'eau < 3°C (INTEGRA)", 
"alarm.type.integra.maxwatertemp":"Température de l'eau > 50°C (INTEGRA)", 
"alarm.type.integra.minambianttemp":"Température ambiante très basse (INTEGRA)", 
"alarm.type.integra.maxambianttemp":"Température ambiante > 70°C (INTEGRA)", 
"alarm.type.integra.lowbattery":"Batterie Faible (INTEGRA)", 
"alarm.type.integra.bubbles":"Air détecté (INTEGRA)", 
"alarm.type.integra.tamper":"Compteur trafiqué (INTEGRA)", 
"alarm.type.integra.leak":"Fuite (INTEGRA)", 
"alarm.type.integra.burst":"Canalisation détruite (INTEGRA)", 
"alarm.type.integra.dry":"Canalisation sèche (INTEGRA)", 
"alarm.type.integra.reverseflow":"Flux inversé (INTEGRA)", 
"alarm.type.integra.asiccommunicationerror":"Erreur de communication (INTEGRA)", 
"alarm.type.axioma.abdormalcondition":"Température ambiante anromal (AXIOMA)", 
"alarm.type.axioma.burst":"Canalisation détruite (AXIOMA)", 
"alarm.type.axioma.burst.temporary":"Canalisation détruite temporairement (AXIOMA)", 
"alarm.type.axioma.burst.permanent":"Canalisation détruite permanent (AXIOMA)", 
"alarm.type.axioma.backflow":"Retour d'eau (AXIOMA)", 
"alarm.type.axioma.backflow.temporary":"Retour d'eau temporaire (AXIOMA)", 
"alarm.type.axioma.backflow.permanent":"Retour d'eau permanent (AXIOMA)", 
"alarm.type.axioma.waterfreeze":"Eau gelée (AXIOMA)", 
"alarm.type.axioma.waterfreeze.temporary":"Eau gelée temporaire (AXIOMA)", 
"alarm.type.axioma.waterfreeze.permanent":"Eau gelée permanente (AXIOMA)", 
"alarm.type.axioma.leakage":"Fuite (AXIOMA)", 
"alarm.type.axioma.leakage.temporary":"Fuite temporaire (AXIOMA)", 
"alarm.type.axioma.leakage.permanent":"Fuite permanente (AXIOMA)", 
"alarm.type.axioma.lowbattery":"Batterie faible (AXIOMA)", 
"alarm.type.axioma.lowbattery.temporary":"Batterie faible temporaire (AXIOMA)", 
"alarm.type.axioma.lowbattery.permanent":"Batterie faible permanente (AXIOMA)", 
"alarm.type.axioma.dry":"Canalisation sèche (AXIOMA)", 
"alarm.type.axioma.dry.permanent":"Canalisation sèche permanente (AXIOMA)", 
"alarm.type.axioma.manipulation":"Manipulation (AXIOMA)", 
"alarm.type.axioma.manipulation.permanent":"Manipulation permanante (AXIOMA)", 
"alarm.type.axioma.lowtemperature":"Température basse (AXIOMA)", 
"alarm.type.axioma.lowtemperature.permanent":"Température basse permanente (AXIOMA)", 
"alarm.type.diehl.leakage":"Fuite (DIEHL)", 
"alarm.type.diehl.leakagehist":"Fuite historique (DIEHL)", 
"alarm.type.diehl.manipulation":"Manipulation (DIEHL)", 
"alarm.type.diehl.manipulationhist":"Manipulation historique (DIEHL)", 
"alarm.type.diehl.blocked":"Compteur bloqué (DIEHL)", 
"alarm.type.diehl.blockedhist":"Compteur bloqué historique (DIEHL)", 
"alarm.type.diehl.unclipped":"Démonté (DIEHL)", 
"alarm.type.diehl.unclippedhist":"Démonté historique (DIEHL)", 
"alarm.type.diehl.overflow":"Sur-débit (DIEHL)", 
"alarm.type.diehl.overflowhist":"Sur-débit historique (DIEHL)", 
"alarm.type.diehl.underflow":"Sous-débit (DIEHL)", 
"alarm.type.diehl.underflowhist":"Sous-débit historique (DIEHL)", 
"alarm.type.diehl.backflow":"Retour d'eau (DIEHL)", 
"alarm.type.diehl.backflowhist":"Retour d'eau historique (DIEHL)", 
"alarm.type.diehl.tempsensor":"Capteur de température (DIEHL)", 
"alarm.type.diehl.tempsensorhist":"Capteur de température historique (DIEHL)", 
"alarm.type.diehl.battery":"Batterie (DIEHL)", 
"alarm.type.diehl.batteryhist":"Batterie historique (DIEHL)", 
"alarm.type.birdz.startflowsmalldiameter":"Débit de démarrage - petit calibre (BIRDZ)", 
"alarm.type.birdz.startflowbigdiameter":"Débit de démarrage - gros calibre (BIRDZ)", 
"alarm.type.birdz.undercountbigdiameter":"Sous-comptage – gros calibre (BIRDZ)", 
"alarm.type.birdz.watchdog":"WatchDog (BIRDZ)", 
"alarm.type.birdz.hourset":"Module remis à l’heure (BIRDZ)", 
"alarm.type.birdz.anormalrestflowvalue":"Valeur anormale du courant de repos (BIRDZ)", 
"alarm.type.birdz.supervisiontension":"Supervision de tension (BIRDZ)", 
"alarm.type.birdz.modulereconfigured":"Reconfiguration module (BIRDZ)", 
"alarm.type.birdz.solenoidvalve":"Electrovanne (BIRDZ)", 
"alarm.type.birdz.noisedefensemaxrssicrossed":"Défense au bruit : limite RSSi franchie (BIRDZ)", 
"alarm.type.birdz.noisedefenseradioreceptionsuspended":"Défense au bruit : réceptions radio suspendues (BIRDZ)", 
"alarm.type.birdz.noisedefenselrlisteningsuspended":"Défense au bruit : écoutes LR suspendues (BIRDZ)", 
"alarm.type.birdz.lowtemperatureradiostoppage":"Température basse: débrayage radio (BIRDZ)", 
"alarm.type.birdz.limitimmediatealarmcycle":"Limitation des cycles d’alarme immédiate (BIRDZ)", 
"alarm.type.birdz.hoursetdelayexpiration":"Expiration du délai de remise à l’heure du module (BIRDZ)", 
"alarm.type.birdz.acquisitionflooddefault":"Défaut étage d’acquisition (BIRDZ)", 
"alarm.type.birdz.destinationtime":"Temps de séjour (BIRDZ)", 
"mask.text.select_at_least_once_meter":"Veuillez sélectionner au moins 1 compteur", 
"mask.text.select_at_least_once_alarm":"Veuillez sélectionner au moins 1 alarme", 
"columns.meter.serial":"$t(all.meter.meter_serial)", 
"columns.serial":"$t(all.meter.meter_serial)", 
"columns.radio.serial":"$t(all.meter.radio_serial)", 
"columns.withAlarm":"Avec alarmes", 
"columns.alarms":"$t(all.alarm_meter.alarm_plural)", 
"columns.reference":"$t(all.column_text.pdi_ref)", 
"columns.state":"$t(all.pdi.state_pdi)", 
"columns.withAlarmMasked":"Avec alarmes masquées", 
"columns.triggerAlarms.leak":"Date de déclenchement fuite", 
"columns.triggerAlarms.fraud":"Date de déclenchement fraude", 
"columns.triggerAlarms.backflow":"Date de déclenchement retour d'eau", 
"columns.closeAlarms.leak":"Date fin période fuite", 
"columns.closeAlarms.fraud":"Date fin période fraude", 
"columns.closeAlarms.backflow":"Date fin période retour d'eau", 
"columns.codeTourneeReleve":"Code tournée relève", 
"columns.labelTourneeReleve":"Nom tournée relève", 
"columns.splitAlarms.Fraudpoc":"Alarme fraude", 
"columns.splitAlarms.Fraudcurrent":"Alarme fraude active", 
"columns.splitAlarms.Fraudpast":"Alarme fraude passée", 
"columns.splitAlarms.Leakpoc":"Alarme fuite", 
"columns.splitAlarms.Leakcurrent":"Alarme fuite active", 
"columns.splitAlarms.Leakpast":"Alarme fuite passée", 
"columns.splitAlarms.Backflowpoc":"Alarme retour d'eau", 
"columns.splitAlarms.Backflowcurrent":"Alarme retour d'eau active", 
"columns.splitAlarms.Backflowpast":"Alarme retour d'eau passée", 
"columns.splitAlarms.Batterycurrent":"Alarme batterie active", 
"columns.splitAlarms.Batterypast":"Alarme batterie passée", 
"columns.splitAlarms.ModuleDefectcurrent":"Alarme module défectueux active", 
"columns.splitAlarms.ModuleDefectpast":"Alarme module defectueux passée", 
"columns.splitAlarms.Clockcurrent":"Alarme horloge active", 
"columns.splitAlarms.Clockpast":"Alarme horloge passée", 
"columns.splitAlarms.MeterBlockpoc":"Alarme compteur bloqué", 
"columns.splitAlarms.MeterBlockpast":"Alarme compteur bloqué passée", 
"columns.splitAlarms.Underflowpoc":"Alarme sous-débit", 
"columns.splitAlarms.Underflowpast":"Alarme sous-débit passée", 
"columns.splitAlarms.Underflowcurrent":"Alarme sous-débit active", 
"columns.splitAlarms.Overflowpoc":"Alarme sur-débit", 
"columns.splitAlarms.Overflowcurrent":"Alarme sur-débit active", 
"columns.splitAlarms.Overflowpast":"Alarme sur-débit passé", 
"columns.previousMeter.installDate":"Date dernier changement compteur", 
"columns.previousMeter.previousMountedMeterSerial":"Numéro de série compteur précédent", 
"columns.previousMeter.deposeIndex":"Index de dépose", 
"columns.alarmsMasked":"Alarmes masquées", 
"columns.simultaneousAlarms":"Alarmes actives simultanées", 
"columns.clpInformations":"Informations abonné", 
"columns.customInformation.couleur_plombage":"$t(column_list.label.sealing_color)", 
"columns.customInformation.code_reseau":"$t(column_list.label.network_code)", 
"columns.customInformation.code_tournee":"Code tournée facturation", 
"columns.customInformation.name_tournee":"Nom de tournée facturation", 
"columns.customInformation.etat_abonnement":"Etat de l'abonnement", 
"columns.customInformation.date_debut_abonnement":"Date de début d'abonnement", 
"columns.customInformation.date_fin_abonnement":"Date de fin d'abonnement", 
"columns.customInformation.complement_voie":"Complément de voie", 
"columns.read.date":"$t(columns.rdsDate)", 
"columns.read.lastDate":"$t(all.read_meter.read_last_date)", 
"columns.read.meterIndex":"$t(all.meter.meter_index)", 
"columns.read.radioIndex":"Index de la radio", 
"columns.read.consumption":"$t(all.read_meter.cons)", 
"columns.read.readMethod":"Méthode de lecture", 
"columns.read.source":"Source de lecture", 
"columns.read.previousDate":"Date précédente de dernière lecture", 
"columns.read.previousMeterIndex":"Index précédent compteur", 
"columns.read.offsetIndex":"Offset index radio", 
"columns.currentRead.date":"Date de lecture courante", 
"columns.currentRead.meterIndex":"Index courant du compteur", 
"columns.currentRead.radioIndex":"Index courant de la radio", 
"columns.currentRead.consumption":"Consommation courante", 
"columns.currentRead.readMethod":"Méthode de lecture courante", 
"columns.currentRead.source":"Source de lecture courante", 
"columns.currentRead.previousDate":"Date précédente de lecture courante", 
"columns.currentRead.previousMeterIndex":"Index précédent de la lecture courante du compteur", 
"columns.currentRead.offsetIndex":"Offset index courrant de la radio", 
"columns.address.city":"$t(all.address.city)", 
"columns.address.country":"$t(all.address.country)", 
"columns.address.complement":"$t(all.address.complement)", 
"columns.address.street":"$t(all.address.street)", 
"columns.address.streetNumber":"$t(all.address.street_number)", 
"columns.address.streetNumberAdditionnal":"$t(all.address.street_number_additionnal)", 
"columns.address.concatAdress":"Adresse complète", 
"columns.address.zipcode":"$t(all.address.zipcode)", 
"columns.address.appartement":"$t(import_billing.label.pdi_appart)", 
"columns.address.batiment":"$t(import_billing.label.pdi_building)", 
"columns.address.etage":"$t(import_billing.label.pdi_floor)", 
"columns.address.escalier":"$t(import_billing.label.pdi_stair)", 
"columns.meter.diameter":"$t(all.meter.real_meter_diameter)", 
"columns.meter.model":"$t(all.meter.meter_model)", 
"columns.model":"$t(all.meter.meter_model)", 
"columns.meter.manufacturer":"$t(all.meter.meter_manufacturer)", 
"columns.manufacturer":"$t(all.meter.meter_manufacturer)", 
"columns.meter.mtrInformations":"Informations compteur", 
"columns.meter.startDate":"$t(all.text.install_date)", 
"columns.meter.lastUpdateDate":"Dernière mise à jour fiche", 
"columns.meter.endDate":"Etat du compteur", 
"columns.meter.fluidType":"Type de fluide", 
"columns.gpsPosition.alt":"$t(column_list.label.altitude)", 
"columns.gpsPosition.lat":"$t(all.text.latitude)", 
"columns.gpsPosition.lng":"$t(all.text.longitude)", 
"columns.radio.manufacturer":"$t(all.radio.radio_manufacturer)", 
"columns.radio.type":"Type de radio", 
"columns.radio.composeInternalSerial":"Numéro de série radio interne", 
"columns.updatedAt":"$t(all.date.update_date)", 
"columns.name":"$t(all.text.name)", 
"columns.average":"$t(all.text.average)", 
"columns.type":"$t(columns.radio.type)", 
"columns.location.name":"$t(identity_chooser.text.location_name)", 
"columns.meter.mtrNote":"Message libre", 
"columns.previousRadio.installUnmount":"Date de démontage ancienne radio", 
"columns.previousRadio.previousMountedRadioSerial":"Numéro de série ancienne radio", 
"columns.meter.message":"Message", 
"columns.releveTerrain.previousIndex":"Index relève précédente", 
"columns.releveTerrain.currentIndex":"Index relève courante", 
"columns.releveTerrain.previousDate":"Date relève précédente", 
"columns.releveTerrain.currentDate":"Date relève courante", 
"columns.releveTerrain.consumption":"Consommation relève", 
"columns.releveTerrain.consumptionState":"Etat consommation relève courante", 
"columns.releveTerrain.seuilOverflow":"Anomalie consommation personnalisée", 
"columns.parentReference":"$t(pdi.PDI_PARENT_REFERENCE)", 
"columns.parentRelation":"$t(pdi.PDI_PARENT_RELATION)", 
"columns.ficheState":"Etat fiche", 
"columns.cardOrder":"Ordre", 
"columns.note":"Note", 
"columns.marquage":"$t(all.text.marquage)", 
"columns.cptSerial":"$t(all.meter.meter_serial)", 
"columns.revisedCptSerial":"Numéro de série compteur révisé", 
"columns.installDate":"Année installation", 
"columns.wheels":"Nb roues", 
"columns.cptManufacturer":"Constructeur compteur", 
"columns.mtrInformations":"$t(columns.meter.mtrInformations)", 
"columns.message":"$t(all.text.message)", 
"columns.particularMessageMeter":"$t(all.text.particular_msg)", 
"columns.hasGpsPosition":"$t(all.text.gps)", 
"columns.oldMeterEndDate":"Changement", 
"columns.newMeterSerial":"NS nouveau compteur", 
"columns.installDateNewMeter":"$t(all.text.install_date)", 
"columns.radioSerial":"NS radio", 
"columns.radioManufacturer":"Constructeur radio", 
"columns.amrReference":"Référence AMR", 
"columns.indexed":"Indéxé", 
"columns.modified":"$t(all.text.modified)", 
"columns.previousDate":"Date relève précédente", 
"columns.previousIndex":"Index relève précédente", 
"columns.currentDate":"Date relève courante", 
"columns.currentIndex":"Index relève courante", 
"columns.consoState":"Etat de la consommation", 
"columns.updateDate":"$t(all.text.update_date)", 
"columns.lastUpdateByUser":"Modifié par", 
"columns.facturationCode":"Code facturation", 
"columns.meterSerialNumber":"NS compteur", 
"columns.radioSerialNumber":"NS radio", 
"columns.rdsDate":"Date de lecture", 
"columns.meterIndex":"Index compteur m3", 
"columns.withCreation":"$t(all.text.indexed)", 
"columns.t1CurrentExist":"Lecture remplacé", 
"columns.readDate":"Date de lecture", 
"columns.compteurSerial":"$t(columns.meter.serial)", 
"columns.readCompteurIndex":"$t(columns.read.meterIndex)", 
"columns.consumption":"$t(columns.read.consumption)", 
"columns.readRadioIndex":"$t(columns.read.radioIndex)", 
"columns.radioSensorPort":"Port capteur", 
"columns.clpReference":"$t(columns.reference)", 
"columns.readRadioPulse":"Pulse radio", 
"columns.codeTournee":"$t(columns.codeTourneeReleve)", 
"columns.codeReseau":"$t(columns.customInformation.code_reseau)", 
"columns.nomTournee":"$t(columns.labelTourneeReleve)", 
"exportpdi.text.load_reads":"Chargement des lectures à exporter", 
"exportpdi.text.csv_export_processing":"Export CSV en cours", 
"exportpdi.text.no_read_found":"Aucune lecture trouvée", 
"columns.withPreviousAlarm":"Alarmes relève précédente", 
"columns.lastUpdate":"$t(all.date.update_date)", 
"columns.cptStatusId":"Statuts consommation", 
"columns.newDate":"Date pose nouveau compteur", 
"columns.group.pdi":"$t(all.pdi.pdi)", 
"columns.group.triggeralarms":"Date de déclenchement alarme", 
"columns.group.closealarms":"Fin de période alarme", 
"columns.group.splitalarms":"Alarmes unitaires", 
"columns.group.previousmeter":"$t(fiche_detail.title.old_meter)", 
"columns.group.custominformation":"$t(all.text.more_info_plural)", 
"columns.group.address":"$t(all.location.address)", 
"columns.group.read":"Lecture", 
"columns.group.currentread":"Lecture courante", 
"columns.group.meter":"$t(all.meter.meter)", 
"columns.group.gpsposition":"Localisation", 
"columns.group.radio":"$t(all.radio.radio)", 
"columns.group.previousradio":"Radio précédente", 
"columns.group.releveterrain":"Relève terrain", 
"columns.exportname":"Nom de l'export personnalisé", 
"columns.exportfilename":"Nom du fichier ", 
"columns.exportowner.username":"Nom du créateur", 
"columns.datePreviousReleve":"$t(columns.previousDate)", 
"columns.listReleve_dateReleve":"Date d'historique facturation", 
"linefilter.operator.date.=":"Egal à", 
"linefilter.operator.date.>=":"Postérieur à ", 
"linefilter.operator.date.<=":"Antérieur à ", 
"linefilter.operator.date.vide":"Vide", 
"linefilter.operator.date.notvide":"Non vide", 
"linefilter.operator.date.between":"Entre", 
"linefilter.operator.text.contient":"Contient", 
"linefilter.operator.text.contientpas":"Ne contient pas", 
"linefilter.operator.text.commencepar":"Commence par", 
"linefilter.operator.text.finipar":"Fini par", 
"linefilter.operator.text.vide":"$t(linefilter.operator.date.vide)", 
"linefilter.operator.text.notvide":"$t(linefilter.operator.date.notvide)", 
"linefilter.operator.checkbox.true":"$t(all.text.yes)", 
"linefilter.operator.checkbox.false":"$t(all.text.no)", 
"linefilter.operator.state.checkbox.true":"$t(all.text.active)", 
"linefilter.operator.state.checkbox.false":"$t(all.text.inactive)", 
"linefilter.operator.number.>":"Supérieur à ", 
"linefilter.operator.number.>=":"Supérieur ou égal à", 
"linefilter.operator.number.<":"Inférieur à", 
"linefilter.operator.number.<=":"Inférieur ou égal à", 
"linefilter.operator.number.=":"$t(linefilter.operator.date.=)", 
"linefilter.operator.number.!=":"Différent de", 
"linefilter.operator.number.== null":"Est nul", 
"linefilter.operator.number.!= null":"N'est pas nul", 
"linefilter.userinput.placeholder_input":"Option 1, Option 2, Option 3 ...", 
"linefilter.operator.selectcontvalue.=":"Est", 
"linefilter.operator.selectcontvalue.!=":"N'est pas", 
"linefilter.operator.selectcont.=":"$t(linefilter.operator.selectcontvalue.=)", 
"linefilter.operator.selectcont.!=":"$t(linefilter.operator.selectcontvalue.!=)", 
"linefilter.operator.selectalarmtypebasic.=":"$t(linefilter.operator.selectcontvalue.=)", 
"linefilter.operator.selectalarmtypebasic.!=":"$t(linefilter.operator.selectcontvalue.!=)", 
"linefilter.operator.selectalarmtypebasic.contient":"$t(linefilter.operator.text.contient)", 
"linefilter.operator.selectalarmtypebasic.contientpas":"$t(linefilter.operator.text.contientpas)", 
"virtualmeter.text.name_already_exist":"Ce nom existe déjà", 
"permission.text.read.location":"Accès à la location en lecture", 
"permission.text.edit.dashboard":"Gestions des Dashboards", 
"permission.text.edit.virtualMeter":"Gestions des compteurs virtuels", 
"permission.text.edit.alert":"Gestions des alertes", 
"permission.text.edit.maskAlarm":"Gestion des masques d’alarmes", 
"permission.text.edit.locationInfo":"Modification des informations du site", 
"permission.text.edit.pdi":"Gestion des PDIs (association / dissociation)", 
"permission.text.edit.subLocation":"Création de sous sites", 
"permission.text.read.round":"Accès en lecture aux tournées", 
"permission.text.use.synchro":"Possibilité de faire des synchronisations / Editer le nom des portables", 
"permission.text.import.synchro":"Importer des tournées / messages / marquages", 
"permission.text.edit.round":"Exporter / Supprimer une tournée", 
"permission.text.import.factu":"Possibilité d’importer des fichiers de facturation", 
"permission.text.export.brute":"Export brute", 
"permission.text.delete.phone":"Supprimer un portable", 
"permission.text.edit.message":"Modifier les informations du pdi", 
"users_list.text.view_informations":"Consulter les informations", 
"users_list.text.block_user":"Bloquer l'utilisateur", 
"users_list.text.unblock_user":"Débloquer l'utilisateur", 
"users_list.text.delete_user":"Supprimer l'utilisateur", 
"tournee_fiches.text.recup_telereleve":"Récupération Télérelève", 
"tournee_fiches.text.recup_reads_telereleve":"Récupération des lectures en télérelève", 
"tournee_fiches.text.reads_get":"lecture récupérée", 
"tournee_fiches.text.reads_get_plural":"lectures récupérées", 
"tournee_fiches.text.new_reads":"nouvelle lecture", 
"tournee_fiches.text.new_reads_plural":"nouvelles lectures", 
"tournee_fiches.text.replaced_reads":"lecture remplacée", 
"tournee_fiches.text.replaced_reads_plural":"lectures remplacées", 
"tournee_fiches.text.recup_reads_processing":"Récupération des lectures en cours", 
"tournee_fiches.text.no_read_found":"$t(exportpdi.text.no_read_found)", 
"tournee_fiches.text.replace_reads_by_more_recent":"Remplacer les lectures existantes par des plus récentes", 
"tournee_fiches.text.lookup_reads_processing":"Recherche des lectures en cours", 
"tournee_fiches.text.rotate_fiches":"Rotation des relèves", 
"tourneefiches.text.rotation_index_description":"Etes vous sur de vouloir démarrer une nouvelle relève pour cette tournée ?", 
"tourneefiches.text.rotation_index_warning":"La rotation des index est irréversible.", 
"rapport.name.amrRead":"Compte rendu des données AMR", 
"rapport.name.cptRead":"Compte rendu des données de relève", 
"rapport.name.cptReadOrderByOrder":"Compte rendu des données de relève triée par ordre", 
"rapport.name.cptReadOrderByDate":"Compte rendu des données de relève triée par date", 
"rapport.name.cptReadOrderByStatus":"Compte rendu des données de relève triée par statut", 
"rapport.name.resumeRead":"Résumé des relèves", 
"rapport.name.agingMeter":"Vieillissement du parc compteur", 
"rapport.name.cptDetail":"Détail des compteurs", 
"rapport.name.amrDetail":"Détail des radio", 
"rapport.name.enqueteDetail":"Détail des enquêtes", 
"rapport.name.cptReadAndAmr":"Compte rendu des données AMR et relève", 
"rapport.name.quickSummary":"Résumé de la relève", 
"rapport.name.statusReleve":"Statut de la relève", 
"rapport.option.sort_by":"Trier par", 
"rapport.option.sorted_by":"Trié par", 
"rapport.option.view_pdf":"Visualiser le PDF", 
"rapport.option.full_screen":"Plein écran", 
"rapport.option.preview_pdf":"Prévisualisation PDF", 
"rapport.text.no_report_selected":"Aucun rapport sélectionné", 
"rapport.error.generic":"Une erreur est survenue lors de la génération du rapport", 
"rapport.error.no_data":"Pas de données suffisantes pour générer un rapport de tournée", 
"import_silex.text.import_rounds":"Importation de tournées", 
"import_silex.text.round_file":"Fichier de tournée", 
"import_silex.columns.identifiant":"Identifiant", 
"import_silex.columns.field":"$t(enquete.text.field)", 
"import_silex.columns.temoin_value":"Valeur témoin", 
"import_silex.columns.generated_value":"Valeur générée", 
"import_silex.text.wrong_encoding":"Erreur : L'encodage du fichier [{{filename}}] [{{fileCharset}}] ne correspond pas aux paramètres définis [{{paramCharset}}]", 
"import_silex.text.load_file_failed":"Chargement du fichier Silex [{{filename}}] échoué", 
"import_silex.text.select_round":"Sélectionner une tournée", 
"import_silex.text.silex_format":"format Silex", 
"import_silex.error_text.lines_incorrect_length":"La ligne contient un nombre de caractère différent de 512", 
"import_silex.error_text.no_line_id":"Le type d’enregistrement est manquant", 
"import_silex.error_text.silex_error":"Le fichier à importer contient des erreurs", 
"import_silex.error_text.no_round_code":"Le code de la tournée est manquant", 
"import_silex.error_text.no_order_number":"Le numéro d'ordre est manquant", 
"import_silex.error_text.no_subscriber_info":"Les informations de l'abonné sont manquantes", 
"import_silex.error_text.no_meter_info":"Les informations du compteur sont manquantes", 
"import_silex.error_text.no_theoretical_serial":"Le numéro de série théorique est manquant", 
"import_silex.error_text.no_theoretical_old_serial":"$t(import_silex.error_text.no_theoretical_serial)", 
"import_silex.error_text.no_change_meter_authorisation":"L'authorisation de changement de compteur est manquante", 
"import_silex.error_text.no_amr_install_date":"La date d'installation de l'AMR est manquante", 
"import_silex.error_text.no_amr_index_number":"Le numéro d'index de l'AMR est manquant", 
"import_silex.error_text.no_meter_max_value":"La valeur maximale du compteur est manquante", 
"import_silex.error_text.no_meter_unit":"L'unité de l'index du compteur est manquante", 
"import_silex.error_text.no_amr_reference":"La référence de l'AMR est manquante", 
"import_silex.error_text.no_amr_serial":"Le numéro de série de l'AMR est manquant", 
"import_silex.error_text.no_amr_internal_id":"L'identifiant interne de l'AMR est manquant", 
"import_silex.error_text.no_amr_data":"Les données de l'AMR sont manquantes", 
"import_silex.error_text.no_pulse_weight":"Le poids d'impulsion est manquant", 
"import_silex.error_text.no_amr_max_value":"La valeur maximale de l'AMR est manquante", 
"import_silex.error_text.no_previous_read_date":"La date de relève précédente est manquante", 
"import_silex.error_text.no_previous_meter_index":"L'index précédent du compteur est manquant", 
"import_silex.error_text.no_previous_amr_index":"L'index précédent de l'AMR est manquant", 
"import_silex.error_text.no_previous_alarms":"Les alarmes précédentes de l'AMR sont manquantes", 
"import_silex.error_text.no_previous_status":"Le statut précédent de l'AMR est manquant", 
"import_silex.error_text.no_new_serial":"Le numéro de série du nouveau compteur est manquant", 
"import_silex.error_text.no_number_of_digits":"Le nombre de digit est manquant", 
"import_silex.error_text.no_start_index":"L'index de départ est manquant", 
"import_silex.error_text.no_install_date":"La date d'installation est manquante", 
"import_silex.error_text.no_meter_serial":"Le numéro de série du compteur est manquant", 
"import_silex.error_text.no_read_date":"La date de relève est manquante", 
"import_silex.error_text.no_meter_index":"L'index du compteur est manquant", 
"import_silex.error_text.no_backflow_index":"L'index de retour d'eau est manquant", 
"import_silex.error_text.no_fraud_alarm":"L'alarme de fraude est manquante", 
"import_silex.error_text.no_leak_alarm":"L'alarme de fuite est manquante", 
"import_silex.error_text.no_backflow_alarm":"L'alarme de retour d'eau est manquante", 
"import_silex.error_text.no_battery_alarm":"L'alarme de batterie est manquante", 
"import_silex.error_text.no_faulty_module_alarm":"L'alarme de défaut de module est manquante", 
"import_silex.error_text.no_clock_alarm":"L'alarme d'horloge est manquante", 
"import_silex.error_text.no_page_number":"Le numéro de page d'enquête est manquant", 
"import_silex.error_text.no_actual_serial":"Le numéro de série actuel est manquant", 
"import_silex.error_text.missing_t0_empty":"Aucune ligne d'enregistrement de tournée trouvée", 
"import_silex.error_text.missing_t1_empty":"Aucune ligne d'enregistrement de compteur trouvée", 
"import_silex.error_text.missing_t2_empty":"Aucune ligne d'enregistrement AMR trouvée", 
"import_silex.error_text.missing_t5_empty":"Aucune ligne de définition de page d’enquête trouvée ", 
"import_silex.error_text.missing_t6_empty":"Aucune ligne de réponse enquête trouvée ", 
"import_silex.error_text.missing_t2":"Radio numéroté {{serial}}", 
"import_silex.error_text.missing_t5":"L'enquête n°{{page}} est manquante", 
"import_silex.error_text.missing_t6":"Réponse à l'enquête n°{{page}} correspondant au compteur {{serial}} est manquante", 
"import_silex.error_text.wrong_data":"La donnée ne respecte pas le format souhaité", 
"import_silex.error_text.meter_in_round":"Un ou plusieurs compteurs sont déjà associés à d'autres tournées", 
"import_silex.column_label.errors":"Erreurs", 
"import_silex.column_label.lines":"Lignes", 
"import_silex.column_label.types":"Types", 
"import_silex.column_label.positions":"Positions", 
"import_silex.column_label.description":"Description", 
"import_silex.column_label.associated_round":"Tournées associées", 
"provisioning.text.provisionned":"Provisionné", 
"provisioning.text.not_provisionned":"Non provisionné", 
"provisioning.text.actions":"Actions", 
"telereleveinfo.text.network":"Réseau", 
"telereleveinfo.text.deveui":"DevEUI", 
"telereleveinfo.text.last_telereleve_read":"Date dernière télérelève", 
"telereleveinfo.text.meter_index":"$t(widget.display_value.meterreadingvalue)", 
"telereleveinfo.text.radio_index":"$t(widget.display_value.radioreadingvalue)", 
"telereleveinfo.text.provision_radio":"Provisionner", 
"telereleveinfo.text.deprovision_radio":"Déprovisionner", 
"telereleveinfo.text.provision_impossible":"Provisioning impossible", 
"telereleveinfo.text.missing_informations":"Les informations requises sont manquantes", 
"telereleveinfo.text.reprovision_impossible_message":"Si vous décidez de déprovisionner le module, vous ne pourrez plus le provisionner car les informations nécéssaires sont manquantes !", 
"telereleve.text.telereleve":"Télé-relève", 
"telereleve.column.networkName":"Protocole", 
"telereleve.column.clpReference":"$t(all.pdi.pdi)", 
"telereleve.column.mtrSerialNumber":"$t(all.meter.meter_serial)", 
"telereleve.column.radioSerialNumber":"$t(all.meter.radio_serial)", 
"telereleve.column.devEUI":"$t(telereleveinfo.text.deveui)", 
"telereleve.column.providerName":"Nom du réseau", 
"telereleve.column.canProvision":"Provisionnable", 
"telereleve.column.adress":"$t(all.location.address)", 
"telereleve.text.load_telereleve":"Chargement des télé-relèves", 
"provisionning.text.provision_processing":"Provisioning en cours", 
"custom_export.title.custom_export":"Export personnalisé", 
"custom_export.title.custom_export_plural":"Exports personnalisés", 
"custom_export.text.add_custom_export":"Ajouter un export personnalisé", 
"custom_export.text.export_name":"Nom de l'export", 
"custom_export.text.export_filename":"Nom du fichier généré", 
"custom_export.text.edit_custom_export":"Editer l'export personnalisé", 
"custom_export.text.duplicate":"Dupliquer l'export personnalisé", 
"custom_export.text.launch_export":"Lancer l'export personnalisé", 
"custom_export.text.delete_export":"Supprimer l'export personnalisé", 
"custom_export.text.define_at_launch":"Définir au lancement", 
"custom_export.text.sorting":"Tri", 
"custom_export.text.add_filter_value_to_filename":"Ajout valeur filtres au nom du fichier", 
"custom_export.text.generatedDate":"Date de génération", 
"custom_export.text.generatedHour":"Heure de génération", 
"custom_export.text.nameSite":"$t(identity_chooser.text.location_name)", 
"gateway.text.gateway":"Gateway", 
"gateway_meter_captured.columns.field":"Nombre de compteurs captés", 
"gateway_geolocated.columns.field":"Gateway géolocalisée", 
"gateway_name.text.map":"Nom de la gateway", 
"gateway_geolocated_meter_captured.columns.field":"Nombre de compteurs géolocalisés captés", 
"gateway_geolocated_meter_last_read.text":"Nombre de compteurs géolocalisés captés de la dernière relève", 
"gateway.registration_date":"Date d'enregistrement de la gateway", 
"gayeway.last_communication_date":"Date de dernière communication de la gateway", 
"gateway.model":"Modèle de la gateway", 
"gateway.informations":"Informations de la gateway", 
"manage_pdi.text.nb_meter_to_link":"{{number}} compteur à associer", 
"manage_pdi.text.nb_meter_to_unlink":"{{number}} compteur à dissocier", 
"mapbox_button.text.save_polygon":"Sauvegarder le(s) polygone(s)", 
"mapbox.return_all_gateways":"Retourner à l'affichage de toutes les gateways", 
"tournee.text.export.success":"La tournée {{tournee}} a été exportée avec succès", 
"tournee.text.export.error_detected":"Une erreur a été détectée pendant l'export de la tournée {{tournee}}", 
"tournee.text.export_factu.success":"La tournée {{tournee}} a été exportée vers la facturation avec succès", 
"tournee.text.forbidden":"$t(tournee.text.unauthorized)", 
"tournee.text.unauthorized":"Non-autorisé", 
"tournee.text.failure":"Echec", 
"tournee.text.notfound":"Introuvable", 
"tournee.text.success":"Exportée", 
"tournee.text.export_round_csv_custom":"Export tournées personnalisées", 
"tournee.text.export_round_csv_custom_choices":"Type d'export ", 
"tournee.text.export_dat":"DAT", 
"tournee.text.export_csv":"CSV", 
"tournee.text.error.lines_incorrect_length":"Lignes de longueur incorrecte", 
"tournee.text.error.wrongData":"Donnée erronée", 
"tournee.text.error.no_line_id":"Id de ligne manquant", 
"tournee.text.error.silex_format_error":"Erreur de format Silex", 
"tournee .text.error.last_try":"Dernière tentative : {{time}}", 
"tournee.text.export.csv.config.not_set":"Les configurations d'export CSV ne sont pas correctement renseignées", 
"tournee.text.import.csv.config.not_set":"Les configurations d'import CSV ne sont pas correctement renseignées", 
"galery.title":"Photo", 
"galery.title_plural":"Photos", 
"galery.others":"autres...", 
"galery.picture_name":"Nom de la photo", 
"galery.fold":"replier", 
} 
}; 
