import React, { Component, ComponentType } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import moment from "moment";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import intervalActions from "../_actions/interval.actions";
import { compose } from "redux";

interface Props {
  dateMin: any;
  dateMax: any;
  zoom?: any;
  handleChange: Function;
  t: Function;
  dispatch: Function;
  getDates: Function;
  unset: any;
  interval: any;
}

interface State {
  dateDeb: any;
  dateFin: any;
  defaultDateDeb: any;
  defaultDateFin: any;
  zoom: string;
}
class BestDateComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dateDeb: moment(props.dateMin).format("YYYY-MM-DD"),
      dateFin: moment(props.dateMax).format("YYYY-MM-DD"),
      defaultDateDeb: moment(props.dateMin).format("YYYY-MM-DD"),
      defaultDateFin: moment(props.dateMax).format("YYYY-MM-DD"),
      zoom: props.zoom,
    };
    this.handleDateDeb = this.handleDateDeb.bind(this);
    this.handleDateFin = this.handleDateFin.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
  }

  componentDidMount() {
    const { dispatch, interval, getDates } = this.props;
    const { dateDeb, dateFin, zoom } = this.state;
    if (!interval.current) {
      dispatch(intervalActions.updateInterval(dateDeb, dateFin, zoom));
    }
    if (getDates) {
      getDates(dateDeb, dateFin);
    }
  }

  getDerivedStateFromProps() {
    const { dateMin, dateMax } = this.props;
    const { dateDeb, dateFin } = this.state;
    if (
      moment(dateMin).format("YYYY-MM-DD") !== dateDeb ||
      moment(dateMax).format("YYYY-MM-DD") !== dateFin
    ) {
      this.setState({
        dateDeb: moment(dateMin).format("YYYY-MM-DD"),
        dateFin: moment(dateMax).format("YYYY-MM-DD"),
      });
    }
  }

  handleDateDeb(e: any) {
    const { handleChange, dispatch, getDates } = this.props;
    const { dateFin, zoom } = this.state;

    this.setState({
      dateDeb: moment(e.target.value).format("YYYY-MM-DD"),
    });
    handleChange(e.target.value, "dateMin");
    dispatch(intervalActions.updateInterval(e.target.value, dateFin, zoom));
    if (getDates) {
      getDates(moment(e.target.value).format("YYYY-MM-DD"), dateFin);
    }
  }

  handleDateFin(e: any) {
    const { handleChange, dispatch, getDates } = this.props;
    const { dateDeb, zoom } = this.state;

    this.setState({
      dateFin: moment(e.target.value).format("YYYY-MM-DD"),
    });
    handleChange(e.target.value, "dateMax");
    dispatch(intervalActions.updateInterval(dateDeb, e.target.value, zoom));
    if (getDates) {
      getDates(dateDeb, moment(e.target.value).format("YYYY-MM-DD"));
    }
  }

  handleZoom(e: any) {
    const { handleChange, dispatch } = this.props;
    const { dateDeb, dateFin } = this.state;
    handleChange(e.target.value, "zoom");
    this.setState({ zoom: e.target.value });
    dispatch(intervalActions.updateInterval(dateDeb, dateFin, e.target.value));
  }

  render() {
    const { dateDeb, dateFin, zoom } = this.state;
    const { t } = this.props;
    return (
      <div className="best-date-component flex-box">
        <FormGroup className="c32">
          <Label for="date-debut">
            {t("best_date_component.label.start_date")}
          </Label>
          <Input
            type="date"
            name="date"
            id="date-debut"
            onInput={this.handleDateDeb}
            value={dateDeb}
            max={dateFin}
          />
        </FormGroup>
        <FormGroup className="c32">
          <Label for="date-fin">
            {t("best_date_component.label.end_date")}
          </Label>
          <Input
            type="date"
            name="date"
            id="date-fin"
            onChange={this.handleDateFin}
            value={dateFin}
            min={dateDeb}
          />
        </FormGroup>
        <FormGroup className="c32">
          <Label for="date-zoom">{t("best_date_component.label.zoom")}</Label>
          <Input
            type="select"
            name="select"
            id="exampleSelect"
            onChange={this.handleZoom}
            value={zoom}
          >
            <option value="Hour">Heure</option>
            <option value="Day">
              {t("best_date_component.zoom_option.day")}
            </option>
            <option value="Week">
              {t("best_date_component.zoom_option.week")}
            </option>
            <option value="Month">
              {t("best_date_component.zoom_option.month")}
            </option>
            <option value="Year">
              {t("best_date_component.zoom_option.year")}
            </option>
          </Input>{" "}
        </FormGroup>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { interval } = state;
  return {
    interval,
  };
}

const connectedDateComponent = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(BestDateComponent);
const tr = withTranslation()(connectedDateComponent);
export default tr;
