import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import DownloadArrow from "../SvgComponents/DownloadArrow";
import FileDropZone from "../_components/FileDropZone";
import { Button, FormGroup } from "reactstrap";
import { TFunction } from "react-i18next";
import { Checkbox, FormControlLabel, FormLabel } from "@material-ui/core";
import { History } from "history";
import { importActions } from "../_actions";

const defaultSelectedLinesValue = [
  true,
  true,
  false,
  true,
  false,
  true,
  true,
  true,
];
const emptySelectedLinesValue = new Array<boolean>(8).fill(false);
const fullSelectedLineValue = new Array<boolean>(8).fill(true);

const ImportFileTab: React.FC<{
  dispatch: Function;
  gestionnaire: any;
  history: History;
  imports: any;
  rndTemplate: any;
  setIsSilexImport: Dispatch<SetStateAction<boolean>>;
  setLoadingMessage: Dispatch<SetStateAction<string>>;
  t: TFunction<"translation", undefined>;
  tournee: any;
  user: any;
}> = ({
  dispatch,
  gestionnaire,
  history,
  imports,
  rndTemplate,
  setIsSilexImport,
  setLoadingMessage,
  t,
  tournee,
  user,
}) => {
  const [silexFile, setSilexFile] = useState<File>();
  const [selectedLines, setSelectedLines] = useState<boolean[]>(
    defaultSelectedLinesValue
  );
  const [stateTimer, setStateTimer] = useState<NodeJS.Timeout>();
  const admin = user.role.name === "DIOPTASE";
  const extensions = ".dat, .rtr, .txt";
  const csvExtensions = ", .csv, .xlsx, .xls";

  const handleLineChoice = (line: number, newState: boolean) => {
    const targetArray = [...selectedLines];
    switch (line) {
      case 0:
      case 1:
        targetArray[0] = newState;
        targetArray[1] = newState;
        break;
      case 2:
        targetArray[2] = newState;
        if (!newState) {
          targetArray[4] = newState;
        }
        break;
      case 4:
        targetArray[4] = newState;
        if (newState) {
          targetArray[2] = newState;
        }
        break;
      case 5:
      case 6:
        targetArray[5] = newState;
        targetArray[6] = newState;
        break;
      default:
        targetArray[line] = newState;
    }
    setSelectedLines(targetArray);
  };

  const validateSilexFile = () => {
    if (silexFile) {
      const file = new FormData();
      file.append("file", silexFile);
      setLoadingMessage(t("import_silex.loading_band.silex_file_loading"));
      setIsSilexImport(true);
      dispatch(
        importActions.reworkedImport(file, selectedLines, gestionnaire.id)
      );
      setStateTimer(setTimeout(getImportState, 5000));
    }
  };

  const getImportState = () => {
    dispatch(importActions.getImportState());
    setStateTimer(setTimeout(getImportState, 2000));
  };

  const isButtonSelected = (selectedLinesCheck: boolean[]) => {
    return selectedLinesCheck.every(
      (value: boolean, index: number) => value === selectedLines[index]
    );
  };

  useEffect(() => {
    if (imports && (imports.silex || imports.error || imports.errorSilex)) {
      history.push(`silex/import`, { file: silexFile, adminimport: admin });
    }
  }, [imports]);

  useEffect(() => {
    if (stateTimer != null && !imports.loading) {
      setIsSilexImport(false);
      clearTimeout(stateTimer);
    }
  }, [imports.loading]);

  return (
    <>
      <DownloadArrow
        width="40px"
        fill="#ACABAB"
        className="dl-icon import-side-icon"
      />
      <h3 className="import-side-title">{t("all.text.select_file")}</h3>
      <FileDropZone
        acceptedFilesFormat={
          tournee.importOption?.import_round_csv_enabled
            ? extensions + csvExtensions
            : extensions
        }
        file={silexFile}
        height={admin ? "150px" : "265px"}
        setFile={setSilexFile}
      />
      {admin && (
        <div className="admin-part">
          <FormGroup component="fieldset" className="admin-checkbox-container">
            <FormLabel component="legend">
              Choisir le type d'import{" "}
              <span className="admin-label-decoration">admin</span> :
            </FormLabel>
            {selectedLines.map((value: boolean, type: number) => (
              <FormControlLabel
                key={type}
                control={
                  <Checkbox
                    checked={value}
                    name={`T${type}`}
                    color="primary"
                    className="admin-checkbox"
                    onClick={() => handleLineChoice(type, !value)}
                    disabled={!admin}
                  />
                }
                label={`Ligne T${type}`}
              />
            ))}
          </FormGroup>
          <div className="admin-selection-button-container">
            <Button
              className={
                "import-silex-selection-button" +
                (isButtonSelected(fullSelectedLineValue) ? " selected" : "")
              }
              onClick={() => setSelectedLines(fullSelectedLineValue)}
            >
              {t("all.text.select_all")}
            </Button>
            <Button
              className={
                "import-silex-selection-button" +
                (isButtonSelected(emptySelectedLinesValue) ? " selected" : "")
              }
              onClick={() => setSelectedLines(emptySelectedLinesValue)}
            >
              {t("all.text.deselect_all")}
            </Button>
            <Button
              className={
                "import-silex-selection-button" +
                (isButtonSelected(defaultSelectedLinesValue) ? " selected" : "")
              }
              onClick={() => setSelectedLines(defaultSelectedLinesValue)}
            >
              {t("remotereading.text.select_default")}
            </Button>
          </div>
        </div>
      )}
      <Button
        id="access-button"
        className={
          "col-right footer-content tournee-import-submit-button" +
          (silexFile ? " visible" : "")
        }
        onClick={validateSilexFile}
        disabled={imports.loading || rndTemplate.loading}
      >
        {t("all.button.validate")}
      </Button>
    </>
  );
};

export default ImportFileTab;
