import React from "react";
import { Chart } from "../Widget";
import HistoriqueVert from "../SvgComponents/HistoriqueVert";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import { locales } from "../_interfaces/reducers";
import { chart2table } from "../_helpers";
import ChartPdf from "./ChartPdf";
import AlarmChartPdf from "./AlarmChartPdf";

interface Props {
  data: any;
  zoom: any;
  graphType: string;
  isMeter: boolean;
  name: string;
  locales: locales;
  startDate: string;
  endDate: string;
  t: any;
}

/**
 *
 *
 * @method formatDate
 * @param {any} date Date
 * @returns {string} La date formatée
 */
function formatDate(date: any) {
  return moment(date, `YYYY-MM-DD ${moment.HTML5_FMT.TIME_SECONDS}`).format(
    "DD/MM/YYYY"
  );
}

/**
 * Composant affichant la fiche imprimable
 *
 * @method PrintableSourceSheet
 * @param {Props} props Propriétés
 * @returns {JSX} Le composant
 */
export function PrintableSourceSheet(props: Props) {
  const {
    data,
    zoom,
    graphType,
    isMeter,
    name,
    locales,
    startDate,
    endDate,
    t,
  } = props;

  const styles = {
    info: {
      background: "#ffffff",
      padding: "20px",
      borderRadius: "15px",
      border: "1px solid #ccc",
    },
    h3: {
      marginRight: "-22px",
      paddingRight: "50px",
      paddingBottom: "5px",
      marginTop: "10px",
      textAlign: "center",
      verticalAlign: "middle",
    },
    h2: {
      borderLeft: "3px solid #31c6b3",
      marginLeft: "-22px",
      paddingLeft: "50px",
      paddingBottom: "5px",
      color: "#2c303b",
    },
    span: {
      marginLeft: "-30px",
      marginRight: "15px",
    },
    chartContainer: {
      width: "100%",
      marginTop: "15px",
    },
  };

  const tableInfo: any = {};

  if (data.index && data.index.labels && data.index.values) {
    tableInfo.index = chart2table(data.index, "index", t);
  }
  if (data.conso && data.conso.labels && data.conso.values) {
    tableInfo.conso = chart2table(data.conso, "conso", t);
  }

  if (data.alarm && data.alarm.labels && data.alarm.values) {
    tableInfo.alarm = chart2table(data.alarm, "alarm", t);
  }
  /*
  .columns.forEach((el:any) => {
      el.text = t(el.text)
      return el
    });
   */
  let start = formatDate(startDate);
  let end = formatDate(endDate);
  return (
    <div id="printable" style={{ height: "100%", width: "100%" }}>
      <div className="table-info-container" style={styles.info}>
        {tableInfo.index && tableInfo.index.data.length > 0 && (
          <div className="print" id={"print-1"}>
            <h2 style={styles.h2}>
              <span style={styles.span}>
                <HistoriqueVert height="1em" width="1em" fill="#31c6b3" />
              </span>
              {t("printable_source_sheet.text.x_data_from_x_to_x_zoom_x", {
                name: name,
                start: start,
                end: end,
                zoom: t(
                  `best_date_component.zoom_option.${(
                    zoom || ""
                  ).toLowerCase()}`
                ),
              })}
            </h2>{" "}
            <br />
            <h3 style={styles.h3}>{t("all.read_meter.read_history")}</h3>
            <div
              className="chart-container"
              style={styles.chartContainer}
              id={"print-table"}
            >
              <ChartPdf
                labels={data.index.labels}
                data={data.index.values}
                unit={t("all.meter.index")}
              />
            </div>
            <BootstrapTable
              keyField="id"
              data={tableInfo.index.data}
              columns={tableInfo.index.columns}
              bootstrap4
              bordered
              condensed
              hover
              striped
            />
          </div>
        )}
        {isMeter && tableInfo.conso && tableInfo.conso.data.length > 0 && (
          <div className="print" id={"print-2"}>
            <br />
            <h3 style={styles.h3}>{t("all.read_meter.cons_history")}</h3>
            <div style={styles.chartContainer} className="chart-container">
              <ChartPdf
                labels={data.conso.labels}
                data={data.conso.values}
                unit={t("all.read_meter.cons")}
              />
            </div>
            <BootstrapTable
              keyField="id"
              data={tableInfo.conso.data}
              columns={tableInfo.conso.columns}
              bootstrap4
              bordered
              condensed
              hover
              striped
            />
          </div>
        )}
        {tableInfo.alarm && tableInfo.alarm.data.length > 0 && (
          <div className="print" id={"print-3"}>
            <h3 style={styles.h3}>
              {t("all.alarm_meter.alarm_history_plural")}
            </h3>{" "}
            <br />
            <div style={styles.chartContainer} className="chart-container">
              <AlarmChartPdf
                labels={data.alarm.labels}
                data={data.alarm.values}
                formatTime={zoom}
                type="StackedHistogram"
              />
            </div>
            <BootstrapTable
              keyField="id"
              data={tableInfo.alarm.data}
              columns={tableInfo.alarm.columns}
              bootstrap4
              bordered
              condensed
              hover
              striped
            />
          </div>
        )}
      </div>
    </div>
  );
}
