import React, { Component, Fragment } from "react";
import _debounce from "lodash.debounce";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Row, UncontrolledAlert } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import _ from "lodash";
import Sticky from "react-sticky-fill";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { history, recursiveTranslate, REGEXVIRTUAL } from "../_helpers";
import listOperator from "../_shared/OperatorList";
import { QueryBuilder } from "../QueryBuilder";
import {
  alertActions,
  autocompleteActions,
  localeActions,
  locationActions,
  masksActions,
  pdiActions,
  vmeterActions,
} from "../_actions";
import CompteurVert from "../SvgComponents/CompteurVert";
import ListTools from "../List/ListTools";
import { locales, locations, vmeters } from "../_interfaces/reducers";
import { VirtualMeterGeneral } from "../_entities/virtual";
import { User } from "../_entities/user";
import ErrorBand from "../Bands/Error";
import MaskAlarmCompenent from "../MaskBar/MaskAlarmCompenent";
import BestDateComponent from "./BestDateComponent";
import SelectionComponent from "./SelectionComponent";
import userActions from "../_actions/user.actions";
import Loading from "../_animations/Loading";
import { translateBasicAlarm } from "../_helpers/locale-helpers";
import locale from "../_shared/Locale.json";
import {
  getFiltersRequest,
  getLightFilters,
} from "../QueryBuilder/FilterLSManager";
import WarningBand from "../Bands/Warning";
import RemoteTable from "../RemoteTable/RemoteTable";

interface Props {
  pdis: any;
  location: any;
  user: User;
  match: any;
  locales: locales;
  dispatch: Function;
  vmeters: vmeters;
  locations: locations;
  alert: any;
  mask: any;
  users: any;
  t: Function;
}

interface State {
  meterList: Array<any>;
  metersToRemove: Array<any>;
  sourceColumns: Array<any>;
  name: string;
  availableMeters: Array<VirtualMeterGeneral>;
  initialized: boolean;
  initializedVMeters: boolean;
  saved: boolean;
  vmeter: any;
  options: any;
  mtrKey: number;
  dateMin: string;
  dateMax: string;
  zoom: string;
  listAlarmActive: any;
  initializedMask: any;
  mask: any;
  period: any;
  existingMeters: any;
  listMeter: any;
}

const itemList = ["Selection", "Filtres", "Tous"];

/**
 * Gère l'édition et création de compteur virtuel
 *
 * @class AddVirtualMeter
 * @extends Component
 */
class AddMask extends Component<Props, any> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (!state.initialized) {
      const allMeters: Array<any> = [];

      let sourceColumns: Array<any> = [];

      let availableMeters: any = [];

      let meterList: Array<any> = [];

      let name = state.name;

      const initialized: boolean = state.initialized;

      let initializedMask: boolean = state.initializedMask;

      let mask = state.mask;

      let listAlarmActive = state.listAlarmActive;

      const dateMin = state.dateMin;

      const dateMax = state.dateMax;

      if (props.pdis && props.pdis.items && props.pdis.items.length > 0) {
        const translatedKeys = recursiveTranslate(
          "fr",
          "pdi",
          locale.fr.pdi,
          props.locales.locale
        );
        sourceColumns = translatedKeys.map((champ: any) => {
          const regex = new RegExp("date");
          const elem: any = {
            dataField: champ.path,
            text: champ.value,
            sort: true,
            classes:
              champ.path === "address.concatAdress" ? "" : "crystalList-column",
            filter: textFilter({
              placeholder: champ.value,
            }),
          };
          if (regex.test(champ.path)) {
            elem.sortFunc = ListTools.sortTableDates;
          }
          return elem;
        });

        if (
          !initializedMask &&
          props.mask &&
          props.mask.fetchedMask &&
          props.mask.fetchedMask.listPdi
        ) {
          mask = props.mask.fetchedMask;
          availableMeters = [];
          name = props.mask.fetchedMask.name;
          listAlarmActive = props.mask.fetchedMask.listAlarm.map(
            (a: any) => a.id
          );
          allMeters.forEach((it: any) => {
            const existing = props.mask.fetchedMask.listPdi.find(
              (mtr: any) => it.id === mtr.id
            );
            if (existing) {
              meterList.push(it);
            } else {
              availableMeters.push(it);
            }
          });
          initializedMask = true;
        } else {
          meterList = state.meterList;
        }
        const meterListExisting = {};
        if (
          !_.get(state, "existingMeters") &&
          _.size(_.get(props.mask, "fetchedMask.listPdi")) > 0
        ) {
          _.get(props.mask, "fetchedMask.listPdi").forEach(
            (el) => (meterListExisting[el.id] = el.serial)
          );
        }

        return {
          meterList,
          sourceColumns,
          name,
          availableMeters,
          initialized,
          initializedMask,
          listAlarmActive,
          mask,
          dateMin,
          dateMax,
          listMeter:
            _.get(props.mask, "fetchedMask") && !_.get(state, "existingMeters")
              ? meterListExisting
              : state.listMeter,
          existingMeters:
            _.get(props.mask, "fetchedMask") && !_.get(state, "existingMeters")
              ? meterListExisting
              : state.existingMeters,
        };
      }
      if (props.mask && props.mask.fetchedMask && !state.initializedMask) {
        const meterList: Array<any> = [];
        const mask = props.mask.fetchedMask;
        const availableMeters: any[] = [];
        const name = props.mask.fetchedMask.name;
        const listAlarmActive = props.mask.fetchedMask.listAlarm.map(
          (a: any) => a.id
        );
        const dateMin = moment
          .utc(props.mask.fetchedMask.startDate)
          .toISOString(false);
        const dateMax = moment
          .utc(props.mask.fetchedMask.endDate)
          .endOf("day")
          .add(-1, "second")
          .toISOString(false);
        state.availableMeters.forEach((it: any) => {
          const existing =
            props.mask.fetchedMask.listPdi &&
            props.mask.fetchedMask.listPdi.find((mtr: any) => it.id === mtr.id);
          if (existing) {
            meterList.push(it);
          } else {
            availableMeters.push(it);
          }
        });
        let isSelect = "Selection";
        if (props.mask.fetchedMask.filterClause) {
          isSelect = "Filtres";
        }
        if (
          !props.mask.fetchedMask.filterClause &&
          !props.mask.fetchedMask.listPdi
        ) {
          isSelect = "Tous";
        }
        return {
          meterList,
          availableMeters,
          initializedMask: true,
          listAlarmActive,
          name,
          mask,
          dateMin,
          dateMax,
          isSelect,
        };
      }
      return state;
    }
  }

  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);

    const { t } = this.props;

    const sourceColumns: any[] = ListTools.getDefaultColumns(
      ListTools.typeOfList.Mask
    ).map((champ: any) => {
      return {
        dataField: champ,
        text: t(`columns.${champ}`),
        sort: true,
        classes: champ.includes("address") ? "" : "crystalList-column",
        //        formatter: _.get(ListTools.formatField, champ),
        filter: textFilter({
          placeholder: t(`columns.${champ}`),
          delay: 500,
        }),
      };
    });

    this.state = {
      meterList: [],
      sourceColumns: [],
      listMeter: {},
      availableMeters: [],
      displayingColumns: sourceColumns,
      displayNoMeterSelected: false,
      displayNoAlarmSelected: false,
      tableOptions: {
        filters: {},
        page: 1,
        sizePerPage: 10,
        sortField: null,
        sortOrder: null,
      },
      name: "",
      initialized: false,
      initializedVMeters: false,
      listAlarmActive: [],
      saved: false,
      vmeter: {},
      options: {
        hideSizePerPage: true,
      },
      metersToRemove: [],
      mtrKey: Math.floor(Math.random() * Math.floor(1024)),
      dateMin: moment().utc().startOf("day").toISOString(false),
      dateMax: moment().utc().endOf("day").add(-1, "second").toISOString(false),
      period: "Day",
      zoom: "day",
      isSelect: "Selection",
      ruleList: [],
    };
    this.updateActive = this.updateActive.bind(this);
    this.getQueryBuilder = this.getQueryBuilder.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.rowStyle = this.rowStyle.bind(this);
    this.clickOnRow = this.clickOnRow.bind(this);
  }

  receiveData = (dataFiltered: any, ruleList: any) => {
    const { dispatch, match } = this.props;
    const { locationId } = match.params;
    const keyStorage = `filter-${ListTools.typeOfList.Mask}-${locationId}`;
    if (!_.isEqual(this.state.ruleList, getLightFilters(keyStorage))) {
      this.setState({ ruleList: getLightFilters(keyStorage) });
    }
  };

  /**
   * Teste l'existence du nom de compteur virtuel
   *
   * @method validateExists
   * @param {string} value Valeur
   * @param {any} ctx Contexte
   * @param {any} input Input
   * @param {any} cb Callback
   * @returns {any} Le retour du test
   */
  validateExists = _debounce((value, ctx, input, cb) => {
    const { match, vmeters } = this.props;
    const { fetchedVMeter } = vmeters;
    const { locationId } = match.params;
    let fetchedsuggestions = [];
    value = value.startsWith("V-") ? value : `V-${value.replace(" ", "_")}`;
    const conditions = [
      {
        conditionTitle: "MeterSerialNumber",
        conditionType: "AutoCompleteString",
        conditionValue: value,
      },
      {
        conditionTitle: "locationId",
        conditionType: "Numeric",
        conditionValue: locationId,
      },
    ];

    if (value === "") {
      cb(true);
    } else {
      try {
        autocompleteActions
          .autocomplete(conditions)
          .then((result: any) => {
            fetchedsuggestions = result && Array.isArray(result) ? result : [];
            const vmeter = fetchedVMeter && fetchedVMeter.general;
            if (fetchedsuggestions && fetchedsuggestions.length > 0) {
              const exists = fetchedsuggestions.find(
                (suggestion: any) => suggestion === value
              );
              if (exists) {
                if (vmeter !== undefined && exists === vmeter.name) {
                  cb(true);
                } else {
                  cb("Ce nom existe déjà");
                }
              }
            }
            cb(true);
          })
          .catch((error: any) => {
            console.error(error);
            cb("Une erreur est survenue");
          });
      } catch (error) {
        console.error(error);
        cb("Une erreur est survenue");
      }
    }
  }, 300);

  rowStyle(row) {
    const { listMeter, existingMeters } = this.state;
    const rowId = _.get(row, "id");
    const isSelected = rowId in listMeter;
    const linked = rowId in (existingMeters || {});
    if (isSelected && linked) {
      return { backgroundColor: "#b9cfe4" };
    }
    if (isSelected) {
      return { backgroundColor: "#bddbd1" };
    }
    if (linked) {
      return { backgroundColor: "#f4c5bb" };
    }
    return {};
  }

  clickOnRow(e: Object, row: any) {
    const copyListMeter = _.cloneDeep(this.state.listMeter);
    const selectedMeterId = _.parseInt(_.get(row, "id"));
    if (selectedMeterId in copyListMeter) {
      delete copyListMeter[selectedMeterId];
    } else {
      copyListMeter[selectedMeterId] = _.get(row, "meter.serial");
    }
    this.setState({ listMeter: copyListMeter });
  }

  handleValidSubmit = (event: Object, values: any) => {
    const { dispatch, mask, match, locations } = this.props;
    const { locationId } = match.params;
    const {
      meterList,
      name,
      listAlarmActive,
      maskEdited,
      metersToRemove,
      dateMin,
      dateMax,
      isSelect,
      ruleList,
      listMeter,
    } = this.state;
    dispatch(alertActions.clear());

    if (
      (_.size(listMeter) === 0 && isSelect === "Selection") ||
      _.size(listAlarmActive) === 0
    ) {
      this.setState({
        displayNoMeterSelected:
          _.size(listMeter) === 0 && isSelect === "Selection",
        displayNoAlarmSelected: _.size(listAlarmActive) === 0,
      });
      return;
    }

    const keyStorage = `filter-${ListTools.typeOfList.Mask}-${locationId}`;

    if (mask && mask.fetchedMask) {
      dispatch(
        masksActions.updateMask(
          maskEdited,
          name,
          dateMin,
          dateMax,
          _.keys(this.state.listMeter).map((meter: string) => Number(meter)),
          isSelect === "Filtres" ? getFiltersRequest(keyStorage) : [],
          listAlarmActive,
          locations.fetchedLocation.code,
          isSelect === "Tous"
            ? "all"
            : isSelect === "Selection"
            ? "select"
            : "filter"
        )
      );
    } else {
      switch (isSelect) {
        case "Selection":
          dispatch(
            masksActions.createMask(
              name,
              dateMin,
              dateMax,
              _.keys(this.state.listMeter).map((meter: string) =>
                Number(meter)
              ),
              [],
              listAlarmActive,
              locations.fetchedLocation.code,
              "select",
              locationId
            )
          );
          break;
        case "Filtres":
          dispatch(
            masksActions.createMask(
              name,
              dateMin,
              dateMax,
              [],
              getFiltersRequest(keyStorage),
              listAlarmActive,
              locations.fetchedLocation.code,
              "filter",
              locationId
            )
          );
          break;
        case "Tous":
          dispatch(
            masksActions.createMask(
              name,
              dateMin,
              dateMax,
              [],
              [],
              listAlarmActive,
              locations.fetchedLocation.code,
              "all",
              locationId
            )
          );
          break;
        default:
      }
    }
    this.setState({ saved: true });
  };

  /**
   * Comportement en cas d'echec de validation du formulaire
   *
   * @method handleInvalidSubmit
   * @param {Object} event Evènement
   * @param {Object} errors Erreurs
   * @param {any} values Valeurs du formulaire
   */
  handleInvalidSubmit = (event: Object, errors: Object, values: any) => {
    this.setState({
      name: values.virtualName,
    });
  };

  /**
   * Construit toute la partie haute du template
   *
   * @method getHeaderOfList
   * @returns {JSX} La partie du template
   */
  getHeaderOfList = () => {
    const { pdis, match, t } = this.props;
    const { meterList, name, vmeter } = this.state;

    return (
      <Fragment>
        <Sticky style={{ top: "90px", zIndex: "190" }}>
          <div
            className="presentation-container virtual-meter-info"
            style={{ border: "0.5px solid #ccc" }}
          >
            <div className="presentation-header">
              <span className="presentation-title">
                {t("all.mask.mask_edition")}
              </span>
              <span className="presentation-main-title">
                {Object.keys(vmeter).length > 0
                  ? vmeter.general.name
                  : t("all.text.new")}
              </span>
            </div>
            <div className="presentation-body" style={{ background: "none" }}>
              <Col md="12">
                <AvForm
                  onValidSubmit={this.handleValidSubmit}
                  onInvalidSubmit={this.handleInvalidSubmit}
                >
                  <Row>
                    <Col md="4">
                      <AvField
                        name="virtualName"
                        placeholder={t("all.mask.mask_name")}
                        type="text"
                        validate={{
                          pattern: { value: REGEXVIRTUAL },
                          maxLength: {
                            value: 100,
                            errorMessage:
                              "Le nom ne doit pas faire plus de 100 caractères",
                          },
                          async: this.validateExists,
                        }}
                        required
                        errorMessage={t(
                          "all.text.required_field_character_condition"
                        )}
                        onChange={(event: any) => {
                          this.setState({
                            name: event.target.value.replace(" ", "_"),
                          });
                        }}
                        value={name}
                      />
                    </Col>
                    <Col md={{ size: 4, offset: 4 }}>
                      <div className="float-right">
                        <Button
                          type="button"
                          className="danger"
                          onClick={this.cancelEdition}
                        >
                          {t("all.button.cancel")}
                        </Button>
                        <Button
                          disabled={name.length === 0}
                          type="submit"
                          style={{ marginLeft: "5px" }}
                        >
                          {t("all.button.register")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
              <div className="clearfix" />
            </div>
          </div>
        </Sticky>
      </Fragment>
    );
  };

  getQueryBuilder() {
    const { pdis, match, mask, locales, t } = this.props;
    const { locationId } = match.params;
    const { sourceColumns } = this.state;
    const listFields = sourceColumns
      .filter((col: any) => "classes" in col && col.dataField !== "locationId")
      .map((col) => {
        let options: any[] = [];
        // S'il s'agit d'un type qui ne peut pas être determiné en regardant le type de la valeur, renseigné dans la liste specificFieldType
        const typeData = ListTools.findSpecificType(col.dataField);
        if (typeData === ListTools.specificType.selectAlarmTypeBasic) {
          options = translateBasicAlarm("fr", locales.locale).sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label === b.label) return 0;
            return 1;
          });
        }
        return {
          label: t(`columns.${col.dataField}`),
          value: col.dataField,
          type: typeData,
          opts: options.length > 0 ? options : undefined,
          filter: textFilter({ placeholder: col.text }),
        };
      })
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label === b.label) return 0;
        return 1;
      });
    return (
      (match.path.includes("add") || _.get(mask, "fetchedMask")) && (
        <div className="filter-container">
          <QueryBuilder
            sendListFiltered={this.receiveData}
            listData={pdis.items}
            listOperator={listOperator}
            listFilters={listFields}
            idContext={ListTools.typeOfList.Mask}
            save={false}
            idSite={locationId}
          />
        </div>
      )
    );
  }

  /**
   * Gère l'annulation de l'édition
   *
   * @method cancelEdition
   */
  cancelEdition = (e: any) => {
    history.goBack();
  };

  /**
   * @method componentDidUpdate
   * @param {Props} prevProps Props précédentes
   * @param {State} prevState State précédent
   * @param {any} snapshot Snapshot
   */
  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    const { saved, availableMeters } = this.state;
    const { mask, match, locations, users, user } = this.props;
    const { locationId } = match.params;
    if (saved && mask && mask.newMask) {
      const link = `/locations/${locationId}/mask/info?id=${mask.newMask.id}`;
      history.push(link);
    }
    const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN"];
    if (
      users.fetchedUser &&
      !roleList.includes(user.role.name) &&
      !locations.fetchedLocation.tournee && // CETTE LIGNE ALEXIS
      !users.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find(
            (permission: any) => permission.name === "edit.maskAlarm"
          ) && el.locationCode === locations.fetchedLocation.code
      )
    ) {
      history.push("/forbidden");
    }
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { dispatch, match, location, user } = this.props;
    const { locationId } = match.params;
    const maskId = location.search;

    dispatch(localeActions.load());
    dispatch(locationActions.getInfos(locationId));
    dispatch(masksActions.getAvailableAlarms());
    dispatch(userActions.get(user.id));

    if (maskId !== undefined && maskId.length > 0) {
      this.setState({ maskEdited: maskId.replace("?id=", "") });
      dispatch(masksActions.getMask(maskId.replace("?id=", "")));
    }

    const keyStorage = `filter-${ListTools.typeOfList.Mask}-${locationId}`;
    this.setState({
      ruleList: getLightFilters(keyStorage),
    });
    dispatch(
      pdiActions.getRemotePdi(
        locationId,
        getFiltersRequest(keyStorage),
        this.state.tableOptions.sizePerPage,
        this.state.tableOptions.page,
        {
          field: this.state.tableOptions.sortField,
          way: this.state.tableOptions.sortOrder,
        },
        this.state.tableOptions.filters,
        null
      )
    );
  }

  /**
   * @method componentWillUnmount
   */
  componentWillUnmount() {
    const {
      dispatch,
      match: {
        params: { locationId },
      },
    } = this.props;
    dispatch(pdiActions.clear());
    dispatch(vmeterActions.clear());
    dispatch(alertActions.clear());
    const keyStorage = `filter-${ListTools.typeOfList.Mask}-${locationId}`;
    localStorage.removeItem(keyStorage);
  }

  updateActive(listAlarmActive: Array<any>) {
    this.setState({ listAlarmActive });
  }

  handleDate(dateMin: any, dateMax: any) {
    this.setState({
      dateMin,
      dateMax,
    });
  }

  handleZoom(zoom: string) {
    this.setState({
      zoom,
    });
  }

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const {
      pdis,
      alert,
      match: {
        params: { locationId },
      },
      t,
    } = this.props;
    const {
      availableMeters,
      listAlarmActive,
      dateMin,
      dateMax,
      zoom,
      isSelect,
      existingMeters,
      displayingColumns,
      listMeter,
      displayNoMeterSelected,
      displayNoAlarmSelected,
    } = this.state;
    const listAssociated = _.uniqBy(
      _.concat(
        _.entries(existingMeters).map((el) => ({
          id: el[0],
          "meter.serial": el[1],
        })),
        _.entries(listMeter).map((el) => ({ id: el[0], "meter.serial": el[1] }))
      ),
      "id"
    ).map((el) => ({
      ...el,
      isSelected: el.id in listMeter,
      linked: el.id in (existingMeters || {}),
    }));

    const displayListSelectedColumns = displayingColumns
      .filter((el) => el.dataField.includes("meter.serial"))
      .map((el) => ({
        ...el,
        text: t(`columns.${el.dataField}`),
        formatter: ListTools.formatSerialWithAction,
      }));

    let explaination = "";
    if (pdis.items && pdis.items.length) {
      switch (isSelect) {
        case itemList[0]:
          explaination = t("all.meter.selection_explanation");
          break;
        case itemList[1]:
          explaination = t("all.text.filter_explanation");
          break;
        case itemList[2]:
          explaination = t("all.meter.confirm_x_selection_explanation", {
            count: pdis.countTotal,
          });
      }
    }

    return (
      <>
        {pdis && !pdis.items && (
          <Loading message="Chargement de la création de masque" />
        )}
        <div
          className="col-md-12"
          style={{ overflowX: "-moz-hidden-unscrollable" }}
        >
          {pdis && pdis.error && <ErrorBand message={pdis.error} />}
          {pdis && pdis.items && (
            <div>
              {alert.message && (
                <UncontrolledAlert
                  className={`alert ${alert.type}`}
                  toggle={() => {
                    const { dispatch } = this.props;
                    dispatch(alertActions.clear());
                  }}
                >
                  {alert.message}
                </UncontrolledAlert>
              )}
              {this.getHeaderOfList()}
              <div className="sidebar-mask-alarm">
                <BestDateComponent
                  dateMin={dateMin}
                  dateMax={dateMax}
                  handleDate={this.handleDate}
                  noZoom
                  zoom={zoom}
                  handleZoom={this.handleZoom}
                />
              </div>

              <MaskAlarmCompenent
                updateActive={this.updateActive}
                alarmSelected={listAlarmActive}
                displayNoAlarmSelected={displayNoAlarmSelected}
              />
              <div className="selected-content">
                <SelectionComponent
                  handleMod={(newMode: string) => {
                    if (newMode === "Filtres") {
                      this.setState({
                        meterList: availableMeters,
                        isSelect: newMode,
                      });
                    } else {
                      this.setState({
                        meterList: [],
                        isSelect: newMode,
                      });
                    }
                  }}
                  activedMod={isSelect}
                  totalMeters={pdis.items.length}
                />
                <div className="select-option">
                  <div className="preview">
                    <h4>{explaination}</h4>
                  </div>

                  {isSelect !== "Tous" && (
                    <>
                      {this.getQueryBuilder()}
                      <div style={{ marginTop: "10px" }}>
                        <Row
                          className="virtual-meter-editor"
                          style={{
                            justifyContent:
                              isSelect === "Filtres" ? "center" : "left",
                            marginBottom: "1vh",
                            padding: "15px",
                          }}
                        >
                          <div
                            style={{
                              maxWidth:
                                isSelect === "Filtres" ? "100%" : "66.66%",
                              width: "100%",
                            }}
                          >
                            <div className="table-info-container">
                              <h2>
                                <span>
                                  <CompteurVert
                                    height="1em"
                                    width="1em"
                                    stroke="#31c6b3"
                                    fill="white"
                                    strokeWidth="1"
                                  />
                                </span>
                                {pdis.count}{" "}
                                {t("all.meter.available_meter", {
                                  count: pdis.count,
                                })}
                              </h2>
                              <br />
                              <RemoteTable
                                keyStorage={`filter-${ListTools.typeOfList.Mask}-${locationId}`}
                                displayingColumns={displayingColumns}
                                rowStyle={
                                  isSelect === "Selection"
                                    ? (row) => this.rowStyle(row)
                                    : null
                                }
                                clickOnRow={this.clickOnRow}
                                ruleList={this.state.ruleList}
                              />
                            </div>
                          </div>
                          {isSelect !== "Filtres" && (
                            <Col md="4" style={{ paddingRight: 0 }}>
                              <div className="table-info-container">
                                <h2>
                                  <span>
                                    <CompteurVert
                                      height="1em"
                                      width="1em"
                                      stroke="#31c6b3"
                                      fill="white"
                                      strokeWidth="1"
                                    />
                                  </span>
                                  {_.size(listMeter)}{" "}
                                  {t(
                                    "all.meter.selected_meter_plural"
                                  ).toLowerCase()}
                                </h2>
                                <br />
                                <Fragment>
                                  {displayNoMeterSelected &&
                                    _.size(listMeter) === 0 && (
                                      <WarningBand
                                        message={t(
                                          "mask.text.select_at_least_once_meter"
                                        )}
                                      />
                                    )}
                                  {_.size(listAssociated) > 0 && (
                                    <BootstrapTable
                                      keyField="id"
                                      key="mtrassociated"
                                      rowClasses="clickable"
                                      data={listAssociated}
                                      bootstrap4
                                      bordered={false}
                                      columns={displayListSelectedColumns}
                                      rowEvents={{ onClick: this.clickOnRow }}
                                      hover
                                      filter={filterFactory()}
                                      headerClasses="crystalList-column"
                                      pagination={paginationFactory()}
                                      rowStyle={this.rowStyle}
                                    />
                                  )}
                                </Fragment>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const {
    authentication,
    pdis,
    locales,
    vmeters,
    locations,
    alert,
    mask,
    users,
  } = state;
  const { user } = authentication;

  return {
    user,
    pdis,
    locales,
    vmeters,
    locations,
    alert,
    mask,
    users,
  };
}

const mapping: any = connect(mapStateToProps)(AddMask);

const connectedAddMask = withRouter(mapping);
const tr = withTranslation()(connectedAddMask);
export default tr;
