import _ from "lodash";
import { authHeader, handleResponse } from "../_helpers";

const uri = "/api/pdi";

async function getAll(siteId: number) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const response = await fetch(
    `${uri}/list/location/${siteId}/`,
    requestOptions
  );
  return handleResponse(response);
}

async function getAllWithChildren(siteId: number, limit: number) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const getParam = limit ? `?limit=${limit}` : "";
  const response = await fetch(
    `${uri}/list/location/${siteId}/children${getParam}`,
    requestOptions
  );
  return handleResponse(response);
}

async function getAllWithChildrenWithRangeDate(siteId: number, endDate: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ end: endDate }),
  };
  const response = await fetch(
    `${uri}/list/location/${siteId}/children/rangeread`,
    requestOptions
  );
  return handleResponse(response);
}

async function getAllWithChildrenFilters(
  siteId: number,
  filterClause: Array<any>,
  limit: number
) {
  filterClause.forEach((e) => {
    delete e.filter.filter;
    if (_.isArray(e.userInput))
      e.userInput = _.join(
        e.userInput.map((el) => el.value),
        "|"
      );
  });
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(filterClause),
  };
  const getParam = limit ? `?limit=${limit}` : "";
  const response = await fetch(
    `${uri}/list/location/${siteId}/children/filters${getParam}`,
    requestOptions
  );
  return handleResponse(response);
}

async function getRemotePdi(
  siteId: number,
  filters: Array<any>,
  sizePerPage: number,
  currentPage: number,
  orderTable: any,
  fastFilters: any,
  columnsDisplay: any,
  ascMode: boolean,
  separatorWhereAnd: boolean
) {
  filters.forEach((e) => {
    delete e.filter.filter;
    if (_.isArray(e.userInput))
      e.userInput = _.join(
        e.userInput.map((el) => el.value),
        "|"
      );
    if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
  });
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      filters,
      sizePerPage,
      currentPage,
      orderTable: orderTable && [orderTable],
      fastFilters,
      columnsDisplay,
      separatorWhereAnd,
    }),
  };
  const response = await fetch(
    `${uri}/remotetable/location/${siteId}${ascMode ? "/asc" : ""}`,
    requestOptions
  );
  return handleResponse(response);
}

async function getRemotePdiCsv(
  siteId: number,
  filters: Array<any>,
  orderTable: any,
  fastFilters: any,
  columnsDisplay: any,
  extraTrad: any,
  optionOr: boolean
) {
  filters.forEach((e) => {
    if (e.filter && e.filter.filter) delete e.filter.filter;
    if (_.isArray(e.userInput))
      e.userInput = _.join(
        e.userInput.map((el) => el.value),
        "|"
      );
    if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
  });
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      filters,
      columnsDisplay,
      orderTable: orderTable && [orderTable],
      fastFilters,
      extraTrad,
      separatorWhereAnd: !optionOr,
    }),
  };
  const response = await fetch(
    `${uri}/remotetable/location/${siteId}/csv`,
    requestOptions
  );
  return handleResponse(response);
}

async function getRemotePdiCsvStatus(siteId: number) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const response = await fetch(
    `${uri}/remotetable/location/${siteId}/csv/status`,
    requestOptions
  );
  return handleResponse(response);
}

async function getRemotePdiDat(
  siteId: number,
  filters: Array<any>,
  orderTable: any,
  fastFilters: any,
  optionOr: boolean
) {
  filters.forEach((e) => {
    delete e.filter.filter;
    if (_.isArray(e.userInput))
      e.userInput = _.join(
        e.userInput.map((el) => el.value),
        "|"
      );
    if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
  });
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      filters,
      orderTable: orderTable && [orderTable],
      fastFilters,
      separatorWhereAnd: !optionOr,
    }),
  };
  const response = await fetch(
    `${uri}/remotetable/location/${siteId}/dat`,
    requestOptions
  );
  if (response.ok) {
    return response
      .blob()
      .then((blob: any) => ({ content: blob, filename: "export.dat" }));
  }
  return response;
}

async function getStock(siteId: number) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const response = await fetch(
    `${uri}/list/location/${siteId}/parents`,
    requestOptions
  );
  return handleResponse(response);
}

async function getInfos(siteId: number, pdiId: number) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const response = await fetch(
    `${uri}/${siteId}/infos/${pdiId}/`,
    requestOptions
  );

  return handleResponse(response);
}

async function linkPdisToLocation(locationId: number, pdiList: Array<number>) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(pdiList),
  };
  const response = await fetch(`${uri}/link/${locationId}/`, requestOptions);

  return handleResponse(response);
}

async function unlinkPdisToLocation(
  locationId: number,
  pdiList: Array<number>,
  actionsList: Array<any>
) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: `{ "pdiIds": ${JSON.stringify(pdiList)} , "unlink": ${JSON.stringify(
      actionsList
    )}}`,
  };
  const response = await fetch(`${uri}/unlink/${locationId}/`, requestOptions);

  return handleResponse(response);
}

async function linkAllPdisToLocation(locationId: number, filters: Array<any>) {
  filters.forEach((e) => {
    delete e.filter.filter;
    if (_.isArray(e.userInput))
      e.userInput = _.join(
        e.userInput.map((el) => el.value),
        "|"
      );
    if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
  });
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(filters),
  };
  const response = await fetch(`${uri}/link/${locationId}/all`, requestOptions);

  return handleResponse(response);
}

async function unlinkAllPdisToLocation(
  locationId: number,
  filters: Array<any>,
  unlink: Array<any>
) {
  filters.forEach((e) => {
    delete e.filter.filter;
    if (_.isArray(e.userInput))
      e.userInput = _.join(
        e.userInput.map((el) => el.value),
        "|"
      );
    if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
  });
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({ filters, unlink }),
  };
  const response = await fetch(
    `${uri}/unlink/${locationId}/all`,
    requestOptions
  );

  return handleResponse(response);
}

async function unlinkActionsPdisToLocation(
  locationId: number,
  pdiList: Array<number>
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(pdiList),
  };
  const response = await fetch(`${uri}/unlink/${locationId}/`, requestOptions);

  return handleResponse(response);
}

async function unlinkActionsAllPdisToLocation(
  locationId: number,
  filters: Array<any>
) {
  filters.forEach((e) => {
    delete e.filter.filter;
    if (_.isArray(e.userInput))
      e.userInput = _.join(
        e.userInput.map((el) => el.value),
        "|"
      );
    if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
  });
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(filters),
  };
  const response = await fetch(
    `${uri}/unlink/${locationId}/all`,
    requestOptions
  );

  return handleResponse(response);
}

async function createPdi(newPdi: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(newPdi),
  };
  const response = await fetch(`${uri}/`, requestOptions);

  return handleResponse(response);
}

async function getFullPdi(pdiId: number) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const response = await fetch(`/api/pdi/info/${pdiId}`, requestOptions);
  return handleResponse(response);
}

async function getMapIdToSerial(
  filters: any[],
  locCode: string,
  searchForSite: number
) {
  filters.forEach((e) => {
    delete e.filter.filter;
    if (_.isArray(e.userInput))
      e.userInput = _.join(
        e.userInput.map((el) => el.value),
        "|"
      );
    if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
  });
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(filters),
  };
  const response = await fetch(
    `/api/pdi/mapmeter/${locCode}/${searchForSite}`,
    requestOptions
  );
  return handleResponse(response);
}

async function addGpsPosition(pdiId: any) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
  };
  const response = await fetch(`/api/pdi/${pdiId}/gps`, requestOptions);
  return handleResponse(response);
}

async function updateInfo(id: any, editableField: string, value: string) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({ editableField, value }),
  };
  const response = await fetch(`/api/pdi/${id}/message`, requestOptions);
  return handleResponse(response);
}

const pdiService = {
  getAll,
  getAllWithChildren,
  getStock,
  getInfos,
  linkPdisToLocation,
  linkAllPdisToLocation,
  unlinkActionsPdisToLocation,
  unlinkActionsAllPdisToLocation,
  unlinkPdisToLocation,
  unlinkAllPdisToLocation,
  createPdi,
  getFullPdi,
  addGpsPosition,
  getAllWithChildrenFilters,
  getAllWithChildrenWithRangeDate,
  getRemotePdi,
  getRemotePdiCsv,
  getRemotePdiCsvStatus,
  getRemotePdiDat,
  getMapIdToSerial,
  updateInfo,
};

export default pdiService;
