import _ from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { withTranslation } from "react-i18next";
import { FormGroup, Input, Label } from "reactstrap";

const ImportSilexErrorTable: React.FC<{
  imports: any;
  t: Function;
  lineType: number;
  setLineType: Dispatch<SetStateAction<number>>;
  selectedRound: any;
  setSelectedRound: Dispatch<SetStateAction<any>>;
}> = ({
  imports,
  t,
  lineType,
  setLineType,
  selectedRound,
  setSelectedRound,
}) => {
  const getErrorTable = (error: any) => {
    switch (error.errorCode) {
      case "400":
        if (error.errorMessage === "silex_format_error") {
          const errorFoundList: string[] = JSON.parse(error.errorFound);
          const data = errorFoundList.map((label) => {
            const missingObjects = JSON.parse(_.get(error, label));
            return missingObjects.map((missingObject: any) => {
              if (label === "lines_incorrect_length") {
                return {
                  id: label + missingObject,
                  description: t(`import_silex.error_text.${label}`),
                  line: missingObject,
                };
              } else if (label === "wrongData") {
                return {
                  id: label + missingObject._1,
                  description: t("import_silex.error_text.wrong_data"),
                  line: missingObject._1,
                  type: missingObject._3,
                  column: missingObject._2,
                };
              } else if (missingObject._4) {
                return {
                  id: label + missingObject._1,
                  description: t(`import_silex.error_text.${label}`),
                  line: missingObject._1,
                  type: missingObject._4,
                  column:
                    missingObject._2 !== missingObject._3
                      ? missingObject._2 + "-" + missingObject._3
                      : missingObject._2,
                };
              } else {
                return {
                  id: label + missingObject._2 + missingObject._3,
                  // label: t(`import_silex.error_text.${label}`),
                  type: missingObject._1,
                  description:
                    missingObject._2 !== "" || missingObject._3 !== ""
                      ? t(`import_silex.error_text.${label}`, {
                          serial: missingObject._2,
                          page: missingObject._3,
                        })
                      : t(`import_silex.error_text.${label}_empty`),
                };
              }
            });
          });
          return (
            <BootstrapTable
              keyField="id"
              bootstrap4
              bordered
              hover
              data={data.flat().sort((a, b) => a.type - b.type)}
              columns={errorImportColumns}
            />
          );
        }

        if (error.errorMessage === "Meters already in a round") {
          const data = Object.entries(error.errorDetail).map(
            ([meterSerial, listRnd]: any) =>
              listRnd.map((rnd: string) => ({
                id: meterSerial + rnd,
                serial: meterSerial,
                round: rnd,
              }))
          );
          return (
            <BootstrapTable
              keyField="id"
              bootstrap4
              bordered
              hover
              data={data.flat().sort((a, b) => a.serial - b.serial)}
              columns={errorAlreadyLinkMeterColumns}
            />
          );
        }
    }
  };

  const errorColumns = [
    {
      dataField: "identifiant",
      text: t("import_silex.columns.identifiant"),
    },
    {
      dataField: "fieldName",
      text: t("import_silex.columns.field"),
    },
    {
      dataField: "valueTemoin",
      text: t("import_silex.columns.temoin_value"),
      type: "number",
    },
    {
      dataField: "valueGenerated",
      text: t("import_silex.columns.generated_value"),
    },
  ];

  const errorImportColumns = [
    {
      dataField: "line",
      text: t("import_silex.column_label.lines"),
    },
    {
      dataField: "type",
      text: t("import_silex.column_label.types"),
    },
    {
      dataField: "column",
      text: t("import_silex.column_label.positions"),
    },
    {
      dataField: "description",
      text: t("import_silex.column_label.description"),
    },
  ];

  const errorAlreadyLinkMeterColumns = [
    {
      dataField: "serial",
      text: t("all.meter.meter_serial"),
    },
    {
      dataField: "round",
      text: t("import_silex.column_label.associated_round"),
    },
  ];
  console.log(selectedRound);
  return (
    <>
      {imports.error && getErrorTable(imports.error)}
      {imports.errorSilex && (
        <>
          {_.get(imports, "errorSilex[0].listResults") && (
            <div className="tab-onglet-import-container">
              <FormGroup style={{ marginBottom: "0.5rem", width: "15%" }}>
                <Label for="exampleSelect">Liste des tournées</Label>
                <Input
                  type="select"
                  name="select"
                  id="exampleSelect"
                  defaultValue=""
                  onChange={(e: any) =>
                    setSelectedRound(
                      imports.errorSilex.find(
                        (error: any) => error.roundCode === e.target.value
                      )
                    )
                  }
                >
                  <option disabled>
                    {t("import_silex.text.select_round")}
                  </option>
                  {selectedRound &&
                    imports.errorSilex
                      .map((el: any) => el.roundCode)
                      .map((roundCode: string, index: number) => (
                        <option
                          key={roundCode + index}
                          value={roundCode}
                          selected={roundCode === selectedRound.roundCode}
                        >
                          {roundCode}
                        </option>
                      ))}
                </Input>
              </FormGroup>
              <div className="onglet-container">
                {_.uniq(
                  imports.errorSilex
                    .map((round: any) =>
                      round.listResults.map((error: any) => error.lineType)
                    )
                    .flat()
                ).map((el: any) => (
                  <div
                    key={el}
                    className={`onglet ${
                      lineType === el
                        ? "actif"
                        : selectedRound?.listResults?.find(
                            (round: any) => round.lineType === el
                          )?.differences.length === 0
                        ? "inactif"
                        : ""
                    }`}
                    onClick={() => {
                      if (
                        selectedRound?.listResults?.find(
                          (round: any) => round.lineType === el
                        )?.differences.length > 0
                      ) {
                        setLineType(el);
                      }
                    }}
                  >
                    <span>{`Type ${el}`}</span>
                  </div>
                ))}
              </div>
              {selectedRound && (
                <BootstrapTable
                  keyField="identifiant"
                  bootstrap4
                  bordered
                  hover
                  data={
                    selectedRound?.listResults?.find(
                      (el: any) => el.lineType === lineType
                    )?.differences || []
                  }
                  columns={errorColumns}
                />
              )}
            </div>
          )}
          {_.size(_.get(imports, "errorSilex[0].doublonsLine")) > 0 && (
            <div>
              <h2>{t("import.anomaly_text.doublons")} :</h2>
              {_.entries(_.get(imports, "errorSilex[0].doublonsLine")).map(
                ([key, value]: [string, any]) => (
                  <div>
                    <h4>{key}</h4>
                    {value.map((el: any) => (
                      <div
                        className="tourneeDoublon"
                        style={{ borderLeft: "solid", paddingLeft: "15px" }}
                      >
                        <p className="bold-tournee">
                          {t("all.round.round_name")} : {el.rndName}
                        </p>
                        <p className="bold-tournee">
                          {t("all.round.round_code")} : {el.rndCode}
                        </p>
                        <p>
                          {t("columns.cardOrder")} : {el.cardOrder}
                        </p>
                        <p>
                          {t("columns.clpInformations")} : {el.clpInformations}
                        </p>
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          )}
          {_.size(_.get(imports, "errorSilex[0].emptySerialLine")) > 0 && (
            <div>
              <h2>{t("import.anomaly_text.emptyMeterSerial")} :</h2>
              {_.get(imports, "errorSilex[0].emptySerialLine").map(
                (el: any) => (
                  <div>
                    <div
                      className="tourneeDoublon"
                      style={{ borderLeft: "solid", paddingLeft: "15px" }}
                    >
                      <p className="bold-tournee">
                        {t("all.round.round_name")} : {el.rndName}
                      </p>
                      <p className="bold-tournee">
                        {t("all.round.round_code")} : {el.rndCode}
                      </p>
                      <p>
                        {t("columns.cardOrder")} : {el.cardOrder}
                      </p>
                      <p>
                        {t("columns.clpInformations")} : {el.clpInformations}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
          {_.size(_.get(imports, "errorSilex[0].dateLineError")) > 0 && (
            <div>
              <h2>{t("import.anomaly_text.dateLineError")} :</h2>
              {_.get(imports, "errorSilex[0].dateLineError").map((el: any) => (
                <div>
                  <h4>{el.serialCpt}</h4>
                  <div
                    className="tourneeDoublon"
                    style={{ borderLeft: "solid", paddingLeft: "15px" }}
                  >
                    <p className="bold-tournee">
                      {t("all.round.round_name")} : {el.rndName}
                    </p>
                    <p className="bold-tournee">
                      {t("all.round.round_code")} : {el.rndCode}
                    </p>
                    <p>
                      {t("columns.cardOrder")} : {el.cardOrder}
                    </p>
                    <p>
                      {t("columns.clpInformations")} : {el.clpInformations}
                    </p>
                    <p>
                      {t("import.anomaly_text.field_with_date_inf_1970")} :{" "}
                      {t(`columns.${el.fieldError}`)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

const tr = withTranslation()(ImportSilexErrorTable);
export default tr;
