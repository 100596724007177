import widgetConstants from "../_constants/widget.constants";
import { action } from "../_interfaces/action";
import _ from "lodash";

export default function widgets(state: any = {}, action: action) {
  switch (action.type) {
    case widgetConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case widgetConstants.GETALL_SUCCESS:
      return {
        items: action.widgets,
      };
    case widgetConstants.GETALL_FAILURE:
      return {
        error: "La liste de widgets n'a pas pu être récupérée",
      };
    case widgetConstants.GET_PREVISU_DATA_REQUEST:
      state.preview = {};
      return {
        loading: true,
        ...state,
      };
    case widgetConstants.GET_PREVISU_DATA_SUCCESS:
      state.preview = action.data;
      return {
        ...state,
      };
    case widgetConstants.GET_PREVISU_DATA_FAILURE:
      return {
        error: "La liste de widgets n'a pas pu être récupérée",
      };
    case widgetConstants.GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case widgetConstants.GET_SUCCESS:
      return {
        fetchedwidget: action.fetchedwidget,
      };
    case widgetConstants.GET_FAILURE:
      return {
        error: "Le widget n'a pas pu être récupéré",
        widgetId: action.widgetId,
      };
    case widgetConstants.GETCONTENT_REQUEST:
      return {
        loading: true,
      };
    case widgetConstants.GETCONTENT_SUCCESS:
      return {
        widgetContentReturn: action.widgetContentReturn,
        widgetId: action.widgetId,
      };
    case widgetConstants.GETCONTENT_FAILURE:
      return {
        error: "Le contenu du widget n'a pas pu être récupéré",
        widgetId: action.widgetId,
      };
    case widgetConstants.GET_DATA_REQUEST:
      return {
        ...state,
      };
    case widgetConstants.GET_DATA_SUCCESS:
      const newAction = { [action.widgetId]: action.data };
      state.data = state.data ? state.data : newAction;
      if (state.data[action.widgetId]) {
        state.data[action.widgetId] = action.data;
      } else {
        _.merge(state.data, newAction);
      }

      return {
        ...state,
        lastUpdate: action.widgetId,
      };
    case widgetConstants.GET_DATA_FAILURE:
      return {
        ...state,
      };
    case widgetConstants.DELETE_FAILURE:
      return {
        error: "Le widget n'a pas pu être supprimé",
        widgetId: action.widgetId,
      };
    case widgetConstants.SAVE_SUCCESS:
      return {
        ...state,
        savedWidget: action.savedWidget,
      };
    case widgetConstants.UPDATE_SUCCESS:
      return {
        updatedWidget: action.updatedWidget,
        ...state,
      };
    case widgetConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
