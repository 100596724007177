const locationConstants = {
  GETALL_REQUEST: "LOCATIONS_GETALL_REQUEST",
  GETALL_SUCCESS: "LOCATIONS_GETALL_SUCCESS",
  GETALL_FAILURE: "LOCATIONS_GETALL_FAILURE",

  GETALLFROMSEED_REQUEST: "LOCATIONS_GETALLFROMSEED_REQUEST",
  GETALLFROMSEED_SUCCESS: "LOCATIONS_GETALLFROMSEED_SUCCESS",
  GETALLFROMSEED_FAILURE: "LOCATIONS_GETALLFROMSEED_FAILURE",

  GET_REQUEST: "LOCATIONS_GET_REQUEST",
  GET_SUCCESS: "LOCATIONS_GET_SUCCESS",
  GET_FAILURE: "LOCATIONS_GET_FAILURE",

  GETINFO_REQUEST: "LOCATIONS_GETINFO_REQUEST",
  GETINFO_SUCCESS: "LOCATIONS_GETINFO_SUCCESS",
  GETINFO_FAILURE: "LOCATIONS_GETINFO_FAILURE",

  GETCONTACT_REQUEST: "LOCATIONS_GETCONTACT_REQUEST",
  GETCONTACT_SUCCESS: "LOCATIONS_GETCONTACT_SUCCESS",
  GETCONTACT_FAILURE: "LOCATIONS_GETCONTACT_FAILURE",

  SAVE_REQUEST: "LOCATIONS_SAVE_REQUEST",
  SAVE_SUCCESS: "LOCATIONS_SAVE_SUCCESS",
  SAVE_FAILURE: "LOCATIONS_SAVE_FAILURE",

  UPDATE_REQUEST: "LOCATIONS_UPDATE_REQUEST",
  UPDATE_SUCCESS: "LOCATIONS_UPDATE_SUCCESS",
  UPDATE_FAILURE: "LOCATIONS_UPDATE_FAILURE",

  DELETE_REQUEST: "LOCATIONS_DELETE_REQUEST",
  DELETE_SUCCESS: "LOCATIONS_DELETE_SUCCESS",
  DELETE_FAILURE: "LOCATIONS_DELETE_FAILURE",

  GET_ALLMETER_REQUEST: "GET_ALLMETER_REQUEST",
  GET_ALLMETER_SUCCESS: "GET_ALLMETER_SUCCESS",
  GET_ALLMETER_FAILURE: "GET_ALLMETER_FAILURE",

  GET_ALLREADMETER_REQUEST: "GET_ALLREADMETER_REQUEST",
  GET_ALLREADMETER_SUCCESS: "GET_ALLREADMETER_SUCCESS",
  GET_ALLREADMETER_FAILURE: "GET_ALLREADMETER_FAILURE",

  GET_ALLREADMETERALARM_REQUEST: "GET_ALLREADMETERALARM_REQUEST",
  GET_ALLREADMETERALARM_SUCCESS: "GET_ALLREADMETERALARM_SUCCESS",
  GET_ALLREADMETERALARM_FAILURE: "GET_ALLREADMETERALARM_FAILURE",

  GET_ALLREADMETERALARMTYPE_REQUEST: "GET_ALLREADMETERALARMTYPE_REQUEST",
  GET_ALLREADMETERALARMTYPE_SUCCESS: "GET_ALLREADMETERALARMTYPE_SUCCESS",
  GET_ALLREADMETERALARMTYPE_FAILURE: "GET_ALLREADMETERALARMTYPE_FAILURE",

  GET_ALLREADMETERMODEL_REQUEST: "GET_ALLREADMETERMODEL_REQUEST",
  GET_ALLREADMETERMODEL_SUCCESS: "GET_ALLREADMETERMODEL_SUCCESS",
  GET_ALLREADMETERMODEL_FAILURE: "GET_ALLREADMETERMODEL_FAILURE",

  GET_ALLREADMETERREADMETHOD_REQUEST: "GET_ALLREADMETERREADMETHOD_REQUEST",
  GET_ALLREADMETERREADMETHOD_SUCCESS: "GET_ALLREADMETERREADMETHOD_SUCCESS",
  GET_ALLREADMETERREADMETHOD_FAILURE: "GET_ALLREADMETERREADMETHOD_FAILURE",

  CLEAR: "LOCATIONS_CLEAR",
};

export default locationConstants;
