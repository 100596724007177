/**
 * Permet d'ajouter une classe active
 * @method getActiveClassByPath
 * @param {string} pathname Fichier de traduction
 * @param {string} link Fichier de traduction
 * @returns {string}  'nav-active' : ''
 */
export default function getActiveClassByPath(
  pathname: string,
  link: string,
  search?: string
): string {
  let isActive = RegExp(link).test(pathname) || RegExp(link).test(search);
  const pathArray = pathname.split("/");
  const last = parseInt(pathArray[pathArray.length - 1], 10);
  //c'est moche mais ça fonctionne et puis on est vendredi
  if (
    !isNaN(last) &&
    link === "details" &&
    !pathname.includes("pdi") &&
    !pathname.includes("virtuals") &&
    !pathname.includes("dashboards")
  ) {
    isActive = true;
  }
  if (link === "resources" && pathname === "/stock") {
    isActive = true;
  }
  if (link === "teleRessource" && pathname === "/telereleve") {
    isActive = true;
  }
  if (link === "manager" && pathArray.length === 4) {
    isActive = true;
  }
  if (link === "/sensor" && pathname.includes("dashboard")) {
    isActive = false;
  }
  if (!isNaN(last) && link === "users") {
    isActive = false;
  }
  if (!isNaN(last) && link === "usersid") {
    isActive = true;
  }
  if (link === "tourneeInfo") {
    isActive =
      !pathname.includes("fiche") &&
      !pathname.includes("statistique") &&
      !pathname.includes("report");
  }
  if (link.length === 0) {
    isActive = pathname === "/";
  }
  return isActive ? "nav-active" : "";
}

export function getClassNameByRegexp(path: string, regex: RegExp) {
  return regex.test(path) ? "nav-active" : "";
}
