import React, { ComponentType } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { compose } from "redux";

interface Props {
  locations: any;
  users: any;
  dashboards: any;
  meters: any;
  radios: any;
  vmeters: any;
  pdis: any;
  alarms: any;
  match: any;
  reports: any;
  gestionnaire: any;
  t: any;
  tournee: any;
  mask: any;
  sensor: any;
  enquete: any;
  customexport: any;
  cluster: any;
}
interface State {
  nav: any;
}

/**
 * @class CrystalBreadCrumbComponent
 * @extends {Component}
 */
class CrystalBreadCrumbComponent extends React.Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      nav: {},
    };
  }

  buildNavTranslatedObject = () => {
    const { t } = this.props;
    const nav = {
      locations: t("bread_crumb.name.location_plural"),
      mask: t("bread_crumb.name.masks"),
      location: t("bread_crumb.name.location_plural"),
      dashboards_bylocation: t("bread_crumb.name.dashboard_plural"),
      dashboards: t("bread_crumb.name.dashboard"),
      users: t("bread_crumb.name.user_plural"),
      widgets: t("bread_crumb.name.widget_plural"),
      widget: t("bread_crumb.name.widget"),
      user: t("bread_crumb.name.user"),
      meters: t("bread_crumb.name.meter_plural"),
      virtuals: t("bread_crumb.name.virtual_meter_plural"),
      radios: t("bread_crumb.name.radio"),
      pdi: t("bread_crumb.name.pdi").toUpperCase(),
      add: t("bread_crumb.name.new"),
      search: t("bread_crumb.name.search"),
      notifications: t("bread_crumb.name.notif_plural"),
      alerts: t("bread_crumb.name.alert_plural"),
      details: t("bread_crumb.name.detail_plural"),
      reports: t("bread_crumb.name.report_plural"),
      resources_liststock: t("bread_crumb.name.stock"),
      createpdi: t("bread_crumb.name.create_pdi"),
      createmeter: t("bread_crumb.name.create_meter"),
      import: t("bread_crumb.name.billing_import"),
      link: t("bread_crumb.name.link_pdi"),
      unlink: t("bread_crumb.name.unlink_pdi"),
      synchronisation: t("bread_crumb.name.synchro"),
      tournees: t("bread_crumb.name.round_plural"),
      fiche: t("bread_crumb.name.record"),
      portables: t("bread_crumb.name.phone_plural"),
      constructeur: t("bread_crumb.name.import_mfr"),
      silex: t("bread_crumb.name.new_tournee"),
      new: t("bread_crumb.name.create_tournee"),
      template: t("bread_crumb.name.templates"),
      importsilex: t("all.round.round_import"),
      messages: t("sidebar_synchro.nav_link.msg_list"),
      marquages: t("sidebar_synchro.nav_link.marking_list"),
      gestionnaires: t("bread_crumb.name.manager_plural"),
      export: t("bread_crumb.name.export"),
      statistique: t("bread_crumb.name.stat_plural"),
      forbidden: t("bread_crumb.name.forbidden_access"),
      profils: t("bread_crumb.name.profile_plural"),
      telereleve: t("bread_crumb.name.telereleve"),
      provisionning: t("bread_crumb.name.provisionning"),
      support: t("bread_crumb.name.support"),
      sync: t("bread_crumb.name.sync"),
      remotereading: t("bread_crumb.name.remotereading"),
      synchrotools: t("bread_crumb.name.synchrotools"),
      importbilling: t("bread_crumb.name.importbilling"),
      enquetes: t("sidebar_synchro.nav_link.enquiry_plural"),
      report: t("bread_crumb.name.report_plural"),
      linkround: t("enquete.text.link_enq_round"),
      gateways: t("gateway.text.gateway"),
      customexport: t("custom_export.title.custom_export_plural"),
      sensor: t("sensor.text.sensor"),
    };

    return nav;
  };

  /**
   * Construit une url à partir d'un tableau
   *
   * @method getUrl
   * @param {any} url Url
   * @param {number} i Indice
   * @returns {string} L'url
   */
  getUrl = (url: any, i: number) => {
    const ret = url.splice(0, i).join("/");
    if (ret === "locations") {
      return `/${ret}${
        this.props.locations.fetchedLocation &&
        this.props.locations.fetchedLocation.tournee
          ? "?root=TOURNEES"
          : "?root=STOCK"
      }`;
    }
    return `/${ret}`;
  };

  /**
   * Récupère le chemin passé dans le breadcrumb et le
   * formate comme voulu
   *
   * @method getTitle
   * @param {any} p Chemin
   * @returns {any} Le chemin formaté
   */
  getTitle = (p: any) => {
    const {
      t,
      match: {
        params: { appname },
      },
    } = this.props;
    const {
      locations,
      users,
      dashboards,
      meters,
      radios,
      pdis,
      vmeters,
      alarms,
      reports,
      gestionnaire,
      tournee,
      mask,
      sensor,
      enquete,
      customexport,
      cluster,
    } = this.props;
    const nav = this.buildNavTranslatedObject();
    const translated: any = nav[p];
    if (translated) return translated;
    if (p === "myself") {
      if (users.fetchedUser) {
        return users.fetchedUser.userName;
      }
      return p;
    }
    if (p === "info") {
      if (meters.fetchedMeter && meters.fetchedMeter.general) {
        return meters.fetchedMeter.general.serial;
      }
      if (radios.fetchedRadio && radios.fetchedRadio.general) {
        return radios.fetchedRadio.general.serial;
      }
      if (vmeters.fetchedVMeter && vmeters.fetchedVMeter.general) {
        return vmeters.fetchedVMeter.general.name;
      }
      if (mask.fetchedMask) {
        return mask.fetchedMask.name;
      }
      return p;
    }
    if (p === "edit") {
      return t("bread_crumb.name.edit_x_virtual_meter", {
        virtualMeterName: _.get(vmeters, "fetchedVMeter.general.name", "..."),
      });
    }
    if (p === "editexport") {
      return t("bread_crumb.name.edit_x_custom_export", {
        customExport: _.get(
          customexport,
          "fetchedCustomExport.exportName",
          "..."
        ),
      });
    }
    if (p === "editalert") {
      return t("bread_crumb.name.edit_x_alarm", {
        alarmName: _.get(alarms, "fetchedAlarm.content.name", "..."),
      });
    }
    if (p === "editmask") {
      if (mask.fetchedMask && mask.fetchedMask.name) {
        return t("bread_crumb.name.edit_x_mask_alarm", {
          maskAlarmName: mask.fetchedMask.name,
        });
      }
    }

    if (p.startsWith(":")) {
      switch (p) {
        case ":locationId": {
          return _.get(locations, "fetchedLocation.name", "...");
        }
        case ":GestionnaireId": {
          return _.get(gestionnaire, "gestionnaire.name", "...");
        }
        case ":ficheId": {
          return _.get(tournee, "fetchedFiche.baseMeter.theoricSerial", "...");
        }
        case ":tourneeCode": {
          return _.get(tournee, "info.name", "...");
        }
        case ":userId": {
          return _.get(users, "fetchedUser.userName", "...");
        }
        case ":enqueteNumPage": {
          return _.get(enquete, "fetchedEnquete.enqName", "...");
        }
        case ":sensorId": {
          return _.get(sensor, "sensor.info.sensorDevEUI", "...");
        }
        case ":appname": {
          return appname === "cc" ? "CrystalCloud" : "CrystalCloudWS";
        }
        case ":dashboardId": {
          if (dashboards.dashboard) {
            if (dashboards.dashboard.personal) {
              return t("bread_crumb.name.personal_dashboard_of_x", {
                userName: dashboards.dashboard.owner.userName.split("@")[0],
              });
            }
            return dashboards.dashboard.name;
          }
          return "...";
        }
        case ":meterId": {
          return _.get(meters, "fetchedMeter.general.serial", "...");
        }
        case ":pdiId": {
          return (
            _.get(pdis, "fetchedPdi.general.reference", "...") ||
            `ID : ${_.get(pdis, "fetchedPdi.general.id", "...")}`
          );
        }
        case ":vmeterId": {
          return _.get(vmeters, "fetchedVMeter.general.name", "...");
        }
        case ":alertId": {
          return _.get(alarms, "fetchedAlarm.id", "...");
        }
        case ":reportId": {
          return _.get(reports, "fetchedReport.id", "...");
        }
        case ":clusterId": {
          return _.get(cluster, "id", "...");
        }
        default:
          return p;
      }
    }
    return p;
  };

  /**
   * Construit les éléments du breadcrumb à partir d'un tableau de chemins
   *
   * @method renderBreadCrumbs
   * @returns {JSX} Les différents éléments du breadcrumb
   */
  renderBreadCrumbs = () => {
    const { match, locations } = this.props;
    const url = match.url.split("/").filter((item: string) => item !== "");
    const paths = match.path.split("/");
    const unclickedItem = [":alertId", ":GestionnaireId", "support"];
    const items = paths.map(
      (p: string, i: number) =>
        p !== "" && (
          <BreadcrumbItem key={p} active={url.length === i}>
            {url.length === i && this.getTitle(p)}
            {url.length !== i && unclickedItem.includes(p) && this.getTitle(p)}
            {url.length !== i && !unclickedItem.includes(p) && (
              <Link to={this.getUrl(Object.assign([], url), i)}>
                {this.getTitle(p)}
              </Link>
            )}
          </BreadcrumbItem>
        )
    );
    return items;
  };

  /**
   * Rends le composant
   *
   * @method render
   */
  render() {
    const { t } = this.props;
    return (
      <div className="CrystalBreadCrumb" id="crystalBreadCrumb">
        <Breadcrumb
          className="navbar-header bg-header"
          style={{ alignSelf: "center" }}
        >
          <BreadcrumbItem>
            <a href="/">{t("bread_crumb.name.crystal_cloud")}</a>
          </BreadcrumbItem>
          {this.renderBreadCrumbs()}
        </Breadcrumb>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  return state;
}

const connectedCrystalBreadCrumb = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(CrystalBreadCrumbComponent);
export default withTranslation()(connectedCrystalBreadCrumb);
