import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { translateBasicAlarm } from "../_helpers/locale-helpers";
import locale from "../_shared/Locale.json";
import _ from "lodash";

const createOption = (label: string) => ({
  label,
  value: label,
});

class CustomExportFillFiltersModal extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      filterValues: {},
      inputValues: {},
    };
  }

  userInputChange = (inputValue: string, numFilter: number) => {
    const { filterValues } = this.state;
    let cloneFilterValues = _.cloneDeep(filterValues);
    cloneFilterValues[numFilter] = _.get(
      inputValue,
      "target.value",
      inputValue
    );
    this.setState({ filterValues: cloneFilterValues });
  };

  clearCurrentUserInput(numFilter) {
    const { inputValues } = this.state;
    let cloneInputValues = _.cloneDeep(inputValues);
    cloneInputValues[numFilter] = "";
    this.setState({ inputValues: cloneInputValues });
  }

  handleKeyDown = (event: any, numFilter: number) => {
    const { inputValues, filterValues } = this.state;
    switch (event.key) {
      case "Enter":
        const newValue = filterValues[numFilter] || [];
        newValue.push(createOption(inputValues[numFilter]));
        let cloneDeep1 = _.cloneDeep(filterValues);
        cloneDeep1[numFilter] = newValue;
        this.clearCurrentUserInput(numFilter);
        this.setState({
          filterValues: cloneDeep1,
        });
        event.preventDefault();
      default:
    }
  };

  onBlur = (event: any, numFilter: number) => {
    const { inputValues, filterValues } = this.state;
    if (!inputValues[numFilter] || _.isEmpty(inputValues[numFilter])) return;
    const newValue = filterValues[numFilter] || [];
    newValue.push(createOption(inputValues[numFilter]));
    this.clearCurrentUserInput(numFilter);
    this.setState({
      filterValues: newValue,
    });
  };

  handleInputChange = (userInput: any, numFilter: any) => {
    const { inputValues } = this.state;
    let cloneInputValues = _.cloneDeep(inputValues);
    cloneInputValues[numFilter] = userInput;
    this.setState({ inputValues: cloneInputValues });
  };

  toggle() {
    const { show } = this.props;
    this.setState({
      filterValues: {},
      inputValues: {},
    });
    show();
  }

  render() {
    const { isOpen, show, customExport, t, launchCustomExport } = this.props;
    const { filterValues, inputValues } = this.state;
    const components = {
      DropdownIndicator: null,
    };
    return (
      <Modal isOpen={isOpen} toggle={show} size="lg">
        <ModalHeader toggle={show}>{customExport.exportName}</ModalHeader>
        <Form>
          <ModalBody>
            {customExport.exportFilters
              .filter(({ defineLater }) => defineLater)
              .map((el) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "baseline",
                  }}
                >
                  <p>{t(`columns.${el.filter.value}`)}</p>
                  <p>
                    {t(
                      `linefilter.operator.${el.filter.type.toLowerCase()}.${el.operator.value.toLowerCase()}`
                    )}
                  </p>
                  <div style={{ width: "40%" }}>
                    {el.filter.type &&
                      el.operator &&
                      !el.operator.noEntry &&
                      el.filter.type.length > 0 &&
                      [
                        "select",
                        "selectCont",
                        "selectContValue",
                        "selectAlarmTypeBasic",
                      ].includes(el.filter.type) && (
                        <Select
                          options={
                            "selectAlarmTypeBasic" === el.filter.type
                              ? translateBasicAlarm("fr", locale).sort(
                                  (a, b) => {
                                    if (a.label < b.label) return -1;
                                    if (a.label === b.label) return 0;
                                    return 1;
                                  }
                                )
                              : el.filter.opts
                          }
                          onChange={(e) => this.userInputChange(e, el.num)}
                          id={`userInput${el.num}`}
                          value={filterValues[el.num] as any}
                        />
                      )}
                    {el.operator &&
                      !el.operator.noEntry &&
                      el.filter.type &&
                      !(
                        el.filter.type === "select" ||
                        el.filter.type === "selectCont" ||
                        el.filter.type === "selectContValue" ||
                        el.filter.type === "selectAlarmTypeBasic" ||
                        el.filter.type === "text" ||
                        el.filter.type === "checkbox"
                      ) && (
                        <Input
                          type={el.filter.type as any}
                          name="filterSaisie"
                          id={`userInput${el.num}`}
                          placeholder=""
                          onChange={(e: any) => this.userInputChange(e, el.num)}
                          value={inputValues[el.num]}
                        />
                      )}
                    {el.operator &&
                      !el.operator.noEntry &&
                      el.filter.type &&
                      el.filter.type === "text" && (
                        <CreatableSelect
                          components={components}
                          name="filterSaisie"
                          id={`userInput${el.num}`}
                          placeholder={t(
                            "linefilter.userinput.placeholder_input"
                          )}
                          onChange={(e) => this.userInputChange(e, el.num)}
                          value={filterValues[el.num]}
                          isMulti
                          isClearable
                          menuIsOpen={false}
                          onInputChange={(e) =>
                            this.handleInputChange(e, el.num)
                          }
                          onKeyDown={(e) => this.handleKeyDown(e, el.num)}
                          onBlur={(e) => this.onBlur(e, el.num)}
                          inputValue={inputValues[el.num]}
                        />
                      )}
                  </div>
                </div>
              ))}
          </ModalBody>
        </Form>
        <ModalFooter>
          <Button
            color="success"
            onClick={() =>
              launchCustomExport(customExport.exportId, filterValues)
            }
          >
            {t("all.button.register")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation()(CustomExportFillFiltersModal);
