import React, { ComponentType, Fragment } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  NavItem,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap";
import { Info } from "@material-ui/icons";
import _, { add } from "lodash";
import { Pie } from "react-chartjs-2";
import { withTranslation } from "react-i18next";
import { locationActions, userActions } from "../_actions";
import { locations } from "../_interfaces/reducers";
import { Address } from "../_entities/location";
import LocationCreator from "../LocationCreator/LocationCreator";
import SvgPoubelleBleu from "../SvgComponents/PoubelleBleu";
import Confirm from "./Confirm";
import Picto from "../SiteDetail/Picto_correspondance";
import NoFilled from "../Message/NoFilled";
import GestionUtilisateurBlanc from "../SvgComponents/GestionUtilisateurBlanc";
import { compose } from "redux";

interface Props {
  dispatch: Function;
  buttonSize: string;
  mini?: boolean;
  match: any;
  history: Array<string>;
  locations: locations;
  user: any;
  users: any;
  t: Function;
}

interface State {
  isOpen: boolean;
}

/**
 * @class LocationModalComponent
 * @extends Component
 */
class LocationModalComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.deleteLocation = this.deleteLocation.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  /**
   * @method componentDidUpdate
   * @param {any} prevProps Props précédentes
   */
  componentDidUpdate(prevProps: any) {}

  componentDidMount() {
    const { dispatch, user } = this.props;
    dispatch(userActions.get(user.id));
  }

  componentWillUnmount() {
    const { dispatch }: any = this.props;
    // TODO ?
  }

  /**
   * Gère l'ouverture/fermeture de la modal
   *
   * @method toggle
   */
  toggle = () => {
    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen,
    });
  };

  couldEditOrDeleteSite = () => {
    const { user, locations, users } = this.props;
    const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN"];
    if (roleList.includes(user.role.name)) {
      return true;
    }
    if (locations.fetchedLocation && locations.fetchedLocation.tournee) {
      return true;
    }
    if (
      users &&
      users.fetchedUser &&
      users.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find(
            (permission: any) => permission.name === "edit.locationInfo"
          ) && el.locationCode === locations.fetchedLocation.code
      )
    ) {
      return true;
    }
    return false;
  };

  async deleteLocation() {
    const { locations, dispatch, history, t } = this.props;

    const result = await Confirm.confirm({
      title: (
        <Fragment>
          <strong>
            {t("location_modal.title_ask.delete_x_location", {
              locationName: locations.fetchedLocation.name,
            })}
          </strong>
        </Fragment>
      ),
      message: t("location_modal.text.delete_location_effect"),
      confirmText: t("all.button.delete"),
      confirmColor: "danger",
      cancelColor: "primary",
      cancelText: t("all.button.cancel"),
    });
    if (result) {
      dispatch(locationActions.toDelete(locations.fetchedLocation.id));
      dispatch(locationActions.clear());
      history.push(`/locations/?root=STOCK`);
      dispatch(locationActions.getAll("STOCK"));
    }
  }

  formatAddressInfo(address: Address) {
    if (address && address.street) {
      return (
        <>
          <p>{`${address.streetNumber || ""} ${
            address.streetNumberAdditionnal || ""
          } ${address.street || ""} ${address.complement || ""}`}</p>
          <p>{`${address.zipcode || ""} ${address.city || ""}`}</p>
          <p>{`${address.country || ""}`}</p>
        </>
      );
    }
    return null;
  }

  generateLineInfo(
    title: string,
    info: string,
    imgName: string,
    noFilledMsg?: string
  ) {
    return (
      <div className="row" style={{ margin: 0, paddingLeft: "10px" }}>
        <div style={{ marginTop: "10px", width: "30px" }}>
          {Picto(imgName, {
            style: { marginRight: "10px" },
            height: "2em",
            width: "2em",
            stroke: "#31c6b3",
            fill: "#31c6b3",
            strokeWidth: "0",
          })}
        </div>
        <div style={{ paddingLeft: "20px", maxWidth: "calc(100% - 30px)" }}>
          <p>
            <span className="infoLibelle">{title}</span> <br />
            {info ? (
              <span
                className={`infoDisplay ${
                  imgName === "meter" && "infoDisplayMeters"
                }`}
                style={{ overflowWrap: "break-word" }}
              >
                {info}
              </span>
            ) : noFilledMsg ? (
              <span className="noFilled" style={{ fontWeight: "bold" }}>
                {noFilledMsg}
              </span>
            ) : (
              <NoFilled />
            )}
          </p>
        </div>
      </div>
    );
  }

  formatContactInfo(contact: any) {
    return `${contact.civility} ${contact.lastName} ${contact.firstName} `;
  }

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { buttonSize, locations, t } = this.props;
    const { isOpen } = this.state;
    const locationContact = _.get(
      locations,
      "fetchedLocation.content.contacts[0]"
    );
    const locationAddress: Address | undefined =
      locations?.fetchedLocation?.content?.address;
    return (
      _.get(locations, "fetchedLocation") && (
        <div>
          {!_.get(locations, "fetchedLocation.tournee") && (
            <NavItem
              className="sidebar-item active"
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                margin: 0,
                marginBottom: "10px",
                marginLeft: "15px",
              }}
              onClick={this.toggle}
            >
              <Button
                size={buttonSize}
                id="HelpMeePlease"
                style={{ color: "#FFFFFF", paddingLeft: "0px" }}
              >
                <Info fill="#FFFFFF" height="1.5em" width="1.5em" />
                {t("all.location.location_info")}
              </Button>
            </NavItem>
          )}

          <Modal
            isOpen={isOpen}
            toggle={this.toggle}
            className="locationModal"
            size="lg"
          >
            <ModalHeader toggle={this.toggle} className="locationModalHeader">
              <Row style={{ margin: 0 }}>
                <span className="locationName">
                  {locations.fetchedLocation.name}
                </span>
                {!locations.fetchedLocation.tournee &&
                  this.couldEditOrDeleteSite() && (
                    <>
                      <span
                        className="addWidget clickable"
                        style={{ alignSelf: "center", marginLeft: "25px" }}
                      >
                        <LocationCreator
                          {...{ edited: () => console.log("CALLBACK EDITED") }}
                        />
                      </span>
                      <div
                        className="round"
                        id="locationModalDelete"
                        style={{
                          position: "relative",
                          marginLeft: "10px",
                          alignSelf: "center",
                          cursor: locations.fetchedLocation.hasChild
                            ? "unset"
                            : "pointer",
                        }}
                        onClick={
                          locations.fetchedLocation.hasChild
                            ? () => {}
                            : this.deleteLocation
                        }
                      >
                        <span className="absoluteCentered">
                          <SvgPoubelleBleu
                            height="1em"
                            width="1em"
                            fill={
                              locations.fetchedLocation.hasChild
                                ? "#fff"
                                : "#2c303b"
                            }
                          />
                        </span>
                        <UncontrolledTooltip
                          placement="bottom"
                          target="locationModalDelete"
                          style={{
                            backgroundColor: locations.fetchedLocation.hasChild
                              ? "#dc3545"
                              : "",
                          }}
                        >
                          {" "}
                          {locations.fetchedLocation.hasChild ? (
                            <div>
                              {t("all.text.not_possible_deletion")} <br />{" "}
                              {t(
                                "all.location.pls_first_delete_child_location"
                              )}
                            </div>
                          ) : (
                            t("all.location.delete_location")
                          )}
                        </UncontrolledTooltip>
                      </div>
                    </>
                  )}
              </Row>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md={6}>
                  {_.get(locations, "fetchedLocation.content.address") &&
                    this.generateLineInfo(
                      t("all.location.address"),
                      this.formatAddressInfo(
                        _.get(locations, "fetchedLocation.content.address")
                      ),
                      "address"
                    )}
                  {this.generateLineInfo(
                    t("all.text.note"),
                    _.get(locations, "fetchedLocation.content.note"),
                    "note"
                  )}
                </Col>

                <Col md={6} style={{ borderLeft: "1px solid lightgrey" }}>
                  {locationContact ? (
                    <div>
                      <div
                        className="row"
                        style={{ margin: 0, paddingLeft: "10px" }}
                      >
                        <div style={{ width: "30px" }}>
                          {Picto("contact", {
                            style: { marginRight: "10px" },
                            height: "2em",
                            width: "2em",
                            stroke: "#31c6b3",
                            fill: "#31c6b3",
                            strokeWidth: "0",
                          })}
                        </div>
                        <div
                          style={{
                            paddingLeft: "20px",
                            maxWidth: "calc(100% - 30px)",
                          }}
                        >
                          <h3 style={{ color: "#31c6b3" }}>
                            {t("all.location.contact")}
                          </h3>
                        </div>
                      </div>
                      <div>
                        {this.generateLineInfo(
                          t("all.text.name"),
                          this.formatContactInfo(locationContact),
                          ""
                        )}
                        {this.generateLineInfo(
                          t("all.contact.phone"),
                          _.get(locationContact, "phone"),
                          "phone"
                        )}
                        {this.generateLineInfo(
                          t("all.contact.email"),
                          _.get(locationContact, "mail"),
                          "email"
                        )}
                        {this.generateLineInfo(
                          t("all.text.note"),
                          _.get(locationContact, "note"),
                          "note"
                        )}
                      </div>
                    </div>
                  ) : (
                    this.generateLineInfo(
                      t("all.location.contact"),
                      "",
                      "contact",
                      t("all.contact.no_contact")
                    )
                  )}
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      )
    );
  }
}

function mapStateToProps(state: any) {
  const { locations, contacts, locales, users } = state;
  return {
    locations,
    contacts,
    locales,
    users,
  };
}

const connectedLocationModalComponent = compose<ComponentType<any>>(
  connect(mapStateToProps)
)(LocationModalComponent);
const tr = withTranslation()(connectedLocationModalComponent);
export default tr;
