import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { WithTranslation, withTranslation } from "react-i18next";

type Props = {
  onClose: any;
  message: string;
  title: string;
  confirmText: string;
  cancelText: string;
  confirmColor: string;
  cancelColor: string;
  className: string;
} & WithTranslation;

interface State {
  isOpen: boolean;
}

/**
 * @class ConfirmModal
 * @extends Component
 */
class ConfirmModal extends Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: true,
    };

    this.confirm = this.confirm.bind(this);
    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  /**
   * Permet d'ouvrir/fermer la modal
   *
   * @method toggle
   * @param {boolean} result Résultat
   */
  toggle(result = false) {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });

    const { onClose } = this.props;
    if (typeof onClose === "function") {
      onClose(result);
    }
  }

  /**
   * Utilise la méthode de fermeture pour la confirmation
   *
   * @method confirm
   */
  confirm() {
    this.toggle(true);
  }

  /**
   * Utilise la méthode de fermeture pour l'annulation
   *
   * @method cancel
   */
  cancel() {
    this.toggle(false);
  }

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const {
      message,
      title,
      confirmText,
      cancelText,
      confirmColor,
      cancelColor,
      className,
      t,
    } = this.props;
    const { isOpen } = this.state;

    const defaultMessage = t("confirm.msg.you_sure");
    const defaultTitle = t("confirm.title.warning");
    const defaultConfirmText = t("confirm.confirm_text.ok");
    const defaultCancelText = t("all.button.cancel");
    const defaultConfirmColor = "danger";
    const defaultCancelColor = "primary";

    let modalHeader = null;
    let cancelButton = null;

    modalHeader = (
      <ModalHeader toggle={this.cancel}>
        {title ? title : defaultTitle}
      </ModalHeader>
    );

    cancelButton = (
      <Button
        color={cancelColor ? cancelColor : defaultCancelColor}
        onClick={this.cancel}
      >
        {cancelText ? cancelText : defaultCancelText}
      </Button>
    );

    return (
      <Modal isOpen={isOpen} className={className}>
        {modalHeader}
        <ModalBody>{message ? message : defaultMessage}</ModalBody>
        <ModalFooter>
          {cancelButton}{" "}
          <Button
            color={confirmColor ? confirmColor : defaultConfirmColor}
            onClick={this.confirm}
          >
            {confirmText ? confirmText : defaultConfirmText}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const tr = withTranslation()(ConfirmModal);
export default tr;
