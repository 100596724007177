import {
  authHeader,
  authHeaderFile,
  handleResponse,
  handleResponseWithData,
} from "../_helpers";
import { handleResponseVoid } from "../_helpers/response-helper";

async function getAllRoundFull(gestionnaireId: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/rounds/full`,
    requestOptions
  );
  return handleResponse(response);
}

async function getAllDevices(gestionnaireId: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices`,
    requestOptions
  );
  return handleResponse(response);
}

async function getAllDevicesTools() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const response = await fetch(`/api/synchro/devices/tools`, requestOptions);
  return handleResponse(response);
}

async function getAllDevicesInfo(idDevice: any, gestionnaireId: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${idDevice}`,
    requestOptions
  );
  return handleResponse(response);
}

async function communication(communication: any, gestionnaireId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(communication),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/communication`,
    requestOptions
  );
  return handleResponse(response);
}

async function deviceCom(deviceId: any, gestionnaireId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${deviceId}/apply`,
    requestOptions
  );
  return handleResponseWithData(response);
}

async function deviceCancel(deviceId: any, gestionnaireId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${deviceId}/clean`,
    requestOptions
  );
  return handleResponse(response);
}

async function roundCom(roundId: any, gestionnaireId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/rounds/${roundId}/apply`,
    requestOptions
  );
  return handleResponseWithData(response);
}

async function rotateRound(roundId: any, gestionnaireId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/rounds/${roundId}/rotate`,
    requestOptions
  );
  return handleResponse(response);
}

async function roundCancel(roundId: any, gestionnaireId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/rounds/${roundId}/clean`,
    requestOptions
  );
  return handleResponse(response);
}

async function comWithAll(gestionnaireId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/apply/all`,
    requestOptions
  );
  return handleResponseWithData(response);
}

async function cancelAll(gestionnaireId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/clean/all`,
    requestOptions
  );
  return handleResponse(response);
}

async function updateDeviceName(
  deviceName: any,
  idDevice: any,
  gestionnaireId: any
) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(deviceName),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${idDevice}`,
    requestOptions
  );
  return handleResponse(response);
}

async function deleteDevice(idDevice: any, gestionnaireId: any) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${idDevice}/delete`,
    requestOptions
  );
  return handleResponse(response);
}

async function getAllMessages(gestionnaireId: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/messages`,
    requestOptions
  );
  return handleResponse(response);
}

async function getAllMarquages(gestionnaireId: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/marquages`,
    requestOptions
  );
  return handleResponse(response);
}

async function synchroDevice(deviceCode: any, gestionnaireId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(deviceCode),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${deviceCode}/sync`,
    requestOptions
  );
  return handleResponse(response);
}

async function synchroRound(roundCode: any, gestionnaireId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(roundCode),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/rounds/${roundCode}/sync`,
    requestOptions
  );
  return handleResponse(response);
}

async function addAffect(affect: any, gestionnaireId: any, deviceId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(affect),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${deviceId}/affect/add`,
    requestOptions
  );
  return handleResponse(response);
}

async function delAffect(affect: any, gestionnaireId: any, deviceId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(affect),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${deviceId}/affect/remove`,
    requestOptions
  );
  return handleResponse(response);
}

async function addDesaffect(
  desaffect: any,
  gestionnaireId: any,
  deviceId: any
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(desaffect),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${deviceId}/deaffect/add`,
    requestOptions
  );
  return handleResponse(response);
}

async function delDesaffect(
  desaffect: any,
  gestionnaireId: any,
  deviceId: any
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(desaffect),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${deviceId}/deaffect/remove`,
    requestOptions
  );
  return handleResponse(response);
}

async function getCurrentVersion() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(`/api/synchro/version/current`, requestOptions);
  return handleResponse(response);
}

async function getMiniVersion() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(`/api/synchro/version/minimum`, requestOptions);
  return handleResponse(response);
}

async function getFullAffect(gestionnaireId: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/fullaffect`,
    requestOptions
  );
  return handleResponse(response);
}

async function switchToPartiel(
  gestionnaireId: any,
  deviceSerial: any,
  rcList: Array<string>,
  isPartial: boolean
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(rcList),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${deviceSerial}/affect/partial?partial=${isPartial}`,
    requestOptions
  );
  return handleResponse(response);
}

async function addMessages(messages: any, gestionnaireId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(messages),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/messages/add`,
    requestOptions
  );
  return handleResponse(response);
}

async function addMarquages(marquages: any, gestionnaireId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(marquages),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/marquages/add`,
    requestOptions
  );
  return handleResponse(response);
}

async function getDevice(deviceSerial: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/device/${deviceSerial}`,
    requestOptions
  );
  return handleResponse(response);
}

async function saphirLogin(deviceSerial: string) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/sahpir/login/${deviceSerial}`,
    requestOptions
  );
  return handleResponse(response);
}

async function saphirLogout(deviceSerial: string) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/sahpir/logout/${deviceSerial}`,
    requestOptions
  );
  return handleResponse(response);
}

async function connectToDeviceTools(deviceSerial: string) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  const response = await fetch(
    `/api/synchro/gestionnaire/devices/${deviceSerial}/tools`,
    requestOptions
  );
  return handleResponse(response);
}

async function disconnectOfDeviceTools(deviceSerial: string) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  const response = await fetch(
    `/api/synchro/gestionnaire/devices/${deviceSerial}/tools/CLOSEDEVICE`,
    requestOptions
  );
  return handleResponse(response);
}

async function launchCommand(
  deviceSerial: string,
  commandName: string,
  options: FormData | string
) {
  const requestOptions = {
    method: "POST",
    headers: commandName === "DOWNLOADROUND" ? authHeaderFile() : authHeader(),
    body: options,
  };
  const response = await fetch(
    `/api/synchro/gestionnaire/devices/${deviceSerial}/tools/${commandName}`,
    requestOptions
  );
  return handleResponseVoid(response);
}

async function disaffectAllForDevice(
  gestionnaireId: any,
  deviceSerial: string
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  const response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${deviceSerial}/deaffect/all`,
    requestOptions
  );
  return handleResponse(response);
}

async function disaffectAllForRound(gestionnaireId: any, roundId: string) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  const response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/rounds/${roundId}/deaffect/all`,
    requestOptions
  );
  return handleResponse(response);
}

async function getListDeviceError() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const response = await fetch(`/api/synchro/device/error`, requestOptions);
  return handleResponse(response);
}

async function removeDeviceError(serial: string) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const response = await fetch(
    `/api/synchro/device/error/${serial}`,
    requestOptions
  );
  console.log(response);
  return handleResponse(response);
}

const reportService = {
  getAllRoundFull,
  getAllDevices,
  communication,
  getAllDevicesInfo,
  updateDeviceName,
  getAllMessages,
  getAllMarquages,
  synchroDevice,
  synchroRound,
  addAffect,
  delAffect,
  addDesaffect,
  delDesaffect,
  getCurrentVersion,
  getMiniVersion,
  getFullAffect,
  deviceCancel,
  deviceCom,
  roundCancel,
  roundCom,
  cancelAll,
  comWithAll,
  addMessages,
  addMarquages,
  getDevice,
  saphirLogin,
  saphirLogout,
  switchToPartiel,
  getAllDevicesTools,
  connectToDeviceTools,
  launchCommand,
  disconnectOfDeviceTools,
  rotateRound,
  disaffectAllForDevice,
  disaffectAllForRound,
  getListDeviceError,
  removeDeviceError,
  deleteDevice,
};

export default reportService;
