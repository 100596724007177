import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { Reducer, useEffect, useReducer, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import Switch from "./_component/Switch";
import EditionBleu from "../SvgComponents/EditionBleu";
import _ from "lodash";
import TextInput from "./TextInput";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import supportActions from "../_actions/support.actions";

const billingInput = [
  { type: "state.inactif.match", value: "" },
  { type: "state.removed.match", value: "" },
  { type: "default.location.id", value: "" },
  { type: "conf.facturation.encoding.csv", value: "" },
];

const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: "30vh",
  },
  rootTruncate: {
    color: "white !important",
  },
  root: {
    color: "white !important",
    width: "195px",
  },
}));

const BillingSupportAutochange: React.FC<
  {
    support: any;
    dispatch: Function;
    locations: any;
  } & WithTranslation
> = ({ support, t, dispatch, locations }) => {
  const [autoResolveState, setAutoResolveState] = useState<{
    truncate: boolean;
    truncateRight: boolean;
    replaceDate: boolean;
  }>({
    truncate: false,
    truncateRight: false,
    replaceDate: false,
  });
  const [propertyList, setPropertyList] = useState<any>([]);
  const [encodings, setEncodings] = useState<string[]>([]);

  const classes = useStyles();

  useEffect(() => {
    if (support.encodings) {
      setEncodings(support.encodings);
    }
  }, [support]);

  useEffect(() => {
    if (support.importbilling && support.importbilling.autochange) {
      const autochange = support.importbilling.autochange;
      setAutoResolveState({
        truncate: autochange.truncate,
        truncateRight: autochange.truncateRight,
        replaceDate: autochange.replaceDate,
      });
      setPropertyList(
        billingInput.map((el: any) => {
          const result = _.cloneDeep(el);
          if (autochange[el.type]) {
            result.value = autochange[el.type].oldValue;
          }
          return result;
        })
      );
    }
  }, [support.importbilling]);

  const handleSwitch = (type: "truncate" | "truncateRight" | "replaceDate") => {
    return (checked: boolean) => {
      const newAutoResolveState = { ...autoResolveState };
      newAutoResolveState[type] = checked;
      setAutoResolveState(newAutoResolveState);
    };
  };

  const AutoChangeSwitch = ({ type }: any) => {
    const value = _.get(autoResolveState, type);
    const hasChanged =
      value !== _.get(support, `importbilling.autochange.${type}`);
    return (
      <>
        <Switch check={value} handleCheck={handleSwitch(type)} />
        {hasChanged && <EditionBleu height="16px" fill="#38ef7d" />}
      </>
    );
  };

  const handleSendAutoChangeConfig = () => {
    dispatch(supportActions.sendAutoChangeconfiguration(autoResolveState));
    const object: any = {};
    console.log(propertyList);
    propertyList.forEach((el: any) => {
      object[el.type] = el.value;
    });
    dispatch(supportActions.sendBillingSetting(object));
  };

  const setTextInputField = (type: any, value: any) => {
    const clone: any = _.cloneDeep(propertyList);
    const i = clone.findIndex((el: any) => el.type === type);
    if (clone[i]) {
      clone[i].value = value;
      setPropertyList(clone);
    }
  };

  return (
    <div className="main-container">
      <div className="block">
        <h3 className="neon second">{t("support.subtitle.autochange")}</h3>
        <div className="allBlockInput">
          <div className="inputBlock">
            <div className="checkbox-container">
              <div className="resultBlock">
                {t("support.autochange.truncate")}
              </div>
              <div className="flex-box">
                <AutoChangeSwitch type="truncate" />
              </div>
              <div className="resultBlock" style={{ marginTop: 16 }}>
                {t("support.autochange.truncate_at")}
              </div>
              <div className="flex-box">
                <FormControl className="select-form-control">
                  <Select
                    onChange={(
                      newValue: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      handleSwitch("truncateRight")(
                        newValue.target.value === 1
                      );
                    }}
                    inputProps={{
                      classes: { root: classes.rootTruncate },
                      "aria-label": "Without label",
                    }}
                    MenuProps={{
                      classes: { paper: classes.menuPaper },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                      getContentAnchorEl: null,
                    }}
                    value={autoResolveState.truncateRight ? 1 : 0}
                    defaultValue={0}
                    disabled={!autoResolveState.truncate}
                  >
                    <MenuItem value={0}>
                      {t("support.autochange.left")}
                    </MenuItem>
                    <MenuItem value={1}>
                      {t("support.autochange.right")}
                    </MenuItem>
                  </Select>
                </FormControl>
                {autoResolveState.truncateRight !==
                  _.get(support, `importbilling.autochange.truncateRight`) && (
                  <EditionBleu height="16px" fill="#38ef7d" />
                )}
              </div>
            </div>
          </div>
          <div className="inputBlock">
            <div className="checkbox-container">
              <div className="resultBlock">
                {t("support.autochange.replace_date")}
              </div>
              <div className="flex-box">
                <AutoChangeSwitch type="replaceDate" />
              </div>
            </div>
          </div>
          {propertyList.map((el: any) => {
            const isInvalid = false;
            const isValid = false;
            const hasChanged = false;
            return (
              <div key={el.type} className="inputBlock">
                <div className="checkbox-container">
                  <div className="flex-box">
                    {el.type == "default.location.id" ? (
                      <div className="styled-input">
                        <div className="form__group field">
                          <InputLabel
                            id={`label-${el.type}`}
                            className="form__label"
                            style={{ top: "-24px" }}
                          >
                            Site par défaut
                          </InputLabel>
                          <Select
                            labelId={`label-${el.type}`}
                            className="form__field"
                            name="select"
                            onChange={(
                              newValue: React.ChangeEvent<{ value: unknown }>
                            ) => {
                              setTextInputField(
                                "default.location.id",
                                newValue.target.value
                              );
                            }}
                            inputProps={{
                              classes: {
                                root: classes.root,
                                input: "form__field",
                              },
                            }}
                            MenuProps={{
                              classes: { paper: classes.menuPaper },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "center",
                              },
                              getContentAnchorEl: null,
                            }}
                            value={
                              propertyList.find(
                                (el: { type: string; value: any }) =>
                                  el.type == "default.location.id"
                              ).value || "null"
                            }
                            label="test"
                          >
                            <MenuItem value="null">Aucun</MenuItem>
                            {locations.items &&
                              locations.items
                                .sort(
                                  (e1: any, e2: any) =>
                                    e1.content.id - e2.content.id
                                )
                                .map((el: any) => (
                                  <MenuItem
                                    key={`${el.content.id}`}
                                    value={`${el.content.id}`}
                                  >{`[${el.code}]${el.name}`}</MenuItem>
                                ))}
                          </Select>
                        </div>
                      </div>
                    ) : el.type === "conf.facturation.encoding.csv" ? (
                      <div className="styled-input">
                        <div className="form__group field">
                          <InputLabel
                            id={`label-${el.type}`}
                            className="form__label"
                            style={{ top: "-24px" }}
                          >
                            CSV source encoding
                          </InputLabel>
                          <Select
                            labelId={`label-${el.type}`}
                            className="form__field"
                            name="select"
                            onChange={(
                              newValue: React.ChangeEvent<{ value: unknown }>
                            ) => {
                              setTextInputField(
                                "conf.facturation.encoding.csv",
                                newValue.target.value
                              );
                            }}
                            inputProps={{
                              classes: {
                                root: classes.root,
                                input: "form__field",
                              },
                            }}
                            MenuProps={{
                              classes: { paper: classes.menuPaper },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "center",
                              },
                              getContentAnchorEl: null,
                            }}
                            value={
                              propertyList.find(
                                (el: { type: string; value: any }) =>
                                  el.type == "conf.facturation.encoding.csv"
                              ).value || null
                            }
                            label="test"
                          >
                            {encodings.map((encoding) => (
                              <MenuItem key={encoding} value={encoding}>
                                {encoding}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    ) : (
                      <TextInput
                        handleChange={(e: any) =>
                          setTextInputField(el.type, e.target.value)
                        }
                        text={t(`import_billing.${el.type}`)}
                        placeholder={t(`import_billing.${el.type}`)}
                        value={el.value}
                        isInvalid={isInvalid}
                        isValid={isValid && hasChanged}
                        type="text"
                        hasChanged={hasChanged}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {
        <button
          className="flash-button submitColor"
          onClick={handleSendAutoChangeConfig}
        >
          {t("all.button.send")}
        </button>
      }
    </div>
  );
};

function mapStateToProps(state: any) {
  const { locales, support, imports, locations } = state;
  return {
    locales,
    support,
    imports,
    locations,
  };
}

const connectedBillingSupportAutochange = connect(mapStateToProps)(
  BillingSupportAutochange
);
export default withTranslation()(connectedBillingSupportAutochange);
