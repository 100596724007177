import React, { Component, ComponentType } from "react";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { Column, ColumnData, SavedColumn } from "../_interfaces/column";
import CollonneVert from "../SvgComponents/ColonneVert";
import { User } from "../_entities/user";
import { compose } from "redux";
import _ from "lodash";

interface Props {
  listColumns: Array<ColumnData>;
  changeDisplay: Function;
  defaultConfig: boolean | null | undefined;
  saveOption: boolean | null | undefined;
  user: User;
  dispatch: Function;
  columns: any;
  localColumns: any;
  idSite: number;
  save: boolean | null | undefined;
  type: string;
  t: Function;
  listFields: any;
  value: any;
}

interface State {
  columns: Array<Column>;
  askSaveConfig?: boolean;
  savedColumnsList?: Array<SavedColumn>;
  showColumns?: boolean;
  columnSelected?: SavedColumn;
  formatColumns?: Array<Column>;
}

/**
 * Utiliser ColumnSelector :
 * Créer un state listColumn (default : []) qui contiendra les noms des colonnes dans le component qui l'utilise
 * Créer une property (ex : this.srcColumns) avec la liste complètes des colonnes possibles à afficher (en ajoutant un champ default: true pour que cette colonne soit fixe) et une propriete (ex : this.myFilter) contenant un filtre pour assurer l'intégrité des colonnes
 * Créer une méthode de callback qui servira à ColumnSelector pour envoyer la liste des colonnes selectionnées (A la reception, attention si une fonction filter a été passé dans la liste :
 *
 * displayingColumns: columns.map((c) => {
        if (c.filter) c.filter = this.myFilter;
        return c;
    })

 * Ajouter dans l'element BootstrapTable : Pour la propriété columns = {this.state.listColumns.length > 0 ? this.state.listColumns : this.srcColumns}
 */
class ColumnOrderSelector extends Component<any, State> {
  static defaultProps = {
    save: true,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      columns: props.value || [],
      showColumns: props.defaultShow || false,
    };
  }

  /**
   * Applique l'action demandé et envoi par callback au parent la nouvelle liste des colonnes à afficher
   *
   * @method changeColumnDisplaying
   * @memberof ColumnSelector
   * @param {any} columns Liste des colonnes à afficher (retour du Select)
   * @param {string} action Nom de l'action à effectuer
   */
  changeColumnDisplaying = (columns: any) => {
    if (!columns) return;
    const { changeDisplay } = this.props;
    const newColumns = columns.map((c: Column) => ({
      field: _.get(c, "value", _.get(c, "field")),
      way: _.get(c, "order", _.get(c, "way")),
    }));
    changeDisplay(newColumns);
    this.setState({ columns: newColumns });
  };

  arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { columns } = this.state;
    const newColumns = this.arrayMove(columns, oldIndex, newIndex);
    this.changeColumnDisplaying(newColumns);
  };

  render() {
    const { columns, showColumns } = this.state;
    const { t, listFields, handleOpen } = this.props;

    const formatColumns2 = _.entries(
      _.groupBy(listFields, (el: string) =>
        el.split(".").length > 1 ? el.split(".")[0] : "PDI"
      )
    ).map(([key, value]) => ({
      label: t(`columns.group.${key.toLowerCase()}`),
      options: _.concat(
        value.map((key) => ({
          order: "ASC",
          value: key,
          label: `${t(`columns.${key}`)} ASC`,
        })),
        value.map((key) => ({
          order: "DESC",
          value: key,
          label: `${t(`columns.${key}`)} DESC`,
        }))
      ).sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label === b.label) return 0;
        return 1;
      }),
    }));

    const selectedColumns = columns.map(({ field, way }: any) => ({
      value: field,
      order: way,
      label: `${t(`columns.${field}`)} ${way}`,
    }));

    const SortableSelect: any = SortableContainer(Select);

    const SortableMultiValue = SortableElement((props) => {
      const onMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };
      const innerProps = { onMouseDown };
      return <components.MultiValue {...props} innerProps={innerProps} />;
    });

    const formatGroupLabel = (data) => (
      <div style={{ color: "black", fontWeight: "bold", fontSize: "1.2em" }}>
        {data.label}
      </div>
    );

    return (
      <div>
        <Row style={{ margin: "0 0 0 10px" }}>
          <Button
            color="secondary"
            size="lg"
            outline
            active
            style={{
              borderColor: "white",
              backgroundColor: "white",
              color: "black",
              display: "inline-flex",
              alignItems: "center",
              marginLeft: "-14px",
            }}
            onClick={(e) => {
              e.preventDefault();
              handleOpen && handleOpen();
              this.setState({
                showColumns: !showColumns,
              });
            }}
          >
            <CollonneVert
              height="1.5em"
              width="1.5em"
              stroke="#31c6b3"
              fill="white"
              strokeWidth="1.5"
            />{" "}
            <h2 style={{ margin: "0 10px" }}>
              {t("custom_export.text.sorting")}
            </h2>
            {showColumns ? <FaCaretDown /> : <FaCaretRight />}{" "}
          </Button>
        </Row>
        {showColumns && (
          <Row style={{ marginTop: "10px" }}>
            <Col xs="11" sm="11" md="11">
              <SortableSelect
                // react-sortable-hoc props:
                axis="xy"
                onSortEnd={this.onSortEnd}
                distance={4}
                // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                components={{
                  MultiValue: SortableMultiValue,
                }}
                closeMenuOnSelect={false}
                isMulti
                id="listeColumns"
                isClearable={!_.isEmpty(columns)}
                options={formatColumns2.sort((a, b) => {
                  if (a.label < b.label) return -1;
                  if (a.label === b.label) return 0;
                  return 1;
                })}
                noOptionsMessage={() =>
                  formatColumns2.length === columns.length
                    ? t(
                        "column_selector.no_options_msg.no_more_column_available"
                      )
                    : t(
                        "column_selector.no_options_msg.no_corresponding_column"
                      )
                }
                onChange={this.changeColumnDisplaying}
                value={selectedColumns}
                placeholder={t("all.button.select")}
                formatGroupLabel={formatGroupLabel}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { columns, authentication, locales } = state;
  const { user } = authentication;

  return {
    columns,
    user,
    locales,
  };
}

const connectedColumnOrderSelector = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(ColumnOrderSelector);
export default withTranslation()(connectedColumnOrderSelector);
