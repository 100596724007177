import JwtDecode from "jwt-decode";

/**
 * Vérifie si l'utilisateur doit être déconnecté
 *
 * @method assertAlive vérifie si l'utilisateur doit être déconnecté
 * @param {Object} decoded token décodé
 */
export function assertAlive(decoded: any) {
  const now = Date.now().valueOf() / 1000;
  if (typeof decoded.exp !== "undefined" && decoded.exp < now) {
    throw new Error(`token expired: ${JSON.stringify(decoded)}`);
  }
  if (typeof decoded.nbf !== "undefined" && decoded.nbf > now) {
    throw new Error(`token expired: ${JSON.stringify(decoded)}`);
  }
  return true;
}

/**
 * Décode le token
 *
 * @method jwtHelper Décode le token
 * @returns {boolean | any} si le token est bien décodé
 */
export default function jwtHelper(): boolean | any {
  if (localStorage.getItem("token")) {
    try {
      const token = localStorage.getItem("token");
      return assertAlive(JwtDecode(token === null ? "" : token));
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  return false;
}

export function getAuthUser(): string | any {
  if (localStorage.getItem("token")) {
    try {
      const token = localStorage.getItem("token");
      const decodedToken: any = JwtDecode(token === null ? "" : token);
      return (
        decodedToken &&
        decodedToken.user &&
        decodedToken.user.role &&
        decodedToken.user.role.name
      );
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  return null;
}

export function getAuthId(): string | any {
  if (localStorage.getItem("token")) {
    try {
      const token = localStorage.getItem("token");
      const decodedToken: any = JwtDecode(token === null ? "" : token);
      return (
        decodedToken &&
        decodedToken.user &&
        decodedToken.user.role &&
        decodedToken.user.id
      );
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  return null;
}
