import React, { Component } from "react";
import {
  localeActions,
  reportActions,
  alarmActions,
  locationActions,
} from "../_actions";
import { locationService } from "../_services";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { AlertContent } from "../_components";
import LoadingBand from "../Bands/Loading";
import ErrorBand from "../Bands/Error";
import SvgEye from "../SvgComponents/Eye";

interface Props {
  reports: any;
  location: any;
  locations: any;
  locales: any;
  match: any;
  dispatch: Function;
  alert: any;
  alarms: any;
  user: any;
  history: any;
}

interface State {
  content: any;
}

class Report extends Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    let content = {};
    if (
      props.reports &&
      props.reports.fetchedReport &&
      props.alarms &&
      props.alarms.fetchedAlarm
    ) {
      content = {
        result: props.reports.fetchedReport.content,
        id: props.alarms.fetchedAlarm.content.alertName,
        locationId: props.match.params.locationId,
      };
    }

    return { content };
  }

  constructor(props: Props, state: State) {
    super(props);

    this.state = {
      content: {},
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { alertId, reportId, locationId } = match.params;

    dispatch(localeActions.load());
    locationService.get(locationId).then((site: any) => {
      dispatch(alarmActions.get(alertId, site.code));
      dispatch(reportActions.get(alertId, reportId, site.code));
    });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(reportActions.clear());
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    const { locations, match, dispatch } = this.props;
    const { locationId } = match.params;

    if (
      Object.keys(prevProps.locations).length === 0 &&
      Object.keys(locations).length === 0
    ) {
      dispatch(locationActions.get(locationId));
    }
  }

  render() {
    const { locales, reports } = this.props;
    const { content } = this.state;
    return (
      <div className="col-md-12">
        {reports.loading && <LoadingBand message="Chargement du rapport" />}
        {reports.error && <ErrorBand message={reports.error} />}
        {content &&
          Object.keys(content).length > 0 &&
          locales &&
          locales.locale && (
            <div className="table-info-container">
              <h2>
                <span>
                  <SvgEye height="1.3em" width="1.3em" fill="#31c6b3" />
                </span>
                Rapport{" "}
                {moment(reports.fetchedReport.createdAt).isValid()
                  ? `du ${moment(reports.fetchedReport.createdAt).format(
                      `DD/MM/YYYY ${moment.HTML5_FMT.TIME_SECONDS}`
                    )}`
                  : ""}
              </h2>
              <AlertContent
                locale={locales.locale}
                content={JSON.stringify(content)}
                isReport={true}
              />
            </div>
          )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, locales, reports, alert, alarms, locations } = state;
  const { user } = authentication;

  return {
    user,
    locales,
    reports,
    alert,
    alarms,
    locations,
  };
}

const mapping: any = connect(mapStateToProps)(Report);

const connectedReport = withRouter(mapping);
export default connectedReport;
