import _ from "lodash";
import vmeterConstants from "../_constants/virtual.constants";

export default function vmeters(
  state: any = { fetchedVMeter: {} },
  action: any
) {
  switch (action.type) {
    case vmeterConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case vmeterConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.vmeters,
        loading: false,
      };
    case vmeterConstants.GETALL_FAILURE:
      return {
        error: "Impossible d'obtenir la liste distante des compteurs virtuels",
      };
    case vmeterConstants.GETINFO_REQUEST:
      return {
        loading: true,
      };
    case vmeterConstants.GETINFO_SUCCESS:
      return {
        fetchedVMeter: action.fetchedVMeter,
        loadingRead: true,
        loadingConso: true,
        loadingAlarms: true,
        laodingLastRead: true,
      };
    case vmeterConstants.GETINFO_FAILURE:
      return {
        error:
          "Impossible d'obtenir les informations distantes du compteur virtuel",
      };
    case vmeterConstants.GETFULLINFO_REQUEST:
      return {
        ...state,
      };
    case vmeterConstants.GETFULLINFO_READS_SUCCESS:
      state.fetchedVMeter.statements = action.data;
      return {
        fetchedVMeter: state.fetchedVMeter,
        loadingRead: false,
        loadingConso: state.loadingConso,
        loadingAlarms: state.loadingAlarms,
        laodingLastRead: state.loadingLastRead,
      };
    case vmeterConstants.GETFULLINFO_CONSO_SUCCESS:
      state.fetchedVMeter.consumption = action.data;
      return {
        fetchedVMeter: state.fetchedVMeter,
        loadingRead: state.loadingRead,
        loadingConso: false,
        loadingAlarms: state.loadingAlarms,
        laodingLastRead: state.loadingLastRead,
      };
    case vmeterConstants.GETFULLINFO_ALARMS_SUCCESS:
      state.fetchedVMeter.alarms = action.data;
      return {
        fetchedVMeter: state.fetchedVMeter,
        loadingRead: state.loadingRead,
        loadingConso: state.loadingConso,
        loadingAlarms: false,
        laodingLastRead: state.loadingLastRead,
      };
    case vmeterConstants.GETFULLINFO_LASTREADS_SUCCESS:
      _.set(state, "fetchedVMeter.lastRead", action.data);
      return {
        fetchedVMeter: state.fetchedVMeter,
        laodingLastRead: false,
        loadingRead: state.loadingRead,
        loadingConso: state.loadingConso,
        loadingAlarms: state.loadingAlarms,
      };
    case vmeterConstants.GETFULLINFO_FAILURE:
      return {
        error:
          "Impossible d'obtenir les informations complètes distantes du compteur virtuel",
      };
    case vmeterConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case vmeterConstants.CREATE_SUCCESS:
      return {
        ...state,
        fetchedVMeter: action.fetchedVMeter,
        loading: false,
      };
    case vmeterConstants.CREATE_FAILURE:
      return {
        error: "Impossible de créer le compteur virtuel",
      };
    case vmeterConstants.EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case vmeterConstants.EDIT_SUCCESS:
      return {
        ...state,
        fetchedVMeter: action.fetchedVMeter,
        loading: false,
      };
    case vmeterConstants.EDIT_FAILURE:
      return {
        error: "Impossible de modifier le compteur virtuel",
      };
    case vmeterConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case vmeterConstants.DELETE_SUCCESS:
      return {
        items: action.vmeters.filter((it) => it.id !== action.vmeterId),
      };
    case vmeterConstants.DELETE_FAILURE:
      return {
        error: "Impossible de supprimer le compteur virtuel",
      };
    case vmeterConstants.GETSTATES_REQUEST:
      return {
        loading: true,
      };
    case vmeterConstants.GETSTATES_SUCCESS:
      return {
        fetchedVMeter: action.fetchedVMeter,
      };
    case vmeterConstants.GETSTATES_FAILURE:
      return {
        error:
          "Impossible d'obtenir les informations de relèves du compteur virtuel",
      };
    case vmeterConstants.GETALARMS_REQUEST:
      return {
        loading: true,
      };
    case vmeterConstants.GETALARMS_SUCCESS:
      return {
        fetchedVMeter: action.fetchedVMeter,
      };
    case vmeterConstants.GETALARMS_FAILURE:
      return {
        error: "Impossible d'obtenir les alarmes du compteur virtuel",
      };
    case vmeterConstants.GETDATA_SUCCESS:
      return {
        fetchedVMeter: action.fetchedVMeter,
      };
    case vmeterConstants.GETDATA_FAILURE:
      return {
        error:
          "Impossible d'obtenir les données complémentaires du compteur virtuel",
      };
    case vmeterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
