import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authentication from "./authentication.reducer";
import users from "./users.reducer";
import alert from "./alert.reducer";
import locations from "./locations.reducer";
import dashboards from "./dashboard.reducer";
import templates from "./templates.reducer";
import widgets from "./widgets.reducer";
import contacts from "./contacts.reducer";
import geolocation from "./geolocation.reducer";
import bookmarks from "./bookmarks.reducer";
import meters from "./meter.reducer";
import radios from "./radio.reducer";
import mails from "./mails.reducer";
import locales from "./locale.reducer";
import colors from "./color.reducer";
import columns from "./columnSelector.reducer";
import filters from "./filter.reducer";
import pdis from "./pdi.reducer";
import vmeters from "./virtual.reducer";
import notifications from "./notification.reducer";
import alarms from "./alarm.reducer";
import reports from "./report.reducer";
import imports from "./import.reducer";
import exports from "./export.reducer";
import mask from "./mask.reducer";
import synchro from "./synchro.reducer";
import synchrocom from "./synchrocom.reducer";
import gestionnaire from "./gestionnaire.reducer";
import tournee from "./tournee.reducer";
import profil from "./profil.reducer";
import remoteReading from "./remoteReading.reducer";
import maps from "./map.reducer";
import roundReports from "./roundReport.reducer";
import telereleve from "./teleReleve.reducer";
import support from "./support.reducer";
import enquete from "./enquete.reducer";
import meterGateway from "./metergateway.reducer";
import customexport from "./customexport.reducer";
import sensor from "./sensor.reducer";
import mapbox from "./mapbox.reducer";
import interval from "./interval.reducer";
import initialProgress from "./initialProgress.reducer";
import picture from "./picture.reducer";
import rndTemplate from "./rndTemplate.reducer";

const persistConfig = {
  key: "root",
  storage,
};

const appReducer = combineReducers({
  authentication,
  users,
  locations,
  alert,
  dashboards,
  templates,
  widgets,
  contacts,
  geolocation,
  bookmarks,
  meters,
  radios,
  mails,
  locales,
  colors,
  columns,
  filters,
  pdis,
  vmeters,
  notifications,
  alarms,
  reports,
  imports,
  exports,
  mask,
  synchro,
  synchrocom,
  gestionnaire,
  tournee,
  profil,
  telereleve,
  remoteReading,
  maps,
  roundReports,
  sensor,
  support,
  enquete,
  meterGateway,
  customexport,
  interval,
  mapbox,
  initialProgress,
  picture,
  rndTemplate,
});

const rootReducer = (state: any, action: any) => {
  let actualState = state;
  if (action.type === "USERS_LOGOUT") {
    actualState = undefined;
  }
  return appReducer(actualState, action);
};

export default persistReducer(persistConfig, rootReducer);
