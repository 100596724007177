import React from "react";
import { withTranslation } from "react-i18next";
import ComAnimation from "./ComAnimation";

const MessageCom = (props: any) => {
  const { message, isDevice, t } = props;
  return isDevice ? (
    <div className="message-com-container">
      <ComAnimation />
      <h5 style={{ color: "#31c6b3" }}>
        {message
          ? t(`synchro.command.${message.toLowerCase()}`)
          : t("synchro.command.listdevice")}
      </h5>
    </div>
  ) : (
    <div />
  );
};

export default withTranslation()(MessageCom);
