import searchService from "../_services/search.service";

/**
 * Effectue une recherche avec la requête passée en paramètre
 *
 * @method search
 * @param {any} query Requête
 * @param {string} site Code du site
 * @returns {Object} Le résultat de recherche
 */
function search(query: any, site = null) {
  return searchService.search(query, site);
}

/**
 * Effectue une recherche spécifique avec la requête passée
 * en paramètre
 *
 * @method specificSearch
 * @param {any} query Requête
 * @param {string} site Code du site
 * @param {string} specific Element spécifique
 * @returns {Object} Le résultat de recherche
 */
function specificSearch(query: any, site: string, specific: string) {
  return searchService.specificSearch(query, site, specific);
}

const searchActions = {
  search,
  specificSearch,
};

export default searchActions;
