import { authHeader, handleResponse } from "../_helpers";
import { TagDto, TemplateDto } from "../_interfaces/TourneeCreationTypes";

const uri = "/api/template";

async function createTemplate(template: TemplateDto) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(template),
  };
  let response = await fetch(`${uri}/`, requestOptions);
  return handleResponse(response);
}

async function getAllTemplates() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(`${uri}/`, requestOptions);
  return handleResponse(response);
}

async function updateTemplate(template: TemplateDto) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(template),
  };
  let response = await fetch(`${uri}/${template.id}`, requestOptions);
  return handleResponse(response);
}

async function deleteTemplate(templateId: number) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  let response = await fetch(`${uri}/${templateId}`, requestOptions);
  return handleResponse(response);
}

async function createTag(tag: TagDto) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(tag),
  };
  let response = await fetch(`${uri}/tag`, requestOptions);
  return handleResponse(response);
}

async function getAllTag() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(`${uri}/tag`, requestOptions);
  return handleResponse(response);
}

async function updateTag(tag: TagDto) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(tag),
  };
  let response = await fetch(`${uri}/tag/${tag.id}`, requestOptions);
  return handleResponse(response);
}

async function deleteTag(tagId: number) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  let response = await fetch(`${uri}/tag/${tagId}`, requestOptions);
  return handleResponse(response);
}

const rndTemplateService = {
  createTemplate,
  getAllTemplates,
  updateTemplate,
  deleteTemplate,
  createTag,
  getAllTag,
  updateTag,
  deleteTag,
};

export default rndTemplateService;
