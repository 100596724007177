import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import DragIcone from "./TaskComponent/DragIcone";
import TaskButtons from "./TaskComponent/TaskButtons";
import RoundInfo from "./TaskComponent/RoundInfo";
import DeviceInfo from "./TaskComponent/DeviceInfo";
import synchrocomActions from "../../_actions/synchrocom.actions";
import MessageCom from "./TaskComponent/MessageCom";
import CheckBox from "./TaskComponent/CheckBox";
import { Spinner } from "reactstrap";
import synchroAction from "../../_actions/synchro.actions";
import SvgCroixNoir from "../../SvgComponents/croixNoir";
import SvgAttentionJaune from "../../SvgComponents/AttentionJaune";

const nodeStyle = {
  display: "flex",
  marginRight: "5px",
};

const BestTask = (props) => {
  const {
    task,
    dispatch,
    drag,
    match,
    column,
    multi,
    multiList,
    loadingList,
    rounds,
    labels,
    loadingLock,
    devicesError,
    deviceErrorList,
    t,
  } = props;
  const { hasCommunication } = task;
  const { params } = match;
  const { GestionnaireId } = params;

  const [enter, setEnter] = useState(false);
  const [loading, setLoading] = useState(false);

  const col = task.serial ? "device" : "round";
  const reversedColumn = task.serial ? "round" : "device";

  const isLastChecked = multi && multiList[0] === task.id;

  //const loading = col === 'device' ? hasCommunication : rounds[task.id];

  let isAffect = labels
    ? column === "round"
      ? labels.affect.find((el) => el.tournee === drag && el.device === task.id)
      : labels.affect.find((el) => el.tournee === task.id && el.device === drag)
    : [];
  if (!isAffect) {
    isAffect = labels
      ? column === "round"
        ? labels.loaded.find(
            (el) => el.tournee === drag && el.device === task.id
          )
        : labels.loaded.find(
            (el) => el.tournee === task.id && el.device === drag
          )
      : [];
  }
  useEffect(() => {
    if (!drag) setEnter(false);
  }, [drag]);

  useEffect(() => {
    if (loadingList?.includes(task.id)) setLoading(true);
    else if (!hasCommunication && !rounds[task.id] && !loadingLock) {
      setLoading(false);
    }
  }, [loadingList]);

  useEffect(() => {
    if (task.serial) {
      if (hasCommunication) setLoading(true);
      else if (!loadingLock) {
        setLoading(false);
      }
    }
  }, [hasCommunication]);

  useEffect(() => {
    if (task.rndCode) {
      if (rounds[task.id]) setLoading(true);
      else if (!loadingLock) {
        setLoading(false);
      }
    }
  }, [rounds]);

  const removeDeviceError = () => {
    dispatch(synchrocomActions.removeDeviceError(task.id));
  };

  useEffect(() => {
    if (
      Array.isArray(devicesError) &&
      devicesError.map((el) => el.second).includes(task.id)
    ) {
      console.log(task.id);
      setTimeout(removeDeviceError, 15000);
    }
  }, [devicesError]);

  const dragStart = () => {
    dispatch(synchrocomActions.dragStart(task.id, col));
  };

  const dragEnter = () => {
    setEnter(true);
  };

  const dragLeave = () => {
    setEnter(false);
  };

  const drop = () => {
    if (!loading) {
      if (column !== col && !isAffect) {
        const round = task.serial ? drag : task.id;
        const device = task.serial ? task.id : drag;
        if (multi) {
          switch (multi) {
            case "device":
              multiList.forEach((deviceId) =>
                dispatch(
                  synchrocomActions.addAffect([round], GestionnaireId, deviceId)
                )
              );
              break;
            case "round":
              dispatch(
                synchrocomActions.addAffect(multiList, GestionnaireId, device)
              );
              break;
            default:
          }
        } else {
          dispatch(
            synchrocomActions.addAffect([round], GestionnaireId, device)
          );
        }
      }
      dispatch(synchrocomActions.dragEnd(task.id));
    }
  };

  const dragEnd = (e) => {
    dispatch(synchrocomActions.dragEnd(task.id));
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const getBackground = () => {
    if (loading) {
      return "#333";
    }
    if (
      Array.isArray(devicesError) &&
      devicesError.find((el) => el.second === task.id)
    ) {
      return "#dc3545";
    }
    if (
      (multiList && multiList.includes(task.id) && !isLastChecked) ||
      isAffect
    ) {
      return "lightgrey";
    }
    if (column === col) {
      return "white";
    }
    if (enter) {
      return "lightgreen";
    }
    return "white";
  };

  return (
    <div className={"node-wrapper"} style={nodeStyle}>
      {multi && multi !== reversedColumn && (
        <div style={{ marginRight: "5px" }}>
          <CheckBox id={task.id} col={col} />
        </div>
      )}
      <div
        className={`task-wrapper ${isLastChecked && "multi"}`}
        draggable
        onDragStart={dragStart}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDragOver={dragOver}
        onDrop={drop}
        onDragEnd={dragEnd}
        style={{
          backgroundColor: getBackground(),
          opacity: isAffect ? 0.5 : 1,
        }}
      >
        <div
          className={"event-wrapper"}
          style={{
            pointerEvents: drag || loading ? "none" : "all",
            zIndex: 1000,
          }}
        >
          <div className="task-header">
            <div className="left">
              <DragIcone />
              <h3>{task.content}</h3>
              <h4 style={{ color: loading ? "#31c6b3" : "#000" }}>
                {task.serial || task.rndCode}
              </h4>
            </div>
            <div className="right">
              {loading && col === "round" ? (
                <Spinner
                  animation="grow"
                  size="2em"
                  role="status"
                  aria-hidden="true"
                  style={{
                    position: "absolute",
                    right: "10px",
                    color: "#31c6b3",
                  }}
                />
              ) : loading ? (
                <div />
              ) : Array.isArray(devicesError) &&
                devicesError.map((el) => el.second).includes(task.id) ? (
                <div
                  className="svg"
                  onClick={removeDeviceError}
                  style={{
                    height: "30px",
                    width: "30px",
                    marginRight: "10px",
                    marginLeft: "auto",
                    cursor: "pointer",
                  }}
                >
                  <SvgCroixNoir fill="white" />
                </div>
              ) : (
                <TaskButtons task={task} />
              )}
            </div>
          </div>
          {Array.isArray(devicesError) &&
          devicesError.map((el) => el.second).includes(task.id) ? (
            <div
              style={{
                color: "white",
                height: "calc(100% - 40px)",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <SvgAttentionJaune fill="white" />
              {deviceErrorList && deviceErrorList[task.id] ? (
                <h4 style={{ maxWidth: "80%", textAlign: "center" }}>
                  {t("device_info_error_load_round")}
                  <br />
                  {deviceErrorList[task.id]
                    .map((el) => el.second)
                    .filter((el) => el !== task.id)
                    .join(",")}
                </h4>
              ) : (
                <h4
                  style={{
                    maxWidth: "60%",
                    minWidth: "fit-content",
                    textAlign: "center",
                  }}
                >
                  {t(
                    `device_info.error_text.${
                      devicesError.find((el) => el.second === task.id).first
                    }`
                  )}
                  <br />
                  {t(`device_info.error_text.contact`)}
                </h4>
              )}
            </div>
          ) : loading ? (
            <MessageCom
              isDevice={col === "device"}
              message={task.currentCommand}
            />
          ) : (
            <div className="task-body">
              {task.rndCode ? (
                <RoundInfo task={task} />
              ) : (
                <DeviceInfo task={task} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { synchrocom } = state;
  const {
    drag,
    column,
    multi,
    multiList,
    loadingList,
    rounds,
    labels,
    loadingLock,
    devicesError,
    deviceErrorList,
  } = synchrocom;
  return {
    drag,
    column,
    multi,
    multiList,
    loadingList,
    rounds,
    labels,
    loadingLock,
    devicesError,
    deviceErrorList,
  };
}

const mapping = connect(mapStateToProps)(BestTask);

const connectedTask = withRouter(mapping);
const tr = withTranslation()(connectedTask);
export default tr;
