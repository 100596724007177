import React, { Component, ComponentType, useEffect, useState } from "react";
import { connect } from "react-redux";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { enqueteActions } from "../_actions";
import BurgerHeader from "../SvgComponents/BurgerHeader";
import EnqueteSvg from "../SvgComponents/EnqueteSvg";
import AjoutBleu from "../SvgComponents/AjoutBleu";
import LinkBleu from "../SvgComponents/LinkBleu";
import { Alert } from "../_entities/alert";
import SvgEditionBleu from "../SvgComponents/EditionBleu";
import Loading from "../_animations/Loading";
import SvgPoubelleBleu from "../SvgComponents/PoubelleBleu";
import ErrorBand from "../Bands/Error";
import StepPopover from "../_components/StepPopover";
import { compose } from "redux";

function mapStateToProps({ enquete }) {
  return { enquete };
}

class EnquetePageList extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(enqueteActions.getAll());
  }

  handleRemoveEnquete(enqueteContent: string) {
    const { dispatch } = this.props;
    dispatch(enqueteActions.deleteEnquetePage(enqueteContent));
  }

  DeleteButton = ({ cellContent, row, t }) => {
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => console.log(open), [open]);
    return (
      <span
        id={`enquetes-${cellContent}-remove`}
        className={`${!row.used ? "clickable" : ""} round`}
        role="presentation"
        style={{ marginLeft: "10px" }}
        onClick={(event) => {
          event.stopPropagation();
          setOpen(true);
        }}
      >
        <SvgPoubelleBleu
          height="1em"
          width="1em"
          fill={row.used && "lightgray"}
        />
        <UncontrolledTooltip
          placement="bottom"
          target={`enquetes-${cellContent}-remove`}
        >
          {row.used
            ? t("enquete.text.remove_impossible_in_used")
            : t("enquetes.text.remove_enquete")}
        </UncontrolledTooltip>
        <StepPopover
          id={`confirmManualPopover`}
          target={`enquetes-${cellContent}-remove`}
          open={open}
          onClose={() => setOpen(false)}
          permission
          action={() => this.handleRemoveEnquete(cellContent)}
          title={t("enquetes.popover.title")}
          confirmText={t("enquete.popover.confirm_text")}
        />
      </span>
    );
  };

  render() {
    const {
      enquete,
      t,
      match: {
        params: { GestionnaireId },
      },
      history,
    } = this.props;
    return (
      <div>
        {enquete.items ? (
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            {enquete.error && <ErrorBand message={enquete.error} />}
            <div className="table-info-container">
              <h2>
                <EnqueteSvg
                  height="1em"
                  width="1em"
                  fill="#31c6b3"
                  className="svg-table-title"
                />{" "}
                {t("sidebar_synchro.nav_link.enquiry_plural")}
                <UncontrolledDropdown className="float-right">
                  <DropdownToggle nav style={{ paddingTop: 0 }}>
                    <BurgerHeader fill="#808080" height="1em" width="1em" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem href="enquetes/add">
                      <AjoutBleu width="1em" style={{ marginRight: 10 }} />
                      {t("enquete.text.add_enq_page")}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="enquetes/linkround">
                      <LinkBleu width="1em" style={{ marginRight: 10 }} />
                      {t("enquete.text.link_enq_round")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </h2>
              <BootstrapTable
                keyField="enqNumPage"
                data={enquete.items}
                columns={[
                  {
                    dataField: "enqName",
                    text: t("enquete.text.name_enquete_page"),
                    sort: true,
                    headerStyle: () => {
                      return { width: "30%" };
                    },
                  },
                  {
                    dataField: "zoneList",
                    text: t("enquete.text.field_list"),
                    formatter: (field) => (
                      <div className="fields-enquete">
                        {" "}
                        {field.map((el) => (
                          <p>
                            <span style={{ float: "left", width: "20ch" }}>
                              {el.libelle}
                            </span>{" "}
                            <span style={{ fontStyle: "italic" }}>
                              (
                              {_.capitalize(t(`enquete.field_type.${el.type}`))}
                              )
                            </span>
                          </p>
                        ))}
                      </div>
                    ),
                  },
                  {
                    dataField: "enqNumPage",
                    align: "center",
                    text: "",
                    headerStyle: () => {
                      return { width: "104px", textAlign: "left" };
                    },
                    formatter: (cellContent: any, row: Alert) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            id={`enquetes-${cellContent}-edit`}
                            className={`${!row.used ? "clickable" : ""} round`}
                            role="presentation"
                            style={{ marginLeft: "10px" }}
                            onClick={() =>
                              !row.used &&
                              history.push({
                                pathname: `/gestionnaires/${GestionnaireId}/synchronisation/enquetes/${cellContent}`,
                              })
                            }
                          >
                            <SvgEditionBleu
                              height="1em"
                              width="1em"
                              fill={row.used && "lightgray"}
                            />
                            <UncontrolledTooltip
                              placement="bottom"
                              target={`enquetes-${cellContent}-edit`}
                            >
                              {" "}
                              {row.used
                                ? t("enquete.text.edit_impossible_in_used")
                                : t("enquetes.text.edit_enquete")}
                            </UncontrolledTooltip>
                          </span>
                          <this.DeleteButton
                            cellContent={cellContent}
                            row={row}
                            t={t}
                          />
                        </div>
                      );
                    },
                  },
                ]}
                bootstrap4
                hover
                bordered={false}
                filter={filterFactory()}
              />
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const connectedEnquetePageList = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(EnquetePageList);
export default withTranslation()(connectedEnquetePageList);
