import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

function chronologie(a, b) {
  return new Date(a) - new Date(b);
}

const formatDate = (date, zoom) => {
  switch (zoom) {
    case "Hour":
      return moment(date).format("DD/MM/YYYY HH:mm");
    case "Day":
      return moment(date).format("DD/MM/YYYY");
    case "Week":
      return moment(date).format("DD/MM/YYYY");
    case "Month":
      return moment(date).format("MM/YYYY");
    case "Year":
      return moment(date).format("YYYY");
    default:
  }
};

const BarChart = (props) => {
  const { id, widgets, zoom, colors, t } = props;

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      t1: false,
      t2: false,
      datalabels: {
        display: false,
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: "80px",
          bottom: 0,
        },
      },
      emptyOverlay: {
        message: t("all.text.no_data_available"),
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    },
  };

  let labels = widgets.data[id].data.map((el) => el.date);
  labels.sort(chronologie);
  labels = _.uniq(labels.map((el) => formatDate(el, zoom)));

  const types = _.uniq(
    widgets.data[id].data.map((el) => Object.keys(el.types)).flat()
  );

  const data = types.map((type, index) => ({
    label: t(`alarm.type.${type.toLowerCase()}`),
    data: labels.map((date) => {
      const item = widgets.data[id].data.find(
        (el) => el.types[type] !== null && formatDate(el.date, zoom) === date
      );
      if (item) {
        return index + 1;
      } else {
        return 0;
      }
    }),
    backgroundColor: colors.color.chart.alarmType[type],
    stack: "full",
  }));
  console.log(data, labels);
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Bar data={{ datasets: data, labels }} options={options} />
    </div>
  );
};

function mapStateToProps(state) {
  const { widgets } = state;
  return {
    widgets,
  };
}

const mapping = connect(mapStateToProps)(BarChart);

const connectedChart = withRouter(mapping);
const tr = withTranslation()(connectedChart);
export default tr;
