import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Tagify, { TagifySettings } from "@yaireo/tagify";
import { MixedTags } from "@yaireo/tagify/dist/react.tagify";
import { WithTranslation, withTranslation } from "react-i18next";
import Tag from "../_components/Tag";
import {
  TagDto,
  TemplateCreationFormType,
} from "../_interfaces/TourneeCreationTypes";
import "@yaireo/tagify/src/tagify.scss";
import _ from "lodash";

export const LINE_INPUT_LIMIT = 30;

const useOutsideClick = (callback) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref]);

  return ref;
};

const TemplateLineInput: React.FC<
  {
    tags: TagDto[];
    value: string;
    focusedLine: number;
    lineNumber: 1 | 2 | 3 | 4 | 5;
    setFocusedLine: Dispatch<SetStateAction<number>>;
    disabled: boolean;
    dispatchFormChange: Dispatch<{
      type: keyof Omit<TemplateCreationFormType, "id">;
      text: string;
    }>;
    isLoaded: boolean;
  } & WithTranslation
> = ({
  tags,
  value,
  focusedLine,
  lineNumber,
  setFocusedLine,
  disabled,
  dispatchFormChange,
  t,
  isLoaded,
}) => {
  const [whitelist, setWhitelist] = useState<any[]>([]);
  const [inputLength, setInputLength] = useState<number>(0);
  const tagifyRef = useRef<Tagify & any>();
  const containerRef = useRef<HTMLDivElement>(null);

  // Tagify settings object
  const settings: TagifySettings<{ id: number; value: string; title: string }> =
    {
      pattern: /[A-Za-z_*]/, // <- must define "patten" in mixed mode
      dropdown: {
        enabled: 0,
        position: "text",
      },
      editTags: false,
      transformTag: () => null,
      enforceWhitelist: true,
      mixTagsInterpolator: ["#{(", ")}#"],
    };

  const handleAddTag = (tag: TagDto) => {
    let tagify: Tagify = tagifyRef.current!;
    const tagifyTag = whitelist.find((wlTag: any) => tag.id === wlTag.id);
    const actualTag = tagify.getTagElmByValue(tag.name);
    if (actualTag) {
      tagify.removeTags(actualTag);
    } else if (calculateInputLength(value) < LINE_INPUT_LIMIT) {
      const tagElm = tagify.createTagElem(tagifyTag);
      tagify.injectAtCaret(tagElm);

      tagify.placeCaretAfterNode(tagElm);
    }
  };

  const onTagChange = (e: any) => {
    let newLineValue: string =
      e.detail.value === undefined ? "" : e.detail.value;
    const tagify: Tagify = tagifyRef.current!;
    dispatchFormChange({ type: `line${lineNumber}`, text: newLineValue });
    calculateInputLength(newLineValue);
  };

  const onOutsideClick = () => {
    setFocusedLine(0);
  };

  const calculateInputLength = (value: string) => {
    const tagify = tagifyRef.current!;
    const selectedTags = tagify.getTagElms();
    let stringLength = value
      .replaceAll(/[\n\r]/g, "")
      .replaceAll(/#\{\(.*?\)\}#/g, "").length;
    selectedTags.forEach((tag: any) => {
      stringLength += tag.__tagifyTagData.size;
    });
    return stringLength;
  };

  const ref = useOutsideClick(onOutsideClick);

  useEffect(() => {
    const tagify: Tagify = tagifyRef.current!;
    const newWhitelist = tags.map((tag) => ({
      id: tag.id,
      value: tag.name,
      size: tag.size,
    }));
    setWhitelist(newWhitelist);
    const listUsedTags = tagify.value;
    listUsedTags.forEach((usedTag) => {
      const newTag = newWhitelist.find((wlTag) => wlTag.id === usedTag.id);
      if (!_.isEqual(newTag, usedTag)) {
        const oldTag = tagify.getTagElmByValue(usedTag.value)!;
        tagify.replaceTag(oldTag, newTag || { ...usedTag, value: "unknown" });
      }
    });
  }, [tags]);

  useEffect(() => {
    const tagify: Tagify = tagifyRef.current!;
    tagify.loadOriginalValues(value);
  }, [isLoaded]);

  useEffect(() => {
    const tagify: Tagify = tagifyRef.current!;
    setInputLength(calculateInputLength(tagify.getInputValue()));
  }, [value]);

  useEffect(() => {
    const tagify: Tagify = tagifyRef.current!;
    const input: HTMLElement = document
      .getElementById(`div-line-${lineNumber}`)
      .getElementsByClassName("tagify__input")[0] as HTMLElement;
    input.onkeydown = (event) => {
      const allowedKeys = [
        "Backspace",
        "Delete",
        "ArrowUp",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight",
      ];
      if (!allowedKeys.includes(event.key) && inputLength >= LINE_INPUT_LIMIT) {
        event.preventDefault();
      }
      const timeout = setTimeout(() => {
        setInputLength(calculateInputLength(tagify.getInputValue()));
      }, 100);
    };
  }, [inputLength]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      onFocus={() => setFocusedLine(lineNumber)}
      ref={ref}
    >
      <div
        id={`div-line-${lineNumber}`}
        ref={containerRef}
        className="template-line-container"
      >
        <div className="template-line-label">
          {t("tournee_creation.input_label.line_x", { lineNumber: lineNumber })}
          :
        </div>
        <MixedTags
          tagifyRef={tagifyRef}
          settings={settings}
          placeholder={t("tournee_creation.input_label.line_x", {
            lineNumber: lineNumber,
          })}
          className={"myTags"}
          onChange={onTagChange}
          onRemove={onTagChange}
          whitelist={whitelist}
          disabled={disabled}
        />
      </div>
      {inputLength > LINE_INPUT_LIMIT && (
        <div className="template-warning-text">
          {t("tournee_creation.input_error.line_too_long")}
        </div>
      )}
      {focusedLine === lineNumber && (
        <>
          {inputLength <= LINE_INPUT_LIMIT && (
            <div
              style={{
                textAlign: "end",
                maxWidth: "400px",
                marginLeft: "100px",
              }}
            >
              {t(
                `tournee_creation.input_info.remaining_characters${
                  LINE_INPUT_LIMIT - inputLength >= 2 ? "_plural" : ""
                }`,
                { nbRemainingChar: LINE_INPUT_LIMIT - inputLength }
              )}
            </div>
          )}
          <div className="template-tags-list-container">
            {tags.map((tag) => {
              const isSelected =
                tagifyRef.current.value.find((el) => el.value === tag.name) !==
                undefined;
              return (
                <Tag
                  key={tag.id}
                  name={tag.name}
                  onClick={() => handleAddTag(tag)}
                  isSelected={isSelected}
                  popoverText={t("tournee_creation.popover.tag_too_long")}
                  disabled={
                    LINE_INPUT_LIMIT - inputLength < tag.size && !isSelected
                  }
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

const tr = withTranslation()(TemplateLineInput);

export default tr;
