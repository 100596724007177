import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { connect } from "react-redux";
import _ from "lodash";
import "./style.scss";
import filterFactory, {
  customFilter,
  selectFilter,
  textFilter,
  dateFilter,
} from "react-bootstrap-table2-filter";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
import { WithTranslation, withTranslation } from "react-i18next";
import paginationFactory from "react-bootstrap-table2-paginator";
import history from "../_helpers/history";
import { locales, locations } from "../_interfaces/reducers";
import {
  localeActions,
  locationActions,
  mapActions,
  tourneeActions,
} from "../_actions";
import ListTools from "../List/ListTools";
import { translate } from "../_helpers";
import SvgPdiBlanc from "../SvgComponents/PdiBlanc";
import { QueryBuilder } from "../QueryBuilder";
import listOperator from "../_shared/OperatorList";
import getC from "./Picto_correspondance";
import StaticList from "./StaticList";
import { downloadCSV } from "../FileDownload";
import { translateBasicAlarmForRound } from "../_helpers/locale-helpers";
import Loading from "../_animations/Loading";
import TelereleveModal from "./TelereleveModal";
import BurgerHeader from "../SvgComponents/BurgerHeader";
import CustomSelect from "./CustomSelect";

type Props = {
  locales: locales;
  dispatch: any;
  locationId: number;
  match: any;
  locations: locations;
  location: any;
  history: any;
  drag: Boolean;
  handleChange: any;
  tournee: any;
} & WithTranslation;

/**
 * @class Search
 * @extends {Component}
 */
class TourneeFiches extends Component<Props, any> {
  /**
   * @constructor
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      query: "",
      results: {},
      data: [],
      info: {},
      isOpen: false,
      activeTab: null,
      key: "globalSearch",
      enterPress: false,
      init: false,
      zoom: "day",
      startDate: moment().add(-1, "month").startOf("day").toISOString(true),
      endDate: moment().endOf("day").toISOString(true),
      replaceMoreRecent: true,
      noChange: false,
      rotateOpen: false,
      filter: {},
      dataLength: null,
      paginationPage: 1,
    };

    this.getColumns = this.getColumns.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.receiveDataFilter = this.receiveDataFilter.bind(this);
    this.onClickSynchro = this.onClickSynchro.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.getTelereleveReads = this.getTelereleveReads.bind(this);
    this.fillTelereleveReads = this.fillTelereleveReads.bind(this);
    this.toggleReplaceMoreRecent = this.toggleReplaceMoreRecent.bind(this);
    this.rotateFiches = this.rotateFiches.bind(this);
    this.handleFastFilter = this.handleFastFilter.bind(this);
    this.afterFilter = this.afterFilter.bind(this);
    this.dateFilterCustom = this.dateFilterCustom.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { tourneeCode, locationId } = match.params;

    dispatch(localeActions.load());
    if (tourneeCode) {
      dispatch(tourneeActions.getAllFicheTournee(tourneeCode));
      dispatch(tourneeActions.getTournee(tourneeCode));
    } else {
      dispatch(locationActions.get(locationId));
    }
    const mountFastFilters = localStorage.getItem("tourneeFicheFilter-fast");
    if (mountFastFilters) {
      this.setState({ filter: JSON.parse(mountFastFilters) });
    }

    const mountPage = localStorage.getItem(`tourneeFichesPage-${tourneeCode}`);
    if (mountPage) {
      this.setState({ paginationPage: parseInt(mountPage, 10) });
    }
    window.addEventListener("resize", () =>
      this.setState({ init: this.state.init })
    );
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(mapActions.clearMarker());
    dispatch(tourneeActions.clear());
  }

  onClickSynchro() {
    const { dispatch, match } = this.props;
    const { tourneeCode } = match.params;
    dispatch(tourneeActions.invalidCache(tourneeCode));
    window.location.reload();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: any,
    snapshot?: any
  ) {
    const { locations, dispatch } = this.props;
    const { init, dataLength, filter } = this.state;
    const docs: any = document.getElementsByClassName("unsortable-input");
    if (docs.length > 0) {
      for (let i = 0; i < docs.length; i++) {
        const doc = docs[i];
        doc.addEventListener("click", (e: any) => {
          e.preventDefault();
        });
      }
    }

    if (locations.fetchedLocation && !init) {
      dispatch(
        tourneeActions.getAllFicheTournee(locations.fetchedLocation.code)
      );
      dispatch(tourneeActions.getTournee(locations.fetchedLocation.code));
      this.setState({ init: true });
    }
  }

  /**
   * Change le compteur actif
   *
   * @method toggleMeter
   * @memberof SourceSheetPDIComponent
   */
  toggleTab = (tab: string) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  receiveDataFilter(data: Array<any>) {
    this.setState({ data });
  }

  onFilterChangement = (filterVal: any, data: any, champ: any) => {
    if (filterVal === "") {
      return data;
    }
    if (JSON.parse(filterVal)) {
      return data.filter(
        (fiche) => fiche.oldMeterEndDate && fiche.oldMeterEndDate.length > 0
      );
    }
    this.handleFastFilter(champ, filterVal);
    return data.filter((fiche) => fiche.oldMeterEndDate === null);
  };

  /**
   * Gère les évènements du tableau en fonction du
   * type de résultat
   *
   * @method getRowEvents
   * @param {string} type Type
   */
  getRowEvents = () => ({
    onClick: (e: Object, row: any) => {
      history.push(`fiche/${row.cptSerial.replace("/", "$S")}`);
    },
  });

  handleFastFilter = (dataField: any, value: any) => {
    const { filter } = this.state;
    const cloneFilter = _.cloneDeep(filter);
    cloneFilter[dataField] = value;
    cloneFilter.current = dataField;
    if (cloneFilter[dataField] !== filter[dataField]) {
      this.setState({
        filter: cloneFilter,
      });
      localStorage.setItem(
        "tourneeFicheFilter-fast",
        JSON.stringify(cloneFilter)
      );
    }
  };

  dateFilterCustom = (value: any, list: any, field: any) => {
    // "2022-07-01T00:00:00.000+00:00"
    const dateElements = value.split("/");
    this.handleFastFilter(field, value);
    return list.filter((el: any) => {
      if (el[field] && el[field].toString().length === 4) {
        return el[field].toString().includes(value);
      }
      let count = 0;
      el[field] &&
        dateElements.forEach((dateFragment: any) => {
          if (el[field].includes(dateFragment)) count += 1;
        });
      return count === dateElements.length;
    });
  };

  getColumns() {
    const { locales, t } = this.props;
    const { data, filter } = this.state;

    const selectOptions = {
      cptManufacturer: _.uniq(
        data
          .map((fiche) => fiche.cptManufacturer)
          .filter((fiche) => fiche && fiche.length > 0)
      ).map((man) => ({
        value: man,
        label: man === "UNKNOWN" ? t("all.text.unknown") : man,
      })),
      ...[
        "lastUpdateByUser",
        "radioManufacturer",
        "consoState",
        "amrReference",
        "message",
        "marquage",
      ].map((el) =>
        _.uniq(
          data
            .map((fiche) => _.get(fiche, el))
            .filter((fiche) => fiche && fiche.length > 0)
        ).map((man) => ({
          value: man,
          label: man,
        }))
      ),
    };
    const stateOptions = StaticList.etatFiche.map((el: any) => ({
      value: el.label,
      label: (
        <div>
          {getC(el.picto, { height: "1.5em", width: "1.5em" })}{" "}
          {t(`releve_meter_status.text.${el.picto}`)}
        </div>
      ),
      field: el.picto,
    }));
    stateOptions.unshift({
      value: "",
      label: <span>{t("all.text.all_plural").toUpperCase()}</span>,
      field: "",
    });
    return (
      locales &&
      locales.locale &&
      ListTools.getDefaultColumns(ListTools.typeOfList.LightFiche).map(
        (champ) => {
          if (champ !== "ficheState") {
            return {
              dataField: champ,
              text: t(`columns.${champ}`),
              classes: `crystalList-column ${
                champ === "clpInformations" || champ === "mtrInformations"
                  ? "pre-space"
                  : ""
              }`,
              sort: true,
              formatter: ListTools.findFormatter(champ),
              filter: _.get(selectOptions, champ)
                ? selectFilter({
                    options: _.get(selectOptions, champ),
                    placeholder: t("all.text.all_plural").toUpperCase(),
                    onFilter: (v: any) => this.handleFastFilter(champ, v),
                    defaultValue: filter[champ],
                  })
                : champ === "oldMeterEndDate"
                ? selectFilter({
                    onFilter: (v: any, d: any) =>
                      this.onFilterChangement(v, d, champ),
                    options: [
                      {
                        value: true,
                        label: t("all.text.yes").toUpperCase(),
                      },
                      {
                        value: false,
                        label: t("all.text.no").toUpperCase(),
                      },
                    ],
                    placeholder: t("all.text.all_plural").toUpperCase(),
                    defaultValue: filter[champ],
                  })
                : champ.toLowerCase().includes("date")
                ? textFilter({
                    onFilter: (val: any, list: any) =>
                      this.dateFilterCustom(val, list, champ),
                    defaultValue: filter[champ],
                  })
                : textFilter({
                    placeholder: t(`columns.${champ}`),
                    onFilter: (v: any) => this.handleFastFilter(champ, v),
                    defaultValue: filter[champ],
                  }),
            };
          }
          return {
            dataField: champ,
            text: t(`columns.${champ}`),
            formatter: ListTools.findFormatter(champ),
            filter: customFilter(),
            classes: `crystalList-column ${
              champ === "clpInformations" || champ === "mtrInformations"
                ? "pre-space"
                : ""
            }`,
            filterRenderer: (onFilter: any, column: any) => {
              let indexValue = 0;
              if (filter && filter.ficheState) {
                indexValue = stateOptions.findIndex(
                  (el) => el.field === filter.ficheState
                );
              }
              return (
                <CustomSelect
                  type="select"
                  style={{ width: "200px", marginTop: "15px" }}
                  className="unsortable-input"
                  options={stateOptions}
                  defaultValue={
                    filter && filter.ficheState
                      ? stateOptions[indexValue]
                      : null
                  }
                  placeholder={t("all.text.all_plural").toUpperCase()}
                  classNamePrefix="table-select"
                  onChange={(e: any) => this.handleFilter(e, onFilter)}
                  onFilter={onFilter}
                  id={`text-filter-column-${champ}`}
                />
              );
            },
          };
        }
      )
    );
  }

  handleFilter(e: any, onFilter: any) {
    onFilter(e.field);
    this.handleFastFilter("ficheState", e.field);
  }

  handleDate(startDate: any, endDate: any) {
    this.setState({
      startDate,
      endDate,
      noChange: true,
    });
  }

  handleZoom(zoom: string) {
    this.setState({
      zoom,
    });
  }

  rotateFiches() {
    const { dispatch, tournee } = this.props;
    dispatch(tourneeActions.rotateFiches(tournee.info.code));
  }

  toggleReplaceMoreRecent() {
    this.setState({ replaceMoreRecent: !this.state.replaceMoreRecent });
  }

  getTelereleveReads() {
    const { startDate, endDate, replaceMoreRecent } = this.state;
    const { dispatch, match, locations } = this.props;
    const { tourneeCode } = match.params;
    const { fetchedLocation } = locations;
    //
    let start = new Date(startDate);
    let startUTC = Date.UTC(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      0,
      0,
      0
    );
    let end = new Date(endDate || startDate);
    let endUTC = Date.UTC(
      end.getFullYear(),
      end.getMonth(),
      end.getDate(),
      23,
      59,
      59
    );
    //
    dispatch(
      tourneeActions.getTelereleveReads(
        tourneeCode || fetchedLocation.code,
        startUTC,
        endUTC,
        replaceMoreRecent
      )
    );
  }

  fillTelereleveReads() {
    const { startDate, endDate, replaceMoreRecent } = this.state;
    const { dispatch, match, locations } = this.props;
    const { tourneeCode } = match.params;
    const { fetchedLocation } = locations;
    //
    let start = new Date(startDate);
    let startUTC = Date.UTC(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      0,
      0,
      0
    );
    let end = new Date(endDate || startDate);
    let endUTC = Date.UTC(
      end.getFullYear(),
      end.getMonth(),
      end.getDate(),
      23,
      59,
      59
    );
    //
    dispatch(
      tourneeActions.fillTelereleveReads(
        tourneeCode || fetchedLocation.code,
        startUTC,
        endUTC,
        replaceMoreRecent
      )
    );
  }

  formatDataForCSV = (sourceColumns: any, data: any) => {
    const { locales } = this.props;
    data.forEach((d: any) => {
      d.ficheState = StaticList.etatFiche.find(
        (el: any) => el.picto === d.ficheState
      )?.label;
      d.hasGpsPosition = d.hasGpsPosition ? "Oui" : "Non";
      d.alarms = d.alarms
        .map((traduction: string) => traduction.replace(/ /g, ""))
        .map((traduction: string) =>
          translate("fr", "alarmType", traduction, locales.locale)
        );
    });
    downloadCSV(sourceColumns, data);
  };

  showModal() {
    const { dispatch, tournee } = this.props;
    const { isOpen } = this.state;
    if (tournee.telereleveGet) {
      dispatch(tourneeActions.clearTelereleveReads());
      this.setState({
        startDate: moment().add(-1, "month").startOf("day").toISOString(false),
        endDate: moment().endOf("day").add(-1, "second").toISOString(false),
        replaceMoreRecent: true,
        noChange: false,
      });
    }
    this.setState({
      isOpen: !isOpen,
    });
  }

  afterFilter = (result: any, filters: any) => {
    const { dataLength } = this.state;
    if (dataLength !== result.length) {
      this.setState({
        dataLength: result.length,
      });
    }
  };

  onPageChange = (pageNumber: any) => {
    const { tournee } = this.props;
    const code = _.get(tournee, "info.code");
    localStorage.setItem(`tourneeFichesPage-${code}`, pageNumber.toString());
    this.setState({
      paginationPage: pageNumber,
    });
  };

  /**
   * Construit le composant et mise en place
   * d'un système d'onglets pour les résultats
   * de la recherche
   *
   * @method render
   */
  render() {
    const {
      startDate,
      endDate,
      isOpen,
      paginationPage,
      data,
      replaceMoreRecent,
      noChange,
      rotateOpen,
      dataLength,
    } = this.state;
    const { tournee, match, locales, t } = this.props;
    const {
      params: { locationId },
    } = match;

    const originalData = _.get(tournee, "fiches", []).sort(
      (r1, r2) => r1.cardOrder - r2.cardOrder
    );

    let sourceColumns = this.getColumns();
    const fields =
      sourceColumns &&
      sourceColumns
        .map((col: any) => {
          const typeData = ListTools.findSpecificType(col.dataField);
          return {
            label: t(`columns.${col.dataField}`),
            value: col.dataField,
            type: typeData,
            classes: col.dataField === "hasGpsPosition" ? "center-td" : "",
            opts:
              col.dataField === "ficheState"
                ? StaticList.etatFiche.map((el) => {
                    return {
                      value: el.picto,
                      label: (
                        <div>
                          {getC(el.picto, { height: "1.5em", width: "1.5em" })}
                          {el.label}
                        </div>
                      ),
                    };
                  })
                : col.dataField === "consoState"
                ? StaticList.consoState
                : col.dataField === "alarms"
                ? translateBasicAlarmForRound("fr", locales.locale).sort(
                    (a, b) => {
                      if (a.label < b.label) return -1;
                      if (a.label === b.label) return 0;
                      return 1;
                    }
                  )
                : [],
          };
        })
        .sort((a: any, b: any) => {
          if (a.label < b.label) return -1;
          if (a.label === b.label) return 0;
          return 1;
        });
    sourceColumns =
      sourceColumns &&
      sourceColumns.filter(
        (c) => !["indexed", "modified", "withAlarm"].includes(c.dataField)
      );

    const mappedData = originalData.map((el) => {
      const clone = _.cloneDeep(el);
      clone.indexed = el.isIndexed;
      clone.modified = el.isModified;
      return clone;
    });

    return (
      <>
        {tournee.loadingFiches && (
          <Loading message="Chargement des fiches..." />
        )}
        <div
          className="fiche-tab-container col-md-12"
          style={{ marginBottom: "10px" }}
        >
          {/* MODAL IMPORT TELERELEVE */}
          <TelereleveModal
            isOpen={isOpen}
            showModal={this.showModal}
            startDate={startDate}
            endDate={endDate}
            handleDate={this.handleDate}
            replaceMoreRecent={replaceMoreRecent}
            fillTelereleveReads={this.fillTelereleveReads}
            getTelereleveReads={this.getTelereleveReads}
            toggleReplaceMoreRecent={this.toggleReplaceMoreRecent}
            noChange={noChange}
          />
          {/* END MODAL */}

          {_.get(tournee, "fiches", []).length > 0 &&
            _.get(tournee, "info.code") && (
              <div className="filter-container">
                <QueryBuilder
                  sendListFiltered={this.receiveDataFilter}
                  listData={mappedData}
                  listOperator={listOperator}
                  listFilters={fields}
                  idContext={ListTools.typeOfList.Fiche}
                  save
                  idSite={locationId}
                  tournee={tournee}
                />
              </div>
            )}
          {(_.get(tournee, "fiches.length") || 0) > 0 && (
            <div
              className="table-info-container"
              style={{
                maxHeight: window.innerHeight - 191,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                paddingBottom: "50px",
              }}
            >
              <h2>
                <span>
                  <SvgPdiBlanc
                    id="rotatefiches"
                    height="0.9em"
                    width="0.9em"
                    fill="#31c6b3"
                    stroke="#31c6b3"
                    strokeWidth="0.3"
                  />
                </span>
                {Number.isInteger(dataLength) ? dataLength : data.length}{" "}
                {t("all.text.record", {
                  count: Number.isInteger(dataLength)
                    ? dataLength
                    : data.length,
                })}
              </h2>
              <UncontrolledDropdown
                className="float-right"
                style={{ position: "absolute", right: "40px" }}
              >
                <DropdownToggle nav>
                  <BurgerHeader fill="#808080" height="2em" width="2em" />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() => this.formatDataForCSV(sourceColumns, data)}
                  >
                    {t("all.text.export_to_csv")}
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => this.showModal()}>
                    {t("tournee_fiches.text.recup_telereleve")}
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    disabled={!_.get(tournee, "info.withRotateOption")}
                    onClick={() => this.setState({ rotateOpen: !rotateOpen })}
                  >
                    {t("tournee_fiches.text.rotate_fiches")}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Modal
                isOpen={rotateOpen}
                placement="top"
                target="rotatefiches"
                toggle={() => this.setState({ rotateOpen: !rotateOpen })}
              >
                <ModalHeader style={{ color: "red" }}>
                  &#x26A0; Rotation des index
                </ModalHeader>
                <ModalBody style={{ display: "grid" }}>
                  <p>{t("tourneefiches.text.rotation_index_description")}</p>
                  <p>{t("tourneefiches.text.rotation_index_warning")}</p>
                  <div
                    style={{ justifyContent: "space-between", display: "flex" }}
                  >
                    <Button
                      onClick={() => this.setState({ rotateOpen: !rotateOpen })}
                    >
                      {t("all.button.cancel")}
                    </Button>
                    <Button
                      color="danger"
                      onClick={() => {
                        this.rotateFiches();
                        this.setState({ rotateOpen: !rotateOpen });
                      }}
                    >
                      {t("all.button.confirm")}
                    </Button>
                  </div>
                </ModalBody>
              </Modal>
              <div
                className="crystalList-container tourneeFicheTable"
                style={{ display: "block", height: "90%" }}
                id="scrollable-table"
              >
                {data && sourceColumns && (
                  <BootstrapTable
                    keyField="pdi"
                    data={data}
                    rowEvents={this.getRowEvents()}
                    columns={sourceColumns}
                    bootstrap4
                    bordered={false}
                    hover
                    headerClasses="crystalList-column"
                    rowClasses="clickable"
                    filter={filterFactory({ afterFilter: this.afterFilter })}
                    pagination={paginationFactory({
                      sizePerPage: 50,
                      page:
                        dataLength / 50 >= paginationPage ? paginationPage : 1,
                      onPageChange: this.onPageChange,
                    })}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, meters, locations, locales, radios, pdis, tournee } =
    state;
  const { user } = authentication;

  return {
    user,
    meters,
    radios,
    pdis,
    locations,
    locales,
    tournee,
  };
}

const connectedTourneeFiches = connect(mapStateToProps)(TourneeFiches);
const tr = withTranslation()(connectedTourneeFiches);
export default tr;
