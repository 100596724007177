import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormGroup } from "reactstrap";
import SvgCroixNoir from "../SvgComponents/croixNoir";
import SvgValidation from "../SvgComponents/ValidationVert";
import {
  SelectOptionType,
  TagCreationFormType,
  TagDto,
} from "../_interfaces/TourneeCreationTypes";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { recursiveTranslate } from "../_helpers";
import locale from "../_shared/Locale.json";
import ListTools from "../List/ListTools";
import { locales } from "../_interfaces/reducers";
import Select, { ActionMeta } from "react-select";
import { WithTranslation, withTranslation } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { rndTemplateActions } from "../_actions";
import Tag from "../_components/Tag";

const emptyForm = {
  name: "",
  type: null,
  nodepath: null,
  size: "",
  isModifiable: false,
};

const infoToRemoveFromList = [
  "read.consumption",
  "meter.mtrInformations",
  "clpInformations",
];

const TagPart: React.FC<
  {
    tags: TagDto[];
    locales: locales;
    disabled: boolean;
    dispatch: Function;
    setLoadingMessage: Dispatch<SetStateAction<string>>;
    loading: boolean;
    findSelectedTagIds: () => number[];
  } & WithTranslation
> = ({
  tags,
  locales,
  disabled,
  dispatch,
  t,
  setLoadingMessage,
  loading,
  findSelectedTagIds,
}) => {
  const [form, setForm] = useState<TagCreationFormType>(emptyForm);
  const [sourceColumns, setSourceColumns] = useState<SelectOptionType[]>([]);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [otherTagNames, setOtherTagNames] = useState<string[]>([]);
  const [isSizeTooLong, setIsSizeTooLong] = useState<boolean>(false);
  const listType = Object.keys(ListTools.specificFieldTypeRegex)
    .filter((type) => type === "number" || type === "text" || type === "date")
    .map((type) => ({
      value: type,
      label: t(`tournee_creation.input_label.type_${type}`),
    }));
  const formRef = useRef<typeof AvForm>();

  const handleFormChange = (identifier: keyof TagCreationFormType) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const newForm = { ...form };
      switch (identifier) {
        case "name":
        case "size":
          newForm[identifier] = e.target.value;
          break;
        case "isModifiable":
          newForm.isModifiable = e.target.value == "true";
          break;
      }
      setForm(newForm);
    };
  };

  const handleSelectChange =
    (identifier: "type" | "nodepath") =>
    (option: any, actionMeta: ActionMeta<SelectOptionType>) => {
      const newForm = { ...form };
      newForm[identifier] = option;
      if (identifier === "nodepath") {
        newForm.type = option.type;
      }
      setForm(newForm);
    };

  const onTagClick = (tag: TagDto) => {
    setOtherTagNames(otherTagNames.filter((tagName) => tag.name !== tagName));
    setForm({
      id: tag.id,
      name: tag.name,
      type: listType.find((type) => type.value === tag.type),
      nodepath: sourceColumns.find(
        (nodepath) => nodepath.value === tag.nodepath
      ),
      size: `${tag.size}`,
      isModifiable: tag.modifiable,
    });
  };

  const onTagDelete = (tag: TagDto) => {
    setLoadingMessage(t("tournee_creation.loading_band.tag_deletion"));
    dispatch(rndTemplateActions.deleteTag(tag));
  };

  const handleValidationsForField = (identifier: keyof TagCreationFormType) => {
    switch (identifier) {
      case "name":
        return {
          required: {
            value: true,
            errorMessage: t("tournee_creation.input_error.empty_tag_name"),
          },
          maxLength: { value: 16 },
        };
      case "size":
        return {
          required: {
            value: true,
            errorMessage: t("tournee_creation.input_error.empty_tag_size"),
          },
          max: {
            value: 30,
            errorMessage: t("tournee_creation.input_error.tag_size_too_high"),
          },
          min: {
            value: 1,
            errorMessage: t("tournee_creation.input_error.tag_size_too_low"),
          },
        };
    }
  };

  const isFormValid = () => {
    if (
      form.nodepath === null ||
      tags
        .filter((tag) => tag.id !== form.id)
        .map((tag) => tag.name)
        .includes(form.name) ||
      (form.id && isSizeTooLong)
    ) {
      setIsSubmited(true);
      return false;
    }
    return true;
  };

  const submit = () => {
    const tag: TagDto = {
      id: form.id,
      name: form.name,
      type: form.type.value,
      nodepath: form.nodepath.value,
      size: Number(form.size),
      modifiable: form.isModifiable,
    };
    if (tag.id) {
      setLoadingMessage(t("tournee_creation.loading_band.tag_update"));
      dispatch(rndTemplateActions.updateTag(tag));
    } else {
      setLoadingMessage(t("tournee_creation.loading_band.tag_creation"));
      dispatch(rndTemplateActions.createTag(tag));
    }
    setIsSubmited(false);
  };

  const isTagNameTaken = () => {
    return otherTagNames
      .map((name) => name.toUpperCase())
      .includes(form.name.toUpperCase());
  };

  const verifyOnSizeModification: () => boolean = () => {
    const selectedTag = tags.find((tag) => tag.id === form.id)!;
    return (
      selectedTag.size >= Number(form.size) ||
      selectedTag.linkedTemplate === undefined ||
      selectedTag.linkedTemplate.every((templates) =>
        templates.lines
          .filter((line) => line.includes(`{{${form.id}}}`))
          .every((line) => calculateLineSize(line) <= 30)
      )
    );
  };

  const calculateLineSize = (line: string) => {
    let size = 0;
    const lineWithoutTag = line.replaceAll(
      /\{\{\d+\}\}/g,
      (correspondance: string) => {
        const id = Number(
          correspondance.substring(2, correspondance.length - 2)
        );
        if (id !== form.id) {
          const tag = tags!.find((el) => el.id === id)!;
          size += tag.size;
        } else {
          size += Number(form.size);
        }
        return "";
      }
    );
    size += lineWithoutTag.length;
    return size;
  };

  useEffect(() => {
    if (form.id && form.size) {
      setIsSizeTooLong(!verifyOnSizeModification());
    } else {
      setIsSizeTooLong(false);
    }
  }, [form.size]);

  useEffect(() => {
    if (locales.locale) {
      const translatedKeys = recursiveTranslate(
        "fr",
        "pdi",
        locale.fr.pdi,
        locales.locale
      );
      const sourceColumns = translatedKeys
        .filter((champ: any) => !infoToRemoveFromList.includes(champ.path))
        .map((champ: any) => {
          const elem = {
            value: champ.path,
            label: champ.value,
            type: listType.find(
              (type) =>
                type.value ===
                (ListTools.findSpecificType(champ.path) === "number"
                  ? "number"
                  : ListTools.findSpecificType(champ.path) === "date"
                  ? "date"
                  : "text")
            ),
          };
          return elem;
        });
      setSourceColumns(sourceColumns);
    }
  }, [locales]);

  useEffect(() => {
    setOtherTagNames(tags.map((tag) => tag.name));
    setForm(emptyForm);
    formRef.current.reset({});
  }, [tags]);

  return (
    <div className="tag-creation-container col-6">
      <h3 className="tag-creation-title">
        {form.id
          ? t("tournee_creation.container_label.tag_modification", {
              tagName: tags.find((tag) => tag.id === form.id)?.name,
            })
          : t("tournee_creation.container_label.tag_creation")}
      </h3>
      <AvForm ref={formRef} validate={isFormValid} onValidSubmit={submit}>
        {/* Creation Part */}
        <div className="tag-creation-form-container">
          <div className="tag-creation-inputs-container">
            <FormGroup className="name-field-group field-group">
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div className="tag-line-label">
                  {t("all.text.name")}:&nbsp;
                </div>
                <AvField
                  type="text"
                  name="tagName"
                  id="inputName"
                  className={
                    "tag-line-input" + (isTagNameTaken() ? " is-invalid" : "")
                  }
                  disabled={disabled}
                  placeholder={t("all.text.name")}
                  onChange={handleFormChange("name")}
                  value={form.name}
                  validate={handleValidationsForField("name")}
                />
              </div>
              <div
                className="invalid-feedback"
                style={{ display: isTagNameTaken() ? "block" : "none" }}
              >
                {t("tournee_creation.input_error.tag_name_already_exists")}
              </div>
            </FormGroup>
            <FormGroup className="field-group">
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div className="tag-line-label">
                  {t("all.text.information")}:&nbsp;
                </div>
                <Select
                  options={sourceColumns}
                  className="tag-line-input"
                  isDisabled={disabled}
                  onChange={handleSelectChange("nodepath")}
                  value={form.nodepath}
                  placeholder={t("all.text.information")}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        isSubmited && form.nodepath === null
                          ? "#dc3545"
                          : baseStyles.borderColor,
                    }),
                  }}
                />{" "}
              </div>

              <div
                className="invalid-feedback"
                style={{
                  display:
                    isSubmited && form.nodepath === null ? "block" : "none",
                  marginLeft: "100px",
                }}
              >
                {t("tournee_creation.input_error.empty_tag_info")}
              </div>
            </FormGroup>
            <FormGroup className="field-group">
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div className="tag-line-label">
                  {t("enquete.text.field_size")}:&nbsp;
                </div>
                <AvField
                  type="number"
                  name="size"
                  id="inputSize"
                  className={`tag-line-input ${
                    form.id && isSizeTooLong ? "is-invalid" : ""
                  }`}
                  disabled={disabled}
                  placeholder={t("enquete.text.field_size")}
                  onChange={handleFormChange("size")}
                  value={form.size}
                  validate={handleValidationsForField("size")}
                />
              </div>
              <div
                className="invalid-feedback"
                style={{
                  display: form.id && isSizeTooLong ? "block" : "none",
                  marginLeft: "100px",
                  marginTop: "-12px",
                }}
              >
                {t("tournee_creation.input_error.empty_tag_info")}
              </div>
            </FormGroup>
            <RadioGroup
              value={form.isModifiable}
              name="radio-buttons-group"
              onChange={handleFormChange("isModifiable")}
            >
              <FormControlLabel
                control={<Radio />}
                disabled={disabled}
                label={t("tournee_creation.input_label.unmodifiable")}
                value={false}
              />
              <FormControlLabel
                control={<Radio />}
                disabled={disabled}
                label={t("tournee_creation.input_label.modifiable")}
                value={true}
              />
            </RadioGroup>
          </div>
          <div className="tag-creation-buttons-container">
            <button className="round-svg validate" disabled={disabled}>
              <SvgValidation height="1em" />
            </button>
            <div
              className="round-svg cancel"
              onClick={() => {
                setIsSubmited(false);
                setOtherTagNames(tags.map((tag) => tag.name));
                setForm(emptyForm);
                formRef.current.reset({});
              }}
            >
              <SvgCroixNoir height="2em" />
            </div>
          </div>
        </div>
      </AvForm>
      {/* List and managment part */}
      <div className="tag-list-title-container">
        <h4>{t("tournee_creation.container_label.list_tag")}</h4>
        <h6>{t("tournee_creation.container_label.list_tag_subtitle")}</h6>
      </div>

      <div className="tag-list-container">
        {tags &&
          tags.map((tag: TagDto) => (
            <Tag
              key={tag.id}
              name={tag.name}
              onClick={() => {
                onTagClick(tag);
              }}
              onDelete={() => !loading && onTagDelete(tag)}
              isSelected={form.id === tag.id}
              deleteDisabled={
                findSelectedTagIds().find((tagId) => tag.id == tagId) !==
                  undefined ||
                (tag.linkedTemplate !== null &&
                  tag.linkedTemplate?.length !== 0)
              }
              disabled={disabled}
              buttonPopoverText={
                tag.linkedTemplate && tag.linkedTemplate.length !== 0
                  ? t("tournee_creation.error_band.tag_still_linked", {
                      tag: tag.name,
                      templates: tag.linkedTemplate
                        .map((template) => template.name)
                        .join(", "),
                    })
                  : t("tournee_creation.error_band.tag_in_use", {
                      tag: tag.name,
                    })
              }
            />
          ))}
      </div>
    </div>
  );
};

const connectedTemplateCreationPage = connect()(TagPart);
const tr = withTranslation()(connectedTemplateCreationPage);

export default tr;
