import React from "react";
import SvgGoutteManual from "../SvgComponents/GoutteManual";
import SvgGoutteRadio from "../SvgComponents/GoutteRadio";
import SvgGoutteTelereleve from "../SvgComponents/GoutteTelereleve";
import SvgGoutteCluster from "../SvgComponents/GoutteCluster";
import SvgGoutteCluster2parts from "../SvgComponents/GoutteCluster2parts";
import SvgGoutteCluster3parts from "../SvgComponents/GoutteCluster3parts";
import SvgGoutteCluster4parts from "../SvgComponents/GoutteCluster4parts";
import _ from "lodash";
import ClusterOptions from "../GoogleMap/ClusterOptions.json";
import SvgHomeMarker from "../SvgComponents/HomeMarker";
import SvgRadioVert from "../SvgComponents/RadioVert";

export const colors = {
  red: "#F24141",
  yellow: "#F2AE2E",
  green: "#26A653",
  blue: "#91E0F2",
  purple: "#A63C76",
};

function getC(key, props) {
  function getClusterProps() {
    return _.sortBy(
      _.map(props.colorCluster, (value, key) => {
        return {
          color: colors[key],
          opacityIn: ClusterOptions.opacityIn,
          opacityMiddle: ClusterOptions.opacityMiddle,
          opacityOut: ClusterOptions.opacityOut,
        };
      }),
      [(el) => _.indexOf(_.values(colors), el.color)]
    );
  }

  switch (key) {
    case "red_manual":
      return <SvgGoutteManual fillGoutte={colors.red} {...props} />;
    case "red_radio":
      return <SvgGoutteRadio fillGoutte={colors.red} {...props} />;
    case "yellow_manual":
      return <SvgGoutteManual fillGoutte={colors.yellow} {...props} />;
    case "yellow_radio":
      return <SvgGoutteRadio fillGoutte={colors.yellow} {...props} />;
    case "green_manual":
      return <SvgGoutteManual fillGoutte={colors.green} {...props} />;
    case "green_radio":
      return <SvgGoutteRadio fillGoutte={colors.green} {...props} />;
    case "blue_radio":
      return <SvgGoutteRadio fillGoutte={colors.blue} {...props} />;
    case "purple_radio":
      return <SvgGoutteRadio fillGoutte={colors.purple} {...props} />;
    case "blue_telereleve":
      return <SvgGoutteTelereleve fillGoutte={colors.blue} {...props} />;
    case "purple_telereleve":
      return <SvgGoutteTelereleve fillGoutte={colors.purple} {...props} />;
    case "home_marker":
      return <SvgHomeMarker {...props} />;
    case "active_gateway":
      return <SvgRadioVert fill={colors.green} {...props} />;
    case "inactive_gateway":
      return <SvgRadioVert fill={colors.red} {...props} />;
    case "cluster_1":
      return <SvgGoutteCluster fillCluster={getClusterProps()} {...props} />;
    case "cluster_2":
      return (
        <SvgGoutteCluster2parts fillCluster={getClusterProps()} {...props} />
      );
    case "cluster_3":
      return (
        <SvgGoutteCluster3parts fillCluster={getClusterProps()} {...props} />
      );
    case "cluster_4":
      return (
        <SvgGoutteCluster4parts fillCluster={getClusterProps()} {...props} />
      );
    default:
      break;
  }
  return <div />;
}

export default getC;
