import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import { UncontrolledTooltip } from "reactstrap";
import WidgetBlanc from "../SvgComponents/WidgetBlanc";
import PoubelleBleue from "../SvgComponents/PoubelleBleu";
import translate from "../_helpers/locale-helpers";

import { templateActions, localeActions } from "../_actions";
import confirm from "../_components";
import WidgetTemplateCreator from "../WidgetTemplate";
import { templates, locales } from "../_interfaces/reducers";
import { User } from "../_entities/user";
import { Template } from "../_entities/template";
import LoadingBand from "../Bands/Loading";
import ErrorBand from "../Bands/Error";

interface Props {
  dispatch: Function;
  templates: templates;
  locales: locales;
  user: User;
  history: Array<string>;
}

/**
 * Liste des templates
 *
 * @class WidgetTemplatesComponent
 * @extends {React.Component<Props>}
 */
class WidgetTemplatesComponent extends React.Component<Props> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof WidgetTemplatesComponent
   */
  constructor(props: Props) {
    super(props);
    this.delete = this.delete.bind(this);
  }

  /**
   * Charge les traductions et les templates
   * au montage du composant
   *
   * @method componentDidMount
   * @memberof WidgetTemplatesComponent
   */
  componentDidMount() {
    const { dispatch, user, history } = this.props;
    // on vérifie la sécurité
    if (user.role.name === "USER") {
      history.push("/forbidden");
    }
    dispatch(localeActions.load());
    dispatch(templateActions.getAll());
  }

  /**
   * Vide le state redux des templates au
   * démontage du composant
   *
   * @method componentWillUnmount
   * @memberof WidgetTemplatesComponent
   */
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(templateActions.clear());
  }

  /**
   * Formate les templates
   *
   * @returns {Array<Object>}
   * @method representItem
   * @memberof WidgetTemplatesComponent
   */
  representItem = () => {
    const { templates, locales } = this.props;
    return templates.items.map((it) => ({
      id: it.id,
      name: `${it.name}`,
      datasource: `${translate(
        "fr",
        "dataSourceName",
        it.datasource,
        locales.locale
      )}`,
      displayType: `${translate(
        "fr",
        "displayType",
        it.content.dataSourceProperty.displayProperty.displayType,
        locales.locale
      )}`,
      displayValue: `${translate(
        "fr",
        "displayValue",
        it.content.dataSourceProperty.displayValue,
        locales.locale
      )}`,
      displayUnit: `${translate(
        "fr",
        "unit",
        it.content.dataSourceProperty.displayUnit,
        locales.locale
      )}`,
    }));
  };

  /**
   * Gère la suppression du template donné
   *
   * @async
   * @param {Object} e Evènement
   * @param {*} template Template
   * @method delete
   * @memberof WidgetTemplatesComponent
   */
  async delete(e: any, template: Template) {
    const { dispatch, templates } = this.props;
    const { Fragment } = React;

    const result = await confirm({
      title: (
        <Fragment>
          <strong>Désactiver le Widget </strong>
        </Fragment>
      ),
      message:
        "Cette action irreversible désactivera l'ajout de ce widget sur un tableau de bord",
      confirmText: "Supprimer",
      confirmColor: "danger",
      cancelColor: "primary",
      cancelText: "Abandonner",
    });
    if (result) {
      dispatch(templateActions.toDelete(template, templates.items));
    }
  }

  /**
   * Methode de rendu
   */
  render() {
    const { templates } = this.props;
    const { Fragment } = React;

    const templateFilter = textFilter({
      placeholder: "Filtrer par widget", // custom the input placeholder
      className: "widget-custom-text-filter", // custom classname on input
      defaultValue: "", // default filtering value
      comparator: Comparator.LIKE, // default is Comparator.LIKE
    });
    const columns = [
      {
        dataField: "name",
        text: "Widget",
        filter: templateFilter, // apply text filter
      },
      {
        dataField: "datasource",
        text: "Source de donnée",
        sort: true,
      },
      {
        dataField: "displayValue",
        text: "Valeur affichée",
        sort: true,
      },
      {
        dataField: "displayType",
        text: "Représentation",
        sort: true,
      },
      {
        dataField: "displayUnit",
        text: "Unité",
        sort: true,
      },
      {
        dataField: "action",
        isDummyField: true,
        align: "center",
        headerStyle: () => ({ width: "80px" }),
        text: "",
        formatter: (cellContent: any, row: Template) => (
          <Fragment>
            <div
              id={`widget${row.id}`}
              className="clickable round"
              role="presentation"
              onClick={(e) => this.delete(e, row)}
            >
              <PoubelleBleue height="1em" width="1em" />
              <UncontrolledTooltip
                placement="bottom"
                target={`widget${row.id}`}
              >
                {" "}
                Supprimer le widget
              </UncontrolledTooltip>
            </div>
          </Fragment>
        ),
      },
    ];
    return (
      <div>
        {templates.loading && (
          <LoadingBand message="Chargement des templates de widget" />
        )}
        {templates.error && <ErrorBand message={templates.error} />}
        {templates.items && (
          <div className="table-info-container">
            {" "}
            <div>
              <h2>
                <span>
                  <WidgetBlanc height="1em" width="1em" fill="#31c6b3" />
                </span>
                Widgets :
                <span className="addItem float-right">
                  {" "}
                  <WidgetTemplateCreator />
                </span>
              </h2>
            </div>
            <BootstrapTable
              keyField="id"
              data={this.representItem()}
              columns={columns}
              bootstrap4
              bordered={false}
              hover
              filter={filterFactory()}
              pagination={
                templates.items.length > 10 ? paginationFactory() : null
              }
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, templates, dashboards, alert, locales } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    templates,
    dashboards,
    locales,
  };
}

const wt: any = connect(mapStateToProps)(WidgetTemplatesComponent);
const connectedWidgetTemplates = withRouter(wt);
export default connectedWidgetTemplates;
