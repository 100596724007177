import React, { Component } from "react";

export default class BestSwitch extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      checked: props.checked,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { checked } = this.state;
    const { id } = this.props;
    const doc: any = document.getElementById(id);
    if (checked && doc) {
      doc.classList.add("slide-right");
    } else if (doc) {
      doc.classList.add("slide-left");
    }
  }

  handleClick() {
    const { checked } = this.state;
    const { id, handleChange } = this.props;
    const doc: any = document.getElementById(id);
    if (doc) {
      if (checked) {
        doc.style.marginLeft = "0";
        doc.classList.remove("slide-right");
        doc.classList.add("slide-left");
      } else {
        doc.style.marginLeft = "-15px";
        doc.classList.remove("slide-left");
        doc.classList.add("slide-right");
      }
      handleChange(!checked);
      this.setState({
        checked: !checked,
      });
    }
  }

  render() {
    const { onColor, offColor, offHandleColor, onHandleColor, id } = this.props;
    const { checked } = this.state;
    return (
      <div className="switch-container">
        <div
          className="switch-point"
          id={id}
          style={{
            backgroundColor: checked ? onHandleColor : offHandleColor,
            marginLeft: "-15px",
          }}
          onClick={this.handleClick}
        />
        <div
          className="switch-bar"
          style={{ backgroundColor: checked ? onColor : offColor }}
        />
      </div>
    );
  }
}
