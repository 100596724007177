import React from "react";
import { connect } from "react-redux";
import { UncontrolledTooltip, NavLink } from "reactstrap";
import Alerte from "../SvgComponents/AlarmeVert";
import { notificationService } from "../_services";
import { WithTranslation, withTranslation } from "react-i18next";

type Props = {
  user: any;
  notifications: any;
} & WithTranslation;

interface State {
  count: number;
  time: number;
}

/**
 * @class NotificationModalComponent
 * @extends {Component}
 */
class NotificationModalComponent extends React.Component<Props, State> {
  interval: NodeJS.Timeout | undefined;

  /**
   * @constructor
   * @param {Props} props Propriétés
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      count: 0,
      time: 60000,
    };

    this.interval = setInterval(() => this.getCount(), this.state.time);
  }

  componentDidMount() {
    this.getCount();
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (
      Object.keys(this.props.notifications).length > 0 &&
      nextProps.notifications !== this.props.notifications
    ) {
      this.getCount();
    }
    return nextState.count !== this.state.count;
  }

  /**
   * Récupère le compteur de notifications non lues
   * et met à jour le state
   *
   * @method getCount
   * @memberof NotificationModalComponent
   */
  getCount = () => {
    const { user } = this.props;
    notificationService
      .getCount(user.id)
      .then((count: number) => {
        this.setState({
          count,
        });
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  render() {
    const { count } = this.state;
    const { t } = this.props;

    return (
      <NavLink
        href="/notifications"
        id="nav-notifs"
        style={{ position: "relative" }}
      >
        <Alerte fill="#808080" height="1.5em" width="1.5em" />
        <UncontrolledTooltip placement="bottom" target="nav-notifs">
          {" "}
          {
            (count > 0
              ? t("notification_modal.tooltip_notif.x_notif_not_read", {
                  count: count,
                })
              : t("notification_modal.tooltip_notif.notif_plural")) as string
          }
        </UncontrolledTooltip>
        {count > 0 && (
          <div className="notification-count">
            {count < 100 ? count : "99+"}
          </div>
        )}
      </NavLink>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, notifications } = state;
  const { user } = authentication;
  return {
    user,
    notifications,
  };
}

const connectedNotificationModalComponent = connect(mapStateToProps)(
  NotificationModalComponent
);
const tr = withTranslation()(connectedNotificationModalComponent);
export default tr;
