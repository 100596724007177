import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { animateScroll } from "react-scroll";
import {
  Button,
  Col,
  Input,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import gestionnaireActions from "../_actions/gestionnaire.actions";
import LoadingBand from "../Bands/Loading";
import ErrorBand from "../Bands/Error";
import ManagerRoundUnit from "./ManagerRoundUnit";
import synchroActions from "../_actions/synchro.actions";
import userActions from "../_actions/user.actions";
import SvgManageTournee from "../SvgComponents/ManageTournee";
import AjoutBleu from "../SvgComponents/AjoutBleu";

type Props = {
  dispatch: Function;
  gestionnaire: any;
  history: Array<string>;
  user: any;
  users: any;
  location: any;
} & WithTranslation;

interface Manager {
  id: number;
  name: string;
  nbRound: number;
  nbPhone: number;
}

class GestionnaireComponent extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      gestionnaires: [],
    };
    this.selectGestionnaire = this.selectGestionnaire.bind(this);
    this.addGestionnaire = this.addGestionnaire.bind(this);
  }

  toggle = () => {
    const { dropdownOpen } = this.state;
    this.setState({
      dropdownOpen: !dropdownOpen,
    });
  };

  componentDidMount() {
    const { dispatch, user } = this.props;
    dispatch(gestionnaireActions.getAllGestionnaires(user.id));
    dispatch(userActions.get(user.id));
    dispatch(synchroActions.clear());
    animateScroll.scrollToTop({ duration: 0 });
  }

  componentDidUpdate() {
    const { gestionnaire, history, location } = this.props;
    if (
      gestionnaire.gestionnaires &&
      gestionnaire.gestionnaires.length === 1 &&
      !location.pathname.includes("/list")
    ) {
      const pathname = `/gestionnaires/${gestionnaire.gestionnaires[0].id}/synchronisation`;
      history.push(pathname);
    }
  }

  selectGestionnaire(manager: Manager) {
    const { history, dispatch, users } = this.props;
    if (
      users.fetchedUser.gestionnaires.find(
        (el: any) => el.gestionnaireId === manager.id
      ) ||
      this.isAdmin()
    ) {
      dispatch(gestionnaireActions.getGestionnaire(manager.id));
      history.push(`/gestionnaires/${manager.id}/synchronisation/`);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
  }

  isAdmin = () => {
    const { user } = this.props;
    return user && ["DIOPTASE", "SUPERADMIN", "ADMIN"].includes(user.role.name);
  };

  isDioptase = () => {
    const { user } = this.props;
    return user && user.role.name === "DIOPTASE";
  };

  addGestionnaire() {
    const { nameGestionnaire, numeroGestionnaire } = this.state;
    const { dispatch } = this.props;
    dispatch(gestionnaireActions.create(nameGestionnaire, numeroGestionnaire));
  }

  render() {
    const { gestionnaire, users, t } = this.props;
    return (
      <div className="col-md-12">
        {gestionnaire.loading && (
          <LoadingBand message="Chargement des gestionnaires" />
        )}
        {gestionnaire.error && <ErrorBand message={gestionnaire.error} />}
        {gestionnaire && gestionnaire.gestionnaires && (
          <div className="table-info-container">
            <h2>
              <span>
                <SvgManageTournee height="1em" width="1em" fill="#31c6b3" />
              </span>
              {t("gestionnaires.main_title.managers")} :
              <span
                className="addWidget float-right"
                id="ajout_gestionnaire"
                style={{
                  backgroundColor: "lightgrey",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "30px",
                  width: "30px",
                  padding: "5px",
                  opacity: this.isDioptase() ? 1 : 0.5,
                }}
              >
                {" "}
                <AjoutBleu width="1em" />{" "}
              </span>
              {this.isDioptase() && (
                <UncontrolledPopover
                  trigger={"legacy"}
                  placement="bottom"
                  target="ajout_gestionnaire"
                >
                  <PopoverHeader>Nouveau gestionnaire</PopoverHeader>
                  <PopoverBody style={{ display: "grid" }}>
                    Nom gestionnaire
                    <Input
                      onChange={(e) =>
                        this.setState({ nameGestionnaire: e.target.value })
                      }
                    />
                    Numero de site
                    <Input
                      type="number"
                      onChange={(e) =>
                        this.setState({ numeroGestionnaire: e.target.value })
                      }
                    />
                    <Button onClick={this.addGestionnaire}>Ajouter</Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </h2>
            <Row>
              {gestionnaire.gestionnaires.map((manager: Manager) => (
                <Col md={4} style={{ padding: "15px" }}>
                  <div
                    className="clickable"
                    onClick={() => this.selectGestionnaire(manager)}
                  >
                    <ManagerRoundUnit
                      manager={manager}
                      enabled={
                        this.isAdmin() ||
                        (users.fetchedUser &&
                          users.fetchedUser.gestionnaires.find(
                            (el: any) => el.gestionnaireId === manager.id
                          ))
                      }
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const {
    authentication,
    locations,
    dashboards,
    contacts,
    gestionnaire,
    users,
  } = state;
  const { user } = authentication;
  return {
    user,
    gestionnaire,
    dashboards,
    contacts,
    users,
  };
}

const mapping: any = connect(mapStateToProps)(GestionnaireComponent);

const connectedGestionnaires = withRouter(mapping);
const tr = withTranslation()(connectedGestionnaires);
export default tr;
