import { remoteReadingConstants } from "../_constants";
import { action } from "../_interfaces/action";
import _ from "lodash";

export default function remoteReading(
  state: any = { loading: {} },
  action: any
) {
  switch (action.type) {
    case remoteReadingConstants.GET_GENERIC_DATA_REQUEST:
      state.loading = true;
      return {
        ...state,
      };
    case remoteReadingConstants.GET_GENERIC_DATA_SUCCESS:
      return {
        ...state,
        genericData: action.genericData,
        loading: false,
      };
    case remoteReadingConstants.GET_GENERIC_DATA_FAILURE:
      return {
        error: "Impossible de récupérer les données générique des telerelèves",
      };

    case remoteReadingConstants.GET_DATA_SUCCESS:
      const start = new Date(action.startDate);
      const end = new Date(action.endDate);
      let nbReadMeter = 0;
      let nbReadMeterWithAlarm = 0;
      let detailAlarmTypeList: any = [];
      let meterModelList: any = [];
      let readMethodList: any = [];
      let meterModelWithAlarmList: any = [];
      let updatedMeter: any = [];

      if (state.genericData && state.genericData.allMeter) {
        let meters = _.cloneDeep(state.genericData.allMeter);
        meters = meters.map((meter: any) => {
          if (
            meter.lastUpdate &&
            new Date(meter.lastUpdate).getTime() <= start.getTime()
          ) {
            updatedMeter.push(meter);
          }
          if (meter.readData != undefined) {
            if (
              new Date(meter.readData.date).getTime() >=
                new Date(start).getTime() &&
              new Date(meter.readData.date).getTime() <= new Date(end).getTime()
            ) {
              //Meter has a READ in the period
              nbReadMeter += 1;

              const meterModel = meterModelList.find(
                (type: any) => type.name === meter.meterModel
              );
              if (meterModel) {
                meterModel.cnt += 1;
              } else {
                meterModelList.push({ name: meter.meterModel, cnt: 1 });
              }

              if (
                meter.readData.alarm != undefined &&
                _.values(meter.readData.alarm).length > 0
              ) {
                //Meter has a READ with ALARM
                _.uniq(_.values(meter.readData.alarm)).map(
                  (alarmName: string) => {
                    const alarmType = detailAlarmTypeList.find(
                      (type: any) => type.name === alarmName
                    );
                    if (alarmType) {
                      alarmType.cnt += 1;
                      if (alarmType[meter.meterModel]) {
                        alarmType[meter.meterModel] += 1;
                      } else {
                        alarmType[meter.meterModel] = 1;
                      }
                    } else {
                      const newObject = { name: alarmName, cnt: 1 };
                      newObject[meter.meterModel] = 1;
                      detailAlarmTypeList.push(newObject);
                    }
                  }
                );

                const meterModelWithAlarm = meterModelWithAlarmList.find(
                  (type: any) => type.name === meter.meterModel
                );
                if (meterModelWithAlarm) {
                  meterModelWithAlarm.cnt += 1;
                } else {
                  meterModelWithAlarmList.push({
                    name: meter.meterModel,
                    cnt: 1,
                  });
                }

                nbReadMeterWithAlarm += 1;
              }

              const readMethod = readMethodList.find(
                (type: any) => type.name === meter.readData.method
              );
              if (readMethod) {
                readMethod.cnt += 1;
              } else {
                readMethodList.push({ name: meter.readData.method, cnt: 1 });
              }
            } else {
              //Reset read because not in the request period
              meter.readData = undefined;
            }
          }

          return meter;
        });

        let gpsMeters = _.cloneDeep(meters).filter((meter: any) => meter.gps);

        const data = {
          meters: meters,
          gpsMeters: gpsMeters,
          nbReadMeter: nbReadMeter,
          nbReadMeterWithAlarm: nbReadMeterWithAlarm,
          alarmType: detailAlarmTypeList,
          meterModel: meterModelList,
          readMethod: readMethodList,
          meterModelWithAlarm: meterModelWithAlarmList,
          updatedMeter,
          nbRadio: state.genericData.nbRadio,
          consumed: false,
        };
        return {
          ...state,
          data,
          startDate: action.startDate,
        };
      }
      return {
        ...state,
      };

    case remoteReadingConstants.CLEAR:
      return {};

    default:
      return state;
  }
}
