import React, { Component } from "react";
import { ImportComponent } from "../_components";
import { WithTranslation, withTranslation } from "react-i18next";

class ImportConstructeur extends Component<WithTranslation, {}> {
  render() {
    const { t } = this.props;
    return (
      <div>
        <ImportComponent
          title={t("import_constructeur.main_title.import_mfr")}
        />
      </div>
    );
  }
}

const tr = withTranslation()(ImportConstructeur);
export default tr;
