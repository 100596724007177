import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import _, { cloneDeep } from "lodash";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import { withTranslation } from "react-i18next";
import { locales, locations } from "../_interfaces/reducers";
import {
  localeActions,
  meterActions,
  pdiActions,
  radioActions,
} from "../_actions";
import ColonneVert from "../SvgComponents/ColonneVert";
import ListTools from "../List/ListTools";
import { recursiveTranslate } from "../_helpers";
import { TinyMeter } from "../Meter";
import { TinyRadio } from "../Radio";
import { TinySourceSheetPDI } from "../SourceSheet";
import { storedSearchService } from "../_services";
import ErrorBand from "../Bands/Error";
import Loading from "../_animations/Loading";

interface Props {
  locales: locales;
  dispatch: any;
  locationId: number;
  match: any;
  locations: locations;
  location: any;
  history: any;
  pdis: any;
  meters: any;
  radios: any;
  t: Function;
}

interface State {
  query: string;
  results: any;
  info: any;
  isOpen: boolean;
  activeTab: any;
  key: string;
}

/**
 * @class Resources
 * @extends {Component}
 */
class Resources extends Component<Props, any> {
  static resultEnum: any = {
    Meter: {
      Label: "Meter",
      Type: ListTools.typeOfList.Meters,
      List: "meters",
    },
    Radio: {
      Label: "Radio",
      Type: ListTools.typeOfList.Radios,
      List: "radios",
    },
    PDI: {
      Label: "PDI",
      Type: ListTools.typeOfList.Pdi,
      List: "pdis",
    },
  };

  /**
   * @constructor
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      query: "",
      results: {},
      info: {},
      isOpen: false,
      activeTab: null,
      key: "globalSearch",
      filter: {},
    };
    this.handleFastFilter = this.handleFastFilter.bind(this);
    this.getTableResult = this.getTableResult.bind(this);
  }

  /**
   * Vérifie dans le local storage s'il existe des recherches
   * stockées pour un autre site que le courant, et les
   * supprime dans ce cas
   *
   * @method checkLocalStorage
   * @param {any} locationId
   */
  checkLocalStorage = (locationId: any) => {
    for (let i = 0; i < localStorage.length; i++) {
      const key: any = localStorage.key(i) !== null ? localStorage.key(i) : "";
      if (key.length > 0 && key.startsWith("site") && key.includes("Search")) {
        const testKey = parseInt(key.substring(4));
        if (testKey !== parseInt(locationId)) {
          storedSearchService.removeSearch(key);
        }
      }
    }
  };

  /**
   * Fait les vérifications des données du local
   * storage au montage du composant (suppression
   * si données d'un autre site, application sinon)
   *
   * @method componentDidMount
   */
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(localeActions.load());
    dispatch(pdiActions.getAll(0));
    dispatch(meterActions.getAll(0));
    dispatch(radioActions.getAll(0));
    this.toggleTab("1");

    const mountFastFilters = localStorage.getItem("stockFilter-fast");
    if (mountFastFilters) {
      this.setState({ filter: JSON.parse(mountFastFilters) });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    const { filter } = this.state;
    if (
      filter.current !== prevState.filter.current ||
      filter[filter.current] !== prevState.filter[prevState.filter.current]
    ) {
      localStorage.setItem("stockFilter-fast", JSON.stringify(filter));
    }
  }

  /**
   * Gère l'ouverture/fermeture de la modal d'un
   * résultat
   *
   * @method toggle
   */
  toggle = () => {
    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen,
    });
  };

  /**
   * Change le compteur actif
   *
   * @method toggleMeter
   * @memberof SourceSheetPDIComponent
   */
  toggleTab = (tab: string) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  handleFastFilter = (dataField: any, value: any) => {
    const { filter } = this.state;
    const cloneFilter = _.cloneDeep(filter);
    cloneFilter[dataField] = value;
    cloneFilter.current = dataField;
    if (cloneFilter[dataField] !== filter[dataField]) {
      this.setState({ filter: cloneFilter });
    }
  };

  /**
   * Construit le tableau des résultats du type
   * passé en paramètre
   *Search
   * @method getTableResult
   * @param {any}     data Résultats
   * @param {string}  type Type
   * @returns {JSX} Le tableau
   */
  getTableResult = (data: any, type: string) => {
    const { filter } = this.state;
    let defaultColumns: any = ListTools.getDefaultColumns(
      Resources.resultEnum[type].Type
    );
    if (type !== "PDI") {
      defaultColumns = defaultColumns.map((el) =>
        el.replace(new RegExp(`${type}.`, "i"), "")
      );
    }
    const { locales, t } = this.props;
    const firstLine = cloneDeep(data[0]) || {};
    const translatedKeys = recursiveTranslate(
      "fr",
      type.toLowerCase(),
      firstLine,
      locales.locale
    );
    const selectOptions = {
      manufacturer: _.uniq(
        data
          .map((line) => line.manufacturer)
          .filter((man) => man && man.length > 0)
      ).map((man) => ({
        value: man,
        label: man === "UNKNOWN" ? "Inconnu" : man,
      })),
      model: _.uniq(
        data
          .map((line) => line.model)
          .filter((model) => model && model.length > 0)
      ).map((model) => ({
        value: model,
        label: model === "UNKNOWN" ? "Inconnu" : model,
      })),
      type: _.uniq(
        data.map((line) => line.type).filter((type) => type && type.length > 0)
      ).map((type) => ({
        value: type,
        label: type === "UNKNOWN" ? "Inconnu" : type,
      })),
    };
    const columns = translatedKeys
      .map((champ: any) => ({
        dataField: champ.path,
        text: t(`columns.${champ.path}`),
        default: defaultColumns.includes(champ.path),
        headerStyle: () => ({ width: `${100 / translatedKeys.length}%` }),
        formatter: ListTools.findFormatter(champ.path),
        filter: _.get(selectOptions, champ.path)
          ? selectFilter({
              options: _.get(selectOptions, champ.path),
              placeholder: "TOUS",
              onFilter: (v: any) => this.handleFastFilter(champ.path, v),
              defaultValue: filter[champ.path],
            })
          : textFilter({
              placeholder: t(`columns.${champ.path}`),
              onFilter: (v: any) => this.handleFastFilter(champ.path, v),
              defaultValue: filter[champ.path],
            }),
      }))
      .filter((it) => it.default);
    const clonedData = cloneDeep(data);

    const rowEvents = this.getRowEvents(type);

    return (
      <Col
        xs="12"
        className={columns && columns.length > 6 ? "crystalList-container" : ""}
      >
        {clonedData && clonedData.length > 0 && (
          <BootstrapTable
            keyField="id"
            rowClasses="clickable"
            data={clonedData}
            bootstrap4
            bordered={false}
            columns={columns}
            hover
            headerClasses="crystalList-column"
            pagination={paginationFactory()}
            filter={filterFactory()}
          />
        )}
      </Col>
    );
  };

  /**
   * Gère les évènements du tableau en fonction du
   * type de résultat
   *
   * @method getRowEvents
   * @param {string} type Type
   */
  getRowEvents = (type: string) => ({
    onClick: (e: Object, row: any) => {
      const { match } = this.props;
      const { results } = this.state;
      const data = results[Resources.resultEnum[type].List];
      const current = data.find((it: any) => it.id === row.id);
      let locationId =
        match && match.params && match.params.locationId
          ? match.params.locationId
          : null;
      if (locationId === null && current.location !== null) {
        locationId = current.location.id;
      }

      let uri = "";
      if (locationId !== null) {
        switch (type) {
          case Resources.resultEnum.Meter.Label:
            uri = `/locations/${locationId}/meters/info?id=${row.id}`;
            break;
          case Resources.resultEnum.Radio.Label:
            uri = `/locations/${locationId}/radios/info?id=${row.id}`;
            break;
          case Resources.resultEnum.PDI.Label:
            uri = `/locations/${locationId}/pdi/${row.id}`;
            break;
          default:
            uri = "";
        }
      }

      this.setState({
        info: {
          data: current,
          type,
          uri,
        },
        isOpen: true,
      });
    },
  });

  /**
   * Affiche la mini fiche en fonction du type
   * de l'élément contenu dans le state
   *
   * @method getCurrentInfo
   * @returns {JSX} La fiche
   */
  getCurrentInfo = () => {
    const { info } = this.state;
    if (info && Object.keys(info).length > 0) {
      switch (info.type) {
        case Resources.resultEnum.Meter.Label:
          return <TinyMeter meter={info.data} />;
        case Resources.resultEnum.Radio.Label:
          return <TinyRadio radio={info.data} />;
        case Resources.resultEnum.PDI.Label:
          return <TinySourceSheetPDI pdi={info.data} />;
        default:
          return <div />;
      }
    }
  };

  /**
   * Réinitialiste les informations du state
   * relatives à la recherche, ainsi que celles
   * contenues dans le local storage
   *
   * @method reinit
   */
  reinit = () => {
    const { key } = this.state;
    storedSearchService.removeSearch(key);
    this.setState({
      results: {},
      query: "",
      activeTab: null,
    });
  };

  /**
   * Construit le composant et mise en place
   * d'un système d'onglets pour les résultats
   * de la recherche
   *
   * @method render
   */
  render() {
    const { pdis, meters, radios, t } = this.props;
    const { activeTab, filter } = this.state;
    return (
      <>
        {((pdis && pdis.loading) ||
          (meters && meters.loading) ||
          (radios && radios.loading)) && <Loading />}
        <div className="col-12">
          {pdis && pdis.error && <ErrorBand message={pdis.error} />}
          {meters && meters.error && <ErrorBand message={meters.error} />}
          {radios && pdis.error && <ErrorBand message={radios.error} />}

          {pdis &&
            pdis.items &&
            meters &&
            meters.items &&
            radios &&
            radios.items && (
              <div className="table-info-container">
                <h2>
                  <span>
                    <ColonneVert
                      height="1em"
                      width="1em"
                      stroke="#31c6b3"
                      fill="white"
                      strokeWidth="1.5"
                    />
                  </span>
                  {t("all.text.resource_list")}
                </h2>
                <Nav tabs>
                  <NavItem key="1">
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.toggleTab("1");
                      }}
                    >
                      {t("all.pdi.pdi").toUpperCase()} : {pdis.items.length}
                    </NavLink>
                  </NavItem>
                  <NavItem key="2">
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.toggleTab("2");
                      }}
                    >
                      {t("all.meter.meter_plural")} : {meters.items.length}
                    </NavLink>
                  </NavItem>
                  <NavItem key="3">
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.toggleTab("3");
                      }}
                    >
                      {t("all.radio.radio_plural")} : {radios.items.length}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1" key="1">
                    {activeTab === "1" && (
                      <Card style={{ borderTop: "none" }}>
                        <br />
                        {this.getTableResult(pdis.items, "PDI")}
                      </Card>
                    )}
                  </TabPane>
                  <TabPane tabId="2" key="2">
                    {activeTab === "2" && (
                      <Card style={{ borderTop: "none" }}>
                        <br />
                        {this.getTableResult(meters.items, "Meter")}
                      </Card>
                    )}
                  </TabPane>
                  <TabPane tabId="3" key="3">
                    {activeTab === "3" && (
                      <Card style={{ borderTop: "none" }}>
                        <br />
                        {this.getTableResult(radios.items, "Radio")}
                      </Card>
                    )}
                  </TabPane>
                </TabContent>
              </div>
            )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, meters, locations, locales, radios, pdis } = state;
  const { user } = authentication;

  return {
    alert,
    user,
    meters,
    radios,
    pdis,
    locations,
    locales,
  };
}

const connectedResources = connect(mapStateToProps)(Resources);
const tr = withTranslation()(connectedResources);
export default tr;
