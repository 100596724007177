import React, { useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { formatDatasets } from "../_helpers/datasets-helper";
import { widgetConstants } from "../_constants";
import "./plugin";
import "./pluginOverlay";
import _ from "lodash";
import CustomBar from "./CustomBar";
import translate from "../_helpers/locale-helpers";
import SvgChartBarVerticalNotAvailable from "../SvgComponents/ChartBarVerticalNotAvailable";
import SvgPieNotAvailable from "../SvgComponents/PieNotAvailable";
import "chartjs-plugin-datalabels";

/**
 *
 *
 * @export
 * @method convertDetail
 * @param {string} dateLabel La date à formater
 * @param {string} format Le format demandé
 * @returns {String} La date formatée
 */
export function convertDate(dateLabel: string, format: string | undefined) {
  switch (format) {
    case "Minute":
      return `${moment(dateLabel).format("DD/MM/YYYY H:mm").toString()}`;
    case "Hour":
      return `${moment(dateLabel).format("DD/MM/YYYY H").toString()}h`;

    case "Day":
      return `${moment(dateLabel).format("DD/MM/YYYY").toString()}`;

    case "Week":
      return `${moment(dateLabel).format("DD/MM/YYYY").toString()}`;

    case "Month":
      return `${moment(dateLabel).format("MM/YYYY").toString()}`;

    case "Year":
      return `${moment(dateLabel).format("YYYY").toString()}`;

    default:
  }
  return dateLabel;
}

const readMethod = (type: any, t: Function) => {
  switch (type) {
    case "A":
      return t("all.read_meter.type.A");
    case "T":
      return t("all.read_meter.type.T");
    default:
      return t("all.read_meter.type.M");
  }
};

export const customTooltips = function (
  ctx: any,
  metersInfo: any,
  formatTime: string,
  t: Function,
  canvas: any
) {
  if (ctx.body && ctx.body[0]) {
    const value = ctx.body[0].lines[0].split(" ");
    if (value[1] === "0.00001") {
      ctx.body[0].lines[0] = `${value[0]} 0`;
    }
    const isTerrain: boolean = ctx.body[0].lines[0].includes(
      t("source_sheet_pdi.text.terrain")
    );
    const serial = isTerrain
      ? ctx.body[0].lines[0].split("-")[1].split(":")[0]
      : ctx.body[0].lines[0].split(":")[0];
    const meter = metersInfo.find(
      (meter: any) => meter.general.serial === serial
    );
    const index = meter.reads.find(
      (index: any) => convertDate(index.date, formatTime) === ctx.title[0]
    );
    let alarms = meter.alarms.data.find(
      (alarm: any) => convertDate(alarm.date, formatTime) === ctx.title[0]
    );
    const releveTerrain = meter.releveTerrains.find(
      (read: any) => convertDate(read.currentDate, formatTime) === ctx.title[0]
    );
    if (releveTerrain || index) {
      ctx.body[0].after.push(
        `${t("all.meter.index")}: ${index && index.index} m³`
      );
    }
    ctx.body[0].after.push(
      `${t("all.read_meter.cons")}: ${ctx.dataPoints[0].yLabel} m³`
    );
    if (isTerrain && releveTerrain) {
      ctx.body[0].after.push(`--------`);
      ctx.body[0].after.push(
        `${t("all.text.previous_releve")}: ${moment(
          releveTerrain.previousDate
        ).format("DD/MM/YYYY")}`
      );
      ctx.body[0].after.push(
        `${t("fiche_detail.title.previous_index")}: ${
          releveTerrain.previousIndex
        } m³`
      );
    } else if (index) {
      ctx.body[0].after.push(
        `${t("columns.read.source")}: ${readMethod(index.source, t)}`
      );
    }
    ctx.width = 200;

    if (alarms && alarms.types) {
      alarms = _.entries(alarms.types).map((el: any) => el[0]);
      let maxWidth = 0;
      alarms.forEach((el: any, index: number) => {
        if (index === 0) {
          ctx.body[0].after.push(
            `Alarme${alarms.length > 1 ? "s" : ""}: - ${t(
              `alarm.type.${el.toLowerCase()}`
            )}`
          );
          maxWidth = t(`alarm.type.${el.toLowerCase()}`).length;
        } else {
          ctx.body[0].after.push(
            `               - ${t(`alarm.type.${el.toLowerCase()}`)}`
          );
          if (t(`alarm.type.${el.toLowerCase()}`).length > maxWidth) {
            maxWidth = t(`alarm.type.${el.toLowerCase()}`).length;
          }
        }
      });
      ctx.width += maxWidth * 2;
    }
    const domCanvas = _.get(canvas, "current.chartInstance.canvas")
      ? _.get(canvas, "current.chartInstance.canvas").getBoundingClientRect()
      : null;
    if (domCanvas && ctx.width + ctx.x > domCanvas.width) {
      ctx.x = domCanvas.width - ctx.width;
    }
    ctx.height += ctx.body[0].after.length * 15;
    ctx.y -= (ctx.body[0].after.length * 15) / 2;
  }
};

interface bornes {
  begin: string;
  end: string;
}

interface Props {
  labels: Array<string>;
  data: any;
  type: string;
  bornes?: bornes | null | undefined;
  formatTime: string | undefined;
  tooltip?: Function | null | undefined;
  click?: Function | null | undefined;
  title?: string;
  additional?: any;
  startDates?: any;
  dimensions?: any;
  values?: any;
  isMasked?: boolean;
  begin_zero?: boolean | any;
  dataLabel?: boolean | any;
  metersInfo?: any | any;
  locales?: any;
  historique?: boolean | any;
  grounData?: any;
  t: Function;
  noDataLabels?: boolean;
}

/**
 * Composant chart utilisé pour afficher les différents
 * graphes de l'application
 *
 * @export
 * @method Chart
 * @param {Props} props Propriétés
 * @returns {JSX} Le chart
 */
export function Chart(props: Props) {
  const {
    labels,
    data,
    type,
    bornes,
    formatTime,
    tooltip,
    click,
    additional,
    startDates,
    dimensions,
    values,
    isMasked,
    begin_zero,
    dataLabel,
    metersInfo,
    locales,
    historique,
    t,
    noDataLabels,
  } = props;

  const [datasets, setDatasets] = useState([]);
  const [labelState, setLabelState] = useState([]);

  const alarmKeys: Array<string> = _.map(data, (alarms: any) => alarms.keySvg);

  const sData = {
    labels: labelState,
    datasets,
  };

  useEffect(() => {
    setDatasets(formatDatasets(type, data, additional, datasets, isMasked));
  }, [data]);

  useEffect(() => {
    setLabelState(labels.map((label) => convertDate(label, formatTime)));
  }, [labels]);

  let min = 0;
  let max = 1;
  let stepSize = 0.1;

  if (data.length > 0) {
    scaleAndFilter(additional.type, begin_zero);
  }

  const options: any = {
    plugins: {
      t1: true,
      datalabels: {
        display(ctx: any) {
          const coef: number = Math.round(labels.length / 15);
          return ctx.dataset.data.filter((el: any) => el !== null).length < 10
            ? true
            : ctx.dataIndex % coef === 0;
        },
        color(ctx: any) {
          return "#4e4e4e";
        },
        borderWidth: 1,
        formatter(value: any) {
          return noDataLabels
            ? ""
            : value === "0.00001"
            ? "0"
            : (value && value.toString()) || "";
        },
        align: "end",
        anchor: "end",
      },
    },
    emptyOverlay: {
      message:
        data.length === 0
          ? t("all.text.no_data_available")
          : "Consommation nulle",
    },
    legend: {
      display: checkLegend(),
    },
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      yAxes: generateYAxis(data, type),
      xAxes: [
        {
          stacked: type === "StackedHistogram" || type === "AlarmMaskHistogram",
          ticks: {
            min: bornes && bornes.begin ? bornes.begin : "",
            max: bornes && bornes.end ? bornes.end : "",
          },
        },
      ],
    },
    lineHeightAnnotation: {
      color: "black",
      always: type === "Curve",
      startDateIndex: startDates || [],
    },
  };

  if (
    type !== "StackedHistogram" &&
    type !== "Mixed" &&
    type !== "AlarmMaskHistogram"
  ) {
    options.scales.yAxes.map((it: any) => {
      it.ticks = {
        beginAtZero: !((data && data.length > 0) || begin_zero),
        min,
        max,
        stepSize,
      };
    });
  }

  if (metersInfo) {
    options.tooltips = {
      custom: (ctx: any) =>
        customTooltips(ctx, metersInfo, formatTime, props.t, null),
    };
  }

  if (type === "Curve") {
    options.scales.xAxes.map((it: any) => {
      it.barPercentage = 0.25;
      it.ticks = {
        callback(value: any, index: any, values: any) {
          if (
            startDates &&
            Number.isInteger(startDates.find((el: any) => el === index))
          ) {
            return `Changement le : ${value}`;
          }
          return value;
        },
      };
    });
  }
  if (historique) {
    options.scales.xAxes = [
      {
        ticks: {
          display: false,
        },
      },
    ];
  }

  if (tooltip !== undefined) {
    options.tooltips = {
      enabled: true,
      custom: tooltip,
    };
  }
  if (click !== undefined) {
    options.onClick = click;
  }

  const barVerticalNotAvailable = (
    <div style={{ textAlign: "center" }}>
      <SvgChartBarVerticalNotAvailable
        height="300px"
        fill="#E5E5E4"
        style={{ maxWidth: "-webkit-fill-available" }}
      />
    </div>
  );
  const pieNotAvailable = (
    <div style={{ textAlign: "center" }}>
      <SvgPieNotAvailable
        height="300px"
        fill="#E5E5E4"
        style={{ maxWidth: "-webkit-fill-available" }}
      />
    </div>
  );
  // sData.key = 'ca fonctionne';
  // background-image: linear-gradient(to top, #4fb576 0%, #44c489 30%, #28a9ae 46%, #28a2b7 59%, #4c7788 71%, #6c4f63 86%, #432c39 100%);
  console.log(sData);
  switch (type) {
    case "Histogram":
      return labels.length === 0 ? (
        barVerticalNotAvailable
      ) : (
        <Bar data={sData} options={options} />
      );
    case "Mix":
    case "StackedHistogram":
      return labels.length === 0 ? (
        barVerticalNotAvailable
      ) : (
        <Bar data={sData} options={options} />
      );
    case "AlarmMaskHistogram":
      return labels.length === 0 ? (
        barVerticalNotAvailable
      ) : (
        <CustomBar
          data={sData}
          options={options}
          alarmKeys={alarmKeys}
          dataValues={props.data}
          switchOff={isMasked}
        />
      );
    case "Curve":
      const withTerrain = props.data.filter((el: any) =>
        el.serial.includes("Terrain")
      );
      const data = (canvas: any) => {
        const ctx = canvas.getContext("2d");
        const gradient0 = ctx.createLinearGradient(
          0,
          dimensions ? dimensions.height : 0,
          -1,
          0
        );
        const gradient1 = ctx.createLinearGradient(
          0,
          dimensions ? dimensions.height : 0,
          -1,
          0
        );
        // const gradient2 = ctx.createLinearGradient(0, dimensions ? dimensions.height : 0, -1, 0);
        gradient0.addColorStop(1, "#87FC83");
        gradient0.addColorStop(0, "#31c6b3");
        gradient1.addColorStop(1, "#fa709a");
        gradient1.addColorStop(0, "#fee140");
        const gradient = [gradient0, gradient1];
        return {
          labels: labels.map((date: any) => convertDate(date, formatTime)),
          datasets: props.data
            .map((el: any, index: number) => {
              const isGround: boolean = el.serial.includes("Terrain");
              return {
                type: !isGround ? "line" : "bar",
                label: el.serial,
                fill: !isGround,
                lineTension: 0.1,
                backgroundColor: isGround ? "#6e004eaa" : gradient[index % 2],
                borderColor: isGround ? "#5d003eaa" : gradient[index % 2],
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "rgba(75,192,192,1)",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 3,
                pointHoverRadius: 10,
                pointHoverBackgroundColor: "rgba(75,192,192,1)",
                pointHoverBorderColor: "rgba(220,220,220,1)",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 1,
                data: el.listConso,
                spanGaps: !isGround,
              };
            })
            .sort(
              (a: any, b: any) =>
                (b.label.includes("Terrain") ? 1 : 0) -
                (a.label.includes("Terrain") ? 1 : 0)
            ),
        };
      };

      return labels.length === 0 ? (
        barVerticalNotAvailable
      ) : (
        <Bar data={data} options={options} />
      );

    case "Pie":
      return labels.length === 0 ? (
        pieNotAvailable
      ) : (
        <Doughnut data={sData} options={options} />
      );
    default:
      return <div>Chart unknown</div>;
  }

  function checkLegend() {
    const checkType = type === "Mix" || type === "StackedHistogram";
    let checkAdditional = false;
    if (undefined != additional) {
      checkAdditional =
        additional.type === widgetConstants.MULTIREADINDEXWIDGET ||
        additional.type === widgetConstants.MULTIREADCONSOWIDGET;
    }
    return checkType || checkAdditional;
  }

  function scaleAndFilter(type: string, begin_zero: boolean) {
    let listValues: any;
    if (
      type === widgetConstants.VIRTUALALARMWIDGET ||
      type === widgetConstants.ALARMWIDGET
    ) {
      listValues = cloneDeep(data).map((it: any) => {
        it.data = it.data.slice(
          bornes && bornes.begin !== ""
            ? sData.labels.findIndex((l) => l === bornes.begin)
            : 0,
          bornes && bornes.end !== ""
            ? sData.labels.findIndex((l) => l === bornes.end) + 1
            : labels.length
        );
        return it;
      });
    } else {
      if (
        type === widgetConstants.MULTIREADINDEXWIDGET ||
        type === widgetConstants.MULTIREADCONSOWIDGET
      ) {
        const allData: any[] = [];
        data.forEach((it: any) => {
          const currentData = cloneDeep(it.data).slice(
            bornes && bornes.begin !== ""
              ? sData.labels.findIndex((l) => l === bornes.begin)
              : 0,
            bornes && bornes.end !== ""
              ? sData.labels.findIndex((l) => l === bornes.end) + 1
              : labels.length
          );
          currentData.forEach((d: any) => {
            allData.push(d);
          });
        });
        listValues = allData;
      } else {
        listValues = cloneDeep(data).slice(
          bornes && bornes.begin !== ""
            ? sData.labels.findIndex((l) => l === bornes.begin)
            : 0,
          bornes && bornes.end !== ""
            ? sData.labels.findIndex((l) => l === bornes.end) + 1
            : labels.length
        );
        if (props.type === "Curve" && values && values.conso) {
          listValues = cloneDeep(values.conso).slice(
            bornes && bornes.begin !== ""
              ? sData.labels.findIndex((l) => l === bornes.begin)
              : 0,
            bornes && bornes.end !== ""
              ? sData.labels.findIndex((l) => l === bornes.end) + 1
              : labels.length
          );
        }
      }
      listValues = listValues.filter((it: any) => !Number.isNaN(it));
      min = Math.min(...listValues);
      max = Math.max(...listValues);
      if (begin_zero) {
        min = 0;
      }
      if (max - min > 1) {
        min = Math.floor(min);
        max = Math.ceil(max);
      } else {
        const addToBornes = max === min ? max * 0.1 : (max - min) / 10;
        min = min === 0 ? 0 : (min -= addToBornes);
        max += addToBornes;
      }
      stepSize = max === min ? (max * 0.1) / 6 : (max - min) / 6;
      if (stepSize > 1 && stepSize < 2) stepSize = 1;
      if (props.type === "Curve") {
        min -= min * 0.1;
      }
    }
  }

  function generateYAxis(data: any, type: string) {
    const yAxis: any = [];

    data.forEach((it: any) => {
      if (Object.keys(it).length > 0 && it.hasOwnProperty("yAxisId")) {
        const axis: any = {
          id: `y-${it.yAxisId}`,
          type: it.type,
        };
        if (it.ref) {
          axis.position = "left";
        } else {
          axis.position = "right";
          axis.gridLines = {
            drawOnChartArea: false,
          };
        }
        yAxis.push(axis);
      }
    });

    if (yAxis.length === 0) {
      yAxis.push({
        stacked: type === "StackedHistogram" || type === "AlarmMaskHistogram",
      });
    }
    let displayLegend = false;
    if (data && data.length > 0 && data[0].listConso) {
      displayLegend = data.some((l) =>
        l.listConso.some((v) => v !== 0 && v !== "null")
      );
    } else {
      displayLegend = data.find((el: any) => el > 0) !== undefined;
    }
    yAxis.map((it: any) => {
      it.display = displayLegend;
    });
    return yAxis;
  }
}
