import React from "react";
import { Bar } from "react-chartjs-2";
import { withTranslation } from "react-i18next";
import moment from "moment";

const ChartPdf = (props) => {
  const { labels, data, unit, t } = props;
  const options = {
    responsive: true,
    plugins: {
      t1: true,
      t2: false,
      datalabels: {
        display: function (ctx) {
          return ctx.dataset.data.length - 1 === ctx.dataIndex ? false : "auto";
        },
        color: "black",
        borderWidth: 0,
        anchor: "end",
        clamp: true,
        align: "end",
      },
    },
    layout: {
      padding: {
        top: 20,
        bottom: 20,
      },
    },
    emptyOverlay: {
      message:
        data.length === 0
          ? t("all.text.no_data_available")
          : "Consommation nulle",
    },
    legend: {
      display: false,
    },
  };

  const xData = {
    labels: labels.map((el) => moment(el).format(t("all.date_format.date"))),
    datasets: [
      {
        label: unit,
        data,
        backgroundColor: "#97ddfb",
      },
    ],
  };

  return <Bar data={xData} options={options} />;
};

export default withTranslation()(ChartPdf);
