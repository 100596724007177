import React, { Component, Fragment, useState, useEffect } from "react";
import { Button, Spinner } from "reactstrap";
import { FaCog, FaRedo } from "react-icons/fa";
import _, { cloneDeep, isArray, isUndefined } from "lodash";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import translate from "../_helpers/locale-helpers";
import { alertActions } from "../_actions";
import SimpleWidget from "./SimpleWidget";
import FullScreenWidget from "./FullScreenWidget";
import WidgetBlanc from "../SvgComponents/WidgetBlanc";
import TinyWidget from "./TinyWidget";
import LoadingBand from "../Bands/Loading";
import widgetActions from "../_actions/widget.actions";
import SvgPetitEcranVert from "../SvgComponents/PetitEcranVert";
import { withTranslation } from "react-i18next";
import BarChart from "./chart/BarChart";
import LineChart from "./chart/LineChart";
import colorActions from "../_actions/color.actions";
import AlarmBarChart from "./chart/AlarmBarChart";

const BaseWidget = (props: any) => {
  const {
    data,
    widgets,
    openEditor,
    locales,
    dispatch,
    match,
    quitFullScreen,
    colors,
    isPreview,
    dataPreview,
    containerId,
    remove,
    displayFullScreen,
    couldEditWidget,
    t,
  } = props;
  const [dataState, setData] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMasked, setIsMasked] = useState(false);

  useEffect(() => {
    getContent();
    setData(data);
    dispatch(colorActions.load());
  }, []);

  useEffect(() => {
    console.log("update");
    if (dataState && data && widgets.data && widgets.data[data.id]) {
      setUpdated(true);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (widgets.lastUpdate === data.id) {
      setUpdated(!updated);
    }
  }, [widgets.lastUpdate]);

  const getContent = () => {
    dispatch(
      widgetActions.getWidgetData(
        data.id,
        data.content.dataSourceProperty.displayID
      )
    );
  };

  const openWidgetEditor = () => {
    dispatch(alertActions.clear());
    if (openEditor) {
      openEditor(data.id);
    }
  };

  const getSubtitleData = (dataSourceProperty: any) => {
    let display = "";
    if (dataSourceProperty) {
      let data: any[] = [];
      if (dataSourceProperty.displayValue) {
        display = `${translate(
          "fr",
          "displayValue",
          dataSourceProperty.displayValue,
          locales.locale
        )}`;
      }
      if (
        dataSourceProperty.displayProperty &&
        dataSourceProperty.displayProperty.condition &&
        dataSourceProperty.displayProperty.condition.length > 0
      ) {
        const interval: any = dataSourceProperty.displayProperty.condition.find(
          (it: any) => it.conditionType === "DateInterval"
        );
        if (interval && null !== interval.conditionValue) {
          data.push(
            `${interval.conditionValue.value} ${translate(
              "fr",
              "conditionHelper",
              interval.conditionValue.name,
              locales.locale,
              "name"
            )}`
          );
          data.push(
            translate(
              "fr",
              "conditionHelper",
              interval.conditionValue.zoom,
              locales.locale,
              "zoom"
            )
          );
        }
      }
      if (dataSourceProperty.displayUnit) {
        data.push(
          translate(
            "fr",
            "unit",
            dataSourceProperty.displayUnit,
            locales.locale
          )
        );
      }

      if (data.length > 0) {
        display = `${display} - ${data.join(" / ")}`;
      }
    }

    return display;
  };

  const getHeader = () => {
    const dataSourceProperty = data.content
      ? data.content.dataSourceProperty
      : null;
    const displayValue = dataSourceProperty
      ? dataSourceProperty.displayValue
      : "";
    const conditions = dataSourceProperty
      ? dataSourceProperty.displayProperty.condition
      : [];

    const conditionSerialNumber = conditions.find((c: any) =>
      c.conditionTitle.includes("SerialNumber")
    );
    let sourceType: string = "";
    if (undefined !== conditionSerialNumber) {
      sourceType = !conditionSerialNumber.conditionValue.startsWith("V-")
        ? data.content.dataSourceName.toLowerCase()
        : "virtual";
    }

    const linkToInfo =
      sourceType.length > 0
        ? `/locations/${match.params.locationId}/${sourceType}s/info?serial=${conditionSerialNumber.conditionValue}`
        : "";
    return (
      <Fragment>
        {data.configured && (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <h2
              style={{ fontSize: "1.2em", maxWidth: "75%", overflow: "hidden" }}
            >
              <span>
                <WidgetBlanc height="1em" width="1em" fill="#31c6b3" />
              </span>{" "}
              {conditionSerialNumber && (
                <Link to={linkToInfo}>
                  {conditionSerialNumber.conditionValue}
                </Link>
              )}{" "}
              - {data.name} <br />
              <span
                style={{
                  fontWeight: "normal",
                  fontSize: "12px",
                  marginLeft: "6px",
                }}
              >
                {getSubtitleData(dataSourceProperty)}
              </span>
            </h2>
            {quitFullScreen && (
              <div className="head-widget">
                <div
                  className="clickable round"
                  role="presentation"
                  onClick={quitFullScreen as any}
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <SvgPetitEcranVert
                    className="add"
                    fill="#808080"
                    height="1.5em"
                    width="1.5em"
                  />
                </div>
              </div>
            )}
            <div className="clearfix" />
          </div>
        )}
        {!data.configured && undefined !== data.content && (
          <span style={{ textAlign: "justify" }}>
            <h2 style={{ fontSize: "1.2em", fontWeight: "bold" }}>
              {t("widget.text.not_configured")} -{" "}
              {t(
                `widget.display_value.${data.content.dataSourceProperty.displayValue.toLowerCase()}`
              )}{" "}
              <br />
              <span
                style={{
                  fontWeight: "normal",
                  fontSize: "12px",
                  marginLeft: "6px",
                }}
              >
                {getSubtitleData(displayValue)}
              </span>
            </h2>
          </span>
        )}
      </Fragment>
    );
  };

  const getBody = (isMasked: boolean) => {
    const isConfigured = data.configured;
    if (isConfigured) {
      if (loading) {
        return <LoadingBand message="Chargement du widget" />;
      }
      //return <YieldChart />;
      const displayId = data.content.dataSourceProperty.displayID;
      const type = data.content.dataSourceProperty.displayProperty.displayType;
      const zoom =
        data.content.dataSourceProperty.displayProperty.condition[
          data.content.dataSourceProperty.displayProperty.condition.length - 1
        ].conditionValue.zoom;
      if (widgets.data && widgets.data[data.id]) {
        switch (type) {
          case "Histogram":
            return displayId.includes("ALARM") ? (
              //@ts-ignore
              <AlarmBarChart id={data.id} zoom={zoom} colors={colors} />
            ) : (
              //@ts-ignore
              <BarChart id={data.id} zoom={zoom} />
            );
          default:
            //@ts-ignore
            return <LineChart id={data.id} zoom={zoom} />;
        }
      }
    }
    return <FaCog size={100} style={{ marginTop: "9%" }} />;
  };

  const removeWidget = () => {
    if (remove) {
      remove(data.id);
    }
  };

  const dicoData = data.content ? data.content.returnedData : [];
  const properties =
    data.content && data.content.dataSourceProperty.displayProperty;
  return (
    <Fragment>
      {isPreview && <TinyWidget bodyWidget={getBody(isMasked)} />}
      {!isUndefined(quitFullScreen) &&
        isUndefined(displayFullScreen) &&
        isUndefined(couldEditWidget) && (
          <FullScreenWidget
            headerWidget={getHeader()}
            bodyWidget={getBody(isMasked)}
            isConfigured={data.configured}
            dicoData={dicoData}
            properties={properties}
          />
        )}{" "}
      {isUndefined(quitFullScreen) &&
      !isUndefined(displayFullScreen) &&
      !isUndefined(couldEditWidget) &&
      ((widgets.data && widgets.data[data.id]) || !data.configured) ? (
        <SimpleWidget
          headerWidget={getHeader()}
          bodyWidget={getBody(isMasked)}
          isConfigured={data.configured}
          displayFullScreen={displayFullScreen}
          couldEditWidget={couldEditWidget}
          openWidgetEditor={openWidgetEditor}
          remove={removeWidget}
          widgetId={data.id}
        />
      ) : (
        <div className={"loading"}>
          <Spinner animation="grow" role="status" aria-hidden="true" />
        </div>
      )}{" "}
    </Fragment>
  );
};

function mapStateToProps(state: any) {
  const { widgets, locales, colors } = state;

  return {
    widgets,
    locales,
    colors,
  };
}

export default withTranslation()(connect(mapStateToProps)(BaseWidget));
