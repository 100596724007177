import React, { Component, Fragment } from "react";
import { AvField } from "availity-reactstrap-validation";
import _debounce from "lodash.debounce";

type Props = {
  service: Function;
  type: string;
  value: string;
  required: boolean;
  helpMessage: string;
  errorMessage: string;
  title: string;
  name: string;
  label: string;
};

type State = {
  activeSuggestion: number;
  filteredSuggestions: Array<any>;
  showSuggestions: boolean;
  userInput: string;
};

/**
 * @class AutocompleteFieldComponent
 * @extends {Component<Props, State>}
 */
class AutocompleteFieldComponent extends Component<Props, State> {
  /**
   * Teste si la saisie dans le champ a une
   * correspondance avec la base
   *
   * @param {string} value Saisie
   * @param {Object} ctx Contexte
   * @param {Object} input Champ
   * @param {Function} cb Fonction de retour
   * @method validateExists
   * @memberof AutocompleteFieldComponent
   */
  validateExists = _debounce((value, ctx, input, cb) => {
    const { service, type, name } = this.props;
    let fetchedsuggestions = [];

    if (value === "") {
      cb(true);
    } else {
      try {
        service(type, this.getServiceName(name), value)
          .then((result: any) => {
            fetchedsuggestions = result;
            let exists;
            if (fetchedsuggestions && fetchedsuggestions.length > 0) {
              exists = fetchedsuggestions.find(
                (suggestion: any) => suggestion === value
              );
            }
            if (typeof exists !== "undefined") {
              cb(true);
            } else {
              cb("Aucune correspondance");
            }
          })
          .catch((error: any) => console.log(error));
      } catch (error) {
        console.error(error);
      }
    }
  }, 300);

  /**
   * @param {Props} props
   * @constructor
   * @memberof AutocompleteFieldComponent
   */
  constructor(props: Props) {
    super(props);
    const { value } = this.props;
    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: value,
    };

    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  /**
   * Relance la recherche des suggestions à chaque
   * changement dans l'input
   *
   * @method onChange
   * @memberof AutocompleteFieldComponent
   */
  onChange = (e: any) => {
    const userInput = e.currentTarget.value;
    const { service, type, name } = this.props;
    let fetchedsuggestions: any = [];

    this.setState({
      filteredSuggestions: [],
    });
    try {
      service(type, this.getServiceName(name), userInput)
        .then((result: any) => {
          fetchedsuggestions = result;
          if (fetchedsuggestions && fetchedsuggestions.length > 0) {
            this.filterSuggestion(fetchedsuggestions, userInput);
          }
        })
        .catch((error: any) => console.log(error));
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * @method getServiceName
   * @param {string} name Nom du service
   */
  getServiceName = (name: string) => {
    const splitedName = name.match(/\w*(?=Ref|_)/);
    return null !== splitedName ? splitedName[0] : name;
  };

  /**
   * Filtre les résultats de la recherche en fonction
   * de la saisie utilisateur
   *
   * @param {Array<string>} result Suggestions
   * @param {string} userInput Saisie utilisateur
   * @method filterSuggestion
   * @memberof AutocompleteFieldComponent
   */
  filterSuggestion = (result: Array<string>, userInput: string) => {
    const filteredSuggestions = result.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput,
    });
  };

  /**
   * Change la valeur du champ au clic
   * sur une suggestion
   *
   * @method onClick
   * @memberof AutocompleteFieldComponent
   */
  onClick = (e: any) => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
    });
  };

  /**
   * Gère les différents comportement à l'appui
   * de certaines touches du clavier
   *
   * @method onKeyDown
   * @memberof AutocompleteFieldComponent
   */
  onKeyDown = (e: any) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      e.preventDefault();
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof AutocompleteFieldComponent
   */
  render() {
    const {
      activeSuggestion,
      filteredSuggestions,
      showSuggestions,
      userInput,
    } = this.state;
    const { title, required, helpMessage, errorMessage, name, label } =
      this.props;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={suggestion}
                  onClick={this.onClick}
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>Pas de résultats</em>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <AvField
          key={title}
          name={name}
          label={label}
          required={required}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          value={userInput}
          validate={{ async: this.validateExists }}
          helpMessage={helpMessage}
          errorMessage={errorMessage}
          autoComplete="off"
        />
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default AutocompleteFieldComponent;
