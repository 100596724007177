import React, { Component, ComponentType, useRef, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { compose } from "redux";

const handleDate = (date: any, t: any, callBack: Function) => {
  callBack(date, t);
};

const SimpleDateComponent = (props: any) => {
  const { isRound, startReleveDate, handleChange, t } = props;
  const delayedQuery = useRef(
    _.debounce((q: any, type: any) => handleDate(q, type, handleChange), 500)
  ).current;

  const minDate = moment(startReleveDate).format("YYYY-MM-DD").toString();
  const now = moment().endOf("day").format("YYYY-MM-DD").toString();

  const [start, setStart] = useState(minDate);
  const [end, setEnd] = useState(now);

  const handleChangeDebounced = (e: any) => {
    setStart(moment(e.target.value).format("YYYY-MM-DD"));
    delayedQuery(e.target.value, "start");
  };

  const handleEndDateDebounced = (e: any) => {
    setEnd(moment(e.target.value).format("YYYY-MM-DD"));
    delayedQuery(e.target.value, "end");
  };

  return (
    <div className="simple-date-component flex-box" style={{ width: "100%" }}>
      <FormGroup style={{ width: "25%", marginRight: "10px" }}>
        <Label
          for="start-date"
          style={{ fontStyle: "italic", marginBottom: "2px" }}
        >
          {t(
            isRound ? "all.read_meter.read_start_date" : "all.text.start_date"
          )}
        </Label>
        {isRound ? (
          <Input
            type="date"
            value={start}
            max={end}
            min={minDate}
            onChange={handleChangeDebounced}
          />
        ) : (
          <Input
            type="date"
            name="date"
            id="start-date"
            onChange={handleChangeDebounced}
            value={start}
            max={end}
          />
        )}
      </FormGroup>
      <FormGroup style={{ width: "25%", marginRight: "10px" }}>
        <Label
          for="end-date"
          style={{ fontStyle: "italic", marginBottom: "2px" }}
        >
          {t(isRound ? "all.date.today_date" : "all.date.end_date")}
        </Label>
        <Input
          type="date"
          value={end}
          max={now}
          min={start}
          onChange={handleEndDateDebounced}
        />
      </FormGroup>
    </div>
  );
};

const connectedSimpleDate = compose<ComponentType<any>>(
  withRouter,
  connect()
)(SimpleDateComponent);
const tr = withTranslation()(connectedSimpleDate);
export default tr;
