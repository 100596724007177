import _ from "lodash";
import pdiConstants from "../_constants/pdi.constants";
import { action } from "../_interfaces/action";

export default function pdis(state: any = {}, action: any) {
  switch (action.type) {
    case pdiConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: !Array.isArray(state.items),
      };
    case pdiConstants.GETALL_SUCCESS:
      //TODO
      console.log("default::pdis::14", action);
      return {
        items: action.pdis.map((it: any) => {
          it.alarms = it.alarms && it.alarms.join();
          return it;
        }),
      };
    case pdiConstants.GETALL_FAILURE:
      return {
        error: "Impossible d'obtenir la liste distante des pdi",
      };
    case pdiConstants.GETALLWITHCHILDREN_REQUEST:
      return {
        ...state,
        loading: !Array.isArray(state.items),
      };
    case pdiConstants.GETALLWITHCHILDREN_SUCCESS:
      //TODO
      return {
        items: action.pdis,
      };
    case pdiConstants.GETALLWITHCHILDREN_FAILURE:
      return {
        error: "Impossible d'obtenir la liste distante des pdi",
      };
    case pdiConstants.GETALLWITHCHILDREN_LIMIT_REQUEST:
      return {
        ...state,
        loading: !Array.isArray(state.items),
      };
    case pdiConstants.GETALLWITHCHILDREN_LIMIT_SUCCESS:
      //TODO
      return {
        items: action.pdis,
        ...state,
      };
    case pdiConstants.GETALLWITHCHILDREN_LIMIT_FAILURE:
      return {
        error: "Impossible d'obtenir la liste distante des pdi",
      };
    case pdiConstants.GETALLWITHCHILDREN_RANGEDATE_REQUEST:
      return {
        ...state,
        loadingRangeDate: true,
      };
    case pdiConstants.GETALLWITHCHILDREN_RANGEDATE_SUCCESS:
      //TODO
      return {
        ...state,
        itemsRangeDate: action.pdis,
        loadingRangeDate: false,
      };
    case pdiConstants.GETALLWITHCHILDREN_RANGEDATE_FAILURE:
      return {
        loadingRangeDate: false,
        error: "Impossible d'obtenir la liste distante des pdi",
      };

    case pdiConstants.GETALLWITHCHILDREN_WITHFILTERS_REQUEST:
      return {
        ...state,
        loadingFilters: true,
      };
    case pdiConstants.GETALLWITHCHILDREN_WITHFILTERS_SUCCESS:
      return {
        ...state,
        loadingFilters: false,
        items: action.pdis,
      };
    case pdiConstants.GETALLWITHCHILDREN_WITHFILTERS_FAILURE:
      return {
        loadingFilters: false,
        error: "Impossible d'obtenir les pdi filtrés",
      };
    case pdiConstants.GET_REMOTE_PDI_REQUEST:
      return {
        ...state,
        launchRequestId: action.launchRequestId,
        loadingFilters: true,
      };
    case pdiConstants.GET_REMOTE_PDI_SUCCESS:
      if (action.launchRequestId >= state.launchRequestId) {
        console.log("reducer::pdis::102", "GOOD REQUEST");
        return {
          ...state,
          loadingFilters: false,
          items: action.pdis.data,
          count: action.pdis.count,
          countTotal: action.pdis.countTotal,
        };
      }
      console.log("reducer::pdis::102", "CANCEL REQUEST");
      return state;
    case pdiConstants.GET_REMOTE_PDI_FAILURE:
      return {
        loadingFilters: false,
        error: "Impossible d'obtenir les pdi filtrés",
      };
    case pdiConstants.EXPORT_PDI_CSV_REQUEST:
      state.processing = true;
      state.csv = null;
      return { ...state };
    case pdiConstants.EXPORT_PDI_CSV_SUCCESS:
      state.processing = false;
      state.csv = action.exports;
      return { ...state };
    case pdiConstants.EXPORT_PDI_CSV_FAILURE:
      return {
        error: "Impossible de récupérer les informations demandées",
      };
    case pdiConstants.EXPORT_PDI_CSV_STATUS_SUCCESS:
      console.log("default::pdis::128", "eoeoeoeoeoeo");
      state.processing = false;
      state.csv = action.exports;
      return { ...state };
    case pdiConstants.EXPORT_PDI_CSV_STATUS_FAILURE:
      return {
        error: "Impossible de récupérer les informations demandées",
      };
    case pdiConstants.EXPORT_PDI_DAT_REQUEST:
      state.processingDat = true;
      state.dat = null;
      return { ...state };
    case pdiConstants.EXPORT_PDI_DAT_SUCCESS:
      state.processingDat = false;
      state.dat = action.exports;
      return { ...state };
    case pdiConstants.EXPORT_PDI_DAT_FAILURE:
      return {
        ...state,
        error: "Impossible de récupérer les informations demandées",
      };
    case pdiConstants.GETSTOCK_REQUEST:
      return {
        loading: true,
      };
    case pdiConstants.GETSTOCK_SUCCESS:
      //TODO
      return {
        itemsStock: action.pdis,
      };
    case pdiConstants.GETSTOCK_FAILURE:
      return {
        error:
          "Impossible d'obtenir la liste distante des pdi des sites parents",
      };
    case pdiConstants.LINKPDI_REQUEST:
    case pdiConstants.LINKPDI_ALL_REQUEST:
      return {
        ...state,
        items: null,
        loading: true,
      };
    case pdiConstants.LINKPDI_SUCCESS:
    case pdiConstants.LINKPDI_ALL_SUCCESS:
      //TODO
      return {
        ...state,
        movedPdi: action.movedPdi.map((it: any) => it.id),
        linkActionDone: true,
      };
    case pdiConstants.LINKPDI_FAILURE:
    case pdiConstants.LINKPDI_ALL_FAILURE:
      return {
        error: "Impossible d'associer les pdis",
      };
    case pdiConstants.UNLINKPDI_REQUEST:
    case pdiConstants.UNLINKPDI_ALL_REQUEST:
      return {
        ...state,
        items: null,
        loading: true,
      };
    case pdiConstants.UNLINKPDI_SUCCESS:
    case pdiConstants.UNLINKPDI_ALL_SUCCESS:
      return {
        ...state,
        unlinkActions: action.unlinkActions,
        linkActionDone: true,
      };
    case pdiConstants.UNLINKPDI_FAILURE:
    case pdiConstants.UNLINKPDI_ALL_FAILURE:
      return {
        error: "Impossible de dissocier les pdis",
      };
    case pdiConstants.UNLINKACTIONSPDI_REQUEST:
      return {
        ...state,
        unlinkActions: { loading: true },
      };
    case pdiConstants.UNLINKACTIONSPDI_SUCCESS:
      return {
        ...state,
        unlinkActions: action.unlinkActions,
      };
    case pdiConstants.UNLINKACTIONSPDI_FAILURE:
      return {
        ...state,
        unlinkActions: {
          error:
            "Impossible d'obtenir la liste des actions pour supprimer la liaison avec les pdis",
        },
      };
    case pdiConstants.CREATEPDI_REQUEST:
      return {
        loading: true,
      };
    case pdiConstants.CREATEPDI_SUCCESS:
      //TODO
      return {
        createdPdi: action.createdPdi,
      };
    case pdiConstants.CREATEPDI_FAILURE:
      return {
        error: "Impossible de créer le pdi",
      };
    case pdiConstants.MAPID_TO_SERIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case pdiConstants.MAPID_TO_SERIAL_SUCCESS:
      //TODO
      console.log("default::pdis::210", _.size(action.mapIdToSerial));
      return {
        ...state,
        clickId: action.clickId,
        mapIdToSerial: action.mapIdToSerial,
        loading: false,
      };
    case pdiConstants.MAPID_TO_SERIAL_FAILURE:
      return {
        error: "Impossible de créer le pdi",
      };
    case pdiConstants.GETINFO_REQUEST:
      return {
        loading: true,
      };
    case pdiConstants.GETINFO_SUCCESS:
      return {
        fetchedPdi: action.fetchedPdi,
        loadingConso: true,
        loadingStats: true,
        loadingAlarms: true,
      };
    case pdiConstants.GETINFO_FAILURE:
      return {
        error: "Impossible d'obtenir les informations distantes du PDI",
      };
    case pdiConstants.GET_FULL_REQUEST:
      return {
        loading: true,
      };
    case pdiConstants.GET_FULL_SUCCESS:
      return {
        fetchedPdi: action.pdi,
      };
    case pdiConstants.GET_FULL_FAILURE:
      return {
        error: "Impossible d'obtenir les informations distantes du PDI",
      };
    case pdiConstants.ADD_GPS_POSITION_REQUEST:
      state.gpsLoading = true;
      return {
        ...state,
      };
    case pdiConstants.ADD_GPS_POSITION_SUCCESS:
      state.fetchedPdi.general = action.pdi;
      state.gpsLoading = false;
      return {
        ...state,
      };
    case pdiConstants.ADD_GPS_POSITION_FAILURE:
      return {
        error:
          "Impossible de trouver les coordonnées gps de ce pdi, veuillez vérifier l'adresse associée",
      };
    case pdiConstants.UPDATE_INFO_REQUEST:
      console.log(state, action);
      if (_.get(state, "fetchedPdi.general")) {
        state.fetchedPdi.general[action.editableField] =
          action.extra || action.value;
      }
      return {
        ...state,
      };
    case pdiConstants.UPDATE_INFO_SUCCESS:
      return {
        ...state,
      };
    case pdiConstants.UPDATE_INFO_FAILURE:
      return {
        error: "La mise à jour des infos a échoué",
      };
    case pdiConstants.CLEAR:
      return {};
    case pdiConstants.CLEAR_RANGEDATE:
      return {
        ...state,
        itemsRangeDate: null,
      };
    default:
      return state;
  }
}
