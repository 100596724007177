const synchroConstants = {
  GETALLROUND_REQUEST: "ROUND_GETALL_REQUEST",
  GETALLROUND_SUCCESS: "ROUND_GETALL_SUCCESS",
  GETALLROUND_FAILURE: "ROUND_GETALL_FAILURE",

  GETALLROUND_FULL_REQUEST: "ROUND_GETALL_FULL_REQUEST",
  GETALLROUND_FULL_SUCCESS: "ROUND_GETALL_FULL_SUCCESS",
  GETALLROUND_FULL_FAILURE: "ROUND_GETALL_FULL_FAILURE",

  GETALLDEVICES_REQUEST: "DEVICE_GETALL_REQUEST",
  GETALLDEVICES_SUCCESS: "DEVICE_GETALL_SUCCESS",
  GETALLDEVICES_FAILURE: "DEVICE_GETALL_FAILURE",

  GETALLLINKS_REQUEST: "LINKS_GETALL_REQUEST",
  GETALLLINKS_SUCCESS: "LINKS_GETALL_SUCCESS",
  GETALLLINKS_FAILURE: "LINKS_GETALL_FAILURE",

  COMMUNICATION_REQUEST: "COMMUNICATION_REQUEST",
  COMMUNICATION_SUCCESS: "COMMUNICATION_SUCCESS",
  COMMUNICATION_FAILURE: "COMMUNICATION_FAILURE",

  UPDATENAME_REQUEST: "UPDATENAME_REQUEST",
  UPDATENAME_SUCCESS: "UPDATENAME_SUCCESS",
  UPDATENAME_FAILURE: "UPDATENAME_FAILURE",

  DELETE_DEVICE_REQUEST: "DELETE_DEVICE_REQUEST",
  DELETE_DEVICE_SUCCESS: "DELETE_DEVICE_SUCCESS",
  DELETE_DEVICE_FAILURE: "DELETE_DEVICE_FAILURE",

  GETALLMESSAGES_REQUEST: "MESSAGES_GETALL_REQUEST",
  GETALLMESSAGES_SUCCESS: "MESSAGES_GETALL_SUCCESS",
  GETALLMESSAGES_FAILURE: "MESSAGES_GETALL_FAILURE",

  GETALLMARQUAGES_REQUEST: "MARQUAGES_GETALL_REQUEST",
  GETALLMARQUAGES_SUCCESS: "MARQUAGES_GETALL_SUCCESS",
  GETALLMARQUAGES_FAILURE: "MARQUAGES_GETALL_FAILURE",

  SYNCHROROUND_REQUEST: "ROUND_SYNCHRO_REQUEST",
  SYNCHROROUND_SUCCESS: "ROUND_SYNCHRO_SUCCESS",
  SYNCHROROUND_FAILURE: "ROUND_SYNCHRO_FAILURE",

  SYNCHRODEVICE_REQUEST: "DEVICE_SYNCHRO_REQUEST",
  SYNCHRODEVICE_SUCCESS: "DEVICE_SYNCHRO_SUCCESS",
  SYNCHRODEVICE_FAILURE: "DEVICE_SYNCHRO_FAILURE",

  GET_FULLAFFECT_REQUEST: "GET_FULLAFFECT_REQUEST",
  GET_FULLAFFECT_SUCCESS: "GET_FULLAFFECT_SUCCESS",
  GET_FULLAFFECT_FAILURE: "GET_FULLAFFECT_FAILURE",

  COM_ALL_REQUEST: "COM_ALL_REQUEST",
  COM_ALL_SUCCESS: "COM_ALL_SUCCESS",
  COM_ALL_FAILURE: "COM_ALL_FAILURE",

  GET_DEVICE_REQUEST: "GET_DEVICE_REQUEST",
  GET_DEVICE_SUCCESS: "GET_DEVICE_SUCCESS",
  GET_DEVICE_FAILURE: "GET_DEVICE_FAILURE",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  UPDATE_UNDROP_REQUEST: "UPDATE_UNDROP_REQUEST",
  UPDATE_UNDROP_SUCCESS: "UPDATE_UNDROP_SUCCESS",

  ADD_MESSAGES_REQUEST: "ADD_MESSAGES_REQUEST",
  ADD_MESSAGES_SUCCESS: "ADD_MESSAGES_SUCCESS",
  ADD_MESSAGES_FAILURE: "ADD_MESSAGES_FAILURE",

  ADD_MARQUAGES_REQUEST: "ADD_MARQUAGES_REQUEST",
  ADD_MARQUAGES_SUCCESS: "ADD_MARQUAGES_SUCCESS",
  ADD_MARQUAGES_FAILURE: "ADD_MARQUAGES_FAILURE",

  GET_DEVICES_ERROR_REQUEST: "DEVICES_ERROR_REQUEST",
  GET_DEVICES_ERROR_SUCCESS: "DEVICES_ERROR_SUCCESS",
  GET_DEVICES_ERROR_FAILURE: "DEVICES_ERROR_FAILURE",

  REMOVE_DEVICES_ERROR_REQUEST: "REMOVE_DEVICES_ERROR_REQUEST",
  REMOVE_DEVICES_ERROR_SUCCESS: "REMOVE_DEVICES_ERROR_SUCCESS",
  REMOVE_DEVICES_ERROR_FAILURE: "REMOVE_DEVICES_ERROR_FAILURE",

  DATA_ERROR: "DATA_ERROR",

  CLEAR: "SYNCHRO_CLEAR",
};

export default synchroConstants;
