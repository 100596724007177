import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import { connect } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { alertActions } from "../_actions";

import IndexCompteurHisto from "../_ressources/index_compteur_histogramme.jpg";
import IndexCompteurCourbe from "../_ressources/index_compteur_courbe.jpg";
import ConsoCompteurHisto from "../_ressources/consommation_compteur_histogramme.jpg";
import ConsoCompteurCourbe from "../_ressources/consommation_compteur_courbe.jpg";
import MultiConsoCompteurHisto from "../_ressources/consommation_multiple_compteurs_histogramme.jpg";
import MultiIndexCompteurHisto from "../_ressources/consommation_multiple_compteurs_courbe.jpg";
import MultiConsoCompteurCourbe from "../_ressources/index_multiple_compteurs_histogramme.jpg";
import MultiIndexCompteurCourbe from "../_ressources/index_multiple_compteurs_courbe.jpg";
import IndexRadioHisto from "../_ressources/index_radio_histogramme.jpg";
import IndexRadioCourbe from "../_ressources/index_radio_courbe.jpg";
import AlarmCompteur from "../_ressources/alarme_compteur.jpg";
import ConsoRendement from "../_ressources/graphiques_widgets_cc_boss.jpg";

const getPicture = (widgetName: any) => {
  switch (widgetName) {
    case "index_meter_histogram":
      return IndexCompteurHisto;
    case "index_meter_curve":
      return IndexCompteurCourbe;
    case "consumption_meter_histogram":
      return ConsoCompteurHisto;
    case "consumption_meter_curve":
      return ConsoCompteurCourbe;
    case "multiple_meter_consumption_histogram":
      return MultiConsoCompteurHisto;
    case "multiple_meter_consumption_curve":
      return MultiConsoCompteurCourbe;
    case "multiple_meter_index_histogram":
      return MultiIndexCompteurHisto;
    case "multiple_meter_index_curve":
      return MultiIndexCompteurCourbe;
    case "index_radio_histogram":
      return IndexRadioHisto;
    case "index_radio_curve":
      return IndexRadioCourbe;
    case "alarm_meter":
      return AlarmCompteur;
    case "meter_yield_curve":
      return ConsoRendement;
    default:
      return null;
  }
};

interface State {
  tooltipOpen: boolean;
  isOpen: boolean;
}

const LigneHorizontal = styled.div`
  border-bottom: 1px solid lightgrey;
  width: 90%;
  margin: 3% 5%;
`;

const LigneVertical = styled.div`
  border-left: 1px solid lightgrey;
  min-height: 90%;
  margin: 0 3%;
`;

/**
 * @class AddWidgetComponent
 * @extends {Component<Props, State>}
 */
class AddWidgetComponent extends Component<any, State> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof AddWidgetComponent
   */
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      tooltipOpen: false,
    };
  }

  /**
   * Gère l'ouverture et fermeture de la modal
   * de création
   *
   * @method toggle
   * @memberof AddWidgetComponent
   */
  toggle = () => {
    const { isOpen } = this.state;
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
    this.setState({
      isOpen: !isOpen,
    });
  };

  /**
   * Formate les informations des templates
   *
   * @method representItem
   * @memberof AddWidgetComponent
   */
  representItem = () => {
    const { listTemplates, locales, t } = this.props;
    if (listTemplates == null) return null;
    return listTemplates.map((it: any) => {
      return {
        id: it.id,
        name: it.name,
        datasource: t(`widget.type_source.${it.datasource.toLowerCase()}`),
        displayType: t(
          `widget.display_type.${it.content.dataSourceProperty.displayProperty.displayType.toLowerCase()}`
        ),
        displayValue: t(
          `widget.display_value.${it.content.dataSourceProperty.displayValue.toLowerCase()}`
        ),
      };
    });
  };

  /**
   * Gère les droits d'ajout d'un widget
   *
   * @method couldAddWidget
   * @memberof AddWidgetComponent
   */
  couldAddWidget = () => {
    const { user, locations, dashboards, users } = this.props;

    const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN"];
    if (roleList.includes(user.role.name)) {
      return true;
    }

    // si la personne est propriétaire du dashboard elle peut ajouter
    if (dashboards.dashboard.owner.id === user.id) {
      return true;
    }

    if (locations.fetchedLocation && locations.fetchedLocation.tournee) {
      // CETTE LIGNE ALEXIS
      return true;
    }

    if (
      users &&
      users.fetchedUser &&
      users.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find(
            (permission: any) => permission.name === "edit.dashboard"
          ) && el.locationCode === locations.fetchedLocation.code
      )
    ) {
      return true;
    }

    return false;
  };

  /**
   * Gère l'ouverture et fermeture de la tooltip
   * d'informations
   *
   * @method toggleButton
   * @memberof AddWidgetComponent
   */
  toggleButton = () => {
    const { tooltipOpen } = this.state;
    this.setState({ tooltipOpen: !tooltipOpen });
  };

  handleClick = (widget: any) => {
    const { listTemplates, addWidget } = this.props;
    const filtered = listTemplates.filter((it: any) => it.id === widget.id);
    if (filtered.length === 1) {
      addWidget(filtered[0]);
    }
    this.toggle();
  };

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof AddWidgetComponent
   */
  render() {
    const { addWidget, buttonLabel, size, disabled, t } = this.props;
    const { isOpen, tooltipOpen } = this.state;
    const data = _.chunk(this.representItem(), 2);
    return (
      <div>
        {this.couldAddWidget && (
          <div>
            <div id="buttonAddWidget">
              <Button onClick={this.toggle} disabled={disabled}>
                {buttonLabel}
              </Button>
              {disabled && (
                <UncontrolledTooltip
                  placement="left"
                  isOpen={tooltipOpen}
                  target="buttonAddWidget"
                  toggle={this.toggleButton}
                >
                  {t("WidgetMaxNumberAchieved")}
                </UncontrolledTooltip>
              )}
            </div>
            <Modal
              isOpen={isOpen}
              toggle={this.toggle}
              size={`${size}`}
              className="modal-addwidget-container"
            >
              <ModalHeader toggle={this.toggle}>
                {t("add_widget.button.add_widget")}
              </ModalHeader>
              <ModalBody>
                <div className="modal-add-widget">
                  {data.map((element: any, index: number) => {
                    return (
                      <>
                        <div className="content flex-box">
                          {element && element[0] && (
                            <div
                              className="widget-card"
                              onClick={() => this.handleClick(element[0])}
                            >
                              <img src={getPicture(element[0].name)} />

                              <div className="card-info">
                                <h5>
                                  {t(`widget.graph_type.${element[0].name}`)}
                                </h5>
                                <span>
                                  {t("all.text.type")}: {element[0].displayType}
                                </span>
                                <span>
                                  {t("add_widget.text.reading_value")}:{" "}
                                  {element[0].displayValue}
                                </span>
                                <span>
                                  {t("add_widget.text.source")}:{" "}
                                  {element[0].datasource}
                                </span>
                              </div>
                            </div>
                          )}
                          <LigneVertical />
                          {element && element[1] && (
                            <div
                              className="widget-card"
                              onClick={() => this.handleClick(element[1])}
                            >
                              <img src={getPicture(element[1].name)} />

                              <div className="card-info">
                                <h5>
                                  {t(`widget.graph_type.${element[1].name}`)}
                                </h5>
                                <span>
                                  {t("all.text.type")}: {element[1].displayType}
                                </span>
                                <span>
                                  {t("add_widget.text.reading_value")}:{" "}
                                  {element[1].displayValue}
                                </span>
                                <span>
                                  {t("add_widget.text.source")}:{" "}
                                  {element[1].datasource}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        {index !== 5 && <LigneHorizontal />}
                      </>
                    );
                  })}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={this.toggle}>
                  {t("all.button.cancel")}
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, dashboards, alert, users } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    dashboards,
    users,
  };
}

const connectedAddWidget = connect(mapStateToProps)(AddWidgetComponent);
const tr = withTranslation()(connectedAddWidget);
export default tr;
