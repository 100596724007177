import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

type Props = {
  customText?: string;
} & WithTranslation;

class NoFilled extends PureComponent<Props> {
  static propTypes: any;
  render() {
    const { t, customText } = this.props;
    return (
      <div className={"infoDisplay noFilled"}>
        {customText ? customText : t("all.text.not_specified")}
      </div>
    );
  }
}

NoFilled.propTypes = {};

const tr = withTranslation()(NoFilled);
export default tr;
