import locationConstants from "../_constants/location.constants";
import locationService from "../_services/location.service";
import alertActions from "./alert.actions";
import tourneeActions from "./tournee.actions";

/**
 * Récupère tous les sites
 *
 * @method getAll
 * @returns {Object} Les sites
 */
function getAll(root: string) {
  function request() {
    return { type: locationConstants.GETALL_REQUEST };
  }
  function success(locations: Object) {
    return { type: locationConstants.GETALL_SUCCESS, locations };
  }
  function failure(error: Object) {
    return { type: locationConstants.GETALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());

    locationService
      .getAll(root)
      .then((locations: Object) => dispatch(success(locations)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère tous les sites à partir d'un site racine
 *
 * @method getAll
 * @returns {Object} Les sites
 */
function getAllFromSeed(id: number) {
  function request() {
    return { type: locationConstants.GETALLFROMSEED_REQUEST };
  }
  function success(locations: Object) {
    return { type: locationConstants.GETALLFROMSEED_SUCCESS, locations };
  }
  function failure(error: Object) {
    return { type: locationConstants.GETALLFROMSEED_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());

    locationService
      .getAllFromSeed(id)
      .then((locations: Object) => dispatch(success(locations)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère le site dont l'id est passé en paramètre
 *
 * @method get
 * @param {number} id Id du site
 * @returns {Object} Le site
 */
function get(id: number) {
  function request() {
    return { type: locationConstants.GET_REQUEST };
  }
  function success(fetchedLocation: Object) {
    return { type: locationConstants.GET_SUCCESS, fetchedLocation };
  }
  function failure(error: Object) {
    return { type: locationConstants.GET_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    locationService
      .get(id)
      .then((fetchedLocation: Object) => dispatch(success(fetchedLocation)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère les infos du site dont l'id est passé
 * en paramètre
 *
 * @method getInfos
 * @param params Id du site
 * @returns {Object} Les infos du site
 */
function getInfos(params: number, round?: boolean) {
  function request() {
    return { type: locationConstants.GETINFO_REQUEST };
  }
  function success(fetchedLocation: Object) {
    return { type: locationConstants.GETINFO_SUCCESS, fetchedLocation };
  }
  function failure(error: Object) {
    return { type: locationConstants.GETINFO_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    locationService
      .getInfos(params)
      .then((fetchedLocation: any) => {
        if (round) {
          dispatch(tourneeActions.getTournee(fetchedLocation.code));
        }
        dispatch(success(fetchedLocation));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 * Enregistre le site passé en paramètre
 *
 * @method save
 * @param {any} location Site
 * @returns {Object} L'état de la sauvegarde
 */
function save(location: any) {
  function request() {
    return { type: locationConstants.SAVE_REQUEST, location };
  }
  function success(savedlocation: Object) {
    return { type: locationConstants.SAVE_SUCCESS, savedlocation };
  }
  function failure(error: Object) {
    return { type: locationConstants.SAVE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    locationService
      .save(location)
      .then((savedlocation: Object) => {
        dispatch(success(savedlocation));
        dispatch(alertActions.success("Opération réussie"));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 * Met à jour le site passé en paramètre
 *
 * @method update
 * @param {any} location Site
 * @returns {Object} L'état de la mise à jour
 */
function update(location: any) {
  function request() {
    return { type: locationConstants.UPDATE_REQUEST };
  }
  function success(updatedLocation: Object) {
    return { type: locationConstants.UPDATE_SUCCESS, updatedLocation };
  }
  function failure(error: Object) {
    return { type: locationConstants.UPDATE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    locationService
      .update(location)
      .then((updatedlocation: Object) => {
        dispatch(success(updatedlocation));
        dispatch(alertActions.success("Opération réussie"));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 * Supprime le site dont l'id est passé en paramètre
 *
 * @method toDelete
 * @param {number} id Id du site
 * @returns {Object} L'état de la suppression
 */
function toDelete(id: number) {
  function request() {
    return { type: locationConstants.DELETE_REQUEST };
  }
  function success(operation: Object) {
    return { type: locationConstants.DELETE_SUCCESS, operation };
  }
  function failure(error: Object) {
    return { type: locationConstants.DELETE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    locationService.toDelete(id).then(
      (operation: Object) => dispatch(success(operation)),
      (error: Object) => dispatch(failure(error))
    );
  };
}

/**
 * Récupère les contacts du site courant
 *
 * @method getContacts
 * @returns {Object} Les contacts
 */
function getContacts() {
  function request() {
    return { type: locationConstants.GETCONTACT_REQUEST };
  }
  function success(contacts: Object) {
    return { type: locationConstants.GETCONTACT_SUCCESS, contacts };
  }
  function failure(error: Object) {
    return { type: locationConstants.GETCONTACT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    locationService
      .getContacts()
      .then((contacts: Object) => dispatch(success(contacts)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 * Vide le state des sites
 *
 * @method clear
 */
function clear() {
  return {
    type: locationConstants.CLEAR,
  };
}

const locationActions = {
  getAll,
  getAllFromSeed,
  get,
  getInfos,
  update,
  save,
  toDelete,
  getContacts,
  clear,
};

export default locationActions;
