import React, { Component } from "react";
import styled from "styled-components";
import SvgDesaffectation from "../../../SvgComponents/desaffectationNoir";
import SvgAffectation from "../../../SvgComponents/affectationNoir";
import SvgCroixNoir from "../../../SvgComponents/croixNoir";
interface Props {
  title: string;
  desaffecteClick?: Function;
  extra?: Boolean;
}

interface State {
  isAffect: Boolean;
}

const Container = styled.div`
  display: inline-flex;
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 8px;
  width: 30%;
  background-color: "white";
  margin-right: 5px;
`;
//?  padding-right: ${(props) => (props.extra ? '10px' : '0px')};

const CrossButton = styled.button`
  padding-left: 4px;
  height: auto;
  margin-left: auto;
  border-radius: 5px;
  background-color: transparent;
  border: none;
`;

export default class DragLabelWaiting extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isAffect: true,
    };
  }

  render() {
    const { title, desaffecteClick, extra } = this.props;

    return (
      <Container id={`drag-label-container-A`} extra={extra}>
        <div
          className={`title-drag-A`}
          style={{ paddingRight: extra ? "10px" : "0px" }}
        >
          <span style={{ color: "black" }}>{title}</span>
        </div>
        {!extra && (
          <CrossButton onClick={() => desaffecteClick(title)}>
            <SvgCroixNoir height="1.5em" />
          </CrossButton>
        )}
      </Container>
    );
  }
}
