import importConstants from "../_constants/import.constants";

export default function imports(state: any = {}, action: any) {
  switch (action.type) {
    case importConstants.IMPORT_REQUEST:
      return {
        loading: true,
      };
    case importConstants.IMPORT_SUCCESS:
      return {
        ...state,
      };
    case importConstants.IMPORT_FAILURE:
      if (action.error.errorMessage) {
        return {
          ...state,
          errorInfo: action.error,
          loading: false,
        };
      } else {
        return {
          ...state,
          error: action.error,
          loading: false,
        };
      }
    case importConstants.IMPORT_REWORKED_REQUEST:
      return {
        loading: true,
      };
    case importConstants.IMPORT_REWORKED_SUCCESS:
      return {
        silex: action.data,
      };
    case importConstants.IMPORT_REWORKED_FAILURE:
      return {
        error: action.error,
      };
    case importConstants.IMPORT_STATE_REQUEST:
      return { ...state };
    case importConstants.IMPORT_STATE_SUCCESS:
      return {
        ...state,
        importState: action.data,
      };
    case importConstants.IMPORT_STATE_FAILURE:
      return {
        ...state,
        importError: action.error,
      };
    case importConstants.ANALYSE_REQUEST:
      return {
        loading: true,
      };
    case importConstants.ANALYSE_SUCCESS:
      console.log(action);
      if (state.canceled) {
        return {};
      } else {
        return {
          ...state,
          fileColumnName: action.data.first,
          generalSettings: action.data.second,
          loading: false,
        };
      }
    case importConstants.ANALYSE_FAILURE:
      if (state.canceled) {
        return {};
      } else {
        return {
          ...state,
          error: action.error.message,
          loading: false,
        };
      }
    case importConstants.IMPORT_SILEX_REQUEST:
      return {
        loading: true,
      };
    case importConstants.IMPORT_SILEX_SUCCESS:
      return {
        silex: action.data,
      };
    case importConstants.IMPORT_SILEX_FAILURE:
      return {
        error: action.error,
      };
    case importConstants.IMPORT_SILEX_ERROR_SUCCESS:
      return {
        ...state,
        errorSilex: action.data,
      };
    case importConstants.IMPORT_SILEX_ERROR_FAILURE:
      return {
        ...state,
        errorInfo: action.error,
      };
    case importConstants.IMPORT_LORA_REQUEST:
      return {
        loading: true,
      };
    case importConstants.IMPORT_LORA_SUCCESS:
      return {
        loraLinks: action.data,
      };
    case importConstants.IMPORT_LORA_FAILURE:
      return {
        error: action.error,
      };
    case importConstants.IMPORT_SILEX_CHANGEST3_REQUEST:
      return {
        loading: true,
      };
    case importConstants.IMPORT_SILEX_CHANGEST3_SUCCESS:
      return {
        silex: action.data,
      };
    case importConstants.IMPORT_SILEX_CHANGEST3_FAILURE:
      return {
        error: action.error,
      };
    case importConstants.IMPORT_SILEX_CHANGEST7_REQUEST:
    case importConstants.IMPORT_SILEX_CHANGEST5T6_REQUEST:
      return {
        loading: true,
      };
    case importConstants.IMPORT_SILEX_CHANGEST7_SUCCESS:
    case importConstants.IMPORT_SILEX_CHANGEST5T6_SUCCESS:
      return {
        silex: action.data,
      };
    case importConstants.IMPORT_SILEX_CHANGEST7_FAILURE:
    case importConstants.IMPORT_SILEX_CHANGEST5T6_FAILURE:
      return {
        error: action.error,
      };
    case importConstants.IMPORT_RESTORE_REQUEST:
      return state;
    case importConstants.IMPORT_RESTORE_SUCCESS:
      if (action.restore.first) {
        state.imports = action.restore.first;
        state.file = action.restore.first.filename;
      }
      if (action.restore.second) {
        state.tests = action.restore.second;
      }
      if (action.restore.third) {
        state.confirm = action.restore.third;
      }
      return {
        ...state,
      };
    case importConstants.IMPORT_RESTORE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case importConstants.IMPORT_CONFIRM_REQUEST:
      state.confirm = { loading: true };
      return {
        ...state,
      };
    case importConstants.IMPORT_CONFIRM_SUCCESS:
      return {
        ...state,
      };
    case importConstants.IMPORT_CONFIRM_FAILURE:
      state.confirm = { error: action.error };
      return {
        ...state,
      };
    case importConstants.IMPORT_RUNNING_REQUEST:
      return {
        ...state,
      };
    case importConstants.IMPORT_RUNNING_SUCCESS:
      state.running = action.data.processing && !state.clear;
      state.job = action.data;
      state.hasError = action.data.hasError;
      if (!state.file) {
        state.file = action.data && action.data.filename;
        state.operatingUser = action.data && action.data.operatingUser;
      }
      if (!state.running) {
        state.loading = false;
        switch (state.job.step) {
          case 1:
            state.imports = action.data.result;
            break;
          case 2:
            state.tests = action.data.result;
            break;
          case 3:
            state.confirm = action.data.result;
            state.confirmResult = action.data.resultDetail;
            break;
        }
      } else {
        state.loading = true;
        switch (state.job.step) {
          case 1:
            state.imports = state.imports || {};
            state.imports.loading = true;
            break;
          case 2:
            state.tests = state.tests || {};
            state.tests.loading = true;
            break;
          case 3:
            state.confirm = state.confirm || {};
            state.confirm.loading = true;
            break;
        }
      }
      return {
        ...state,
      };
    case importConstants.IMPORT_RUNNING_FAILURE:
      state.running = false;
      return {
        ...state,
      };
    case importConstants.IMPORT_TEST_REQUEST:
      state.tests = { loading: true };
      return {
        ...state,
      };
    case importConstants.IMPORT_TEST_SUCCESS:
      return {
        ...state,
      };
    case importConstants.IMPORT_TEST_FAILURE:
      state.tests = { error: action.error };
      return {
        ...state,
      };
    case importConstants.REPORT_DOWNLOAD_REQUEST:
      return {
        ...state,
        downloadLoading: true,
        reportError: null,
        importProcessing: true,
      };
    case importConstants.REPORT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        downloadLoading: false,
        importProcessing: false,
      };

    case importConstants.REPORT_DOWNLOAD_FAILURE:
      return {
        ...state,
        downloadLoading: false,
        reportError: action.error,
        importProcessing: false,
      };

    case importConstants.LAUNCH_IMPORT_FROM_FACTURATION_REQUEST:
      return {
        ...state,
        loading: true,
        importFromFactu: undefined,
      };
    case importConstants.LAUNCH_IMPORT_FROM_FACTURATION_SUCCESS:
      return {
        ...state,
        importFromFactu: action.data,
        loading: false,
      };
    case importConstants.LAUNCH_IMPORT_FROM_FACTURATION_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case importConstants.CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case importConstants.CLEAR:
      return {
        clear: true,
      };
    case importConstants.CANCEL_CONFIG_IMPORT_REQUEST:
      if (state.fileColumnName || state.error) {
        return {};
      } else {
        return {
          loading: false,
          canceled: true,
        };
      }
    default:
      return {
        ...state,
      };
  }
}
