import _ from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { TFunction, withTranslation } from "react-i18next";
import { Button, Col, Row, Spinner } from "reactstrap";

import { recursiveTranslate } from "../_helpers";
import locale from "../_shared/Locale.json";
import listOperator from "../_shared/OperatorList";
import WarningBand from "../Bands/Warning";
import { QueryBuilder } from "../QueryBuilder";
import RemoteTable from "../RemoteTable/RemoteTable";
import CompteurVert from "../SvgComponents/CompteurVert";
import ListTools from "../List/ListTools";
import { Rule } from "../_interfaces/filter";
import ErrorBand from "../Bands/Error";

const MeterSelectionComponent: React.FC<{
  displayingColumns: any;
  displayNoMeterSelected?: boolean;
  existingMeters?: any;
  keyStorage: string;
  idContext: string;
  listMeter: any;
  locationId: number;
  pdis: any;
  resetPage?: boolean;
  selectListMeter: any;
  setListMeter: (newListMeter: any) => void;
  loading: boolean;
  receiveData: any;
  t: TFunction;
  setRuleList?: Dispatch<SetStateAction<Array<Rule>>>;
  disabled?: boolean;
}> = ({
  displayingColumns,
  displayNoMeterSelected = false,
  existingMeters,
  keyStorage,
  idContext,
  listMeter,
  locationId,
  pdis,
  resetPage,
  selectListMeter,
  setListMeter,
  loading,
  receiveData,
  t,
  setRuleList,
  disabled,
}) => {
  const displayListSelectedColumns = displayingColumns
    .filter((el: any) => el.dataField.includes("meter.serial"))
    .map((el: any) => ({
      ...el,
      formatter: ListTools.formatSerialWithAction,
    }));
  console.log(displayListSelectedColumns);
  const rowStyle = (row: any) => {
    const rowId = _.get(row, "meter.id");
    const isSelect = rowId in listMeter;
    const linked = rowId in (existingMeters || {});
    if (isSelect && linked) {
      return { backgroundColor: "#b9cfe4" };
    }
    if (isSelect) {
      return { backgroundColor: "#bddbd1" };
    }
    if (linked) {
      return { backgroundColor: "#f4c5bb" };
    }
    return {};
  };

  const clickOnRow = (e: Object, row: any) => {
    if (!disabled) {
      const copyListMeter = _.cloneDeep(listMeter);
      const selectedMeterId = _.parseInt(_.get(row, "meter.id"));
      if (selectedMeterId in copyListMeter) {
        delete copyListMeter[selectedMeterId];
      } else {
        copyListMeter[selectedMeterId] = row.meter.serial;
      }
      setListMeter(copyListMeter);
    }
  };

  const listAssociated = _.uniqBy(
    _.concat(
      _.entries(existingMeters).map((el) => ({
        "meter.id": el[0],
        "meter.serial": el[1],
      })),
      _.entries(listMeter).map((el) => ({
        "meter.id": el[0],
        "meter.serial": el[1],
      }))
    ),
    "meter.id"
  ).map((el) => ({
    ...el,
    isSelect: _.get(el, "meter.id") in listMeter,
    linked: _.get(el, "meter.id") in (existingMeters || {}),
  }));

  const deselectListMeter = () => {
    setListMeter({});
  };

  const sourceColumns = recursiveTranslate(
    "fr",
    "pdi",
    locale.fr.pdi,
    locale
  ).map((champ: any) => {
    const regex = new RegExp("date");
    const elem: any = {
      dataField: champ.path,
      text: t(`columns.${champ.path}`),
      sort: true,
      classes:
        champ.path === "address.concatAdress"
          ? ""
          : `crystalList-column ${
              champ.path === "clpInformations" ||
              champ.path === "mtrInformations"
                ? "pre-space"
                : ""
            }`,
      filter: textFilter({
        placeholder: t(`columns.${champ.path}`),
        // defaultValue: _.get(this.state, `tableOptions.filters['${champ.path}'].filterVal`),
        delay: 500,
      }),
    };
    if (regex.test(champ.path)) {
      elem.sortFunc = ListTools.sortTableDates;
    }
    return elem;
  });
  // //
  const listFields = sourceColumns
    .map((col: any) => {
      const typeData = ListTools.findSpecificType(col.dataField);

      return {
        label: t(`columns.${col.dataField}`),
        value: col.dataField,
        type: typeData,
      };
    })
    .sort((a: any, b: any) => {
      if (a.label < b.label) return -1;
      if (a.label === b.label) return 0;
      return 1;
    });

  return (
    <>
      <div className="filter-container" style={{ marginTop: "10px" }}>
        <QueryBuilder
          sendListFiltered={receiveData}
          listData={pdis.items}
          listOperator={listOperator}
          listFilters={listFields}
          idContext={idContext}
          save={false}
          idSite={locationId}
          setRuleList={setRuleList}
        />
      </div>
      <div style={{ marginTop: "10px" }}>
        <Row className="virtual-meter-editor">
          <Col md="9">
            <div className="crystalList-container">
              <div className="table-info-container">
                <div style={{ display: "flex" }}>
                  <h2>
                    <span>
                      <CompteurVert
                        height="1em"
                        width="1em"
                        stroke="#31c6b3"
                        fill="white"
                        strokeWidth="1"
                      />
                    </span>
                    {pdis.count}{" "}
                    {t("all.meter.available_meter", { count: pdis.count })}
                  </h2>
                  <Button
                    className="button-all-associate"
                    onClick={selectListMeter}
                    style={{ marginBottom: "10px" }}
                    disabled={pdis.count === 0 || loading || disabled}
                  >
                    {`${t("all.text.select_all")} (${pdis.count})`}
                  </Button>
                  {loading && (
                    <Spinner
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{
                        alignSelf: "center",
                        marginBottom: "10px",
                        marginLeft: "8px",
                      }}
                    />
                  )}
                </div>

                <br />
                <RemoteTable
                  resetPage={resetPage}
                  keyStorage={keyStorage}
                  displayingColumns={displayingColumns}
                  rowStyle={rowStyle}
                  clickOnRow={clickOnRow}
                />
              </div>
            </div>
          </Col>
          <Col md="3">
            <div className="table-info-container">
              <h2>
                <span>
                  <CompteurVert
                    height="1em"
                    width="1em"
                    stroke="#31c6b3"
                    fill="white"
                    strokeWidth="1"
                  />
                </span>
                {_.size(listMeter)}{" "}
                {t(
                  `all.meter.selected_meter${
                    _.size(listMeter) > 1 ? "_plural" : ""
                  }`,
                  { count: _.size(listMeter) }
                )}
              </h2>
              {_.size(listMeter) > 0 && (
                <Button
                  className="button-all-associate"
                  onClick={deselectListMeter}
                  style={{ marginBottom: "10px" }}
                  disabled={pdis.count === 0 || disabled}
                >
                  {t("all.text.deselect_all")}
                </Button>
              )}
              <br />
              <div className="existing-meter-list">
                {displayNoMeterSelected && _.size(listMeter) === 0 && (
                  <WarningBand
                    message={t("mask.text.select_at_least_once_meter")}
                  />
                )}
                {locationId === 0 && _.size(listMeter) > 9999 && (
                  <ErrorBand
                    message={t(
                      "tournee_creation.input_error.tournee_meter_list_size_over_limit"
                    )}
                  />
                )}
                {_.size(listAssociated) > 0 && (
                  <BootstrapTable
                    keyField="id"
                    key="mtrassociated"
                    rowClasses="clickable"
                    data={listAssociated}
                    bootstrap4
                    bordered={false}
                    columns={displayListSelectedColumns}
                    rowEvents={{ onClick: clickOnRow }}
                    hover
                    filter={filterFactory()}
                    headerClasses="crystalList-column"
                    pagination={paginationFactory()}
                    rowStyle={rowStyle}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withTranslation()(MeterSelectionComponent);
