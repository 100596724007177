import React, { Component } from "react";
import DragLabelAssociate from "./DragDrop/TaskComponent/DragLabelAssociate";
import { Col, Row } from "reactstrap";
import SvgLoupeMoins from "../SvgComponents/loupeMoins";
import _ from "lodash";
import { connect } from "react-redux";

export class TableRow extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      multiDisplay: false,
    };
  }

  formatLabelList(list: any) {
    const newCopy = _.cloneDeep(_.uniqWith(list, _.isEqual));
    const { synchro } = this.props;
    const length = newCopy.length - 2;
    const littleLength = newCopy.length - 1;
    const newList = [];
    for (let i = 0; i < 2; i++) {
      if (newCopy[i]) {
        newList.push(newCopy[i]);
      }
    }

    const uniqLabel = [newCopy[0]];

    let extraList = null;
    switch (length) {
      case -2:
        break;
      case -1:
        break;
      case 0:
        break;
      case 1:
        extraList = "+" + length + "autre";
        break;
      default:
        extraList = "+" + length + "autres";
    }

    let extraLittleList = null;
    switch (length) {
      case -2:
        break;
      case -1:
        break;
      case 0:
        break;
      case 1:
        extraLittleList = "+" + littleLength + "autre";
        break;
      default:
        extraLittleList = "+" + littleLength + " autres";
    }

    const associateLabel = {
      labelList: _.cloneDeep(_.uniqWith(newList, _.isEqual)).map((el) =>
        _.get(synchro, `tasks[${el}].content`)
      ),
      extraList: extraList,
      uniqLabel: uniqLabel,
      extraLittleList: extraLittleList,
      default: newCopy.length + " Tournees",
    };
    return associateLabel;
  }
  render() {
    const { multiDisplay } = this.state;
    const { row, synchro } = this.props;
    const labels = this.formatLabelList(_.cloneDeep(row.affectList));
    return (
      <div style={{ marginLeft: "20px" }}>
        {!multiDisplay && (
          <div style={{ display: "flex" }}>
            <Row style={{ marginLeft: "-25px" }}>
              {labels &&
                labels.labelList.map((el: any) => (
                  <Col
                    key={el}
                    xs="6"
                    sm="3"
                    style={{
                      padding: "0",
                      maxWidth: "fit-content",
                      maxHeight: "fit-content",
                    }}
                  >
                    <div style={{ marginRight: "2px" }}>
                      <DragLabelAssociate key={el} title={el} extra={true} />
                    </div>
                  </Col>
                ))}
              {labels && labels.extraList && (
                <Col
                  xs="6"
                  sm="3"
                  style={{
                    padding: "0",
                    maxWidth: "fit-content",
                    maxHeight: "fit-content",
                  }}
                >
                  <div onClick={() => this.setState({ multiDisplay: true })}>
                    <DragLabelAssociate
                      key={labels.extraList}
                      title={labels.extraList}
                      extra={true}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </div>
        )}
        {multiDisplay && (
          <div
            className="drag-associate-label"
            style={{ width: "fit-content" }}
          >
            <Row>
              {row.affectList.map((el: any) => (
                <Col
                  xs="6"
                  sm="3"
                  style={{
                    padding: "0",
                    maxWidth: "max-content",
                    maxHeight: "fit-content",
                  }}
                >
                  <div
                    style={{
                      marginRight: "2px",
                      width: "fit-content",
                      color: "#2c303b",
                    }}
                  >
                    <DragLabelAssociate
                      key={el}
                      title={_.get(synchro, `tasks[${el}].content`)}
                      extra={true}
                    />
                  </div>
                </Col>
              ))}
              <Col
                xs="6"
                sm="3"
                style={{
                  padding: "0",
                  minWidth: "max-content",
                  maxHeight: "fit-content",
                }}
              >
                <div
                  className="synchro-tab-reduc"
                  onClick={() => this.setState({ multiDisplay: false })}
                >
                  <SvgLoupeMoins height="2em" />
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { synchro } = state;
  return {
    synchro,
  };
}

export default connect(mapStateToProps)(TableRow);
