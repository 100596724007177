import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Row, UncontrolledAlert } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import _ from "lodash";
import { WithTranslation, withTranslation } from "react-i18next";
import { history, recursiveTranslate } from "../_helpers";
import listOperator from "../_shared/OperatorList";
import { QueryBuilder } from "../QueryBuilder";
import {
  alertActions,
  localeActions,
  locationActions,
  pdiActions,
} from "../_actions";
import CompteurVert from "../SvgComponents/CompteurVert";
import ListTools from "../List/ListTools";
import { locales, locations } from "../_interfaces/reducers";
import { VirtualMeterGeneral } from "../_entities/virtual";
import { User } from "../_entities/user";
import ModalConfirmUnlink from "./ModalConfirmUnlink";
import Error from "../Bands/Error";
import SuccessBand from "../Bands/Success";
import confirm from "../_components";
import userActions from "../_actions/user.actions";
import Loading from "../_animations/Loading";
import locale from "../_shared/Locale.json";
import RemoteTable from "../RemoteTable/RemoteTable";
import {
  getFiltersRequest,
  getLightFilters,
} from "../QueryBuilder/FilterLSManager";

type Props = {
  pdis: any;
  location: any;
  user: User;
  match: any;
  locales: locales;
  dispatch: Function;
  locations: locations;
  alert: any;
  linkMode: boolean;
  initializedVMeters: boolean;
  users: any;
} & WithTranslation;

interface State {
  pdiList: Array<any>;
  sourceColumns: Array<any>;
  availablePdis: Array<VirtualMeterGeneral>;
  initialized: boolean;
  saved: boolean;
  asked: boolean;
  options: any;
  mtrKey: number;
  isOpen: boolean;
  selectAll: Boolean;
  ruleList: any;
  displayingColumns: any;
  listMeter: any;
  initializedVMeters: any;
}

/**
 * Gère l'édition et création de compteur virtuel
 *
 * @class ManagePdi
 * @extends Component
 */
class ManagePdi extends Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    const { t } = props;
    if (!state.initialized) {
      let sourceColumns: Array<any> = [];

      let initialized: boolean = state.initialized;

      if (
        (props.pdis &&
          props.pdis.itemsStock &&
          props.pdis.itemsStock.length > 0) ||
        (props.pdis.items && props.pdis.items.length > 0)
      ) {
        const translatedKeys = recursiveTranslate(
          "fr",
          "pdi",
          locale.fr.pdi,
          props.locales.locale
        );
        sourceColumns = translatedKeys.map((champ: any) => {
          const regex = new RegExp("date");
          const elem: any = {
            dataField: champ.path,
            text: t(`columns.${champ.path}`),
            sort: true,
            classes:
              champ.path === "address.concatAdress" ? "" : "crystalList-column",
            filter: textFilter({
              placeholder: champ.value,
            }),
          };
          if (regex.test(champ.path)) {
            elem.sortFunc = ListTools.sortTableDates;
          }
          return elem;
        });
        initialized = true;
      }
      return {
        sourceColumns,
        name,
        initialized,
      };
    }
  }

  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
    const { t } = this.props;
    const sourceColumns: any[] = ListTools.getDefaultColumns(
      ListTools.typeOfList.Link
    ).map((champ: any) => {
      return {
        dataField: champ,
        text: t(`columns.${champ}`),
        sort: true,
        classes: champ.includes("address") ? "" : "crystalList-column",
        //        formatter: _.get(ListTools.formatField, champ),
        filter: textFilter({
          placeholder: _.get(locale, `fr.pdi.${champ}`),
          delay: 500,
        }),
      };
    });

    this.state = {
      pdiList: [],
      sourceColumns,
      displayingColumns: sourceColumns,
      listMeter: {},
      availablePdis: [],
      ruleList: [],
      initialized: false,
      initializedVMeters: false,
      saved: false,
      asked: false,
      options: {
        hideSizePerPage: true,
      },
      mtrKey: Math.floor(Math.random() * Math.floor(1024)),
      isOpen: false,
      selectAll: false,
    };

    this.rowStyle = this.rowStyle.bind(this);
    this.clickOnRow = this.clickOnRow.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  /**
   * Permet d'ouvrir/fermer la modal
   *
   * @method toggle
   * @param {boolean} result Résultat
   */
  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  /**
   * Utilise la méthode de fermeture pour la confirmation
   *
   * @method confirm
   */
  confirm = () => {
    const { dispatch, pdis, match } = this.props;
    const { pdiList, availablePdis, selectAll, listMeter } = this.state;
    const { locationId } = match.params;
    dispatch(alertActions.clear());
    console.log(pdis);
    const pdiToDissociate = selectAll ? availablePdis : pdiList;
    this.toggle();

    if (selectAll) {
      const keyStorage = `filter-${ListTools.typeOfList.Link}-${locationId}`;
      dispatch(
        pdiActions.unlinkAllPdisToLocation(
          locationId,
          getFiltersRequest(keyStorage),
          pdis.unlinkActions
        )
      );
    } else {
      dispatch(
        pdiActions.unlinkPdisToLocation(
          locationId,
          _.keys(listMeter),
          pdis.unlinkActions
        )
      );
    }
  };

  /**
   * Gère le filtrage des données
   *
   * @method receiveData
   * @param {any} dataFiltered
   */
  receiveData = (dataFiltered: any, ruleList: any) => {
    const { dispatch, match } = this.props;
    const { locationId } = match.params;
    const keyStorage = `filter-${ListTools.typeOfList.Link}-${locationId}`;
    if (!_.isEqual(this.state.ruleList, getLightFilters(keyStorage))) {
      this.setState({ ruleList: getLightFilters(keyStorage) });
    }
  };

  /**
   * Comportement à la validation du formulaire
   *
   * @method handleValidSubmit
   * @param {Object} event Evènement
   * @param {any} values Valeurs du formulaire
   */
  handleValidSubmit() {
    const { dispatch, match, linkMode } = this.props;
    const { listMeter } = this.state;
    const { locationId } = match.params;
    dispatch(alertActions.clear());

    console.log("ManagePdi::handleValidSubmit::200", listMeter);

    if (linkMode) {
      dispatch(pdiActions.linkPdisToLocation(locationId, _.keys(listMeter)));
    } else {
      dispatch(
        pdiActions.unlinkActionsPdisToLocation(locationId, _.keys(listMeter))
      );
    }

    this.setState({
      isOpen: true,
      selectAll: false,
    });
  }

  /**
   * Associer tous les compteurs présent dans le tableau filtré ou non
   *
   * @method handleValidSubmit
   * @param {Object} event Evènement
   * @param {any} values Valeurs du formulaire
   */
  handleValidSubmitAll = () => {
    const { availablePdis } = this.state;

    const {
      dispatch,
      linkMode,
      locations,
      pdis,
      match: {
        params: { locationId },
      },
    } = this.props;
    const { Fragment } = React;
    dispatch(alertActions.clear());
    const keyStorage = `filter-${ListTools.typeOfList.Link}-${locationId}`;
    //! const result = this.confirmSubmit(availablePdis);
    if (linkMode) {
      dispatch(
        pdiActions.linkAllPdisToLocation(
          locations.fetchedLocation.id,
          getFiltersRequest(keyStorage)
        )
      );
    } else {
      dispatch(
        pdiActions.unlinkActionsAllPdisToLocation(
          locations.fetchedLocation.id,
          getFiltersRequest(keyStorage)
        )
      );
    }

    this.setState({
      isOpen: true,
      selectAll: true,
    });
  };

  async confirmSubmit(listPdi: any) {
    const { linkMode, locations, dispatch, pdis } = this.props;
    const { listMeter } = this.state;
    if (linkMode) {
      const resultSubmit = await confirm({
        title: (
          <Fragment>
            <strong>Associer les PDIs</strong>
          </Fragment>
        ),
        message: `Voulez vous vraiment associer ces ${_.size(
          listMeter
        )} compteurs ?`,
        confirmText: "Associer",
        confirmColor: "primary",
      });
      dispatch(
        pdiActions.linkPdisToLocation(
          locations.fetchedLocation.id,
          _.keys(listMeter)
        )
      );
    } else {
      const resultSubmitUnlink = await confirm({
        title: (
          <Fragment>
            <strong>Dissocier les compteurs</strong>
          </Fragment>
        ),
        message: `Voulez vous vraiment dissocier ces ${_.size(
          listMeter
        )} compteurs ?`,
        confirmText: "Dissocier",
        confirmColor: "danger",
      });

      dispatch(
        pdiActions.unlinkPdisToLocation(
          locations.fetchedLocation.id,
          _.keys(listMeter),
          pdis.unlinkActions
        )
      );
    }
  }

  /**
   * Construit toute la partie haute du template
   *
   * @method getHeaderOfList
   * @returns {JSX} La partie du template
   */
  getHeaderOfList = () => {
    const { pdis, match, linkMode, t } = this.props;
    const { locationId } = match.params;
    const { sourceColumns } = this.state;
    const listFields = sourceColumns
      .map((col: any) => ({
        label: t(`columns.${col.dataField}`),
        value: col.dataField,
        type: ListTools.findSpecificType(col.dataField),
      }))
      .sort((a: any, b: any) => {
        if (a.label < b.label) return -1;
        if (a.label === b.label) return 0;
        return 1;
      });
    console.log();
    return (
      <Fragment>
        <div className="filter-container" style={{ marginTop: "10px" }}>
          <QueryBuilder
            sendListFiltered={this.receiveData}
            listData={pdis.items}
            listOperator={listOperator}
            listFilters={listFields}
            defaultConfig={this.getDefaultFilterByURL()}
            idContext={ListTools.typeOfList.Link}
            save={false}
            idSite={locationId}
          />
        </div>
      </Fragment>
    );
  };

  /**
   * Gère l'annulation de l'édition
   *
   * @method cancelEdition
   */
  cancelEdition = (e: any) => {
    history.goBack();
  };

  /**
   * @method componentDidUpdate
   * @param {Props} prevProps Props précédentes
   * @param {State} prevState State précédent
   * @param {any} snapshot Snapshot
   */
  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    const { match, pdis, linkMode, users, locations, user } = this.props;
    const { locationId } = match.params;
    if (linkMode) {
      if (pdis && pdis.movedPdi && pdis.movedPdi.length > 0) {
        const link = `/locations/${locationId}/pdi`;
        setTimeout(() => {
          history.push(link);
        }, 2000);
      }
    } else if (
      pdis &&
      pdis.unlinkActions &&
      pdis.unlinkActions.status &&
      pdis.unlinkActions.status == "OK"
    ) {
      const link = `/locations/${locationId}/pdi`;
      setTimeout(() => {
        history.push(link);
      }, 2000);
    }
    const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN"];
    if (
      users.fetchedUser &&
      locations.fetchedLocation &&
      !locations.fetchedLocation.tournee && // CETTE LIGNE ALEXIS
      !roleList.includes(user.role.name) &&
      !users.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find(
            (permission: any) => permission.name === "edit.pdi"
          ) && el.locationCode === locations.fetchedLocation.code
      )
    ) {
      history.push("/forbidden");
    }
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { dispatch, match, user, linkMode } = this.props;
    const { locationId } = match.params;

    dispatch(localeActions.load());
    dispatch(userActions.get(user.id));
    dispatch(locationActions.getInfos(locationId));
    // TODO changer pdiActions.getAll with getAllAscendant pour get tous les pdi en montant
    const keyStorage = `filter-${ListTools.typeOfList.Link}-${locationId}`;
    this.setState({
      ruleList: getLightFilters(keyStorage),
    });
    dispatch(
      pdiActions.getRemotePdi(
        locationId,
        getFiltersRequest(keyStorage),
        10,
        1,
        {
          field: null,
          way: null,
        },
        {},
        null,
        linkMode
      )
    );
  }

  /**
   * @method componentWillUnmount
   */
  componentWillUnmount() {
    const {
      dispatch,
      match: {
        params: { locationId },
      },
    } = this.props;
    dispatch(locationActions.clear());
    dispatch(pdiActions.clear());
    dispatch(alertActions.clear());
    const keyStorage = `filter-${ListTools.typeOfList.Link}-${locationId}`;
    localStorage.removeItem(keyStorage);
  }

  /**
   * Applique des filtres passés par URL
   *
   * @method getDefaultFilterByURL
   * @memberof AddVirtualMeter
   */
  getDefaultFilterByURL = () => {
    const { location } = this.props;

    const listeOfFilter = location.search.slice(1).split("&");

    let listDefaultFilter: Array<any> = [];
    listeOfFilter.forEach((filter: any) => {
      const filterUrl = Object.keys(ListTools.filterURL).find(
        (url) => url === filter
      );
      if (filterUrl !== undefined)
        listDefaultFilter = listDefaultFilter.concat(
          ListTools.filterURL[filter]
        );
    });

    listDefaultFilter.map((f, i) => {
      const copy = f;
      copy.num = i;
      return copy;
    });
    return listDefaultFilter;
  };

  /**
   * Formate correctement les colonnes, en effectuant la
   * traduction notamment
   *
   * @method formatColumns
   * @param {string} type Type
   * @param {boolean} recursive Récursif
   */
  formatColumns = (type: string, recursive: boolean) => {
    const { t } = this.props;
    const baseColumns = ListTools.getDefaultColumns(type);
    if (recursive) {
      return baseColumns.map((it) => {
        const test: any = it.split(".");
        if (test.length > 1) {
          return {
            dataField: it,
            text: t(`columns.${it}`),
            sort: true,
          };
        }
        return {
          dataField: it,
          text: t(`columns.${it}`),
          sort: true,
        };
      });
    }
    return baseColumns.map((champ) => ({
      dataField: champ,
      text: t(`columns.${champ}`),
      sort: true,
    }));
  };

  /**
   * Rend la liste des compteurs existants
   *
   * @method getExistingMetersList
   * @returns {JSX} Le tableau
   */
  getExistingPdisList = () => {
    const { pdiList, options, mtrKey } = this.state;
    const columns = this.formatColumns(ListTools.typeOfList.PdiLight, true);
    return (
      <Col md="12">
        <BootstrapTable
          keyField="id"
          key={mtrKey}
          data={pdiList}
          bootstrap4
          bordered={false}
          columns={columns}
          hover
          filter={filterFactory()}
          headerClasses="crystalList-column"
          pagination={paginationFactory(options)}
        />
      </Col>
    );
  };

  rowStyle(row) {
    const { listMeter } = this.state;
    const { linkMode } = this.props;
    const rowId = _.get(row, "id");
    const isSelected = rowId in listMeter;
    if (isSelected) {
      return { backgroundColor: linkMode ? "#bddbd1" : "#f4c5bb" };
    }
  }

  clickOnRow(e: Object, row: any) {
    const copyListMeter = _.cloneDeep(this.state.listMeter);
    const selectedMeterId = _.parseInt(_.get(row, "id"));
    if (selectedMeterId in copyListMeter) {
      delete copyListMeter[selectedMeterId];
    } else {
      copyListMeter[selectedMeterId] = _.get(row, "meter.serial");
    }
    this.setState({ listMeter: copyListMeter });
  }

  render() {
    const {
      pdis,
      alert,
      linkMode,
      locations,
      match: {
        params: { locationId },
      },
      match,
      t,
    } = this.props;
    const {
      availablePdis,
      isOpen,
      pdiList,
      selectAll,
      displayingColumns,
      listMeter,
    } = this.state;
    const displayListSelectedColumns = displayingColumns
      .filter((el) => el.dataField.includes("meter.serial"))
      .map((el) => ({
        ...el,
        formatter: ListTools.formatSerialWithAction,
      }));
    const listMeterWithAction = _.entries(listMeter)
      .map((el) => ({ id: el[0], "meter.serial": el[1] }))
      .map((el) => ({
        ...el,
        isSelected: linkMode,
        linked: linkMode,
      }));
    console.log("ManagePdi::render::526", listMeterWithAction);
    return (
      <>
        {(!_.get(pdis, "items") || !locations.fetchedLocation) &&
          !pdis.linkActionDone && <Loading />}
        <div className="col-md-12 ">
          {pdis && pdis.error && <Error message={pdis.error} />}
          {linkMode && pdis && pdis.movedPdi && pdis.movedPdi.length > 0 && (
            <SuccessBand
              message={`${pdis.movedPdi.length} ${t(
                "all.pdi.link_location_pdi"
              )}.`}
            />
          )}
          {!linkMode &&
            pdis &&
            pdis.unlinkActions &&
            pdis.unlinkActions.status &&
            pdis.unlinkActions.status === "OK" && (
              <SuccessBand
                message={`${selectAll ? pdis.count : _.size(listMeter)} ${t(
                  "all.pdi.dissociate_location_pdi"
                )}.`}
              />
            )}

          {_.get(pdis, "items") && locations.fetchedLocation && (
            <div>
              {this.getHeaderOfList()}
              <div style={{ marginTop: "10px" }}>
                <Row className="virtual-meter-editor">
                  <Col md="8">
                    <div className="crystalList-container">
                      <div className="table-info-container">
                        <h2>
                          <span>
                            <CompteurVert
                              height="1em"
                              width="1em"
                              stroke="#31c6b3"
                              fill="white"
                              strokeWidth="1"
                            />
                          </span>
                          {pdis.count}{" "}
                          {t("all.meter.available_meter", {
                            count: pdis.count,
                          })}
                        </h2>
                        <br />
                        {pdis.items && (
                          <RemoteTable
                            pdis={pdis.items}
                            displayingColumns={displayingColumns}
                            clickOnRow={this.clickOnRow}
                            rowStyle={(row) => this.rowStyle(row)}
                            keyStorage={`filter-${ListTools.typeOfList.Link}-${locationId}`}
                            ruleList={this.state.ruleList}
                            ascMode={linkMode}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="table-info-container">
                      <h2>
                        <span>
                          <CompteurVert
                            height="1em"
                            width="1em"
                            stroke="#31c6b3"
                            fill="white"
                            strokeWidth="1"
                          />
                        </span>
                        {linkMode
                          ? t("manage_pdi.text.nb_meter_to_link", {
                              number: _.size(listMeter),
                            })
                          : t("manage_pdi.text.nb_meter_to_unlink", {
                              number: _.size(listMeter),
                            })}
                      </h2>
                      <br />
                      <Button
                        onClick={this.handleValidSubmit}
                        style={{ marginBottom: "10px" }}
                        disabled={_.size(listMeter) === 0}
                      >
                        {linkMode ? t("all.text.link") : t("all.text.unlink")}
                      </Button>
                      <Button
                        className="button-all-associate"
                        onClick={this.handleValidSubmitAll}
                        style={{ marginBottom: "10px" }}
                      >
                        {`${
                          linkMode
                            ? t("all.text.link_all")
                            : t("all.text.dissociate_all")
                        } (${pdis.count})`}
                      </Button>
                      {_.size(listMeter) > 0 && (
                        <BootstrapTable
                          keyField="id"
                          key="mtrassociated"
                          rowClasses="clickable"
                          data={listMeterWithAction}
                          bootstrap4
                          bordered={false}
                          columns={displayListSelectedColumns}
                          rowEvents={{ onClick: this.clickOnRow }}
                          hover
                          filter={filterFactory()}
                          headerClasses="crystalList-column"
                          pagination={paginationFactory()}
                          rowStyle={this.rowStyle}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          <ModalConfirmUnlink
            onValid={this.confirm}
            toggle={this.toggle}
            isOpen={isOpen}
            actions={pdis && pdis.unlinkActions}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, pdis, locales, locations, alert, users } = state;
  const { user } = authentication;

  return {
    user,
    pdis,
    locales,
    locations,
    alert,
    users,
  };
}

const mapping: any = connect(mapStateToProps)(ManagePdi);

const connectedManagePdiMeter = withRouter(mapping);
const trans = withTranslation()(connectedManagePdiMeter);
export default trans;
