import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import _debounce from "lodash.debounce";
import { connect } from "react-redux";
import { locales, locations } from "../_interfaces/reducers";
import { localeActions, meterActions } from "../_actions";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button, Col, Row } from "reactstrap";
import {
  intersection as _intersection,
  keys as _keys,
  set as _set,
  get as _get,
} from "lodash";
import CompteurVert from "../SvgComponents/CompteurVert";
import translate from "../_helpers/locale-helpers";
import ErrorBand from "../Bands/Error";
import SuccessBand from "../Bands/Success";
import { WithTranslation, withTranslation } from "react-i18next";

type Props = {
  locales: locales;
  dispatch: any;
  locationId: number;
  match: any;
  locations: locations;
  location: any;
  history: any;
  pdis: any;
  meters: any;
  radios: any;
} & WithTranslation;

interface State {
  meter: any;
  serial: string;
}

/**
 * @class CreateMeter
 * @extends {Component}
 */
class CreateMeter extends Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      meter: {},
      serial: "",
    };
  }

  /**
   * Fait les vérifications des données du local
   * storage au montage du composant (suppression
   * si données d'un autre site, application sinon)
   *
   * @method componentDidMount
   */
  componentDidMount() {
    const { dispatch } = this.props;
    //TODO
    //dispatch(radioActions.getAll(1));
    //dispatch(meterActions.getManufacturers());
    dispatch(localeActions.load());
  }

  componentDidUpdate() {
    const { history, meters } = this.props;
    if (meters && meters.createdMeter && meters.createdMeter.serial) {
      //history.push('/stock');
    }
  }

  /**
   * Tronque les résultats s'ils dépassent 15 entrées
   * et retourne le check
   *
   * @method checkTotal
   * @param {any} data Résultats
   * @returns {boolean} Le dépassement ou non de 15 entrées
   */
  checkTotal = (data: any): boolean => {
    return true;
  };

  handleMeterChange = (event: any, values: any) => {
    const { meter } = this.state;
    meter[event.target.name] = event.target.value;
    this.setState({ meter });
  };

  send = (e: any, values: any, errors: any) => {
    const { serial } = this.state;
    const { dispatch, meters } = this.props;
    const { serialInfo } = meters;
    serialInfo.serial = serial;
    dispatch(meterActions.createMeterFromSerial(serialInfo));
  };

  searchMeterInfos = (event: any) => {
    const { serial } = this.state;
    const { dispatch } = this.props;
    event.preventDefault();
    dispatch(meterActions.findInfosWithSerial(serial));
  };

  /**
   * Construit le composant et mise en place
   * d'un système d'onglets pour les résultats
   * de la recherche
   *
   * @method render
   */
  render() {
    const { meters, radios, locales, t } = this.props;

    const locale = locales && locales.locale;

    return (
      <div className="col-12">
        {locale && (
          <div className="table-info-container">
            <h2>
              <span>
                <CompteurVert
                  height="1em"
                  width="1em"
                  stroke="#31c6b3"
                  fill="white"
                  strokeWidth="1.5"
                />
              </span>
              {t("create_meter.main_title.create_meter")}
            </h2>
            <AvForm onValidSubmit={this.searchMeterInfos}>
              <Row>
                <Col md="4">
                  <AvField
                    name="serial"
                    label={t("all.meter.meter_serial")}
                    required
                    validate={{
                      minLength: {
                        value: 5,
                        errorMessage: t(
                          "create_meter.field_validate.serial_least_5_char"
                        ),
                      },
                      maxLength: {
                        value: 20,
                        errorMessage: t(
                          "create_meter.field_validate.serial_max_20_char"
                        ),
                      },
                      pattern: {
                        value: "^[A-Za-z][0-9][0-9][A-Za-z][A-Za-z]",
                        errorMessage: t(
                          "create_meter.field_validate.serial_valid"
                        ),
                      },
                    }}
                    errorMessage={t("create_meter.field_error_msg.need_serial")}
                    onChange={(event: any) => {
                      this.setState({ serial: event.target.value });
                    }}
                  />
                  {meters && meters.error && (
                    <ErrorBand message={meters.error} />
                  )}
                </Col>
                <Col md="8" style={{ margin: "auto", marginBottom: "16px" }}>
                  <Button color="info" style={{ margin: "auto" }}>
                    {t("all.button.search")}
                  </Button>
                </Col>
              </Row>
            </AvForm>

            {meters && meters.serialInfo && (
              <div style={{ marginTop: 10 }}>
                <AvForm onValidSubmit={this.send}>
                  <p>
                    <span className="bold-text">
                      {t("all.meter.real_meter_diameter")} :
                    </span>{" "}
                    {meters.serialInfo.diameter}
                  </p>
                  <p>
                    <span className="bold-text">
                      {t("all.meter.wheels_number")} :
                    </span>{" "}
                    {meters.serialInfo.wheels}
                  </p>
                  <p>
                    <span className="bold-text">
                      {t("all.meter.pulse_weight")} :
                    </span>{" "}
                    {meters.serialInfo.impulsion}
                  </p>
                  <p>
                    <span className="bold-text">{t("all.text.unity")} :</span>{" "}
                    {meters.serialInfo.unit}
                  </p>
                  <p>
                    <span className="bold-text">
                      {t("all.read_meter.max_index")} :
                    </span>{" "}
                    {meters.serialInfo.indexMax}
                  </p>
                  <p>
                    <span className="bold-text">
                      {t("all.meter.meter_manufacturer")} :
                    </span>{" "}
                    {meters.serialInfo.constructeur}
                  </p>
                  <p>
                    <span className="bold-text">
                      {t("all.meter.meter_model")} :
                    </span>{" "}
                    {meters.serialInfo.model}
                  </p>
                  <Button color="primary">{t("all.button.register")}</Button>
                </AvForm>
              </div>
            )}
            {meters && meters.createdMeter && meters.createdMeter.serial && (
              <div style={{ marginTop: "20px" }}>
                <SuccessBand
                  message={t("create_meter.success_band.meter_created")}
                />
              </div>
            )}
            {radios && radios.items && (
              <BootstrapTable
                keyField="id"
                rowClasses="clickable"
                data={radios.items}
                bootstrap4
                bordered={false}
                columns={["manufacturer", "serial", "type"]}
                hover
                headerClasses="crystalList-column"
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, meters, locations, locales, radios, pdis } = state;
  const { user } = authentication;

  if (meters && meters.manufacturers)
    meters.manufacturers = meters.manufacturers.sort((a: any, b: any) =>
      a.name.localeCompare(b.name)
    );

  return {
    alert,
    user,
    meters,
    radios,
    pdis,
    locations,
    locales,
  };
}

const connectedCreateMeter = connect(mapStateToProps)(CreateMeter);
const tr = withTranslation()(connectedCreateMeter);
export default tr;
