import React, { Component, ComponentType } from "react";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import i18next from "i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SvgCroixNoir from "../../../SvgComponents/croixNoir";
import SvgTourneeWaitAffect from "../../../SvgComponents/TourneeWaitAffect";
import SvgTourneeWaitDeaffect from "../../../SvgComponents/TourneeWaitDeaffect";
import SvgTourneeLoad from "../../../SvgComponents/TourneeLoad";
import SvgPartielVert from "../../../SvgComponents/PartielVert";
import BestSwitch from "../../BestSwitch";
import { compose } from "redux";

class Label extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      hover: false,
      x: 0,
      y: 0,
      isPartiel: false,
      init: false,
    };
    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const copyState = _.cloneDeep(state);
    const { column, label, partiel } = props;
    if (partiel && partiel.length > 0 && !copyState.init) {
      let round = "";
      let device = "";
      if (label && label.firstId) {
        round = column && column.id === "column-1" ? label.firstId : label.id;
        device = column && column.id === "column-2" ? label.firstId : label.id;
      } else if (label) {
        round =
          column && column.id === "column-1"
            ? label.destination.droppableId
            : label.draggableId;
        device =
          column && column.id === "column-2"
            ? label.destination.droppableId
            : label.draggableId;
      }
      copyState.isPartiel = partiel.find(
        (el: any) => el.device === device && el.tournee === round
      );
      copyState.init = true;
    }
    return copyState;
  }

  handleEnter(e: any) {
    const { label } = this.props;

    let labelWidth: any = document.getElementsByClassName("label")[0];
    let taskWidth: any = document.getElementsByClassName("synchro-column")[0];
    let hoverWidth: any = document.getElementsByClassName("hover-container")[0];
    if (labelWidth && taskWidth && hoverWidth) {
      labelWidth = document
        .getElementsByClassName("label")[0]
        .getBoundingClientRect().width;
      taskWidth = document
        .getElementsByClassName("synchro-column")[0]
        .getBoundingClientRect().width;
      hoverWidth = document
        .getElementsByClassName("hover-container")[0]
        .getBoundingClientRect().width;
      this.setState({
        x: taskWidth - labelWidth - hoverWidth,
      });
    }

    this.setState({ hover: true });
  }

  handleLeave(e: any) {
    this.setState({ hover: false });
  }

  verifPartial = (newLabel: any) => {
    const { synchro, label } = this.props;
    const isLoaded =
      synchro.labels &&
      synchro.labels.loaded &&
      synchro.labels.loaded.find(
        (el: any) =>
          el.device === newLabel.device && el.tournee === newLabel.round
      );
    if (isLoaded && isLoaded.partialOption) {
      return true;
    }
    if (label.partial) {
      return true;
    }
    return false;
  };

  render() {
    const {
      label,
      cancelOne,
      loaded,
      switchToPartial,
      partiel,
      column,
      isAffect,
      withCode,
      synchro,
      isDevice,
      t,
    } = this.props;
    const { hover, x, y } = this.state;
    let newLabel = {
      round: "",
      device: "",
    };
    if (label && label.taskId) {
      newLabel = {
        round: isDevice ? label.taskId : label.destination.droppableId,
        device: !isDevice ? label.taskId : label.destination.droppableId,
      };
    } else if (label) {
      newLabel = {
        round: !isDevice ? label.firstId : label.id,
        device: isDevice ? label.firstId : label.id,
      };
    }

    const id = `switch-${newLabel.round}-${newLabel.device}`;

    return (
      <div
        className="label"
        style={{
          backgroundColor: loaded
            ? "#716AB4"
            : isAffect || !_.get(label, "destination.index")
            ? "#31c6b3"
            : "#dc3545",
        }}
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}
      >
        {label && (
          <>
            <div style={{ margin: "0 8px" }}>
              {loaded ? (
                <SvgTourneeLoad height="1.5em" fill="white" />
              ) : isAffect || !_.get(label, "destination.index") ? (
                <SvgTourneeWaitAffect height="1.5em" fill="white" />
              ) : (
                <SvgTourneeWaitDeaffect height="1.5em" fill="white" />
              )}
            </div>
            <div style={{ width: "100%", display: "flex" }}>
              <span style={{ color: "white", minWidth: "25ch" }}>
                {label.taskTitle || label.title}
              </span>
              {withCode && (
                <i style={{ textAlign: "right" }}>
                  <span style={{ color: "white" }}>
                    {label.taskId || label.id}
                  </span>
                </i>
              )}
            </div>
            <div
              style={{
                textAlign: "right",
                display: "flex",
                marginRight: "8px",
              }}
            >
              <div
                style={{
                  backgroundColor: loaded
                    ? "#716AB4"
                    : isAffect || !_.get(label, "destination.index")
                    ? "#31c6b3"
                    : "#dc3545",
                  cursor: "pointer",
                  display: "flex",
                }}
              >
                {this.verifPartial(newLabel) && (
                  <div style={{ marginRight: "8px" }}>
                    <SvgPartielVert height="1.5em" fill="#fff" />
                  </div>
                )}
                <div onClick={() => cancelOne(label.taskId || label.id)}>
                  <SvgCroixNoir height="1.5em" fill="white" />
                </div>
              </div>
            </div>
          </>
        )}
        {!isMobile &&
          label &&
          label.destination &&
          (isAffect || !_.get(label, "destination.index")) && (
            <div
              className="hover-container"
              style={{
                visibility: hover && partiel ? "visible" : "hidden",
                left: x,
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", left: x }}
                className="info-label"
              >
                <SvgPartielVert height="2em" fill="#31c6b3" />
                <span>{t("synchronisation.option.partial_sync")}</span>
                <div style={{ marginLeft: "15px", width: "30px", zIndex: 10 }}>
                  <BestSwitch
                    checked={label.partial}
                    handleChange={() => switchToPartial(label, column)}
                    onColor="#409F95"
                    offColor="#999999"
                    offHandleColor="#CCCCCC"
                    onHandleColor="#31C6B3"
                    id={id}
                  />
                </div>
              </div>
              <div className="pointe" style={{ zIndex: 1 }} />
            </div>
          )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { synchro } = state;
  return {
    synchro,
  };
}

const connectedTask = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(Label);
const tr = withTranslation()(connectedTask);
export default tr;
