import { filterConstants } from "../_constants";
import { action } from "../_interfaces/action";

export default function filters(state: any = {}, action: action) {
  switch (action.type) {
    case filterConstants.GET_SUCCESS:
      return {
        items: action.filters,
      };
    case filterConstants.GET_FAILURE:
      return {
        error: "Impossible de récupérer les filtres",
      };
    case filterConstants.SAVE_SUCCESS:
      return {
        filter: action.savedFilter,
        items: [...state.items, action.savedFilter],
      };
    case filterConstants.DELETE_SUCCESS:
      return {
        deletedFilter: action.deletedFilter,
        items: state.items.filter((el) => el.id !== action.deletedFilter.id),
      };
    case filterConstants.DELETE_FAILURE:
      return {
        error: "La suppression du filtre a échouée",
      };
    case filterConstants.UPDATE_SUCCESS:
      return {
        filter: action.updatedFilter,
        items: [
          state.items.filter((el) => el.id !== action.updatedFilter.id),
          action.updatedFilter,
        ],
      };
    case filterConstants.UPDATE_FAILURE:
      return {
        error: "Impossible de sauvegarder les informations du filtre",
      };
    case filterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
