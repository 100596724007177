const rndTemplateConstants = {
  CREATE_TEMPLATE_REQUEST: "CREATE_TEMPLATE_REQUEST",
  CREATE_TEMPLATE_SUCCESS: "CREATE_TEMPLATE_SUCCESS",
  CREATE_TEMPLATE_FAILURE: "CREATE_TEMPLATE_FAILURE",

  GET_ALL_TEMPLATE_REQUEST: "GET_ALL_TEMPLATE_REQUEST",
  GET_ALL_TEMPLATE_SUCCESS: "GET_ALL_TEMPLATE_SUCCESS",
  GET_ALL_TEMPLATE_FAILURE: "GET_ALL_TEMPLATE_FAILURE",

  UPDATE_TEMPLATE_REQUEST: "UPDATE_TEMPLATE_REQUEST",
  UPDATE_TEMPLATE_SUCCESS: "UPDATE_TEMPLATE_SUCCESS",
  UPDATE_TEMPLATE_FAILURE: "UPDATE_TEMPLATE_FAILURE",

  DELETE_TEMPLATE_REQUEST: "DELETE_TEMPLATE_REQUEST",
  DELETE_TEMPLATE_SUCCESS: "DELETE_TEMPLATE_SUCCESS",
  DELETE_TEMPLATE_FAILURE: "DELETE_TEMPLATE_FAILURE",

  CREATE_TAG_REQUEST: "CREATE_TAG_REQUEST",
  CREATE_TAG_SUCCESS: "CREATE_TAG_SUCCESS",
  CREATE_TAG_FAILURE: "CREATE_TAG_FAILURE",

  GET_ALL_TAG_REQUEST: "GET_ALL_TAG_REQUEST",
  GET_ALL_TAG_SUCCESS: "GET_ALL_TAG_SUCCESS",
  GET_ALL_TAG_FAILURE: "GET_ALL_TAG_FAILURE",

  UPDATE_TAG_REQUEST: "UPDATE_TAG_REQUEST",
  UPDATE_TAG_SUCCESS: "UPDATE_TAG_SUCCESS",
  UPDATE_TAG_FAILURE: "UPDATE_TAG_FAILURE",

  DELETE_TAG_REQUEST: "DELETE_TAG_REQUEST",
  DELETE_TAG_SUCCESS: "DELETE_TAG_SUCCESS",
  DELETE_TAG_FAILURE: "DELETE_TAG_FAILURE",

  CLEAR_SUCCESS: "CLEAR_SUCCESS",
  CLEAR_ERROR: "CLEAR_ERROR",

  CLEAR: "CLEAR",
};

export default rndTemplateConstants;
