import profilConstants from "../_constants/profil.constants";
import profilService from "../_services/profil.service";
import userActions from "./user.actions";
import i18n from "i18next";
import gestionnaireActions from "./gestionnaire.actions";
import gestionnaireConstants from "../_constants/gestionnaire.constants";

function getAll() {
  function request() {
    return { type: profilConstants.GET_ALL_REQUEST };
  }
  function success(profils: any) {
    return { type: profilConstants.GET_ALL_SUCCESS, profils };
  }
  function failure(error: Object) {
    return { type: profilConstants.GET_ALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    profilService
      .getAll()
      .then((profils: any) => dispatch(success(profils)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getAllTypePerm() {
  function request() {
    return { type: profilConstants.GET_ALL_TYPE_REQUEST };
  }
  function success(typePerm: any) {
    return { type: profilConstants.GET_ALL_TYPE_SUCCESS, typePerm };
  }
  function failure(error: Object) {
    return { type: profilConstants.GET_ALL_TYPE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    profilService
      .getAllTypePerm()
      .then((typePerm: any) => dispatch(success(typePerm)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function addProfil(profil: any) {
  function request() {
    return { type: profilConstants.SAVE_PROFIL_REQUEST };
  }
  function success(profil: any) {
    return { type: profilConstants.SAVE_PROFIL_SUCCESS, profil };
  }
  function failure(error: Object) {
    return { type: profilConstants.SAVE_PROFIL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    profilService
      .addProfil(profil)
      .then((profil: any) => dispatch(success(profil)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function deleteProfil(id: any) {
  function request() {
    return { type: profilConstants.DELETE_PROFIL_REQUEST };
  }
  function success(profil: any) {
    return { type: profilConstants.DELETE_PROFIL_SUCCESS, profil };
  }
  function failure(error: Object) {
    return { type: profilConstants.DELETE_PROFIL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    profilService
      .deleteProfil(id)
      .then((profil: any) => dispatch(success(profil)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function addProfilToUser(links: Array<any>, userId: any) {
  function request() {
    return { type: profilConstants.SAVE_PROFIL_LINKS_REQUEST };
  }
  function success(links: any) {
    return { type: profilConstants.SAVE_PROFIL_LINKS_SUCCESS, links };
  }
  function failure(error: Object) {
    return { type: profilConstants.SAVE_PROFIL_LINKS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    profilService
      .addProfilToUser(links, userId)
      .then((links: any) => {
        if (links.length > 0) {
          dispatch(userActions.get(links[0].userId));
        }
        dispatch(success(links));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function deleteProfilLinks(locationsId: Array<any>, userId: any) {
  function request() {
    return { type: profilConstants.DELETE_PROFIL_LINKS_REQUEST };
  }
  function success(links: any) {
    return { type: profilConstants.DELETE_PROFIL_LINKS_SUCCESS, links };
  }
  function failure(error: Object) {
    return { type: profilConstants.DELETE_PROFIL_LINKS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    profilService
      .deleteProfilLinks(locationsId, userId)
      .then((links: any) => {
        if (links.length > 0) {
          dispatch(userActions.get(links[0].userId));
        }
        dispatch(success(links));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère la version de crystalCloud
 *
 * @method getVersion
 * @returns {String} version
 */

const getVersion = () => {
  const request = () => ({ type: profilConstants.GETVERSION_REQUEST });

  const success = (version: Object) => ({
    type: profilConstants.GETVERSION_SUCCESS,
    version,
  });

  const failure = (error: Object) => ({
    type: profilConstants.GETVERSION_FAILURE,
    error,
  });

  return (dispatch: Function) => {
    dispatch(request());

    profilService
      .getVersion()
      .then((version: Object) => dispatch(success(version)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
};

/**
 * Récupère la lanue par défaut de crystalCloud
 *
 * @method getDefaultLanguage
 * @returns {String} language
 */

const getDefaultLanguage = () => {
  return (dispatch: Function) => {
    profilService
      .getDefaultLanguage()
      .then((lng: string) => {
        if (lng) {
          i18n.changeLanguage(lng);
          localStorage.setItem("language", lng);
        }
      })
      .catch((error: Object) => {
        console.log("ERROR GET DEFAULT LANG ");
        //i18n.changeLanguage('fr');
        //localStorage.setItem('language', 'fr');
      });
  };
};

function addGestionnairePermission(gestionnaires: Array<any>, userID: number) {
  function request() {
    return { type: gestionnaireConstants.ADD_GESTIONNAIRE_PERM_REQUEST };
  }
  function success(gestionnaires: any) {
    return {
      type: gestionnaireConstants.ADD_GESTIONNAIRE_PERM_SUCCESS,
      gestionnaires,
    };
  }
  function failure(error: Object) {
    return { type: gestionnaireConstants.ADD_GESTIONNAIRE_PERM_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    profilService
      .addGestionnairePermission(gestionnaires)
      .then((gestionnaires: any) => {
        dispatch(success(gestionnaires));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function deleteGestionnairePermission(
  gestionnaires: Array<any>,
  userID: number
) {
  function request() {
    return { type: gestionnaireConstants.DELETE_GESTIONNAIRE_PERM_REQUEST };
  }
  function success(gestionnaires: any) {
    return {
      type: gestionnaireConstants.DELETE_GESTIONNAIRE_PERM_SUCCESS,
      gestionnaires,
    };
  }
  function failure(error: Object) {
    return {
      type: gestionnaireConstants.DELETE_GESTIONNAIRE_PERM_FAILURE,
      error,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    profilService
      .deleteGestionnairePermission(gestionnaires)
      .then((gestionnaires: any) => {
        dispatch(success(gestionnaires));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}
function switchDeviceDeletionPermission(gestionnaires: any, userID: number) {
  function request() {
    return {
      type: gestionnaireConstants.SWITCH_DEVICE_DELETION_PERMISSION_REQUEST,
    };
  }
  function success(gestionnaires: any) {
    return {
      type: gestionnaireConstants.SWITCH_DEVICE_DELETION_PERMISSION_SUCCESS,
      gestionnaires,
    };
  }
  function failure(error: Object) {
    return {
      type: gestionnaireConstants.SWITCH_DEVICE_DELETION_PERMISSION_FAILURE,
      error,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    profilService
      .switchGestionnaireDevicePermission(gestionnaires)
      .then((gestionnaires: any) => {
        dispatch(success(gestionnaires));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function clear() {
  return {
    type: profilConstants.CLEAR,
  };
}

const profilActions = {
  getVersion,
  getAll,
  getAllTypePerm,
  addProfil,
  deleteProfil,
  addProfilToUser,
  deleteProfilLinks,
  addGestionnairePermission,
  deleteGestionnairePermission,
  switchDeviceDeletionPermission,
  clear,
  getDefaultLanguage,
};

export default profilActions;
