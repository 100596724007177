import * as React from "react";

const DownloadArrow = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Calque_1"
    style={{
      enableBackground: "new 0 0 57.5 46.6",
    }}
    viewBox="0 0 57.5 46.6"
    {...props}
  >
    <path
      d="M28.3 32.1c.3.1.7.1 1 0 .2-.1.3-.2.4-.3l16.5-16.5c.5-.5.5-1.3 0-1.8s-1.3-.5-1.8 0L30 27.8v-26c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3v26L13.1 13.5c-.5-.5-1.3-.5-1.8 0-.2.2-.4.6-.4.9s.1.7.4.9l16.5 16.5c.2.1.3.2.5.3zM.5 42.2H57v3.9H.5z"
      className="st0"
      fill={props.fill}
      stroke={props.fill}
    />
  </svg>
);
export default DownloadArrow;
