import { convertDate } from "../Widget/Chart";
import moment from "moment";
import { orderBy as _orderBy } from "lodash";
import { widgetConstants } from "../_constants";

/**
 * Formate les données pour les compteurs virtuels,
 * et alimente les infos additionnelles
 *
 * @method formatAdditionalData
 * @param {any}           values          Valeurs
 * @param {Array<string>} labels          Labels
 * @param {any}           additionalInfo  Infos additionnelles
 * @param {string}        zoom            Zoom
 * @param {string}        serial          Numéro de série
 */
export function formatAdditionalData(
  values: any,
  labels: Array<string>,
  additionalInfo: any,
  zoom: string,
  serial?: string
): any {
  let colors = additionalInfo.colors;
  if (additionalInfo.type.startsWith("MULTI")) {
    colors = {
      label: serial,
      values: [],
    };
  }
  values = values.map((it: any, key: any) => {
    const date = labels[key];
    if (null !== it && it.hasOwnProperty("indexCount")) {
      let val: any = {
        date: convertDate(date, zoom),
      };

      if (it.meterCount < it.indexCount) {
        val.valid = false;
        val.missings = it.indexCount - it.meterCount;
        val.meterRead = it.meterCount;
        if (undefined !== serial) {
          val.serial = serial;
          colors.values.push(it.ref ? "#ff5314" : "#FF7F50");
        } else {
          colors.push("#FF7F50");
        }
        additionalInfo.values.push(val);
      } else {
        val.valid = true;
        additionalInfo.values.push(val);
        if (undefined === serial) {
          colors.push("#80dfff");
        } else {
          colors.values.push(it.ref ? "#2BC687" : "#80dfff");
        }
      }
    }
    return it.hasOwnProperty("value") ? it.value : it;
  });

  if (additionalInfo.type.startsWith("MULTI")) {
    additionalInfo.colors.push(colors);
  }

  if (additionalInfo.values.length > 0) {
    additionalInfo.context = "vmeter";
  }

  return values;
}

/**
 * Formate les données pour les graphes à multiple compteurs
 *
 * @method formatMultiMeterData
 * @param {any}     data  Données
 * @param {number}  refId Id du compteur de référence
 * @param {string}  type  Type
 * @returns {any} Les données formatées
 */
export function formatMultiMeterData(data: any, refId: number, type: string) {
  let result: any = {
    labels: [],
    values: [],
  };
  let dates: any[] = [];

  data.forEach((it: any) => {
    let checkDate = dates.find((d: any) => it.date === d);
    if (undefined === checkDate) {
      dates.push(it.date);
    }
  });

  result.labels = _orderBy(
    dates,
    (it: any) => {
      return moment(it).format("YYYYMMDD");
    },
    ["asc"]
  );

  result.values = result.values.map((it: any) => {
    let reads = data.filter((d: any) => d.meter.id === it.yAxisId);
    let finalReads: any[] = [];
    result.labels.forEach((it: any) => {
      let checkData = reads.find((r: any) => it === r.date);
      if (undefined !== checkData) {
        let val =
          type === widgetConstants.MULTIREADINDEXWIDGET
            ? checkData.index
            : checkData.consumption;
        let item: any = {
          value: val,
        };
        if (checkData.hasOwnProperty("indexCount")) {
          item.indexCount = checkData.indexCount;
          item.meterCount = checkData.meterCount;
          item.ref = checkData.meter.id === refId;
        }
        finalReads.push(item);
      } else {
        finalReads.push({
          value: NaN,
        });
      }
    });
    it.data = finalReads;
    return it;
  });

  return result;
}
