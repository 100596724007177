import { Button, CustomInput, Modal, ModalBody, ModalHeader } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import paginationFactory from "react-bootstrap-table2-paginator";
import { withTranslation } from "react-i18next";
import ListTools from "../List/ListTools";
import LoadingBand from "../Bands/Loading";

class TelereleveModal extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.onChangeDate = this.onChangeDate.bind(this);
  }

  onChangeDate(date: any) {
    const { handleDate, startDate, endDate } = this.props;
    if (
      (startDate && endDate) ||
      moment.utc(date).isBefore(moment(startDate))
    ) {
      handleDate(moment(date).startOf("day").toISOString(true), null);
    } else if (startDate && endDate === null) {
      handleDate(startDate, moment(date).startOf("day").toISOString(true));
    }
  }

  render() {
    const {
      tournee,
      locales,
      isOpen,
      showModal,
      startDate,
      endDate,
      replaceMoreRecent,
      fillTelereleveReads,
      getTelereleveReads,
      toggleReplaceMoreRecent,
      noChange,
      t,
    } = this.props;

    const importationColumns =
      locales.locale &&
      ListTools.getDefaultColumns(ListTools.typeOfList.ImportTelereleve).map(
        (champ) => ({
          dataField: champ,
          text: t(`columns.${champ}`),
          classes: `crystalList-column ${
            champ === "clpInformations" || champ === "mtrInformations"
              ? "pre-space"
              : ""
          }`,
          sort: true,
          formatter: ListTools.findFormatter(champ),
          filter: textFilter({ placeholder: t(`columns.${champ}`) }),
        })
      );
    if (tournee.telereleveFill) {
      importationColumns.push({
        dataField: "t1CurrentExist",
        text: t(`columns.t1CurrentExist`),
        classes: "crystalList-column",
        sort: true,
        formatter: ListTools.findFormatter("t1CurrentExist"),
        filter: textFilter({ placeholder: t(`columns.t1CurrentExist`) }),
      });
    }
    const rowStyle = (row: any) => {
      const style: any = {};
      if (tournee.telereleveFill) {
        row.t1CurrentExist
          ? (style.backgroundColor = "#FFD70088")
          : (style.backgroundColor = "#c8e6c9");
      }
      return style;
    };

    return (
      <Modal isOpen={isOpen} toggle={showModal} size="lg">
        <ModalHeader toggle={showModal}>
          {t("tournee_fiches.text.recup_reads_telereleve")}
        </ModalHeader>
        <ModalBody>
          {tournee.telereleveGet ? (
            <>
              {tournee.telereleveGet.length > 0 ? (
                <>
                  <h2>
                    {tournee.telereleveGet.length}{" "}
                    {t("tournee_fiches.text.reads_get", {
                      count: tournee.telereleveGet.length,
                    })}
                  </h2>
                  <h6 style={{ fontStyle: "italic" }}>
                    {
                      tournee.telereleveGet.filter((el) => el.withCreation)
                        .length
                    }{" "}
                    {t("tournee_fiches.text.new_reads", {
                      count: tournee.telereleveGet.filter(
                        (el) => el.withCreation
                      ).length,
                    })}
                  </h6>
                  <h6 style={{ fontStyle: "italic" }}>
                    {
                      tournee.telereleveGet.filter((el) => !el.withCreation)
                        .length
                    }{" "}
                    {t("tournee_fiches.text.replaced_reads", {
                      count: tournee.telereleveGet.filter(
                        (el) => !el.withCreation
                      ).length,
                    })}
                  </h6>
                  {tournee.telereleveFillLoading && (
                    <LoadingBand
                      message={`${t(
                        "tournee_fiches.text.recup_reads_processing"
                      )}...`}
                    />
                  )}
                  <BootstrapTable
                    keyField="cmlId"
                    key={Math.floor(Math.random() * Math.floor(1024))}
                    data={tournee.telereleveFill || tournee.telereleveGet}
                    columns={importationColumns}
                    bootstrap4
                    bordered={false}
                    hover
                    headerClasses="crystalList-column"
                    filter={filterFactory()}
                    rowStyle={rowStyle}
                    pagination={
                      (tournee.telereleveFill || tournee.telereleveGet).length >
                      10
                        ? paginationFactory()
                        : null
                    }
                  />
                </>
              ) : (
                <h2>{t("tournee_fiches.text.no_read_found")}</h2>
              )}
            </>
          ) : (
            <div>
              <div className="modal-mask-alarm" style={{ textAlign: "center" }}>
                <DatePicker
                  utcOffset={0}
                  selected={!noChange ? startDate : endDate || startDate}
                  onChange={this.onChangeDate}
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={moment().toISOString(false)}
                  selectsRange
                  inline
                  locale="fr"
                  monthsShown={2}
                />
              </div>
              <h4>{t("all.text.option_plural")} :</h4>
              <CustomInput
                type="checkbox"
                id="replaceMoreRecent"
                label={t("tournee_fiches.text.replace_reads_by_more_recent")}
                checked={replaceMoreRecent}
                onChange={toggleReplaceMoreRecent}
              />
              {tournee.telereleveGetLoading && (
                <LoadingBand
                  message={`${t(
                    "tournee_fiches.text.lookup_reads_processing"
                  )}...`}
                />
              )}
            </div>
          )}
          {!tournee.telereleveFill && (
            <div className="button-submit-date">
              <Button
                color="primary"
                disabled={
                  tournee.telereleveGet && tournee.telereleveGet.length === 0
                }
                onClick={
                  tournee.telereleveGet
                    ? fillTelereleveReads
                    : getTelereleveReads
                }
              >
                {tournee.telereleveGet
                  ? t("all.button.recup")
                  : t("all.button.search")}
              </Button>
              <Button color="danger" onClick={showModal}>
                {t("all.button.cancel")}
              </Button>{" "}
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

function mapStateToProps(state: any) {
  const { tournee, locales } = state;

  return {
    tournee,
    locales,
  };
}

export default withTranslation()(connect(mapStateToProps)(TelereleveModal));
