import fileDownload from "js-file-download";
import { customExportConstants } from "../_constants";
import customExportService from "../_services/customexport.service";
import _ from "lodash";

function getCustomExport() {
  function request() {
    return { type: customExportConstants.GET_ALL_REQUEST };
  }
  function success(customExport: Array<any>) {
    return { type: customExportConstants.GET_ALL_SUCCESS, customExport };
  }
  function failure(error: Object) {
    return { type: customExportConstants.GET_ALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    customExportService
      .getAllCustomExport()
      .then((customExport: Array<any>) => {
        dispatch(success(customExport));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}
function deleteExport(exportId: number) {
  function success(customExport: Array<any>) {
    return { type: customExportConstants.GET_ALL_SUCCESS, customExport };
  }
  return (dispatch: Function) => {
    customExportService
      .deleteExport(exportId)
      .then((customExport: Array<any>) => {
        dispatch(success(customExport));
      })
      .catch((error: Object) => {
        console.log("deleteExport::error::37", error);
      });
  };
}

function getFavoriCustomExport() {
  function request() {
    return { type: customExportConstants.GET_ALL_REQUEST };
  }
  function success(customExport: Array<any>) {
    return { type: customExportConstants.GET_ALL_SUCCESS, customExport };
  }
  function failure(error: Object) {
    return { type: customExportConstants.GET_ALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    customExportService
      .getFavoriCustomExport()
      .then((customExport: Array<any>) => {
        dispatch(success(customExport));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getOneCustomExport(exportId: number) {
  function request() {
    return { type: customExportConstants.GET_ALL_REQUEST };
  }
  function success(customExport: Array<any>) {
    return { type: customExportConstants.GET_ONE_BY_ID_SUCCESS, customExport };
  }
  function failure(error: Object) {
    return { type: customExportConstants.GET_ALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    customExportService
      .getCustomExport(exportId)
      .then((customExport: Array<any>) => {
        dispatch(success(customExport));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function saveCustomExport(body: any) {
  function request() {
    return { type: customExportConstants.SAVE_REQUEST };
  }
  function success(customExport: any) {
    return { type: customExportConstants.SAVE_SUCCESS, customExport };
  }
  function failure(error: Object) {
    return { type: customExportConstants.SAVE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    customExportService
      .saveCustomExport(body)
      .then((customExport: any) => {
        dispatch(success(customExport));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function updateCustomExport(body: any, exportId: number) {
  function request() {
    return { type: customExportConstants.SAVE_REQUEST };
  }
  function success(customExport: any) {
    return { type: customExportConstants.SAVE_SUCCESS, customExport };
  }
  function failure(error: Object) {
    return { type: customExportConstants.SAVE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    customExportService
      .updateCustomExport(body, exportId)
      .then((customExport: any) => {
        dispatch(success(customExport));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function changeFavoriState(exportId: any, newFavoriState: boolean) {
  function success(customExport: any) {
    return { type: customExportConstants.GET_ALL_SUCCESS, customExport };
  }
  return (dispatch: Function) => {
    customExportService
      .changeFavoriState(exportId, newFavoriState)
      .then((customExport: any) => {
        dispatch(success(customExport));
      })
      .catch((error: Object) =>
        console.log("changeFavoriState::changeFavoriState::85", error)
      );
  };
}

let intervalCustomExportStatus: NodeJS.Timeout | null = null;
function launchCustomExportStatus(exportId: number, locationId: string) {
  function request() {
    return { type: customExportConstants.LAUNCH_REQUEST };
  }
  function success(customExport: any) {
    return { type: customExportConstants.LAUNCH_SUCCESS, customExport };
  }
  function failure(error: Object) {
    return { type: customExportConstants.LAUNCH_FAILURE, error };
  }
  return (dispatch: Function) => {
    customExportService
      .launchCustomExportStatus(exportId, locationId)
      .then((customExport: any) => {
        if (_.isNull(intervalCustomExportStatus)) {
          intervalCustomExportStatus = setInterval(() => {
            console.log("%c ANOTHER TURN", "background: #222; color: #bada55");
            dispatch(launchCustomExportStatus(exportId, locationId));
          }, 1000);
        }
        if (customExport.filename) {
          fileDownload(customExport.content, customExport.filename);
          dispatch(success(customExport.content));
          clearInterval(intervalCustomExportStatus);
          intervalCustomExportStatus = null;
        }
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function launchCustomExport(
  exportId: number,
  locationId: string,
  fillFilters: any
) {
  function request(exportId: number) {
    return { type: customExportConstants.LAUNCH_REQUEST, exportId };
  }
  function failure(error: Object) {
    return { type: customExportConstants.LAUNCH_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request(exportId));
    customExportService
      .launchCustomExport(exportId, locationId, fillFilters)
      .then(() => {
        dispatch(launchCustomExportStatus(exportId, locationId));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

export default {
  getCustomExport,
  saveCustomExport,
  launchCustomExport,
  launchCustomExportStatus,
  getOneCustomExport,
  changeFavoriState,
  updateCustomExport,
  getFavoriCustomExport,
  deleteExport,
};
