import React, { useEffect, useState } from "react";
import _ from "lodash";

const EditableRoll = (props: any) => {
  const { index, changeInputIndex, wheels } = props;
  const [value, setValue] = useState(
    index
      ? index.toString().padStart(wheels, "0").split("")
      : "".padStart(wheels, "0").split("")
  );
  const rolls = [];
  for (let i = 0; i < wheels; i++) {
    rolls.push(i);
  }

  useEffect(() => {
    document.getElementById(`${wheels - 1}`).focus();
  }, []);

  const handleChange = (e: any, xIndex: any) => {
    console.log(value, value[0], xIndex, e.target.value);
    if (
      xIndex === wheels - 1 &&
      (value[0] === "0" || xIndex === 0 || e.target.value.length === 0)
    ) {
      const copyValue = _.cloneDeep(value);
      const oldValue = parseInt(value[xIndex]);
      const splitedValue = e.target.value.split("");
      let newValue = 0;
      if (parseInt(splitedValue[0]) === oldValue) {
        newValue = splitedValue[1];
      } else {
        newValue = splitedValue[0];
      }
      if (e.target.value.length > 0) {
        for (let i = 0; i < wheels - 1; i++) {
          copyValue[i] = copyValue[i + 1];
        }
        copyValue[xIndex] = newValue;
      } else {
        for (let i = wheels - 1; i > -1; i--) {
          if (copyValue[i - 1]) {
            copyValue[i] = copyValue[i - 1];
          } else {
            copyValue[i] = "0";
          }
        }
      }
      setValue(copyValue);
      changeInputIndex(parseInt(copyValue.join(""), 10));
    }
  };
  console.log(value);
  return (
    <div
      className="roll-container"
      onClick={() => document.getElementById(`${wheels - 1}`).focus()}
    >
      {rolls.map((e: any, indexZ: number) => (
        <div
          className="roll-block"
          style={{
            pointerEvents: indexZ === rolls.length - 1 ? "all" : "none",
          }}
        >
          <input
            id={`${indexZ}`}
            className="roll-input"
            value={value[indexZ]}
            defaultValue={value[indexZ]}
            onChange={(e: any) => handleChange(e, indexZ)}
            maxLength={wheels + 1}
          />
        </div>
      ))}
    </div>
  );
};

export default EditableRoll;
