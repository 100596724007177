import React from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { connect } from "react-redux";
import Geocode from "react-geocode";
import { AvField, AvForm } from "availity-reactstrap-validation";
import FormText from "reactstrap/lib/FormText";
import _ from "lodash";
import { withTranslation } from "react-i18next";

Geocode.setApiKey("AIzaSyBSRYNpTYG6I-BWu_MSDpnkDyuKsJrafc4");
Geocode.setLanguage("fr");

interface State {
  locationInProgress: {
    address: {
      streetNumber: string;
      streetNumberAdditionnal: string;
      street: string;
      complement: string;
      zipcode: string;
      city: string;
      country: string;
      [key: string]: string;
    };
    gpsPosition: {
      lat: number;
      lng: number;
      alt: number;
    };
  };
  createdLocation: {
    address: {
      streetNumber: string;
      streetNumberAdditionnal: string;
      street: string;
      complement: string;
      zipcode: string;
      city: string;
      country: string;
    };
    gpsPosition: {
      lat: number;
      lng: number;
      alt: number;
    };
  };
  locationDoNotExist: string;
  locationDisplayed: any;
}

/**
 * @class LocationAdressChooser
 * @extends {React.Component<Props, State>}
 */
class LocationAdressChooser extends React.Component<any, State> {
  /**
   * @param {any} props Propriétés
   * @memberof DisplayTypeChooser
   */
  constructor(props: any) {
    super(props);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.formatAddress = this.formatAddress.bind(this);

    this.state = {
      locationInProgress: LocationAdressChooser.emptyLocation(),
      createdLocation: LocationAdressChooser.emptyLocation(),
      locationDoNotExist: "",
      locationDisplayed: undefined,
    };
  }

  /**
   * Met à jour le state si l'attribut location du formulaire
   * contient quelque chose
   *
   * @static
   * @param {any} props
   * @param {State} state
   * @returns {State} Le nouveau states
   * @method getDerivedStateFromProps
   * @memberof LocationAdressChooser
   */
  static getDerivedStateFromProps(props: any, state: State) {
    const { form } = props;

    // Remise à zéro de createdLocation & locationInProgress si 'location' supprimée
    if (
      !form.location &&
      !!state.createdLocation.address.street &&
      !form.locationDisplayed
    ) {
      return {
        createdLocation: LocationAdressChooser.emptyLocation(),
        locationInProgress: LocationAdressChooser.emptyLocation(),
      };
    }

    // Remise à zéro de createdLocation si retour de (LocationAdressDisplay)
    if (
      !form.location &&
      !!state.createdLocation.address.street &&
      form.locationDisplayed
    ) {
      return {
        createdLocation: LocationAdressChooser.emptyLocation(),
      };
    }

    // Récupération de la 'location' du form si edit mode
    if (form.location && !_.isEqual(form.location, state.createdLocation)) {
      if (form.location.address && form.location.address.street) {
        return {
          createdLocation: _.cloneDeep(form.location),
          locationInProgress: _.cloneDeep(form.location),
          locationDisplayed: undefined,
        };
      }
      if (
        !state.locationInProgress.address &&
        !state.locationInProgress.address.street &&
        !state.createdLocation.address &&
        !state.createdLocation.address.street
      ) {
        return {
          createdLocation: LocationAdressChooser.emptyLocation(),
          locationInProgress: LocationAdressChooser.emptyLocation(),
          locationDisplayed: undefined,
        };
      }
    }
    return null;
  }

  /**
   * Récupération d'une Location vide
   *
   * @method validate
   * @memberof DisplayValueChooserComponent
   */
  static emptyLocation() {
    return {
      address: {
        streetNumber: "",
        streetNumberAdditionnal: "",
        street: "",
        complement: "",
        zipcode: "",
        city: "",
        country: "",
      },
      gpsPosition: {
        lat: 0,
        lng: 0,
        alt: 0,
      },
    };
  }

  /**
   * Gère le passage à l'étape précédente
   *
   * @method validate
   * @memberof DisplayTypeChooser
   */
  validate = () => {
    const { form, update } = this.props;
    const { createdLocation } = this.state;

    // Remettre l'état initial de 'locationInProgress'
    if (form.location && createdLocation.address.street) {
      this.setState({
        locationInProgress: _.cloneDeep(createdLocation),
      });
    }

    // Remettre l'état initial de locationDisplayed
    if (form.locationDisplayed) {
      update("locationDisplayed", undefined);
    }

    this.props.firstStep();
  };

  /**
   * Gère le passage à l'étape suivante et la mise à jour
   * du template
   *
   * @param {Object} e Evènement
   * @method send
   * @memberof DisplayTypeChooser
   */
  send = (e: any) => {
    // e.preventDefault();
    const { update, steps } = this.props;
    const { locationInProgress } = this.state;

    Geocode.fromAddress(this.formatAddress()).then(
      // Vérification adresse
      (response: any) => {
        const { lat, lng } = response.results[0].geometry.location;
        if (!locationInProgress.gpsPosition)
          locationInProgress.gpsPosition = { lat: 0, lng: 0, alt: 0 };
        locationInProgress.gpsPosition.lat = lat;
        locationInProgress.gpsPosition.lng = lng;

        const locationDisplayed = this.getLocationDifference(
          response.results[0],
          locationInProgress
        );

        update("locationDisplayed", _.cloneDeep(locationDisplayed));
        update("location", _.cloneDeep(locationInProgress));

        this.setState({
          locationDisplayed,
          createdLocation: _.cloneDeep(locationInProgress),
          locationInProgress,
        });

        this.props.goToStep(steps.displayLocation);
      },
      (error: any) => {
        locationInProgress.gpsPosition.lat = 0;
        locationInProgress.gpsPosition.lng = 0;

        this.setState({
          locationDoNotExist: "Veuillez renseigner une adresse valide.",
        });
      }
    );
  };

  getLocationDifference(items: any, locationInProgress: any) {
    const locationDisplayed = _.cloneDeep(locationInProgress);
    const { address } = locationDisplayed;

    items.address_components.map((item) => {
      switch (item.types[0]) {
        case "route":
          const streetName = item.long_name.toLowerCase();
          if (!_.isEqual(streetName, address.street))
            address.street = item.long_name;
          break;
        case "locality":
          const cityName = item.long_name.toLowerCase();
          if (!_.isEqual(cityName, address.city)) address.city = item.long_name;
          break;
        case "country":
          if (address.country) {
            const countryName = item.long_name.toLowerCase();
            if (!_.isEqual(countryName, address.country))
              address.country = item.long_name;
          }
          break;
        case "postal_code":
          const zipcodeName = item.long_name.toLowerCase();
          if (!_.isEqual(zipcodeName, address.zipcode))
            address.zipcode = item.long_name;
          break;
        default:
          break;
      }
    });

    return locationDisplayed;
  }

  /**
   * Gère le changement du type d'affichage
   *
   * @param {Object} event Evènement
   * @method handleAddressChange
   * @memberof DisplayTypeChooser
   */
  handleAddressChange(event: any) {
    const { locationInProgress } = this.state;
    const key: string = event.target.id;
    const value: string = event.target.value;

    locationInProgress.address[key] = value;
    this.setState({
      locationInProgress,
      locationDoNotExist: "",
    });
  }

  getValidationsForField = (name: string) => {
    const { t } = this.props;
    switch (name) {
      case "city":
        return {
          required: this.hasRequired(name),
          pattern: {
            value: "[A-Za-z]+",
            errorMessage: t(
              "location_address_chooser.warning_message.format_city_name"
            ),
          },
        };

      case "streetNumber":
        return {
          required: this.hasRequired(name),
          pattern: {
            value: "[0-9]+",
            errorMessage: t(
              "location_address_chooser.warning_message.format_streetnumber_name"
            ),
          },
        };

      case "zipcode":
        return {
          required: this.hasRequired(name),
          pattern: {
            value: "^[0-9]{5}$",
            errorMessage: t(
              "location_address_chooser.warning_message.format_zipcode_name"
            ),
          },
        };

      default:
        return {
          required: this.hasRequired(name),
        };
    }
  };

  hasRequired = (name: string) => {
    switch (name) {
      case "city":
        return { value: true, errorMessage: "Renseigner la ville" };

      case "street":
        return { value: true, errorMessage: "Renseigner le nom de la rue" };

      case "zipcode":
        return { value: true, errorMessage: "Renseigner le code postal" };

      default:
        return false;
    }
  };

  formatAddress() {
    const { locationInProgress } = this.state;
    const addressInline: string = `${locationInProgress.address.streetNumber} ${locationInProgress.address.streetNumberAdditionnal} ${locationInProgress.address.street} ${locationInProgress.address.complement} ${locationInProgress.address.zipcode} ${locationInProgress.address.city} ${locationInProgress.address.country}`;
    console.log(addressInline);
    return addressInline;
  }

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof DisplayTypeChooser
   */
  render() {
    const { isActive, locales, t } = this.props;
    const { locationInProgress, createdLocation, locationDoNotExist } =
      this.state;
    const locale = locales.locale;

    if (!isActive) return null;
    return (
      <div>
        <h3>{t("location_creator.title.location_place")}</h3>
        {createdLocation && locationInProgress && (
          <AvForm onValidSubmit={this.send}>
            <div className="locationChoices">
              {Object.keys(locationInProgress.address)
                .filter((key: string) => !key.includes("concat"))
                .map((key: string) => (
                  <FormGroup key={key}>
                    <Label for={key}>
                      {t(`columns.address.${key}`)}{" "}
                      <span style={{ color: "red" }}>
                        {this.hasRequired(key) ? "*" : ""}
                      </span>
                    </Label>
                    <AvField
                      type="text"
                      name={key}
                      id={key}
                      placeholder={t(`columns.address.${key}`)}
                      onChange={this.handleAddressChange}
                      value={locationInProgress.address[key]}
                      autoComplete="NONE"
                      validate={this.getValidationsForField(key)}
                      required={this.hasRequired(key)}
                    />
                  </FormGroup>
                ))}
              <div className="text-right">
                <Label>
                  <span style={{ color: "red" }}>*</span>{" "}
                  {t("all.text.required_field_plural")}
                </Label>
              </div>
            </div>
            {locationDoNotExist && (
              <FormText color="danger">{locationDoNotExist}</FormText>
            )}
            <div className="bottomChoice">
              <Row>
                <Col md="6" className="text-right">
                  <Button color="danger" onClick={this.validate}>
                    {t("all.button.cancel")}
                  </Button>
                </Col>
                <Col md="6" className="text-left">
                  <Button color="primary">
                    {t("location_creator.button.check_on_map")}
                  </Button>
                </Col>
              </Row>
            </div>
          </AvForm>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { locales } = state;
  return {
    locales,
  };
}

const LocationAdressChooserConnectedComponent = connect(mapStateToProps)(
  LocationAdressChooser
);
const tr = withTranslation()(LocationAdressChooserConnectedComponent);
export default tr;
