import { remoteReadingConstants } from "../_constants";
import remoteReadingService from "../_services/remoteReading.service";

function getGenericData(locationId: number) {
  function request() {
    return { type: remoteReadingConstants.GET_GENERIC_DATA_REQUEST };
  }
  function success(genericData: any) {
    return {
      type: remoteReadingConstants.GET_GENERIC_DATA_SUCCESS,
      genericData,
    };
  }
  function failure(error: Object) {
    return { type: remoteReadingConstants.GET_GENERIC_DATA_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    remoteReadingService
      .getGenericData(locationId)
      .then((genericData: any) => dispatch(success(genericData)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getData(locationId: number, startDate: any, endDate: any) {
  function success(startDate: any) {
    return {
      type: remoteReadingConstants.GET_DATA_SUCCESS,
      startDate,
      endDate,
    };
  }
  return (dispatch: Function) => {
    dispatch(success(startDate));
  };
}

/**
 * Vide le state des sites
 *
 * @method clear
 */
function clear() {
  return {
    type: remoteReadingConstants.CLEAR,
  };
}

const remoteReadingActions = {
  clear,
  getGenericData,
  getData,
};

export default remoteReadingActions;
