import synchroConstants from "../_constants/synchro.constants";
import synchroService from "../_services/synchro.service";

import synchroComActions from "./synchrocom.actions";

function getAllRoundFull(gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.GETALLROUND_FULL_REQUEST };
  }
  function success(rounds: Object) {
    return { type: synchroConstants.GETALLROUND_FULL_SUCCESS, rounds };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GETALLROUND_FULL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getAllRoundFull(gestionnaireId)
      .then((rounds: Object) => dispatch(success(rounds)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getAllDevice(gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.GETALLDEVICES_REQUEST };
  }
  function success(devices: Object) {
    return { type: synchroConstants.GETALLDEVICES_SUCCESS, devices };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GETALLDEVICES_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getAllDevices(gestionnaireId)
      .then((devices: any) => dispatch(success(devices)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getAllDevicesTools() {
  function request() {
    return { type: synchroConstants.GETALLDEVICES_REQUEST };
  }
  function success(devices: Object) {
    return { type: synchroConstants.GETALLDEVICES_SUCCESS, devices };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GETALLDEVICES_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getAllDevicesTools()
      .then((devices: any) => dispatch(success(devices)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function communication(communication: any, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.COMMUNICATION_REQUEST };
  }
  function success(communication: Object) {
    return { type: synchroConstants.COMMUNICATION_SUCCESS, communication };
  }
  function failure(error: Object) {
    return { type: synchroConstants.COMMUNICATION_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .communication(communication, gestionnaireId)
      .then((communication: Object) => {
        dispatch(synchroComActions.getCurrentCommunication(gestionnaireId));
        dispatch(synchroComActions.getCurrentRounds(gestionnaireId));
        dispatch(success(communication));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function comWithAll(gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.COM_ALL_REQUEST };
  }
  function success(all: Object) {
    return { type: synchroConstants.COM_ALL_SUCCESS, all };
  }
  function failure(error: Object) {
    return { type: synchroConstants.COM_ALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .comWithAll(gestionnaireId)
      .then((all: Object) => {
        dispatch(synchroComActions.getCurrentCommunication(gestionnaireId));
        dispatch(synchroComActions.getCurrentRounds(gestionnaireId));
        dispatch(success(all));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function updateDeviceName(
  deviceConfig: any,
  deviceId: any,
  gestionnaireId: any
) {
  function request() {
    return { type: synchroConstants.UPDATENAME_REQUEST };
  }
  function success(device: Object) {
    return { type: synchroConstants.UPDATENAME_SUCCESS, device };
  }
  function failure(error: Object) {
    return { type: synchroConstants.UPDATENAME_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .updateDeviceName(deviceConfig, deviceId, gestionnaireId)
      .then((deviceName: Object) => {
        dispatch(success(deviceName));
        dispatch(getAllDevice(gestionnaireId));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function deleteDevice(deviceId: any, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.DELETE_DEVICE_REQUEST };
  }
  function success(device: Object) {
    return { type: synchroConstants.DELETE_DEVICE_SUCCESS, device };
  }
  function failure(error: Object) {
    return { type: synchroConstants.DELETE_DEVICE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .deleteDevice(deviceId, gestionnaireId)
      .then((deviceName: Object) => {
        dispatch(success(deviceName));
        dispatch(getAllDevice(gestionnaireId));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getAllMessages(gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.GETALLMESSAGES_REQUEST };
  }
  function success(messages: Object) {
    return { type: synchroConstants.GETALLMESSAGES_SUCCESS, messages };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GETALLMESSAGES_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getAllMessages(gestionnaireId)
      .then((messages: Object) => dispatch(success(messages)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getAllMarquages(gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.GETALLMARQUAGES_REQUEST };
  }
  function success(marquages: Object) {
    return { type: synchroConstants.GETALLMARQUAGES_SUCCESS, marquages };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GETALLMARQUAGES_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getAllMarquages(gestionnaireId)
      .then((marquages: Object) => dispatch(success(marquages)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getFullAffect(gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.GET_FULLAFFECT_REQUEST };
  }
  function success(fullAffect: Array<string>) {
    return { type: synchroConstants.GET_FULLAFFECT_SUCCESS, fullAffect };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GET_FULLAFFECT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getFullAffect(gestionnaireId)
      .then((fullAffect: Array<string>) => dispatch(success(fullAffect)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function updateUndroppable(devicesId: any) {
  function request() {
    return { type: synchroConstants.UPDATE_UNDROP_SUCCESS };
  }
  function success(devicesId: any) {
    return { type: synchroConstants.UPDATE_UNDROP_SUCCESS, devicesId };
  }

  return (dispatch: Function) => {
    dispatch(success(devicesId));
  };
}

function addMessages(messages: any, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.ADD_MESSAGES_REQUEST };
  }
  function success(messages: any) {
    return { type: synchroConstants.ADD_MESSAGES_SUCCESS, messages };
  }
  function failure(error: Object) {
    return { type: synchroConstants.ADD_MESSAGES_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .addMessages(messages, gestionnaireId)
      .then((messages: any) => dispatch(success(messages)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function addMarquages(marquages: any, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.ADD_MARQUAGES_REQUEST };
  }
  function success(marquages: any) {
    return { type: synchroConstants.ADD_MARQUAGES_SUCCESS, marquages };
  }
  function failure(error: Object) {
    return { type: synchroConstants.ADD_MARQUAGES_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .addMarquages(marquages, gestionnaireId)
      .then((marquages: any) => dispatch(success(marquages)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getDevice(deviceSerial: String) {
  function request() {
    return { type: synchroConstants.GET_DEVICE_REQUEST };
  }
  function success(serial: Object) {
    return { type: synchroConstants.GET_DEVICE_SUCCESS, serial };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GET_DEVICE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getDevice(deviceSerial)
      .then((serial: Object) => dispatch(success(serial)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function saphirLogin(deviceSerial: string) {
  function request() {
    return { type: synchroConstants.LOGIN_REQUEST };
  }
  function success(serial: string) {
    return { type: synchroConstants.LOGIN_SUCCESS, serial };
  }
  function failure(error: Object) {
    return { type: synchroConstants.LOGIN_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .saphirLogin(deviceSerial)
      .then((serial: string) => dispatch(success(serial)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function saphirLogout(deviceSerial: string) {
  function request() {
    return { type: synchroConstants.LOGOUT_REQUEST };
  }
  function success(serial: string) {
    return { type: synchroConstants.LOGOUT_SUCCESS, serial };
  }
  function failure(error: Object) {
    return { type: synchroConstants.LOGOUT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .saphirLogout(deviceSerial)
      .then((serial: string) => dispatch(success(serial)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 TOOLS ACTION
 */

function connectToDeviceTools(deviceSerial: string) {
  return () => {
    synchroService
      .connectToDeviceTools(deviceSerial)
      .then((serial: string) =>
        console.log(
          "connectToDeviceTools::connectToDeviceTools::691",
          "YES -> ",
          serial
        )
      )
      .catch((error: Object) => {
        console.log(error);
      });
  };
}

function disconnectOfDeviceTools(deviceSerial: string) {
  return () => {
    synchroService
      .disconnectOfDeviceTools(deviceSerial)
      .then((serial: string) =>
        console.log(
          "disconnectOfDeviceTools::disconnectOfDeviceTools::691",
          "YES -> ",
          serial
        )
      )
      .catch((error: Object) => {
        console.log(error);
      });
  };
}

function launchCommand(
  deviceSerial: string,
  commandName: string,
  options: FormData | string
) {
  return () => {
    synchroService
      .launchCommand(deviceSerial, commandName, options)
      .then((serial: string) =>
        console.log(
          "connectToDeviceTools::launchCommand::691",
          "YES -> ",
          serial,
          commandName,
          options
        )
      )
      .catch((error: Object) => {
        console.log(error);
      });
  };
}

function getListDeviceError() {
  function request() {
    return { type: synchroConstants.GET_DEVICES_ERROR_REQUEST };
  }
  function success(devices: any) {
    return { type: synchroConstants.GET_DEVICES_ERROR_SUCCESS, devices };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GET_DEVICES_ERROR_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getListDeviceError()
      .then((devices: any) => dispatch(success(devices)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function dataError(message: string) {
  return {
    type: synchroConstants.DATA_ERROR,
    error: message,
  };
}

/** END* */

function clear() {
  return {
    type: synchroConstants.CLEAR,
  };
}

const synchroActions = {
  getAllRoundFull,
  getAllDevice,
  communication,
  updateDeviceName,
  getAllMessages,
  getAllMarquages,
  updateUndroppable,
  getFullAffect,
  comWithAll,
  getDevice,
  saphirLogin,
  saphirLogout,
  addMessages,
  addMarquages,
  clear,
  getAllDevicesTools,
  connectToDeviceTools,
  launchCommand,
  disconnectOfDeviceTools,
  getListDeviceError,
  deleteDevice,
  dataError,
};

export default synchroActions;
