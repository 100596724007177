import enqueteConstants from "../_constants/enquete.constants";
import { action } from "../_interfaces/action";

export default function telereleve(state: any = {}, action: action) {
  switch (action.type) {
    case enqueteConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case enqueteConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        items: action.enquetes,
        loading: false,
      };
    case enqueteConstants.GET_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Impossible d'obtenir la liste des enquêtes",
      };
    case enqueteConstants.GET_LINKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case enqueteConstants.GET_LINKS_SUCCESS:
      return {
        ...state,
        links: action.enquetelinks,
        loading: false,
      };
    case enqueteConstants.GET_LINKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Impossible d'obtenir la liste des liens tournees - enquêtes",
      };
    case enqueteConstants.SAVE_LINKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case enqueteConstants.SAVE_LINKS_SUCCESS:
      return {
        ...state,
        savedLinks: action.savedLinks,
        loading: false,
      };
    case enqueteConstants.SAVE_LINKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Impossible d'obtenir la liste des liens tournees - enquêtes",
      };
    case enqueteConstants.GET_BY_NUM_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case enqueteConstants.GET_BY_NUM_PAGE_SUCCESS:
      return {
        ...state,
        fetchedEnquete: action.enquete,
        loading: false,
      };
    case enqueteConstants.GET_BY_NUM_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Impossible d'obtenir l'enquête",
      };
    case enqueteConstants.DELETE_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case enqueteConstants.DELETE_PAGE_SUCCESS:
      return {
        ...state,
        items: action.enquetes,
        loading: false,
      };
    case enqueteConstants.DELETE_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Impossible de supprimer l'enquête",
      };
    case enqueteConstants.SAVE_PAGE_REQUEST:
      return {
        ...state,
        saveProcess: true,
      };
    case enqueteConstants.SAVE_PAGE_SUCCESS:
      if (state.items) {
        state.items.push(action.enquete);
      }
      return {
        ...state,
        pageSaved: action.enquete,
        saveProcess: false,
      };
    case enqueteConstants.SAVE_PAGE_FAILURE:
      return {
        ...state,
        saveProcess: false,
      };
    default:
      return state;
  }
}
