import translate from "./locale-helpers";
import colorize from "./colorize-helper";
import { colors, locales } from "../_interfaces/reducers";
import _ from "lodash";
import i18next from "i18next";
import Locale from "../_shared/Locale.json";

interface Option {
  value: string;
  label: string;
}

interface AlarmType {
  label: string;
  options: Array<Option>;
}

export function formatAlarmPdi(
  listValues: Array<any>,
  masksDesac: Array<string>,
  locale: any,
  colors: any,
  t: Function
) {
  const newData = {
    labels: <any>[],
    values: <any>[],
  };
  newData.labels = listValues.map((el: any) => el.date);

  const allAlarmType = _.uniq(listValues.map((el) => _.keys(el.types)).flat());
  newData.values = allAlarmType.map((el: string) => ({
    label: translate("fr", "alarmType", el, locale.locale),
    color: colorize("chart", "alarmType", el, colors.color),
    data: listValues.map((element: any) =>
      _.entries(element.types).find(
        (alarmType: any) =>
          alarmType[0] === el &&
          (!alarmType[1] || masksDesac.includes(alarmType[0].split(".")[0]))
      )
        ? 1
        : 0
    ),
  }));
  return newData;
}

/**
 * Formate les types d'alarmes pour les rendre
 * exploitables par le composante React Select,
 * et les trie (général ou ITRON)
 *
 * @method formatAlarmTypes
 * @param {Array} baseTypes Liste des types
 * @param {Object} locale Fichier de traduction
 * @returns {Array} La liste formatée
 */
export function formatAlarmTypes(): Array<any> {
  const generalTypes: AlarmType = {
    label: i18next.t("all.text.general_male"),
    options: _.keys(Locale["fr"]["alarmTypeBasic"]).map((el) => ({
      value: el,
      label: i18next.t("alarm.type." + el.toLowerCase()),
    })),
  };

  return [generalTypes];
}

/**
 * Formate les types d'alarmes pour les rendre exploitables
 * par le composant Chart (stacked bar), et récupère les
 * couleurs associées, ainsi que les bonnes traductions
 *
 * @method formatAlarmData
 * @param {Array} alarms Liste des alarmes
 * @param {locales} locales Fichier de traduction
 * @param {colors} colors Fichier de couleurs
 * @returns {Object} Les types formatés
 */

interface parsedAlarms {
  labels: Array<any>;
  values: Array<any>;
}
export function formatAlarmData(
  alarms: Array<any>,
  locales: locales,
  colors: colors,
  displayMasked: boolean
): parsedAlarms {
  const parsedAlarms: parsedAlarms = {
    labels: <any>[],
    values: <any>[],
  };
  let types: any = [];

  if (alarms && alarms.length > 0) {
    alarms = alarms.map((it: any) => {
      if (it.hasOwnProperty("data")) {
        return {
          date: it.date ? it.date : it.startDate,
          types: it.data.map((it: any) => {
            const meters = it.meters;
            const maskedList = it.masked;
            return {
              label: it.type,
              value: meters.length,
              masked: _.filter(meters, (meter: any) => {
                return maskedList.includes(meter.id);
              }),
            };
          }),
        };
      } else {
        return {
          date: it.date ? it.date : it.startDate,
          types: _.map(it.types, (val, key) => {
            return {
              label: key,
              value: 1,
              masked: val,
            };
          }),
        };
      }
    });
    alarms = _.orderBy(alarms, ["date"], "asc");
    alarms.forEach((el: any) => {
      parsedAlarms.labels.push(el.date ? el.date : el.startDate);
      el.types.forEach((it: any) => {
        let translated = translate("fr", "alarmType", it.label, locales.locale);
        let test = types.find((t: any) => t.label === translated);
        if (undefined === test) {
          types.push({
            label: translated,
            color: colorize("chart", "alarmType", it.label, colors.color),
            data: [],
          });
        }
      });
    });
    parsedAlarms.values = types;

    types.forEach((key: any) => {
      parsedAlarms.labels.forEach((label: any) => {
        let currentAlarm = alarms.find((a) => a.date === label);
        let isType = currentAlarm.types.find(
          (it: any) =>
            i18next.t("alarm.type." + it.label.toLowerCase()) === key.label
        );
        if (undefined !== isType) {
          key.data.push(!isType.masked || displayMasked ? 1 : 0);
        } else {
          key.data.push(0);
        }
      });
    });
  }
  return parsedAlarms;
}

const alarmTypes = {
  formatAlarmTypes,
  formatAlarmData,
};
export default alarmTypes;
