import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { render } from "enzyme";

/**
 * Rend la page indiquant un accès interdit
 *
 * @method Forbidden
 */
class Forbidden extends Component<WithTranslation> {
  render() {
    const { t } = this.props;
    return (
      <div className="container">
        <div className="row">
          <h1>{t("forbidden.main_title.not_authorized")}</h1>
        </div>
      </div>
    );
  }
}

const tr = withTranslation()(Forbidden);
export default tr;
