import React, { Component } from "react";
import isArray from "lodash/isArray";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { Button, Col, Label, Row } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { autocompleteActions } from "../_actions";
import Stats from "../Widget/Stats";
import AutocompleteField from "../Widget/Autocomplete";
import translate from "../_helpers/locale-helpers";
import { formatAlarmTypes } from "../_helpers/alarms-types-helper";
import { alert, locales } from "../_interfaces/reducers";
import ConsoInterval from "./ConsoInterval";
import MeterSelector from "./MeterSelector";
import { cloneDeep } from "lodash";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import confirm from "../_components";
import DateInterval from "../Widget/DateInterval";
import HourPeriod from "../Widget/HourPeriod";
import { withTranslation } from "react-i18next";

const percentFormatter = (v: any) => {
  return `${v} %`;
};

interface State {
  values: any;
  name: string;
  alarms: Array<Object> | null | undefined;
  percent: number | null;
  meterList: string[];
  SliderWithTooltip: any;
  condition: any;
}

interface Condition {
  conditionValue: any;
  conditionType: any;
}

interface Props {
  locales: locales;
  content: {
    alertName: string;
    name: string;
    dataSourceProperty: {
      condition: Condition[];
      alertUnit: any;
    };
  };
  locationCode: any;
  update?: Function;
  nextStep?: Function;
  locationId: number;
  isActive?: boolean;
  hashKey?: string;
  previousStep?: Function;
  alert: alert;
  t: Function;
}

/**
 * @class AlarmOptionComponent
 * @extends {Component}
 */
class AlarmOptionComponent extends Component<Props & {}, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.alert.type === "alert-success") {
      return {
        values: null,
        content: null,
        name: "",
        alarms: [],
        percent: null,
        meterList: [],
        SliderWithTooltip: undefined,
      };
    } else if (state.values === null && props.alert.type != "alert-success") {
      let values: any = {};
      let content: any = props.content.dataSourceProperty.condition.forEach(
        (cond: any) => {
          values[cond.conditionTitle] =
            cond.conditionValue != null && cond.conditionValue.length !== 0
              ? cond.conditionValue
              : cond.conditionDefaultValue;
          if (cond.conditionType === "Period") {
            if (null == cond.conditionValue) {
              cond.conditionValue = {
                name: "",
                value: null,
              };
            }
            values[`${cond.conditionTitle}Name`] = cond.conditionValue.name;
            values[`${cond.conditionTitle}Value`] = cond.conditionValue.value;
          }
        }
      );
      return {
        values,
        content,
        name: content && content.name ? content.name : state.name,
        percent: state.percent,
        meterList: state.meterList,
        SliderWithTooltip: state.SliderWithTooltip,
      };
    }
    return state;
  }
  /**
   * Définit les valeurs du formulaire, et créé
   * l'instance
   *
   * @param {Object} props Propriétés
   * @constructor
   * @memberof AlarmOptionComponent
   */
  constructor(props: any) {
    super(props);

    this.validate = this.validate.bind(this);
    this.state = {
      values: null,
      name: "",
      alarms: [],
      percent: null,
      meterList: [],
      SliderWithTooltip: createSliderWithTooltip(Slider),
      condition: {},
    };
  }

  /**
   * Affiche une modal de confirmation de passage à
   * l'étape précédente, et y retourne en fonction
   * du choix de l'utilisateur
   *
   * @async
   * @method validate
   * @memberof LastComponent
   */
  async validate() {
    const { previousStep, t } = this.props;
    const { Fragment } = React;

    const result = await confirm({
      title: (
        <Fragment>
          <strong>{t("all.text.go_back")} </strong>
        </Fragment>
      ),
      message: t("all.text.go_backward_step"),
      confirmText: t("all.text.yes"),
      cancelText: t("all.text.no"),
      confirmColor: "danger",
      cancelColor: "primary",
    });
    if (result && undefined !== previousStep) {
      previousStep();
    }
  }

  /**
   * Gère l'autocomplétion sur le formulaire
   *
   * @param {string} type Type
   * @param {string} field Champ
   * @param {string} userInput Saisie (valeur)
   * @returns {Promise}
   * @method autocompleteservice
   * @memberof AlarmOptionComponent
   */
  autocompleteservice = (type: string, field: string, userInput: string) => {
    const { locationId } = this.props;
    // TODO
    // A améliorer

    const conditions = [];
    conditions.push({
      conditionTitle: field,
      conditionType: type,
      conditionValue: userInput,
    });
    if (locationId > 0) {
      conditions.push({
        conditionTitle: "locationId",
        conditionType: "Numeric",
        conditionValue: locationId,
      });
    }
    return autocompleteActions
      .autocomplete(conditions)
      .then((result: any) => {
        if (result && Array.isArray(result)) {
          return result;
        }
        return [];
      })
      .catch((error: any) => {
        // A améliorer
        console.log(error);
        return [];
      });
  };

  /**
   * Gère la multi sélection des alarmes
   *
   * @param {Array} types Types
   * @method handleChangeSelect
   * @memberof AlarmOptionComponent
   */
  handleChangeSelect = (types: any) => {
    const { values }: any = this.state;
    values.AlarmType = types.map((el: any) => el.value);
    this.setState({
      values,
      alarms: types,
    });
  };

  handleChangeSlider = (percent: number) => {
    this.setState({
      percent,
    });
  };

  /**
   * Gère le comportement à la soumission
   * invalide du formulaire
   *
   * @param {Object} event Evènement
   * @param {Object} errors Erreurs
   * @param {Object} values Valeurs du formulaire
   * @memberof AlarmOptionComponent
   */
  handleInvalidSubmit = (event: any, errors: any, values: any) => {
    this.setState({ values });
  };

  /**
   * Gère le comportement à la soumission du
   * formulaire, met à jour les informations
   * et passe à l'étape suivante
   *
   * @param {Object} event Evènement
   * @param {Object} values Valeurs du formulaire
   * @memberof AlarmOptionComponent
   */
  handleValidSubmit = (event: any, values: any) => {
    const { values: baseValues } = this.state;
    values.meters = baseValues.meters;
    this.setState({ values });
    const { percent } = this.state;
    const { content, update, nextStep } = this.props;
    const { dataSourceProperty } = content;
    const { condition } = dataSourceProperty;
    condition.forEach((cond: any, i: number) => {
      const condCopy = cond;
      if (cond.conditionTitle === "GapConsumption") {
        if (null !== percent) {
          condCopy.conditionValue = percent;
        }
      } else if (condition[i].conditionType === "Period") {
        condCopy.conditionValue = {
          name: values[`${cond.conditionTitle}Name`],
          value: values[`${cond.conditionTitle}Value`],
        };
      } else if (cond.conditionType === "HourPeriod") {
        condCopy.conditionValue = {
          min: parseInt(values[`${cond.conditionTitle}Value_min`], 10),
          max: parseInt(values[`${cond.conditionTitle}Value_max`], 10),
        };
      } else {
        condCopy.conditionValue = values[cond.conditionTitle];
      }
      condition[i] = condCopy;
    });

    let updatedForm = cloneDeep(content);
    updatedForm.name = values.name;
    this.setState({
      name: values.name,
    });
    if (update != undefined && nextStep != undefined) {
      update("content", updatedForm);
      nextStep();
    }
  };

  updateMeters = (meterList: string[], title: string) => {
    let { values }: any = this.state;
    values[title] = meterList;
    this.setState({
      meterList,
      values,
    });
  };

  /**
   * Construit tout le formulaire, en fonction des types
   * de champs
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof AlarmOptionComponent
   */
  render() {
    const { isActive, content, locales, locationCode, previousStep, t } =
      this.props;
    const { values, SliderWithTooltip }: any = this.state;
    let { alarms } = this.state;
    if (
      alarms &&
      values &&
      isArray(values.AlarmType) &&
      values.AlarmType.length > 0
    ) {
      alarms = values.AlarmType.map((el: any) => ({
        label: t(`alarm.type.${el}`),
        value: el,
      }));
    }

    if (!isActive) return null;
    return (
      <div>
        {content && content && (
          <AvForm
            onValidSubmit={this.handleValidSubmit}
            onInvalidSubmit={this.handleInvalidSubmit}
            className="config-form"
          >
            <Label>Méthode de calcul</Label>
            <p>
              {translate(
                "fr",
                "alarmCalculation",
                content.alertName,
                locales.locale
              )}
            </p>
            <AvField
              name="name"
              value={content.name}
              label={t("all.text.name")}
              required
              helpMessage="Obligatoire"
              errorMessage={t(
                "alarm_options.text.alert_name_required_with_min_length"
              )}
            />
            {content.dataSourceProperty.condition.map((item: any) => (
              <div key={item.conditionTitle}>
                {item.conditionType === "String" &&
                  !item.conditionMandatory && (
                    <AvField
                      key={item.conditionTitle}
                      name={item.conditionTitle}
                      value={values[item.conditionTitle]}
                      label={translate(
                        "fr",
                        "conditionTitle",
                        item.conditionTitle,
                        locales.locale
                      )}
                    />
                  )}

                {item.conditionType === "String" && item.conditionMandatory && (
                  <AvField
                    key={item.conditionTitle}
                    name={item.conditionTitle}
                    value={values[item.conditionTitle]}
                    label={translate(
                      "fr",
                      "conditionTitle",
                      item.conditionTitle,
                      locales.locale
                    )}
                    required
                    helpMessage={t("all.text.required")}
                    errorMessage={t("all.text.required_field")}
                  />
                )}

                {item.conditionType === "AutoCompleteString" &&
                  item.conditionMandatory && (
                    <AutocompleteField
                      key={item.conditionTitle}
                      title={item.conditionTitle}
                      name={item.conditionTitle}
                      value={values[item.conditionTitle]}
                      label={translate(
                        "fr",
                        "conditionTitle",
                        this.getConditionTitle(item),
                        locales.locale
                      )}
                      required
                      helpMessage={t("all.text.required")}
                      errorMessage={t("all.text.required_field")}
                      service={this.autocompleteservice}
                      type={item.conditionType}
                    />
                  )}

                {item.conditionType === "CheckList" &&
                  item.conditionMandatory && (
                    <AvGroup key={item.conditionTitle}>
                      <Label for={item.conditionTitle}>
                        {" "}
                        {translate(
                          "fr",
                          "conditionTitle",
                          item.conditionTitle,
                          locales.locale
                        )}
                      </Label>
                      <Select
                        options={formatAlarmTypes()}
                        isMulti
                        onChange={this.handleChangeSelect}
                        value={alarms}
                      />
                      <AvField
                        type="hidden"
                        name={item.conditionTitle}
                        value={values[item.conditionTitle]}
                      />
                    </AvGroup>
                  )}

                {item.conditionType === "RangeConsumption" &&
                  item.conditionMandatory && (
                    <ConsoInterval
                      condition={item}
                      unit={translate(
                        "fr",
                        "unit",
                        content.dataSourceProperty.alertUnit,
                        locales.locale
                      )}
                    />
                  )}

                {item.conditionTitle === "GapConsumption" &&
                  item.conditionMandatory && (
                    <div style={{ marginBottom: "35px" }}>
                      <Label for={item.conditionTitle}>
                        {" "}
                        {translate(
                          "fr",
                          "conditionTitle",
                          item.conditionTitle,
                          locales.locale
                        )}
                        {` (${translate(
                          "fr",
                          "unit",
                          content.dataSourceProperty.alertUnit,
                          locales.locale
                        )})`}
                      </Label>
                      <SliderWithTooltip
                        onChange={this.handleChangeSlider}
                        tipFormatter={percentFormatter}
                        tipProps={{ overlayClassName: "gap-tooltip" }}
                        marks={{ 0: "0%", 100: "100%" }}
                        name={item.conditionTitle}
                        id={item.conditionTitle}
                        defaultValue={
                          item.conditionValue &&
                          typeof item.conditionValue !== "string"
                            ? item.conditionValue
                            : 0
                        }
                      />
                    </div>
                  )}

                {item.conditionType === "Period" && item.conditionMandatory && (
                  <DateInterval
                    conditions={item.conditionHelper}
                    title={item.conditionTitle}
                    values={values}
                    locales={locales}
                    period={true}
                  />
                )}
                {item.conditionType === "HourPeriod" &&
                  item.conditionMandatory && (
                    <HourPeriod
                      conditions={item.conditionHelper}
                      title={item.conditionTitle}
                      values={values}
                      locales={locales}
                      period={true}
                    />
                  )}
                {item.conditionTitle === "meters" && (
                  <div>
                    <Label for={item.conditionTitle}>
                      {" "}
                      {translate(
                        "fr",
                        "conditionTitle",
                        item.conditionTitle,
                        locales.locale
                      )}
                    </Label>
                    <MeterSelector
                      locationCode={locationCode}
                      update={this.updateMeters}
                      condition={item}
                    />
                  </div>
                )}
              </div>
            ))}
            <Row>
              <Col md="4">
                {previousStep && (
                  <Stats {...this.props} previousStep={this.validate} />
                )}
              </Col>
              <Col md="8" className="text-right">
                <Button
                  type="submit"
                  color="primary"
                  id="alert-option-continue"
                  style={{ marginLeft: "5px" }}
                >
                  {t("all.button.next")}
                </Button>
              </Col>
            </Row>
          </AvForm>
        )}
      </div>
    );
  }

  private getConditionTitle(item: any): string {
    let pos: number = item.conditionTitle.indexOf("_");
    if (pos !== -1) {
      return item.conditionTitle.substring(0, pos + 1);
    }
    return item.conditionTitle;
  }
}

function mapStateToProps(state: any) {
  const { locales, alert } = state;
  return {
    locales,
    alert,
  };
}
const connectedAlarmOptionComponent =
  connect(mapStateToProps)(AlarmOptionComponent);
const tr = withTranslation()(connectedAlarmOptionComponent);
export default tr;
