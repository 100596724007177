import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import { compose } from "redux";

interface ChartData {
  label: string;
  color: string;
  hoverColor?: string;
  data: number;
}

interface Props {
  chartData: ChartData[];
  handleClick?: Function;
}

class ChartRemoteReading extends Component<Props, any> {
  constructor(props: any) {
    super(props);

    this.handleMoreInfo = this.handleMoreInfo.bind(this);

    this.state = {
      moreInfo: false,
    };
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const { chartData, handleClick } = props;
    if (chartData) {
      const labels: string[] = [];
      const colors: string[] = [];
      const hoverColors: string[] = [];
      const data: number[] = [];

      let totData = 0;
      let smallData = 0;

      chartData.sort((a, b) => (a.data > b.data ? 1 : -1));

      chartData.map((chartData) => (totData += chartData.data));

      chartData.map((chart) => {
        // Do not show data if less than 0% of total if only 2 data, and less than 5% if multi data
        if (
          (chart.data * 100) / totData > (chartData.length > 2 ? 5 : 0) ||
          data.length < 4
        ) {
          labels.push(chart.label);
          colors.push(chart.color);
          hoverColors.push(chart.hoverColor ? chart.hoverColor : "");
          data.push(chart.data);
        } else if (chart.data != 0) {
          smallData += chart.data;
        }
      });

      if (smallData > 0) {
        labels.push("Autres");
        colors.push("#ffc107");
        hoverColors.push("#b38600");
        data.push(smallData);
      }

      const dataPie = {
        labels,
        datasets: [
          {
            backgroundColor: colors,
            hoverBackgroundColor: hoverColors,
            data,
            borderWidth: 2,
          },
        ],
      };
      const optionsPie: any = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true, // Légendes explicatives
        },
        plugins: {
          t1: false,
          datalabels: {
            display: "auto",
            color: "black",
            formatter(value, context) {
              let result = 0;
              if (data[0] === value) {
                // value is the smallest
                result = Math.ceil((value * 100) / totData);
              } else if (data[data.length - 1] === value) {
                // value is the biggest
                result = Math.floor((value * 100) / totData);
              } else {
                result = Math.round((value * 100) / totData);
              }
              return `${result}%`;
            },
            font: {
              size: 30,
            },
          },
        },
      };

      if (handleClick !== undefined) {
        optionsPie.onClick = handleClick;
      }

      return {
        dataPie,
        smallData,
        optionsPie,
      };
    }
  }

  handleMoreInfo() {
    const { moreInfo } = this.state;
    this.setState({
      moreInfo: !moreInfo,
    });
  }

  render() {
    const { moreInfo, dataPie, optionsPie, smallData } = this.state;
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Pie data={dataPie} options={optionsPie} />
        {smallData.length > 0 && (
          <h5 className="clickable" onClick={this.handleMoreInfo}>
            Données supplémentaires{" "}
            <span
              style={{ transform: moreInfo ? "rotate(45deg)" : "rotate(0deg)" }}
            >
              &rang;
            </span>{" "}
          </h5>
        )}
      </div>
    );
  }
}

const connectedChartRemoteReading = compose<ComponentType<any>>(
  withRouter,
  connect()
)(ChartRemoteReading);
export default connectedChartRemoteReading;
