import React, { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import _, { unset } from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, CustomInput, FormGroup, Label } from "reactstrap";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  importActions,
  localeActions,
  locationActions,
  supportActions,
} from "../_actions";
import SuccessBand from "../Bands/Success";
import LoadingBand from "../Bands/Loading";
import { translate } from "../_helpers";
import ErrorBand from "../Bands/Error";
import BillingGeneralConfig from "./BillingGeneralConfig";
import BillingSupportAutochange from "./BillingSupportAutochange";

interface Props {
  dispatch: any;
  locales: any;
  support: any;
  imports: any;
  t: any;
}

const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: "30vh",
  },
  root: {
    color: "white !important",
  },
}));

const BillingSupport: React.FC<Props> = ({
  dispatch,
  locales,
  support,
  imports,
  t,
}) => {
  const [model, setModel] = useState({
    init: false,
    duplicateAuthorized: false,
    withColumns: ["remotereading", "importbilling"].includes(
      _.last(location.pathname.split("/")) || ""
    ),
    pathSupportDetail: _.last(location.pathname.split("/")),
  });
  const [file, setFile] = useState<FormData | null>(null);
  const [selectTagValue, setSelectTagValue] = useState<any>({});
  const [openInfo, setOpenInfo] = useState<string>("");
  const [columns, setColumns] = useState<
    | { label: string; value: number }[]
    | { label: string; options: { label: string; value: number }[] }[]
  >();
  const [isModifing, setIsModifing] = useState<boolean>(false);
  const [autoHeaderSize, setAutoHeaderSize] = useState<boolean>(true);
  const [headerSize, setheaderSize] = useState<number>(0);

  const classes = useStyles();

  const onChange = (e: any) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    setFile(formData);
  };

  useEffect(() => {
    dispatch(localeActions.load());
    dispatch(supportActions.getGeneralImportBillingSetting());
    dispatch(supportActions.getColumnImportBillingSetting());
    dispatch(supportActions.getAutoChangeImportBillingSetting());
    dispatch(locationActions.getAll("STOCK"));
    dispatch(supportActions.getEncodings());
    return () => {
      handleCancel();
    };
  }, []);

  useEffect(() => {
    if (imports.fileColumnName) {
      if (imports.fileColumnName.length === 1) {
        const newColumns = imports.fileColumnName
          .flat()
          .map((label: string, index: number) => ({ label, value: index }));
        setColumns(newColumns);
      } else {
        const newColumns = [];
        const [categoryLabels, columnsLabel] = imports.fileColumnName;
        let newCategory: {
          label: string;
          options: { label: string; value: number }[];
        } = {
          label: categoryLabels[0],
          options: [],
        };
        for (let i = 0; i < categoryLabels.length; i++) {
          if (categoryLabels[i] !== "") {
            if (newCategory.options.length) {
              newColumns.push(newCategory);
            }
            newCategory = { label: categoryLabels[i], options: [] };
          }
          newCategory.options.push({ label: columnsLabel[i], value: i });
        }
        newColumns.push(newCategory);
        setColumns(newColumns);
      }
    }
  }, [imports]);

  useEffect(() => {
    if (!support.loading && (support.success || support.error)) {
      setOpenInfo(support.success || support.error);
    } else if (imports.fileColumnName) {
      setOpenInfo(t("import.step_1_success_band.load_file"));
    }
  }, [support, imports]);

  useEffect(() => {
    if (openInfo) {
      const timer = setTimeout(() => {
        if (support.success) {
          handleCancel();
          dispatch(supportActions.clear());
          dispatch(localeActions.load());
          dispatch(supportActions.getGeneralImportBillingSetting());
          dispatch(supportActions.getColumnImportBillingSetting());
          dispatch(supportActions.getAutoChangeImportBillingSetting());
        } else {
          setOpenInfo("");
        }
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [openInfo]);

  const invalidFile = (e: any) => {
    dispatch(importActions.clear());
    dispatch(importActions.isRunning());
    dispatch(importActions.cancelConfigImport());
    setOpenInfo("");
    setFile(null);
  };

  const submitFile = () => {
    if (file) {
      dispatch(
        importActions.analyseFile(file, autoHeaderSize ? null : headerSize)
      );
    }
  };

  const checkIfValidField = () => {
    const invalidFields = document.getElementsByClassName("is-invalid");

    return !(_.size(_.defaultTo(invalidFields, [])) > 0);
  };

  const sendCustomSetting = () => {
    let columnSetting: any = {};

    const validForm = checkIfValidField();

    if (validForm) {
      // columnSetting = _.fromPairs(
      //   _.entries(model).filter(([key, value]) => _.get(support, `${model.pathSupportDetail}.column.${key}`))
      // );
      columnSetting = _.fromPairs(
        _.map(
          _.get(support, `importbilling.column`),
          (value: any, key: any) => {
            return _.map(value, (innerValue: any, innerKey: any) => {
              const inputValue = selectTagValue[`${key}-${innerKey}`];
              console.log(`${key}.${innerKey}`, inputValue);
              return [
                `${key}.${innerKey}`,
                inputValue != undefined ? inputValue : null,
              ];
            });
          }
        ).flat()
      );
      dispatch(
        supportActions.sendAllImportBillingSetting({
          general: imports.generalSettings,
          column: columnSetting,
        })
      );
    }
  };

  const chooseFileDone = () => {
    return file !== null && file && imports && !imports.running;
  };

  const getFileName = (form: FormData) => {
    const file: any = form.get("file");
    if (file) {
      return file.name;
    }
    return "";
  };

  const onTagClick = (e: any) => {
    let newSelectTagValue = { ...selectTagValue };
    if (newSelectTagValue.hasOwnProperty(e.target.id)) {
      delete newSelectTagValue[e.target.id];
    } else {
      newSelectTagValue = { ...newSelectTagValue, [e.target.id]: null };
    }
    setSelectTagValue(newSelectTagValue);
  };

  const setSelectValue = (
    newValue: React.ChangeEvent<{ value: unknown }>,
    tag: string
  ) => {
    setSelectTagValue({ ...selectTagValue, [tag]: newValue.target.value });
  };

  const checkIsDisabled = () => {
    const mendatoryConfig = _.map(
      _.get(support, "importbilling.column"),
      (cat, key) => {
        return _.filter(
          Object.entries(cat),
          (
            value: [
              string,
              { type: string; mandatory: boolean; oldValue: string }
            ]
          ) => value[1].mandatory
        )
          .map((value: any) => value[0])
          .filter(
            (value) =>
              !Object.keys(selectTagValue).includes(`${key}-${value}`) ||
              selectTagValue[`${key}-${value}`] === null
          );
      }
    ).flat();
    if (mendatoryConfig.length || support.success) {
      return true;
    }
    return false;
  };

  const handleCancel = () => {
    dispatch(importActions.cancelConfigImport());
    setSelectTagValue({});
    setFile(null);
    setIsModifing(false);
    setOpenInfo("");
  };

  const selectMenuGroup = (category: any) => {
    const options = category.options.map((option: any) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
    return [
      <MenuItem disabled value="" style={{ background: "#ebecf1" }}>
        {category.label}
      </MenuItem>,
      options,
    ];
  };

  const handleHeaderSizeChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setheaderSize(e.target.value);
    }
  };

  return (
    <div className="col-md-12 support-import-billing">
      <div className="wrapper">
        <div className="title">
          <h2>{t(`support.title.${model.pathSupportDetail}`)}</h2>
        </div>
        <BillingSupportAutochange />
        <h3 className="neon second" style={{ marginTop: 32 }}>
          {t("support.subtitle.billing_import")}
        </h3>
        {!isModifing ? (
          <>
            <button
              className="flash-button"
              onClick={() => setIsModifing(true)}
              style={{ marginTop: 20 }}
            >
              {t("all.button.modification")}
            </button>
            <div className="main-container">
              <div className="block">
                <h4 className="neon second">{t("support.title.general")}</h4>
                <BillingGeneralConfig
                  columnSize={_.get(
                    support,
                    "importbilling.general.columnSize.oldValue"
                  )}
                  endOfFirstCell={_.get(
                    support,
                    "importbilling.general.endOfFirstCell.oldValue"
                  )}
                  firstCellColumn={_.get(
                    support,
                    "importbilling.general.firstCellColumn.oldValue"
                  )}
                  firstCellRow={_.get(
                    support,
                    "importbilling.general.firstCellRow.oldValue"
                  )}
                  firstLineNumber={_.get(
                    support,
                    "importbilling.general.firstLineNumber.oldValue"
                  )}
                  headerSize={_.get(
                    support,
                    "importbilling.general.headerSize.oldValue"
                  )}
                  t={t}
                />
              </div>
              {_.get(support, `${model.pathSupportDetail}.column`) && (
                <div className="block">
                  <h4 className="neon second">{t("support.title.column")}</h4>
                  <div className="allBlockInputSection">
                    {_.map(
                      _.get(support, `${model.pathSupportDetail}.column`),
                      (v, k) => (
                        <div key={k} className="block-title">
                          <h5 className="neon third">
                            {t(
                              translate(
                                "fr",
                                `support${
                                  model.pathSupportDetail &&
                                  model.pathSupportDetail
                                    .charAt(0)
                                    .toUpperCase() +
                                    model.pathSupportDetail.slice(1)
                                }`,
                                k,
                                locales.locale
                              )
                            )}
                          </h5>
                          <div
                            className="allBlockResult"
                            style={{ minWidth: "20%" }}
                          >
                            {_.map(v, (v2, k2) => (
                              <div key={k + k2} className="resultBlock">{`${t(
                                translate(
                                  "fr",
                                  `support${_.capitalize(
                                    model.pathSupportDetail
                                  )}`,
                                  `${k}.${k2}`,
                                  locales.locale
                                )
                              )} : ${v2.oldValue}`}</div>
                            ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            {imports &&
            (!imports.fileColumnName || !(file && file.get("file"))) &&
            !imports.loading ? (
              <>
                {imports.error && <ErrorBand message={imports.error} />}
                <div style={{ display: "flex", gap: 32 }}>
                  <FormGroup style={{ width: "25%" }}>
                    <Label for="dataFile">
                      {t("import_component.input_file_label.import_file")}{" "}
                      (.csv,.tsv,.xlsx)
                    </Label>
                    <CustomInput
                      type="file"
                      id="import-silex-button"
                      onChange={onChange}
                      accept=".csv,.tsv,.xlsx"
                      placeholder={t("all.text.select_file")}
                      name="customFile"
                      label={
                        file ? getFileName(file) : t("all.text.select_file")
                      }
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </FormGroup>
                  <div>
                    <h5>Taille Header</h5>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={autoHeaderSize}
                          onChange={() => setAutoHeaderSize(!autoHeaderSize)}
                          defaultChecked
                          inputProps={{ "aria-label": "controlled" }}
                          style={{ color: "white" }}
                        />
                      }
                      label="Auto"
                    />
                    {!autoHeaderSize && (
                      <TextField
                        type="number"
                        style={{ color: "white" }}
                        inputProps={{ style: { color: "white" } }}
                        onChange={handleHeaderSizeChange}
                        value={headerSize}
                      />
                    )}
                  </div>
                </div>
              </>
            ) : (imports && imports.loading) || (support && support.loading) ? (
              <>
                <div style={{ marginTop: "10px" }}>
                  <LoadingBand
                    message={t("import.step_1_loading_band.file_analysis")}
                  />
                </div>
                <button className="flash-button error" onClick={handleCancel}>
                  {t("all.button.cancel")}
                </button>
              </>
            ) : (
              <>
                <div>
                  <Button color="danger" onClick={invalidFile}>
                    {t("all.button.change_file")}
                  </Button>
                  <p
                    style={{ marginLeft: 2, marginTop: 5, fontWeight: "bold" }}
                  >
                    {file && getFileName(file)}
                  </p>
                </div>
              </>
            )}

            <div style={{ marginBottom: 20 }} />

            {chooseFileDone() ? (
              <>
                <div>
                  {file && !imports.fileColumnName && !imports.loading && (
                    <>
                      <button
                        className="flash-button submitColor"
                        disabled={imports && imports.loading}
                        color="primary"
                        style={{ marginRight: 10 }}
                        onClick={submitFile}
                      >
                        {t("all.button.submit")}
                      </button>
                      <button
                        className="flash-button error"
                        onClick={handleCancel}
                        style={{ marginTop: "2px" }}
                      >
                        {t("all.button.cancel")}
                      </button>
                    </>
                  )}

                  {openInfo !== "" && !(support.success || support.error) && (
                    <SuccessBand message={openInfo} />
                  )}
                </div>
              </>
            ) : (
              <button className="flash-button error" onClick={handleCancel}>
                {t("all.button.cancel")}
              </button>
            )}
            {imports && imports.fileColumnName && (
              <>
                <div className="main-container">
                  <div className="block">
                    <h4 className="neon second">
                      {t("support.title.general")}
                    </h4>
                    <BillingGeneralConfig
                      columnSize={_.get(imports, "generalSettings.columnSize")}
                      endOfFirstCell={_.get(
                        imports,
                        "generalSettings.endOfFirstCell"
                      )}
                      firstCellColumn={_.get(
                        imports,
                        "generalSettings.firstCellColumn"
                      )}
                      firstCellRow={_.get(
                        imports,
                        "generalSettings.firstCellRow"
                      )}
                      firstLineNumber={_.get(
                        imports,
                        "generalSettings.firstLineNumber"
                      )}
                      headerSize={_.get(imports, "generalSettings.headerSize")}
                      t={t}
                    />
                  </div>
                </div>
                <AvForm
                  onValidSubmit={sendCustomSetting}
                  className="custom-setting-container"
                  model={model}
                  style={{ overflow: "visible" }}
                >
                  <div className="div-tag-container">
                    {_.map(
                      _.get(support, `${model.pathSupportDetail}.column`),
                      (v, k) =>
                        _.map(v, (v2, k2) => (
                          <div
                            className={`clickable-div-tag ${
                              selectTagValue.hasOwnProperty(`${k}-${k2}`) &&
                              "selected"
                            } ${v2.mandatory && "mandatory"}`}
                            onClick={onTagClick}
                            key={`${k}.${k2}`}
                            id={`${k}-${k2}`}
                          >
                            {t(
                              translate(
                                "fr",
                                `support${_.capitalize(
                                  model.pathSupportDetail
                                )}`,
                                `${k}.${k2}`,
                                locales.locale
                              )
                            )}
                          </div>
                        ))
                    )}
                  </div>
                  {Object.keys(selectTagValue).length !== 0 && (
                    <div className="configContainer">
                      {Object.keys(selectTagValue).map((tag: string) => (
                        <div className="inputBlock">
                          <label>
                            {t(
                              translate(
                                "fr",
                                `support${_.capitalize(
                                  model.pathSupportDetail
                                )}`,
                                `${tag.replace("-", ".")}`,
                                locales.locale
                              )
                            )}{" "}
                            :{" "}
                          </label>
                          <FormControl className="select-form-control">
                            <Select
                              onChange={(
                                newValue: React.ChangeEvent<{ value: unknown }>
                              ) => setSelectValue(newValue, tag)}
                              inputProps={{
                                "aria-label": "Without label",
                                classes: { root: classes.root },
                              }}
                              MenuProps={{
                                classes: { paper: classes.menuPaper },
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "center",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "center",
                                },
                                getContentAnchorEl: null,
                              }}
                              value={
                                selectTagValue[tag] !== null
                                  ? selectTagValue[tag]
                                  : ""
                              }
                              defaultValue=""
                            >
                              {columns &&
                                columns.map((item) => {
                                  if (Object.keys(item).includes("options")) {
                                    return selectMenuGroup(item);
                                  }
                                  return (
                                    <MenuItem value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </div>
                      ))}
                    </div>
                  )}
                  <div style={{ marginTop: 20 }}>
                    {openInfo !== "" &&
                      (support.success || support.error) &&
                      (support.error ? (
                        <ErrorBand message={openInfo} />
                      ) : (
                        <SuccessBand message={openInfo} />
                      ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 10,
                      marginTop: 20,
                      marginBottom: 100,
                    }}
                  >
                    <button
                      className="flash-button submitColor"
                      disabled={checkIsDisabled()}
                    >
                      {t("all.button.send")}
                    </button>
                    <button
                      className="flash-button error"
                      onClick={handleCancel}
                      disabled={support.success}
                    >
                      {t("all.button.cancel")}
                    </button>
                  </div>
                </AvForm>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { locales, support, imports } = state;
  return {
    locales,
    support,
    imports,
  };
}

const connectedBillingSupport = connect(mapStateToProps)(BillingSupport);
export default withTranslation()(connectedBillingSupport);
