import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";

import StepWizard from "react-step-wizard";
import { templateActions, alertActions, localeActions } from "../_actions";
import Last from "./Last";
import DatasourceChooser from "./DatasourceChooser";
import DisplayValueChooser from "./DisplayValueChooser";
import DisplayTypeChooser from "./DisplayTypeChooser";
import Ajout from "../SvgComponents/AjoutBleu";
import WidgetBlanc from "../SvgComponents/WidgetBlanc";
import { alert, templates } from "../_interfaces/reducers";
import AjoutBleu from "../SvgComponents/AjoutBleu";
import { WithTranslation, withTranslation } from "react-i18next";

type Props = {
  alert: alert;
  className: string;
  dispatch: any;
  templates: templates;
} & WithTranslation;

type State = {
  form: any;
  datasources: Array<any>;
  modal: boolean;
  saved: boolean;
};

/**
 * @class WidgetTemplateCreatorComponent
 * @extends {React.Component<Props, State>}
 */
class WidgetTemplateCreatorComponent extends React.Component<Props, State> {
  /**
   * Met à jour le state si l'alerte affiche un succès
   *
   * @static
   * @param {Props} props
   * @param {State} state
   * @returns {State} Le nouveau state
   * @method getDerivedStateFromProps
   * @memberof WidgetTemplateCreatorComponent
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.alert.type === "alert-success") {
      return {
        form: state.form,
        datasources: state.datasources,
        modal: state.modal,
        saved: true,
      };
    }
    return {
      form: state.form,
      datasources: state.datasources,
      modal: state.modal,
      saved: state.saved,
    };
  }

  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof WidgetTemplateCreatorComponent
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {},
      datasources: [],
      modal: false,
      saved: false,
    };
    this.updateForm = this.updateForm.bind(this);
    this.onStepChange = this.onStepChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleAutoClose = this.toggleAutoClose.bind(this);
    this.send = this.send.bind(this);
  }

  /**
   * Récupère les datasources au montage du
   * composant
   *
   * @method componentDidMount
   * @memberof WidgetTemplateCreatorComponent
   */
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(localeActions.load());
    templateActions
      .getAllDatasources()
      .then((datasources) => {
        this.setState({ datasources });
        this.updateForm("datasource", datasources[0]);
      })
      .catch((error) => {
        console.log(error);
        dispatch(alertActions.error(error));
      });
  }

  /**
   * Ferme la modale en cas de réussite à la sauvegarde
   *
   * @method componentDidUpdate
   * @memberof WidgetTemplateCreatorComponent
   */
  componentDidUpdate() {
    const { alert } = this.props;
    if (alert && alert.type === "alert-success") {
      this.toggleAutoClose();
    }
  }

  onStepChange = (stats: any) => {
    console.log({ stats });
  };

  /**
   * Met à jour les informations du formulaire
   *
   * @param {string} key
   * @param {string} value
   * @method updateForm
   * @memberof WidgetTemplateCreatorComponent
   */
  updateForm(key: any, value: any) {
    const { form }: any = this.state;
    form[key] = value;
    this.setState({ form });
  }

  /**
   * Gère l'ouverture et fermeture de la modal
   *
   * @method toggle
   * @memberof WidgetTemplateCreatorComponent
   */
  toggle() {
    const { dispatch } = this.props;
    const { modal, datasources } = this.state;
    dispatch(alertActions.clear());
    this.setState({
      modal: !modal,
      saved: false,
    });
    this.updateForm("datasource", datasources[0]);
  }

  /**
   * Gère la fermeture de la modal à la sauvegarde
   *
   * @method toggleAutoClose
   * @memberof WidgetTemplateCreatorComponent
   */
  toggleAutoClose() {
    const { dispatch } = this.props;
    const { modal, datasources } = this.state;
    dispatch(alertActions.clear());
    this.setState({
      saved: true,
      modal: !modal,
    });
    this.updateForm("datasource", datasources[0]);
  }

  /**
   * Gère la sauvegarde du template
   *
   * @method send
   * @memberof WidgetTemplateCreatorComponent
   */
  send() {
    const { form }: any = this.state;
    const { dispatch, templates } = this.props;

    const { property }: any = form;
    const content = {
      dataSourceName: form.datasource.dataSourceName,
      dataSourceProperty: Object.assign({}, property),
    };
    content.dataSourceProperty.displayProperty = form.display;
    const template = {
      name: form.name,
      private: false,
      deleted: false,
      type: "PUBLIC",
      datasource: form.datasource.dataSourceName,
      content,
    };
    dispatch(templateActions.save(template, templates.items));
  }

  render() {
    const { alert, className, t } = this.props;
    const { saved, modal, datasources, form } = this.state;
    return (
      <Fragment>
        <div
          id="addWidget"
          className="round"
          onClick={this.toggle}
          role="presentation"
          style={{ padding: "7px 5px 7px 5px", margin: "auto" }}
        >
          <Ajout className="add" width="1em" height="1em" fill="curentcolor" />
          <UncontrolledTooltip placement="bottom" target="addWidget">
            {" "}
            {t("add_widget.button.add_widget") as string}
          </UncontrolledTooltip>
        </div>
        <Modal isOpen={modal} toggle={this.toggle} className={className}>
          <ModalHeader toggle={this.toggle}>
            <WidgetBlanc height="1em" width="1em" fill="currentcolor" /> &nbsp;
            Créer un widget
          </ModalHeader>
          <ModalBody>
            <div>
              {alert.message && (
                <div className={`alert ${alert.type}`}>{alert.message}</div>
              )}
            </div>
            {datasources && datasources.length > 0 && (
              <div className="col-md-12">
                <StepWizard onStepChange={this.onStepChange}>
                  <DatasourceChooser
                    hashKey="Datatources"
                    update={this.updateForm}
                    datasources={datasources}
                    datasource={form.datasource}
                    t={t}
                  />
                  <DisplayValueChooser
                    hashKey="displayValues"
                    update={this.updateForm}
                    form={form}
                  />
                  <DisplayTypeChooser
                    hashKey="DisplayValueChooser"
                    update={this.updateForm}
                    form={form}
                  />
                  <Last
                    hashKey="TheEnd!"
                    update={this.updateForm}
                    send={this.send}
                    form={form}
                  />
                </StepWizard>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            {!saved && (
              <Button color="danger" onClick={this.toggle}>
                Annuler
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, templates, alert, locale } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    templates,
    locale,
  };
}

const wtc: any = connect(mapStateToProps)(WidgetTemplateCreatorComponent);
const widgetTemplateCreatorConnectedComponent = withRouter(wtc);
const tr = withTranslation()(widgetTemplateCreatorConnectedComponent);
export default tr;
