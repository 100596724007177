import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import GoogleMap from "google-map-react";
import useSupercluster from "use-supercluster";
import _ from "lodash";
import { Button, UncontrolledTooltip } from "reactstrap";
import { withTranslation } from "react-i18next";
import MarkerGM from "./MarkerGM";
import ClusterMarkerGM from "./ClusterMarkerGM";
import mapStyles from "../mapStyles.json";
import mapOptions from "./mapOptions.json";
import SvgTarget from "../SvgComponents/Target";
import MarkerGatewayGM from "./MarkerGatewayGM";

function mapStateToProps({ maps }) {
  return { maps };
}

export interface Props {
  maps: any;
  zoom: number;
  initialCenter: GpsPosition;
  centerMarker?: CenterMarker;
  clickMarker?: Function;
  clickCluster?: Function;
  yesIWantToUseGoogleMapApiInternals: boolean;
  selectedMeterId?: number;
  allowCenterButton?: boolean;
  allowClusterList?: boolean;
  t: Function;
  gatewayClicked?: any;
}

export interface CenterMarker {
  lat?: number;
  lng?: number;
  icon: string;
}

export interface GpsPosition {
  lat: number;
  lng: number;
}

export interface MarkerMeter {
  id: number;
  meterSerial: string;
  gpsPosition: GpsPosition;
  icon: string;
}

function GoogleMapReactC(props: Props) {
  const { maps, t, gatewayClicked: propsGatewayClicked } = props;
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [zoomSelectedMeter, setZoomSelectedMeter] = useState(null);
  const [selectedCluster, setSelectedCluster] = useState([]);
  const mapRef: any = useRef();

  useEffect(() => {
    if (mapRef && mapRef.current && propsGatewayClicked.lat) {
      mapRef.current.setZoom(15);
      mapRef.current.panTo(propsGatewayClicked);
    }
  }, [propsGatewayClicked]);

  const points = (maps.markers || []).map((el: MarkerMeter) => ({
    type: "Feature",
    properties: {
      cluster: false,
      meterId: el.id,
      meterSerial: el.meterSerial,
      icon: el.icon,
    },
    geometry: {
      type: "Point",
      coordinates: [el.gpsPosition.lng, el.gpsPosition.lat],
    },
  }));

  const options = {
    radius: 85,
    maxZoom: 21,
    extent: 256,
    map: (props) => {
      const color = props.icon;

      return {
        ...props,
        colorList: [color],
        meterClusters: [
          {
            id: props.meterId,
            meterSerial: props.meterSerial,
            meterState: props.icon,
          },
        ],
      };
    },
    reduce: (acc, props) => {
      acc.colorList = _.concat(acc.colorList, props.colorList);
      acc.meterClusters = _.concat(acc.meterClusters, props.meterClusters);
    },
  };

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options,
  });

  function clickCluster(
    clusterId: string,
    lat: number,
    lng: number,
    meterClusters: any
  ) {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(clusterId),
      options.maxZoom
    );
    mapRef.current.setZoom(expansionZoom);
    mapRef.current.panTo({ lat, lng });

    setSelectedCluster([lat, lng]);

    if (mapRef.current.zoom === options.maxZoom && props.clickCluster) {
      props.clickCluster(meterClusters);
    }
  }

  function clickMarker(key: any, lat: number, lng: number) {
    mapRef.current.setCenter({ lat, lng });
    setZoomSelectedMeter(mapRef.current.zoom);
  }

  return (
    <>
      {/* @ts-ignore */}
      <GoogleMap
        bootstrapURLKeys={{ key: "AIzaSyBSRYNpTYG6I-BWu_MSDpnkDyuKsJrafc4" }}
        defaultCenter={props.initialCenter}
        defaultZoom={props.zoom}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);

          const nw = [bounds.nw.lng, bounds.nw.lat];
          const se = [bounds.se.lng, bounds.se.lat];

          const ajoutLongitude = nw[0] - se[0];
          const ajoutLatitude = nw[1] - se[1];

          const newNw = [nw[0] + ajoutLongitude, nw[1] + ajoutLatitude];
          const newSe = [se[0] - ajoutLongitude, se[1] - ajoutLatitude];

          setBounds([newNw[0], newSe[1], newSe[0], newNw[1]]);
        }}
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        styles={mapStyles}
        options={mapOptions}
      >
        {props.centerMarker && (
          <MarkerGM
            lat={
              props.centerMarker.lat
                ? props.centerMarker.lat
                : _.cloneDeep(props.initialCenter.lat)
            }
            lng={
              props.centerMarker.lng
                ? props.centerMarker.lng
                : _.cloneDeep(props.initialCenter.lng)
            }
            isCenterMarker
            icon={props.centerMarker.icon}
          />
        )}
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;
          const isMaxZoom =
            mapRef.current && mapRef.current.zoom === options.maxZoom;

          if (isCluster) {
            return (
              <ClusterMarkerGM
                clickCluster={(
                  clusterId: string,
                  lat: number,
                  lng: number,
                  meterClusters: any
                ) => clickCluster(clusterId, lat, lng, meterClusters)}
                unselectCluster={() => setSelectedCluster([])}
                clusterId={cluster.id}
                markerCount={pointCount}
                lat={latitude}
                lng={longitude}
                colorCluster={_.countBy(cluster.properties.colorList) as any}
                meterClusters={cluster.properties.meterClusters}
                isSelected={
                  isMaxZoom && _.isEqual(selectedCluster, [latitude, longitude])
                }
                clickMeter={(meterId: number) => {
                  if (props.clickMarker) props.clickMarker(meterId);
                }}
                meterSelected={props.selectedMeterId}
                allowClusterList={props.allowClusterList}
              />
            );
          }
          return (
            <MarkerGM
              clickMarker={(meterId, lat, lng) => {
                if (props.clickMarker) props.clickMarker(meterId);
                clickMarker(meterId, lat, lng);
              }}
              meterId={cluster.properties.meterId}
              meterSerial={cluster.properties.meterSerial}
              lat={latitude}
              lng={longitude}
              icon={cluster.properties.icon}
              isSelected={props.selectedMeterId === cluster.properties.meterId}
            />
          );
        })}
        {maps.gateways &&
          maps.gateways.map((el: any, index: any) => (
            <MarkerGatewayGM
              clickMarker={(id: number, lat: any, lng: any) => {
                if (props.clickMarker) props.clickMarker(id);
                clickMarker(id, lat, lng);
              }}
              id={index}
              lat={el.gpsPosition.lat}
              lng={el.gpsPosition.lng}
              name={el.name}
              active={el.active}
              icon={el.active ? "active_gateway" : "inactive_gateway"}
              isSelected={
                propsGatewayClicked &&
                propsGatewayClicked.lat === el.gpsPosition.lat &&
                propsGatewayClicked.lng === el.gpsPosition.lng
              }
            />
          ))}
      </GoogleMap>
      {props.allowCenterButton && (
        <Button
          id="centerMeterSelectedButton"
          className="centerMeterSelected"
          color="white"
          disabled={!props.selectedMeterId}
          onClick={() => {
            const meter = _.find(
              maps.markers,
              (meter) => meter.id === props.selectedMeterId
            );
            const position = _.get(meter, "gpsPosition");

            if (position) {
              mapRef.current.setZoom(zoomSelectedMeter || 21);
              mapRef.current.setCenter(position);
            }
          }}
        >
          <SvgTarget fill={props.selectedMeterId ? "" : "#343a40"} />
          <UncontrolledTooltip
            placement="bottom"
            target="centerMeterSelectedButton"
            dangerouslySetInnerHTML={{
              __html: t("google_map_react_c.text.center_selected_meter", {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </Button>
      )}
    </>
  );
}

const googleMapReactCConnected = connect(mapStateToProps)(GoogleMapReactC);
const tr = withTranslation()(googleMapReactCConnected);
export default tr;
