import { widgetConstants } from "../_constants";
import widgetService from "../_services/widget.service";
import dashboardActions from "./dashboard.actions";
import { getDisplayId } from "../_helpers";

/**
 * Récupère tous les widgets
 *
 * @method getAll
 * @returns {Object} Les widgets
 */
function getAll() {
  function request() {
    return { type: widgetConstants.GETALL_REQUEST };
  }
  function success(widgets: Object) {
    return { type: widgetConstants.GETALL_SUCCESS, widgets };
  }
  function failure(error: Object) {
    return { type: widgetConstants.GETALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    widgetService
      .getAll()
      .then((widgets: Object) => dispatch(success(widgets)))
      .catch((error: Object) => dispatch(failure(error)));
  };
}

/**
 * Récupère le widget dont l'id est passé en paramètre
 *
 * @method get
 * @param {number} widgetId Id du widget
 * @returns {Object} Le widget
 */
function get(widgetId: number) {
  return widgetService.get(widgetId);
}

function getWidgetData(widgetId: number, displayID: string) {
  function request() {
    return { type: widgetConstants.GET_DATA_REQUEST };
  }
  function success(data: Object) {
    return { type: widgetConstants.GET_DATA_SUCCESS, data, widgetId };
  }
  function failure(error: Object) {
    return { type: widgetConstants.GET_DATA_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    widgetService
      .getWidgetData(widgetId, displayID)
      .then((widgetData: Object) => {
        dispatch(success(widgetData));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Sauvegarde le widget ainsi que sa position passés en
 * paramètre
 *
 * @method save
 * @param {any} widget Widget
 * @param {any} dashboard Tableau de bord
 * @param {any} positionWidget Position du widget
 * @returns {Object} L'état de la sauvegarde
 */
function save(widget: any, dashboard: any, positionWidget: any) {
  function request() {
    return { type: widgetConstants.SAVE_REQUEST, widget };
  }
  function success(savedwidget: Object) {
    return { type: widgetConstants.SAVE_SUCCESS, savedwidget };
  }
  function failure(error: Object) {
    return { type: widgetConstants.SAVE_FAILURE, error };
  }
  function saveToDashboard(dispatch: Function, savedWidget: any) {
    const updatedDashboard = dashboard;
    updatedDashboard.widgets.unshift(savedWidget);

    const updatedPositionWidget = positionWidget;
    updatedPositionWidget.i = savedWidget.id.toString();
    updatedDashboard.content.unshift(updatedPositionWidget);
    dispatch(dashboardActions.update(updatedDashboard));
  }
  return (dispatch: Function) => {
    dispatch(request());
    widgetService
      .save(widget, dashboard.id)
      .then((savedwidget: Object) => {
        dispatch(success(savedwidget));
        saveToDashboard(dispatch, savedwidget);
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Sauvegarde le widget sur le dashboard passé en paramètre
 *
 * @method update
 * @param {any} widget Widget
 * @param {number} dashboardId Id du tableau de bord
 * @param {any} dashboard Tableau de bord
 * @returns {Object} L'état de la mise à jour
 */
function update(widget: any, dashboardId: number, dashboard: any) {
  function success(updatedWidget: Object) {
    return { type: widgetConstants.UPDATE_SUCCESS, updatedWidget };
  }
  function failure(error: Object) {
    return { type: widgetConstants.UPDATE_FAILURE, error, widgetId: widget.id };
  }
  function saveToDashboard(dispatch: Function, updatedWidget: any) {
    const updatedDashboard = dashboard;
    updatedDashboard.widgets[
      updatedDashboard.widgets.findIndex(
        (elem: any) => elem.id === updatedWidget.id
      )
    ] = updatedWidget;
    dispatch(dashboardActions.update(updatedDashboard));
  }
  return (dispatch: Function) => {
    widgetService
      .update(widget, dashboardId)
      .then((updatedWidget: any) => {
        dispatch(success(updatedWidget));
        saveToDashboard(dispatch, updatedWidget);
        dispatch(
          getWidgetData(
            updatedWidget.id,
            updatedWidget.content.dataSourceProperty.displayID
          )
        );
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Recupère le contenu du widget dont l'id est passé en paramètre
 *
 * @method getContent
 * @param {number} widgetId Id du widget
 * @returns {Object} Le contenu du widget
 */
function getContent(widgetId: number) {
  function success(widgetContent: Object) {
    return {
      type: widgetConstants.GETCONTENT_SUCCESS,
      widgetContent,
      widgetId,
    };
  }
  function failure(error: Object) {
    return { type: widgetConstants.GETCONTENT_FAILURE, error, widgetId };
  }

  return (dispatch: Function) => {
    widgetService
      .get(widgetId)
      .then((widget: any) => {
        let displayId = getDisplayId(widget);
        return widgetService.getContent(displayId, widget.content);
      })
      .then((widgetContent: any) => {
        dispatch(success(widgetContent));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Supprime le widget du tableau de bord passé en paramètre
 *
 * @method toDelete
 * @param {number} widgetId Id du widget
 * @param {any} dashboard Tableau de bord
 * @returns {Object} L'état de la suppression
 */
function toDelete(widgetId: number, dashboard: any) {
  function request() {
    return { type: widgetConstants.DELETE_REQUEST, widgetId };
  }
  function success(operation: Object) {
    return { type: widgetConstants.DELETE_SUCCESS, operation };
  }
  function failure(error: Object) {
    return { type: widgetConstants.DELETE_FAILURE, error, widgetId };
  }
  function deleteOfDashboard(dispatch: Function) {
    const updatedDashboard = dashboard;
    updatedDashboard.widgets = updatedDashboard.widgets.filter(
      (widget: any) => widget.id !== widgetId
    );
    updatedDashboard.content = updatedDashboard.content.filter(
      (widget: any) => widget.i !== widgetId.toString()
    );
    dispatch(dashboardActions.update(dashboard));
  }
  return (dispatch: Function) => {
    dispatch(request());
    widgetService
      .toDelete(widgetId, dashboard.id)
      .then((operation: Object) => {
        dispatch(success(operation));
        deleteOfDashboard(dispatch);
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getWidgetcontent(template: any) {
  return widgetService.getWidgetContent(template);
}

/**
 * Vide le state des widgets
 *
 * @method clear
 */
function clear() {
  return {
    type: widgetConstants.CLEAR,
  };
}

const widgetActions = {
  getAll,
  get,
  update,
  save,
  toDelete,
  getContent,
  clear,
  getWidgetData,
  getWidgetcontent,
};

export default widgetActions;
