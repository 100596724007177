import React from 'react';
export default { 
translation: { 
"all.button.submit":"Enviar", 
"all.button.validate":"Validar", 
"all.button.edit":"Editar", 
"all.button.integrate":"Editar", 
"all.button.close":"Cerrar", 
"all.button.cancel":"Cancelar", 
"all.button.delete":"Eliminar", 
"all.button.register":"Guardar", 
"all.button.change_file":"Cambiar archivo", 
"all.button.search":"Buscar", 
"all.button.reset":"Reiniciar", 
"all.button.back":"Atras", 
"all.button.export":"Exportar", 
"all.button.select":"Seleccionar", 
"all.button.add_to_favorites":"Agregar a los favoritos", 
"all.button.next":"Continuar", 
"all.button.apply":"Aplicar", 
"all.button.save":"Guardar", 
"all.button.giveup":"Abondonar", 
"all.button.recup":"Recuperar", 
"all.button.confirm":"Confirmar", 
"all.button.copy":"Copiar", 
"all.button.paste":"Pegar", 
"all.button.apply.all":"Aplica todo", 
"all.button.cancel.all":"Cancelar todo", 
"all.button.modification":"Modificación", 
"all.button.send":"enviar", 
"all.text.block":"$t(all.button.delete)", 
"all.text.factu":"Facturación", 
"all.text.doc":"Documentación", 
"all.column_text.code":"$t(all.text.code)", 
"all.column_text.label":"Etiqueta", 
"all.column_text.name":"$t(all.text.name)", 
"all.column_text.state":"$t(all.text.state)", 
"all.column_text.row":"Linea", 
"all.column_text.pdi_ref":"Referencia PDI", 
"all.column_text.serial_meter":"$t(all.meter.meter_serial)", 
"all.column_text.meter":"$t(all.meter.meter)", 
"all.column_text.virtual_meter":"$t(all.text.virtual_meter)", 
"all.column_text.type":"$t(all.text.type)", 
"all.column_text.type_plural":"$t(all.text.type_plural)", 
"all.column_text.cons":"$t(all.read_meter.cons)", 
"all.column_text.cons_avg":"Consumo medio", 
"all.column_text.date":"$t(all.date.date)", 
"all.column_id":"Id", 
"all.brand.itron":"Itron", 
"all.address.city":"Cuidad", 
"all.address.country":"Pais", 
"all.address.complement":"Direccion addicional", 
"all.address.street":"Calle", 
"all.address.street_number":"Nombre de la calle", 
"all.address.streetNumber":"$t(all.address.street_number)", 
"all.address.street_number_additionnal":"Otro", 
"all.address.streetNumberAdditionnal":"$t(all.address.street_number_additionnal)", 
"all.address.zipcode":"Código postal", 
"all.contact.civility":"Civilidad", 
"all.contact.civility_male":"Sr", 
"all.contact.civility_female":"Sra", 
"all.contact.first_name":"Nombre", 
"all.contact.last_name":"Apellido", 
"all.contact.phone":"Numero de telefono", 
"all.contact.email":"Email", 
"all.contact.mail":"$t(all.contact.email)", 
"all.contact.note":"$t(all.text.note)", 
"all.contact.no_contact":"Ningun contacto", 
"all.devices":"Teléfonos", 
"all.filter.filter_by_serial":"Filtrar por numero de serie", 
"all.filter.filter_by_ref":"Filtrar por referencia", 
"all.filter.filter_by_name":"Filtrar por nombre", 
"all.filter.saved_filter":"Filtro guardado", 
"all.filter.saved_filter_plural":"Fitros guardados", 
"all.filter.no_conf_filter_saved":"No se guardó ninguna configuración de filtro", 
"all.filter.delete_saved_filter":"Eliminar filtro guardado", 
"all.filter.add_filter":"Añadir filtro", 
"all.filter.delete_filter":"Eliminar filtro", 
"all.filter.filter":"Filtro", 
"all.filter.filter_plural":"Filtros", 
"all.filter.mask_filter":"Filtrar por mascara de alarma", 
"all.filter.alarm_type_filter":"Filtrar por tipo de mascara", 
"all.filter.round_filter":"Filtrar por ruta", 
"all.filter.round_filter_plural":"Filtrar por rutas", 
"all.filter.device_filter":"Filtrar por dispositivo", 
"all.filter.device_filter_plural":"Filtrar por dispositivos", 
"all.filter.with_hour":"Con horas", 
"all.date.date":"Fecha", 
"all.date.date_plural":"Fechas", 
"all.date.updated_on":"Actualizado el", 
"all.date.update_date":"Actualizar fecha", 
"all.date_format.date":"DD/MM/YYYY", 
"all.date_format.date_back":"dd/MM/yyyy", 
"all.date_format.date_with_time":"DD/MM/YYYY {{time}}", 
"all.date_format.date_and_time":"DD/MM/YYYY HH:mm", 
"all.date.today_date":"Fecha de hoy", 
"all.date.end_date":"Fecha final", 
"all.user.user":"Usario", 
"all.user.user_plural":"Usarios", 
"all.user.profile":"Perfil", 
"all.user.profile_plural":"Perfiles", 
"all.user.add_profile":"Añadir perfil", 
"all.user.delete_profile":"Eliminar perfil", 
"all.user.profile_name":"Nombre de perfil", 
"all.user.role":"Papel", 
"all.user.permission_plural":"Permisos", 
"all.user.my_info_plural":"Mis informaciones", 
"all.user.logout":"Cerrar secion", 
"all.user.user_required":"Usario obligatorio", 
"all.user.pwd":"Contraseña", 
"all.user.logIn":"Acceso", 
"all.text.all":"Todo", 
"all.text.all_plural":"Todos", 
"all.text.no":"No", 
"all.text.yes":"Si", 
"all.text.unknown":"Desconocido", 
"all.text.no_data_available":"Datos no disponible", 
"all.text.indexed":"Indexado", 
"all.text.indexed_plural":"Indexados", 
"all.text.not_indexed":"No indexado", 
"all.text.not_indexed_plural":"No indexados", 
"all.text.modified":"Modificado", 
"all.text.modified_plural":"Modificados", 
"all.text.not_modified":"No modificado", 
"all.text.not_modified_plural":"No modificados", 
"all.text.gps":"Localizado", 
"all.text.gps_plural":"Localizados", 
"all.text.no_gps":"No localizado", 
"all.text.no_gps_plural":"No localizados", 
"all.text.multiple_select":"Multiseleccion", 
"all.text.edited_not_indexed_plural":"$t(tournee_stat.container_label.edited_not_indexed_plural)", 
"all.text.active":"Activado", 
"all.text.inactive":"Inactivado", 
"all.text.latitude":"Latitud", 
"all.text.longitude":"Longitud", 
"all.text.not_possible_deletion":"Supresión imposible", 
"all.text.unity":"Unidad", 
"all.text.configure":"Configurar", 
"all.text.filter_explanation":"Utilizará filtros que se calcularán dinámicamente", 
"all.text.required_field_character_condition":"El campo es obligatorio, no debe contener ningún carácter especial que no sea _ o acentos, y tener menos de 100 caracteres.", 
"all.text.option":"Opcion", 
"all.text.option_plural":"Opciones", 
"all.text.select_file":"Seleccione un archivo", 
"all.text.already_exist":"Ya existe", 
"all.text.imported":"Importado", 
"all.text.msg":"Mensajes", 
"all.text.marking":"Marcas", 
"all.text.required":"Obligatorio", 
"all.text.required_field":"Campo obligatorio", 
"all.text.required_field_plural":"Campos obligatorios", 
"all.text.virtual_meter":"Medidor virtual", 
"all.text.note":"Nota", 
"all.text.manager":"Gestionario", 
"all.text.manager_plural":"Gestionarios", 
"all.text.import_mfr":"Importacion del fabricante", 
"all.text.silex_import":"Importacion de Silex", 
"all.text.write_note":"Escribir una nota", 
"all.text.record":"Expediente", 
"all.text.record_plural":"Expedientes", 
"all.text.update_date":"Ultima actualizacion", 
"all.text.marquage":"Marca", 
"all.text.message":"Mensaje", 
"all.text.particular_msg":"Mensaje especial", 
"all.text.import_billing":"Importacion de patrimonio", 
"all.text.notif":"Notificacion", 
"all.text.notif_plural":"Notificaciones", 
"all.text.alert_plural":"$t(all.alert.alert_plural)", 
"all.text.detail_plural":"Detalles", 
"all.text.stock":"Existencias", 
"all.text.synchro":"Synchronizacion", 
"all.text.start_new_releve":"Arrancar une nueva campaña de lecturas", 
"all.text.phone":"Movil", 
"all.text.phone_plural":"Moviles", 
"all.text.name.export":"Exporta", 
"all.text.stat_plural":"Estadisticas", 
"all.text.forbidden_access":"No autorizado", 
"all.text.none_male":"Ninguno", 
"all.text.none_female":"Ninguna", 
"all.text.name":"Nombre", 
"all.text.update_selected_conf":"Actualizar la configuracion seleccionada", 
"all.text.save_new_conf":"Guardar la nueva configuracion", 
"all.text.operator":"Operador", 
"all.text.start_date":"Fecha de inicio", 
"all.text.end_date":"$t(all.date.end_date)", 
"all.text.new":"Neuvo", 
"all.text.name_lenght_max_100":"El nombre no debe tener mas de 100 caracteres", 
"all.text.link":"Asociar", 
"all.text.unlink":"Disociar", 
"all.text.model":"Modelo", 
"all.text.export_to":"Exportar en CVS", 
"all.text.export_to_csv":"Exportar en CVS", 
"all.text.export_to_teleo":"Teleo", 
"all.text.type_x_list_loading":"Cargando la lista de {{type}}", 
"all.text.not_specified":"No especificado", 
"all.text.zoom":"Zoom", 
"all.text.interval":"Interval", 
"all.text.export_data_from_x":"Exportacion de los datos de {{name}}", 
"all.text.save_pdf":"Guardar PDF", 
"all.text.preview_pdf":"Vista previa del PDF", 
"all.text.export_row_data":"Exportar datos brutos", 
"all.text.average":"Promedio", 
"all.text.type":"Tipo", 
"all.text.type_plural":"Tipos", 
"all.text.information":"Informacion", 
"all.text.information_plural":"Informaciones", 
"all.text.state":"Estado", 
"all.text.start":"Inicio", 
"all.text.end":"Fin", 
"all.text.ok":"Ok", 
"all.text.more_info_plural":"Informaciones complementarias", 
"all.text.last_update":"Ultima actualizacion", 
"all.text.unlock":"Desbloquear", 
"all.text.locked":"Bloqueado", 
"all.text.lock":"Bloquear", 
"all.text.link_all":"Asocia todo", 
"all.text.dissociate_all":"Discia todo", 
"all.text.threshold":"Umbral", 
"all.text.edit_information":"Modificar las informaciones", 
"all.text.edit_password":"Modificar la contrasena", 
"all.text.text_email":"Texto de su correo electronico", 
"all.text.mark_all_as_read":"Marcar todo como leido", 
"all.text.title":"Titro", 
"all.text.read_notification":"Leer la notificacion", 
"all.text.resource_list":"Lista de recursos", 
"all.text.validate-_deletion_link":"Validar la eliminacion del enlace", 
"all.text.widgets":"Widgets", 
"all.text.lora_import":"Importacion LoRa", 
"all.text.code":"Codigo", 
"all.text.waiting_action":"Accion en espera", 
"all.text.waiting_action_plural":"Acciones en espera", 
"all.text.go_back":"Regresa", 
"all.text.go_backward_step":"Volver al paso anterior?", 
"all.text.greater_than":"Superior a", 
"all.text.less_than":"Inferior a", 
"all.text.not_define":"Indefinida", 
"all.text.summary":"Resumen", 
"all.text.calculation_method":"Metodo de calculo", 
"all.text.delete_x_ask":"Eliminar {{type}} ?", 
"all.text.irreversible_action_will_delete_x":"Esta accion irreversible eliminara {{type}}.", 
"all.text.loading":"Cargando", 
"all.text.selection":"Seleccion", 
"all.text.more_informations_plural":"Informacion aditional", 
"all.text.select_all":"Seleccionar todo", 
"all.text.deselect_all":"Deseleccionar todo", 
"all.text.submit_file":"Envio de archivos", 
"all.text.install_date":"Fecha de instalacion", 
"all.text.reference":"Referencia", 
"all.text.general_male":"General", 
"all.text.previous":"Previa", 
"all.text.close":"Cerado", 
"all.text.doclose":"Cerar", 
"all.text.open":"Abierto", 
"all.text.doopen":"Abrir", 
"all.text.humidity":"Humedad", 
"all.text.temperature":"Temperatura", 
"all.text.pressure":"Presión", 
"all.text.co2":"CO2", 
"all.text.downlink":"Downlink", 
"all.text.monday":"Lunes", 
"all.text.tuesday":"Martes", 
"all.text.wednesday":"Miercoles", 
"all.text.thursday":"Jueves", 
"all.text.friday":"Viernes", 
"all.text.saturday":"Sabado", 
"all.text.sunday":"Domingo", 
"all.text.version":"Version", 
"all.text.previous_releve":"Lectura previa", 
"all.text.value":"Valor", 
"all.text.field":"Campo", 
"all.text.filter":"Filtro", 
"all.text.result":"Resultado", 
"all.text.other":"Otro", 
"all.text.subscriber":"Suscriptor", 
"all.text.portable_informations":"Información sobre el teléfono móvil", 
"all.text.portable_alias":"Alias", 
"all.text.irreversible_action_warning":"Atención, esta acción es irreversible", 
"all.text.other_plural_fem":"otras..", 
"all.text.mounted":"Montado", 
"all.text.unmounted":"Desmontado", 
"all.threshold.min_x_threshold":"Umbral minimo: {{value}}", 
"all.threshold.max_x_threshold":"Umbral maximo: {{value}}", 
"all.alert.alert":"Alerta", 
"all.alert.alert_plural":"Alertas", 
"all.alert.alert_name":"Nombre de la alerta", 
"all.alert.alert_type":"Tipo de la alerta", 
"all.alert.alert_conf":"Configurar alerta", 
"all.alert.delete_alert":"Eliminar alerta", 
"all.alert.edit_alert":"Modificar alerta", 
"all.alert.view_report_plural":"Ver informes", 
"all.alert.deactivate_alert":"Desactivar la alerta", 
"all.alert.irreversible_action_deactivate_alert":"Esta accion irreversible desactivara esta alerta de sitio", 
"all.homepage.homepage":"Pagina de inincio", 
"all.homepage.choose_homepage":"Elegir pagina de inicio", 
"all.mask.mask_plural":"Mascaras", 
"all.mask.edit_mask":"Modificar mascara", 
"all.mask.delete_mask":"Eliminar mascara", 
"all.mask.add_mask":"Agregar mascara", 
"all.mask.mask_edition":"Edicion mascara", 
"all.mask.mask_name":"Nombre de la mascara", 
"all.device.last_login_date":"Fecha de la ultima conexion", 
"all.device.connected":"Conectado", 
"all.device.disconnected":"Desconectado", 
"all.device.device_own_last_version":"Este telefono tiene la ultima version de Saphir", 
"all.device.device_not_own_last_version":"Este telefono no tiene la ultima version de Saphir", 
"all.device.device_with_obsolete_version":"Este telefono tiene una version desactualizada de Saphir y no puede hacer com", 
"all.device.edit_device":"Modificar dispositivo", 
"all.device.delete_device":"Eliminar dispositivo", 
"all.device.phone":"$t(all.text.phone)", 
"all.device.phone_plural":"$t(all.text.phone_plural)", 
"all.dashboard.dashboard_name":"Nombre del tablero", 
"all.dashboard.name_need_least_2_char":"El nombre del tablero es necessario y debe tener a lo menos 2 caracteres", 
"all.dashboard.dashboard_favorite_plural":"Tableros favoritos", 
"all.dashboard.dashboard_plural":"Tableros", 
"all.pdi.pdi":"PDI", 
"all.pdi.link_pdi_ref":"Referencia del PDI asociado", 
"all.pdi.pdi_ref":"Referencia de PDI", 
"all.pdi.link_location_pdi":"PDI asociados con el sitio", 
"all.pdi.dissociate_location_pdi":"PDI disociado del sitios", 
"all.pdi.go_to_pdi":"Ir al PDI", 
"all.pdi.state_pdi":"Estado del PDI", 
"all.pdi.add_location":"Añadir una ubicación GPS", 
"all.pdi.loading_location":"Cargando coordenadas GPS", 
"all.location.location":"Sitio", 
"all.location.location_plural":"Sitios", 
"all.location.address":"Direccion", 
"all.location.contact":"Contacto", 
"all.location.note":"$t(all.text.note)", 
"all.location_button.access_location":"Acceder al sitio", 
"all.location.location_info":"Informaciones del sitio", 
"all.location.pls_first_delete_child_location":"Primero elimine los sitios asociados", 
"all.location.delete_location":"Eliminar sitio", 
"all.round.location_follow_up":"Monitoreo del sitio", 
"all.round.round":"Ruta", 
"all.round.round_plural":"Rutas", 
"all.round.round_code":"Codigo ruta", 
"all.round.round_name":"Nombre de la ruta", 
"all.round.go_to_record":"Ir al archivo", 
"all.round.go_to_round":"Ir a la ruta", 
"all.round.assigned_round_plural":"Rutas asignadas", 
"all.round.round_info":"informacion de la ruta", 
"all.round.round_follow_up":"Seguimiento de la ruta", 
"all.round.round_mngmt":"Gestion de la ruta", 
"all.round.round_import":"Importar una ruta", 
"all.meter.serial":"Numero de serie", 
"all.meter.mfr":"Fabricante", 
"all.meter.meter_serial":"Numero de serie del medidor", 
"all.meter.radio_serial":"Numero de serie del radio", 
"all.meter.meter_serial_ref":"Numero de serie del medidor de referencia", 
"all.meter.meter_serial_control":"Numero de serie del medidor testigo", 
"all.meter.available_meter":"Medidor disponible", 
"all.meter.available_meter_plural":"Medidores disponibles", 
"all.meter.selection_explanation":"Seleccionará una lista finita de medidores", 
"all.meter.confirm_x_selection_explanation":"Seleccionará {{count}} medidor del sitio", 
"all.meter.confirm_x_selection_explanation_plural":"Seleccionarà los {{count}} medidores del sitio", 
"all.meter.selected_meter":"medidor seleccionado", 
"all.meter.selected_meter_plural":"medidores seleccionados", 
"all.meter.real_meter_diameter":"Diámetro real del metro", 
"all.meter.meter_model":"Modelo del medidor", 
"all.meter.meter_manufacturer":"Fabricante del medidor", 
"all.meter.meter_index":"Indice del medidor", 
"all.meter.index":"Indice", 
"all.meter.meter_ref":"Medidor de referencia", 
"all.meter.control_meter":"Medidor testigo", 
"all.meter.meter":"Medidor", 
"all.meter.meter_plural":"Medidores", 
"all.meter.indexed_meter":"Medidor indexado", 
"all.meter.indexed_meter_plural":"Medidores indexados", 
"all.meter.modified_meter":"Medidor modificado", 
"all.meter.modified_meter_plural":"Medidores modificados", 
"all.meter.all_meter_nb":"Numero total de medidores", 
"all.meter.virtual_meter_plural":"Medidores virtuales", 
"all.meter.pulse_weight":"Peso del pulso", 
"all.meter.wheels_number":"Numero de ruedas", 
"all.meter.add_virtual_meter":"Agregar medidor virtual", 
"all.meter.go_to_meter":"ir al archivo del medidor", 
"all.meter.export_data_from_valid":"$t(all.text.export_data_from) - Lecturas exitosas", 
"all.meter.edit_virtual_meter":"Modificar el medidor virtual", 
"all.meter.delete_virtual_meter":"Eliminar el medidor virtual", 
"all.meter.virtual_meter_edition":"Edicion del medidor virtual", 
"all.meter.virtual_meter_name":"Nombre del medidor virtual", 
"all.meter.linked_meter_plural":"medidores asociados", 
"all.meter.stack_index_plural":"Indice acumulativo", 
"all.meter.meter_to_x_plural":"Medidores {{mode}}", 
"all.meter.all_meter_dissociation_easy":"Los medidores se pueden disociar del sitio sin verificación", 
"all.meter.total_number_read_given_period":"Nombre total de compteurs géolocalisés captés sur la période donnée", 
"all.read_meter.read_last_date":"Fecha de la ultima lectura", 
"all.read_meter.read_previous_date":"Fecha de la lectura anterior", 
"all.read_meter.total_conso_in_day":"Consumo en un día", 
"all.read_meter.total_conso_in_period":"Consumo durante {{totalDays}} días", 
"all.read_meter.daily_avg_calc_info":"Para un periodo de {{totalDays}} días", 
"all.read_meter.daily_avg_cons_ratio":"Calculado sobre {{totalReads}} lecturas anteriores durante {{totalDays}} días", 
"all.read_meter.daily_avg_cons":"Media diaria", 
"all.read_meter.tendency":"Tendencia", 
"all.read_meter.cons":"Consumo", 
"all.read_meter.cons_gap":"Brecha de consumo", 
"all.read_meter.last_read":"Ultima lectura", 
"all.read_meter.cons_history":"Historia de consumo", 
"all.read_meter.no_current_read":"Sin lectura actual", 
"all.read_meter.read_history":"Historia de lectura", 
"all.read_meter.empty_cons":"Consumo vacio", 
"all.read_meter.high_cons":"Consumo fuerte", 
"all.read_meter.normal_cons":"Consumo normal", 
"all.read_meter.low_cons":"Consumo bajo", 
"all.read_meter.cons_comparison":"Comparacion de consumo", 
"all.read_meter.max_index":"Indice maximal", 
"all.read_meter.read_start_date":"Fecha de inicio de la lectura", 
"all.read_meter.read_method":"Metodo de lectura", 
"all.read_meter.cons_m3":"Consumo m<sup>3</sup>", 
"all.read_meter.cons_history_m3":"Historia de consumo (m<sup>3</sup>)", 
"all.read_meter.cons_history_m3_facturation":"Historia facturación de consumo (m<sup>3</sup>)", 
"all.read_meter.cons_history_m3_radio":"Historia radio de consumo (m<sup>3</sup>)", 
"all.read_meter.type.M":"Manual", 
"all.read_meter.type.A":"$t(all.radio.radio)", 
"all.read_meter.type.T":"Lectura remota", 
"all.read_meter.consumptions":"Consumos", 
"all.read_meter.read_meters":"Compteurs relevés", 
"all.read_meter.not_read_meters":"Compteurs non relevés", 
"all.alarm_meter.alarm":"Alarma", 
"all.alarm_meter.alarm_plural":"Alarmas", 
"all.alarm_meter.alarm_type":"Tipos de alarma", 
"all.alarm_meter.alarms_list":"Lista de alarmas", 
"all.alarm_meter.show_masked_alarm_plural":"Ver alarmas ocultas", 
"all.alarm_meter.alarm_history_plural":"Historia de las alarmas", 
"all.radio.radio":"Radio", 
"all.radio.radio_plural":"Radios", 
"all.radio.all_radio_nb":"Numero total de radios", 
"all.radio.current_radio":"Radio actual", 
"all.radio.go_to_radio":"Ir al radio", 
"all.radio.no_linked_radio":"Ningun radio asociado", 
"all.radio.radio_history":"Historia de radio", 
"all.radio.no_old_radio_linked":"No hay radio antigua asociada", 
"all.radio.radio_manufacturer":"Fabricante de radio", 
"all.radio.radio_model":"Modelo de radio", 
"all.radio.mount_date":"Date de montage ", 
"edit_device.field_label.phone_name":"Nombre del telefono", 
"edit_device.field_error_msg.name_required_min_2_char":"El nombre del telefono es obligatorio y debe tener más de 2 caracteres.", 
"edit_device.main_title.edit_phone":"Modificar un telefono", 
"edit_device.main_title.delete_phone":"Eliminar un telefono", 
"edit_device.text.code_max_2_char":"El código de facturacion no puede tener más de 2 caracteres", 
"edit_device.modal.text_confirm":"¿Seguro que quieres eliminar este móvil?", 
"edit_device.modal.text_warning_confirm":"¿Estás seguro de que quieres eliminar este dispositivo?", 
"edit_device.modal.text_warning_confirm_round":"¡Atención! Este teléfono móvil contiene actualmente una ruta", 
"edit_device.modal.text_warning_confirm_x_round":"¡Atención! Este teléfono móvil contiene actualmente {{count}} rutas", 
"gestionnaires.main_title.managers":"$t(all.text.manager_plural)", 
"gestionnaires.tree_view_placeholder.search_manager":"Buscar un gestionario", 
"import_constructeur.main_title.import_mfr":"$t(all.text.name)", 
"import_silex.main_title.import_silex":"$t(all.text.silex_import)", 
"import_silex.input_label.silex_file":"Archivo Silex", 
"import_silex.tab_label.file_import":"Importación de rutas", 
"import_silex.tab_label.facturation_import":"Importación de facturas", 
"import_silex.error_band.load_x_file_failed":"Error al cargar el archivo Silex {{fileName}}", 
"import_silex.loading_band.silex_file_loading":"Importación del archivo Silex actual", 
"import_silex.loading_band.import_facturation_loading":"Importación para facturación en curso", 
"import_silex.error_band.import_error":"Se ha producido un error durante la importación", 
"import_silex.error_band.facturation_unreachable":"Se ha producido un error al conectar con el servicio de facturación", 
"import_silex.submission_title.submission_file":"$t(all.text.submit_file)", 
"import_silex.button.admin_submit":"Enviar en admin", 
"import_silex.button.radio_file_submit":"Enviar archivo radio", 
"import_silex.success_msg.x_loaded_silex_file":"Carga del archivo Silex completa, {{count}} ruta importada", 
"import_silex.success_msg.x_loaded_silex_file_plural":"Carga del archivo Sliex completa, {{count}} rutas importadas", 
"import_silex.report_title.importation_report":"Informe de importación", 
"import_silex.column_header.round_code":"$t(all.round.round_code)", 
"import_silex.column_header.round_name":"$t(all.round.round_name)", 
"import_silex.column_header.round_status":"Estado", 
"import_silex.column_header.first_row":"Primera linea", 
"import_silex.column_header.last_row":"Ultima linea", 
"import_silex.status_text.already_exist":"$t(all.text.already_exist)", 
"import_silex.status_text.imported":"$t(all.text.imported)", 
"import_silex.error_band.already_exists_round":"La ruta {{round}} ya existe.", 
"import_silex.error_band.already_exists_rounds":"Los rutas {{rounds}} ya existen.", 
"import_silex.loading_band.analysis_in_progress":"Análisis del expediente actual", 
"import_silex.loading_band.import_in_progress":"Importación de \"{{label}}/{{code}}\",  ruta {{number}}/{{numberOfRound}}", 
"import_silex.loading_band.validating_import":"Validación de nuevos datos", 
"import_silex.facturation_import.title":"Importación desde facturación", 
"import_silex.facturation_import.button_label":"Iniciar importación", 
"list_adder.main_title.add_x_list":"Agregar una lista de {{type}}", 
"list_adder.modal_header.add_x_type":"Agregar {{type}}", 
"list_adder.modal_header.file_x_type":"Archivo {{type}}", 
"list_marquages.loading_band.loading_marking":"Importación de marcas actuales", 
"list_messages.loading_band.loading_msg":"Importacion de mensajes actuales", 
"list_message_add_file":"Ajouter un fichier", 
"list_message_no_file":"Aucun fichier choisi", 
"list_message_or":"OU", 
"list_message_manually":"Ajouter manuellement", 
"list_message_add":"Ajouter un", 
"synchronisation.loading_band.loading_rounds_":"Importación de rutas y teléfonos actuales", 
"synchronisation.option.partial_sync":"Sincronización parcial", 
"synchronisation.error_band.devices_not_connected":"Algunos dispositivos vinculados a la ruta no están conectados", 
"tournee_ressources.title_tab.rounds":"$t(all.round.round_plural)", 
"tournee_ressources.column_text.affected_phone":"Telefono(s) afectado(s)", 
"tournee_ressources.column_text.round_code":"$t(all.column_text.code)", 
"tournee_ressources.column_exported_tooltip.unload_round":"Ruta descargada", 
"tournee_ressources.column_exported_tooltip.load_round":"Rutas descargadas", 
"tournee_ressources.column_exported_tooltip.waiting_load":"En espera de la asignación", 
"tournee_ressources.column_exported_tooltip.unload_all":"Desafiar a todos", 
"tournee_ressources.column_exported_tooltip.waiting_unload":"En espera de desasignación", 
"tournee_ressources.column_exported_tooltip.exported_x_date":"Exportada el {{date}}", 
"tournee_ressources.column_exported_tooltip.never_exported":"Ruta nunca exportada", 
"tournee_ressources.column_exported_tooltip.exported_round":"Ruta exportada", 
"tournee_ressources.tooltip.delete_round":"Eliminar la ruta", 
"tournee_ressources.tooltip.export_round":"Exportar la ruta", 
"tournee_ressources.tooltip.access_round_detail":"Acceder a los detalles de la ruta", 
"tournee_ressources.popup_title.ask_delete_x_round":"eliminar la ruta {{roundName}} ?", 
"tournee_ressources.popup_title.some_round_cant_delete":"Algunas rutas seleccionadas no pueden borrarse", 
"tournee_ressources.deletable_rounds_confirmation":"¿Desea, no obstante, suprimir las siguientes rondas?", 
"tournee_ressources.no_round_can_be_deleted":"No se puede borrar ninguna de las rondas seleccionadas", 
"tournee_ressources.popup_title.ask_delete_x_round_plural":"Eliminar las rutas seleccionadas ?", 
"tournee_ressources.popup_msg.delete_x_round_data":"Esta acción irreversible eliminará todos los datos de la ruta {{roundName}}.", 
"tournee_ressources.popup_msg.delete_x_round_data_plural":"Esta acción irreversible eliminará todos los datos de las siguientes rutas: [{{roundName}}].", 
"tournee_ressources.popup_msg.delete_x_round_not_eligible":"La siguente ruta no se puede eliminar porque lo usa un telefono o no se exporta: {{roundNameNotEligible}}.", 
"tournee_ressources.popup_msg.delete_x_round_not_eligible_plural":"Las siguientes rutas no se pueden eliminar porque las usa un telefono o no se exportan: [{{roundNameNotEligible}}].", 
"tournee_ressources.column.avancement":"Avance", 
"synchro.command.listround":"Lecture des données depuis le terminal", 
"synchro.command.listdevice":"Connexion au portable", 
"synchro.command.uploadround":"Téléchargement des données depuis le terminal", 
"synchro.command.downloadround":"Chargement des données sur le terminal", 
"synchro.command.downloadroundresponse":"Fin du chargement des données", 
"synchro.command.closedevice":"Finalisation de la communication", 
"synchro.command.closedeviceresponse":"Fin de communication", 
"synchro.command.listroundresponse":"Analyse des données lues", 
"synchro.command.uploadroundresponse":"Intégration des données reçues", 
"synchro.command.listdeviceresponse":"Connexion au portable établie", 
"synchro.command.removeround":"Suppression des données", 
"synchro.command.removeroundresponse":"Suppression des données terminées", 
"synchro.command.downloadmark":"Chargements des marquages", 
"synchro.command.downloadmessage":"Chargements des messages", 
"synchro.command.busydevice":"En attente ...", 
"synchro.command.error":"Erreur", 
"synchro.command.downloadamrkey":"Chargement des clés AMR", 
"tournee_ressources.delete_round":"Eliminar ronda", 
"tournee_ressources.cannot_delete_round":"Imposible eliminar esta visita", 
"tournee_ressources.round_not_selected":"Esta ronda no fue seleccionada", 
"tournee_ressources.round_pending_affectation":"ronda pendiente de asignación", 
"tournee_ressources.round_pending_deaffectation":"ronda pendiente de desasignación", 
"tournee_ressources.round_modif_without_export":"ronda editada sin haber sido exportada", 
"tournee_ressources.round_used_by_devices":"ronda utilizada por los móviles", 
"tournee_ressources.round_never_exported":"esta ronda nunca fue exoportada", 
"tournee_ressources.deleting_round":"Supresión de la visita", 
"tournee_ressources.deleting_round_plural":"Supresión de varias visitas", 
"conflict_handling.change_type.meter_change":"Cambio de contador", 
"conflict_handling.title.singular":"Conflicto", 
"conflict_handling.title.plural":"Conflictos", 
"conflict_handling.title.neutral":"Conflicto(s)", 
"conflict_handling.associated_devices":"Teléfonos móviles asociados al conflicto", 
"conflict_handling.author":"Responsable de la gestión de este conflicto", 
"conflict_handling.handling.main_instruction":"Seleccione las correcciones que desea realizar en el recorrido", 
"conflict_handling.execution_time.delayed":"Aplicar en la próxima sincronización", 
"conflict_handling.execution_time.immediate":"Aplicar inmediatamente", 
"conflict_handling.no_conflict_handling":"Sin gestión de conflictos", 
"conflict_handling.loading":"Carga de conflictos en curso", 
"conflict_handling.no_conflict_handling_on_round":"No hay gestión de conflictos en esta ruta", 
"conflict_handling.conflict_open_button":"Haga clic aquí para saber más", 
"conflict_handling.conflict_in_round":"Conflicto en curso sobre ela ruta", 
"conflict_handling.conflict_in_round_and_device":"Conflicto(s) actual(es) entre esta ruta y el/los siguiente(s) móvil(es)", 
"conflict_handling.no_date":"No date", 
"conflict_handling.conflict_in_device":"Este portátil ha generado un conflicto", 
"tournee_detail.info_container.round_code":"$t(all.round.round_code)", 
"tournee_detail.info_container.round_msg":"Mensaje ruta", 
"tournee_detail.info_container.start_read_date":"$t(all.read_meter.read_start_date)", 
"tournee_detail.info_container.all_meter_nb":"$t(all.meter.all_meter_nb)", 
"tournee_detail.info_container.all_radio_nb":"$t(all.radio.all_radio_nb)", 
"tournee_detail.map_title.any_meter_gps":"Ningun medidor localizado", 
"tournee_detail.field_placeholder.write_note":"$t(all.text.write_note)", 
"tournee_detail.tooltip.edit_note":"Modificar un mensaje", 
"tournee_detail.main_title.round_name":"$t(all.round.round_name)", 
"tournee_fiche.main_title.record":"$t(all.text.record)", 
"tournee_fiche.main_title.record_plural":"$t(all.text.record_plural)", 
"tournee_fiche.tooltip.invalid_cache":"Invalidar caché", 
"tournee_fiche.loading_band.load_round_record":"Carga los archivos de la ruta", 
"tournee_stat.container_title.radio_type":"Tipo de radio AMR", 
"tournee_stat.container_title.radio_read_state":"Estado de lectura AMR", 
"tournee_stat.container_title.cons_state":"Estado de consumo", 
"tournee_stat.container_title.status_edited":"Estado de modificaciones", 
"tournee_stat.container_state_read.not_read":"AMR no leido", 
"tournee_stat.container_state_read.not_radio":"No AMR", 
"tournee_stat.container_state_read.read_with_alarm":"AMR leido con alarma", 
"tournee_stat.container_state_read.read_with_no_alarm":"AMR leido sin alarma", 
"tournee_stat.container_label.edited_not_indexed":"Modificado no indexado", 
"tournee_stat.container_label.edited_not_indexed_plural":"Modificados no indexados", 
"tournee_stat.container_label.not_edited_not_indexed":"No modificado/indexado", 
"tournee_stat.container_label.not_edited_not_indexed_plural":"No modificados/indexados", 
"tournee_stat.container_title.nb_meter_year":"Número de metros por año de instalación", 
"tournee_stat.container_title.nb_meter":"Numero de medidor(es)", 
"tournee_creation.container_label.template":"Creación de su plantilla de abonados/información sobre contadores", 
"tournee_creation.input_label.template_name":"Nombre de su plantilla", 
"tournee_creation.container_label.create_tournee":"Crear una ruta", 
"tournee_creation.container_label.template_list":"Lista de sus plantillas", 
"tournee_creation.container_label.tournee_informations":"Información sobre la ruta", 
"tournee_creation.container_label.tournee_template":"Plantilla de ruta", 
"tournee_creation.input_label.autorize_meter_change":"Autorizar el cambio de contador", 
"tournee_creation.dropzone.or_drop_file":"o suelte el archivo aquí", 
"tournee_creation.dropzone.drop_autorized":"¡Y suelta!", 
"tournee_creation.dropzone.drop_unautorized":"Sólo se puede importar un archivo cada vez", 
"tournee_creation.dropzone.incorrect_format":"Formato de archivo incorrecto. Formatos autorizados : {{fileformats}}", 
"tournee_creation.dropzone.authorized_format":"Formatos {{fileformats}}", 
"tournee_creation.button.start":"Inicio", 
"tournee_creation.input_label.line_x":"Línea {{lineNumber}}", 
"tournee_creation.input_label.information_on_default":"Si el modelo no genera ningún dato, un texto por defecto sustituirá la información.", 
"tournee_creation.placeholder.default_info":"No se generan datos", 
"tournee_creation.container_label.template_creation":"Creación de líneas", 
"tournee_creation.container_label.tag_creation":"Crear una etiqueta", 
"tournee_creation.container_label.tag_modification":"Crear una etiqueta", 
"tournee_creation.container_label.list_tag":"Lista de sus etiquetas", 
"tournee_creation.container_label.list_tag_subtitle":"Utiliza tus etiquetas para rellenar tus líneas", 
"tournee_creation.input_label.modifiable":"Modificable", 
"tournee_creation.input_label.unmodifiable":"No modificable", 
"tournee_creation.input_label.code_placeholder":"Introduzca el código de ruta", 
"tournee_creation.input_label.message_placeholder":"Escriba su mensaje", 
"tournee_creation.input_label.wheels_placeholder":"Número de ruedas (por defecto: 5)", 
"tournee_creation.input_label.pdi_template_placeholder":"Seleccione su plantilla PDI", 
"tournee_creation.input_label.meter_template_placeholder":"Seleccione su plantilla de contador", 
"tournee_creation.template_popover.title":"Supresión de plantillas", 
"tournee_creation.template_popover.confirm_text":"¿Está seguro de que desea eliminar esta plantilla?", 
"tournee_creation.input_error.empty_round_name":"Introduzca el nombre de la ruta", 
"tournee_creation.input_error.round_name_already_exists":"Este nombre de ruta ya existe", 
"tournee_creation.input_error.empty_round_code":"Introduzca el código de ruta", 
"tournee_creation.input_error.round_code_already_exists":"Este código de ruta ya existe", 
"tournee_creation.input_error.empty_template":"Seleccione una plantilla", 
"tournee_creation.input_error.round_wheels_too_high":"El número de ruedas debe estar entre 4 y 9", 
"tournee_creation.input_error.round_wheels_too_low":"$t(tournee_creation.input_error.round_wheels_too_high)", 
"tournee_creation.input_error.template_name_already_exists":"Este nombre de plantilla ya existe", 
"tournee_creation.input_error.empty_template_name":"Introduzca un nombre para la plantilla", 
"tournee_creation.input_error.template_name_too_long":"El nombre de la plantilla es demasiado largo. Máximo: 30 caracteres", 
"tournee_creation.input_error.line_too_long":"El número teórico de caracteres excede el máximo de 30, el exceso será ignorado", 
"tournee_creation.input_error.default_info_too_long":"El texto por defecto es demasiado largo. Máximo: 30 caracteres", 
"tournee_creation.input_error.empty_tag_name":"Introduzca el nombre de la etiqueta", 
"tournee_creation.input_error.tag_name_already_exists":"Nombre de una etiqueta existente", 
"tournee_creation.input_error.tag_name_is_too_long":"El nombre de la etiqueta es demasiado largo. Máximo: 16 caracteres", 
"tournee_creation.input_error.empty_tag_info":"Seleccione la información que desea visualizar", 
"tournee_creation.input_error.empty_tag_size":"Seleccione el tamaño de la etiqueta", 
"tournee_creation.input_error.tag_size_too_high":"El tamaño de la etiqueta debe estar comprendido entre 1 y 30", 
"tournee_creation.input_error.tag_size_too_low":"$t(tournee_creation.input_error.tag_size_too_high)", 
"tournee_creation.input_label.type_text":"$t(enquete.field_type.A)", 
"tournee_creation.input_label.type_number":"Número", 
"tournee_creation.input_label.type_date":"$t(all.date.date)", 
"tournee_creation.success.templateCreation":"Plantilla creada {{successTarget}}", 
"tournee_creation.success.templateUpdate":"Plantilla actualizada {{successTarget}}", 
"tournee_creation.success.templateDeletion":"Plantilla suprimida {{successTarget}}", 
"tournee_creation.success.tagCreation":"Etiqueta creada {{successTarget}}", 
"tournee_creation.success.tagUpdate":"Etiqueta actualizada {{successTarget}}", 
"tournee_creation.success.tagDeletion":"Etiqueta suprimida {{successTarget}}", 
"tournee_creation.success.roundCreation":"Ruta creada {{tourneeName}}", 
"tournee_creation.loading_band.template_creation":"Plantilla que se está creando", 
"tournee_creation.loading_band.template_update":"Plantilla modificada", 
"tournee_creation.loading_band.template_deletion":"Plantilla eliminada", 
"tournee_creation.loading_band.tag_creation":"Etiqueta que se está creando", 
"tournee_creation.loading_band.tag_update":"Etiqueta modificada", 
"tournee_creation.loading_band.tag_deletion":"Etiqueta eliminada", 
"tournee_creation.loading_band.tournee_creation":"Ruta en curso", 
"tournee_creation.error_band.tag_not_found":"Etiqueta {{tag}} no encontrada", 
"tournee_creation.error_band.tag_still_linked":"No se puede eliminar la etiqueta {{tag}}. La etiqueta sigue vinculada a las siguientes plantillas : {{templates}}", 
"tournee_creation.error_band.errors_in_tag":"Se han encontrado errores en la etiqueta enviada : {{errors}}", 
"tournee_creation.error_band.template_not_found":"Plantilla {{template}} no encontrada", 
"tournee_creation.error_band.errors_in_template":"Se han encontrado errores en la plantilla enviada : {{errors}}", 
"tournee_creation.error_band.tag_in_use":"No se puede eliminar la etiqueta {{tag}}. Etiqueta en uso.", 
"tournee_creation.error_band.errors_in_tournee":"Se han encontrado errores en la ruta enviada : {{errors}}", 
"tournee_creation.error_band.template_still_linked":"No se puede eliminar la plantilla {{template}}. La plantilla sigue vinculada a la siguiente ruta : {{rndList}}", 
"tournee_creation.input_error.tournee_name_is_empty":"El nombre de la ruta está vacío", 
"tournee_creation.input_error.tournee_name_already_exists":"El nombre de la ruta ya existe", 
"tournee_creation.input_error.tournee_code_is_empty":"El código de ruta está vacío", 
"tournee_creation.input_error.tournee_default_wheels_too_low":"El número de ruedas por defecto es inferior a 4", 
"tournee_creation.input_error.tournee_default_wheels_too_high":"El número de ruedas por defecto es superior a 9", 
"tournee_creation.input_error.tournee_collect_point_template_is_missing":"Plantilla de abonado no rellenada", 
"tournee_creation.input_error.tournee_meter_template_is_missing":"Plantilla de contador no rellenada", 
"tournee_creation.input_error.tournee_meter_list_is_empty":"La lista del contador está vacía", 
"tournee_creation.input_error.tournee_meter_list_size_over_limit":"El número de contadores es superior a 9999", 
"tournee_creation.input_info.remaining_characters_plural":"{{nbRemainingChar}} caracteres restantes", 
"tournee_creation.input_info.remaining_characters":"{{nbRemainingChar}} caractere restante", 
"tournee_creation.popover.tag_too_long":"No se puede añadir la etiqueta. El tamaño de la etiqueta excede el número de caracteres restantes en la línea.", 
"create_meter.main_title.create_meter":"Crear un medidor", 
"create_meter.field_validate.serial_least_5_char":"El numero de serie debe contener al menos 5 caracteres", 
"create_meter.field_validate.serial_max_20_char":"El numero de serie debe contener  al maximo 20 caracteres", 
"create_meter.field_validate.serial_valid":"Ingrese un número de medidor válido", 
"create_meter.field_error_msg.need_serial":"El numero de serie debe estar ingresado", 
"create_meter.success_band.meter_created":"El medidor ha sido creado", 
"create_pdi.main_title.create_pdi":"Crear un PDI", 
"location_address_chooser.loading_band.search_gps":"Buscando coordenadas GPS ...", 
"location_address_chooser.link_container_title.link_meter":"Asociar un medidor", 
"location_address_chooser.success_band.pdi_created":"El PDI se creo", 
"location_address_chooser.warning_message.format_city_name":"Le nom de la ville ne doit pas contenir de chiffres ou de caractères spéciaux", 
"location_address_chooser.warning_message.format_streetnumber_name":"Le numéro de la voie doit contenir exclusivement des chiffres", 
"location_address_chooser.warning_message.format_zipcode_name":"Le code postal doit contenir 5 chiffres", 
"stock_importation.text.launch_by":"Lanzado por", 
"stock_importation.main_title.import_billing":"$t(all.text.import_billing)", 
"stock_importation.input_file_label.import_file":"Importar archivo (.csv, .tsv, .xls, .xlsx)", 
"stock_importation.step_1_loading_band.send_file":"Enviando archivo...", 
"stock_importation.step_2_loading_band.check_file":"Verificando archivo...", 
"stock_importation.step_3_success_band.integration_x_row_and_x_modified":"La integración se completó correctamente. {{NbRow}} lineas procesadas, incluido {{count}} modificado", 
"stock_importation.step_3_success_band.nb_meter_archived":"{{nbRow}} PDI archivado", 
"stock_importation.step_3_success_band.integration_x_row_and_x_modified_plural":"La integración se completó correctamente. {{NbRow}} lineas procesadas, incluido {{count}} modificados", 
"stock_importation.error.serial_too_long":"El número de serie {{elemento}} tiene más de 12 caracteres", 
"stock_importation.error.wheels_wrong_value":"El número de ruedas {{elemento}} no está entre 4 y 9", 
"stock_importation.error.date_too_old":"La fecha {{elemento}} es anterior al 01/01/1970", 
"stock_importation.error.index_too_long":"El tamaño del índice {{element}} es superior a 9", 
"stock_importation.error.depose_date_expected":"Falta la fecha de retirada del antiguo contador", 
"stock_importation.error.serial_number_missing":"Falta el número de serie del antiguo contador", 
"stock_importation.error.depose_index_expected":"Falta el índice de retirada del contador antiguo", 
"stock_importation.importation_report.action_meter_change":"Cambio de contador", 
"stock_importation.importation_report.action_creation":"Creación", 
"stock_importation.importation_report.action_unauthorized":"Cambio rechazado", 
"stock_importation.importation_report.action_modification":"Modificación", 
"stock_importation.importation_report.detail_address":"Direccion", 
"stock_importation.importation_report.detail_start_date":"Fecha de inicio", 
"stock_importation.importation_report.detail_meter_serial":"Numero de serie del medidor", 
"stock_importation.importation_report.detail_notes":"Notas", 
"stock_importation.importation_report.detail_custom_info":"Información personalizada", 
"stock_importation.importation_report.detail_state":"Estado", 
"stock_importation.importation_report.detail_revised_wheels":"Número de ruedas", 
"stock_importation.importation_report.detail_meter_model":"Modelo de contador", 
"stock_importation.importation_report.detail_empty":"Vacío", 
"stock_importation.importation_report.download_button":"Descargar el informe", 
"stock_importation.importation_report.download_loading":"Envío del informe actual", 
"stock_importation.importation_report.download_error":"Se ha producido un error al enviar el archivo", 
"stock_importation.importation_report.detail_gps_longitude":"Coordenadas GPS - Longitude", 
"stock_importation.importation_report.detail_gps_latitude":"Coordenadas GPS - Latitud", 
"stock_importation.importation_report.detail_gps_altitude":"Coordenadas GPS - Altitud", 
"stock_importation.importation_report.detail_gps_score":"Coordenadas GPS - Puntuación", 
"stock_importation.importation_report.detail_gps_accuracy":"Coordenadas GPS - Precisión", 
"stock_importation.error.not_match_config":"El archivo no coincide con la configuración", 
"import.loading_band.import_already_loading":"Ya se está procesando una importación", 
"import.step_1_title.first_step_load_file":"Primer paso: cargar el archivo", 
"import.step_1_success_band.load_file":"Carga del archivo realizada", 
"import.step_1_loading_band.file_analysis":"Verificando archivo ...", 
"import.step_2_title.second_step_check_error":"Segundo paso : Verificar errores en el archivo", 
"import.list.x_row":"Linea {{row}},    PDI {{pdi}},    Numero de serie {{serial}}", 
"import.step.warning":"Aviso :", 
"import.step_2_help.check_error_for_import":"Verifique los errores anteriores para probar la integración", 
"import.step_2_button.test_integration":"Pruebar la interogacion", 
"import.step_2_success_band.test_integration":"Prueba de integración realizada", 
"import.step_3_title.third_step_check_integration":"Paso tres : Comprobando problemas de integración", 
"import.step_error.error":"Errores", 
"import.step_3_help.check_error_for_finish_import":"Verifique los errores anteriores parar completar la integracion", 
"import.step_3_loading_band.integration_file":"Integracion del archivo...", 
"import.step_4_title.integration_report":"Informe de integracion", 
"import.anomaly_text.emptyMeterSerial":"Numero de serie del medidor vacio", 
"import.anomaly_text.dateLineError":"Fecha invalida (debajo de 1970)", 
"import.anomaly_text.emptyPDIReference":"Referencia PDI vacia", 
"import.anomaly_text.doublonMeter":"Numero de serie del medidor en duplicado", 
"import.anomaly_text.doublonPdi":"Referencia PDI en duplicado", 
"import.anomaly_text.unfoundModel":"Modelo de medidor no encontrado", 
"import.anomaly_text.datePoseBeforeActual":"Fecha de instalacion del archivo anterior a la fecha de instalación en base", 
"import.anomaly_text.collectPointUsedByRound":"PDI con cambio de medidor pero en proceso de lectura", 
"import.anomaly_text.meterUsedByRound":"Medidor con cambio de PDI pero en proceso de lectura", 
"import.anomaly_text.doublons":"Duplicado", 
"import.anomaly_text.doublonsMeter":"Contadores duplicados", 
"import.anomaly_text.doublonsPdi":"PDI duplicados", 
"import.anomaly_text.field_with_date_inf_1970":"Campo con fecha inválida", 
"import.anomaly_text.datePoseNull":"Fecha de colocación vacía", 
"import_component.input_file_label.import_file":"Importar el archivo", 
"import_component.step_2_loading_band.testing_file":"Probando el archivo ...", 
"import_component.step_3_success_band.integration_x_row_success":"Integracion exitoso. {{count}} linea procesada.", 
"import_component.step_3_success_band.integration_x_row_success_plural":"Integracionn exitoso. {{count}} lineas procesadas", 
"alert_content.alarm_title.alarm_mfr":"Alarmas fabricante", 
"alert_content.alarm_title.read_gap":"Brecha de lectura", 
"alert_content.alarm_title.real_cons":"Consumo real", 
"alert_content.alarm_title.cons_avg":"Consumo medio", 
"alert_content.alarm_title.cons_comparison":"$t(all.read_meter.cons_comparison)", 
"alert_content.alarm_title.cons_gap":"$t(all.read_meter.cons_gap)", 
"alert_content.alarm_title.cons_alert":"Alertas de consumo", 
"alert_content.alarm_text.alarm_meter_since_integration":"Alarma de medidor (desde la ultima integracion)", 
"alert_content.alarm_text.cons_of_period":"Consumo durante el período predefinido", 
"alert_content.alarm_text.read_gap_today":"Desviación de lectura de la fecha de hoy", 
"alert_content.alarm_text.cons_limit_since_integration":"Límite de consumo mínimo y / o máximo (m3) para todos los consumos desde la última integración", 
"alert_content.alarm_text.m3_cons_interval_of_cons_avg":"Intervalo de consumo mínimo y / o máximo (en m3) entre el consumo medio actual y el último consumo medio (desde la última integración)", 
"alert_content.alarm_text.cons_interval_of_two_meter":" Intervalo de consumo máximo (en porcentaje) entre el último consumo del medidor 1 y el medidor 2 (desde la última integración)", 
"alert_content.alarm_text.pct_cons_interval_of_cons_avg":"Intervalo de consumo mínimo y / o máximo (en porcentaje) entre el consumo medio y el consumo medio periódico (para el último mes / semana / día)", 
"alert_content.alarm_result.result":"$t(all.text.result)", 
"alert_content.alarm_result.x_cons_gap":"Diferencia de consumo : {{gapCons}}", 
"alert_content.meter_type.ref":"$t(all.text.reference)", 
"alert_content.meter_type.witness":"Testigo", 
"alert_content.table_title.alert_meter_list_and_info":"Aquí está la lista de medidores en alerta, así como la información asociada", 
"alert_content.table_title.alert_virtual_meter_list_and_info":"Aquí está la lista de medidores virtuales en alerta, así como la información asociada", 
"alert_content.warning.no_alert_found":"No se notó ninguna alerta durante este período", 
"alert_content.column_text.gap_last_date":"Diferencia desde la ultima fecha", 
"alert_content.column_text.to_date":"Hasta", 
"alert_content.column_text.since_date":"Desde", 
"alert_content.column_text.ref_cons":"Consumo de referencia", 
"alert_content.column_text.avg_day_cons":"Consumo medio por dia", 
"alert_content.column_text.cons_gap":"$t(all.read_meter.cons_gap)", 
"alert_content.column_text.read_date":"Fecha de la lectura", 
"alert_creator.name.rangeconsumption":"$t(all.read_meter.cons)", 
"alert_creator.name.gapconsumption":"$t(all.read_meter.cons_gap)", 
"alert_creator.name.gapconsumptioncomparison":"$t(all.read_meter.cons_comparison)", 
"alert_creator.name.alarms":"$t(alert_content.alarm_title.alarm_mfr)", 
"alert_creator.name.averagerangeconsumption":"$t(alert_content.alarm_title.cons_avg)", 
"alert_creator.name.rangerealconsumption":"$t(alert_content.alarm_title.real_cons)", 
"alert_creator.name.noreadformeteralert":"Retraso en la lectura del medidor", 
"alert_creator.name.consumptionhour":"Consumo durante periodo", 
"alert_creator.extra_info.rangeconsumption":"Límite de consumo mínimo y / o máximo (m3) para el consumo medio periódico (para el último mes / semana / día)", 
"alert_creator.extra_info.gapconsumption":"$t(alert_content.alarm_text.pct_cons_interval_of_cons_avg)", 
"alert_creator.extra_info.gapconsumptioncomparison":"$t(alert_content.alarm_text.cons_interval_of_two_meter)", 
"alert_creator.extra_info.alarms":"$t(alert_content.alarm_text.alarm_meter_since_integration)", 
"alert_creator.extra_info.averagerangeconsumption":"$t(alert_content.alarm_text.m3_cons_interval_of_cons_avg)", 
"alert_creator.extra_info.rangerealconsumption":"$t(alert_content.alarm_text.cons_limit_since_integration)", 
"alert_creator.extra_info.noreadformeteralert":"La ultima lectura anterior a la fecha de hoy menos la distancia del zoom", 
"alert_creator.extra_info.consumptionhour":"Se detectaron consumos durante un horario predefinido", 
"alert_creator.unit.number":"Alarmas cantidad", 
"alert_creator.unit.m3":"m3", 
"alert_creator.unit.percent":"Porcentaje", 
"alert_creator.unit.time":"Tiempo", 
"best_date_component.label.start_date":"$t(all.text.start_date)", 
"best_date_component.label.end_date":"$t(all.text.end_date)", 
"best_date_component.label.zoom":"$t(all.text.zoom)", 
"best_date_component.zoom_option.hour":"Hora", 
"best_date_component.zoom_option.day":"Dia", 
"best_date_component.zoom_option.week":"Semana", 
"best_date_component.zoom_option.month":"Mes", 
"best_date_component.zoom_option.year":"Ano", 
"best_date_component.text.custom":"Personalizado", 
"best_date_component.error":"La fecha de inicio debe ser anterior a la fecha de finalización", 
"bread_crumb.name.crystal_cloud":"CrystalCloud", 
"bread_crumb.name.location":"$t(all.location.location)", 
"bread_crumb.name.location_plural":"$t(all.location.location_plural)", 
"bread_crumb.name.masks":"$t(all.mask.mask_plural)", 
"bread_crumb.name.dashboard":"Panel de control", 
"bread_crumb.name.dashboard_plural":"$t(all.dashboard.dashboard_plural)", 
"bread_crumb.name.user":"$t(all.user.user)", 
"bread_crumb.name.user_plural":"$t(all.user.user_plural)", 
"bread_crumb.name.widget":"Widget", 
"bread_crumb.name.widget_plural":"$t(all.text.widgets)", 
"bread_crumb.name.meter":"$t(all.meter.meter)", 
"bread_crumb.name.meter_plural":"$t(all.meter.meter_plural)", 
"bread_crumb.name.virtual_meter":"$t(all.text.virtual_meter)", 
"bread_crumb.name.virtual_meter_plural":"$t(all.meter.virtual_meter_plural)", 
"bread_crumb.name.radio":"$t(all.radio.radio)", 
"bread_crumb.name.radio_plural":"$t(all.radio.radio_plural)", 
"bread_crumb.name.pdi":"$t(all.pdi.pdi)", 
"bread_crumb.name.pdi_plural":"PDIs", 
"bread_crumb.name.new":"$t(all.text.new)", 
"bread_crumb.name.search":"$t(all.button.search)", 
"bread_crumb.name.notif":"$t(all.text.notif)", 
"bread_crumb.name.notif_plural":"$t(all.text.notif_plural)", 
"bread_crumb.name.alert":"$t(all.alert.alert)", 
"bread_crumb.name.alert_plural":"$t(all.alert.alert_plural)", 
"bread_crumb.name.detail":"Detalle", 
"bread_crumb.name.detail_plural":"$t(all.text.detail_plural)", 
"bread_crumb.name.report":"Informe", 
"bread_crumb.name.report_plural":"Informes", 
"bread_crumb.name.stock":"$t(all.text.stock)", 
"bread_crumb.name.create_pdi":"Creacion PDI", 
"bread_crumb.name.create_meter":"Creacion medidor", 
"bread_crumb.name.billing_import":"$t(all.text.import_billing)", 
"bread_crumb.name.link_pdi":"Asociación del PDI", 
"bread_crumb.name.unlink_pdi":"Disociación del PDI", 
"bread_crumb.name.synchro":"$t(all.text.synchro)", 
"bread_crumb.name.round":"$t(all.round.round)", 
"bread_crumb.name.round_plural":"$t(all.round.round_plural)", 
"bread_crumb.name.record":"$t(all.text.record)", 
"bread_crumb.name.record_plural":"$t(all.text.record_plural)", 
"bread_crumb.name.phone":"$t(all.text.phone)", 
"bread_crumb.name.phone_plural":"$t(all.text.phone_plural)", 
"bread_crumb.name.import_mfr":"$t(all.text.import_mfr)", 
"bread_crumb.name.silex_import":"$t(all.text.silex_import)", 
"bread_crumb.name.msg_list":"Lista de mensajes", 
"bread_crumb.name.marking_list":"Lista de marcas", 
"bread_crumb.name.manager":"$t(all.text.manager)", 
"bread_crumb.name.manager_plural":"$t(all.text.manager_plural)", 
"bread_crumb.name.export":"$t(all.text.name.export)", 
"bread_crumb.name.stat":"Estadistica", 
"bread_crumb.name.stat_plural":"$t(all.text.stat_plural)", 
"bread_crumb.name.forbidden_access":"$t(all.text.forbidden_access)", 
"bread_crumb.name.profile":"$t(all.user.profile)", 
"bread_crumb.name.profile_plural":"$t(all.user.profile_plural)", 
"bread_crumb.name.edit_x_virtual_meter":"Edicion de {{virtualMeterName}}", 
"bread_crumb.name.edit_x_alarm":"Edicion de {{alarmName}}", 
"bread_crumb.name.edit_x_mask_alarm":"Edicion de {{maskAlarmName}}", 
"bread_crumb.name.edit_x_custom_export":"Edicion de  {{customExport}}", 
"bread_crumb.name.personal_dashboard_of_x":"Tablero personal de {{userName}}", 
"bread_crumb.name.telereleve":"$t(all.read_meter.type.T)", 
"bread_crumb.name.provisionning":"Provisionning", 
"bread_crumb.name.support":"Soporte", 
"bread_crumb.name.sync":"$t(all.text.synchro)", 
"bread_crumb.name.remotereading":"$t(all.read_meter.type.T)", 
"bread_crumb.name.synchrotools":"Synchro mode tools", 
"bread_crumb.name.importbilling":"$t(all.text.import_billing)", 
"bread_crumb.name.export_brut":"Exportacion bruta", 
"bread_crumb.name.new_tournee":"$t(sidebar_synchro.nav_link.new_round)", 
"bread_crumb.name.create_tournee":"Creación de ruta", 
"bread_crumb.name.templates":"Plantillas", 
"confirm.msg.you_sure":"Està seguro?", 
"confirm.title.warning":"Aviso!", 
"confirm.confirm_text.ok":"$t(all.text.ok)", 
"error_boundary.main_title.cannot_show_page":"La página no se pudo mostrar correctamente.", 
"error_boundary.data":"Se ha detectado un conflicto de datos", 
"forbidden.main_title.not_authorized":"$t(all.text.forbidden_access)", 
"help_modal.button_send.send_msg":"Enviar un mensaje", 
"help_modal.main_title.send_msg_to_support":"Enviar un mensaje al soporte", 
"help_modal.success_msg.msg_sent_thanks":"Correo enviado, gracias por tu mensaje.", 
"help_modal.field_label.msg_subject":"Asunto del mensaje", 
"help_modal.field_required.min_size_10_char":"El campo requiere al menos 10 caracteres", 
"help_modal.field_subject_error.max_size_10_char":"El asunto del mensaje debe tener al menos 10 caracteres.", 
"help_modal.field_msg_error.max_size_10_char":"Por favor complete el cuadro de mensaje", 
"not_found.main_title.not_found":"404 Pagina no encontrada", 
"notification_modal.tooltip_notif.notif":"$t(all.text.notif)", 
"notification_modal.tooltip_notif.notif_plural":"$t(all.text.notif_plural)", 
"notification_modal.tooltip_notif.x_notif_not_read":"Notificaciones : {{count}} no leido", 
"notification_modal.tooltip_notif.x_notif_not_read_plural":"Notificaciones :  {{count}} no leidos", 
"search.main_title_label.fast_search":"Busqueda rapida", 
"search.main_title.general":"General", 
"search.input_search.serial_or_address":"Numero de serie o direccion", 
"search.info_tooltip.search_in_pdi_meter_radio":"Búsqueda en PDI, medidores y radio (mínimo 3 caracteres)", 
"search.search_result.result_of_search":"Resultados de la búsqueda", 
"search.search_result.x_pdi":"PDI: {{pdiResult}}", 
"search.search_result.x_meter":"Medidor: {{meterResult}}", 
"search.search_result.x_meter_plural":"Medidores: {{meterResult}}", 
"search.search_result.x_radio":"Radio: {{radioResult}}", 
"search.search_result.x_radio_plural":"Radio: {{radioResult}}", 
"search.info_title.general_info":"Informacion general", 
"search.info_title.general_info_plural":"Informaciones generales", 
"search.record_access_button.go_to_record":"$t(all.round.go_to_record)", 
"sidebar.nav_link.home":"Hogar", 
"sidebar.nav_link.location":"$t(all.location.location)", 
"sidebar.nav_link.location_plural":"$t(all.location.location_plural)", 
"sidebar.nav_link.round":"$t(all.round.round)", 
"sidebar.nav_link.round_plural":"$t(all.round.round_plural)", 
"sidebar.nav_link.profile_manager":"Gestion de perfile", 
"sidebar.nav_link.profile_manager_plural":"Gestion de perfiles", 
"sidebar.nav_link.user_manager":"Gestion del usario", 
"sidebar.nav_link.user_manager_plural":"Gestion de los usarios", 
"sidebar.nav_link.my_info_plural":"$t(all.user.my_info_plural)", 
"sidebar.nav_link.info_plural":"$t(all.text.information_plural)", 
"sidebar.nav_link.import_billing":"$t(all.text.import_billing)", 
"sidebar.nav_link.global_search":"Busqueda global", 
"sidebar.nav_link.lora_import":"$t(all.text.lora_import)", 
"sidebar.nav_link.installer_manager":"Gestion de los instaladores", 
"sidebar_location.nav_link.round_detail_plural":"Detalles de la ruta", 
"sidebar_location.nav_link.location_detail_plural":"Detalles del sitio", 
"sidebar_location.nav_link.record_plural":"$t(all.text.record_plural)", 
"sidebar_location.nav_link.stat_plural":"$t(all.text.stat_plural)", 
"sidebar_location.nav_link.dashboard_plural":"$t(all.dashboard.dashboard_plural)", 
"sidebar_location.nav_link.alert_plural":"$t(all.alert.alert_plural)", 
"sidebar_location.nav_link.mask_alarm_plural":"Mascaras de alarmas", 
"sidebar_location.nav_link.meter_plural":"$t(all.meter.meter_plural)", 
"sidebar_location.nav_link.virtual_meter_plural":"$t(all.meter.virtual_meter_plural)", 
"sidebar_location.nav_link.radio":"$t(all.radio.radio)", 
"sidebar_location.nav_link.pdi":"$t(all.pdi.pdi)", 
"sidebar_location.nav_link.search":"$t(all.button.search)", 
"sidebar_location.nav_link.export":"$t(all.text.name.export)", 
"sidebar_stock.nav_title.stock":"$t(all.text.stock)", 
"sidebar_stock.nav_link.resources_list":"$t(all.text.resource_list)", 
"sidebar_stock.nav_link.create_meter":"Creacion medidor", 
"sidebar_stock.nav_link.create_pdi":"Creacion PDI", 
"sidebar_synchro.nav_title.synchro":"$t(all.text.synchro)", 
"sidebar_synchro.nav_link.round":"$t(all.round.round)", 
"sidebar_synchro.nav_link.round_plural":"$t(all.round.round_plural)", 
"sidebar_synchro.nav_link.phone":"$t(all.text.phone)", 
"sidebar_synchro.nav_link.phone_plural":"$t(all.text.phone_plural)", 
"sidebar_synchro.nav_link.import_mfr":"$t(all.text.import_mfr)", 
"sidebar_synchro.nav_link.msg_list":"Lista de mensajes", 
"sidebar_synchro.nav_link.marking_list":"Lista de marcas", 
"sidebar_synchro.nav_link.silex_import":"$t(all.text.silex_import)", 
"sidebar_synchro.nav_link.enquiry":"Encuesta", 
"sidebar_synchro.nav_link.enquiry_plural":"Encuestas", 
"sidebar_synchro.nav_link.new_round":"Nueva ruta", 
"sidebar_tournees.nav_link.round_detail":"Detalles de la ruta", 
"sidebar_tournees.nav_link.record_plural":"$t(all.text.record_plural)", 
"sidebar_tournees.nav_link.stat_plural":"$t(all.text.stat_plural)", 
"single_gmap.loading_band.loading_map":"Carga de el mapa", 
"single_gmap.failure_text.not_able_show_map":"no se puede mostrar el mapa", 
"single_gmap.map_setting.language":"esp", 
"single_gmap.text.no_radio":"$t(all.text.none_male)", 
"tableau_gestion.menu_option.add":"Agregar", 
"tableau_gestion.menu_option.unlink":"$t(all.text.unlink)", 
"tableau_gestion.menu_option.link":"$t(all.text.link)", 
"tableau_gestion.menu_option.alarm_plural":"$t(all.alarm_meter.alarm_plural)", 
"tableau_gestion.loading_band.exporting":"Exportación en curso ...", 
"tableau_gestion.error_band.exporting":"Imposible exportar datos", 
"tournee_navbar.nav_tooltip.synchro":"$t(all.text.unlink)", 
"tournee_navbar.nav_tooltip.stock":"$t(all.text.link)", 
"tournee_navbar.nav_tooltip.dashboard_favorite_plural":"$t(all.alarm_meter.alarm_plural)", 
"tournee_navbar.nav_tooltip.my_info_plural":"Mis informaciones", 
"tournee_navbar.nav_tooltip.disconnect":"$t(all.user.logout)", 
"edit_dashboard.main_title.edit_dashboard":"Modificar un tablero", 
"edit_dashboard.name_field_label.dashboard_name":"$t(all.dashboard.dashboard_name)", 
"edit_dashboard.name_field_error_msg.name_need_least_2_char":"$t(all.dashboard.name_need_least_2_char)", 
"locations.loading_band.loading_location_info":"Cargando las informaciones del sitio", 
"locations.button.access_round":"$t(all.round.go_to_round)", 
"locations.error_msg.no_location_found":"Sin coincidencia", 
"locations.placeholder.search_location":"Buscar un sitio", 
"locations.placeholder.search_rounds":"Buscar una ruta", 
"locations.title.all_rounds":"Todas", 
"locations.text.total_nb_meter":"$t(all.meter.all_meter_nb)", 
"locations.text.total_nb_sensor":"Numero total de sensores", 
"locations.text.total_nb_radio":"$t(all.radio.all_radio_nb)", 
"locations.text.no_sub_location":"Ningun subsitio", 
"dashboard_helper.dashboard_name.my_dashboard":"Mi tablero", 
"dashboard_helper.dashboard_name.dashboard_of_x":"{{userName}} tablero", 
"fiche_detail.label.detail_plural":"$t(all.text.detail_plural)", 
"fiche_detail.label.diameter":"Diametro", 
"fiche_detail.label.install_year":"Ano de instalacion", 
"fiche_detail.label.wheel_nb":"$t(all.meter.wheels_number)", 
"fiche_detail.label.revision":"Revision", 
"fiche_detail.label.mfr":"$t(all.meter.mfr)", 
"fiche_detail.label.model":"$t(all.text.model)", 
"fiche_detail.label.msg":"$t(all.text.message)", 
"fiche_detail.label.particular_msg":"$t(all.text.particular_msg)", 
"fiche_detail.title.new_meter":"Nuevo medidor", 
"fiche_detail.title.old_meter":"Medidor antiguo", 
"fiche_detail.title.old_meter_plural":"medidores antiguos", 
"fiche_detail.label.show_old_meter":"Ver medidor antiguo", 
"fiche_detail.label.show_new_meter":"Ver nuevo medidor", 
"fiche_detail.title.meter":"$t(all.meter.meter)", 
"fiche_detail.title.revise_meter":"Medidor revisado", 
"fiche_detail.title.install_read":"Instalar indice", 
"fiche_detail.title.uninstall_read":"$t(columns.previousMeter.deposeIndex)", 
"fiche_detail.title.cons_m3":"$t(all.read_meter.cons_m3)", 
"fiche_detail.title.avg":"$t(all.text.average)", 
"fiche_detail.label.radio":"$t(all.radio.radio)", 
"fiche_detail.label.new_radio":"Radio nuevo", 
"fiche_detail.label.old_radio":"Radio antiguo", 
"fiche_detail.label.show_old_radio":"Ver radio antiguo", 
"fiche_detail.label.show_new_radio":"Ver radio nuevo", 
"fiche_detail.label.internal_id":"identificador interno", 
"fiche_detail.label.install_date":"$t(all.text.install_date)", 
"fiche_detail.label.pulse_weight":"$t(all.meter.pulse_weight)", 
"fiche_detail.error_msg.no_radio":"Ningun radio", 
"fiche_detail.title.historical_alarm_plural":"$t(all.alarm_meter.alarm_history_plural)", 
"fiche_detail.title.previous_alarm_plural":"Alarmas anteriores", 
"fiche_detail.title.current_alarm_plural":"Alarmas actuales", 
"fiche_detail.title.record_number":"Número de registro", 
"fiche_detail.label.marking":"$t(all.text.marquage)", 
"fiche_detail.label.last_update":"$t(all.text.update_date)", 
"fiche_detail.title.no_gps":"sin coordenadas GPS", 
"fiche_detail.title.historical_cons":"Historial de consumo (in m<1>3</1>)", 
"fiche_detail.title.start_index":"Indice de inicio", 
"fiche_detail.title.closest_reference_index":"Indice de referencia", 
"fiche_detail.title.previous_index":"Indice anterior", 
"fiche_detail.title.end_index":"Indice final", 
"fiche_detail.title.current_index":"Indice actual", 
"fiche_detail.text.no_alarm":"Ningun alarma", 
"fiche_detail.text.saisie_index":"Entrada de indice", 
"fiche_detail.title.round":"Hoja de ruta", 
"fiche_detail.conso.range_tooltip":"La media ya no se incluye en la horquilla de consumo", 
"fiche_detail.conso.null_range_tooltip":"No debería haber consumo en este", 
"index_wrapped.label.read":"Lectura", 
"index_wrapped.read_method.radio":"$t(all.read_meter.type.A)", 
"index_wrapped.read_method.manual":"$t(all.read_meter.type.M)", 
"index_wrapped.label.date":"$t(all.date.date)", 
"index_wrapped.no_data.index_not_present":"Indice no especificado", 
"index_wrapped.popover.title":"Índice actual inferior al anterior", 
"index_wrapped.popover.confirm_text":"El índice actual es inferior al anterior, ¿ha dado la vuelta el contador?", 
"index_wrapped.popover.confirm_deletion_text":"¿Quiere acabar definitivamente con esta lectura?", 
"index_wrapped.updated_by":"Actualizado por :", 
"static_list.label.not_indexed_manual_read":"No indexado (lectura manual)", 
"static_list.label.not_indexed_radio_read":"No indexado (lectura radio)", 
"static_list.label.not_indexed_telereleve_read":"No indexado (lectura remota)", 
"static_list.label.edited_manual_read":"Modificado (lectura manual)", 
"static_list.label.edited_radio_read":"Modificado (lectura radio)", 
"static_list.label.edited_with_telereleve":"Modificado (lectura remota)", 
"static_list.label.indexed_no_radio":"Indexado (lectura manual) sin radio", 
"static_list.label.indexed_with_radio":"Indexado (lectura radio) con radio", 
"static_list.label.indexed_with_telereleve":"Indexado (lectura manual) con lectura remota", 
"static_list.label.indexed_radio_read":"Indexado (lectura radio)", 
"static_list.label.indexed_telereleve_read":"Indexado (lectura remota)", 
"static_list.label.indexed_radio_read_with_alarm":"Indexado con alarmas (lectura radio)", 
"static_list.label.indexed_telereleve_read_with_alarm":"Indexado con alarmas (lectura remota)", 
"static_list.label.normal":"Normale", 
"static_list.value.normal_cons":"Consumo normal", 
"static_list.label.weak":"Débil", 
"static_list.value.weak_cons":"Consumo débil", 
"static_list.label.nul":"Cero", 
"static_list.value.nul_cons":"Consumo cero", 
"static_list.label.strong":"Fuerte", 
"static_list.value.strong_cons":"Consumo fuerte", 
"column_list.label.pdi_ref":"$t(all.pdi.pdi_ref)", 
"column_list.label.meter":"$t(all.meter.meter)", 
"column_list.label.id_meter":"ID del medidor", 
"column_list.label.serial_meter":"$t(all.meter.meter_serial)", 
"column_list.label.revised_serial_meter":"Número de serie del medidor revisado", 
"column_list.label.c":"c", 
"column_list.label.city":"$t(all.address.city)", 
"column_list.label.country":"$t(all.address.country)", 
"column_list.label.index_meter":"$t(all.meter.meter_index)", 
"column_list.label.serial_radio":"$t(all.meter.radio_serial)", 
"column_list.label.alarm":"Alarma", 
"column_list.label.altitude":"Altitud", 
"column_list.label.network_code":"Codigo de red", 
"column_list.label.round_code":"$t(all.round.round_code)", 
"column_list.label.radio_mfr":"$t(all.radio.radio_manufacturer)", 
"column_list.label.meter_mfr":"$t(all.meter.meter_manufacturer)", 
"column_list.label.last_read_date":"$t(all.read_meter.read_last_date)", 
"column_list.label.sealing_color":"Color de relleno", 
"column_list.label.meter_diameter":"$t(all.meter.real_meter_diameter)", 
"column_list.label.radio_id":"Identificacion de radio", 
"column_list.label.pdi_id":"Identificacion de PDI", 
"column_list.label.meter_id":"Indentificacion de medidor", 
"bookmark_location.error_band.impossible_get_favorite":"No se puede obtener la lista de favoritos", 
"bookmark.loading_band.loading_favorite_dashboard":"Carga de los tableros favoritos", 
"display_bookmark.link_text.dashboard_nb_x":"Tablero {{dashboardIndex}}", 
"column_selector.no_options_msg.no_more_column_available":"No hay más columnas disponibles", 
"column_selector.no_options_msg.no_corresponding_column":"Sin columna correspondiente", 
"column_selector.text.column_plural":"Columnas", 
"column_selector.text.delete_saved_conf":"Eliminar configuración guardada", 
"column_selector.text.no_saved_col_conf":"No se guardó ninguna configuración de columna", 
"home_page.title.hello":"Holà", 
"home_page.subtitle.your_dashboard":"tus tableros preferidos", 
"list_render.list_type.pdi":"$t(all.pdi.pdi)", 
"list_render.list_type.radio_plural":"$t(all.radio.radio_plural)", 
"list_render.list_type.meter_plural":"$t(all.meter.meter_plural)", 
"list_render.list_type.virtual_meter_plural":"$t(all.meter.virtual_meter_plural)", 
"list_render.delete_modal_title.delete_x_virtual_meter":"Eliminar el medidor virtual {{rowName}}", 
"list_tools.label.cons_state":"Estado de consumo", 
"list_tools.text.cons":"Consumo", 
"notifications.tooltip.delete_selected":"Eliminar le seleccion", 
"notifications.loading_band.loading_notification_plural":"Cargando notificaciones", 
"notifications.popup_delete_title.delete_selected_notification_plural":"Eliminar notificaciones seleccionadas", 
"notifications.popup_delete_title.delete_notification":"Eliminar la notificacion", 
"notifications.popup_delete_msg.sure_delete_selected_notification_plural":"Esta acción irreversible eliminará las notificaciones seleccionadas.", 
"notifications.popup_delete_msg.sure_delete_notification":"Esta acción irreversible eliminará esta notificación", 
"historique_compteur.title.install_date":"fecha de montaje", 
"historique_compteur.title.uninstall_date":"Fecha de desmontaje", 
"historique_compteur.text.dashboard_1":"Tablero 1", 
"historique_compteur.text.serial_meter":"Numero serial del medidor", 
"historique_compteur.text.actual_meter":"Medidor actual", 
"historique_compteur.text.go_to_meter_detail":"Ir al archivo del medidor", 
"porte_ouverte.label.duration_door_opening":"Tiempo de apertura de la puerta (en minutos)", 
"porte_ouverte.label.door_opening":"Puerta de apertura", 
"location_creator.title.add_location":"Agregar un sitio", 
"location_creator.title.edit_location":"Modificar sitio", 
"identity_chooser.text.location_name":"Nombre del sitio", 
"identity_chooser.text.new_location_name":"Nuevo nombre del sitio", 
"identity_chooser.text.link_to_location":"Asociar con un sitio", 
"identity_chooser.text.select_location_to_link":"Seleccione con qué sitio asociar su nuevo sitio", 
"identity_chooser.input_option.no_location":"$t(all.text.none_male)", 
"identity_chooser.text.location_x_belong_to_first_level":"El sitio {{locationName}} será un sitio de primer nivel.", 
"identity_chooser.text.location_x_belong_to_location_x":"El sitio {{locationName}} será un subsitio de {{belongTo}}.", 
"identity_chooser.error_msg.max_length_name":"El nombre debe tener menos de 25 letras", 
"identity_chooser.error_msg.max_length_name_required":"El campo es obligatorio y debe tener menos de 25 caracteres.", 
"identity_chooser.warning_msg.no_location_to_link":"No hay sitio disponible para asociar", 
"identity_chooser.button.create_location":"Crear sitio", 
"identity_chooser.button.edit_location":"Modificar sitio", 
"widget.type_source.meter":"$t(all.meter.meter)", 
"widget.type_source.radio":"$t(all.radio.radio)", 
"widget.display_type.curve":"Curva", 
"widget.display_type.histogram":"Histograma", 
"widget.display_value.meterreadingvalue":"Indice de medidor", 
"widget.display_value.meterreadingconsumption":"Consumo de medidor", 
"widget.display_value.radioreadingvalue":"Indice de radio", 
"widget.display_value.meteralarm":"Alarma de medidor", 
"widget.display_value.metermultireadingvalue":"Indice de medidor multiple", 
"widget.display_value.metermultireadingconsumption":"Consumo de medidor multiple", 
"widget.display_value.meteryield":"Eficiencia de consumo", 
"widget.condition_title.meterserialnumber":"$t(all.meter.meter_serial)", 
"widget.condition_title.radioserialnumber":"$t(all.meter.radio_serial)", 
"widget.condition_title.meterserialnumberref":"$t(all.meter.meter_serial_ref)", 
"widget.condition_title.meterserialnumber_":"$t(all.meter.meter_serial_control)", 
"widget.condition_title.readingdateinterval":"Intervalo de lectura", 
"widget.condition_title.alarmtype":"$t(all.alarm_meter.alarm_type)", 
"widget.condition_title.meters":"$t(all.meter.meter_plural)", 
"widget.condition_title.rangeconsumption":"Interval de consumo", 
"widget.condition_title.gapconsumption":"Limite de consumo", 
"widget.condition_title.meterslistautocomplete":"medidores afectados", 
"widget.condition_title.period":"Periodo", 
"widget.condition_title.meterserialnumber_1":"$t(all.meter.meter_serial_control)", 
"widget.graph_type.index_meter_histogram":"Indice de medidor histograma", 
"widget.graph_type.index_meter_curve":"Indice de medidor curvo", 
"widget.graph_type.consumption_meter_histogram":"Consumo de medidor histograma", 
"widget.graph_type.consumption_meter_curve":"Consumo de medidor curvo", 
"widget.graph_type.alarm_meter":"$t(widget.display_value.meteralarm)", 
"widget.graph_type.multiple_meter_consumption_histogram":"Consumo múltiple medidor histograma", 
"widget.graph_type.multiple_meter_consumption_curve":"Consumo múltiple medidor curvo", 
"widget.graph_type.multiple_meter_index_histogram":"Indice multiple medidor histograma", 
"widget.graph_type.multiple_meter_index_curve":"Indice multiple medidor curvo", 
"widget.graph_type.index_radio_histogram":"Indice radio histograma", 
"widget.graph_type.index_radio_curve":"Indice radio curva", 
"widget.graph_type.meter_yield_curve":"Rendimiento del medidor curvo", 
"widget_last.text.no_alarm_type":"$t(all.text.none_male)", 
"widget_last.title.summary":"$t(all.text.summary)", 
"widget_last.text.widget_name":"Nombre del widget", 
"widget_last.text.widget_type":"Tipo del widget", 
"widget_last.text.widget_display":"Visualizacion del widget", 
"widget.text.not_configured":"No configurado", 
"location_creator.button.check_on_map":"Comprobar en el mapa", 
"location_creator.title.location_place":"Ubicacion del sitio", 
"location_creator.text.address_entry":"Direccion ingresada", 
"location_creator.button.edit_address":"Modificar la direccion", 
"location_creator.text.corrected_address":"Direccion corregida", 
"location_creator.text.choose_address":"Seleccione la dirección corregida o la ingresada", 
"location_creator.title.location_contact":"Contacto del sitio", 
"location_creator.title.location_note":"Nota para el sitio", 
"location_creator.placeholder.write_note":"$t(all.text.write_note)", 
"location_creator.text.no_location_selected":"Ningun sitio seleccionado", 
"display_dashboards.title.favorite_dashboard":"$t(all.dashboard.dashboard_favorite_plural)", 
"display_dashboards.title.dashboard_plural":"$t(all.dashboard.dashboard_plural)", 
"display_dashboards.filter_placeholder.filter_by_dashboard":"Filtrar por tablero", 
"display_dashboards.button.delete_dashboard":"Eliminar tablero", 
"display_dashboards.button.rename_dashboard":"Cambiar el nombre del tablero", 
"display_dashboard.confirm_modal_title.delete_x_dashboard":"Eliminar tablero {{dashboardName}}?", 
"display_dashboards.delete_modal.title":"Supresión de un cuadro de mandos", 
"display_dashboards.delete_modal. warning_confirm_dashboard":"Este cuadro de mandos es el cuadro de mandos personal de {{ownerFirstName}} {{ownerLastName}}", 
"display_dashboards.delete_modal.warning_confirm":"De verdad quieres borrar este salpicadero?", 
"display_dashboards.delete_modal.confirm":"Estás seguro de que quieres borrar este panel de control?", 
"add_dashboard.title.add_dashboard":"Agregar tablero", 
"add_dashboard.text.dashboard_name":"$t(all.dashboard.dashboard_name)", 
"add_dashboard.error_msg.dashboard_name_error":"$t(all.dashboard.name_need_least_2_char)", 
"dashboard.button.switch_display_mode":"Cambiar al modo de presentación", 
"dashboard.button.exit_display_mode":"salir del modo de presentación", 
"dashboard.button.delete_widget_from_dashboard":"Eliminar widget del tablero", 
"dashboard.button.delete_from_favorite":"Eliminar favoritos", 
"add_widget.button.add_widget":"Agregar widget", 
"add_widget.text.reading_value":"Valor de lectura", 
"add_widget.text.source":"Fuente", 
"add_group.title":"Agregar un grupo", 
"widget_creator.title.configure_widget":"Configurar el widget", 
"widget_option.error_msg.widget_name_error":"El nombre del widget es obligatorio y debe tener más de 2 caracteres.", 
"range_configurator.title.gap_consumption_percent":"Limite de consumo (porcentaje)", 
"alarm_creator.title.create_alert":"Crear una alerta", 
"alarm_creator.text.add_alert":"Agregar alerta", 
"alarms.filter_placeholder.alert_filter":"Filtrar por alerta", 
"alarm_configurator.title.alert_edition":"Modificar alerta", 
"alarm_configurator.placeholder.alert_name":"$t(all.alert.alert_name)", 
"alarm_options.text.calculation_method":"$t(all.text.calculation_method)", 
"alarm_options.text.alert_name_required_with_min_length":"El nombre de la alerta es obligatorio y debe tener más de 2 caracteres", 
"period_selector.text.trigger_period":"Período de activación", 
"period_selector.text.schedule_interval":"Intervalo de programacion", 
"mask_alarm.button.access_card":"Acceder al archivo", 
"mask_alarm.text.delete_mask_action":"Esta acción irreversible eliminará la máscara de la ubicación.", 
"mask_alarm.text.nb_days_with_alarm":"Numero de dias con alarma", 
"mask_alarm.text.no_meter_associated":"Sin medidores asociados", 
"mask_alarm.text.alarm_of_meters":"Alarmas de medidores", 
"mask_alarm.text.no_alarm_masked":"Sin alarmas ocultas", 
"source_sheet.text.internal_number":"Numero interno", 
"source_sheet.text.current_meter":"Medidor actual", 
"source_sheet.text.no_linked_meter":"Sin medidor asociado", 
"source_sheet.text.meter_linked_history":"Historial de contadores asociados", 
"source_sheet.text.no_old_meter_linked":"Ningun medidor antiguo asociado", 
"printable_source_sheet.text.x_data_from_x_to_x_zoom_x":"Datos de {{name}} - desde {{start}} hasta {{end}} - Zoom : {{zoom}}", 
"virtual_meter.text.last_best_read":"Última mejor lectura", 
"virtual_meter.text.invalid":"Invalido", 
"virtual_meter.text.read_meter_plural":"Medidores leidos", 
"virtual_meter.text.only_valid_read_plural":"Solo lecturas válidas", 
"virtual_meter.text.load_index":"Carga de indices", 
"virtual_meter.text.load_consumption":"Carga de consumos", 
"virtual_meter.text.load_last_best_read":"Carga de la última mejor lectura", 
"virtual_meter.text.load_alarms":"Carga de alarmas", 
"alarm_selector.text.type_to_display_plural":"Tipos para mostrar", 
"saving_settings_modal.text.saving_conf":"Guardar la configuración", 
"saving_settings_modal.text.backup_name":"Nombre de la copia de seguridad", 
"source_sheet_pdi.text.alarm_mask":"Mascara de alarma", 
"source_sheet_pdi.text.display_interval":"intervalo de visualización", 
"source_sheet_pdi.text.meter_loading":"Carga de medidores", 
"source_sheet_pdi.text.terrain":"Terreno", 
"source_sheet_pdi.text.many_data_loading":"Gran cantidad de datos para recuperar. Espere por favor ...", 
"source_sheet_pdi.text.invalid_interval":"Intervalo inválido", 
"source_sheet_pdi.text.averages_unavailable":"Media no disponible", 
"source_sheet_pdi.text.no_data_display":"Datos no disponibles", 
"source_sheet_pdi.text.display_type_read":"Visualización por tipo de lectura", 
"source_sheet_pdi.text-unique_read":"El intervalo de tiempo anterior sólo contiene una lectura.", 
"export_pdi.text.export_preview":"Vista previa (las 5 primeras vistas previas)", 
"export_pdi.text.refresh_preview":"Actualizar vista previa", 
"export_pdi.text.cvs_export_progress":"Exportando el CSV...", 
"export_pdi.text.cvs_export_done":"Exportacion del CSV completado...", 
"export_pdi.text.separator":"Seperador", 
"export_pdi.text.comma":"Coma", 
"export_pdi.text.semicolon":"Punto y coma", 
"export_pdi.text.vertical_bar":"Barra vertical", 
"users.text.user_management":"Gestion de usarios", 
"users.text.search_user":"Buscar un usario", 
"users.text.add_user":"Agregar usario", 
"users.text.add_tourmaline_user":"Agregar usario Tourmaline", 
"add_user.button.add_new_user":"Agregar un nuevo usario", 
"add_user.field.ucid_code_error":"El código UCID es obligatorio y debe tener más de 16 caracteres.", 
"add_user.field.email_unique":"Correo electronico, unico", 
"add_user.field.email_error":"Invalido correo electronico", 
"add_user.field.name_error":"El nombre es obligatorio y debe tener más de 2 caracteres.", 
"add_user.field.field_only_alphabetical":"El campo es solo alfabético", 
"add_user.field.minimum_x_size":"El tamaño mínimo del campo es {{min}} caracteres", 
"add_user.field.maximum_x_size":"El tamaño maximo del campo es {{max}} caracteres", 
"user.text.remove_profile":"Eliminar perfil", 
"user.text.select_location":"Seleccione un sitio", 
"user.text.select_profile":"Seleccione perfil", 
"user.text.remove_permission_to_user":"Revocarás el permiso de este usuario", 
"user.text.last_connection":"Ultima conexion", 
"user.text.generate_pwd":"Generar una contraseña", 
"user.text.reset_pwd":"restablecer la contraseña", 
"user.text.admin_all_gestionnaires":"Eres administrador de todos los gerentes", 
"user.text.admin_all_locations":"Eres administador de todos los sitios", 
"user.text.user_is_admin":"Este usuario tiene un perfil de administrador", 
"user.text.select_lang":"Elegir una lengua", 
"user.text.lang_choosen":"Lengua elegido", 
"user.text.select_role":"Elegir un rol", 
"user.text.please_fill_password":"Ingrese una contraseña, al menos 8 caracteres, debe contener al menos una letra mayúscula y un número", 
"user.text.new_password":"Nueva contraseña", 
"user.text.rewrite_new_password":"Vuelva a escribir la contraseña", 
"user.text.password_not_match":"Las contraseñas no coinciden", 
"user.text.delete":"Atención, la supresión esta definitiva. El usuario y su cuadro de indicadores serán borrados.  Los cuadro de indicadores creados por el usuario te serán asignados y los datos de conexión serán borrados", 
"user.text.unlock":"Cette action débloquera l'utilisateur, il pourra à nouveau se connecter", 
"user.text.lock":"Cette action bloquera l'utilisateur, il ne pourra plus se connecter", 
"user.text.sure":"Etes-vous sur ?", 
"user.text.nb.phone":"Nombre de téléphones", 
"user.text.nb.deliveries":"Nombre de tournées", 
"user.text.authorization":"Autorisation", 
"user.text.language":"Idioma", 
"user.text.french":"Francés", 
"user.text.english":"Inglés", 
"user.text.spanish":"Español", 
"user.button.save.key":"Guardar esta sección", 
"location_modal.title_ask.delete_x_location":"Eliminar sitio {{locationName}} ?", 
"location_modal.text.delete_location_effect":"Esta acción irreversible eliminará todos los datos de este sitio.", 
"columns.text.date_added":"Fecha agregado (defecto)", 
"columns.text.abc_order":"A -> Z", 
"columns.text.abc_disorder":"Z -> A", 
"columns.text.max_to_min":"+ -> -", 
"columns.text.min_to_max":"- -> +", 
"columns.text.code_abc_order":"$t(all.text.code) $t(columns.text.abc_order)", 
"columns.text.code_abc_disorder":"$t(all.text.code) $t(columns.text.abc_disorder)", 
"columns.text.wording_abc_order":"Fraseología $t(columns.text.abc_order)", 
"columns.text.wording_abc_disorder":"Fraseología $t(columns.text.abc_disorder)", 
"columns.text.actions_max_to_min":"Acciones $t(columns.text.max_to_min)", 
"columns.text.actions_min_to_max":"Acctiones $t(columns.text.min_to_max)", 
"columns.text.loaded_max_to_min":"Cargadas $t(columns.text.max_to_min)", 
"columns.text.loaded_min_to_max":"Cargadas $t(columns.text.min_to_max)", 
"avancement_chart.text.indexed_x_on_x":"$t(all.text.indexed) : {{count}} / {{totIndexed}}", 
"avancement_chart.text.indexed_x_on_x_plural":"$t(all.text.indexed_plural) : {{count}} / {{totIndexed}}", 
"avancement_chart.text.edited_x_on_x":"$t(all.text.modified) : {{count}} / {{totEdited}}", 
"avancement_chart.text.edited_x_on_x_plural":"$t(all.text.modified_plural) : {{count}} / {{totEdited}}", 
"device_info.text.waiting_action":"$t(all.text.waiting_action)", 
"device_info.text.waiting_action_plural":"$t(all.text.waiting_action_plural)", 
"device_info.text.x_loaded_round":"{{count}} ruta cargada", 
"device_info.text.x_loaded_round_plural":"{{count}} rutas cargadas", 
"device_info.text.first_com":"Primera comunicacion", 
"device_info.error_text.load":"No se pueden cargar los datos", 
"device_info.error_text.integrate":"Imposibilidad de integrar datos", 
"device_info.error_text.contact":"Póngase en contacto con el servicio de asistencia", 
"device_info_error_load_round":"No se pueden cargar la(s) siguiente(s) ronda(s):", 
"round_info.text.indexed":"Indexado(s)", 
"round_info.text.remaining":"Restante(s)", 
"round_info.text.edited":"Modificado(s)", 
"round_info.text.indexed_x_percent_plural":"$t(all.text.indexed_plural) ({{percent}}%)", 
"round_info.text.edited_x_percent_plural":"$t(all.text.modified_plural) ({{percent}}%)", 
"round_info.text.loaded_on_x_phone":"Cargada en {{count}} telefono", 
"round_info.text.loaded_on_x_phone_plural":"Cargada en {{count}} telefonos", 
"task_duplication.text.code_x":"codigo : {{code}}", 
"alarm_updater.text.alert_edition":"Modificacion de una alerta", 
"conso_interval.text.threshold_required":"Debes ingresar al menos un umbral", 
"conso_interval.text.threshold_x":"Umbral ({{unit}})", 
"last.text.recipient_user_plural":"Usuarios destinatarios", 
"manage_alarm.text.x_linked_pdi_to_location":"{{count}} PDI asociado con el sitio.", 
"manage_alarm.text.x_linked_pdi_to_location_plural":"{{count}} PDI asociados con el sitio.", 
"manage_alarm.text.x_dissociate_pdi_to_location":"{{count}} PDI disociado con el sitio.", 
"manage_alarm.text.x_dissociate_pdi_to_location_plural":"{{count}} PDI disociados con el sitio.", 
"cluster_marker_gm.title.meter_detail_plural":"Detalles del medidor", 
"cluster_meter_list.text.close_meter_list":"Cerrar la lista de medidores <br />", 
"google_map_react_c.text.center_selected_meter":"Centrar en el <br /> medidor seleccionado", 
"contact_chooser.text.lastname_only_letters":"El apellido solo puede contener letras", 
"contact_chooser.text.firstname_only_letters":"El nombre solo puede contener letras", 
"contact_chooser.text.phone_number_needs_10_number":"El número de teléfono debe contener 10 dígitos", 
"contact_chooser.text.give_name_info":"Ingresa un nombre", 
"contact_chooser.text.give_email_info":"Ingresa un email", 
"loraimport.text.nb_link_in_file":"Número de enlaces en el archivo", 
"loraimport.text.nb_new_link_in_file":"Número de nuevos enlaces en el archivo", 
"loraimport.text.import_lorafile_processing":"Importación del archivo LoRa actual", 
"loraimport.text.import_file_failed":"Error al cargar el archivo LoRa {{filename}}", 
"loraimport.text.import_lora":"Importacion LoRa", 
"loraimport.text.lorafile_process_success":"Carga del archivo LoRa completa", 
"import_billing.label.header_size":"Tamaño del encabezado", 
"import_billing.label.first_cell_row":"Línea celda de identificación", 
"import_billing.label.first_cell_col":"Columna celda de identificacion", 
"import_billing.label.column_size":"Numero de columnas", 
"import_billing.label.end_of_first_cell":"Fin de la primera celda", 
"import_billing.label.first_line_number":"Indice de la primera linea", 
"import_billing.label.id_cell_row":"Número de línea", 
"import_billing.label.id_cell_col":"Número de columna", 
"import_billing.label.id_cell_content":"Contenido", 
"import_billing.label.id_cell":"Célula de identificación", 
"import_billing.label.sub":"Suscripcion `", 
"import_billing.label.pdi":"$t(all.pdi.pdi)", 
"import_billing.label.round":"$t(all.round.round)", 
"import_billing.label.cpt":"$t(all.meter.meter)", 
"import_billing.label.else":"$t(all.text.other)", 
"import_billing.label.pdi_ref":"$t(all.text.reference)", 
"import_billing.label.pdi_state":"$t(all.text.state)", 
"import_billing.label.pdi_address_number":"Numero de direccion", 
"import_billing.label.pdi_number_additionnal":"$t(all.address.street_number_additionnal)", 
"import_billing.label.pdi_street":"Calle", 
"import_billing.label.pdi_additionnal_street":"Suplemento de calle", 
"import_billing.label.pdi_complement_address":"$t(all.address.complement)", 
"import_billing.label.pdi_appart":"Apartamento", 
"import_billing.label.pdi_floor":"Piso", 
"import_billing.label.pdi_building":"Edificio", 
"import_billing.label.pdi_stair":"Escaleras", 
"import_billing.label.pdi_zipcode":"$t(all.address.zipcode)", 
"import_billing.label.pdi_city":"$t(all.address.city)", 
"import_billing.label.pdi_note_plural":"Notas", 
"import_billing.label.round_code":"$t(all.round.round_code)", 
"import_billing.label.round_name":"$t(all.round.round_name)", 
"import_billing.label.cpt_number":"$t(all.meter.serial)", 
"import_billing.label.cpt_wheels":"Numero de ruedas de medidor", 
"import_billing.label.cpt_install_date":"$t(all.text.install_date)", 
"import_billing.label.cpt_oldnumber":"Ancien numéro de série compteur", 
"import_billing.label.date_depose":"Date de dépose", 
"import_billing.label.index_depose":"$t(columns.previousMeter.deposeIndex)", 
"import_billing.label.index_pose":"$t(fiche_detail.title.install_read)", 
"import_billing.label.cpt_color_filling":"Relleno de color", 
"import_billing.label.branchement_code_reseau":"Código de conexión a la red", 
"import_billing.label.date_start_subscription":"Fecha comienzo de la suscripcion", 
"import_billing.label.date_end_subscription":"Fecha el final de la suscripcion", 
"import_billing.label.state_subscription":"Estado de la suscripcion", 
"import_billing.label.gps":"GPS", 
"import_billing.label.gps_latitude":"$t(all.text.latitude)", 
"import_billing.label.gps_longitude":"$t(all.text.longitude)", 
"import_billing.label.gps_altitude":"$t(column_list.label.altitude)", 
"import_billing.label.gps_score":"Score", 
"import_billing.label.gps_source":"$t(add_widget.text.source)", 
"import_billing.placeholder.value_select":"Seleccione un nombre de columna", 
"import_billing.state.removed.match":"Archivar PDI con uno de los estados", 
"import_billing.state.inactif.match":"Estado de PDI a inactivo SI", 
"pdi.PDI_GPS_LONGITUDE":"$t(all.text.longitude)", 
"pdi.PDI_GPS_LATITUDE":"$t(all.text.latitude)", 
"pdi.PDI_GPS_SCORE":"$t(import_billing.label.gps_score)", 
"pdi.PDI_GPS_SOURCE":"$t(add_widget.text.source)", 
"pdi.PDI_GPS_ALTITUDE":"$t(column_list.label.altitude)", 
"pdi.PDI_GPS_ACCURACY":"Precisión", 
"pdi.PDI_REF_ID":"Referencia ID", 
"pdi.PDI_PARENT_REFERENCE":"Referencia PDI de los padres", 
"pdi.PDI_PARENT_RELATION":"Relación con el padre PDI", 
"export_round_factu":"Exportación de facturación", 
"export_round_teleo":"Exportación téolia", 
"nbColumns":"$t(import_billing.label.column_size)", 
"general.label.round_code":"Exportación DAT de código por defecto", 
"general.label.round_default_wheels":"Exportación DAT de ruedas por defecto", 
"general.label.round_libelle":"Exportacion DAT de fraseología por defecto", 
"general.label.default_language":"Idioma por defecto", 
"general.label.end_date_demo":"Fecha final de los demos", 
"general.label.in_demo_mode":"Modo demo", 
"general.label.saphir_version_current":"Version actual de Saphir", 
"general.label.skip_trigger_alarm":"Ignorar la fecha de activación de la alarma", 
"general.label.fetch_size_query":"Tamaño máximo de BATCH", 
"general.label.supported_language":"Idiomas admitidos (separados por comas)", 
"general.label.timezone_id":"Timezone ID", 
"general.label.archive.inactif.pdi":"Archiver pdi inconnu fichier facturation", 
"general.label.apply.if_in_round":"Appliquer changement de compteur sur compteur en cours de relève", 
"general.label.telereleve.patrimoine.last_update":"$t(all.text.update_date) patrimoine télérelève", 
"general.ignore.t2t4.mrlId":"Ignorador de las líneas T2 y T4", 
"general.seuil.limit":"umbral límite", 
"general.only.current_read_table.pdi":"Usar solo las últimas lecturas", 
"synchronisation.label.export_silex_encoding":"Codificación archivos Importar/Exportar", 
"synchronisation.label.export_silex_encoding_detection_method":"Método de detección de la codificación", 
"synchronisation.label.export_silex_name":"Nombre archivo RTR", 
"synchronisation.label.telereleve_sup_startdate":"Importación de lectura remota> fecha de inicio de la lectura", 
"synchronisation.label.refresh_synchro_devices":"Intervalo de actualización del telefono", 
"synchronisation.label.remove_missing_t3":"Retire los T3 faltantes", 
"synchronisation.label.silex_export_geoloc":"Exportacion RTR con geolocalización (T7)", 
"synchronisation.label.silex_export_radio":"Exportacion RTR con radio (T2/T4)", 
"synchronisation.label.silex_export_enquete":"Export enquete T5/T6", 
"synchronisation.label.silex_import_allow_doublons":"Authorize doublons silex", 
"synchronisation.label.synchro_allow_creation_radiomodel":"Autoriser création Modèle radio", 
"synchronisation.label.stop_and_go":"STOP & GO", 
"synchronisation.label.import_all":"Prueba de synchronizacion", 
"synchronisation.label.import_t0":"Prueba T0", 
"synchronisation.label.import_t1":"Prueba T1", 
"synchronisation.label.import_t2":"Prueba T2", 
"synchronisation.label.import_t3":"Prueba T3", 
"synchronisation.label.import_t4":"Prueba T4", 
"synchronisation.label.import_t6":"Prueba T6", 
"synchronisation.label.import_t7":"Prueba T7", 
"synchronisation.label.saphir_version_current":"$t(general.label.saphir_version_current)", 
"synchronisation.ignore_invalid_histo_infuture":"Ignorar la invalidación del historial en el futuro", 
"synchronisation.ignore_previous_index_value":"Ignorar la comparación entre el índice anterior y el valor máximo", 
"synchronisation.option.rotate.round":"Opción de rotación de recorridos", 
"synchronisation.device":"$t(all.text.phone_plural)", 
"synchronisation.round":"$t(all.round.round_plural)", 
"remote_reading.label.default_format_lora_file":"Formato de archivo Lora", 
"remote_reading.label.default_format_lora_file_column_number":"Número de columna del archivo Lora", 
"remote_reading.label.has_remote_reading":"Opción de lectura remota", 
"remote_reading.label.app_eui_column_index":"Indice AppEUI", 
"remote_reading.label.app_key_column_index":"Indice Appclave", 
"remote_reading.label.dev_eui_column_index":"Indice DevEUI", 
"remote_reading.label.lora_serial_column_index":"Numero de serie Lora", 
"remote_reading.label.radio_serial_column_index":"$t(all.meter.radio_serial)", 
"remote_reading.nbColumns":"$t(import_billing.label.column_size)", 
"report.label.logo_path":"Ruta del logo", 
"report.label.specific_template_directory":"Archivos que contiene plantillas personalizadas", 
"report.label.custom_template_names":"Lista de plantillas personalizadas", 
"support.title.report":"$t(bread_crumb.name.report)", 
"meter.unit.W":"m<sup>3</sup>", 
"meter.unit.E":"kWh", 
"meter.unit.":"$t(meter.unit.W)", 
"meter.unit.C":"$t(meter.unit.W)", 
"meter.unit.H":"$t(meter.unit.W)", 
"meter.unit.G":"$t(meter.unit.W)", 
"meter.unit.T":"$t(meter.unit.E)", 
"meter.unit.O":"$t(meter.unit.W)", 
"meter.type.W":"Agua", 
"meter.type.E":"Electricidad", 
"meter.type.":"$t(meter.type.W)", 
"meter.type.C":"Agua fria", 
"meter.type.H":"Agua caliente", 
"meter.type.G":"Gas", 
"meter.type.T":"Termica", 
"meter.type.O":"$t(all.text.other)", 
"last_reading.text.tooltip_real_previous_index":"El índice anterior real se muestra actualmente y se tiene en cuenta en los cálculos siguientes. Como los índices anterior y actual son del mismo día, puede hacer clic en el botón de abajo para visualizar el índice de referencia correspondiente.", 
"last_reading.text.tooltip_reference_index":"Como el índice actual y el anterior son del mismo día, se ha elegido automáticamente el índice pertinente más reciente.", 
"last_reading.text.no_last_read":"Sin última lectura", 
"last_reading.text.no_other_read":"Ninguna otra lectura", 
"last_reading.text.no_previous_read":"Sin lectura previa", 
"last_reading.text.index_meter":"$t(widget.display_value.meterreadingvalue)", 
"tournee_band.text.list_devices":"Lista de dispositivos", 
"releve_meter_status.text.red_manual":"$t(static_list.label.not_indexed_manual_read)", 
"releve_meter_status.text.red_radio":"$t(static_list.label.not_indexed_radio_read)", 
"releve_meter_status.text.yellow_manual":"$t(static_list.label.edited_manual_read)", 
"releve_meter_status.text.yellow_radio":"$t(static_list.label.edited_radio_read)", 
"releve_meter_status.text.green_manual":"$t(static_list.label.indexed_no_radio)", 
"releve_meter_status.text.green_radio":"$t(static_list.label.indexed_with_radio)", 
"releve_meter_status.text.blue_radio":"$t(static_list.label.indexed_radio_read)", 
"releve_meter_status.text.blue_telereleve":"$t(static_list.label.indexed_telereleve_read)", 
"releve_meter_status.text.purple_radio":"$t(static_list.label.indexed_radio_read_with_alarm)", 
"releve_meter_status.text.purple_telereleve":"$t(static_list.label.indexed_telereleve_read_with_alarm)", 
"sensor.text.sensor":"Sensor", 
"sensor.text.sensor_plural":"Sensores", 
"sensor.plannification":"Planificacion abertura", 
"sensor.title.manual_management":"Gestion manual", 
"sensor.title.synchro":"Sincronización de reloj", 
"sensor.title.frequency":"Frecuencia de cuadro", 
"sensor.error.wrong_plage":"Franjas horarias incorrectas", 
"sensor.error.previous_date_sup":"El inicio de este intervalo de tiempo no puede ser menor que el intervalo anterior", 
"sensor.error.fix_previous_plage":"Corrija la franja horaria anterior", 
"sensor.text.minutes":"Minutos", 
"sensor.text.hour":"Horas", 
"sensor.error.synchro":"Error Sincronisacion", 
"sensor.title.vanne_status":"Estado de la válvula", 
"sensor.title.open_planned":"Planificacion abertura", 
"sensor.text.remove_interval":"Supprimer un intervalle", 
"sensor.text.add_interval":"Agregar intervalo", 
"sensor.text.when_vanne_open":"Cuando la válvula está abierta", 
"sensor.text.when_vanne_close":"Cuando la válvula está cerrada", 
"sensor.type.STR-EV":"Electroválvula STREGA", 
"sensor.type.ADE-COM":"Adeunis Comfort2", 
"sensor.type.ADE-CO2":"Adeunis Comfort CO2", 
"sensor.type.ADE-DRYC":"Adeunis Dry Contact", 
"sensor.type.ADE-DTP":"Adeunis DeltaP", 
"sensor.type.ADE-TEMP":"Adeunis Temp", 
"sensor.text.sensor_type":"Tipo de sensor", 
"sensor.text.serial":"Numero de serie del sensor", 
"sensor.dashboard":"sensor del tablero", 
"sensor.data.temperature":"$t(all.text.temperature)", 
"sensor.data.temperature2":"Temperatura 2", 
"sensor.data.humidity":"$t(all.text.humidity)", 
"sensor.data.co2":"$t(all.text.co2)", 
"sensor.data.pressure":"$t(all.text.pressure)", 
"sensor.data.valveOpen":"Válvula abierta", 
"support.title.importbilling":"$t(all.text.import_billing)", 
"support.title.remotereading":"$t(all.read_meter.type.T)", 
"support.title.sync":"$t(all.text.synchro)", 
"support.title.general":"$t(all.text.general_male)", 
"support.title.column":"$t(column_selector.text.column_plural)", 
"support.title.script":"Script", 
"support.title.import":"Import", 
"support.option.duplicateAllowed":"Duplicas autorizadas", 
"support.subtitle.autochange":"Configuración de cambios automáticos", 
"support.subtitle.billing_import":"Importar configuración", 
"support.autochange.truncate":"Rastrear el número de serie", 
"support.autochange.truncate_at":"Truncar a la", 
"support.autochange.left":"izquierda", 
"support.autochange.right":"derecha", 
"support.autochange.replace_date":"Corrección de fechas demasiado antiguas", 
"support.title.export":"Exportar", 
"replace_silex_lines":"Hilo de pedernal de repuesto", 
"saphir_version_current":"versión de Saphir", 
"stop_and_go":"Reinicio automático", 
"refresh_synchro_devices":"Actualizar dispositivos sincronizados", 
"silex.export.alarm.past":"Exportar alarmas pasadas", 
"silex.export.change_readmethod_T_to_A":"Modificación RMT T -> A", 
"export_round_csv_custom":"Exportación de CSV de recorrido personalizado", 
"export_round_csv_custom_format_dir":"Exportación de CSV de recorrido personalizado DIR", 
"import_round_csv_enabled":"Importar ronda CSV habilitada", 
"import_round_csv_custom":"CSV de importación redondo personalizado", 
"import_round_facturation_enabled":"Activar la importación de facturas", 
"export_round_csv_encoding":"exportar codificación csv redonda", 
"support.option.comma":"Coma", 
"support.option.semicolon":"Punto y coma", 
"support.option.tabulation":"Tabulación", 
"script.export.format":"Formatos de exportación", 
"synchronisation.label.script.export.encoding_source":"Exportar codificación de origen", 
"synchronisation.label.script.export.encoding_output":"Exportar codificación de salida", 
"synchronisation.label.script.export.delimiter":"Delimitador de exportación", 
"synchronisation.label.script.import.format":"Formato de importación", 
"synchronisation.label.script.import.encoding_source":"Importar codificación de origen", 
"synchronisation.label.script.import.encoding_output":"Importar codificación de salida", 
"synchronisation.label.script.import.delimiter":"Importar delimitador", 
"synchrotools.option.download_round":"Cargar une ruta", 
"synchrotools.option.remove_round":"Eliminar una ruta", 
"synchrotools.option.upload_round":"Descargar ua ruta", 
"synchrotools.option.rounds":"$t(all.round.round_plural)", 
"synchrotools.button.connect":"Coneccion", 
"synchrotools.button.disconnect":"Desconexión", 
"synchrotools.option.command":"Pedidos", 
"synchrotools.button.launchCommand":"Hacer un pedido", 
"remotereading.text.read_with_no_alarm":"Leido sin alarma", 
"remotereading.text.read_with_alarm":"Leido con alarma", 
"remotereading.text.read_sort_by_model":"Leidos por modelo", 
"remotereading.text.meter_alarm":"Medidores con alarmas", 
"remotereading.text.without_alarm":"Sin alarmas", 
"remotereading.text.meter_alarm_by_model":"Medidores alarmados por modelo", 
"remotereading.text.alarm_type":"Tipo de alarma", 
"remotereading.text.synthese":"Síntesis", 
"remotereading.text.in_alarm":"En alarmas", 
"remotereading.text.read":"leido", 
"remotereading.text.not_read":"no leido", 
"remotereading.text.no_meter_in_interval ":"No existe ningún medidor con esta opción en el intervalo elegido", 
"remotereading.text.link_meter_now":"Asociar Medidores ahora ?", 
"remotereading.text.no_meter_link_to":"Ningun medidor asociado con", 
"remotereading.text.select_default":"Seleccion por defecto", 
"remotereading.text.map_view":"Ver carta", 
"remotereading.text.list_view":"Ver lista", 
"remotereading.text.for_current_round":"Para la lectura", 
"remotereading.text.for_selected_period":"Para el período seleccionado", 
"remotereading.text.by_read_mode":"Por modo", 
"remotereading.text.by_manufacturer":"Por fabricante", 
"remotereading.text.by_status":"Por estado", 
"remotereading.text.multi_choices_allow":"Opción múltiple posible", 
"remotereading.text.read_method":"Modo de lectura", 
"remotereading.text.select_at_least":"Por favor seleccione al menos", 
"remotereading.text.one_manufacturer":"Un fabricante", 
"remotereading.text.one_status":"Un estado", 
"remotereading.text.one_read_method":"Un metodo de lectura", 
"remotereading.text.manufacturer":" Fabricante", 
"remotereading.text.alarm_detail":"Detalles de alarma", 
"remotereading.text.match_selected_meter":"Correspondiente a la selección de un total de {{nbMeters}}", 
"remotereading.text.meter_geolocated":"incluido {{meterGeolocated}} con geolocalización", 
"remotereading.text.no_meter_to_display":"Ningun medidor disponible", 
"enquete.text.add_enq_page":"Agregar página de encuesta", 
"enquete.text.link_enq_round":"Asociar rutas", 
"enquete.text.name_enquete_page":"Nombre de la página de la encuesta", 
"enquete.text.field_list":"Lista de campos", 
"enquete.text.field_type":"Tipo de campo", 
"enquete.field_type.A":"Texto", 
"enquete.field_type.N":"Numerico", 
"enquete.field_type.C":"Opcion", 
"enquete.text.delete_field":"Eliminar campo", 
"enquete.text.delete_field_impossible":"No se puede borrar el campo", 
"enquete.text.add_field":"Agregar campo de encuesta", 
"enquete.text.field":"$t(all.text.field)", 
"enquete.text.field_libelle":"Fraselogica de campo", 
"enquete.text.field_size":"Tamaño del campo", 
"enquete.text.field_default_value":"Valor del campo", 
"enquete.text.no_field_exist":"Ningun opcion definida", 
"enquete.text.no_field_selected":"No se seleccionó ninguna opción para este campo", 
"enquete.text.choices_list":"Lista de opciones", 
"enquete.text.associated_page":"Página de encuestas relacionadas", 
"enquetes.text.edit_enquete":"Modificar página de encuesta", 
"enquete.text.edit_impossible_in_used":"No se puede editar la encuesta: utilizada por una ruta", 
"enquete.text.enquete_deleted_links_warning":"Está a punto de desvincular las encuestas. Estás seguro de que quieres continuar ? Esta acción es irreversible.", 
"enquete.text.deletion_enquetes":"Eliminar encuestas", 
"enquete.text.placeholder_create_options":"Opción 1, Opción 2, Opción 3 ...", 
"enquete.text.remove_impossible_in_used":"No se puede suprimir la encuesta: utilizada por una ruta", 
"enquetes.text.remove_enquete":"Borrar la página de la encuesta", 
"enquetes.popover.title":"Suprimir una página de encuesta", 
"enquete.popover.confirm_text":"¿Está seguro de que quiere suprimir esta encuesta?", 
"enquete.error.choice_max_length":"Sólo puede haber 8 valores en este campo", 
"enquete.text.round_loaded_devices_no_update_allow":"La ruta se carga en los dispositivos. No es posible realizar modificaciones.", 
"enquete.text.select_enquete":"Seleccionar página de encuesta", 
"enquete.text.each_option_max_20_char":"Chaque choix ne peut faire que 20 caractères maximum", 
"enquete.text.libelle_max_16_char":"Le libellé doit faire maximum 16 caractères", 
"enquete.text.size_max_30":"La taille doit être inférieur à 30 et ne doit pas contenir de décimales", 
"enquete.text.default_value_max_size":"La valeur par défaut doit avoir moins de caractères que la taille renseignée, ici", 
"lostpass.text.password_identifiant_lost":"¿Olvidaste tu contraseña / nombre de usuario?", 
"lostpass.text.get_identifants":"Recuperación de identificadores", 
"lostpass.text.enter_email":"Indique su correo electrónico para recibir información de conexión", 
"lostpass.text.your_email":"Su correo electronico", 
"lostpass.text.enter_valid_email":"Su correo electronico debe ser validado", 
"lostpass.text.check_email":"Mire sus correos electrónicos, la información ha sido enviada. Si no ha recibido un correo electrónico dentro de las 24 horas, comuníquese con su administrador.", 
"alarm.type.fraud":"Fraude", 
"alarm.type.leak":"Fuga", 
"alarm.type.backflow":"Reflujo", 
"alarm.type.meterblock":"Medidor bloqueado", 
"alarm.type.clock":"Reloj", 
"alarm.type.moduledefect":"Mal funcionamiento del módulo", 
"alarm.type.moduleremoved":"Módulo desmontado", 
"alarm.type.configuration":"Configuración inconsistente", 
"alarm.type.reversedmeter":"Medidor inverso", 
"alarm.type.overflow":"Flujo alto", 
"alarm.type.underflow":"Flujo bajo", 
"alarm.type.brokenpipe":"Tuberia rota", 
"alarm.type.battery":"Bateria", 
"alarm.type.pocfraud":"Fraude (previo o actual)", 
"alarm.type.pocleak":"Fuga (previo o actual)", 
"alarm.type.pocbackflow":"Reflujo (previo o actual)", 
"alarm.type.pocmeterblock":"Medidor bloqueado (previo o actual)", 
"alarm.type.pocoverflow":"$t(alarm.type.overflow.poc)", 
"alarm.type.endofbattery":"Fin de bateria", 
"alarm.type.clockfault":"$t(alarm.type.clock)", 
"alarm.type.itronbatteryalarm":"Bateria (ITRON)", 
"alarm.type.itrondetectionalarm":"Deteccion", 
"alarm.type.itronmagnetictamperalarm":"Fraude magnetico", 
"alarm.type.itronmemorizedremovalalarm":"Modulo desmontado (previo)", 
"alarm.type.itronmemoryalarm":"Memoria", 
"alarm.type.itronrealtimeremovalalarm":"Modulo desmontado (actual)", 
"alarm.type.itronmemorizedtamperalarm":"Modulo defectuoso (previo)", 
"alarm.type.itronrealtimetamperalarm":"Modulo defectuoso (actual)", 
"alarm.type.itronnotwatermediumtype":"Tipo de fluido desconocido", 
"alarm.type.itronchecksumcodealarm":"Checksum", 
"alarm.type.itroncorruptdataalarm":"Datos corrompidos", 
"alarm.type.itroncurrentyearyearlyindexabovethresholdalarm":"Índice anual por encima del umbral", 
"alarm.type.itroncurrentyearyearlyindexbelowthresholdalarm":"Índice anual por debajo del umbral", 
"alarm.type.itrondateandtimereconfigurationalarm":"Reconfiguración de fecha / hora", 
"alarm.type.itronmeterblockedalarm":"$t(alarm.type.meterblock)", 
"alarm.type.itronmonthlyleakagealarm":"Fuga mensual", 
"alarm.type.itronpreviousyearyearlyindexabovethresholdalarm":"Índice anual anterior por encima del umbral", 
"alarm.type.itronpreviousyearyearlyindexbelowthresholdalarm":"Índice anual anterior por debajo del umbral", 
"alarm.type.itronrfwakeupalarm":"Alarma despertador", 
"alarm.type.itronreconfigurationalarm":"Re configuración", 
"alarm.type.itronreversedmeteralarm":"$t(alarm.type.reversedmeter)", 
"alarm.type.itronyearlybackflowalarm":"Reflujo anual", 
"alarm.type.itronyearlyleakagealarm":"Fuga anual", 
"alarm.type.itronyearlypeakalarm":"Pico de consumo anual", 
"alarm.type.itroneverbluenabled":"EverBlue activado", 
"alarm.type.itroncablecutalarm":"Corte de cable", 
"alarm.type.itron.everbluenabled":"Modo EverBlue activado", 
"alarm.type.pastleak":"Fugas previos", 
"alarm.type.currentleak":"Fugas en curso", 
"alarm.type.pastmecafraud":"Fraude mecánico previo", 
"alarm.type.currentmecafraud":"Fraude mecanico en curso", 
"alarm.type.pastmagnetfraud":"Fraude magnetico previo", 
"alarm.type.currentmagnetfraud":"Fraude magnetico en curso", 
"alarm.type.pastmeterblock":"Bloqueo previo", 
"alarm.type.currentmeterblock":"Bloqueo actual", 
"alarm.type.metrologyerror":"Error metrologico", 
"alarm.type.mediumabsent":"medio ausente", 
"alarm.type.specificerror":"Error specifico", 
"alarm.type.modulesetting":"Configuración del módulo", 
"alarm.type.temperature":"$t(all.text.temperature)", 
"alarm.type.fraud.current":"Fraude (actual)", 
"alarm.type.fraud.poc":"Fraude (previo o actual)", 
"alarm.type.fraud.past":"Fraude (previo)", 
"alarm.type.leak.past":"Fuga (previo)", 
"alarm.type.leak.poc":"Fuga (previo o actual)", 
"alarm.type.leak.current":"Fuga (actual)", 
"alarm.type.leak.current.high":"Fuga importante (actual)", 
"alarm.type.backflow.past":"Reflujo previo", 
"alarm.type.backflow.current":"Reflujo actual", 
"alarm.type.backflow.poc":"Reflujo (previo o actual)", 
"alarm.type.pastbackflow":"$t(alarm.type.backflow.past)", 
"alarm.type.currentbackflow":"$t(alarm.type.backflow.current)", 
"alarm.type.underflow.past":"Flujo bajo (previo)", 
"alarm.type.pocunderflow":"$t(alarm.type.underflow.poc)", 
"alarm.type.underflow.current":"Flujo bajo (actual)", 
"alarm.type.underflow.poc":"Flujo bajo (previo o actual)", 
"alarm.type.overflow.past":"Flujo alto (previo)", 
"alarm.type.overflow.current":"Flujo alto (actual)", 
"alarm.type.overflow.poc":"Desmordamiento (previo o actual)", 
"alarm.type.meterblock.past":"Medidor bloqueado (previo)", 
"alarm.type.meterblock.current":"Medidor bloqueado (actual)", 
"alarm.type.meterblock.poc":"$t(alarm.type.pocmeterblock)", 
"alarm.type.battery.past":"Bateria (previo)", 
"alarm.type.battery.current":"Bateria (actual)", 
"alarm.type.battery.poc":"Bateria (previo o actual)", 
"alarm.type.clock.past":"Reloj (previo)", 
"alarm.type.clock.current":"Reloj (actual)", 
"alarm.type.clock.poc":"Reloj (previo o actual)", 
"alarm.type.moduledefect.past":"Mal funcionamiento del módulo (previo)", 
"alarm.type.moduledefect.current":"Mal funcionamiento del módulo (actual)", 
"alarm.type.moduledefect.poc":"Mal funcionamiento del modulo (previo o actual)", 
"alarm.type.modulesetting.past":"Configuracion del modulo (previo)", 
"alarm.type.modulesetting.current":"Configuracion del modulo (actual)", 
"alarm.type.modulesetting.poc":"Configuracion del modulo (previo o actual)", 
"alarm.type.reversedmeter.past":"Medidor inverso (previo)", 
"alarm.type.reversedmeter.current":"Medidor inverso (actual)", 
"alarm.type.reversedmeter.poc":"Medidor inverso (previo o actual)", 
"alarm.type.mediumabsent.past":"Medio ausente (previo)", 
"alarm.type.mediumabsent.current":"Medio ausente (actual)", 
"alarm.type.mediumabsent.poc":"Medio ausente (previo o actual)", 
"alarm.type.brokenpipe.past":"Tubería rota (previo)", 
"alarm.type.brokenpipe.current":"Tubería rota (actual)", 
"alarm.type.brokenpipe.poc":"Tuberia (previo o actual)", 
"alarm.type.temperature.past":"Temperatura (previo)", 
"alarm.type.temperature.current":"Temperatura (actual)", 
"alarm.type.temperature.poc":"Temperatura (previo o actual)", 
"alarm.type.specificerror.past":"Error specifico (previo)", 
"alarm.type.specificerror.current":"Error specifico (actual)", 
"alarm.type.specificerror.poc":"Error specifico (previo o actual)", 
"alarm.type.fraud.mecanic.past":"Fraude mecánico (previo)", 
"alarm.type.fraud.mecanic.current":"Fraude mecánico (actual)", 
"alarm.type.fraud.mecanic.poc":"Fraude mecánico (previo o actual)", 
"alarm.type.fraud.magnetic.past":"Fraude magnético (previo)", 
"alarm.type.fraud.magnetic.current":"Fraude magnético (actual)", 
"alarm.type.fraud.magnetic.poc":"Fraude magnético (previo o actual)", 
"alarm.type.temperature.low.past":"Temperatura baja (previo)", 
"alarm.type.temperature.low.current":"Temperatura baja (actual)", 
"alarm.type.temperature.low.poc":"Temperatura baja (previo o actual)", 
"alarm.type.temperature.hight.past":"Temperatura alta (previo)", 
"alarm.type.temperature.hight.current":"Temperatura alta (actual)", 
"alarm.type.temperature.hight.poc":"Temperatura alta (previo o alta)", 
"alarm.type.moduleremoved.past":"$t(alarm.type.itronmemorizedremovalalarm)", 
"alarm.type.moduleremoved.current":"Modulo desmontado (acutal)", 
"alarm.type.moduleremoved.poc":"Modulo desmontado (previo o actual)", 
"alarm.type.itron.battery":"$t(alarm.type.itronbatteryalarm)", 
"alarm.type.itron.detection":"Deteccion (ITRON)", 
"alarm.type.itron.magnetic.tamper":"Fraude magnético", 
"alarm.type.itron.memorizedremoval":"Modulo ITRON desmontado (previo)", 
"alarm.type.itron.memory":"Memoria (ITRON)", 
"alarm.type.itron.realtimeremoval":"Modulo ITRON desmontado (actual)", 
"alarm.type.itron.memorizedtamper":"Módulo ITRON defectuoso (previo)", 
"alarm.type.itron.realtimetamper":"Modulo ITRON defectuoso (actual)", 
"alarm.type.itron.notwatermedium":"fluido desconocido (ITRON)", 
"alarm.type.itron.checksumcode":"Marco no válido (ITRON)", 
"alarm.type.itron.corruptdata":"Datos corruptos (ITRON)", 
"alarm.type.itron.currentyearyearlyindexabovethreshold":"Índice anual por encima del umbral (ITRON)", 
"alarm.type.itron.currentyearyearlyindexbelowthreshold":"Índice anual por debajo del umbral (ITRON)", 
"alarm.type.itron.dateandtimereconfiguration":"Reconfiguración de fecha / hora (ITRON)", 
"alarm.type.itron.meterblocked":"Medidor bloqueado (ITRON)", 
"alarm.type.itron.monthlyleakage":"Fuga mensual (ITRON)", 
"alarm.type.itron.previousyearyearlyindexabovethreshold":"Índice anual anterior por encima del umbral (ITRON)", 
"alarm.type.itron.previousyearyearlyindexbelowthreshold":"Índice anual anterior por debajo del umbral (ITRON)", 
"alarm.type.itron.rfwakeup":"Alarma despertador (ITRON)", 
"alarm.type.itron.reconfiguration":"Reconfiguración (ITRON)", 
"alarm.type.itron.reversedmeter":"Medidor inverso (ITRON)", 
"alarm.type.itron.yearlybackflow":"Reflujo anual (ITRON)", 
"alarm.type.itron.yearlyleakage":"Fuga anual (ITRON)", 
"alarm.type.itron.yearlypeak":"Consumo anual pico (ITRON)", 
"alarm.type.itron.cablecut":"Cable cortado (ITRON)", 
"alarm.type.birdz.reversedmountmeter":"Medidor inverso (BIRDZ)", 
"alarm.type.birdz.oversizedsmalldiameter":"Pequeño diametro sobredimensionado (BIRDZ)", 
"alarm.type.birdz.oversizedbigdiameter":"Gran diámetro sobredimensionado (BIRDZ)", 
"alarm.type.birdz.undersizedsmalldiameter":"Pequeño diámetro infradimensionado (BIRDZ)", 
"alarm.type.birdz.undersizedbigdiameter":"Gran diámetro infradimensionado (BIRDZ)", 
"alarm.type.birdz.irregularblock":"Bloqueo iregular (BIRDZ)", 
"alarm.type.birdz.lowtemperaturefreeze":"Gel a temperatura baja (BIRDZ)", 
"alarm.type.birdz.reversedmeter":"$t(alarm.type.birdz.reversedmountmeter)", 
"alarm.type.birdz.magneticfraud":"Fraude magnetico (BIRDZ)", 
"alarm.type.birdz.unmountedmodule":"Modulo desmontado (BIRDZ)", 
"alarm.type.birdz.backflowl1":"Reflujo L1 (BIRDZ)", 
"alarm.type.birdz.backflowl2":"Reflujo L2 (BIRDZ)", 
"alarm.type.birdz.hightemperaturesmalldiameter":"Diámetro pequeño para alta temperatura (BIRDZ)", 
"alarm.type.birdz.hightemperaturebigdiameter":"Diámetro grande de alta temperatura (BIRDZ)", 
"alarm.type.birdz.stoppersistence":"Parar la persistencia (BIRDZ)", 
"alarm.type.birdz.irregularleak":"Fuga iregular (BIRDZ)", 
"alarm.type.integra.meterblocked":"Medidor bloqueado (INTEGRA)", 
"alarm.type.integra.minwatertemp":"Temperatura agua < 3°C (INTEGRA)", 
"alarm.type.integra.maxwatertemp":"Temperatura agua > 50°C (INTEGRA)", 
"alarm.type.integra.minambianttemp":"Temperatura ambiente baja > 50°C (INTEGRA)", 
"alarm.type.integra.maxambianttemp":"Temperatura ambiente > 70 ° C (INTEGRA)", 
"alarm.type.integra.lowbattery":"Bateria baja (INTEGRA)", 
"alarm.type.integra.bubbles":"Airo detectado (INTEGRA)", 
"alarm.type.integra.tamper":"Medidor manipulado (INTEGRA)", 
"alarm.type.integra.leak":"Fuga (INTEGRA)", 
"alarm.type.integra.burst":"Tuberia destruida (INTEGRA)", 
"alarm.type.integra.dry":"Tuberia seca (INTEGRA)", 
"alarm.type.integra.reverseflow":"Flujo inverso (INTEGRA)", 
"alarm.type.integra.asiccommunicationerror":"Error de comunicacion (INTEGRA)", 
"alarm.type.axioma.abdormalcondition":"Temperatura ambiente anromal (AXIOMA)", 
"alarm.type.axioma.burst":"Tuberia destruida (AXIOMA)", 
"alarm.type.axioma.burst.temporary":"Tuberia destruida temporal (AXIOMA)", 
"alarm.type.axioma.burst.permanent":"Tubería destruida permanente (AXIOMA)", 
"alarm.type.axioma.backflow":"Reflujo (AXIOMA)", 
"alarm.type.axioma.backflow.temporary":"Reflujo temporal (AXIOMA)", 
"alarm.type.axioma.backflow.permanent":"Reflujo permanente (AXIOMA)", 
"alarm.type.axioma.waterfreeze":"Agua congelada (AXIOMA)", 
"alarm.type.axioma.waterfreeze.temporary":"Agua congelada temporal (AXIOMA)", 
"alarm.type.axioma.waterfreeze.permanent":"Agua congelada permanente (AXIOMA)", 
"alarm.type.axioma.leakage":"Fuga (AXIOMA)", 
"alarm.type.axioma.leakage.temporary":"Fuga temporal (AXIOMA)", 
"alarm.type.axioma.leakage.permanent":"Fuga permanente (AXIOMA)", 
"alarm.type.axioma.lowbattery":"Bateria baja (AXIOMA)", 
"alarm.type.axioma.lowbattery.temporary":"Batería baja temporal (AXIOMA)", 
"alarm.type.axioma.lowbattery.permanent":"Bateria baja permanente (AXIOMA)", 
"alarm.type.axioma.dry":"Tubería seca (AXIOMA)", 
"alarm.type.axioma.dry.permanent":"Tubería seca permanente (AXIOMA)", 
"alarm.type.axioma.manipulation":"Manipulacion (AXIOMA)", 
"alarm.type.axioma.manipulation.permanent":"Manipulacion permanente (AXIOMA)", 
"alarm.type.axioma.lowtemperature":"Temperatura baja (AXIOMA)", 
"alarm.type.axioma.lowtemperature.permanent":"Temperatura baja permanente (AXIOMA)", 
"alarm.type.diehl.leakage":"Fuga (DIEHL)", 
"alarm.type.diehl.leakagehist":"Historico fuga (DIEHL)", 
"alarm.type.diehl.manipulation":"Manipulacion (DIEHL)", 
"alarm.type.diehl.manipulationhist":"Historico manipulacion (DIEHL)", 
"alarm.type.diehl.blocked":"Medidor bloqueado (DIEHL)", 
"alarm.type.diehl.blockedhist":"Historico medidor bloqueado (DIEHL)", 
"alarm.type.diehl.unclipped":"Desmontado (DIEHL)", 
"alarm.type.diehl.unclippedhist":"Desmontado historico (DIEHL)", 
"alarm.type.diehl.overflow":"Flujo alto (DIEHL)", 
"alarm.type.diehl.overflowhist":"Flujo alto historico (DIEHL)", 
"alarm.type.diehl.underflow":"Flujo bajo (DIEHL)", 
"alarm.type.diehl.underflowhist":"Flujo bajo historico (DIEHL)", 
"alarm.type.diehl.backflow":"Reflujo (DIEHL)", 
"alarm.type.diehl.backflowhist":"Reflujo historico (DIEHL)", 
"alarm.type.diehl.tempsensor":"Sensor de temperatura (DIEHL)", 
"alarm.type.diehl.tempsensorhist":"Sensor de temperatura historico (DIEHL)", 
"alarm.type.diehl.battery":"Bateria (DIEHL)", 
"alarm.type.diehl.batteryhist":"Bateria historico (DIEHL)", 
"alarm.type.birdz.startflowsmalldiameter":"Flujo inicial: calibre pequeño (BIRDZ)", 
"alarm.type.birdz.startflowbigdiameter":"Flujo inicial: calibre grande (BIRDZ)", 
"alarm.type.birdz.undercountbigdiameter":"Recuento insuficiente: gran diámetro (BIRDZ)", 
"alarm.type.birdz.watchdog":"WatchDog (BIRDZ)", 
"alarm.type.birdz.hourset":"Módulo de reinicio de tiempo (BIRDZ)", 
"alarm.type.birdz.anormalrestflowvalue":"Valor de flujo de descanso anormal (BIRDZ)", 
"alarm.type.birdz.supervisiontension":"Supervisión de voltaje (BIRDZ)", 
"alarm.type.birdz.modulereconfigured":"Reconfiguración del módulo (BIRDZ)", 
"alarm.type.birdz.solenoidvalve":"Válvula solenoide (BIRDZ)", 
"alarm.type.birdz.noisedefensemaxrssicrossed":"Defensa contra el ruido: límite RSSi cruzado (BIRDZ)", 
"alarm.type.birdz.noisedefenseradioreceptionsuspended":"Defensa contra el ruido: recepciones de radio suspendidas (BIRDZ)", 
"alarm.type.birdz.noisedefenselrlisteningsuspended":"Defensa contra el ruido: láminas suspendidas LR (BIRDZ)", 
"alarm.type.birdz.lowtemperatureradiostoppage":"Temperatura baja: liberación por radio (BIRDZ)", 
"alarm.type.birdz.limitimmediatealarmcycle":"Limitación de ciclos de alarma inmediata (BIRDZ)", 
"alarm.type.birdz.hoursetdelayexpiration":"Vencimiento del retardo de restablecimiento de tiempo (BIRDZ)", 
"alarm.type.birdz.acquisitionflooddefault":"Fallo de etapa de adquisición (BIRDZ)", 
"alarm.type.birdz.destinationtime":"Tiempo de residencia (BIRDZ)", 
"mask.text.select_at_least_once_meter":"Seleccione al menos 1 medidor", 
"mask.text.select_at_least_once_alarm":"Seleccione al menos 1 alarma", 
"columns.meter.serial":"$t(all.meter.meter_serial)", 
"columns.serial":"$t(all.meter.meter_serial)", 
"columns.radio.serial":"$t(all.meter.radio_serial)", 
"columns.withAlarm":"Con alarmas", 
"columns.alarms":"$t(all.alarm_meter.alarm_plural)", 
"columns.reference":"$t(all.column_text.pdi_ref)", 
"columns.state":"$t(all.pdi.state_pdi)", 
"columns.withAlarmMasked":"Con alarmas ocultas", 
"columns.triggerAlarms.leak":"Fecha de activación de la fuga", 
"columns.triggerAlarms.fraud":"Fecha de activacion del fraude", 
"columns.triggerAlarms.backflow":"Fecha de activacion del reflujo", 
"columns.closeAlarms.leak":"Fecha del final del periodo de la fuga", 
"columns.closeAlarms.fraud":"Fecha del final del periodo del fraude", 
"columns.closeAlarms.backflow":"Fecha del final del periodo del reflujo", 
"columns.codeTourneeReleve":"Codigo de la ruta leido", 
"columns.labelTourneeReleve":"Nombre de la ruta leido", 
"columns.splitAlarms.Fraudpoc":"Alarma fraude", 
"columns.splitAlarms.Fraudcurrent":"Alarma fraude activa", 
"columns.splitAlarms.Fraudpast":"Alarma fraude paso", 
"columns.splitAlarms.Leakpoc":"Alarma fuga", 
"columns.splitAlarms.Leakcurrent":"Alarma fuga activa", 
"columns.splitAlarms.Leakpast":"Alarma fuga paso", 
"columns.splitAlarms.Backflowpoc":"Alarma reflujo", 
"columns.splitAlarms.Backflowcurrent":"Alarma reflujo actual", 
"columns.splitAlarms.Backflowpast":"Alarma reflujo paso", 
"columns.splitAlarms.Batterycurrent":"Alarma bateria activa", 
"columns.splitAlarms.Batterypast":"Alarma bateria paso", 
"columns.splitAlarms.ModuleDefectcurrent":"Alarma módulo defectuoso activa", 
"columns.splitAlarms.ModuleDefectpast":"Alarma modulo defectuoso paso", 
"columns.splitAlarms.Clockcurrent":"Alarma reloj activa", 
"columns.splitAlarms.Clockpast":"Alarma reloj paso", 
"columns.splitAlarms.MeterBlockpoc":"Alarma medidor bloqueado", 
"columns.splitAlarms.MeterBlockpast":"Alarma medidor bloqueado paso", 
"columns.splitAlarms.Underflowpoc":"Alarma de flujo bajo", 
"columns.splitAlarms.Underflowpast":"Alarma de flujo bajo paso", 
"columns.splitAlarms.Underflowcurrent":"Alarma de flujo bajo activa", 
"columns.splitAlarms.Overflowpoc":"Alarma de desbordamiento", 
"columns.splitAlarms.Overflowcurrent":"Alarma de flujo alto activa", 
"columns.splitAlarms.Overflowpast":"Alarma de flujo alto paso", 
"columns.previousMeter.installDate":"Fecha del ultimo cambio de medidor", 
"columns.previousMeter.previousMountedMeterSerial":"Numero de serie del medidor anterior", 
"columns.previousMeter.deposeIndex":"Índice de depósitos", 
"columns.alarmsMasked":"Alarmas ocultas", 
"columns.simultaneousAlarms":"Alarmas activas simultáneas", 
"columns.clpInformations":"Informaciones suscriptor", 
"columns.customInformation.couleur_plombage":"$t(column_list.label.sealing_color)", 
"columns.customInformation.code_reseau":"$t(column_list.label.network_code)", 
"columns.customInformation.code_tournee":"Código de la ruta facturación", 
"columns.customInformation.name_tournee":"Nombre de la ruta facturacion", 
"columns.customInformation.etat_abonnement":"Estado suscripcion", 
"columns.customInformation.date_debut_abonnement":"Fecha de inicio de la suscripción", 
"columns.customInformation.date_fin_abonnement":"Fecha de finalisacion de la suscripcion", 
"columns.customInformation.complement_voie":"Otros informaciones", 
"columns.read.date":"$t(columns.rdsDate)", 
"columns.read.lastDate":"$t(all.read_meter.read_last_date)", 
"columns.read.meterIndex":"$t(all.meter.meter_index)", 
"columns.read.radioIndex":"Indice radio", 
"columns.read.consumption":"$t(all.read_meter.cons)", 
"columns.read.readMethod":"Método de lectura", 
"columns.read.source":"Fuente de lectura", 
"columns.read.previousDate":"Fecha anterior de la ultima lectura", 
"columns.read.previousMeterIndex":"Indice anterior del medidor", 
"columns.read.offsetIndex":"Radio de índice de desplazamiento", 
"columns.currentRead.date":"Fecha de lectura actual", 
"columns.currentRead.meterIndex":"Índice del contador actual", 
"columns.currentRead.radioIndex":"Índice de radio actual", 
"columns.currentRead.consumption":"Consumo actual", 
"columns.currentRead.readMethod":"Método de lectura actual", 
"columns.currentRead.source":"Fuente de lectura actual", 
"columns.currentRead.previousDate":"Fecha anterior de la lectura actual", 
"columns.currentRead.previousMeterIndex":"Índice anterior del valor actual del contador", 
"columns.currentRead.offsetIndex":"Desplazamiento del índice de corriente de radio", 
"columns.address.city":"$t(all.address.city)", 
"columns.address.country":"$t(all.address.country)", 
"columns.address.complement":"$t(all.address.complement)", 
"columns.address.street":"$t(all.address.street)", 
"columns.address.streetNumber":"$t(all.address.street_number)", 
"columns.address.streetNumberAdditionnal":"$t(all.address.street_number_additionnal)", 
"columns.address.concatAdress":"Dirreccion completo", 
"columns.address.zipcode":"$t(all.address.zipcode)", 
"columns.address.appartement":"$t(import_billing.label.pdi_appart)", 
"columns.address.batiment":"$t(import_billing.label.pdi_building)", 
"columns.address.etage":"$t(import_billing.label.pdi_floor)", 
"columns.address.escalier":"$t(import_billing.label.pdi_stair)", 
"columns.meter.diameter":"$t(all.meter.real_meter_diameter)", 
"columns.meter.model":"$t(all.meter.meter_model)", 
"columns.model":"$t(all.meter.meter_model)", 
"columns.meter.manufacturer":"$t(all.meter.meter_manufacturer)", 
"columns.manufacturer":"$t(all.meter.meter_manufacturer)", 
"columns.meter.mtrInformations":"informacion medidor", 
"columns.meter.startDate":"$t(all.text.install_date)", 
"columns.meter.lastUpdateDate":"Dernière mise à jour fiche", 
"columns.meter.endDate":"Etat du compteur", 
"columns.meter.fluidType":"Tipo de liquido", 
"columns.gpsPosition.alt":"$t(column_list.label.altitude)", 
"columns.gpsPosition.lat":"$t(all.text.latitude)", 
"columns.gpsPosition.lng":"$t(all.text.longitude)", 
"columns.radio.manufacturer":"$t(all.radio.radio_manufacturer)", 
"columns.radio.type":"Tipo de radio", 
"columns.radio.composeInternalSerial":"Número de serie interno de la radio", 
"columns.updatedAt":"$t(all.date.update_date)", 
"columns.name":"$t(all.text.name)", 
"columns.average":"$t(all.text.average)", 
"columns.type":"$t(columns.radio.type)", 
"columns.location.name":"$t(identity_chooser.text.location_name)", 
"columns.meter.mtrNote":"Message libre", 
"columns.previousRadio.installUnmount":"Date de démontage ancienne radio", 
"columns.previousRadio.previousMountedRadioSerial":"Numéro de série ancienne radio", 
"columns.meter.message":"Message", 
"columns.releveTerrain.previousIndex":"Index relève précédente", 
"columns.releveTerrain.currentIndex":"Index relève courante", 
"columns.releveTerrain.previousDate":"Date relève précédente", 
"columns.releveTerrain.currentDate":"Date relève courante", 
"columns.releveTerrain.consumption":"Consommation relève", 
"columns.releveTerrain.consumptionState":"Etat consommation relève courante", 
"columns.releveTerrain.seuilOverflow":"Anomalía de consumo personalizado", 
"columns.parentReference":"$t(pdi.PDI_PARENT_REFERENCE)", 
"columns.parentRelation":"$t(pdi.PDI_PARENT_RELATION)", 
"columns.ficheState":"Estado del enchufe", 
"columns.cardOrder":"Pedido", 
"columns.note":"Nota", 
"columns.marquage":"$t(all.text.marquage)", 
"columns.cptSerial":"$t(all.meter.meter_serial)", 
"columns.revisedCptSerial":"Numero de serie medidor revisado", 
"columns.installDate":"Ano instalacion", 
"columns.wheels":"Numero de ruedas", 
"columns.cptManufacturer":"Fabricante de medidores", 
"columns.mtrInformations":"$t(columns.meter.mtrInformations)", 
"columns.message":"$t(all.text.message)", 
"columns.particularMessageMeter":"$t(all.text.particular_msg)", 
"columns.hasGpsPosition":"$t(all.text.gps)", 
"columns.oldMeterEndDate":"Cambio", 
"columns.newMeterSerial":"NS nuevo medidor", 
"columns.installDateNewMeter":"$t(all.text.install_date)", 
"columns.radioSerial":"NS radio", 
"columns.radioManufacturer":"Fabricant del radio", 
"columns.amrReference":"Referencia AMR", 
"columns.indexed":"Indexado", 
"columns.modified":"$t(all.text.modified)", 
"columns.previousDate":"Fecha de la ultima lectura", 
"columns.previousIndex":"Indice de la ultima lectura", 
"columns.currentDate":"Fecha de la lectura actual", 
"columns.currentIndex":"Indice de la lectura actual", 
"columns.consoState":"Estado de consumo", 
"columns.updateDate":"$t(all.text.update_date)", 
"columns.lastUpdateByUser":"Editado por", 
"columns.facturationCode":"Código de facturacion", 
"columns.meterSerialNumber":"$t(columns.cptSerial)", 
"columns.radioSerialNumber":"$t(columns.radioSerial)", 
"columns.rdsDate":"Fecha de lectura", 
"columns.meterIndex":"Indice de medidor m3", 
"columns.withCreation":"$t(all.text.indexed)", 
"columns.t1CurrentExist":"Lectura reemplazada", 
"columns.readDate":"Fecha de lectura", 
"columns.compteurSerial":"$t(columns.meter.serial)", 
"columns.readCompteurIndex":"$t(columns.read.meterIndex)", 
"columns.consumption":"$t(columns.read.consumption)", 
"columns.readRadioIndex":"$t(columns.read.radioIndex)", 
"columns.radioSensorPort":"Puerto del sensor", 
"columns.clpReference":"$t(columns.reference)", 
"columns.readRadioPulse":"Pulso de radio", 
"columns.codeTournee":"$t(columns.codeTourneeReleve)", 
"columns.codeReseau":"$t(columns.customInformation.code_reseau)", 
"columns.nomTournee":"$t(columns.labelTourneeReleve)", 
"exportpdi.text.load_reads":"Carga de lecturas para exportar", 
"exportpdi.text.csv_export_processing":"Exportacion de CSV en proceso", 
"exportpdi.text.no_read_found":"Ninguna lectura encontrada", 
"columns.withPreviousAlarm":"Alarmas de lectura anterior", 
"columns.lastUpdate":"$t(all.date.update_date)", 
"columns.cptStatusId":"Estado de consumo", 
"columns.newDate":"Fecha de instalación del nuevo medidor", 
"columns.group.pdi":"$t(all.pdi.pdi)", 
"columns.group.triggeralarms":"Fecha de activacion", 
"columns.group.closealarms":"Fecha del final del periodo de alarma", 
"columns.group.splitalarms":"Alarmas unitaria", 
"columns.group.previousmeter":"$t(fiche_detail.title.old_meter)", 
"columns.group.custominformation":"$t(all.text.more_info_plural)", 
"columns.group.address":"$t(all.location.address)", 
"columns.group.read":"Lectura", 
"columns.group.currentread":"Lectura actual", 
"columns.group.meter":"$t(all.meter.meter)", 
"columns.group.gpsposition":"Localizacion", 
"columns.group.radio":"$t(all.radio.radio)", 
"columns.group.previousradio":"Radio précédente", 
"columns.group.releveterrain":"Relève terrain", 
"columns.exportname":"Nombre de la exportacion personalizado", 
"columns.exportfilename":"Nombre del archivo", 
"columns.exportowner.username":"Nombre del creador", 
"columns.datePreviousReleve":"$t(columns.previousDate)", 
"columns.listReleve_dateReleve":"Fecha del historial de facturación", 
"linefilter.operator.date.=":"Igual a", 
"linefilter.operator.date.>=":"Antes", 
"linefilter.operator.date.<=":"Despues", 
"linefilter.operator.date.vide":"Vacio", 
"linefilter.operator.date.notvide":"No vacio", 
"linefilter.operator.date.between":"Entre", 
"linefilter.operator.text.contient":"Contiene", 
"linefilter.operator.text.contientpas":"No contiene", 
"linefilter.operator.text.commencepar":"Empieza por", 
"linefilter.operator.text.finipar":"Termina por", 
"linefilter.operator.text.vide":"$t(linefilter.operator.date.vide)", 
"linefilter.operator.text.notvide":"$t(linefilter.operator.date.notvide)", 
"linefilter.operator.checkbox.true":"$t(all.text.yes)", 
"linefilter.operator.checkbox.false":"$t(all.text.no)", 
"linefilter.operator.state.checkbox.true":"$t(all.text.active)", 
"linefilter.operator.state.checkbox.false":"$t(all.text.inactive)", 
"linefilter.operator.number.>":"Superior a", 
"linefilter.operator.number.>=":"Superior o igual a", 
"linefilter.operator.number.<":"Inferior a", 
"linefilter.operator.number.<=":"Inferior o igual a", 
"linefilter.operator.number.=":"$t(linefilter.operator.date.=)", 
"linefilter.operator.number.!=":"Diferente de", 
"linefilter.operator.number.== null":"Es cero", 
"linefilter.operator.number.!= null":"No es cero", 
"linefilter.userinput.placeholder_input":"Opcion 1, Opcion 2, Opcion 3...", 
"linefilter.operator.selectcontvalue.=":"Es", 
"linefilter.operator.selectcontvalue.!=":"No es", 
"linefilter.operator.selectcont.=":"$t(linefilter.operator.selectcontvalue.=)", 
"linefilter.operator.selectcont.!=":"$t(linefilter.operator.selectcontvalue.!=)", 
"linefilter.operator.selectalarmtypebasic.=":"$t(linefilter.operator.selectcontvalue.=)", 
"linefilter.operator.selectalarmtypebasic.!=":"$t(linefilter.operator.selectcontvalue.!=)", 
"linefilter.operator.selectalarmtypebasic.contient":"$t(linefilter.operator.text.contient)", 
"linefilter.operator.selectalarmtypebasic.contientpas":"$t(linefilter.operator.text.contientpas)", 
"virtualmeter.text.name_already_exist":"Este nombre ya existe", 
"permission.text.read.location":"Acceso al ubicacion en lectura", 
"permission.text.edit.dashboard":"Gestion de cuadros de mando", 
"permission.text.edit.virtualMeter":"Gestion de medidores virtuales", 
"permission.text.edit.alert":"Gestion de alertas", 
"permission.text.edit.maskAlarm":"Gestion de máscaras de alarma", 
"permission.text.edit.locationInfo":"Modificacion de informaciones del sitio", 
"permission.text.edit.pdi":"Gestion de PDIs (asosiacion/disociacion)", 
"permission.text.edit.subLocation":"Creacion de subsitios", 
"permission.text.read.round":"Acceso de lectura a la ruta", 
"permission.text.use.synchro":"Capacidad para sincronizar / editar nombres de dispositivos", 
"permission.text.import.synchro":"Importar rutas / mensajes / marcas", 
"permission.text.edit.round":"Exportar / Eliminar una ruta", 
"permission.text.import.factu":"Posibilidad de importar archivos de facturación", 
"permission.text.export.brute":"Exportacion bruta", 
"permission.text.delete.phone":"Eliminar un móvil", 
"permission.text.edit.message":"Editar información de PDI", 
"users_list.text.view_informations":"Consultar la información", 
"users_list.text.block_user":"Bloquear usario", 
"users_list.text.unblock_user":"Debloquear usario", 
"users_list.text.delete_user":"Eliminar el usario", 
"tournee_fiches.text.recup_telereleve":"Recuperación de lectura remota", 
"tournee_fiches.text.recup_reads_telereleve":"Recuperación de lecturas remotas", 
"tournee_fiches.text.reads_get":"Recuperacion de lectura", 
"tournee_fiches.text.reads_get_plural":"Recuperacion de lecturas", 
"tournee_fiches.text.new_reads":"Nueva lectura", 
"tournee_fiches.text.new_reads_plural":"Nueva lecturas", 
"tournee_fiches.text.replaced_reads":"Lectura reemplazada", 
"tournee_fiches.text.replaced_reads_plural":"Lecturas reemplazadas", 
"tournee_fiches.text.recup_reads_processing":"Recuperacion de lecturas en curso", 
"tournee_fiches.text.no_read_found":"$t(exportpdi.text.no_read_found)", 
"tournee_fiches.text.replace_reads_by_more_recent":"Reemplace las lecturas existentes por otras más nuevas", 
"tournee_fiches.text.lookup_reads_processing":"Buscar lecturas actuales", 
"tournee_fiches.text.rotate_fiches":"Rotation des relèves", 
"tourneefiches.text.rotation_index_description":"Etes vous sur de vouloir démarrer une nouvelle relève pour cette tournée ?", 
"tourneefiches.text.rotation_index_warning":"La rotation des index est irréversible.", 
"rapport.name.amrRead":"Informe de datos de AMR", 
"rapport.name.cptRead":"Informe de datos de lectura", 
"rapport.name.cptReadOrderByOrder":"Informe de datos de lectura ordenados por orden", 
"rapport.name.cptReadOrderByDate":"Informe de datos de lectura ordenados por fecha", 
"rapport.name.cptReadOrderByStatus":"Informe de datos de lectura ordenados por estado", 
"rapport.name.resumeRead":"Resumé de lecturas", 
"rapport.name.agingMeter":"Envejecimiento del parque de medidores", 
"rapport.name.cptDetail":"Detalles de medidor", 
"rapport.name.amrDetail":"Detalles de radio", 
"rapport.name.enqueteDetail":"Detalles de encuestas", 
"rapport.name.cptReadAndAmr":"Informe de datos de AMR y datos de lectura", 
"rapport.name.quickSummary":"Resumen de datos", 
"rapport.name.statusReleve":"Estado de los datos", 
"rapport.option.sort_by":"Ordenar por", 
"rapport.option.sorted_by":"Ordenado por", 
"rapport.option.view_pdf":"Visualizar el PDF", 
"rapport.option.full_screen":"Pantalla completa", 
"rapport.option.preview_pdf":"Vista previa en PDF", 
"rapport.text.no_report_selected":"Ningun informe seleccionado", 
"rapport.error.generic":"Se ha producido un error durante la creación del informe", 
"rapport.error.no_data":"No hay datos suficientes para generar un informe de rutas", 
"import_silex.text.import_rounds":"Importacion de rutas", 
"import_silex.text.round_file":"Archivo de ruta", 
"import_silex.columns.identifiant":"Nombre de usario", 
"import_silex.columns.field":"$t(enquete.text.field)", 
"import_silex.columns.temoin_value":"Valor testigo", 
"import_silex.columns.generated_value":"Valor generado", 
"import_silex.text.wrong_encoding":"Error : La codificación del archivo [{{filename}}] [{{fileCharset}}] no coincide con los parámetros definidos [{{paramCharset}}]", 
"import_silex.text.load_file_failed":"No se pudo cargar el archivo Silex [{{filename}}]", 
"import_silex.text.select_round":"Elegir una ruta", 
"import_silex.text.silex_format":"Formato Silex", 
"import_silex.error_text.lines_incorrect_length":"La línea contiene un número de caracteres distinto de 512", 
"import_silex.error_text.no_line_id":"Falta el tipo de registro", 
"import_silex.error_text.silex_error":"El archivo a importar contiene errores", 
"import_silex.error_text.no_round_code":"Falta el código de recorrido", 
"import_silex.error_text.no_order_number":"Falta el número de pedido", 
"import_silex.error_text.no_subscriber_info":"Faltan datos del abonado", 
"import_silex.error_text.no_meter_info":"Falta información sobre el contador", 
"import_silex.error_text.no_theoretical_serial":"Falta el número de serie teórico", 
"import_silex.error_text.no_theoretical_old_serial":"$t(import_silex.error_text.no_theoretical_serial)", 
"import_silex.error_text.no_change_meter_authorisation":"Falta la autorización de cambio de contador", 
"import_silex.error_text.no_amr_install_date":"Falta la fecha de instalación del AMR", 
"import_silex.error_text.no_amr_index_number":"Falta el número de índice AMR", 
"import_silex.error_text.no_meter_max_value":"Falta el valor máximo del contador", 
"import_silex.error_text.no_meter_unit":"Falta la unidad de lectura del contador", 
"import_silex.error_text.no_amr_reference":"Falta la referencia AMR", 
"import_silex.error_text.no_amr_serial":"Falta el número de serie AMR", 
"import_silex.error_text.no_amr_internal_id":"Falta el identificador AMR interno", 
"import_silex.error_text.no_amr_data":"Faltan datos AMR", 
"import_silex.error_text.no_pulse_weight":"Falta el peso del pulso", 
"import_silex.error_text.no_amr_max_value":"Falta el valor máximo del AMR", 
"import_silex.error_text.no_previous_read_date":"Falta la fecha de la lectura anterior", 
"import_silex.error_text.no_previous_meter_index":"Falta la lectura anterior del contador", 
"import_silex.error_text.no_previous_amr_index":"Falta el índice AMR anterior", 
"import_silex.error_text.no_previous_alarms":"Faltan las alarmas AMR anteriores", 
"import_silex.error_text.no_previous_status":"Falta el anterior estatuto AMR", 
"import_silex.error_text.no_new_serial":"Falta el número de serie del nuevo contador", 
"import_silex.error_text.no_number_of_digits":"Falta el número de dígitos", 
"import_silex.error_text.no_start_index":"Falta el índice inicial", 
"import_silex.error_text.no_install_date":"Falta la fecha de instalación del AMR", 
"import_silex.error_text.no_meter_serial":"Falta el número de serie del contador", 
"import_silex.error_text.no_read_date":"Falta la fecha del cambio", 
"import_silex.error_text.no_meter_index":"Falta el índice del contador", 
"import_silex.error_text.no_backflow_index":"Falta el índice de agua de retorno", 
"import_silex.error_text.no_fraud_alarm":"Falta la alarma antifraude", 
"import_silex.error_text.no_leak_alarm":"Falta la alarma de fuga", 
"import_silex.error_text.no_backflow_alarm":"Falta la alarma de reflujo", 
"import_silex.error_text.no_battery_alarm":"Falta la alarma de batería", 
"import_silex.error_text.no_faulty_module_alarm":"Falta la alarma de fallo del módulo", 
"import_silex.error_text.no_clock_alarm":"Falta la alarma del reloj", 
"import_silex.error_text.no_page_number":"Falta el número de página de la encuesta", 
"import_silex.error_text.no_actual_serial":"Falta el número de serie actual", 
"import_silex.error_text.missing_t0_empty":"No se ha encontrado ninguna línea de registro de giras", 
"import_silex.error_text.missing_t1_empty":"No counter record line found", 
"import_silex.error_text.missing_t2_empty":"No se ha encontrado ninguna línea de registro AMR", 
"import_silex.error_text.missing_t5_empty":"No se han encontrado líneas de definición de páginas de encuestas", 
"import_silex.error_text.missing_t6_empty":"No se han encontrado líneas de respuesta", 
"import_silex.error_text.missing_t2":"Radio número {{serial}}", 
"import_silex.error_text.missing_t5":"Falta la encuesta {{page}}", 
"import_silex.error_text.missing_t6":"Falta la respuesta a la encuesta {{page}} correspondiente al contador {{serial}}", 
"import_silex.error_text.wrong_data":"Los datos no respetan el formato deseado", 
"import_silex.error_text.meter_in_round":"Uno o varios contadores ya están asociados a otras rutas", 
"import_silex.column_label.errors":"Errores", 
"import_silex.column_label.lines":"Líneas", 
"import_silex.column_label.types":"Tipos", 
"import_silex.column_label.positions":"Puestos", 
"import_silex.column_label.description":"Descripción", 
"import_silex.column_label.associated_round":"Rutas asociadas", 
"provisioning.text.provisionned":"Aprovisionamiento", 
"provisioning.text.not_provisionned":"Desaprovisionamiento", 
"provisioning.text.actions":"Acciones", 
"telereleveinfo.text.network":"Red", 
"telereleveinfo.text.deveui":"DevEUI", 
"telereleveinfo.text.last_telereleve_read":"Fecha de la ultima lectura remota", 
"telereleveinfo.text.meter_index":"$t(widget.display_value.meterreadingvalue)", 
"telereleveinfo.text.radio_index":"$t(widget.display_value.radioreadingvalue)", 
"telereleveinfo.text.provision_radio":"Aprovisionamiento", 
"telereleveinfo.text.deprovision_radio":"Desaprovisionamiento", 
"telereleveinfo.text.provision_impossible":"Provisioning imposible", 
"telereleveinfo.text.missing_informations":"Falta información requerida", 
"telereleveinfo.text.reprovision_impossible_message":"Si decide desaprovisionar el módulo, ya no podrá aprovisionarlo porque el falta la información necesaria!", 
"telereleve.text.telereleve":"Lecturas remotas", 
"telereleve.column.networkName":"Protocolo", 
"telereleve.column.clpReference":"$t(all.pdi.pdi)", 
"telereleve.column.mtrSerialNumber":"$t(all.meter.meter_serial)", 
"telereleve.column.radioSerialNumber":"$t(all.meter.radio_serial)", 
"telereleve.column.devEUI":"$t(telereleveinfo.text.deveui)", 
"telereleve.column.providerName":"Nombre de red", 
"telereleve.column.canProvision":"Aprovisionable", 
"telereleve.column.adress":"$t(all.location.address)", 
"telereleve.text.load_telereleve":"Carga de lecturas remotas", 
"provisionning.text.provision_processing":"Provisionning en curso", 
"custom_export.title.custom_export":"Exportacion personalizada", 
"custom_export.title.custom_export_plural":"Exportaciones personalizadas", 
"custom_export.text.add_custom_export":"Agregar un exportacion personalizadas", 
"custom_export.text.export_name":"Nombre de la exportacion", 
"custom_export.text.export_filename":"Nombre del archivo generado", 
"custom_export.text.edit_custom_export":"Modificar la exportacion personalizada", 
"custom_export.text.duplicate":"Exportación personalizada duplicada", 
"custom_export.text.launch_export":"Iniciar la exportación personalizada", 
"custom_export.text.delete_export":"Eliminar exportacion personalizada", 
"custom_export.text.define_at_launch":"Establecido en el lanzamiento", 
"custom_export.text.sorting":"Clasificacion", 
"custom_export.text.add_filter_value_to_filename":"Agregar filtros de valor al nombre del archivo", 
"custom_export.text.generatedDate":"Fecha de generacion", 
"custom_export.text.generatedHour":"Hora de generacion", 
"custom_export.text.nameSite":"$t(identity_chooser.text.location_name)", 
"gateway.text.gateway":"Puerta", 
"gateway_meter_captured.columns.field":"Número de medidores recogidos", 
"gateway_geolocated.columns.field":"Gateway géolocalisée", 
"gateway_name.text.map":"Nom de la gateway", 
"gateway_geolocated_meter_captured.columns.field":"Nombre de compteurs géolocalisés captés", 
"gateway_geolocated_meter_last_read.text":"Nombre de compteurs géolocalisés captés de la dernière relève", 
"gateway.registration_date":"Date d'enregistrement de la gateway", 
"gayeway.last_communication_date":"Date de dernière communication de la gateway", 
"gateway.model":"Modèle de la gateway", 
"gateway.informations":"Informations de la gateway", 
"manage_pdi.text.nb_meter_to_link":"{{number}} medidores para asociar", 
"manage_pdi.text.nb_meter_to_unlink":"{{number}} medidores para disociar", 
"mapbox_button.text.save_polygon":"Sauvegarder le(s) polygone(s)", 
"mapbox.return_all_gateways":"Retourner à l'affichage de toutes les gateways", 
"tournee.text.export.success":"La ruta {{tournee}} se ha exportado correctamente", 
"tournee.text.export.error_detected":"Se ha producido un error durante la exportación de la ruta {{tournee}}", 
"tournee.text.export_factu.success":"La ruta {{tournee}} se ha exportado correctamente a la facturation", 
"tournee.text.forbidden":"$t(tournee.text.unauthorized)", 
"tournee.text.unauthorized":"No autorizado", 
"tournee.text.failure":"Fallo", 
"tournee.text.notfound":"Irrastreable", 
"tournee.text.success":"Exportado", 
"tournee.text.export_round_csv_custom":"Exportación de rutas personalizados", 
"tournee.text.export_round_csv_custom_choices":"Tipo de exportación", 
"tournee.text.export_dat":"DAT", 
"tournee.text.export_csv":"CSV", 
"tournee.text.error.lines_incorrect_length":"Lignes de longueur incorrecte", 
"tournee.text.error.wrongData":"Donnée erronée", 
"tournee.text.error.no_line_id":"Id de ligne manquant", 
"tournee.text.error.silex_format_error":"Error de formato Silex", 
"tournee .text.error.last_try":"Ultimo intento : {{time}}", 
"tournee.text.export.csv.config.not_set":"Las configuraciones de exportación CSV no se rellenan correctamente", 
"tournee.text.import.csv.config.not_set":"Las configuraciones de importación CSV no se rellenan correctamente", 
"galery.title":"Foto", 
"galery.title_plural":"Fotos", 
"galery.others":"otros", 
"galery.picture_name":"nombre de la imagen", 
"galery.fold":"doblar", 
} 
}; 
