import React from "react";
import {
  Box,
  Checkbox,
  Chip,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import EditionBleu from "../SvgComponents/EditionBleu";

interface MultipleSelectInputProps {
  handleChange: Function;
  placeholder: string;
  text: string;
  isInvalid: boolean | null;
  isValid: boolean;
  hasChanged: boolean;
  items: Array<string>;
  value: string[];
}
const MultipleSelectInput: React.FC<MultipleSelectInputProps> = ({
  handleChange,
  placeholder,
  text,
  value,
  items,
  isInvalid,
  isValid,
  hasChanged,
}) => {
  return (
    <div className="styled-input-select">
      <div className="form__group field">
        <InputLabel
          htmlFor="name"
          className={`form__label ${isInvalid ? "error" : ""} ${
            isValid ? "valid" : ""
          }`}
        >
          {text}
          {hasChanged && (
            <EditionBleu
              height="16px"
              fill={isInvalid ? "#ff2281" : "#38ef7d"}
            />
          )}
        </InputLabel>
        <Select
          className={`form__field ${isInvalid ? "error" : ""} ${
            isValid ? "valid" : ""
          }`}
          placeholder={placeholder}
          multiple
          required
          value={value}
          renderValue={(selected: any) => (
            <Box style={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {Array.isArray(selected) &&
                selected.map((value: any) => (
                  <Chip key={value} label={value} />
                ))}
            </Box>
          )}
          onChange={(e: any) => handleChange(e)}
          MenuProps={{
            getContentAnchorEl: () => null,
          }}
        >
          {items.map((it: string) => (
            <MenuItem key={it} value={it}>
              <Checkbox checked={value.indexOf(it) > -1} />
              <ListItemText primary={it} />
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default MultipleSelectInput;
