import { authHeader, handleResponse } from "../_helpers";

async function getAllDevicesInfo(gestionnaireId: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId.toString()}/devices/status`,
    requestOptions
  );
  return handleResponse(response);
}

async function getAllRoundsInfo(gestionnaireId: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId.toString()}/rounds/status`,
    requestOptions
  );
  return handleResponse(response);
}

async function getLabels(gestionnaireId: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/etiquettes`,
    requestOptions
  );
  return handleResponse(response);
}

async function addAffect(affect: any, gestionnaireId: any, deviceId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(affect),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${deviceId}/affect/add`,
    requestOptions
  );
  return handleResponse(response);
}

async function delAffect(affect: any, gestionnaireId: any, deviceId: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(affect),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${deviceId}/affect/remove`,
    requestOptions
  );
  return handleResponse(response);
}

async function addDesaffect(
  desaffect: any,
  gestionnaireId: any,
  deviceId: any
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(desaffect),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${deviceId}/deaffect/add`,
    requestOptions
  );
  return handleResponse(response);
}

async function delDesaffect(
  desaffect: any,
  gestionnaireId: any,
  deviceId: any
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(desaffect),
  };
  let response = await fetch(
    `/api/synchro/gestionnaire/${gestionnaireId}/devices/${deviceId}/deaffect/remove`,
    requestOptions
  );
  return handleResponse(response);
}

const reportService = {
  getAllDevicesInfo,
  getAllRoundsInfo,
  getLabels,
  addAffect,
  addDesaffect,
  delAffect,
  delDesaffect,
};

export default reportService;
