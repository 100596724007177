import { ChartData } from "../_entities/sheet";
import moment from "moment";

/**
 * Convertit les données de chart en données de tableau
 *
 * @param {ChartData} data Données de chart
 * @param {string}    type Type de donnée
 * @returns {any} Les données parsées
 */
export default function chart2table(
  data: ChartData,
  type: string,
  t: Function
): any {
  const colText: any = {
    index: t("all.meter.index"),
    conso: t("all.read_meter.cons"),
    alarm: t("all.alarm_meter.alarm_type"),
  };

  const columns = [
    {
      dataField: "label",
      text: t("all.date.date"),
    },
    {
      dataField: "value",
      text: colText[type],
    },
  ];

  let tableData: any[] = [];
  switch (type) {
    case "alarm":
      if (data && data.labels && data.values) {
        tableData = data.labels.map((it: any, index: any) => {
          let types: any[] = [];
          data.values.forEach((it: any) => {
            if (it.data[index] > 0) {
              types.push(it.label);
            }
          });
          return {
            label: moment(it).format(
              `DD/MM/YYYY ${moment.HTML5_FMT.TIME_SECONDS}`
            ),
            value: types.join(", "),
            id: index + 1,
          };
        });
      }

      break;
    case "index":
    case "conso":
      if (data && data.labels && data.values) {
        tableData = data.labels.map((it: any, index: any) => {
          return {
            label: moment(it).format(
              `DD/MM/YYYY ${moment.HTML5_FMT.TIME_SECONDS}`
            ),
            value: data.values[index],
            id: index + 1,
          };
        });
      }
      break;
  }

  return {
    columns,
    data: tableData,
  };
}
