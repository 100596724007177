import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";
import StepWizard from "react-step-wizard";
import { alertActions, localeActions, locationActions } from "../_actions";
import IdentityChooser from "./IdentityChooser";
import ContactChooser from "./ContactChooser";
import LocationNote from "./LocationNote";
import LocationAdressChooser from "./LocationAdressChooser";
import LocationAdressDisplay from "./LocationAdressDisplay";
import AjoutBleu from "../SvgComponents/AjoutBleu";
import PointCollecteVert from "../SvgComponents/PointCollecteVert";
import { alert, templates } from "../_interfaces/reducers";
import { Permission } from "../_entities/user";
import { getAuthUser } from "../_helpers";
import Confirm from "../_components/Confirm";
import _ from "lodash";
import EditionBleu from "../SvgComponents/EditionBleu";
import { withTranslation } from "react-i18next";

type Props = {
  alert?: alert;
  className?: string;
  dispatch?: any;
  templates?: templates;
  fromLocation?: number;
  padding?: string;
  editLocation?: number;
  user: any;
  locations: any;
  users: any;
  t: any;
  edited?: Function;
  history: any;
};

type State = {
  isEdit: any;
  form: any;
  modal: boolean;
  saved: boolean;
  steps: any;
  editingMode: boolean;
};

/**
 * @class WidgetTemplateCreatorComponent
 * @extends {React.Component<Props, State>}
 */
class LocationCreator extends React.Component<Props, State> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof WidgetTemplateCreatorComponent
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isEdit: !_.isEmpty(
        props.history.location.pathname.match(/\/locations\/\d+/)
      ),
      form: {},
      modal: false,
      saved: false,
      steps: {
        location: 2,
        displayLocation: 3,
        contact: 4,
        note: 5,
      },
      editingMode: false,
    };
    this.updateForm = this.updateForm.bind(this);
    this.getWritingLocations = this.getWritingLocations.bind(this);
    this.onStepChange = this.onStepChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleAutoClose = this.toggleAutoClose.bind(this);
    this.send = this.send.bind(this);
    this.getTitle = this.getTitle.bind(this);
  }

  /**
   * Met à jour le state si l'alerte affiche un succès
   *
   * @static
   * @param {Props} props
   * @param {State} state
   * @returns {State} Le nouveau state
   * @method getDerivedStateFromProps
   * @memberof WidgetTemplateCreatorComponent
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    const { editingMode } = state;
    const { locations, user, history } = props;
    const locPathname = history.location.pathname;
    if (props.alert.type === "alert-success") {
      return {
        form: state.form,
        modal: state.modal,
        saved: true,
      };
    }

    if (
      !editingMode &&
      locations &&
      locations.fetchedLocation &&
      locPathname.match(/\/locations\/\d+/)
    ) {
      const editLocation = _.cloneDeep(locations.fetchedLocation);
      const parentPermission = _.find(user.permissions, (el) => {
        return _.isEqual(el.location.id, editLocation.groupId);
      });
      const parentLocation = parentPermission && parentPermission.location;
      return {
        form: editLocation && {
          identity: {
            name: editLocation.name,
            parentCode: parentLocation && parentLocation.code,
            parentName: parentLocation && parentLocation.name,
          },
          location: {
            address: editLocation.content.address,
            gpsPosition: editLocation.content.gpsPosition,
          },
          contact: editLocation.content.contacts[0],
          note: editLocation.content.note,
        },
        editingMode: true,
        modal: state.modal,
        saved: state.saved,
      };
    }

    return null;
  }

  /**
   * Récupère les datasources au montage du
   * composant
   *
   * @method componentDidMount
   * @memberof WidgetTemplateCreatorComponent
   */
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(localeActions.load());
    dispatch(locationActions.getAll("STOCK"));
  }

  /**
   * Ferme la modale en cas de réussite à la sauvegarde
   *
   * @method componentDidUpdate
   * @memberof WidgetTemplateCreatorComponent
   */
  componentDidUpdate() {
    const { alert } = this.props;
    if (alert && alert.type === "alert-success") {
      this.toggleAutoClose();
    }
  }

  onStepChange = (stats: any) => {};

  /**
   * Met à jour les informations du formulaire
   *
   * @param {string} key
   * @param {string} value
   * @method updateForm
   * @memberof WidgetTemplateCreatorComponent
   */
  updateForm(key: any, value: any) {
    const { form }: any = this.state;
    form[key] = value;
    this.setState({ form });
  }

  /**
   * Récupère la liste des sites où l'utilisateur a le droit d'écriture
   *
   dispatch(locationActions.getAll());
   * @method getWrintingLocations
   * @memberof WidgetTemplateCreatorComponent
   */
  getWritingLocations() {
    const { user, locations }: any = this.props;
    const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN"];
    if (
      user &&
      user.fetchedUser &&
      roleList.includes(user.fetchedUser.role.name) &&
      locations.items
    ) {
      return locations.items.filter((el) => el.name !== "STOCK");
    }
    if (user && user.fetchedUser && locations.items) {
      return user.fetchedUser.profils
        .filter((profilEl: any) =>
          profilEl.profil.permissions.find(
            (el: any) => el.name === "edit.subLocation"
          )
        )
        .map((el: any) =>
          locations.items.find(
            (location: any) => location.code === el.locationCode
          )
        );
    }
    return null;
  }

  /**
   * Gère l'ouverture et fermeture de la modal
   *
   * @method toggle
   * @memberof WidgetTemplateCreatorComponent
   */
  async toggle() {
    const { dispatch } = this.props;
    const { modal, isEdit } = this.state;
    if (_.isEmpty(this.state.form) || isEdit) {
      dispatch(alertActions.clear());
      this.setState({
        modal: !modal,
        saved: false,
        form: {},
        editingMode: false,
      });
    } else {
      const result = await Confirm.confirm({
        title: (
          <Fragment>
            <strong>Supprimer le site en cours de création ?</strong>
          </Fragment>
        ),
        message:
          "Cette action irreversible supprimera toutes les données saisies sur ce site.",
        confirmText: "Supprimer",
        confirmColor: "danger",
        cancelColor: "primary",
        cancelText: "Abandonner",
      });

      if (result) {
        dispatch(alertActions.clear());
        this.setState({
          modal: !modal,
          saved: false,
          form: {},
        });
      }
    }
  }

  /**
   * Gère la fermeture de la modal à la sauvegarde
   *
   * @method toggleAutoClose
   * @memberof WidgetTemplateCreatorComponent
   */
  toggleAutoClose() {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
    this.setState({
      saved: true,
      modal: false,
    });
  }

  /**
   * Gère la sauvegarde du template
   *
   * @method send
   * @memberof WidgetTemplateCreatorComponent
   */
  send() {
    const { form, isEdit }: any = this.state;
    const { dispatch, locations, edited } = this.props;
    if (isEdit) {
      const locationEdited = {
        code: locations.fetchedLocation.code,
        name: form.identity.name,
        address: form.location && form.location.address,
        gpsPosition: form.location && form.location.gpsPosition,
        contacts: [form.contact],
        note: form.note,
      };

      dispatch(locationActions.update(locationEdited));
      if (edited) edited();
    } else {
      dispatch(locationActions.save(form));
    }
  }

  getTitle() {
    const { t } = this.props;
    const { isEdit } = this.state;
    return isEdit
      ? t("location_creator.title.edit_location")
      : t("location_creator.title.add_location");
  }

  couldEditOrCreate = () => {
    const { user, locations } = this.props;
    const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN"];
    if (roleList.includes(user.fetchedUser.role.name)) {
      return true;
    }
    if (locations.fetchedLocation && locations.fetchedLocation.tournee) {
      //CETTE LIGNE ALEXIS
      return true;
    }
    if (
      user &&
      user.fetchedUser &&
      user.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find(
            (permission: any) => permission.name === "edit.subLocation"
          ) && el.locationCode === locations.fetchedLocation.code
      )
    ) {
      return true;
    }
    return false;
  };

  canAddSubLoc = () => {
    const { user, locations } = this.props;
    const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN"];
    if (roleList.includes(user.fetchedUser.role.name)) {
      return true;
    }
    if (locations.fetchedLocation && locations.fetchedLocation.tournee) {
      //CETTE LIGNE ALEXIS
      return true;
    }
    if (
      user &&
      user.fetchedUser &&
      user.fetchedUser.profils.find((el: any) =>
        el.profil.permissions.find(
          (permission: any) => permission.name === "edit.subLocation"
        )
      )
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { alert, className, fromLocation, padding, user, t } = this.props;
    const { isEdit, modal, form, steps } = this.state;
    const locationsWrite = this.getWritingLocations();
    const authUser = getAuthUser();
    const custom = {
      enterRight: "",
      enterLeft: "",
      exitRight: "",
      exitLeft: "",
    };
    return (
      <Fragment>
        {user.fetchedUser && this.canAddSubLoc() && (
          <div
            className="round"
            id="addWidget"
            onClick={this.toggle}
            role="presentation"
            style={{ position: "relative" }}
          >
            {isEdit ? (
              <span className="absoluteCentered">
                <EditionBleu height="1em" width="1em" fill="#2c303b" />
              </span>
            ) : (
              <span className="absoluteCentered" style={{ margin: 0 }}>
                <AjoutBleu
                  className="add"
                  width="1em"
                  height="1em"
                  fill="curentcolor"
                />
              </span>
            )}
            {isEdit ? (
              <UncontrolledTooltip placement="bottom" target="addWidget">
                {" "}
                {t("location_creator.title.edit_location")}
              </UncontrolledTooltip>
            ) : (
              <UncontrolledTooltip placement="bottom" target="addWidget">
                {" "}
                {t("location_creator.title.add_location")}
              </UncontrolledTooltip>
            )}
          </div>
        )}

        <Modal
          backdrop="static"
          keyboard={false}
          isOpen={modal}
          toggle={this.toggle}
          className={className}
        >
          <ModalHeader toggle={this.toggle}>
            <PointCollecteVert height="1.5em" width="1.5em" fill="#31c6b3" />{" "}
            &nbsp; {this.getTitle()}
          </ModalHeader>
          <ModalBody>
            <div>
              {alert.message && (
                <div className={`alert ${alert.type}`}>{alert.message}</div>
              )}
            </div>
            {locationsWrite && (
              <div className="col-md-12">
                <StepWizard
                  transitions={custom}
                  onStepChange={this.onStepChange}
                >
                  <IdentityChooser
                    hashKey="IdentityChooser"
                    update={this.updateForm}
                    send={this.send}
                    locations={locationsWrite}
                    isEdit={isEdit}
                    steps={steps}
                    form={form}
                    toggle={this.toggle}
                    fromLocation={fromLocation}
                  />
                  <LocationAdressChooser
                    hashKey="LocationAdressChooser"
                    update={this.updateForm}
                    form={form}
                    steps={steps}
                  />
                  <LocationAdressDisplay
                    hashKey="LocationAdressDisplay"
                    update={this.updateForm}
                    form={form}
                    steps={steps}
                  />
                  <ContactChooser
                    hashKey="ContactChooser"
                    update={this.updateForm}
                    form={form}
                    steps={steps}
                  />
                  <LocationNote
                    hashKey="LocationNote"
                    update={this.updateForm}
                    form={form}
                    steps={steps}
                  />
                </StepWizard>
              </div>
            )}
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { alert, locale, users, locations } = state;
  return {
    user: users,
    alert,
    locale,
    locations,
  };
}

const wtc: any = connect(mapStateToProps)(LocationCreator);
const LocationCreatorConnected = withRouter(wtc);
const tr = withTranslation()(LocationCreatorConnected);
export default tr;
