import { filterConstants } from "../_constants";
import filterService from "../_services/filter.service";
import alertActions from "./alert.actions";
import { FilterConfig } from "../_interfaces/filter";

/**
 * Récupère tous les filtres correspondant au contexte
 * passé en paramètre, et lié à l'utilisateur courant
 *
 * @method getAll
 * @param {number} userId Id de l'utilisateur
 * @param {number} idContext Id du contexte
 * @returns {Object} Les filtres
 */
function getAll(userId: number, idContext: number) {
  function request() {
    return {
      type: filterConstants.GET_REQUEST,
    };
  }

  function success(filters: Object) {
    return {
      type: filterConstants.GET_SUCCESS,
      filters,
    };
  }

  function failure(error: Object) {
    return {
      type: filterConstants.GET_FAILURE,
      error,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    filterService
      .getAllByType(userId, "" + idContext)
      .then((filters: Object) => {
        dispatch(success(filters));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Sauvegarde le filtre dans le contexte passé en paramètre,
 * sur l'utilisateur courant
 *
 * @method save
 * @param {string} nameFilter Nom du filtre
 * @param {number} userId Id de l'utilisateur
 * @param {number} idContext Id du contexte
 * @param {Object} filter Le filtre
 * @returns {Object} L'état de la sauvegarde
 */
function save(
  nameFilter: string,
  userId: number,
  idContext: number,
  filter: Object,
  optionOr: boolean
) {
  const toSave: any = {
    userId,
    content: filter,
    extraOptions: { separator: optionOr ? "or" : "and" },
    name: nameFilter,
  };
  function success(savedFilter: Object) {
    return {
      type: filterConstants.SAVE_SUCCESS,
      savedFilter,
    };
  }
  return (dispatch: Function) => {
    filterService
      .save(toSave, userId, "" + idContext)
      .then((savedFilter: Object) => {
        dispatch(success(savedFilter));
        dispatch(alertActions.success("Opération réussie"));
      })
      .catch((error: Object) => {
        dispatch(alertActions.error(error));
      });
  };
}

/**
 * Met à jour le filtre relatif au contexte passé en paramètre,
 * sur l'utilisateur courant
 *
 * @method update
 * @param {Object} filter Le filtre
 * @param {number} filterId L'id du filtre
 * @param {string} nameFilter Nom du filtre
 * @param {number} userId Id de l'utilisateur
 * @param {number} idContext Id du contexte
 * @returns {Object} L'état de la mise à jour
 */
function update(
  filter: Object,
  filterId: number,
  nameFilter: string,
  userId: number,
  idContext: number,
  optionOr: boolean
) {
  const toSave: any = {
    userId,
    content: filter,
    extraOptions: { separator: optionOr ? "or" : "and" },
    name: nameFilter,
  };
  function success(updatedFilter: Object) {
    return {
      type: filterConstants.UPDATE_SUCCESS,
      updatedFilter,
    };
  }
  return (dispatch: Function) => {
    filterService
      .update(toSave, filterId, userId, "" + idContext)
      .then((updatedFilter: Object) => {
        dispatch(success(updatedFilter));
        dispatch(alertActions.success("Opération réussie"));
      })
      .catch((error: Object) => {
        dispatch(alertActions.error(error));
      });
  };
}

/**
 * Supprime le filtre passé en paramètre
 *
 * @method deleteFilter
 * @param {FilterConfig | undefined} filter Filtre
 * @param {number} filterId Id du filtre
 * @param {number} userId Id de l'utilisateur
 * @param {number} idContext Id du contexte
 * @returns {Object} L'état de la suppression
 */
function deleteFilter(
  filter: FilterConfig | undefined,
  filterId: number,
  userId: number,
  idContext: number
) {
  function success() {
    return {
      type: filterConstants.DELETE_SUCCESS,
      deletedFilter: filter,
    };
  }
  return (dispatch: Function) => {
    filterService
      .deleteFilter(filterId, userId, "" + idContext)
      .then(() => {
        dispatch(success());
        dispatch(alertActions.success("Opération réussie"));
      })
      .catch((error: Object) => {
        dispatch(alertActions.error(error));
      });
  };
}

/**
 * Vide le state des filtres
 *
 * @method clear
 */
function clear() {
  return {
    type: filterConstants.CLEAR,
  };
}

const filtreActions = {
  getAll,
  save,
  update,
  deleteFilter,
  clear,
};

export default filtreActions;
