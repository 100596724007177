import React from "react";
import "./style.scss";
import MultiSelectionButton from "./MultiSelectionButton";
import DragButton from "./DragButton";
import styled from "styled-components";
import Col from "./Col";
import synchrocomActions from "../../_actions/synchrocom.actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import synchroActions from "../../_actions/synchro.actions";

const Container = styled.div`
  display: flex;
  z-index: 0;
  width: 95%;
`;

const columns = ["round", "device"];

const DragDrop = (props) => {
  const { hoverMulti, dispatch, match } = props;
  const { params } = match;
  const { GestionnaireId } = params;

  const handleMultiSelection = (on) => {
    dispatch(synchrocomActions.multiDragChange(on));
  };

  const onClickCancelAll = (a, b) => {
    dispatch(synchrocomActions.cancelAll(GestionnaireId));
  };

  const getAllCommunication = (a, b) => {
    dispatch(synchroActions.comWithAll(GestionnaireId));
  };
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ display: "flex", zIndex: 0, width: "95%" }}>
        {columns.map((col) => (
          <Col column={col} />
        ))}
      </div>
      <div className="multi-drag-bar">
        <MultiSelectionButton
          handleChangeChecked={handleMultiSelection}
          hoverMulti={hoverMulti}
        />
        <DragButton
          onClickCancelAll={onClickCancelAll}
          getAllCommunication={getAllCommunication}
        />
      </div>
    </div>
  );
};

function mapStateToProps() {}

const mapping = connect(mapStateToProps)(DragDrop);
const connectedComponent = withRouter(mapping);
const tr = withTranslation()(connectedComponent);
export default tr;
