import React, { ComponentType, Fragment } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { alarmActions, alertActions, localeActions } from "../_actions";
import Ajout from "../SvgComponents/AjoutBleu";
import AlarmVert from "../SvgComponents/AlarmeVert";
import { alarms, alert } from "../_interfaces/reducers";
import { User } from "../_entities/user";
import { translate } from "../_helpers";
import { compose } from "redux";

type Props = {
  alert: alert;
  dispatch: any;
  alarms: alarms;
  locationId: any;
  locationCode?: string;
  availableUsers: Array<User>;
  locales: any;
  history: any;
  t: Function;
};

type State = {
  form: any;
  alarmTemplates: Array<any>;
  modal: boolean;
  saved: boolean;
  alarmSelected: string;
};

/**
 * @class AlarmCreatorComponent
 * @extends {React.Component<Props, State>}
 */
class AlarmCreatorComponent extends React.Component<Props & {}, State> {
  /**
   * Met à jour le state si l'alerte affiche un succès
   *
   * @static
   * @param {Props} props
   * @param {State} state
   * @returns {State} Le nouveau state
   * @method getDerivedStateFromProps
   * @memberof AlarmCreatorComponent
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    const form = state.form;
    if (props.alert.type === "alert-success") {
      return {
        form,
        alarmTemplates: state.alarmTemplates,
        modal: state.modal,
        saved: true,
      };
    }
    return {
      form,
      alarmTemplates: state.alarmTemplates,
      modal: state.modal,
      saved: state.saved,
    };
  }

  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof AlarmCreatorComponent
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        users: [],
        content: {
          dataSourceProperty: {
            condition: [],
          },
        },
        locationCode: props.locationCode,
      },
      alarmTemplates: [],
      modal: false,
      saved: false,
      alarmSelected: "",
    };
  }

  /**
   * Récupère les types d'alarme au montage du
   * composant
   *
   * @method componentDidMount
   * @memberof AlarmCreatorComponent
   */
  componentDidMount() {
    const { dispatch, locationCode } = this.props;
    this.updateForm("saved", false);
    this.updateForm("locationCode", locationCode);
    this.updateForm("users", []);
    dispatch(localeActions.load());
    alarmActions
      .getAllTemplates()
      .then((alarmTemplates) => {
        this.setState({ alarmTemplates });
        const content = alarmTemplates[0] ? alarmTemplates[0].content : {};
        this.updateForm("content", content);
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(alertActions.error(error));
      });
  }

  /**
   * Ferme la modale en cas de réussite à la sauvegarde
   *
   * @method componentDidUpdate
   * @memberof AlarmCreatorComponent
   */
  componentDidUpdate() {
    const { alert } = this.props;
    const { form, modal } = this.state;
    if (alert && alert.type === "alert-success" && form.saved && modal) {
      this.toggleAutoClose();
    }
  }

  onStepChange = (stats: any) => {};

  /**
   * Met à jour les informations du formulaire
   *
   * @param {string} key
   * @param {string} value
   * @method updateForm
   * @memberof AlarmCreatorComponent
   */
  updateForm = (key: any, value: any) => {
    const { form }: any = this.state;
    form[key] = value;
    this.setState({ form });
  };

  /**
   * Gère l'ouverture et fermeture de la modal
   *
   * @method toggle
   * @memberof AlarmCreatorComponent
   */
  toggle = () => {
    const { dispatch } = this.props;
    const { modal } = this.state;
    this.setState({
      modal: !modal,
      saved: false,
      form: {
        users: [],
        content: {
          dataSourceProperty: {
            condition: [],
          },
        },
        saved: false,
      },
    });
  };

  /**
   * Gère la fermeture de la modal à la sauvegarde
   *
   * @method toggleAutoClose
   * @memberof AlarmCreatorComponent
   */
  toggleAutoClose = () => {
    const { dispatch } = this.props;
    const { modal } = this.state;
    this.setState({
      saved: true,
      modal: !modal,
      form: {
        users: [],
        content: {
          dataSourceProperty: {
            condition: [],
          },
        },
        saved: false,
      },
    });
  };

  /**
   * Gère la sauvegarde de l'alarme
   *
   * @method send
   * @memberof AlarmCreatorComponent
   */
  send = () => {
    const { form }: any = this.state;
    const { dispatch, alarms, locationCode } = this.props;

    const { content } = form;
    const alarm = {
      active: true,
      content,
      users: form.users,
      locationCode,
    };
    dispatch(alarmActions.save(alarm));
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { alert, locationId, locationCode, locales, history, t } = this.props;
    const { saved, modal, alarmTemplates, form, alarmSelected } = this.state;
    const columns = [
      {
        dataField: "name",
        text: t("all.column_text.name"),
      },
      {
        dataField: "unit",
        text: t("all.text.unity"),
        sort: true,
      },
      {
        dataField: "extraInfo",
        text: t("all.text.more_informations_plural"),
        sort: true,
      },
    ];
    const data =
      alarmTemplates.map((item) => ({
        value: item.content.alertName,
        name: t(`alert_creator.name.${item.content.alertName.toLowerCase()}`),
        unit: t(
          `alert_creator.unit.${item.content.dataSourceProperty.alertUnit.toLowerCase()}`
        ),
        extraInfo: t(
          `alert_creator.extra_info.${item.content.alertName.toLowerCase()}`
        ),
      })) || [];
    const rowEvents = {
      onClick: (e: Object, row: any) => {
        this.setState({
          alarmSelected: row.value,
        });
      },
    };
    const selectRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      bgColor: "#31c6b3",
    };
    const path = `?alarm=${alarmSelected}`;
    return (
      <Fragment>
        <div id="addAlert" onClick={this.toggle} role="presentation">
          <Ajout className="add" />
          <UncontrolledTooltip placement="bottom" target="addAlert">
            {" "}
            {t("alarm_creator.text.add_alert")}
          </UncontrolledTooltip>
        </div>
        <Modal isOpen={modal} toggle={this.toggle} backdrop size="lg">
          <ModalHeader toggle={this.toggle}>
            <AlarmVert height="1em" width="1em" fill="currentcolor" /> &nbsp;{" "}
            {t("alarm_creator.title.create_alert")}{" "}
          </ModalHeader>
          <ModalBody>
            <div className="table-info-container">
              <BootstrapTable
                keyField="name"
                data={data}
                rowEvents={rowEvents}
                columns={columns}
                bootstrap4
                bordered
                condensed
                hover
                filter={filterFactory()}
                selectRow={selectRow}
                striped
                rowClasses="clickable"
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toggle}>
              {t("all.button.cancel")}
            </Button>
            <Button
              color="primary"
              disabled={!(alarmSelected && alarmSelected.length > 0)}
              onClick={() =>
                history.push(`/locations/${locationId}/alerts/add${path}`)
              }
              className="button-alarm-configurator"
            >
              {t("all.text.configure")}
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, alarms, alert, locales } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    alarms,
    locales,
  };
}

const connectedAlarms = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(AlarmCreatorComponent);
const tr = withTranslation()(connectedAlarms);
export default tr;
