export { default as authHeader } from "./auth-header";
export { default as authHeaderFile } from "./auth-header-file";
export { default as history } from "./history";
export {
  handleResponse,
  logout,
  handleResponseWithData,
} from "./response-helper";
export { default as jwtHelper, getAuthUser } from "./jwthelper";
export { default as store } from "./store";
export { default as formatDashboardName } from "./dashboard-helper";
export { default as translate, recursiveTranslate } from "./locale-helpers";
export { default as colorize } from "./colorize-helper";
export { default as alarmHelper } from "./alarms-types-helper";
export {
  default as getActiveClassByPath,
  getClassNameByRegexp,
} from "./active-sidebar";
export { default as userPasswordHelper } from "./user-helper";
export {
  formatAdditionalData,
  formatMultiMeterData,
} from "./statements-helper";
export { printSheet, printMultiSheet } from "./pdf-helper";
export { default as chart2table } from "./chart2table-helper";
export { default as getDisplayId } from "./widget-display-helper";

export const REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[!@#$%^&*a-zA-Z\d]{8,}$/;
export const REGEXP8 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[!@#$%a-zA-Z\d]{8}$/;

export const REGEXVIRTUAL = /^[a-zA-Z0-9 \-_]{0,100}$/;

export const secondsToTime = (secs: number) => {
  let hours = Math.floor(secs / (60 * 60));

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
    h: hours,
    m: minutes,
    s: seconds,
  };
  return obj;
};
