import React, { Component, ComponentType, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import { alertActions, importActions, localeActions } from "../_actions";
import Ajout from "../SvgComponents/AjoutBleu";
import PointCollecteVert from "../SvgComponents/PointCollecteVert";
import { alert, templates } from "../_interfaces/reducers";
import { Permission } from "../_entities/user";
import Tag from "./Tag";
import _ from "lodash";
import synchroActions from "../_actions/synchro.actions";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "redux";

type Props = {
  alert: alert;
  className: string;
  dispatch: any;
  templates: templates;
  type: any;
  match: any;
  history: any;
  withRedirection: any;
} & WithTranslation;

type State = {
  form: any;
  modal: boolean;
  saved: boolean;
  fileName: string;
  invalidFile: boolean;
  file: any;
  tags: any;
};

/**
 * @class WidgetTemplateCreatorComponent
 * @extends {React.Component<Props, State>}
 */
class ListAdder extends Component<Props, State> {
  /**
   * Met à jour le state si l'alerte affiche un succès
   *
   * @static
   * @param {Props} props
   * @param {State} state
   * @returns {State} Le nouveau state
   * @method getDerivedStateFromProps
   * @memberof WidgetTemplateCreatorComponent
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.alert.type === "alert-success") {
      return {
        form: state.form,
        modal: state.modal,
        saved: true,
      };
    }
    return {
      form: state.form,
      modal: state.modal,
      saved: state.saved,
    };
  }

  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof WidgetTemplateCreatorComponent
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {},
      modal: false,
      saved: false,
      fileName: "",
      invalidFile: false,
      file: null,
      tags: [],
    };
    this.updateForm = this.updateForm.bind(this);
    this.getWritingLocations = this.getWritingLocations.bind(this);
    this.onStepChange = this.onStepChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleAutoClose = this.toggleAutoClose.bind(this);
    this.send = this.send.bind(this);
    this.onChange = this.onChange.bind(this);
    this.invalidFile = this.invalidFile.bind(this);
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(localeActions.load());
  }

  onChange(e: any) {
    const { dispatch } = this.props;
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    this.setState({ file: formData });
    dispatch(importActions.clear());
  }

  invalidFile() {
    const { dispatch } = this.props;
    dispatch(importActions.clear());
    this.setState({ file: null });
  }

  /**
   * Ferme la modale en cas de réussite à la sauvegarde
   *
   * @method componentDidUpdate
   * @memberof WidgetTemplateCreatorComponent
   */
  componentDidUpdate() {
    const { alert } = this.props;
    if (alert && alert.type === "alert-success") {
      this.toggleAutoClose();
    }
  }

  onStepChange = (stats: any) => {};

  /**
   * Met à jour les informations du formulaire
   *
   * @param {string} key
   * @param {string} value
   * @method updateForm
   * @memberof WidgetTemplateCreatorComponent
   */
  updateForm(key: any, value: any) {
    const { form }: any = this.state;
    form[key] = value;
    this.setState({ form });
  }

  /**
   * Récupère la liste des sites où l'utilisateur a le droit d'écriture
   *
    dispatch(locationActions.getAll());
   * @method getWrintingLocations
   * @memberof WidgetTemplateCreatorComponent
   */
  getWritingLocations() {
    const { user }: any = this.props;
    if (user && user.permissions && user.permissions.length > 0) {
      return user.permissions
        .filter((p: Permission) => p.locationRole === "WRITER")
        .map((p: Permission) => p.location);
    }
    return;
  }

  /**
   * Gère l'ouverture et fermeture de la modal
   *
   * @method toggle
   * @memberof WidgetTemplateCreatorComponent
   */
  toggle() {
    const { dispatch } = this.props;
    const { modal } = this.state;
    dispatch(alertActions.clear());
    this.setState({
      modal: !modal,
      saved: false,
      file: null,
      tags: [],
    });
  }

  /**
   * Gère la fermeture de la modal à la sauvegarde
   *
   * @method toggleAutoClose
   * @memberof WidgetTemplateCreatorComponent
   */
  toggleAutoClose() {
    const { dispatch } = this.props;
    const { modal } = this.state;
    dispatch(alertActions.clear());
    this.setState({
      saved: true,
      modal: !modal,
    });
  }

  /**
   * Gère la sauvegarde du template
   *
   * @method send
   * @memberof WidgetTemplateCreatorComponent
   */
  send() {
    const { dispatch, type, match, t } = this.props;
    const { file, tags } = this.state;
    const { params } = match;
    let copyTags = _.cloneDeep(tags).filter(
      (tag: any) => tag.code.length > 0 && tag.label.length > 0
    );
    if (tags.length > 0) {
      type === t("all.text.msg")
        ? dispatch(synchroActions.addMessages(copyTags, params.GestionnaireId))
        : dispatch(
            synchroActions.addMarquages(copyTags, params.GestionnaireId)
          );
    } else {
      if (file) {
        type === t("all.text.msg")
          ? dispatch(importActions.importMessagesFile(file))
          : dispatch(importActions.importMarquagesFile(file));
      }
    }
    this.setState({
      modal: false,
    });
  }

  addTag = () => {
    const { tags } = this.state;
    const copyTags = _.cloneDeep(tags);
    copyTags.push({
      code: "",
      label: "",
    });
    this.setState({
      tags: copyTags,
    });
  };

  updateTag = (value: string, index: number, type: string) => {
    const { tags } = this.state;
    const copyTags = _.cloneDeep(tags);
    copyTags[index][type] = type === "code" ? value.toUpperCase() : value;
    this.setState({
      tags: copyTags,
    });
  };

  deleteRow = (indexTag: number) => {
    const { tags } = this.state;
    const copyTags = _.cloneDeep(tags);
    copyTags.splice(indexTag, 1);
    this.setState({
      tags: copyTags,
    });
  };

  redirectImportTournee = (urlRedirection: string) => {
    const { history, match } = this.props;
    history.push({
      pathname: `/gestionnaires/${match.params.GestionnaireId}/synchronisation/${urlRedirection}`,
    });
  };

  render() {
    const { className, type, withRedirection, t } = this.props;
    const { saved, modal, tags, file } = this.state;

    return (
      <Fragment>
        <div style={{ display: "flex" }}>
          <div
            id="addWidget"
            className="round"
            onClick={
              withRedirection
                ? () => this.redirectImportTournee(withRedirection)
                : this.toggle
            }
            role="presentation"
          >
            <Ajout className="add" fill="curentcolor" width="20px" />
            <UncontrolledTooltip placement="bottom" target="addWidget">
              {" "}
              {
                t("list_adder.modal_header.add_x_type", {
                  type: type.toLowerCase(),
                }) as string
              }
            </UncontrolledTooltip>
          </div>
        </div>
        <Modal isOpen={modal} toggle={this.toggle} className={className}>
          <ModalHeader toggle={this.toggle}>
            <PointCollecteVert height="1.5em" width="1.5em" fill="#31c6b3" />{" "}
            &nbsp; {t("list_adder.modal_header.add_x_type", { type })}
          </ModalHeader>
          <ModalBody>
            {tags.length === 0 && (
              <FormGroup>
                {/* @ts-ignore */}
                <h5 for="dataFile">{t("list_message_add_file")}</h5>
                <Input
                  type="file"
                  name="file"
                  id="dataFile"
                  onChange={this.onChange}
                />
              </FormGroup>
            )}
            {!file && tags.length === 0 && (
              <h5 style={{ textAlign: "center" }}>{t("list_message_or")}</h5>
            )}
            {!file && (
              <div>
                <h5>{t("list_message_manually")}</h5>
                {tags.map((tag: any, index: number) => (
                  <Tag
                    tag={tag}
                    tagIndex={index}
                    updateTag={this.updateTag}
                    deleteRow={this.deleteRow}
                  />
                ))}
                <div
                  id="addSynchro"
                  className="round"
                  onClick={this.addTag}
                  role="presentation"
                  style={{ marginTop: "10px" }}
                >
                  <Ajout className="add" fill="curentcolor" width="20px" />
                  <UncontrolledTooltip placement="bottom" target="addSynchro">
                    {" "}
                    {t("list_message_add")}{" "}
                    {type.toLowerCase().substring(type.length - 1, 0)}
                  </UncontrolledTooltip>
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            {!saved && (
              <>
                <Button color="danger" onClick={this.toggle}>
                  {t("all.button.cancel")}
                </Button>
                <Button color="success" onClick={this.send}>
                  {t("all.button.validate")}
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { alert, locale, users } = state;
  return {
    users,
    alert,
    locale,
  };
}

const connectedListAdder = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(ListAdder);
const tr = withTranslation()(connectedListAdder);
export default tr;
