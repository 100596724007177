import React from "react";
import { Alert } from "reactstrap";
import MainNavbar from "../_components/MainNavbar";
import Sidebar from "../_components/Sidebar";
import logo from "../_svgs/logo_fd_blanc.svg";

/**
 * Rend le layout principal
 *
 * @method MainLayout
 * @param props Props du composant
 */
export default function MainLayout(props: any) {
  const { children } = props;
  return (
    <div>
      <div className="wrapper">
        <Sidebar />
        <div id="main-panel" className="main-panel">
          <div className="CrystalMainHeader synchronisation flex-box">
            <div className="logo-sidebar">
              <img src={logo} alt="Logo" />
            </div>
            <MainNavbar />
          </div>
          <section
            style={{ marginLeft: "280px" }}
            className="container text-muted dash"
          >
            {children}
          </section>
        </div>
      </div>
    </div>
  );
}
