import reportConstants from "../_constants/report.constants";
import { supportConstants } from "../_constants";

export default function support(state: any = {}, action: any) {
  switch (action.type) {
    case supportConstants.GET_GENERAL_IMPORT_BILLING_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case supportConstants.GET_GENERAL_IMPORT_BILLING_SETTING_SUCCESS:
      const newImpotBillingState = state.importbilling || {};
      newImpotBillingState.general = action.generalSetting;
      return {
        ...state,
        loading: false,
        importbilling: newImpotBillingState,
      };
    case supportConstants.REFRESH_ABO_PORTAL_FAILURE:
      return {
        ...state,
        refresh: false,
      };
    case supportConstants.REFRESH_ABO_PORTAL_REQUEST:
      return {
        ...state,
        refresh: true,
      };
    case supportConstants.REFRESH_ABO_PORTAL_SUCCESS:
      return {
        ...state,
        refresh: false,
      };
    case supportConstants.GET_GENERAL_IMPORT_BILLING_SETTING_FAILURE:
      return {
        loading: false,
        error: "Impossible d'obtenir la liste des configurations générales",
      };
    case supportConstants.SEND_ALL_IMPORT_BILLING_SETTING_REQUEST:
    case supportConstants.GET_COLUMN_IMPORT_BILLING_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case supportConstants.GET_COLUMN_IMPORT_BILLING_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        importbilling: {
          ...state.importbilling,
          column: action.columnSetting.columns,
        },
      };
    case supportConstants.GET_COLUMN_IMPORT_BILLING_SETTING_FAILURE:
      return {
        loading: false,
        error: "Impossible d'obtenir la liste des configurations générales",
      };
    case supportConstants.AUTOCHANGE_CONFIG_REQUEST:
    case supportConstants.GET_AUTOCHANGE_IMPORT_BILLING_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case supportConstants.AUTOCHANGE_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        importbilling: {
          ...state.importbilling,
          autochange: action.autoChangeSetting,
        },
      };
    case supportConstants.GET_AUTOCHANGE_IMPORT_BILLING_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        importbilling: {
          ...state.importbilling,
          autochange: action.autoChangeSetting,
        },
      };
    case supportConstants.GET_AUTOCHANGE_IMPORT_BILLING_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error:
          "Impossible d'obtenir la liste des configurations de changement automatique",
      };
    case supportConstants.SEND_BILLING_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        importbilling: {
          ...state.importbilling,
          autochange: action.autoChangeSetting,
        },
      };
    case supportConstants.SEND_BILLING_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error:
          "Impossible d'obtenir la liste des configurations de changement automatique",
      };
    case supportConstants.AUTOCHANGE_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        error:
          "Echec de l'importation des configurations de changements automatiques",
      };
    case supportConstants.SEND_ALL_IMPORT_BILLING_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Importation des configurations réussit",
      };
    case supportConstants.SEND_ALL_IMPORT_BILLING_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Impossible d'importer les configurations",
      };
    case supportConstants.GET_CUSTOM_GENERAL_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case supportConstants.GET_CUSTOM_GENERAL_SETTING_SUCCESS:
    case supportConstants.SEND_CUSTOM_GENERAL_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        general: {
          ...state.general,
          general: action.setting,
        },
      };
    case supportConstants.GET_CUSTOM_REPORT_SETTING_SUCCESS:
    case supportConstants.SEND_CUSTOM_REPORT_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        report: {
          ...state.general,
          general: action.setting,
        },
      };
    case supportConstants.GET_CUSTOM_GENERAL_SETTING_FAILURE:
      return {
        loading: false,
        error: "Impossible d'obtenir la liste des configurations générales",
      };
    case supportConstants.GET_SYNC_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case supportConstants.GET_SYNC_SETTING_SUCCESS:
    case supportConstants.SEND_SYNC_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        sync: {
          ...state.sync,
          general: action.syncSetting,
        },
      };
    case supportConstants.GET_SYNC_SETTING_FAILURE:
      return {
        loading: false,
        error:
          "Impossible d'obtenir la liste des configurations de synchronisation",
      };

    case supportConstants.GET_SILEX_ENCODINGS_REQUEST:
      return {
        ...state,
      };
    case supportConstants.GET_SILEX_ENCODINGS_SUCCESS:
      return {
        ...state,
        encodings: action.encodings,
      };
    case supportConstants.GET_SILEX_ENCODINGS_FAILURE:
      return {
        ...state,
        error: "Impossible d'obtenir la liste des configurations d'encodage",
      };
    case supportConstants.GET_SILEX_ENCODING_METHODS_REQUEST:
      return {
        ...state,
      };
    case supportConstants.GET_SILEX_ENCODING_METHODS_SUCCESS:
      return {
        ...state,
        encodingMethods: action.encodingMethods,
      };
    case supportConstants.GET_SILEX_ENCODING_METHODS_FAILURE:
      return {
        ...state,
        error: "Impossible d'obtenir la liste des configurations d'encodage",
      };
    case supportConstants.GET_SILEX_DELIMITER_REQUEST:
      return {
        ...state,
      };
    case supportConstants.GET_SILEX_DELIMITER_SUCCESS:
      return {
        ...state,
        delimiters: action.delimiters,
      };
    case supportConstants.GET_SILEX_DELIMITER_FAILURE:
      return {
        ...state,
        error:
          "Impossible d'obtenir la liste des configurations de délimiteurs",
      };
    case supportConstants.GET_EXPORT_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case supportConstants.GET_EXPORT_SETTING_SUCCESS:
    case supportConstants.SEND_EXPORT_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        export: {
          ...state.export,
          general: action.exportSetting,
        },
      };
    case supportConstants.GET_EXPORT_SETTING_FAILURE:
      return {
        loading: false,
        error: "Impossible d'obtenir la liste des configurations d'export",
      };
    case supportConstants.GET_SCRIPT_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case supportConstants.GET_SCRIPT_SETTING_SUCCESS:
      console.log("scriptSetting", action.scriptSetting);
      return {
        ...state,
        loading: false,
        script: action.scriptSetting,
      };
    case supportConstants.SEND_SCRIPT_SETTING_SUCCESS:
      return { ...state, loading: false };
    case supportConstants.GET_SCRIPT_SETTING_FAILURE:
      return {
        loading: false,
        error: "Impossible d'obtenir la liste des configurations de script",
      };
    case supportConstants.GET_REMOTE_READING_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case supportConstants.GET_REMOTE_READING_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        remotereading: {
          ...state.remotereading,
          setting: action.remotereadingSetting,
        },
      };
    case supportConstants.GET_REMOTE_READING_SETTING_FAILURE:
      return {
        loading: false,
        error: "Impossible d'obtenir la liste des configurations de télérelève",
      };
    case supportConstants.GET_GENERAL_REMOTE_READING_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case supportConstants.GET_GENERAL_REMOTE_READING_SETTING_SUCCESS:
    case supportConstants.SEND_REMOTE_READING_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        remotereading: {
          ...state.remotereading,
          general: action.generalRemoteReadingSetting,
        },
      };
    case supportConstants.GET_GENERAL_REMOTE_READING_SETTING_FAILURE:
      return {
        loading: false,
        error:
          "Impossible d'obtenir la liste des configurations général de télérelève",
      };
    case supportConstants.GET_COLUMN_REMOTE_READING_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case supportConstants.GET_COLUMN_REMOTE_READING_SETTING_SUCCESS:
    case supportConstants.SEND_COLUMN_REMOTE_READING_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        remotereading: {
          ...state.remotereading,
          column: action.columnRemoteReadingSetting,
        },
      };
    case supportConstants.GET_COLUMN_REMOTE_READING_SETTING_FAILURE:
      return {
        loading: false,
        error:
          "Impossible d'obtenir la liste des configurations général de télérelève",
      };
    case supportConstants.GET_LOGS_REQUEST:
      return {
        loading_log: true,
      };
    case supportConstants.GET_LOGS_SUCCESS:
      return {
        logs: action.logs,
      };
    case supportConstants.GET_LOGS_FAILURE:
      return {
        error: "ERROR LOGS",
      };
    case supportConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
