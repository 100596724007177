import React from "react";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import logo from "../_svgs/logo_fd_blanc.svg";
import PointCollecteVert from "../SvgComponents/PointCollecteVert";
import MesInformationsBlanc from "../SvgComponents/MesInformationsBlanc";
import LoupeBleu from "../SvgComponents/LoupeBleu";
import { getActiveClassByPath } from "../_helpers";
import HelpModal from "./HelpModal";
import ImportBleu from "../SvgComponents/ImportBleu";
import GestionUtilisateurBlanc from "../SvgComponents/GestionUtilisateurBlanc";
import { FaHome } from "react-icons/fa";
import SvgTourneesVertes from "../SvgComponents/TourneesVertes";
import SvgCleVerte from "../SvgComponents/CleVert";
import { WithTranslation, withTranslation } from "react-i18next";
import _ from "lodash";

type Props = {
  user: any;
  users: any;
  location: any;
  history: any;
  dispatch: any;
  match: any;
} & WithTranslation;

interface State {
  isOpen: boolean;
}

/**
 * @class Sidebar
 * @extends Component
 */
class Sidebar extends React.Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    const { user, history } = this.props;
    if (user === undefined) {
      history.push("/signin");
    }
  }

  /**
   * @method toggle
   */
  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  isAdmin = () => {
    const { user, users } = this.props;
    return (
      user &&
      ["DIOPTASE", "SUPERADMIN", "ADMIN"].includes(
        user.role
          ? _.get(user, "role.name")
          : _.get(users, "fetchedUser.role.name")
      )
    );
  };

  isDioptase = () => {
    const { user } = this.props;
    return user && _.get(user, "role.name") === "DIOPTASE";
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { isOpen } = this.state;
    const { user, location, t } = this.props;
    console.log("here");
    return (
      <div className="sidebar">
        <Navbar className="sidebar-wrapper" color="green" light expand="md">
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar>
              <div className="logo-sidebar" />
              <NavItem className="sidebar-item">
                <NavLink
                  href="/"
                  className={getActiveClassByPath(location.pathname, "")}
                >
                  <FaHome />
                  {t("sidebar.nav_link.home")}
                </NavLink>
              </NavItem>
              <NavItem className="sidebar-item">
                <NavLink
                  href="/locations?root=STOCK"
                  className={getActiveClassByPath(
                    location.pathname,
                    "\\?root=STOCK",
                    location.search
                  )}
                >
                  <PointCollecteVert
                    height="1em"
                    width="1em"
                    stroke="currentcolor"
                    fill="currentcolor"
                    strokeWidth="0"
                  />
                  {t("sidebar.nav_link.location_plural")}
                </NavLink>
              </NavItem>
              <NavItem className="sidebar-item">
                <NavLink
                  href="/locations?root=TOURNEES"
                  className={getActiveClassByPath(
                    location.pathname,
                    "\\?root=TOURNEES",
                    location.search
                  )}
                >
                  <SvgTourneesVertes
                    height="1em"
                    width="1em"
                    stroke="currentcolor"
                    fill="currentcolor"
                    strokeWidth="0"
                  />
                  {t("sidebar.nav_link.round_plural")}
                </NavLink>
              </NavItem>
              {this.isAdmin() && (
                <NavItem className="sidebar-item">
                  <NavLink
                    href="/profils"
                    className={getActiveClassByPath(
                      location.pathname,
                      "profils"
                    )}
                  >
                    <SvgCleVerte
                      height="1em"
                      width="1em"
                      stroke="currentcolor"
                      fill="currentcolor"
                      strokeWidth="0"
                    />
                    {t("sidebar.nav_link.profile_manager_plural")}
                  </NavLink>
                </NavItem>
              )}
              {this.isAdmin() && (
                <NavItem className="sidebar-item">
                  <NavLink
                    href="/users/"
                    className={getActiveClassByPath(location.pathname, "users")}
                  >
                    <MesInformationsBlanc
                      height="1em"
                      width="1em"
                      stroke="currentcolor"
                      fill="currentcolor"
                      strokeWidth="0"
                    />
                    {t("sidebar.nav_link.user_manager_plural")}
                  </NavLink>
                </NavItem>
              )}
              <NavItem className="sidebar-item">
                <NavLink
                  href={`/users/${user.id}`}
                  className={getActiveClassByPath(location.pathname, "users")}
                >
                  <GestionUtilisateurBlanc
                    height="1em"
                    width="1em"
                    stroke="currentcolor"
                    fill="currentcolor"
                    strokeWidth="0"
                  />
                  {t("sidebar.nav_link.my_info_plural")}
                </NavLink>
              </NavItem>
              {this.isAdmin() && (
                <NavItem className="sidebar-item">
                  <NavLink
                    className={getActiveClassByPath(
                      location.pathname,
                      "import"
                    )}
                    href="/import"
                  >
                    <ImportBleu
                      fill="currentcolor"
                      stroke="currentcolor"
                      height="1em"
                      width="1em"
                    />
                    {t("sidebar.nav_link.import_billing")}
                  </NavLink>
                </NavItem>
              )}
              <NavItem className="sidebar-item">
                <NavLink
                  href="/search"
                  className={getActiveClassByPath(location.pathname, "search")}
                >
                  <LoupeBleu
                    height="1em"
                    width="1em"
                    stroke="currentcolor"
                    fill="currentcolor"
                    strokeWidth="0"
                  />
                  {t("sidebar.nav_link.global_search")}
                </NavLink>
              </NavItem>
              <HelpModal buttonSize="md" mini={false} />
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, match, users } = state;
  const { user } = authentication;
  return {
    user,
    match,
    users,
  };
}

const mapping: any = connect(mapStateToProps)(Sidebar);

const connectedSidebar = withRouter(mapping);
const tr = withTranslation()(connectedSidebar);
export default tr;
