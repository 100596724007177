import * as turf from "@turf/turf";
import { FeatureCollection, GeoJSONObject } from "@turf/turf";
import _ from "lodash";
import { EventData, GeoJSONSourceRaw, Map } from "mapbox-gl";
import { Gateway, Meter } from "../interface";

function selectedLines(dataSource: Array<Meter>, event: EventData) {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: dataSource
        .filter(
          (el: Meter) =>
            el.telereleveGatewayInfo &&
            el.telereleveGatewayInfo.name === event.features[0].properties.name
        )
        .filter((el: Meter) => el.gpsPosition.lat)
        .map((item: Meter, idx: number) => {
          return {
            id: idx,
            type: "Feature",
            properties: {
              selected: false,
              distance: turf.distance(
                [item.gpsPosition.lng, item.gpsPosition.lat],
                [
                  item.telereleveGatewayInfo.gpsPosition.lng,
                  item.telereleveGatewayInfo.gpsPosition.lat,
                ],
                { units: "kilometers" }
              ),
              gatewayId: item.telereleveGatewayInfo.id,
              gatewayName: item.telereleveGatewayInfo.name,
              meterSerial: item.meterSerial,
              rssi: item.rssi,
            },
            geometry: {
              type: "LineString",
              coordinates: [
                [item.gpsPosition.lng, item.gpsPosition.lat],
                [
                  item.telereleveGatewayInfo.gpsPosition.lng,
                  item.telereleveGatewayInfo.gpsPosition.lat,
                ],
              ],
            },
          };
        }),
    },
  };
}

function filteredMeters(
  dataSource: Array<Meter>,
  event: EventData
): GeoJSONSourceRaw {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: dataSource
        .filter(
          (el: Meter) =>
            el.telereleveGatewayInfo &&
            el.telereleveGatewayInfo.name === event.features[0].properties.name
        )
        .filter((el: Meter) => el.gpsPosition.lat)
        .map((item: Meter) => {
          return {
            id: item.id,
            type: "Feature",
            properties: {
              currentDate: item.currentDate,
              alarms: item.alarms,
              meterId: item.id,
              meterModel: item.model,
              radioSerial: item.serialRadio,
              pulseWeight: item.pulseWeight,
              ficheState: item.ficheState,
              meterSerial: item.meterSerial,
              gatewayInfo: item.telereleveGatewayInfo,
            },
            geometry: {
              type: "Point",
              coordinates: [item.gpsPosition.lng, item.gpsPosition.lat],
            },
          };
        }),
    },
  };
}

function selectedGateway(dataSource: FeatureCollection): GeoJSONSourceRaw {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: dataSource.features.map((item: any) => {
        return {
          type: "Feature",
          properties: {
            name: item.properties.name,
            active: item.properties.active,
          },
          geometry: item.geometry,
        };
      }),
    },
  };
}

function basicsGateways(dataSource: Array<Gateway>) {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: dataSource
        .filter((el: Gateway) => el.gpsPosition.lat)
        .map((item: Gateway) => {
          return {
            id: item.id,
            type: "Feature",
            properties: {
              name: item.name,
              active: item.active,
            },
            geometry: {
              type: "Point",
              coordinates: [item.gpsPosition.lng, item.gpsPosition.lat],
            },
          };
        }),
    },
  };
}

function basicsMeters(dataSource: Array<Meter>, currentMeter: any) {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features:
        dataSource &&
        dataSource
          .filter((el: Meter) => el.gpsPosition)
          .map((item: any) => {
            return {
              id: item.id,
              type: "Feature",
              properties: {
                meterId: item.id,
                ficheState: item.ficheState || item.icon,
                meterSerial: item.meterSerial,
                serialRadio: item.serialRadio,
                coords: [item.gpsPosition.lng, item.gpsPosition.lat],
                pdiId: item.pdiId,
                isSelected: item.id === _.get(currentMeter, "current.id"),
                clpReference: item.clpReference,
                // gatewayInfo: item.telereleveGatewayInfo,
                lastRead: {
                  consumption: item.lastRead.consumption,
                  date: item.lastRead.date,
                  index: item.lastRead.index,
                  method: item.lastRead.method,
                },
              },
              geometry: {
                type: "Point",
                coordinates: [item.gpsPosition.lng, item.gpsPosition.lat],
              },
            };
          }),
    },
  };
}

function roundMeters(dataSource: Array<any>) {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features:
        dataSource &&
        dataSource
          .filter((el: any) => el.gpsPosition)
          .map((item: any) => {
            return {
              id: item.id,
              type: "Feature",
              properties: {
                meterId: item.id,
                ficheState: item.ficheState || item.icon,
                meterSerial: item.meterSerial,
                lastRead: item.lastRead.index
                  ? {
                      consumption: item.lastRead.consumption,
                      date: item.lastRead.date,
                      index: item.lastRead.index,
                      method: item.lastRead.method,
                    }
                  : null,
                /* serialRadio: item.radioSerial,
                consumption: item.consumption,
                currentDate: item.currentDate,
                currentIndex: item.currentIndex,
                readMethod: item.readMethod, */
              },
              geometry: {
                type: "Point",
                coordinates: [item.gpsPosition.lng, item.gpsPosition.lat],
              },
            };
          }),
    },
  };
}

function gatewayRange(dataSource: Array<Meter>) {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: dataSource
        .filter((el: Meter) => el.gpsPosition.lat)
        .filter((el: Meter) => el.telereleveGatewayInfo)
        .filter((el: Meter) => el.telereleveGatewayInfo.gpsPosition.lat)
        .map((item: Meter, idx: number) => {
          return {
            id: idx,
            type: "Feature",
            properties: {
              selected: false,
              distance: turf.distance(
                [item.gpsPosition.lng, item.gpsPosition.lat],
                [
                  item.telereleveGatewayInfo.gpsPosition.lng,
                  item.telereleveGatewayInfo.gpsPosition.lat,
                ],
                { units: "kilometers" }
              ),
              gatewayId: item.telereleveGatewayInfo.id,
              gatewayName: item.telereleveGatewayInfo.name,
              meterSerial: item.meterSerial,
              rssi: item.rssi,
            },
            geometry: {
              type: "LineString",
              coordinates: [
                [item.gpsPosition.lng, item.gpsPosition.lat],
                [
                  item.telereleveGatewayInfo.gpsPosition.lng,
                  item.telereleveGatewayInfo.gpsPosition.lat,
                ],
              ],
            },
          };
        }),
    },
  };
}

function polygonSource(coords: Array<string>) {
  return {
    type: "geojson",
    data: {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          _.range(0, _.size(coords), 2).map((el) => [
            coords[el],
            coords[el + 1],
          ]),
        ],
      },
    },
  };
}

function clusterMeter(
  dataSource: GeoJSONObject,
  clusterProperties: Object,
  zoom: any
) {
  return {
    type: "geojson",
    data: dataSource && dataSource,
    cluster: true,
    clusterRadius: zoom >= 15 ? 75 : 150,
    clusterMaxZoom: 25,
    clusterProperties,
  };
}

function capturedMeters(
  dataSource: Array<Meter>,
  clusterProperties: Object
): GeoJSONSourceRaw {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: dataSource
        .filter((el: Meter) => el.gpsPosition.lat)
        .filter((el: Meter) => el.currentDate != null)
        .map((item: Meter) => {
          return {
            id: item.id,
            type: "Feature",
            properties: {
              currentDate: item.currentDate,
              alarms: item.alarms,
              meterId: item.id,
              meterModel: item.model,
              radioSerial: item.serialRadio,
              pulseWeight: item.pulseWeight,
              ficheState: item.ficheState,
              meterSerial: item.meterSerial,
              gatewayInfo: item.telereleveGatewayInfo,
            },
            geometry: {
              type: "Point",
              coordinates: [item.gpsPosition.lng, item.gpsPosition.lat],
            },
          };
        }),
    },
    cluster: true,
    clusterRadius: 120,
    clusterMaxZoom: 20,
    clusterProperties,
  };
}

function removeSources(map: Map, sourcesName: Array<string>): void {
  _.each(sourcesName, function (sourceName) {
    map.getSource(sourceName) && map.removeSource(sourceName);
  });
}

function addSource(
  map: any,
  sourcesName: Array<string>,
  source: Array<any>
): void {
  _.each(sourcesName, function (sourceName, index) {
    const sourceIdx = source[index];
    if (!map.getSource(sourceName)) {
      map.addSource(sourceName, sourceIdx);
    } else if (
      _.size(_.get(sourceIdx, "data.features")) !==
      _.size(_.get(map.getSource(sourceName), "_data.features"))
    ) {
      map.getSource(sourceName).setData(_.get(sourceIdx, "data"));
    }
  });
}

function addSourceFromBack(
  map: any,
  sourcesName: Array<string>,
  source: Array<Object>
): void {
  _.each(sourcesName, function (sourceName, index) {
    const sourceIdx = source[index];
    !map.getSource(sourceName) &&
      map.addSource(sourceName, { type: "geojson", data: sourceIdx });
  });
}

const data = {
  selectedLines,
  filteredMeters,
  selectedGateway,
  basicsGateways,
  basicsMeters,
  gatewayRange,
  polygonSource,
  clusterMeter,
  capturedMeters,
  removeSources,
  addSource,
  addSourceFromBack,
  roundMeters,
};

export default data;
