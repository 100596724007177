import React, { useEffect, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import StepPopover from "./StepPopover";

const RoundButton: React.FC<
  {
    id: string;
    icon: JSX.Element;
    onClick: () => void;
    tooltipText?: string;
    disabled?: boolean;
  } & (
    | {
        withPopover?: false;
        popoverTitle?: null;
        confirmText?: null;
      }
    | {
        withPopover: true;
        popoverTitle: string;
        confirmText: string;
      }
  )
> = ({
  id,
  icon,
  onClick,
  tooltipText = "",
  withPopover,
  popoverTitle,
  confirmText,
  disabled,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <span
      id={id}
      className={"round" + (!disabled ? " clickable" : "")}
      role="presentation"
      style={{ marginLeft: "10px" }}
      onClick={(event) => {
        if (!disabled) {
          if (withPopover) {
            event.stopPropagation();
            setOpen(true);
          } else {
            onClick();
          }
        }
      }}
      aria-disabled={disabled}
    >
      {icon}
      {tooltipText != "" && (
        <UncontrolledTooltip placement="bottom" target={id}>
          {tooltipText}
        </UncontrolledTooltip>
      )}
      {withPopover && (
        <StepPopover
          id={`confirmManualPopover`}
          target={id}
          open={open}
          onClose={() => setOpen(false)}
          permission
          action={() => {
            onClick();
            setOpen(false);
          }}
          title={popoverTitle}
          confirmText={confirmText}
        />
      )}
    </span>
  );
};

export default RoundButton;
