import React from "react";
import "./style.scss";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import InfoMap from "./InfoMap";
import Historique from "./Historique";

const canalList = [
  {
    key: "canalA",
    title: "Canal A",
  },
  {
    key: "canalB",
    title: "Canal B",
  },
  {
    key: "canalC",
    title: "Canal C",
  },
  {
    key: "canalD",
    title: "Canal D",
  },
];

const DryContactSensor = (props) => {
  const { sensor } = props;
  return (
    <div className="sensor-graph-container dryc">
      <div className="info-container">
        <InfoMap />
      </div>
      <div className="sensors-wrapper">
        <div className="title">
          <h2>Dry contact</h2>
        </div>
        <div className="charts-container">
          {sensor.sensor &&
            canalList.map((el) => (
              <Historique keyProps={el.key} dryContact={el.title} />
            ))}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { sensor, users } = state;
  return {
    sensor,
    users,
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps)(DryContactSensor))
);
