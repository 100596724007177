import { rndTemplateConstants } from "../_constants";
import { TemplatesState } from "../_interfaces/TourneeCreationTypes";

export default function rndTemplate(state: TemplatesState = {}, action: any) {
  switch (action.type) {
    case rndTemplateConstants.CREATE_TEMPLATE_REQUEST:
    case rndTemplateConstants.GET_ALL_TEMPLATE_REQUEST:
    case rndTemplateConstants.UPDATE_TEMPLATE_REQUEST:
    case rndTemplateConstants.DELETE_TEMPLATE_REQUEST:
    case rndTemplateConstants.CREATE_TAG_REQUEST:
    case rndTemplateConstants.GET_ALL_TAG_REQUEST:
    case rndTemplateConstants.UPDATE_TAG_REQUEST:
    case rndTemplateConstants.DELETE_TAG_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case rndTemplateConstants.CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "templateCreation",
        successTarget: action.template,
      };
    case rndTemplateConstants.GET_ALL_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: action.listTemplates,
        loading: false,
      };
    case rndTemplateConstants.UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "templateUpdate",
        successTarget: action.template,
      };
    case rndTemplateConstants.DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "templateDeletion",
        successTarget: action.template,
      };
    case rndTemplateConstants.CREATE_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "tagCreation",
        successTarget: action.tag,
      };
    case rndTemplateConstants.GET_ALL_TAG_SUCCESS:
      return {
        ...state,
        tags: action.tagList,
        loading: false,
      };
    case rndTemplateConstants.UPDATE_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "tagUpdate",
        successTarget: action.tag,
      };
    case rndTemplateConstants.DELETE_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "tagDeletion",
        successTarget: action.tag,
      };

    case rndTemplateConstants.CREATE_TEMPLATE_FAILURE:
    case rndTemplateConstants.GET_ALL_TEMPLATE_FAILURE:
    case rndTemplateConstants.UPDATE_TEMPLATE_FAILURE:
    case rndTemplateConstants.DELETE_TEMPLATE_FAILURE:
    case rndTemplateConstants.CREATE_TAG_FAILURE:
    case rndTemplateConstants.GET_ALL_TAG_FAILURE:
    case rndTemplateConstants.UPDATE_TAG_FAILURE:
    case rndTemplateConstants.DELETE_TAG_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case rndTemplateConstants.CLEAR_SUCCESS:
      return {
        ...state,
        success: undefined,
      };
    case rndTemplateConstants.CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case rndTemplateConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
