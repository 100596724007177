import React from "react";
import { connect } from "react-redux";
import { locationActions } from "../_actions";
import BookmarkLocation from "./BookmarkLocation";
import { Location } from "../_entities/location";
import { User } from "../_entities/user";
import { locations } from "../_interfaces/reducers";
import Loading from "../_animations/Loading";
import { withTranslation } from "react-i18next";

interface Props {
  locations: locations;
  user: User;
  dispatch: Function;
  t: Function;
}

/**
 * Affiche la liste des marque-pages par site
 *
 * @class BookmarksComponent
 * @extends Component
 */
class BookmarksComponent extends React.Component<Props> {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(locationActions.getAll("STOCK"));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(locationActions.clear());
  }

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { locations, user, t } = this.props;
    return (
      <div>
        {locations.loading && (
          <Loading
            message={t("bookmark.loading_band.loading_favorite_dashboard")}
          />
        )}
        {locations.items && (
          <div>
            {locations.items.map((location: Location) => (
              <BookmarkLocation
                key={location.id}
                location={location}
                user={user}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, locations } = state;
  const { user } = authentication;
  return {
    user,
    locations,
  };
}

const connectedBookmarksComponent =
  connect(mapStateToProps)(BookmarksComponent);
const tr = withTranslation()(connectedBookmarksComponent);
export default tr;
