import React from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import StepWizard from "react-step-wizard";
import { widgetActions } from "../_actions";
import Last from "./Last";
import WidgetOption from "./WidgetOption";
import { dashboards } from "../_interfaces/reducers";
import { Widget } from "../_entities/widget";
import { withTranslation } from "react-i18next";

interface Props {
  widget: Widget;
  dispatch: Function;
  configureDone: Function;
  dashboards: dashboards;
  modal?: boolean;
  locationId?: number;
}

interface State {
  form: any;
  dataPreview: any;
}

/**
 * @class WidgetCreatorComponent
 * @extends {React.Component<Props, State>}
 */
class WidgetCreatorComponent extends React.Component<Props & {}, State> {
  /**
   * Définit les valeurs du formulaire et créé l'instance
   *
   * @param {Props} props Propriétés
   * @memberof WidgetCreatorComponent
   */
  constructor(props: any) {
    super(props);
    const { widget }: any = this.props;
    this.state = {
      form: {
        name: widget.name,
        templateOption: widget.content,
      },
      dataPreview: {},
    };
    this.updateForm = this.updateForm.bind(this);
  }

  /**
   * Met à jour les valeurs du formulaire
   *
   * @method updateForm
   * @memberof WidgetCreatorComponent
   */
  async updateForm(key: string, value: string) {
    const { form }: any = this.state;
    const { dispatch } = this.props;
    form[key] = value;
    let data = await widgetActions
      .getWidgetcontent(form.templateOption)
      .then((result) => {
        return result;
      })
      .catch((error: Object) => {
        console.log("error:", error);
      });
    this.setState({
      form,
      dataPreview: data,
    });
  }

  onStepChange = (stats: any) => {
    //console.log({ stats });
  };

  /**
   * Lance la mise à jour du widget et met fin à
   * la configuration de celui-ci
   *
   * @method send
   * @memberof WidgetCreatorComponent
   */
  send = () => {
    const { widget, dispatch, configureDone, dashboards }: any = this.props;
    const { form }: any = this.state;
    const updatedWidget = {
      id: widget.id,
      name: form.name,
      content: form.templateOption,
      template: widget.template,
      dashboard: widget.dashboard,
      configured: true,
    };
    dispatch(
      widgetActions.update(
        updatedWidget,
        dashboards.dashboard.id,
        dashboards.dashboard
      )
    );
    configureDone();
    this.setState({});
  };
  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof WidgetCreatorComponent
   */
  render() {
    const { alert, modal, configureDone, locationId, widget, t }: any =
      this.props;
    const { form, dataPreview }: any = this.state;
    return (
      <Modal isOpen={modal} toggle={configureDone} backdrop>
        <ModalHeader toggle={configureDone}>
          {t("widget_creator.title.configure_widget")}
        </ModalHeader>
        <ModalBody>
          <div className="col-md-12">
            {alert.type !== "alert-success" && alert.message && (
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            )}
            <StepWizard onStepChange={this.onStepChange}>
              <WidgetOption
                hashKey="WidgetOption"
                update={this.updateForm}
                form={form}
                locationId={locationId}
              />
              <Last
                hashKey="TheEnd!"
                form={form}
                update={this.send}
                widget={widget}
                dataPreview={dataPreview}
              />
            </StepWizard>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={configureDone}>
            {t("all.button.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, templates, dashboards, alert, locales } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    templates,
    dashboards,
    locales,
  };
}

const connectedWidgetCreator = connect(mapStateToProps)(WidgetCreatorComponent);
const tr = withTranslation()(connectedWidgetCreator);
export default tr;
