import React, { Component } from "react";

interface Props {
  pdi: any;
  dispatch?: Function;
}

interface State {}

/**
 * @class SourceSheetPDIComponent
 * @extends {Component}
 */
export default class TinySourceSheetPDI extends Component<Props & {}, State> {
  /**
   * Met les informations de base dans le template,
   * et créé l'instance
   *
   * @constructor
   * @memberof SourceSheetPDIComponent
   */
  constructor(props: Props) {
    super(props);
  }

  /**
   * Convertie l'état du PDI d'une donnée numérique
   * à textuelle
   *
   * @method convertPDIStatus
   * @memberof SourceSheetPDIComponent
   * @returns {String} L'état
   */
  convertPDIStatus = (status: number | null | undefined) => {
    switch (status) {
      case 1:
        return "Actif";
      case 0:
        return "Inactif";
      default:
        return "Non défini";
    }
  };

  /**
   * Rend le composant
   *
   * @returns {JSX} le composant
   * @method render
   * @memberof SourceSheetPDIComponent
   */
  render() {
    const { pdi } = this.props;
    return (
      <div className="col-md-12">
        {pdi && Object.keys(pdi).length > 0 && (
          <div className="presentation-container">
            <div
              className="presentation-header"
              style={{ backgroundSize: "800px" }}
            >
              <span className="presentation-title">Référence du PDI </span>
              <span className="presentation-main-title">
                {pdi.reference || "Non renseigné"}{" "}
              </span>
            </div>
            <div className="presentation-body">
              <div className="display-info" style={{ margin: "20px 0 0 30px" }}>
                <p>
                  <span className="infoLibelle">Etat </span> <br /> &nbsp;{" "}
                  <span className="infoDisplay">
                    {this.convertPDIStatus(pdi.state)}{" "}
                  </span>
                </p>
                <p>
                  <span className="infoLibelle">Adresse </span> <br /> &nbsp;{" "}
                  <span className="infoDisplay">
                    {pdi.address.streetNumber} {pdi.address.street}{" "}
                  </span>
                </p>
                <p>
                  <span className="infoLibelle">Ville </span> <br /> &nbsp;{" "}
                  <span className="infoDisplay">
                    {pdi.address.city} ({pdi.address.zipcode}){" "}
                  </span>
                </p>
                <p>
                  <span className="infoLibelle">Pays </span> <br /> &nbsp;{" "}
                  <span className="infoDisplay">{pdi.address.country} </span>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
