import React from "react";
import { Button, Col, CustomInput, FormGroup, Label, Row } from "reactstrap";
import { connect } from "react-redux";
import { AvField, AvForm } from "availity-reactstrap-validation";
import _ from "lodash";
import { withTranslation } from "react-i18next";

interface State {
  createdContact: {
    civility: string;
    lastName: string;
    firstName: string;
    phone: string;
    mail: string;
    note: string;
  };
  contactInProgress: {
    civility: string;
    lastName: string;
    firstName: string;
    phone: string;
    mail: string;
    note: string;
    [key: string]: string;
  };
}

/**
 * @class DisplayValueChooserComponent
 * @extends {React.Component<Props, State>}
 */
class ContactChooser extends React.Component<any, State> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof DisplayValueChooserComponent
   */
  constructor(props: any) {
    super(props);
    this.send = this.send.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.validate = this.validate.bind(this);

    this.state = {
      createdContact: ContactChooser.emptyContact(props),
      contactInProgress: ContactChooser.emptyContact(props),
    };
  }

  /**
   * Met à jour le state si l'attribut datasource du formulaire
   * contient quelque chose
   *
   * @static
   * @param {Props} props
   * @param {State} state
   * @returns {State} Le nouveau state
   * @method getDerivedStateFromProps
   * @memberof DisplayValueChooserComponent
   */
  static getDerivedStateFromProps(props: any, state: State) {
    const { form } = props;
    if (!!state.createdContact.lastName && !form.contact) {
      return {
        createdContact: ContactChooser.emptyContact(props),
        contactInProgress: ContactChooser.emptyContact(props),
      };
    }
    if (
      form.contact &&
      !state.createdContact.lastName &&
      !state.contactInProgress.lastName
    ) {
      return {
        createdContact: _.cloneDeep(form.contact),
        contactInProgress: _.cloneDeep(form.contact),
      };
    }
    return null;
  }

  /**
   * Récupération d'un Contact vide
   *
   * @method validate
   * @memberof DisplayValueChooserComponent
   */
  static emptyContact(props: any) {
    return {
      civility: props.t("all.contact.civility_male"),
      lastName: "",
      firstName: "",
      phone: "",
      mail: "",
      note: "",
    };
  }

  /**
   * Gère le passage à l'étape précédente
   *
   * @method validate
   * @memberof DisplayValueChooserComponent
   */
  validate = () => {
    const { createdContact } = this.state;

    if (createdContact.lastName) {
      this.setState({
        contactInProgress: _.cloneDeep(createdContact),
      });
    }

    this.props.firstStep();
  };

  /**
   * Gère le passage à l'étape suivante et la mise à jour
   * du template
   *
   * @param {Object} e Evènement
   * @method send
   * @memberof DisplayValueChooserComponent
   */
  send(e: any) {
    // e.preventDefault();
    const { update } = this.props;
    const { contactInProgress } = this.state;

    update("contact", _.cloneDeep(contactInProgress));

    this.setState({
      createdContact: _.cloneDeep(contactInProgress),
    });

    this.props.firstStep();
  }

  /**
   * Gère le changement des propriétés du widget
   *
   * @param {Object} event Evènement
   * @method handleContactChange
   * @memberof DisplayValueChooserComponent
   */
  handleContactChange(event: any) {
    const { contactInProgress } = this.state;
    const key: string = event.target.id;
    const value: string = event.target.value.trim();

    if (key.includes("civility")) {
      contactInProgress.civility = value;
    } else {
      contactInProgress[key] = value;
    }

    this.setState({
      contactInProgress,
    });
  }

  getValidationsForField = (name: string) => {
    const { t } = this.props;
    switch (name) {
      case "lastName":
        return {
          required: this.hasRequired(name),
          pattern: {
            value: "[A-Za-z]+",
            errorMessage: t("contact_chooser.text.lastname_only_letters"),
          },
        };

      case "firstName":
        return {
          pattern: {
            value: "[A-Za-z]+",
            errorMessage: t("contact_chooser.text.firstname_only_letters"),
          },
        };
      case "phone":
        return {
          pattern: {
            value: "^[0-9]{10}$",
            errorMessage: t(
              "contact_chooser.text.phone_number_needs_10_number"
            ),
          },
        };
      case "mail":
        return {
          required: this.hasRequired(name),
          email: true,
        };
    }
  };

  hasRequired = (name: string) => {
    const { t } = this.props;
    switch (name) {
      case "lastName":
        return {
          value: true,
          errorMessage: t("contact_chooser.text.give_name_info"),
        };

      case "mail":
        return {
          value: true,
          errorMessage: t("contact_chooser.text.give_email_info"),
        };

      default:
        return false;
    }
  };

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof DisplayValueChooserComponent
   */
  render() {
    const { isActive, t, locales } = this.props;
    const { createdContact, contactInProgress } = this.state;

    if (!isActive) return null;

    return (
      <div>
        <h3>{t("location_creator.title.location_contact")}</h3>
        {createdContact && contactInProgress && (
          <AvForm onValidSubmit={this.send}>
            <div className="contactChoices">
              <FormGroup key="civility" onChange={this.handleContactChange}>
                <Label for="civility">
                  {t("all.contact.civility")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Label>
                <Row style={{ margin: "0 0 15px 0" }}>
                  <CustomInput
                    type="radio"
                    value="Mr"
                    label={t("all.contact.civility_male")}
                    id="civility1"
                    checked={_.isEqual(contactInProgress.civility, "Mr")}
                    name="customRadio"
                    onClick={this.handleContactChange}
                  />
                  <div style={{ marginLeft: "25px" }}>
                    <CustomInput
                      type="radio"
                      value="Mme"
                      id="civility2"
                      label={t("all.contact.civility_female")}
                      checked={_.isEqual(contactInProgress.civility, "Mme")}
                      name="customRadio"
                      onClick={this.handleContactChange}
                    />
                  </div>
                </Row>
              </FormGroup>
              {Object.keys(contactInProgress)
                .filter((key) => key !== "civility" && key !== "id")
                .map((key: string) => (
                  <FormGroup key={key}>
                    <Label for={key}>
                      {t(`all.contact.${_.snakeCase(key)}`)}{" "}
                      <span style={{ color: "red" }}>
                        {this.hasRequired(key) ? "*" : ""}
                      </span>
                    </Label>
                    <AvField
                      type={key === "note" ? "textarea" : "text"}
                      name={key}
                      id={key}
                      rows={key === "note" ? "7" : null}
                      placeholder={t(`all.contact.${_.snakeCase(key)}`)}
                      onChange={this.handleContactChange}
                      value={contactInProgress[key]}
                      autoComplete="NONE"
                      validate={this.getValidationsForField(key)}
                    />
                  </FormGroup>
                ))}
              <div className="text-right">
                <Label>
                  <span style={{ color: "red" }}>*</span>{" "}
                  {t("all.text.required_field_plural")}
                </Label>
              </div>
            </div>
            <div className="bottomChoice">
              <Row>
                <Col md="6" className="text-right">
                  <Button color="danger" onClick={this.validate}>
                    {t("all.button.cancel")}
                  </Button>
                </Col>
                {createdContact.lastName ? (
                  <Col md="6" className="text-left">
                    <Button color="primary">{t("all.button.edit")}</Button>
                  </Col>
                ) : (
                  <Col md="6" className="text-left">
                    <Button color="primary">{t("all.button.validate")}</Button>
                  </Col>
                )}
              </Row>
            </div>
          </AvForm>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { locales } = state;
  return {
    locales,
  };
}

const ContactChooserConnectedComponent =
  connect(mapStateToProps)(ContactChooser);
const tr = withTranslation()(ContactChooserConnectedComponent);
export default tr;
