import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Divider } from "@material-ui/core";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import _ from "lodash";
import { withRouter } from "react-router";
import { compose } from "redux";

import Ajout from "../SvgComponents/AjoutBleu";
import FlecheAllerLarge from "../SvgComponents/FlecheAllerLarge";
import { UncontrolledTooltip } from "reactstrap";
import SvgPoubelleBleu from "../SvgComponents/PoubelleBleu";
import SvgEditionBleu from "../SvgComponents/EditionBleu";
import RoundButton from "../_components/RoundButton";
import {
  importActions,
  localeActions,
  rndTemplateActions,
  tourneeActions,
} from "../_actions";
import {
  TemplateDto,
  TemplatesState,
} from "../_interfaces/TourneeCreationTypes";
import ErrorBand from "../Bands/Error";
import SuccessBand from "../Bands/Success";
import LoadingBand from "../Bands/Loading";
import { SilexImportState } from "../_interfaces/imports";
import ImportFileTab from "./ImportFileTab";
import ImportFactuTab from "./ImportFactuTab";

const NewTourneePage: React.FC<
  {
    dispatch: Function;
    gestionnaire: any;
    imports: any;
    rndTemplate: TemplatesState;
    tournee: any;
    user: any;
  } & RouteComponentProps &
    WithTranslation
> = ({
  dispatch,
  gestionnaire,
  history,
  imports,
  match,
  rndTemplate,
  t,
  tournee,
  user,
}) => {
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("file-import");
  const [isSilexImport, setIsSilexImport] = useState<boolean>(false);
  const admin = user.role.name === "DIOPTASE";

  useEffect(() => {
    const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN", "USER"];
    if (!roleList.includes(user.role.name)) {
      history.push("/forbidden");
    }
    dispatch(localeActions.load());
    dispatch(rndTemplateActions.getAllTemplates());
    dispatch(tourneeActions.getImportOptions());
    return () => {
      dispatch(rndTemplateActions.clearError());
      dispatch(rndTemplateActions.clearSuccess());
    };
  }, []);

  useEffect(() => {
    if (rndTemplate.success) {
      setSuccessMessage(
        t(`tournee_creation.success.${rndTemplate.success}`, {
          successTarget: rndTemplate.successTarget!.name,
        })
      );
      setTimeout(() => {
        dispatch(rndTemplateActions.clearSuccess());
      }, 5000);
    }
    if (rndTemplate.error) {
      setTimeout(() => {
        dispatch(rndTemplateActions.clearError());
      }, 5000);
    }
  }, [rndTemplate]);

  useEffect(() => {
    if (imports.error && !imports) {
      setTimeout(() => {
        dispatch(importActions.clearError());
      }, 5000);
    }
  }, [imports]);

  const getStateString = () => {
    if (!imports.importState || imports.importState.numberOfRounds == 0) {
      return ` - ${t("import_silex.loading_band.analysis_in_progress")}`;
    } else {
      const importState: SilexImportState = imports.importState;
      if (importState.actualRoundNumber === importState.numberOfRounds + 1) {
        return ` - ${t("import_silex.loading_band.validating_import")}`;
      } else {
        return ` - ${t("import_silex.loading_band.import_in_progress", {
          label: importState.actualRoundLabel,
          code: importState.actualRoundCode,
          number: importState.actualRoundNumber,
          numberOfRound: importState.numberOfRounds,
        })}`;
      }
    }
  };

  const onTemplateDelete = (templateId: number) => {
    dispatch(rndTemplateActions.deleteTemplate(templateId));
  };

  const columns = [
    {
      dataField: "name",
      text: t("all.text.name"),
    },
    {
      dataField: "type",
      text: t("all.text.type"),
      formatter: (cell: any, row: TemplateDto) => {
        return row.type === "meter"
          ? t("all.meter.meter")
          : t("all.text.subscriber");
      },
    },
    {
      dataField: "actions",
      formatter: (cell: any, row: TemplateDto) => {
        return (
          <div className="template-action-cell">
            <RoundButton
              id={`template-${row.id}-edit`}
              onClick={() =>
                history.push(
                  `/gestionnaires/${_.get(
                    match,
                    "params.GestionnaireId"
                  )}/synchronisation/silex/template`,
                  {
                    template: row,
                    from: location.pathname,
                  }
                )
              }
              icon={<SvgEditionBleu height="1em" width="1em" />}
            />
            <RoundButton
              id={`template-${row.id}-remove`}
              onClick={() => {
                setLoadingMessage(
                  t("tournee_creation.loading_band.template_deletion")
                );
                onTemplateDelete(row.id!);
              }}
              disabled={
                imports.loading ||
                rndTemplate.loading ||
                (row.linkedTourneeList && row.linkedTourneeList.length !== 0)
              }
              icon={
                <SvgPoubelleBleu
                  height="1em"
                  width="1em"
                  fill={
                    row.linkedTourneeList &&
                    row.linkedTourneeList.length !== 0 &&
                    "lightgray"
                  }
                />
              }
              withPopover
              popoverTitle={t("tournee_creation.template_popover.title")}
              confirmText={t("tournee_creation.template_popover.confirm_text")}
            />
            {row.linkedTourneeList && row.linkedTourneeList.length !== 0 && (
              <UncontrolledTooltip
                placement="bottom"
                target={`template-${row.id}-remove`}
              >
                {t("tournee_creation.error_band.template_still_linked", {
                  template: row.name,
                  rndList: row.linkedTourneeList?.join(", "),
                })}
              </UncontrolledTooltip>
            )}
          </div>
        );
      },
      text: "",
    },
  ];

  const getErrorBandOptions = (error: {
    translationId: string;
    errorValue: any;
  }) => {
    switch (error.translationId) {
      case "template_still_linked":
        return { rndList: error.errorValue.join(", ") };
      default:
        return {};
    }
  };

  return (
    <>
      <div className="col-12">
        {(rndTemplate.error || imports.error) && (
          <ErrorBand
            message={
              imports.error
                ? imports.error === "facturation_unreachable"
                  ? t("import_silex.error_band.facturation_unreachable")
                  : t("import_silex.error_band.import_error")
                : typeof rndTemplate.error === "string"
                ? rndTemplate.error
                : t(
                    `tournee_creation.error_band.${rndTemplate.error.translationId}`,
                    {
                      ...getErrorBandOptions(rndTemplate.error),
                    }
                  )
            }
          />
        )}
        {(rndTemplate.success || imports.importFromFactu) && (
          <SuccessBand message={successMessage} />
        )}
        {(imports.loading || rndTemplate.loading) && loadingMessage !== "" && (
          <LoadingBand
            message={loadingMessage + (isSilexImport ? getStateString() : "")}
          />
        )}
      </div>
      <div className="new-tournee-container">
        {/* Partie import silex */}
        <div className="col-5" style={{ gap: 0 }}>
          {tournee.importOption?.import_round_facturation_enabled && (
            <div className="tab-container">
              <div
                className={
                  "tab" + (activeTab === "file-import" ? " active" : "")
                }
                onClick={() => setActiveTab("file-import")}
              >
                {t("import_silex.tab_label.file_import")}
              </div>
              <div
                className={
                  "tab" + (activeTab === "moonstone-import" ? " active" : "")
                }
                onClick={() => setActiveTab("moonstone-import")}
              >
                {t("import_silex.tab_label.facturation_import")}
              </div>
            </div>
          )}
          <div
            className={
              "table-info-container import-side" +
              (tournee.importOption?.import_round_facturation_enabled
                ? " with-tab"
                : "")
            }
          >
            {(!tournee.importOption?.import_round_facturation_enabled ||
              activeTab === "file-import") && (
              <ImportFileTab
                dispatch={dispatch}
                gestionnaire={gestionnaire}
                history={history}
                imports={imports}
                rndTemplate={rndTemplate}
                setIsSilexImport={setIsSilexImport}
                setLoadingMessage={setLoadingMessage}
                t={t}
                tournee={tournee}
                user={user}
              />
            )}
            {tournee.importOption?.import_round_facturation_enabled &&
              activeTab === "moonstone-import" && (
                <ImportFactuTab
                  dispatch={dispatch}
                  history={history}
                  imports={imports}
                  match={match}
                  setLoadingMessage={setLoadingMessage}
                  setSuccessMessage={setSuccessMessage}
                  t={t}
                />
              )}
          </div>
        </div>

        {/* Partie création de tournée */}
        <div
          className="col-5 tournee-creation-part"
          // style={{ maxHeight: admin ? "565px" : "465px" }}
        >
          {/* Block création de tournée */}
          <div className="table-info-container create-side">
            <div className="add-icon">
              <Ajout className="add" fill="#ACABAB" width="24px" />
            </div>
            <h3 className="align-start">
              {t("tournee_creation.container_label.create_tournee")}
            </h3>
            <button
              className="tournee-creation-link-button"
              onClick={() =>
                history.push(
                  `/gestionnaires/${_.get(
                    match,
                    "params.GestionnaireId"
                  )}/synchronisation/silex/new`
                )
              }
            >
              <div className="tournee-creation-link-label">
                {t("tournee_creation.button.start")}
                <FlecheAllerLarge
                  width="14px"
                  className="tournee-creation-link-icon"
                />
              </div>
            </button>
          </div>

          {/* Block gestion des templates */}
          <div
            className="table-info-container"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              maxHeight: "455px",
            }}
          >
            <div className="create-side">
              <div className="add-icon" />
              <h3 className="align-start">
                {t("tournee_creation.container_label.template_list")}
              </h3>
              <div
                className="template-add-button-container"
                onClick={() =>
                  history.push(
                    `/gestionnaires/${_.get(
                      match,
                      "params.GestionnaireId"
                    )}/synchronisation/silex/template`,
                    {
                      from: location.pathname,
                    }
                  )
                }
              >
                <button className="template-add-button">
                  <Ajout className="add" fill="curentcolor" width="20px" />
                </button>
              </div>
            </div>
            {rndTemplate.templates && rndTemplate.templates.length != 0 && (
              <>
                <Divider className="template-divider" />
                <div className="template-table-container">
                  <BootstrapTable
                    keyField="id"
                    bootstrap4
                    bordered={false}
                    hover
                    data={rndTemplate.templates}
                    columns={columns}
                    condensed
                    headerClasses="template-table-header"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state: any) {
  const {
    authentication,
    imports,
    gestionnaire: { gestionnaire },
    rndTemplate,
    tournee,
  } = state;
  const { user } = authentication;
  return {
    imports,
    gestionnaire,
    rndTemplate,
    tournee,
    user,
  };
}

const connectedNewTourneePage = compose(
  withRouter,
  connect(mapStateToProps)
)(NewTourneePage);
const tr = withTranslation()(connectedNewTourneePage);
export default tr;
