import notificationConstants from "../_constants/notification.constants";
import { Notification } from "../_entities/notification";
import { action } from "../_interfaces/action";
import { cloneDeep } from "lodash";

export default function notifications(state: any = {}, action: action) {
  let notifications: Notification[] = [];

  switch (action.type) {
    case notificationConstants.GETALL_REQUEST:
      return {
        loading: true,
        count: state.count,
      };
    case notificationConstants.GETALL_SUCCESS:
      return {
        items: action.notifications,
      };
    case notificationConstants.GETALL_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des notifications",
      };
    case notificationConstants.GETLAST_REQUEST:
      return {
        loading: true,
      };
    case notificationConstants.GETLAST_SUCCESS:
      return {
        items: action.notifications,
      };
    case notificationConstants.GETLAST_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des dernières notifications",
      };
    case notificationConstants.GETCOUNT_SUCCESS:
      state.count = action.count;
      return state;
    case notificationConstants.GETCOUNT_FAILURE:
      return {
        error: "Impossible d'obtenir le nombre des notifications non lues",
      };
    case notificationConstants.GET_REQUEST:
      return {
        loading: true,
      };
    case notificationConstants.GET_SUCCESS:
      return {
        fetchedNotification: action.fetchedNotification,
      };
    case notificationConstants.GET_FAILURE:
      return {
        error: "Impossible d'obtenir la notification",
      };
    case notificationConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case notificationConstants.UPDATE_SUCCESS:
      notifications = cloneDeep(state.items);
      const found: any = notifications.find(
        (it: any) => it.id === action.fetchedNotification.id
      );
      const index = notifications.indexOf(found);
      notifications[index] = action.fetchedNotification;
      return {
        items: notifications,
        updated: true,
      };
    case notificationConstants.UPDATE_FAILURE:
      return {
        error: "Impossible de mettre à jour la notification",
      };
    case notificationConstants.READ_ALL_SUCCESS:
      notifications = cloneDeep(state.items);
      action.notifications.forEach((notif: Notification) => {
        const found: any = notifications.find((it: any) => it.id === notif.id);
        const index = notifications.indexOf(found);
        notifications[index] = notif;
      });
      return {
        items: notifications,
        updated: true,
      };
    case notificationConstants.UPDATE_FAILURE:
      return {
        error: "Impossible de mettre à jour la notification",
      };
    case notificationConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case notificationConstants.DELETE_SUCCESS:
      notifications = cloneDeep(state.items);
      return {
        items: notifications.filter(
          (notif: any) => notif.id !== action.notifId
        ),
        deleted: true,
      };
    case notificationConstants.DELETE_FAILURE:
      return {
        error: "Impossible de supprimer la notification",
      };
    case notificationConstants.DELETE_MULTI_SUCCESS:
      notifications = cloneDeep(state.items);
      return {
        items: notifications.filter((notif: any) => {
          return action.notifIds.find((id) => id === notif.id) === undefined;
        }),
        deleted: true,
      };
    case notificationConstants.DELETE_MULTI_FAILURE:
      return {
        error: "Impossible de supprimer les notifications sélectionnées",
      };
    case notificationConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
