import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
}));

const MultilineTextFields = (props) => {
  const { value, handleChange } = props;
  const classes = useStyles();

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      style={{ width: "100%" }}
    >
      <TextField
        id="standard-multiline-static"
        multiline
        rows={4}
        onChange={handleChange}
        value={value}
      />
    </form>
  );
};

export default withTranslation()(MultilineTextFields);
