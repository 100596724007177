import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import Label from "./Label";
import BestLabel from "./BestLabel";
import ValidateButtons from "./ValidateButtons";
import SvgCroixNoir from "../../../SvgComponents/croixNoir";
import synchroComActions from "../../../_actions/synchrocom.actions";

const RoundInfo = (props) => {
  const {
    task,
    synchrocom,
    synchro,
    match,
    dispatch,
    column,
    getAllCommunication,
    cancelOne,
    delLabel,
    switchToPartial,
    affects,
    desaffect,
    partiel,
    t,
  } = props;
  const { labels } = synchrocom;
  const { params } = match;
  const { GestionnaireId } = params;

  const [affectedLabels, setAffectedLabels] = useState([]);
  const [waitingLabels, setWaitingLabels] = useState([]);

  const updateAffectedLabels = () => {
    setAffectedLabels(
      labels?.loaded
        .filter((el) => el.tournee === task.rndCode)
        .map((el) => {
          el.type = "loaded";
          return el;
        })
    );
  };

  const updateWaitingLabels = () => {
    setWaitingLabels(
      _.concat(
        labels?.affect
          .filter((el) => el.tournee === task.rndCode)
          .map((el) => {
            el.type = "affect";
            return el;
          }),
        labels?.disaffect
          .filter((el) => el.tournee === task.rndCode)
          .map((el) => {
            el.type = "dissaffect";
            return el;
          })
      )
    );
  };

  useEffect(() => {
    updateAffectedLabels();
    updateWaitingLabels();
  }, [labels]);

  useEffect(() => {
    if (
      labels?.loaded.filter((el) => el.tournee === task.rndCode).length !==
      affectedLabels?.length
    ) {
      updateAffectedLabels();
    }
    if (
      _.concat(
        labels?.affect.filter((el) => el.tournee === task.rndCode),
        labels?.disaffect.filter((el) => el.tournee === task.rndCode)
      ).length !== waitingLabels.length
    ) {
      updateWaitingLabels();
    }
  });

  /* const {
    task,
    deathLabel,
    getAllCommunication,
    cancelOne,
    delLabel,
    switchToPartial,
    affects,
    desaffect,
    partiel,
    column,
    t,
  } = props; */

  const GraphDisplay = () => {
    const isBigScreen = useMediaQuery({ query: "(min-width: 1650px)" });
    const isNormalScreen = useMediaQuery({ query: "(max-width: 1649px)" });

    return (
      <>
        {isBigScreen && (
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "black",
                paddingBottom: "5px",
                height: "45%",
                alignItems: "center",
                width: "100%",
                margin: 0,
                display: "flex",
              }}
            >
              <div style={{ width: "35%" }}>
                <span
                  style={{
                    marginBottom: 0,
                    fontSize: "17px",
                    display: "block",
                  }}
                >
                  {t("all.text.indexed_plural")}
                </span>
                <span
                  style={{ marginBottom: 0, fontSize: "15px", color: "#333" }}
                >
                  {`${task.indexedMeter}/${task.totalMeter}`} (
                  {Math.floor((task.indexedMeter / task.totalMeter) * 100)}
                  %)
                </span>
              </div>
              <Col style={{ width: "60%", padding: "0" }} xs="auto">
                <ProgressBar
                  progressWidth={(task.indexedMeter / task.totalMeter) * 100}
                  color="green"
                />
              </Col>
            </div>
            <div
              style={{
                color: "black",
                paddingBottom: "5px",
                height: "45%",
                alignItems: "center",
                width: "100%",
                margin: 0,
                display: "flex",
              }}
            >
              <div style={{ width: "35%" }}>
                <span
                  style={{
                    marginBottom: 0,
                    fontSize: "17px",
                    display: "block",
                  }}
                >
                  {t("all.text.modified_plural")}
                </span>
                <span
                  style={{ marginBottom: 0, fontSize: "15px", color: "#333" }}
                >
                  {`${task.modifiedMeter}/${task.totalMeter}`} (
                  {Math.floor((task.modifiedMeter / task.totalMeter) * 100)}
                  %)
                </span>
              </div>
              <Col style={{ width: "60%", padding: "0" }} xs="auto">
                <ProgressBar
                  progressWidth={(task.modifiedMeter / task.totalMeter) * 100}
                  color="lightblue"
                />
              </Col>
            </div>
          </div>
        )}
        {isNormalScreen && (
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "black",
                paddingBottom: "5px",
                height: "45%",
                alignItems: "center",
                width: "100%",
                margin: 0,
                display: "flex",
              }}
            >
              <div style={{ width: "40%" }}>
                <span
                  style={{
                    marginBottom: 0,
                    fontSize: "15px",
                    display: "block",
                  }}
                >
                  {t("round_info.text.indexed_x_percent_plural", {
                    percent: Math.floor(
                      (task.indexedMeter / task.totalMeter) * 100
                    ),
                  })}
                </span>
                <span
                  style={{ marginBottom: 0, fontSize: "15px" }}
                >{`${task.indexedMeter}/${task.totalMeter}`}</span>
              </div>
              <Col style={{ width: "55%", padding: "0" }} xs="auto">
                <ProgressBar
                  progressWidth={(task.indexedMeter / task.totalMeter) * 100}
                  color="green"
                />
              </Col>
            </div>
            <div
              style={{
                color: "black",
                paddingBottom: "5px",
                height: "45%",
                alignItems: "center",
                width: "100%",
                margin: 0,
                display: "flex",
              }}
            >
              <div style={{ width: "40%" }}>
                <span
                  style={{
                    marginBottom: 0,
                    fontSize: "15px",
                    display: "block",
                  }}
                >
                  {t("round_info.text.edited_x_percent_plural", {
                    percent: Math.floor(
                      (task.modifiedMeter / task.totalMeter) * 100
                    ),
                  })}
                </span>
                <span
                  style={{ marginBottom: 0, fontSize: "15px" }}
                >{`${task.modifiedMeter}/${task.totalMeter}`}</span>
              </div>
              <Col style={{ width: "55%", padding: "0" }} xs="auto">
                <ProgressBar
                  progressWidth={(task.modifiedMeter / task.totalMeter) * 100}
                  color="lightblue"
                />
              </Col>
            </div>
          </div>
        )}
      </>
    );
  };

  const disaffectAll = () => {
    dispatch(synchroComActions.disaffectAllForRound(GestionnaireId, task.id));
  };

  return (
    <div
      style={{ padding: "0 0 0 10px", height: "100%" }}
      className="round-info-container"
    >
      {waitingLabels.length === 0 && affectedLabels?.length === 0 ? (
        <div
          style={{
            padding: "15px 0 15px 0",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row
            style={{
              color: "black",
              paddingBottom: "5px",
              height: "45%",
              width: "100%",
            }}
          >
            <Col xs="3">
              <span style={{ marginBottom: 0, fontSize: "15px" }}>
                {t("round_info.text.indexed_x_percent_plural", {
                  percent: Math.floor(
                    (task.indexedMeter / task.totalMeter) * 100
                  ),
                })}
              </span>
            </Col>
            <Col style={{ width: "50%", padding: "0" }} xs="auto">
              <ProgressBar
                progressWidth={(task.indexedMeter / task.totalMeter) * 100}
                color="green"
              />
            </Col>
            <Col
              style={{ padding: 0, maxWidth: "8%", textAlign: "right" }}
              xs="3"
            >
              <span
                style={{ marginBottom: 0, fontSize: "15px" }}
              >{`${task.indexedMeter}/${task.totalMeter}`}</span>
            </Col>
          </Row>
          <Row
            style={{
              color: "black",
              alignItems: "center",
              height: "45%",
              width: "100%",
            }}
          >
            <Col xs="3">
              <span style={{ marginBottom: 0, fontSize: "15px" }}>
                {t("round_info.text.edited_x_percent_plural", {
                  percent: Math.floor(
                    (task.modifiedMeter / task.totalMeter) * 100
                  ),
                })}
              </span>
            </Col>
            <Col style={{ width: "50%", padding: "0" }} xs="auto">
              <ProgressBar
                progressWidth={(task.modifiedMeter / task.totalMeter) * 100}
                color="lightblue"
              />
            </Col>
            <Col
              style={{ padding: 0, maxWidth: "8%", textAlign: "right" }}
              xs="3"
            >
              <span
                style={{ marginBottom: 0, fontSize: "15px" }}
              >{`${task.modifiedMeter}/${task.totalMeter}`}</span>
            </Col>
          </Row>
        </div>
      ) : (
        <GraphDisplay />
      )}
      {(waitingLabels.length > 0 || affectedLabels?.length > 0) && (
        <div className="label-list-container">
          <div
            className="labels-container"
            onScroll={(e) => e.stopPropagation()}
          >
            {waitingLabels.length > 0 && (
              <>
                <span
                  style={{
                    width: "100%",
                    borderBottom: "1px solid lightgrey",
                    marginTop: "5px",
                  }}
                >
                  {t("all.text.waiting_action", {
                    count: waitingLabels.length,
                  })}
                </span>
                {waitingLabels.map(
                  (el) =>
                    el && (
                      <BestLabel
                        label={el}
                        cancelOne={delLabel}
                        switchToPartial={switchToPartial}
                        partiel={partiel}
                        column={column}
                        isRound
                      />
                    )
                )}
              </>
            )}
            {affectedLabels?.length > 0 && (
              <>
                <div
                  className="flex-box"
                  style={{
                    width: "100%",
                    borderBottom: "1px solid lightgrey",
                    marginBottom: "5px",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    {t("device_info.text.x_loaded_round", {
                      count: affectedLabels?.length,
                    })}
                  </span>
                  <div className="red-cross" onClick={() => disaffectAll()}>
                    <div className="svg">
                      <SvgCroixNoir height="1.7em" fill="#dc3545" />
                    </div>
                    <span className="span">
                      {t(
                        "tournee_ressources.column_exported_tooltip.unload_all"
                      )}
                    </span>
                  </div>
                </div>
                {affectedLabels?.map(
                  (el) =>
                    el && (
                      <BestLabel
                        label={el}
                        cancelOne={desaffect}
                        loaded
                        partiel={partiel}
                        switchToPartial={switchToPartial}
                        column={column}
                        isRound
                      />
                    )
                )}
              </>
            )}
          </div>
          {waitingLabels.length > 0 && (
            <ValidateButtons
              task={task}
              waitingLabels={waitingLabels}
              online={false}
              isRound={true}
              gestionnaireId={GestionnaireId}
            />
          )}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { synchrocom } = state;
  return {
    synchrocom,
  };
}

const mapping = connect(mapStateToProps)(RoundInfo);

const connectedTask = withRouter(mapping);
const tr = withTranslation()(connectedTask);
export default tr;
