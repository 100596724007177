import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import _ from "lodash";
import Select from "react-select";
import { Button, CustomInput, FormGroup, Label, Spinner } from "reactstrap";
import styled from "styled-components";
import synchroAction from "../_actions/synchro.actions";
import SvgBatterieVert from "../SvgComponents/BatterieVerte";

const SynchroBattery = styled.div`
  background-color: ${(props: any) =>
    props.lvlcolor ? props.lvlcolor : "#d3d3d3"};
  width: ${(props: any) => props.x}px;
  height: 16px;
  position: absolute;
  margin: 4px;
`;

class SynchroTools extends Component<any, any> {
  refreshDevices: any;
  constructor(props) {
    super(props);
    this.rowStyle = this.rowStyle.bind(this);
    this.clickOnRow = this.clickOnRow.bind(this);
    this.launchConnexion = this.launchConnexion.bind(this);
    this.closeConnexion = this.closeConnexion.bind(this);
    this.getOptionForCommand = this.getOptionForCommand.bind(this);
    this.launchCommand = this.launchCommand.bind(this);

    this.state = { selectedDevice: null, selectedCommand: null, option: {} };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(synchroAction.getAllDevicesTools());
    this.refreshDevices = setInterval(
      () => dispatch(synchroAction.getAllDevicesTools()),
      5000
    );
  }

  componentWillUnmount() {
    clearInterval(this.refreshDevices);
  }

  rowStyle(row) {
    const { selectedDevice } = this.state;
    const newVar =
      row.id === selectedDevice
        ? { border: "2px solid black", fontWeight: "bold" }
        : {};
    if (_.get(row, "status.online")) {
      if (_.get(row, "synchroModeTools")) {
        return _.merge({ backgroundColor: "#7ac3e8" }, newVar);
      }
      if (_.get(row, "communication_status")) {
        return { backgroundColor: "#782fec" };
      }
      console.log("SynchroTools::rowStyle::43", row.id, selectedDevice);
      return _.merge({ backgroundColor: "#bddbd1" }, newVar);
    }

    return { backgroundColor: "#f4c5bb" };
  }

  commandOptions() {
    const { t } = this.props;
    return [
      {
        value: "DOWNLOADROUND",
        label: t("synchrotools.option.download_round"),
      },
      { value: "REMOVEROUND", label: t("synchrotools.option.remove_round") },
      { value: "UPLOADROUND", label: t("synchrotools.option.upload_round") },
    ];
  }

  clickOnRow(e: Object, row: any) {
    if (_.get(row, "status.online")) {
      this.setState({
        selectedDevice: row.id,
        options: null,
        formattedOptions: null,
      });
    }
  }

  launchConnexion() {
    const { dispatch } = this.props;
    const { selectedDevice } = this.state;
    dispatch(synchroAction.connectToDeviceTools(selectedDevice));
  }

  closeConnexion() {
    const { dispatch } = this.props;
    const { selectedDevice } = this.state;
    dispatch(synchroAction.disconnectOfDeviceTools(selectedDevice));
  }

  getOptionForCommand() {
    const { t, synchro } = this.props;
    const { selectedCommand, options, selectedDevice } = this.state;
    const extension = ".dat,.rtr,.txt";
    console.log("SynchroTools::getOptionForCommand::70");
    switch (_.get(selectedCommand, "value")) {
      case "DOWNLOADROUND":
        console.log("SynchroTools::getOptionForCommand::73");
        return (
          <FormGroup style={{ width: "25%" }}>
            <Label for="dataFile">
              Fichier de tournée (format silex) {`(${extension})`}
            </Label>
            <CustomInput
              type="file"
              id="import-silex-button"
              onChange={(e) => {
                const formData = new FormData();
                formData.append("file", e.target.files[0]);
                this.setState({
                  options: formData,
                  formattedOptions: formData,
                });
              }}
              accept={extension}
              name="customFile"
              label={
                options ? options.get("file").name : t("all.text.select_file")
              }
            />
          </FormGroup>
        );
      case "REMOVEROUND":
      case "UPLOADROUND":
        return (
          <Select
            id="command_selector"
            options={(
              _.get(
                synchro,
                `devices.${selectedDevice}.actualRoundsInDevice`,
                []
              ) || []
            ).map((el) => ({
              value: el,
              label: el,
            }))}
            isMulti
            placeholder={t("synchrotools.option.rounds")}
            onChange={(e) => {
              this.setState({
                options: e,
                formattedOptions: JSON.stringify(e.map((el) => el.value)),
              });
            }}
            value={options}
            isSearchable
          />
        );
    }
  }

  batteryColor(percent: any) {
    if (percent <= 20) {
      return "red";
    }
    if (percent > 20 && percent <= 50) {
      return "orange";
    }
    if (percent > 50) {
      return "green";
    }
    if (percent === -1) {
      return "lightgrey";
    }
  }

  launchCommand() {
    const { dispatch } = this.props;
    const { selectedDevice, selectedCommand, formattedOptions } = this.state;
    dispatch(
      synchroAction.launchCommand(
        selectedDevice,
        selectedCommand.value,
        formattedOptions
      )
    );
    this.setState({ options: null, formattedOptions: null });
  }

  render() {
    const { synchro, t } = this.props;
    const { selectedDevice, selectedCommand } = this.state;
    return (
      <div className="synchrotools" style={{ display: "flex" }}>
        <BootstrapTable
          keyField="devEUI"
          bootstrap4
          bordered
          hover
          rowClasses={(row) => (_.get(row, "status.online") ? "clickable" : "")}
          data={_.sortBy(_.values(synchro.devices), "status.online")}
          key="id"
          columns={[
            {
              dataField: "id",
              text: t("all.text.phone_plural"),
              classes: "crystalList-column",
              formatter: (id: any, row: any) => (
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.2em",
                      marginRight: "auto",
                    }}
                  >
                    {row.content}
                  </span>
                  <div>
                    <div
                      className="battery-component"
                      style={{ position: "relative" }}
                    >
                      {_.get(row, "status.batteryLevel") > 0 && (
                        <>
                          <SynchroBattery
                            x={38 * (_.get(row, "status.batteryLevel") / 100)}
                            lvlcolor={this.batteryColor(
                              _.get(row, "status.batteryLevel")
                            )}
                          />
                          <span
                            style={{
                              position: "absolute",
                              marginLeft: "6px",
                              marginTop: "2px",
                            }}
                          >
                            {_.get(row, "status.batteryLevel")}%
                          </span>
                        </>
                      )}
                      {_.get(row, "status.batteryLevel") > 0 && (
                        <SynchroBattery x={0} lvlcolor={-1} />
                      )}
                      <SvgBatterieVert width="4em" />
                    </div>
                    {_.get(row, "currentCommand") && <Spinner />}
                  </div>
                </div>
              ),
            },
          ]}
          headerClasses="crystalList-column"
          rowStyle={this.rowStyle}
          rowEvents={{ onClick: this.clickOnRow }}
        />
        {selectedDevice && (
          <div style={{ width: "70%" }}>
            <Button
              disabled={_.get(
                synchro,
                `devices.${selectedDevice}.communication_status`
              )}
              onClick={this.launchConnexion}
            >
              {t("synchrotools.button.connect")}
            </Button>
            <Button
              disabled={
                !_.get(
                  synchro,
                  `devices.${selectedDevice}.communication_status`
                )
              }
              onClick={this.closeConnexion}
            >
              {t("synchrotools.button.disconnect")}
            </Button>
            <Select
              id="command_selector"
              options={this.commandOptions()}
              placeholder={t("synchrotools.option.command")}
              onChange={(e) => {
                this.setState({
                  selectedCommand: e,
                  options: null,
                  formattedOptions: null,
                });
              }}
              value={selectedCommand}
              isSearchable
              style={{ width: "30%" }}
            />
            {this.getOptionForCommand()}
            <Button onClick={this.launchCommand}>
              {t("synchrotools.button.launchCommand")}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { synchro } = state;
  return {
    synchro,
  };
}

const wt: any = connect(mapStateToProps)(SynchroTools);
export default withTranslation()(withRouter(wt));
